// src/components/CaptchaOverlay.js
import React, { useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Trans } from '@lingui/macro';
import { i18n } from '@lingui/core';

const CaptchaOverlay = ({ onCaptchaSolved }) => {
  // Beim Mounten prüfen, ob das reCAPTCHA-Skript geladen ist; falls nicht, laden wir es mit der aktuellen Sprache.
  useEffect(() => {
    if (!window.grecaptcha) {
      const script = document.createElement('script');
      // Sprache aus i18n (z. B. "de" oder "en")
      script.src = `https://www.google.com/recaptcha/api.js?hl=${i18n.locale}`;
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      };
    }
  }, []);

  // Callback, wenn der Benutzer das Captcha löst.
  const handleCaptchaChange = (token) => {
    if (token && onCaptchaSolved) {
      onCaptchaSolved(token);
    }
  };

  return (
    <div style={styles.overlay}>
      <div style={styles.content}>
        <h1>
          <Trans id="captcha.required.title">Captcha erforderlich</Trans>
        </h1>
        <p>
          <Trans id="captcha.required.message">
            Bitte lösen Sie das Captcha, um fortzufahren.
          </Trans>
        </p>
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
          onChange={handleCaptchaChange}
          theme="dark"
        />
      </div>
    </div>
  );
};

const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.85)',
    color: '#fff',
    zIndex: 9999,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    padding: '20px',
  },
  content: {
    maxWidth: '500px',
  },
};

export default CaptchaOverlay;
