// /src/components/BuyingSimulator.js

import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import '../styles/common.css'; 
import '../styles/BuyingSimulator.css';

// Importiere die neuen Chart-Komponenten
import LineChart from './LineChart';
import BarChart from './BarChart';

const BuyingSimulator = ({ addCoins }) => {
  const [step, setStep] = useState(1);
  const [coins, setCoins] = useState(0);
  const [quizAnswer, setQuizAnswer] = useState('');
  const [quizFeedback, setQuizFeedback] = useState('');

  const nextStep = () => {
    if (step < 6) { // Maximale Schrittzahl auf 6 setzen
      setStep(step + 1);
      if (step === 5) { // Belohnung nach Schritt 5
        setCoins(coins + 10);
        addCoins(10);
      }
    }
  };

  const prevStep = () => {
    if (step > 1) {
      setStep(step - 1);
      setQuizFeedback('');
      setQuizAnswer('');
    }
  };

  const handleQuizSubmit = () => {
    if (quizAnswer.toLowerCase() === 'technologie') {
      setQuizFeedback('Richtig! Du erhältst 5 Münzen.');
      setCoins(coins + 5);
      addCoins(5);
    } else {
      setQuizFeedback('Leider falsch. Versuche es noch einmal.');
    }
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <div className="simulator-step">
            <h3>Schritt 1: Einführung in Kryptowährungen</h3>
            <p>
              Kryptowährungen sind digitale Assets, die auf der Blockchain-Technologie basieren. Sie dienen als Zahlungsmittel, Wertaufbewahrungsmittel oder zur Nutzung in dezentralen Anwendungen.
            </p>
            <div className="chart-container">
              <LineChart data={getLineChartDataStep1()} options={getLineChartOptions()} />
            </div>
          </div>
        );
      case 2:
        return (
          <div className="simulator-step">
            <h3>Schritt 2: Angebot und Nachfrage</h3>
            <p>
              Der Preis einer Kryptowährung wird stark durch Angebot und Nachfrage bestimmt. Wenn die Nachfrage steigt und das Angebot konstant bleibt, steigt der Preis und umgekehrt.
            </p>
            <div className="charts-step2">
              <div className="chart-container">
                <BarChart data={getSupplyDemandBarData()} options={getBarChartOptions()} />
              </div>
            </div>
          </div>
        );
      case 3:
        return (
          <div className="simulator-step">
            <h3>Schritt 3: Preisbewegungen verstehen</h3>
            <p>
              Preisbewegungen in Kryptowährungen können durch verschiedene Faktoren wie Marktstimmung, Nachrichten oder regulatorische Entwicklungen beeinflusst werden.
            </p>
            <div className="chart-container">
              <LineChart data={getAnnotatedLineChartData()} options={getAnnotatedLineChartOptions()} />
            </div>
          </div>
        );
      case 4:
        return (
          <div className="simulator-step">
            <h3>Schritt 4: Einflussfaktoren auf den Kryptomarkt</h3>
            <p>
              Faktoren wie technologische Entwicklungen, Partnerschaften, Marktakzeptanz und makroökonomische Trends können den Kryptomarkt maßgeblich beeinflussen.
            </p>
            <div className="chart-container">
              <BarChart data={getInfluenceFactorsBarData()} options={getInfluenceFactorsOptions()} />
            </div>
            {/* Quizfrage */}
            <div className="quiz-section">
              <h4>Quizfrage:</h4>
              <p>Welcher Faktor beeinflusst den Preis einer Kryptowährung am stärksten?</p>
              <Form>
                <Form.Group controlId="quizAnswer">
                  <Form.Control
                    type="text"
                    placeholder="Deine Antwort"
                    value={quizAnswer}
                    onChange={(e) => setQuizAnswer(e.target.value)}
                  />
                </Form.Group>
                <Button variant="success" onClick={handleQuizSubmit} className="mt-2">
                  Antwort überprüfen
                </Button>
              </Form>
              {quizFeedback && <p className={`quiz-feedback ${quizFeedback.startsWith('Richtig') ? 'success' : 'error'}`}>{quizFeedback}</p>}
            </div>
          </div>
        );
      case 5:
        return (
          <div className="simulator-step">
            <h3>Schritt 5: Risikomanagement</h3>
            <p>
              Beim Handel mit Kryptowährungen ist es wichtig, Risiken zu managen. Strategien wie Stop-Loss-Orders und Diversifikation helfen, Verluste zu begrenzen und das Portfolio zu schützen.
            </p>
            <div className="chart-container">
              <LineChart data={getRiskManagementData()} options={getRiskManagementOptions()} />
            </div>
          </div>
        );
      case 6:
        return (
          <div className="simulator-step summary-step">
            <h3>Schritt 6: Herzlichen Glückwunsch! Zusammenfassung und Belohnung</h3>
            <p>
              Du hast die grundlegenden Konzepte des Kryptokaufs erfolgreich durchlaufen! Du erhältst <strong>10 Münzen</strong> als Belohnung.
            </p>
            {/* Diagramm wurde entfernt */}
            <p>Gesammelte Münzen: <strong>{coins}</strong></p>
          </div>
        );
      default:
        return null;
    }
  };

  // Verschiedene Chart-Daten je nach Schritt
  const getLineChartDataStep1 = () => {
    return {
      labels: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun'],
      datasets: [
        {
          label: 'Bitcoin Preis (USD)',
          data: [30000, 32000, 31000, 33000, 34000, 35000],
          fill: false,
          borderColor: '#ff7300',
          tension: 0.1,
          pointRadius: 3,
        },
      ],
    };
  };

  const getLineChartOptions = () => {
    return {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: true,
        },
      },
    };
  };

  const getSupplyDemandBarData = () => {
    return {
      labels: ['Angebot', 'Nachfrage'],
      datasets: [
        {
          label: 'Prozentsatz',
          data: [60, 40], // Angepasstes Verhältnis
          backgroundColor: ['#4BC0C0', '#FF9F40'],
        },
      ],
    };
  };

  const getBarChartOptions = () => {
    return {
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: true,
          text: 'Angebot und Nachfrage Verhältnis',
        },
      },
      scales: {
        y: {
          beginAtZero: true,
          max: 100,
          ticks: {
            stepSize: 20,
          },
        },
      },
    };
  };

  const getInfluenceFactorsBarData = () => {
    return {
      labels: ['Technologie', 'Partnerschaften', 'Marktakzeptanz', 'Makroökonomie'],
      datasets: [
        {
          label: 'Einflussfaktoren (%)',
          data: [40, 30, 20, 10], // Technologie hat den größten Einfluss
          backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'],
        },
      ],
    };
  };

  const getInfluenceFactorsOptions = () => {
    return {
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: true,
          text: 'Einflussfaktoren auf den Kryptomarkt',
        },
      },
      scales: {
        y: {
          beginAtZero: true,
          max: 50,
          ticks: {
            stepSize: 10,
          },
        },
      },
    };
  };

  const getAnnotatedLineChartData = () => {
    return {
      labels: ['Tag 1', 'Tag 2', 'Tag 3', 'Tag 4', 'Tag 5', 'Tag 6'],
      datasets: [
        {
          label: 'Preisbewegung (USD)',
          data: [35000, 34000, 36000, 35500, 36500, 37000],
          fill: false,
          borderColor: '#36A2EB',
          tension: 0.1,
          pointRadius: 3,
        },
      ],
    };
  };

  const getAnnotatedLineChartOptions = () => {
    return {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: true,
        },
      },
    };
  };

  const getRiskManagementData = () => {
    return {
      labels: ['Diversifikation', 'Stop-Loss', 'Position Sizing'],
      datasets: [
        {
          label: 'Risikomanagement-Strategien',
          data: [40, 35, 25],
          backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
        },
      ],
    };
  };

  const getRiskManagementOptions = () => {
    return {
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: true,
          text: 'Risikomanagement-Strategien',
        },
      },
      scales: {
        y: {
          beginAtZero: true,
          max: 50,
          ticks: {
            stepSize: 10,
          },
        },
      },
    };
  };

  return (
    <div className="buying-simulator">
      {renderStep()}
      <div className="simulator-navigation">
        <Button variant="secondary" onClick={prevStep} disabled={step === 1}>
          Zurück
        </Button>
        <Button variant="primary" onClick={nextStep} disabled={step === 6}>
          Weiter
        </Button>
      </div>
      <div className="simulator-progress">
        Schritt {step} von 6
      </div>
      <div className="simulator-coins">
        <p>Gesammelte Münzen: <strong>{coins}</strong></p>
      </div>
    </div>
  );
};

export default BuyingSimulator;
