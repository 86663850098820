import React, { useEffect, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { toast } from 'react-toastify';
import { t } from '@lingui/macro';

const OAuthRedirect = () => {
  const navigate = useNavigate();
  // Verwende eine dedizierte Funktion, die den Profilabruf triggert.
  const { handleOAuthLogin } = useContext(AuthContext);
  const hasProcessed = useRef(false);

  useEffect(() => {
    if (hasProcessed.current) return;
    hasProcessed.current = true;

    const urlParams = new URLSearchParams(window.location.search);
    const mode = urlParams.get('mode') || 'login';

    // Hier brauchen wir die Token nicht mehr zu verwenden,
    // da das Backend bereits die httpOnly-Cookies setzen sollte.
    handleOAuthLogin()
      .then(() => {
        if (mode === 'register') {
          toast.success(
            t({
              id: 'success.userRegisteredGoogle',
              message: 'Du hast dich erfolgreich registriert. Bitte passe dein Profil entsprechend an.',
            })
          );
          navigate('/profile');
        } else {
          toast.success(
            t({
              id: 'success.loginSuccessful',
              message: 'Login erfolgreich',
            })
          );
          navigate('/');
        }
      })
      .catch(() => {
        toast.error(
          t({
            id: 'error.loginFailed',
            message: 'Login fehlgeschlagen! Bitte überprüfe deine Eingaben.',
          })
        );
        navigate('/login');
      });
  }, [handleOAuthLogin, navigate]);

  return <div>Lade...</div>;
};

export default OAuthRedirect;
