// /frontend/src/utils/formatPrice.js
import React from 'react';
import { getCurrencyIcon } from './currencySymbols';

const storedLang = localStorage.getItem('language') || 'de';
const locale = storedLang === 'de' ? 'de-DE' : 'en-US';

/**
 * Formatiert einen Preis numerisch und kann optional ein Icon oder Symbol hinzufügen.
 *
 * Für Crypto-Werte wird die Dezimalstellenanzahl dynamisch gewählt:
 *  • Preis ≥ 100: 2 Dezimalstellen
 *  • Preis zwischen 10 und 100: 4 Dezimalstellen (nach Formatierung werden überflüssige Nullen am Ende entfernt)
 *  • Preis zwischen 1 und 10: 4 Dezimalstellen (überflüssige Nullen werden entfernt)
 *  • Preis < 1 und ≥ 0.001: Rundung in Schritten von 0,000005, 6 Dezimalstellen
 *  • Preis < 0.001 und ≥ 0.000001: 10 Dezimalstellen
 *  • Preis < 0.000001: 13 Dezimalstellen
 *
 * @param {number}  price                   - Der zu formatierende Preis
 * @param {string}  [currency='usd']        - Währung (z.B. 'usd')
 * @param {boolean} [showIcons=true]        - Ob ein Icon/Symbol angezeigt werden soll
 * @param {boolean} [highlightNumbersOnly=false]
 *        Falls true, wird das Icon vom Zahlen-Span getrennt, damit ein reines Text-Highlight möglich ist
 * @param {string}  [highlightClass='']     - CSS-Klasse für den reinen Zahlen-Span (z. B. 'highlightText-up')
 * @returns {JSX.Element|string} React-Element oder String
 */
export function formatPrice(
  price,
  currency = 'usd',
  showIcons = true,
  highlightNumbersOnly = false,
  highlightClass = ''
) {
  if (price === null || price === undefined || isNaN(price)) {
    return 'N/A';
  }

  let formattedNumber;

  if (price >= 100) {
    formattedNumber = price.toLocaleString(locale, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    formattedNumber = removeTrailingZeros(formattedNumber);
  } else if (price >= 10) {
    // Für Preise zwischen 10 und 100: zunächst 4 Dezimalstellen formatieren,
    // danach überflüssige Nullen am Ende entfernen.
    formattedNumber = removeTrailingZeros(
      price.toLocaleString(locale, {
        minimumFractionDigits: 4,
        maximumFractionDigits: 4,
      })
    );
  } else if (price >= 1) {
    formattedNumber = price.toLocaleString(locale, {
      minimumFractionDigits: 4,
      maximumFractionDigits: 4,
    });
    formattedNumber = removeTrailingZeros(formattedNumber);
  } else {
    // Preise unter 1:
    if (price >= 0.001) {
      const increment = 0.000005;
      const rounded = Math.round(price / increment) * increment;
      formattedNumber = parseFloat(rounded.toFixed(6)).toLocaleString(locale, {
        minimumFractionDigits: 4,
        maximumFractionDigits: 6
      });
      formattedNumber = removeTrailingZeros(formattedNumber);
    } else if (price >= 0.000001) {
      // Nutze toFixed, um die Rundung zu erreichen, und parse dann den Float
      const rounded = parseFloat(price.toFixed(12));
      // toLocaleString wandelt den Float in einen String mit korrektem Dezimaltrenner um
      formattedNumber = rounded.toLocaleString(locale, {
        minimumFractionDigits: 6,
        maximumFractionDigits: 12
      });
      formattedNumber = removeTrailingZeros(formattedNumber);
    } else {
      const rounded = parseFloat(price.toFixed(18));
      formattedNumber = rounded.toLocaleString(locale, {
        minimumFractionDigits: 8,
        maximumFractionDigits: 16,
      });
      formattedNumber = removeTrailingZeros(formattedNumber);
    }
  }

  if (!showIcons) {
    return formattedNumber;
  }

  const iconElement = getCurrencyIcon(currency, { size: 12 });

  return highlightNumbersOnly
    ? renderIconAndPriceSeparately(iconElement, formattedNumber, highlightClass)
    : renderClassicIconWithPrice(iconElement, formattedNumber);
}

/**
 * FormatPriceAsString: Gibt den formatierten Preis als reinen String zurück.
 *
 * Zusätzlich wird – falls `ellipsize` true ist – bei Preisen unter 0.000001
 * die Funktion maybeEllipsizeThree genutzt, um den Bruchteil ab 5 führenden Nullen
 * zu kürzen.
 *
 * @param {number}  price                   - Der zu formatierende Preis
 * @param {string}  [currency='usd']        - Währung (z.B. 'usd')
 * @param {boolean} [ellipsize=false]       - Falls true, wird bei Preisen unter 0.000001
 *                                           die Ellipsen-Logik angewendet
 * @param {boolean} [forceFullPrecision=false]
 *        Falls true, wird keine Stufen-/Ellipsen-Logik angewendet,
 *        sondern bis zu 18 Nachkommastellen und kein Kürzen.
 * @returns {string} Formatierter Preis als String
 */
export function formatPriceAsString(
  price,
  currency = 'usd',
  ellipsize = false,
  forceFullPrecision = false
) {
  if (price === null || price === undefined || isNaN(price)) {
    return 'N/A';
  }
  if (price === 0) return '0';
  if (price < 0) {
    return '-' + formatPriceAsString(-price, currency, ellipsize, forceFullPrecision);
  }

  if (forceFullPrecision) {
    const raw = price.toFixed(18);
    const trimmed = removeTrailingZeros(raw);
    if (parseFloat(trimmed) === 0 && price < 1e-18) {
      return '<1e-18';
    }
    return trimmed;
  }

  let formatted;
  if (price >= 100) {
    formatted = price.toFixed(2);
    formatted = removeTrailingZeros(formatted);
  } else if (price >= 10) {
    // Für Preise zwischen 10 und 100: 4 Dezimalstellen formatieren,
    // dann werden überflüssige Nullen am Ende entfernt.
    formatted = removeTrailingZeros(price.toFixed(4));
    return formatted;
  } else if (price >= 1) {
    formatted = price.toFixed(4);
    formatted = removeTrailingZeros(formatted);
    return formatted;
  } else {
    if (price >= 0.001) {
      const increment = 0.000005;
      const rounded = Math.round(price / increment) * increment;
      formatted = rounded.toFixed(6);
      formatted = removeTrailingZeros(formatted);
      return formatted;
    } else if (price >= 0.000001) {
      formatted = price.toFixed(12);
      formatted = removeTrailingZeros(formatted);
      return formatted;
    } else if (price >= 1e-18) {
      formatted = price.toFixed(18);
      formatted = removeTrailingZeros(formatted);
      if (parseFloat(formatted) === 0) {
        return '<1e-18';
      }
      return ellipsize ? maybeEllipsizeThree(formatted) : formatted;
    } else {
      return '<1e-18';
    }
  }
  formatted = removeTrailingZeros(formatted);
  return formatted;
}

/**
 * Entfernt überschüssige Nachkommastellen, z. B. "17.6000" -> "17.6" oder "94,1220" -> "94,122".
 * Dabei wird der letzte (Dezimal-)Trenner korrekt ermittelt, um Tausender-Trennzeichen unberührt zu lassen.
 *
 * @param {string} str - Der zu bereinigende Zahlenstring.
 * @returns {string} - Der bereinigte String.
 */
function removeTrailingZeros(str) {
  // Finde den letzten Vorkommnis von '.' oder ',' – das ist unser Dezimaltrenner.
  const lastComma = str.lastIndexOf(',');
  const lastDot = str.lastIndexOf('.');
  let separator = '';
  let intPart = str;
  let fracPart = '';

  if (lastComma > lastDot) {
    separator = ',';
  } else if (lastDot > lastComma) {
    separator = '.';
  }

  // Falls kein Dezimaltrenner gefunden wird, gib den String zurück
  if (!separator) {
    return str;
  }

  intPart = str.substring(0, str.lastIndexOf(separator));
  fracPart = str.substring(str.lastIndexOf(separator) + 1);

  // Entferne überflüssige Nullen im Nachkommabereich
  fracPart = fracPart.replace(/0+$/, '');

  // Falls nach dem Kürzen der Bruchteil leer ist, gib nur den ganzzahligen Teil zurück.
  if (fracPart === '') {
    return intPart;
  }

  return intPart + separator + fracPart;
}

function renderClassicIconWithPrice(iconElement, formattedNumber) {
  return (
    <span
      className="currency-with-icon"
      style={{ display: 'inline-flex', alignItems: 'center', gap: '1px' }}
    >
      {iconElement}
      {formattedNumber}
    </span>
  );
}

function renderIconAndPriceSeparately(iconElement, formattedNumber, highlightClass) {
  return (
    <span
      className="currency-with-icon"
      style={{ display: 'inline-flex', alignItems: 'center', gap: '1px' }}
    >
      {iconElement}
      <span className={`price-numeric-only ${highlightClass}`}>
        {formattedNumber}
      </span>
    </span>
  );
}

/**
 * maybeEllipsizeThree:
 * Wenn der ganzzahlige Teil "0" ist und im Bruchteil ab 5 führenden Nullen vorhanden sind,
 * wird das Ergebnis in der Form "0.00...XYZ" dargestellt.
 *
 * @param {string} trimmed - Der bereits formatierte Preis als String
 * @returns {string} eventuell ellipsierter Preis
 */
function maybeEllipsizeThree(trimmed) {
  const [intPart, fracPart = ''] = trimmed.split('.');
  if (intPart !== '0') {
    return trimmed;
  }
  const leadingZeros = (fracPart.match(/^0*/) || [''])[0].length;
  if (leadingZeros < 5) {
    return trimmed;
  }
  let result = '0.00..';
  const remainder = fracPart.slice(leadingZeros);
  if (!remainder) {
    return '0.00';
  }
  const idx = remainder.search(/[^0]/);
  if (idx === -1) {
    return '0.00';
  }
  let sig = remainder.slice(idx, idx + 2);
  sig = '0' + sig;
  return result + sig;
}

export { removeTrailingZeros };
