// /frontend/src/utils/formatLargeValue.js
import React from 'react';
import { getCurrencyIcon } from './currencySymbols';
import { i18n } from '@lingui/core';

/**
 * Formatiert große Werte (MarketCap, Volume) und kann
 *   - kurz abkürzen (k, M, B)
 *   - Icons/Symbole voranstellen
 *   - bei Zahlenänderungen einen Highlight-Effekt anwenden.
 *
 * @param {number}   value                 - Wert, z. B. MarketCap/Volume
 * @param {string}   [currency='usd']      - Währung (z.B. 'usd')
 * @param {boolean}  [forceAbbreviated=false] - Wenn true, wird immer abgekürzt (z. B. 2.3M), auch auf Desktop
 * @param {boolean}  [showIcons=true]      - Ob ein Symbol/Icon angezeigt werden soll
 * @param {boolean}  [highlightNumbersOnly=false]
 *          Falls true, wird das Icon vom Zahlen-Span getrennt, damit ein reines Text-Highlight möglich ist
 * @param {string}   [highlightClass='']   - CSS-Klasse für den reinen Zahlen-Span (z. B. 'highlightText-up')
 * @returns {JSX.Element|string} React-Element oder String
 */
export function formatLargeValue(
  value,
  currency = 'usd',
  forceAbbreviated = false,
  showIcons = true,
  highlightNumbersOnly = false,
  highlightClass = ''
) {
  if (value === null || value === undefined) {
    return 'N/A';
  }

  // Ermitteln, ob wir mobil sind oder nicht.
  const isMobile = typeof window !== 'undefined' && window.innerWidth <= 768;
  const lang = i18n.locale && i18n.locale.startsWith('de') ? 'de' : 'en';
  const locale = lang === 'de' ? 'de-DE' : 'en-US';


  if (forceAbbreviated || isMobile) {
    return formatAbbreviated(value);
  } else {
    return regularFormat(value);
  }

  // -------------------------------------------
  // Hilfsfunktionen
  // -------------------------------------------

  /**
   * Entfernt überflüssige Nachkommastellen, z. B. "3.1000" => "3.1", "0,003500" => "0,0035"
   * Berücksichtigt dabei sowohl Punkt als auch Komma als Dezimaltrenner.
   */
  function removeTrailingZeros(str) {
    return str
      .replace(/([.,]\d*?[1-9])0+$/,'$1')
      .replace(/([.,])0+$/,'$1')
      .replace(/([.,])$/,'');
  }

  /**
   * Kürzt Werte ab (k, M, B)
   * z. B. 1234567 => '1.2M'
   */
  function formatAbbreviated(num) {
    let unit = '';
    let divisor = 1;
  
    if (Math.abs(num) >= 1e12) {
      divisor = 1e12;
      unit = lang === 'de' ? ' B' : 'T';
    } else if (Math.abs(num) >= 1e9) {
      divisor = 1e9;
      unit = lang === 'de' ? ' Mrd' : 'B';
    } else if (Math.abs(num) >= 1e6) {
      divisor = 1e6;
      unit = lang === 'de' ? ' Mio' : 'M';
    } else if (Math.abs(num) >= 1e3) {
      divisor = 1e3;
      unit = lang === 'de' ? ' Tsd' : 'k';
    } else {
      return regularFormat(num);
    }
  
    const shortValue = num / divisor;
    let formattedShortValue;
  
    if (Math.abs(num) >= 1e12) {
      // Spezifisch für Werte ab 1e12: 3 Dezimalstellen
      formattedShortValue = shortValue.toLocaleString(locale, {
        minimumFractionDigits: 3,
        maximumFractionDigits: 3,
      });
    } else {
      // Standard: 1 Dezimalstelle
      formattedShortValue = shortValue.toLocaleString(locale, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }
  
    const finalShortValue = removeTrailingZeros(formattedShortValue);
    const valueStr = finalShortValue + unit;
  
    if (!showIcons) {
      return valueStr;
    }
  
    const iconElement = getCurrencyIcon(currency, { size: 12 });
    return highlightNumbersOnly
      ? renderIconAndValueSeparately(iconElement, valueStr, highlightClass)
      : renderWithSymbolOrIcon(iconElement, valueStr);
  }

  /**
   * Formatiert Werte ohne Abkürzung.
   * Z. B. 1234 => '1,234'
   */
  function regularFormat(num) {
    let minimumFractionDigits = 0;
    let maximumFractionDigits = 0;
  
    if (num < 0) {
      num = 0;
    }
  
    if (num < 0.001) {
      minimumFractionDigits = 10;
      maximumFractionDigits = 10;
    } else if (num < 1) {
      minimumFractionDigits = 6;
      maximumFractionDigits = 6;
    } else if (num < 100) {
      minimumFractionDigits = 2;
      maximumFractionDigits = 2;
    } else {
      minimumFractionDigits = 0;
      maximumFractionDigits = 0;
    }
  
    const formattedNumber = num.toLocaleString(locale, {
      minimumFractionDigits,
      maximumFractionDigits,
    });
    const finalNumber = removeTrailingZeros(formattedNumber);
  
    if (!showIcons) {
      return finalNumber;
    }
  
    const iconElement = getCurrencyIcon(currency, { size: 12 });
    return highlightNumbersOnly
      ? renderIconAndValueSeparately(iconElement, finalNumber, highlightClass)
      : renderWithSymbolOrIcon(iconElement, finalNumber);
  }
  

  function renderWithSymbolOrIcon(iconElement, valueStr) {
    return (
      <span
        className="currency-with-icon"
        style={{ display: 'inline-flex', alignItems: 'center', gap: '1px' }}
      >
        {iconElement}
        {valueStr}
      </span>
    );
  }

  function renderIconAndValueSeparately(iconElement, valueStr, highlightClass) {
    return (
      <span
        className="currency-with-icon"
        style={{ display: 'inline-flex', alignItems: 'center', gap: '1px' }}
      >
        {iconElement}
        <span className={`price-numeric-only ${highlightClass}`}>
          {valueStr}
        </span>
      </span>
    );
  }
}
