import React, { createContext, useState, useRef, useEffect, useCallback } from 'react';

export const BinanceDataContext = createContext(null);

export function BinanceDataProvider({ children }) {
  const [data, setData] = useState({});
  const wsRef = useRef(null);
  const activeSymbolsRef = useRef({});
  const globalReconnectAttemptsRef = useRef(0);
  const globalIntervalRef = useRef(null);

  // --- Ref pattern to handle circular dependencies and ESLint warnings ---
  // We store the latest versions of memoized functions in refs
  const cleanupSymbolRef = useRef();
  const attemptGlobalReconnectRef = useRef();
  const connectWebSocketRef = useRef();

  // Define the functions using useCallback
  const cleanupSymbol = useCallback((lowerSymbol) => {
    const symbolObj = activeSymbolsRef.current[lowerSymbol];
    if (!symbolObj) return;
    if (symbolObj.fallbackTimeoutId) clearTimeout(symbolObj.fallbackTimeoutId);
    delete activeSymbolsRef.current[lowerSymbol];
  }, []); // OK: No dependencies other than stable refs

  const connectWebSocket = useCallback(() => {
    if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) return;

    const wsUrl = 'wss://cryptoscan.digital';
    const socket = new WebSocket(wsUrl);
    wsRef.current = socket;

    socket.onopen = () => {
      console.log('Global WebSocket connected.');
      globalReconnectAttemptsRef.current = 0;
      const symbols = Object.keys(activeSymbolsRef.current);
      symbols.forEach((lowerSymbol) => {
        const { count } = activeSymbolsRef.current[lowerSymbol];
        if (count > 0) {
          const origSymbol = lowerSymbol.endsWith('usdt') ? lowerSymbol.slice(0, -4) : lowerSymbol;
          socket.send(JSON.stringify({ type: 'subscribe', symbol: origSymbol }));
        }
      });
    };

    socket.onmessage = (event) => {
      const msg = JSON.parse(event.data);
      if (msg.symbol && msg.data && msg.data.price !== undefined) {
        const lowerSymbol = msg.symbol.toLowerCase();
        if (activeSymbolsRef.current[lowerSymbol]) {
          activeSymbolsRef.current[lowerSymbol].latestPrice = parseFloat(msg.data.price);
          if (msg.data.percentageChange !== undefined) {
            activeSymbolsRef.current[lowerSymbol].latestChange = parseFloat(msg.data.percentageChange);
          }
        }
      } else if (msg.symbol && msg.data && msg.data.error) {
        const lowerSymbol = msg.symbol.toLowerCase();
        console.error(`Symbol ${msg.symbol} existiert nicht auf Binance.`);
        // Use ref to call the latest cleanupSymbol
        cleanupSymbolRef.current(lowerSymbol); // <-- Use Ref
        setData((old) => {
          const copy = { ...old }; delete copy[lowerSymbol]; return copy;
        });
      }
    };

    socket.onerror = (error) => {
      console.error('Global WebSocket error:', error);
    };

    socket.onclose = (event) => {
      console.warn(`WS geschlossen (Code: ${event.code}, Reason: ${event.reason}).`);
      wsRef.current = null;
      const symbols = Object.keys(activeSymbolsRef.current);
      if (symbols.length > 0) {
        // Use ref to call the latest attemptGlobalReconnect
        attemptGlobalReconnectRef.current(); // <-- Use Ref
      }
    };
    // Dependency array can be empty because we use refs internally
  }, []);

  const attemptGlobalReconnect = useCallback(() => {
    globalReconnectAttemptsRef.current++;
    const attempts = globalReconnectAttemptsRef.current;
    const delay = Math.min(3000 * attempts, 15000);
    console.warn(`WS: versuche Reconnect in ${delay / 1000}s (Versuch ${attempts}).`);
    setTimeout(() => {
      // Use ref to call the latest connectWebSocket
      connectWebSocketRef.current(); // <-- Use Ref
    }, delay);
    // Dependency array can be empty because we use refs internally
  }, []);

  // Effect to keep refs updated with the latest memoized functions
  useEffect(() => {
    cleanupSymbolRef.current = cleanupSymbol;
    attemptGlobalReconnectRef.current = attemptGlobalReconnect;
    connectWebSocketRef.current = connectWebSocket;
  }); // No dependency array needed here, runs after every render

  // --- End of Ref pattern setup ---


  // Interval for data distribution (No changes needed here from previous step)
  useEffect(() => {
    if (!globalIntervalRef.current) {
      globalIntervalRef.current = setInterval(() => {
        const updatedDataBatch = {};
        for (const lowerSymbol in activeSymbolsRef.current) {
          const symbolObj = activeSymbolsRef.current[lowerSymbol];
          const { latestPrice, prevPrice, latestChange } = symbolObj;
          if (latestPrice != null) {
            let priceDirection = '';
            let useBinancePrice = true;
            let shouldHighlight = false;
            if (prevPrice == null) {
              symbolObj.prevPrice = latestPrice;
            } else {
              if (latestPrice > prevPrice) { priceDirection = 'up'; shouldHighlight = true; }
              else if (latestPrice < prevPrice) { priceDirection = 'down'; shouldHighlight = true; }
              symbolObj.prevPrice = latestPrice;
            }
            updatedDataBatch[lowerSymbol] = {
              binancePrice: latestPrice,
              binanceChange: latestChange ?? null,
              useBinancePrice,
              priceDirection: priceDirection,
              highlightKey: shouldHighlight ? Date.now() : (data[lowerSymbol]?.highlightKey || null)
            };
          }
        }
        if (Object.keys(updatedDataBatch).length > 0) {
          setData((old) => ({ ...old, ...updatedDataBatch }));
        }
      }, 2000);
    }
    return () => {
      if (globalIntervalRef.current) {
        clearInterval(globalIntervalRef.current);
        globalIntervalRef.current = null;
      }
    };
  }, [data]); // Dependency on data is correct here

  // Symbol subscription (Uses refs for internal calls)
  const subscribeToSymbol = useCallback(
    (symbol) => {
      const lowerSymbol = symbol.toLowerCase() + 'usdt';
      let symbolObj = activeSymbolsRef.current[lowerSymbol];
      if (!symbolObj) {
        symbolObj = { count: 1, reconnectAttempts: 0, prevPrice: null, latestPrice: null, latestChange: null, fallbackTimeoutId: null };
        activeSymbolsRef.current[lowerSymbol] = symbolObj;
        symbolObj.fallbackTimeoutId = setTimeout(() => {
          const { prevPrice, latestPrice } = symbolObj;
          if (prevPrice === null && latestPrice === null) {
            console.warn(`Keine Daten für ${symbol} nach 25s empfangen.`);
            cleanupSymbolRef.current(lowerSymbol); // <-- Use Ref
            setData((old) => { const copy = { ...old }; delete copy[lowerSymbol]; return copy; });
          }
        }, 25000);
      } else {
        symbolObj.count++;
      }
      connectWebSocketRef.current(); // <-- Use Ref
      if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
        wsRef.current.send(JSON.stringify({ type: 'subscribe', symbol }));
      }
    },
    [] // Empty array as internal calls use stable refs
  );

  // Symbol unsubscription (Uses refs for internal calls)
  const unsubscribeFromSymbol = useCallback(
    (symbol) => {
      const lowerSymbol = symbol.toLowerCase() + 'usdt';
      const symbolObj = activeSymbolsRef.current[lowerSymbol];
      if (!symbolObj) return;
      symbolObj.count--;
      if (symbolObj.count <= 0) {
        if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
          wsRef.current.send(JSON.stringify({ type: 'unsubscribe', symbol }));
        }
        cleanupSymbolRef.current(lowerSymbol); // <-- Use Ref
        setData((old) => { const copy = { ...old }; delete copy[lowerSymbol]; return copy; });
      }
    },
    [] // Empty array as internal calls use stable refs
  );

  // beforeunload listener (No changes needed)
  useEffect(() => {
    const handleBeforeUnload = () => { if (wsRef.current) { wsRef.current.close(); } };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, []);

  // WebSocket check interval (Uses refs for internal calls)
  useEffect(() => {
    const checkWsInterval = setInterval(() => {
      if (!wsRef.current || wsRef.current.readyState !== WebSocket.OPEN) {
        const symbols = Object.keys(activeSymbolsRef.current);
        if (symbols.length > 0) {
          console.warn('WebSocket nicht aktiv => versuche Reconnect.');
          attemptGlobalReconnectRef.current(); // <-- Use Ref
        }
      }
    }, 5000);
    return () => clearInterval(checkWsInterval);
  }, []); // Empty array as internal calls use stable refs

  // Context Provider (Passes the memoized functions, not the refs)
  return (
    <BinanceDataContext.Provider
      value={{
        data,
        subscribeToSymbol,    // Pass the actual useCallback version
        unsubscribeFromSymbol // Pass the actual useCallback version
      }}
    >
      {children}
    </BinanceDataContext.Provider>
  );
}