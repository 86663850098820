// src/components/TradesDisplay.js
import React, { useState, useEffect } from 'react';
import { FaFlag } from 'react-icons/fa'; // Wird jetzt nur in der Legende genutzt
import { Trans } from '@lingui/macro';
import useBinanceTrades from '../../hooks/useBinanceTrades';
import { formatPrice } from '../../utils/formatPrice';
import { formatLargeValue } from '../../utils/formatLargeValue';
import SingleSelectNoSearch from '../../utils/SingleSelectNoSearch';
import InfoTooltip from './InfoTooltip'; // InfoTooltip importieren
import api from '../../services/api';     // <-- Neu: unserer zentralen Axios-Instanz

const TradesDisplay = ({ symbol }) => {
  const vsCurrency = localStorage.getItem('vsCurrency') || 'usd';
  const [conversionFactor, setConversionFactor] = useState(1);
  const [tradesCount, setTradesCount] = useState(5); // Standardmäßig 5 Trades

  // Mögliche Auswahloptionen für die Anzahl der angezeigten Trades
  const tradeOptions = [5, 10, 20];

  // Beim Laden oder bei Änderung von vsCurrency die Exchange-Rates laden
  useEffect(() => {
    if (vsCurrency.toLowerCase() === 'usd') {
      setConversionFactor(1);
    } else {
      (async () => {
        try {
          const { data } = await api.get('/exchange-rates');
          const rates = data.rates;
          if (rates && rates[vsCurrency.toLowerCase()] && rates['usd']) {
            const factor =
              rates[vsCurrency.toLowerCase()].value / rates['usd'].value;
            setConversionFactor(factor);
          } else {
            setConversionFactor(1);
          }
        } catch (err) {
          console.error('Exchange rates fetch failed:', err);
          setConversionFactor(1);
        }
      })();
    }
  }, [vsCurrency]);

  const { trades, error } = useBinanceTrades(symbol);

  // Helper-Funktion zur Formatierung der Uhrzeit
  const formatTradeTime = (timeString) => {
    const timeMs = Number(timeString);
    const date = new Date(timeMs);
    const locale = navigator.language.startsWith('de') ? 'de-DE' : 'en-GB';
    return date.toLocaleTimeString(locale, {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    });
  };

  if (error) {
    console.warn(`TradesDisplay: Symbol ${symbol} nicht verfügbar (${error})`);
    return null;
  }

  // Rendere die Komponente erst, wenn Trade-Daten verfügbar sind
  if (!trades || trades.length === 0) {
    return null; // Alternativ: return <div>Loading...</div>;
  }

  return (
    <div className="trades-display">
      {/* Header mit Tooltip */}
      <div className="trades-header" style={{ position: 'relative' }}>
        <h3>
          <Trans id="label.trades">Trades</Trans>
        </h3>
        <div className="tooltip-wrapper" style={{ position: 'absolute', top: 0, right: 0 }}>
          <InfoTooltip iconSize={18}>
            <Trans id="tooltips.tradesExplanation">
              {
                "Diese Anzeige zeigt die letzten ausgeführten Trades dieses Coins. Du kannst den Zeitpunkt, Preis, Menge und den Trade-Status (Buy/Sell) ablesen."
              }
            </Trans>
          </InfoTooltip>
        </div>
      </div>

      <div className="entries-container" style={{ marginBottom: '10px' }}>
        <div style={{ marginBottom: '4px' }}>
          <Trans id="label.entriesPerPage">Einträge:</Trans>
        </div>
        <div style={{ width: '75px' }}>
          <SingleSelectNoSearch
            options={tradeOptions}
            selectedValue={tradesCount}
            onChange={(val) => setTradesCount(val)}
            placeholder="Bitte wählen..."
            minWidth="75px"
          />
        </div>
      </div>

      <table>
        <thead>
          <tr>
            <th>
              <Trans id="label.time">Zeit</Trans>
            </th>
            <th>
              <Trans id="label.price">Preis</Trans>
            </th>
            <th>
              <Trans id="label.quantity">Menge</Trans>
            </th>
            <th>
              <Trans id="label.status">Status</Trans>
            </th>
          </tr>
        </thead>
        <tbody>
          {trades.slice(0, tradesCount).map((trade, index) => (
            <tr
              key={index}
              className={`${trade.isBuyerMaker ? 'trade-sell' : 'trade-buy'} ${
                trade.isBuyerMaker ? 'market-maker' : ''
              }`}
            >
              <td>{formatTradeTime(trade.time)}</td>
              <td>{formatPrice(trade.price * conversionFactor, vsCurrency, false)}</td>
              <td>{formatLargeValue(trade.quantity, vsCurrency, false, false)}</td>
              <td>
                {trade.isBuyerMaker ? (
                  <Trans id="label.side.sell">Sell</Trans>
                ) : (
                  <Trans id="label.side.buy">Buy</Trans>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Legende für Market Maker */}
      <div style={{ marginTop: '10px', fontSize: '0.8rem', color: 'var(--text-color)' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div
            style={{
              width: '10px',
              height: '16px',
              borderLeft: '2px dashed #ff7f57',
              marginRight: '5px',
            }}
          />
          <Trans id="legend.marketMaker">Market Maker Trade</Trans>
        </div>
      </div>
    </div>
  );
};

export default TradesDisplay;
