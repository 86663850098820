// src/components/FuturesExchangeDetails.js

import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Trans, t } from '@lingui/macro';
import { formatLargeValue } from '../utils/formatLargeValue';
import { formatPrice } from '../utils/formatPrice';
import { formatChangePercentage } from '../utils/formatChangePercentage';
import '../styles/FuturesExchangeDetails.css';
import SingleSelectNoSearch from '../utils/SingleSelectNoSearch';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';
import { Pagination } from '../utils/StyledButton';

import api from '../services/api'; // Neu: Unser Axios-Client

const sortOptions = [
  { value: 'openInterest_desc', label: <Trans id="sort.openInterest_desc">Open Interest High-Low</Trans> },
  { value: 'openInterest_asc', label: <Trans id="sort.openInterest_asc">Open Interest Low-High</Trans> },
  { value: 'volume_desc', label: <Trans id="sort.volume_desc">Volume High-Low</Trans> },
  { value: 'volume_asc', label: <Trans id="sort.volume_asc">Volume Low-High</Trans> },
  { value: 'price_desc', label: <Trans id="sort.price_desc">Preis High-Low</Trans> },
  { value: 'price_asc', label: <Trans id="sort.price_asc">Preis Low-High</Trans> },
  { value: 'change_desc', label: <Trans id="sort.change_desc">24h Change High-Low</Trans> },
  { value: 'change_asc', label: <Trans id="sort.change_asc">24h Change Low-High</Trans> },
  { value: 'symbol_asc', label: <Trans id="sort.symbol_asc">Symbol A-Z</Trans> },
  { value: 'symbol_desc', label: <Trans id="sort.symbol_desc">Symbol Z-A</Trans> },
];

const pairsPerPageOptions = [
  { value: '25', label: '25' },
  { value: '50', label: '50' },
  { value: '100', label: '100' },
  { value: '250', label: '250' },
];

const FuturesExchangeDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  // Erwarteter State: { pairs: [...], market: "Exchange Name" }
  const { pairs, market } = location.state || { pairs: [], market: 'Exchange Details' };

  // STATES
  const [exchangePairs, setExchangePairs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error] = useState(null); // Falls du später Fehler abfangen willst

  // Währungsumrechnung
  const [vsCurrency, setVsCurrency] = useState(localStorage.getItem('vsCurrency') || 'usd');
  const [conversionFactor, setConversionFactor] = useState(1);

  // Sortierung – Standard: nach Open Interest absteigend
  const [sortOrder, setSortOrder] = useState('openInterest_desc');

  // Pagination für die Pairs
  const [currentPage, setCurrentPage] = useState(1);
  const [pairsPerPage, setPairsPerPage] = useState(25);

  // Beim ersten Laden: Falls Pairs vorhanden, in den State übernehmen
  useEffect(() => {
    if (pairs && Array.isArray(pairs)) {
      setExchangePairs(pairs);
    }
    setLoading(false);
  }, [pairs]);

  // Währungsumrechnung: Hole Umrechnungsfaktor von /exchange-rates
  useEffect(() => {
    if (vsCurrency.toLowerCase() === 'usd') {
      setConversionFactor(1);
    } else {
      api
        .get('/exchange-rates')
        .then(({ data }) => {
          const rates = data.rates;
          if (rates && rates[vsCurrency.toLowerCase()] && rates['usd']) {
            const factor =
              rates[vsCurrency.toLowerCase()].value / rates['usd'].value;
            setConversionFactor(factor);
          } else {
            setConversionFactor(1);
          }
        })
        .catch((err) => {
          console.error(err);
          setConversionFactor(1);
        });
    }
  }, [vsCurrency]);

  // Sortierfunktion
  const sortPairs = (pairsArray, order) => {
    return [...pairsArray].sort((a, b) => {
      switch (order) {
        case 'openInterest_desc':
          return Number(b.open_interest) - Number(a.open_interest);
        case 'openInterest_asc':
          return Number(a.open_interest) - Number(b.open_interest);
        case 'volume_desc':
          return Number(b.volume_24h) - Number(a.volume_24h);
        case 'volume_asc':
          return Number(a.volume_24h) - Number(b.volume_24h);
        case 'price_desc':
          return Number(b.price) - Number(a.price);
        case 'price_asc':
          return Number(a.price) - Number(b.price);
        case 'change_desc':
          return Number(b.price_percentage_change_24h) - Number(a.price_percentage_change_24h);
        case 'change_asc':
          return Number(a.price_percentage_change_24h) - Number(b.price_percentage_change_24h);
        case 'symbol_asc':
          return a.symbol.localeCompare(b.symbol);
        case 'symbol_desc':
          return b.symbol.localeCompare(a.symbol);
        default:
          return 0;
      }
    });
  };

  const sortedPairs = sortPairs(exchangePairs, sortOrder);

  // Pagination
  const indexOfLastPair = currentPage * pairsPerPage;
  const indexOfFirstPair = indexOfLastPair - pairsPerPage;
  const visiblePairs = sortedPairs.slice(indexOfFirstPair, indexOfLastPair);
  const totalPagesCalc = sortedPairs.length > 0 ? Math.ceil(sortedPairs.length / pairsPerPage) : 1;

  // Sortier-Icon
  const renderSortIcon = (columnPrefix) => {
    if (!sortOrder.startsWith(columnPrefix)) return null;
    return sortOrder.endsWith('_asc') ? (
      <FaChevronUp className="sort-icon" />
    ) : (
      <FaChevronDown className="sort-icon" />
    );
  };

  const handleSortChange = (newSort) => {
    setSortOrder(newSort);
    setCurrentPage(1);
  };

  const handlePairsPerPageChange = (newVal) => {
    setPairsPerPage(parseInt(newVal, 10));
    setCurrentPage(1);
  };

  const handleBack = () => {
    navigate(-1);
  };

  // Automatischen Reload bei Sichtbarkeitswechsel
  useEffect(() => {
    function handleVisibilityChange() {
      if (!document.hidden) {
        if (pairs && Array.isArray(pairs)) {
          setExchangePairs(pairs);
        }
      }
    }
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [pairs]);

  return (
    <SkeletonTheme baseColor="#ccc" highlightColor="#eee">
      <div className="futures-exchange-details-container">
        <h2 className="futures-exchange-details-title">
          {market ? (
            <Trans id="title.exchangeDetails">{market} – Alle Handelspaare</Trans>
          ) : (
            <Trans id="title.exchangeDetailsFallback">Exchange Details</Trans>
          )}
        </h2>
        <button className="back-button" onClick={handleBack}>
          <Trans id="btn.back">Zurück</Trans>
        </button>
        {market && (
          <div className="exchange-header">
            <h3>{market}</h3>
          </div>
        )}
        {loading ? (
          <div className="loading-spinner-container">
            <div className="spinner"></div>
            <p>
              <Trans id="msg.loading">Lade Daten...</Trans>
            </p>
          </div>
        ) : (
          <>
            <div className="exchange-summary">
              <div className="summary-item">
                <span className="summary-label">
                  <Trans id="label.totalOpenInterest">Gesamtes Open Interest:</Trans>
                </span>
                <span className="summary-value">
                  {formatLargeValue(
                    exchangePairs.reduce((sum, pair) => sum + Number(pair.open_interest || 0), 0) *
                      conversionFactor,
                    vsCurrency,
                    false,
                    true,
                    true,
                    ''
                  )}
                </span>
              </div>
              <div className="summary-item">
                <span className="summary-label">
                  <Trans id="label.totalVolume">Gesamtes 24h Volumen:</Trans>
                </span>
                <span className="summary-value">
                  {formatLargeValue(
                    exchangePairs.reduce((sum, pair) => sum + Number(pair.volume_24h || 0), 0) *
                      conversionFactor,
                    vsCurrency,
                    false,
                    true,
                    true,
                    ''
                  )}
                </span>
              </div>
            </div>

            <div className="details-controls">
              <div className="details-sort-controls">
                <label>
                  <Trans id="label.sortBy">Sortieren nach:</Trans>
                </label>
                <SingleSelectNoSearch
                  options={sortOptions}
                  selectedValue={sortOrder}
                  onChange={handleSortChange}
                  placeholder="Sortieren"
                  minWidth={150}
                />
              </div>
              <div className="details-per-page">
                <label>
                  <Trans id="label.entriesPerPage">Einträge pro Seite:</Trans>
                </label>
                <SingleSelectNoSearch
                  options={pairsPerPageOptions}
                  selectedValue={String(pairsPerPage)}
                  onChange={handlePairsPerPageChange}
                  placeholder="Pro Seite"
                  minWidth={100}
                />
              </div>
            </div>

            {visiblePairs.length > 0 ? (
              <table className="futures-exchange-details-table">
                <thead>
                  <tr>
                    <th>
                      <Trans id="label.symbol">Symbol</Trans> {renderSortIcon('symbol')}
                    </th>
                    <th>
                      <Trans
                        id="label.priceWithCurrency"
                        values={{ currency: vsCurrency.toUpperCase() }}
                      >
                        Preis ({vsCurrency.toUpperCase()})
                      </Trans>{' '}
                      {renderSortIcon('price')}
                    </th>
                    <th>
                      <Trans id="label.change24h">24h %</Trans> {renderSortIcon('change')}
                    </th>
                    <th>
                      <Trans
                        id="label.openInterestWithCurrency"
                        values={{ currency: vsCurrency.toUpperCase() }}
                      >
                        Open Interest ({vsCurrency.toUpperCase()})
                      </Trans>{' '}
                      {renderSortIcon('openInterest')}
                    </th>
                    <th>
                      <Trans
                        id="label.volumeWithCurrency"
                        values={{ currency: vsCurrency.toUpperCase() }}
                      >
                        24h Volumen ({vsCurrency.toUpperCase()})
                      </Trans>{' '}
                      {renderSortIcon('volume')}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {visiblePairs.map((pair, index) => (
                    <tr key={`${pair.symbol}-${index}`}>
                      <td data-label={t({ id: 'label.symbolMobile', message: 'Symbol' })}>
                        {pair.symbol}
                      </td>
                      <td data-label={t({ id: 'label.priceMobile', message: 'Preis' })}>
                        {formatPrice(Number(pair.price) * conversionFactor, vsCurrency, true, true, '')}
                      </td>
                      <td data-label={t({ id: 'label.change24hMobile', message: '24h Change' })}>
                        {formatChangePercentage(pair.price_percentage_change_24h)}
                      </td>
                      <td data-label={t({ id: 'label.openInterestMobile', message: 'Open Interest' })}>
                        {formatLargeValue(
                          Number(pair.open_interest) * conversionFactor,
                          vsCurrency,
                          false,
                          true,
                          true,
                          ''
                        )}
                      </td>
                      <td data-label={t({ id: 'label.volumeMobile', message: '24h Volumen' })}>
                        {formatLargeValue(
                          Number(pair.volume_24h) * conversionFactor,
                          vsCurrency,
                          false,
                          true,
                          true,
                          ''
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p>
                <Trans id="msg.noPairs">Keine Handelspaare verfügbar.</Trans>
              </p>
            )}

            {sortedPairs.length > pairsPerPage && (
              <div className="pagination-controls bottom-pagination-controls">
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPagesCalc}
                  onPageChange={setCurrentPage}
                />
              </div>
            )}
          </>
        )}
      </div>
    </SkeletonTheme>
  );
};

export default FuturesExchangeDetails;
