// src/components/NewlyListedCoinRow.js
import React, { useEffect, useRef, useState } from 'react';
import { formatPrice } from '../utils/formatPrice';
import { formatLargeValue } from '../utils/formatLargeValue';
import { formatChangePercentage } from '../utils/formatChangePercentage';
import { formatDate } from '../utils/formatDate';
import { t } from '@lingui/macro';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import FavoriteStar from './CoinDetails/FavoriteStar';

const NewlyListedCoinRow = React.memo(({ coin, onClick, vsCurrency, loading }) => {
  const prevCoinRef = useRef(coin);
  const [highlightPrice, setHighlightPrice] = useState(null);
  const [highlightVolume, setHighlightVolume] = useState(null);

  const isMobile = typeof window !== 'undefined' && window.innerWidth <= 768;

  useEffect(() => {
    if (loading) return;
    const prevCoin = prevCoinRef.current;
    if (!prevCoin || !coin) {
      prevCoinRef.current = coin;
      return;
    }

    const checkChange = (field, setHighlight) => {
      if (prevCoin[field] !== coin[field]) {
        const direction = coin[field] > prevCoin[field] ? 'up' : 'down';
        setHighlight(direction);
        setTimeout(() => setHighlight(null), 1000);
      }
    };

    checkChange('price', setHighlightPrice);
    checkChange('volume', setHighlightVolume);

    prevCoinRef.current = coin;
  }, [coin, loading]);

  if (loading) {
    return (
      <tr>
        <td><Skeleton width={100} /></td>
        <td><Skeleton width={50} /></td>
        <td><Skeleton width={40} /></td>
        <td><Skeleton width={60} /></td>
        <td><Skeleton width={80} /></td>
      </tr>
    );
  }

  const priceChangeFormatted = formatChangePercentage(coin.price_change_percentage_24h);

  return (
    <tr onClick={() => onClick(coin.id)}>
      <td className="coin-info-cell">
        <img src={coin.image} alt={coin.name} className="coin-logo" />
        <span className="coin-name">{coin.name}</span>
        <span className="coin-symbol desktop-only">
          ({coin.symbol.toUpperCase()})
        </span>
        {/* FavoriteStar einfügen – Klicks auf den Stern lösen nicht den Zeilen-OnClick aus */}
        <span onClick={(e) => e.stopPropagation()}>
          <FavoriteStar coinId={coin.id} coinData={coin} />
        </span>
      </td>
      <td data-label={t({ id: "label.priceMobile", message: "💲 Preis" })}>
        <span className={highlightPrice ? `highlight-${highlightPrice}` : ''}>
          {formatPrice(coin.price, vsCurrency)}
        </span>
      </td>
      <td data-label={t({ id: "label.24hChangeMobile", message: "🔄 24h %" })}>
        {priceChangeFormatted}
      </td>
      <td data-label={t({ id: "label.24hVolumeMobile", message: "💹 24h Volumen" })}>
        <span className={highlightVolume ? `highlight-${highlightVolume}` : ''}>
          {formatLargeValue(coin.volume, vsCurrency)}
        </span>
      </td>
      <td data-label={t({ id: "label.activatedAtMobile", message: "🚀 Aktiviert am" })}>
        {coin.activated_at && coin.activated_at !== 'N/A'
          ? formatDate(coin.activated_at, isMobile)
          : 'N/A'}
      </td>
    </tr>
  );
});

export default NewlyListedCoinRow;
