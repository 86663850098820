import React, { useState, useRef, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import './CryptoJourney.css';

// 3D-Imports
import { Canvas, useLoader, useFrame } from '@react-three/fiber';
import { OrbitControls, Stars } from '@react-three/drei';
import * as THREE from 'three';
import { EffectComposer, Bloom } from '@react-three/postprocessing';

// Lokales Bitcoin-Bild
import btcTextureUrl from './assets/btc.png';

/**
 * CoinMesh rendert die zentrale 3D-Münze.
 */
const CoinMesh = ({ scaleFactor }) => {
  const meshRef = useRef();
  const texture = useLoader(THREE.TextureLoader, btcTextureUrl);
  return (
    <mesh
      ref={meshRef}
      rotation={[-Math.PI / 2, Math.PI / 2, 0]}
      scale={[scaleFactor, scaleFactor, scaleFactor]}
      castShadow
      receiveShadow
    >
      <cylinderGeometry args={[1, 1, 0.2, 64]} />
      <meshStandardMaterial 
        map={texture}
        metalness={0.4}
        roughness={0.3}
        clearcoat={0.5}
      />
    </mesh>
  );
};

/**
 * InteractiveIntro bietet ein vollflächiges, interaktives Intro.
 */
const InteractiveIntro = ({ onComplete }) => {
  const [visited, setVisited] = useState([]);
  const [coinScale, setCoinScale] = useState(0.8);

  useEffect(() => {
    const handleResize = () => {
      setCoinScale(window.innerWidth < 480 ? 0.6 : 0.8);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Dynamische Hotspot-Positionen: Abstand relativ zur Münzgröße
  const offset = coinScale + 1; // kleiner Abstand zur Münze
  const hotspots = [
    { id: 'dezentral', position: [offset, 0, 0], text: 'Dezentral: Keine zentrale Instanz kontrolliert das Netzwerk.' },
    { id: 'sicherheit', position: [-offset, 0, 0], text: 'Sicherheit: Kryptographische Schutzmaßnahmen gewährleisten deine Sicherheit.' },
    { id: 'transparenz', position: [0, offset, 0], text: 'Transparenz: Alle Transaktionen sind öffentlich nachvollziehbar.' },
  ];

  const handleHotspotClick = (id, e) => {
    e.stopPropagation();
    if (!visited.includes(id)) {
      setVisited(prev => [...prev, id]);
    }
  };

  const allVisited = visited.length === hotspots.length;

  return (
    <div className="interactive-intro-wrapper">
      <Canvas className="crypto-canvas" camera={{ position: [0, 0, 8], fov: 50 }}>
        <Stars radius={100} depth={50} count={5000} factor={4} saturation={0} fade />
        <ambientLight intensity={0.8} />
        <directionalLight position={[5, 5, 5]} intensity={1} />
        <EffectComposer>
          <Bloom intensity={1.2} luminanceThreshold={0.3} luminanceSmoothing={0.4} height={700} />
        </EffectComposer>
        <CoinMesh scaleFactor={coinScale} />
        {hotspots.map(hotspot => (
          <mesh key={hotspot.id} position={hotspot.position} onClick={(e) => handleHotspotClick(hotspot.id, e)}>
            <sphereGeometry args={[0.2, 32, 32]} />
            <meshStandardMaterial 
              color={visited.includes(hotspot.id) ? 'green' : 'orange'}
              emissive={visited.includes(hotspot.id) ? 'green' : 'orange'}
              emissiveIntensity={0.7}
            />
          </mesh>
        ))}
        <OrbitControls enableZoom={false} enablePan={false} />
      </Canvas>
      <div className="intro-overlay">
        {hotspots.map(hotspot =>
          visited.includes(hotspot.id) && (
            <div key={hotspot.id} className="hotspot-info">
              {hotspot.text}
            </div>
          )
        )}
        {allVisited && (
          <div className="continue-button" onClick={onComplete}>
            (Weiter)
          </div>
        )}
        <div className="instruction-text">
          (Klicke auf die leuchtenden Punkte, um mehr zu erfahren)
        </div>
      </div>
    </div>
  );
};

/**
 * Die einzelnen Schritte der CryptoJourney.
 */
const steps = [
  {
    title: "Was sind Kryptowährungen?",
    content: (onInteract) => (
      <div className="step-placeholder">
        <InteractiveIntro onComplete={onInteract} />
      </div>
    )
  },
  {
    title: "Wie funktioniert die Blockchain?",
    content: () => (
      <div className="step-placeholder">
        <p>Die Blockchain ist ein dezentrales, digitales Kassenbuch, in dem alle Transaktionen fälschungssicher gespeichert werden.</p>
        <div className="instruction-text">(Erfahre, wie Daten sicher gespeichert werden)</div>
      </div>
    )
  },
  {
    title: "Dezentral und Sicher",
    content: () => (
      <div className="step-placeholder">
        <p>Das System läuft über ein Netzwerk unabhängiger Computer – das macht es robust und transparent.</p>
        <div className="instruction-text">(Verstehe, warum Dezentralität Vertrauen schafft)</div>
      </div>
    )
  },
  {
    title: "Bitcoin: Der Pionier",
    content: () => (
      <div className="step-placeholder">
        <p>Bitcoin war die erste Kryptowährung und hat die Finanzwelt revolutioniert.</p>
        <div className="instruction-text">(Erkunde die Ursprünge des digitalen Geldes)</div>
      </div>
    )
  },
  {
    title: "Deine Reise beginnt hier",
    content: () => (
      <div className="step-placeholder cta">
        <p>Bist du bereit, tiefer in die Welt der Kryptowährungen einzutauchen?</p>
        <div className="instruction-text">(Klicke hier, um zur Hauptseite zu gelangen)</div>
      </div>
    )
  }
];

const CryptoJourney = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const navigate = useNavigate();

  const handleInteractionComplete = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(prev => prev + 1);
    } else {
      navigate("/");
    }
  };

  const currentContent = steps[currentStep].content(handleInteractionComplete);

  return (
    <div className="crypto-journey-wrapper">
      <AnimatePresence exitBeforeEnter>
        <motion.div 
          key={currentStep}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.6 }}
        >
          <h2 className="step-title">{steps[currentStep].title}</h2>
          <div>{currentContent}</div>
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default CryptoJourney;
