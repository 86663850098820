// src/components/NewlyListedCoins.js

import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/NewlyListedCoins.css';
import { Trans } from '@lingui/macro';
import NewlyListedCoinRow from './NewlyListedCoinRow';
import SingleSelectNoSearch from '../utils/SingleSelectNoSearch';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

// Importiere die zentrale Pagination-Komponente aus StyledButton
import { Pagination } from '../utils/StyledButton';
import api from '../services/api'; // <-- Neu: Unser Axios-Objekt

function NewlyListedCoins() {
  const [coins, setCoins] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Währungs-Setting (nur aus localStorage)
  const [vsCurrency, setVsCurrency] = useState('usd');

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [coinsPerPage, setCoinsPerPage] = useState(25);
  const [totalPages, setTotalPages] = useState(1);

  const prevDataRef = useRef([]);
  const navigate = useNavigate();

  // Felder, die wir tracken wollen
  const fieldsToCheck = [
    'price',
    'volume',
    'price_change_percentage_24h',
    'image',
    'name',
    'symbol',
    'activated_at',
  ];

  // Refs für Retry-Logik
  const retryCountRef = useRef(0);
  const maxRetries = 5;

  // ---------------------------------------------------------
  // 1) updateCoins
  // ---------------------------------------------------------
  const updateCoins = (newData) => {
    const oldData = prevDataRef.current || [];
    const oldMap = {};
    oldData.forEach((c) => {
      oldMap[c.id] = c;
    });

    let changedAny = false;
    const finalCoins = newData.map((newCoin) => {
      const oldCoin = oldMap[newCoin.id];
      if (!oldCoin) {
        changedAny = true;
        return newCoin;
      }

      const updatedCoin = { ...oldCoin };
      let changed = false;
      for (const field of fieldsToCheck) {
        if (oldCoin[field] !== newCoin[field]) {
          changed = true;
          updatedCoin[field] = newCoin[field];
        }
      }

      if (!changed) {
        return oldCoin;
      }
      changedAny = true;
      return updatedCoin;
    });

    if (finalCoins.length !== oldData.length) {
      changedAny = true;
    }

    if (changedAny) {
      setCoins(finalCoins);
      prevDataRef.current = finalCoins;
    }
  };

  // ---------------------------------------------------------
  // 2) fetchData: Lädt die neu gelisteten Coins
  // ---------------------------------------------------------
  const fetchData = async (showLoading = false) => {
    if (showLoading) {
      setLoading(true);
      setError(null);
    }

    try {
      // GET /newly-listed-coins?page=...&per_page=...&currency=...
      const { data } = await api.get('/newly-listed-coins', {
        params: {
          page: currentPage,
          per_page: coinsPerPage,
          currency: vsCurrency,
        },
      });

      updateCoins(data.coins || []);
      setTotalPages(data.totalPages || 1);

      // Erfolgreicher Abruf: Retry-Zähler zurücksetzen
      retryCountRef.current = 0;
      if (showLoading) {
        setLoading(false);
      }
    } catch (err) {
      console.error('fetchData error:', err);

      // Prüfen, ob wir einen Netzwerkfehler haben (err.response fehlt)
      const isNetworkError =
        !err.response && (err.message.includes('Network') || err.message.includes('fetch'));

      if (isNetworkError) {
        // Exponential Backoff
        if (retryCountRef.current < maxRetries) {
          retryCountRef.current++;
          const retryDelay = Math.pow(2, retryCountRef.current) * 1000; // z. B. 2s, 4s, 8s, ...
          console.log(
            `fetchData: Erneuter Versuch in ${retryDelay / 1000} Sekunden (Versuch ${retryCountRef.current} von ${maxRetries})`
          );
          setTimeout(() => {
            fetchData(showLoading);
          }, retryDelay);
          return;
        } else {
          console.log('Max retries erreicht, erneuter Versuch in 5 Sekunden...');
          setTimeout(() => {
            fetchData(showLoading);
          }, 5000);
        }
      } else {
        console.log('Nicht-Netzwerkfehler, erneuter Versuch in 5 Sekunden...');
        setTimeout(() => {
          fetchData(showLoading);
        }, 5000);
      }
      if (showLoading) {
        setLoading(false);
      }
    }
  };

  // ---------------------------------------------------------
  // 3) Lifecycle - Laden der Währung aus localStorage
  // ---------------------------------------------------------
  useEffect(() => {
    const savedCurrency = localStorage.getItem('vsCurrency') || 'usd';
    setVsCurrency(savedCurrency);
  }, []);

  // ---------------------------------------------------------
  // 4) Sobald vsCurrency, currentPage, coinsPerPage geändert werden
  //    => speichern + neu laden + Polling
  // ---------------------------------------------------------
  useEffect(() => {
    localStorage.setItem('vsCurrency', vsCurrency);
    fetchData(true);

    // Polling-Interval => fetchData(false)
    const interval = setInterval(() => {
      fetchData(false);
    }, 11000);

    return () => clearInterval(interval);
  }, [vsCurrency, currentPage, coinsPerPage]);

  // ---------------------------------------------------------
  // 5) Automatischer Reload bei Sichtbarkeitswechsel
  // ---------------------------------------------------------
  useEffect(() => {
    function handleVisibilityChange() {
      if (!document.hidden) {
        fetchData(true);
      }
    }
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  // ---------------------------------------------------------
  // 6) Eventhandler
  // ---------------------------------------------------------
  const handleCoinClick = (coinId) => {
    navigate(`/coin-details?coin=${coinId}&from=3`);
  };

  const handlePerPageChange = (newValAsString) => {
    const newVal = parseInt(newValAsString, 10);
    setCoinsPerPage(newVal);
    setCurrentPage(1);
  };

  // ---------------------------------------------------------
  // Rendering
  // ---------------------------------------------------------
  return (
    <SkeletonTheme baseColor="#ccc" highlightColor="#eee">
      <div className="newly-listed-container">
        <h2 className="title">
          <Trans id="title.newlyListedCoins">Neu gelistete Coins</Trans>
        </h2>

        <p className="intro-text">
          <Trans id="newListings.intro">
            Neu gelistet und brandaktuell – hier findest du alle Coins, die gerade frisch in den
            Handel eingetreten sind. Entdecke die neuesten Markttrends und informiere dich über
            Preise, Volumen und mehr.
          </Trans>
        </p>

        <div className="newly-listed-controls">
          {/* Anzahl Einträge pro Seite */}
          <div className="newly-listed-coins-per-page">
            {loading ? (
              <Skeleton width={120} />
            ) : (
              <label>
                <Trans id="label.entriesPerPage">Entries per page:</Trans>
              </label>
            )}
            {loading ? (
              <Skeleton width={60} height={30} />
            ) : (
              <SingleSelectNoSearch
                options={['25', '50', '100']}
                selectedValue={String(coinsPerPage)}
                onChange={handlePerPageChange}
                placeholder="Einträge pro Seite"
              />
            )}
          </div>
        </div>

        {error && <div className="error-message">{error}</div>}

        {!error && (
          <>
            {loading ? (
              // --- Skeleton-Ansicht ---
              <table className="newly-listed-table">
                <thead>
                  <tr>
                    {/* Nur 5 Spalten als Skeleton-Platzhalter */}
                    <th>
                      <Skeleton width={80} />
                    </th>
                    <th>
                      <Skeleton width={80} />
                    </th>
                    <th>
                      <Skeleton width={80} />
                    </th>
                    <th>
                      <Skeleton width={80} />
                    </th>
                    <th>
                      <Skeleton width={80} />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Array.from({ length: coinsPerPage }).map((_, i) => (
                    <NewlyListedCoinRow key={i} loading={true} />
                  ))}
                </tbody>
              </table>
            ) : (
              // --- Normale Ansicht ---
              <>
                {coins.length > 0 ? (
                  <>
                    <table className="newly-listed-table">
                      <thead>
                        <tr>
                          <th>
                            <Trans id="label.name">Name</Trans>
                          </th>
                          <th>
                            <Trans id="label.price">Preis</Trans>
                          </th>
                          <th>
                            <Trans id="label.24hChange">24h %</Trans>
                          </th>
                          <th>
                            <Trans id="label.24hVolume">24h Volumen</Trans>
                          </th>
                          <th>
                            <Trans id="label.activatedAt">Aktiviert am</Trans>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {coins.map((coin) => (
                          <NewlyListedCoinRow
                            key={coin.id}
                            coin={coin}
                            vsCurrency={vsCurrency}
                            onClick={handleCoinClick}
                            loading={false}
                          />
                        ))}
                      </tbody>
                    </table>

                    {/* Pagination */}
                    {totalPages > 1 && (
                      <div className="pagination-controls bottom-pagination-controls">
                        <Pagination
                          currentPage={currentPage}
                          totalPages={totalPages}
                          onPageChange={setCurrentPage}
                        />
                      </div>
                    )}
                  </>
                ) : (
                  <div>
                    <Trans id="message.noCoinsFound">Keine neu gelisteten Coins gefunden.</Trans>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </SkeletonTheme>
  );
}

export default NewlyListedCoins;
