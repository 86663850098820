// src/components/admin/AdminNotifications.js
import React, { useState, useEffect } from 'react';
import { Trans, t } from '@lingui/macro';
import { toast } from 'react-toastify';
import AdminSubmenu from './AdminSubmenu';
import api from '../../services/api'; // <-- Neue Axios-Instanz
import { formatDate } from '../../utils/formatDate';
import '../../styles/AdminNotifications.css';

// React Icons
import { FaCheck, FaTimes } from 'react-icons/fa';

/**
 * Übersetzungs-/Fehlermapping
 */
function translateMessageKey(key) {
  switch (key) {
    case 'error.dbQueryFailed':
      return t({ id: 'error.dbQueryFailed', message: 'Datenbankfehler.' });
    case 'error.titleMessageRequired':
      return t({ id: 'error.titleMessageRequired', message: 'Titel und Nachricht benötigt.' });
    case 'success.notificationAllSent':
      return t({ id: 'success.notificationAllSent', message: 'Benachrichtigungen an ausgewählte Gruppen gesendet.' });
    case 'success.deletedNotification':
      return t({ id: 'success.deletedNotification', message: 'Benachrichtigung gelöscht.' });
    case 'success.deletedAllNotifications':
      return t({ id: 'success.deletedAllNotifications', message: 'Alle Benachrichtigungen gelöscht.' });
    case 'success.deletedUserNotifications':
      return t({ id: 'success.deletedUserNotifications', message: 'Alle Benachrichtigungen des Users gelöscht.' });
    default:
      return key; // Fallback
  }
}

/**
 * 403 => "Keine Berechtigung!"
 */
function handleBackendError(err) {
  if (err.response) {
    const { status, data } = err.response;
    if (status === 403) {
      toast.error(t({ id: 'error.noPermission', message: 'Keine Berechtigung!' }));
    } else {
      // Anderenfalls => versuche messageKey
      toast.error(translateMessageKey(data?.messageKey) || 'Fehler.');
    }
  } else {
    // Netzwerk-/Serverfehler
    toast.error('Fehler bei der Anfrage oder Netzwerkproblem.');
  }
}

const AdminNotifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Sammel-Senden-Modal
  const [showSendAllModal, setShowSendAllModal] = useState(false);
  const [sendAllTitle, setSendAllTitle] = useState('');
  const [sendAllMessage, setSendAllMessage] = useState('');

  // Gruppen-Checkbox
  const groupOptions = [
    { label: 'Admin', value: 'admin' },
    { label: 'Superuser', value: 'superuser' },
    { label: 'Free', value: 'free' },
    { label: 'Pro', value: 'pro' },
  ];
  const [selectedGroups, setSelectedGroups] = useState([]);

  // Beim Mount => Notifications laden
  useEffect(() => {
    fetchNotifications();
  }, []);

  // 1) Notifications laden
  const fetchNotifications = () => {
    setIsLoading(true);
    api.get('/admin/notifications')
      .then((resp) => {
        setIsLoading(false);
        setNotifications(resp.data.notifications || []);
      })
      .catch((err) => {
        setIsLoading(false);
        console.error('Fehler beim Laden der Benachrichtigungen:', err);
        handleBackendError(err);
      });
  };

  // 2) Delete single Notification
  const handleDeleteNotification = (notifId) => {
    if (!window.confirm('Benachrichtigung wirklich löschen?')) return;

    api.delete(`/admin/notifications/${notifId}`)
      .then(() => {
        toast.success(translateMessageKey('success.deletedNotification'));
        fetchNotifications();
      })
      .catch((err) => {
        console.error('Fehler beim Löschen einer Notif:', err);
        handleBackendError(err);
      });
  };

  // 3) Delete all
  const handleDeleteAll = () => {
    if (!window.confirm('Wirklich alle Benachrichtigungen löschen?')) return;

    // Du hast /api/admin/notifications?all=true => wir verwenden axios param:
    api.delete('/admin/notifications', { params: { all: true } })
      .then(() => {
        toast.success(translateMessageKey('success.deletedAllNotifications'));
        fetchNotifications();
      })
      .catch((err) => {
        console.error('Fehler beim Löschen aller:', err);
        handleBackendError(err);
      });
  };

  // 4) Delete all for a user
  const handleDeleteAllForUser = (userId) => {
    if (!window.confirm('Alle Benachrichtigungen dieses Users löschen?')) return;

    api.delete('/admin/notifications', {
      params: { userId }
    })
      .then(() => {
        toast.success(translateMessageKey('success.deletedUserNotifications'));
        fetchNotifications();
      })
      .catch((err) => {
        console.error('Fehler beim Löschen der User-Notifs:', err);
        handleBackendError(err);
      });
  };

  // 5) Modal öffnen: "An Gruppen senden"
  const openSendAllModal = () => {
    setSendAllTitle('');
    setSendAllMessage('');
    setSelectedGroups([]);
    setShowSendAllModal(true);
  };

  // 6) Checkbox inline-Change
  const handleCheckboxChange = (value) => {
    setSelectedGroups((prev) => {
      if (prev.includes(value)) {
        return prev.filter((v) => v !== value);
      } else {
        return [...prev, value];
      }
    });
  };

  // 7) POST /send-all
  const handleSendAll = () => {
    if (!sendAllTitle.trim() || !sendAllMessage.trim()) {
      toast.error(t({ id: 'error.titleMessageRequired', message: 'Titel und Nachricht benötigt.' }));
      return;
    }
    const body = {
      title: sendAllTitle,
      message: sendAllMessage,
      groups: selectedGroups
    };

    api.post('/admin/notifications/send-all', body)
      .then(() => {
        toast.success(translateMessageKey('success.notificationAllSent'));
        fetchNotifications();
      })
      .catch((err) => {
        console.error('Fehler beim Senden an Gruppen:', err);
        handleBackendError(err);
      })
      .finally(() => {
        setShowSendAllModal(false);
        setSendAllTitle('');
        setSendAllMessage('');
        setSelectedGroups([]);
      });
  };

  return (
    <div className="admin-notifications-container">
      <AdminSubmenu />

      <h2>
        <Trans id="admin.notifications.header.title">Benachrichtigungen verwalten</Trans>
      </h2>

      {/* Buttons oben */}
      <div style={{ marginBottom: '20px' }}>
        <button onClick={openSendAllModal} className="btn-sendall">
          <Trans id="admin.notifications.sendAll">An Gruppen senden</Trans>
        </button>
        <button onClick={handleDeleteAll} className="btn-deleteall">
          <Trans id="admin.notifications.deleteAll">Alle löschen</Trans>
        </button>
      </div>

      {isLoading ? (
        <p><Trans id="admin.notifications.loading">Lade Benachrichtigungen...</Trans></p>
      ) : (
        <table className="admin-notifications-table">
          <thead>
            <tr>
              <th>ID</th>
              <th><Trans id="admin.notifications.user">Benutzer</Trans></th>
              <th><Trans id="admin.notifications.title">Titel</Trans></th>
              <th><Trans id="admin.notifications.message">Nachricht</Trans></th>
              <th><Trans id="admin.notifications.date">Zeit</Trans></th>
              <th><Trans id="admin.notifications.status">Gelesen?</Trans></th>
              <th><Trans id="admin.notifications.actions">Aktionen</Trans></th>
            </tr>
          </thead>
          <tbody>
            {notifications.map((n) => (
              <tr key={n.id}>
                <td>{n.id}</td>
                <td>{n.username || '-'}</td>
                <td>{n.title}</td>
                <td>{n.message}</td>
                <td>{formatDate(n.created_at, false)}</td>
                <td>
                  {n.is_read
                    ? <FaCheck style={{ color: 'green' }} />
                    : <FaTimes style={{ color: 'red' }} />
                  }
                </td>
                <td>
                  <button
                    className="btn-notification-action"
                    onClick={() => handleDeleteNotification(n.id)}
                  >
                    <Trans id="admin.notifications.deleteOne">Löschen</Trans>
                  </button>
                  <button
                    className="btn-notification-action"
                    onClick={() => handleDeleteAllForUser(n.user_id)}
                  >
                    <Trans id="admin.notifications.deleteUserAll">Alle dieses Users</Trans>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {/* Modal: Gruppen-Senden */}
      {showSendAllModal && (
        <div className="admin-notifications-modal-overlay">
          <div className="admin-notifications-modal-content">
            <h3><Trans id="admin.notifications.sendAllModalTitle">An Gruppen senden</Trans></h3>

            <div className="form-group">
              <label><Trans id="admin.notifications.title">Titel</Trans></label>
              <input
                type="text"
                value={sendAllTitle}
                onChange={(e) => setSendAllTitle(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label><Trans id="admin.notifications.message">Nachricht</Trans></label>
              <textarea
                rows={4}
                value={sendAllMessage}
                onChange={(e) => setSendAllMessage(e.target.value)}
              />
            </div>

            <div className="form-group" style={{ textAlign: 'left' }}>
              <label><Trans id="admin.notifications.selectGroups">Gruppen auswählen:</Trans></label>
              <div style={{ marginTop: '8px' }}>
                {groupOptions.map((opt) => (
                  <label key={opt.value} style={{ display: 'block', margin: '2px 0' }}>
                    <input
                      type="checkbox"
                      checked={selectedGroups.includes(opt.value)}
                      onChange={() => handleCheckboxChange(opt.value)}
                    />
                    {' '}{opt.label}
                  </label>
                ))}
              </div>
            </div>

            <div className="modal-buttons">
              <button onClick={handleSendAll}>
                <Trans id="button.send">Senden</Trans>
              </button>
              <button
                onClick={() => {
                  setShowSendAllModal(false);
                  setSendAllTitle('');
                  setSendAllMessage('');
                  setSelectedGroups([]);
                }}
              >
                <Trans id="modal.cancel">Abbrechen</Trans>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminNotifications;
