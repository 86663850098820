// src/components/VerifyEmail.js
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import '../styles/VerifyEmail.css';
import { t } from '@lingui/macro';
import { StyledButton } from '../utils/StyledButton';
import api from '../services/api'; // Neu: unser zentraler Axios-Client

function translateMessageKey(messageKey) {
  switch (messageKey) {
    case 'success.emailVerified':
      return t({
        id: 'success.emailVerified',
        message: 'E-Mail erfolgreich bestätigt. Du kannst dich jetzt einloggen.',
      });
    case 'error.invalidLink':
      return t({ id: 'error.invalidLink', message: 'Ungültiger Bestätigungslink.' });
    case 'error.dbQueryFailed':
      return t({ id: 'error.dbQueryFailed', message: 'Fehler bei der Datenbankabfrage.' });
    case 'error.invalidOrExpiredVerifyLink':
      return t({
        id: 'error.invalidOrExpiredVerifyLink',
        message: 'Der Bestätigungslink ist ungültig oder abgelaufen.',
      });
    case 'error.updatingUser':
      return t({
        id: 'error.updatingUser',
        message: 'Fehler beim Aktualisieren des Benutzers.',
      });
    case 'error.internalServerError':
      return t({ id: 'error.internalServerError', message: 'Interner Serverfehler' });
    default:
      return t({
        id: 'error.emailVerifyFailed',
        message: 'E-Mail-Bestätigung fehlgeschlagen.',
      });
  }
}

const VerifyEmail = () => {
  const location = useLocation();
  const [message, setMessage] = useState(
    t({ id: 'message.verifying', message: 'Bestätigung läuft...' })
  );
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const token = query.get('token');
    const id = query.get('id');

    if (!token || !id) {
      const invalidLinkMsg = translateMessageKey('error.invalidLink');
      setMessage(invalidLinkMsg);
      toast.error(invalidLinkMsg);
      return;
    }

    const verify = async () => {
      try {
        // Axios-Aufruf statt fetch
        const { data, status } = await api.get('/verify-email', {
          params: { token, id },
        });

        const localizedMsg = translateMessageKey(data.messageKey);
        if (status === 200) {
          setMessage(localizedMsg);
          setSuccess(true);
          toast.success(localizedMsg);
        } else {
          setMessage(localizedMsg);
          toast.error(localizedMsg);
        }
      } catch (error) {
        let errorMsg = translateMessageKey('error.internalServerError');
        if (error.response && error.response.data) {
          const respData = error.response.data;
          if (respData.messageKey) {
            errorMsg = translateMessageKey(respData.messageKey);
          }
        }
        setMessage(errorMsg);
        toast.error(errorMsg);
      }
    };

    verify();
  }, [location.search]);

  return (
    <div className="verify-email-container">
      <h2>{t({ id: 'title.emailVerification', message: 'E-Mail-Bestätigung' })}</h2>
      <p>{message}</p>
      {success && (
        <div className="links">
          <StyledButton variant="login" href="/login">
            {t({ id: 'link.loginHere', message: 'Hier einloggen' })}
          </StyledButton>
        </div>
      )}
    </div>
  );
};

export default VerifyEmail;
