// src/utils/StyledButton.jsx
import React, { useState, useEffect } from 'react';
import { i18n } from '@lingui/core';
import { FaGoogle, FaUserPlus } from 'react-icons/fa';
import { Trans, t } from '@lingui/macro';
import { StyledInput } from './StyledInput'; // Import des gestylten Eingabefelds

const baseButtonStyle = {
  padding: '8px 16px',
  fontSize: '0.9rem',
  border: '0.5px solid var(--primary-color)',
  backgroundColor: 'transparent', // Hintergrund immer transparent
  color: 'var(--text-color)',
  borderRadius: '4px',
  cursor: 'pointer',
  transition: 'box-shadow 0.2s ease, background-color 0.2s ease, color 0.2s ease',
  outline: 'none',
  transform: 'none', // Keine Transformation standardmäßig
  textDecoration: 'none', // Verhindert Unterstreichungen bei <a>
  display: 'inline-block', // Sorgt dafür, dass auch <a> wie ein Button wirkt
};

// Zusätzliche Effekt-Stile, die per Prop ausgewählt werden können
const effectStyles = {
  glow: {
    boxShadow: '0 0 10px var(--primary-color)',
  },
  pulse: {
    animation: 'pulseAnimation 1s infinite',
  },
  shake: {
    animation: 'shakeAnimation 0.5s ease-in-out',
  },
  bounce: {
    animation: 'bounceAnimation 1s infinite',
  },
};

// Füge die Keyframe-Animationen zum Dokument hinzu (sofern noch nicht vorhanden)
if (typeof document !== 'undefined' && !document.getElementById('styled-button-animations')) {
  const styleElement = document.createElement('style');
  styleElement.id = 'styled-button-animations';
  styleElement.innerHTML = `
    @keyframes pulseAnimation {
      0% { transform: scale(1); }
      50% { transform: scale(1.05); }
      100% { transform: scale(1); }
    }
    @keyframes shakeAnimation {
      0% { transform: translateX(0); }
      25% { transform: translateX(-5px); }
      50% { transform: translateX(5px); }
      75% { transform: translateX(-5px); }
      100% { transform: translateX(0); }
    }
    @keyframes bounceAnimation {
      0%, 100% { transform: translateY(0); }
      50% { transform: translateY(-10px); }
    }
  `;
  document.head.appendChild(styleElement);
}

export function StyledButton({
  variant = 'default',        // z. B. 'default', 'primary', 'secondary', 'danger', 'confirm', 'cancel', 'google', 'register', 'pagination', 'category'
  iconType,                   // Optional: Farbe "delete" oder "normal"
  translationKey,             // z. B. "button.saveProfile"
  defaultMessage,             // z. B. "Speichern"
  children,                   // Optional: direkter Buttoninhalt
  style,
  disabled,
  effect,                     // Optional: 'glow', 'pulse', 'shake', 'bounce'
  href,                       // Neu: Wenn vorhanden, wird der Button als Link gerendert.
  ...props
}) {
  const [isFocused, setIsFocused] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  // Standardinhalt: entweder Kinder oder über translationKey/defaultMessage
  const defaultContent = translationKey
    ? i18n._({ id: translationKey, message: defaultMessage })
    : defaultMessage;

  // Falls die Variante "google" oder "register" ist, wird automatisch das jeweilige Icon eingebunden.
  const buttonContent =
    variant === 'google' ? (
      <>
        <FaGoogle style={{ marginRight: '8px', fontSize: '20px' }} />
        {children || defaultContent}
      </>
    ) : variant === 'register' ? (
      <>
        <FaUserPlus style={{ marginRight: '8px', fontSize: '20px' }} />
        {children || defaultContent}
      </>
    ) : (
      children || defaultContent
    );

  // Variantspezifische Stile
  let variantStyles = {};
  switch (variant) {
    case 'primary':
      variantStyles = {
        border: '0.5px solid var(--btn-primary-bg)',
        color: 'var(--btn-primary-bg)',
        backgroundColor: 'transparent',
      };
      break;
    case 'secondary':
      variantStyles = {
        border: '0.5px solid var(--btn-secondary-bg)',
        color: 'var(--btn-secondary-bg)',
        backgroundColor: 'transparent',
      };
      break;
      case 'icon':
        // Basisstil für icon-Buttons – hier setzen wir noch keine Farbe, die erfolgt später per iconType
        variantStyles = {
          border: 'none',
          backgroundColor: 'transparent',
          display: 'inline-flex',
          alignItems: 'center',
          gap: '6px',
          fontSize: '0.9rem',
          padding: '4px 8px',
        };
        break;

    case 'login':
      variantStyles = {
        border: '0.5px solid #4682B4',  // Steel Blue
        color: '#4682B4',
        backgroundColor: 'transparent',
      };
      break;
    case 'google':
      variantStyles = {
        border: '0.5px solid var(--primary-color)',
        color: 'var(--primary-color)',
        backgroundColor: 'transparent',
      };
      break;
    case 'register':
      variantStyles = {
        border: '0.5px solid #4682B4',  // Steel Blue
        color: '#4682B4',
        backgroundColor: 'transparent',
      };
      break;
    case 'danger':
      variantStyles = {
        border: '0.5px solid red',
        color: 'red',
        backgroundColor: 'transparent',
      };
      break;
    case 'confirm':
      variantStyles = {
        border: '0.5px solid green',
        color: 'green',
        backgroundColor: 'transparent',
      };
      break;
    case 'cancel':
      variantStyles = {
        border: '0.5px solid grey',
        color: 'grey',
        backgroundColor: 'transparent',
      };
      break;
    case 'pagination':
      variantStyles = {
        border: '0.5px solid var(--primary-color)',
        color: 'var(--primary-color)',
        backgroundColor: 'transparent',
        padding: '4px 8px',
        fontSize: '0.8rem',
      };
      break;
    case 'category':
      variantStyles = {
        border: '0.5px solid var(--primary-color)',
        color: 'var(--primary-color)',
        backgroundColor: 'transparent',
        padding: '4px 8px',
        fontSize: '0.8rem',
        borderRadius: '4px',
        whiteSpace: 'nowrap',     // Verhindert Zeilenumbruch
        flex: '0 0 auto',         // Verhindert, dass der Button wächst oder schrumpft
        width: 'auto',            // Erzwingt, dass die Breite nur vom Inhalt bestimmt wird
      };
      break;
    case 'chart': // Neue Variante für kleine Chart-Buttons/Icons
      variantStyles = {
        border: '0.5px solid var(--primary-color)',
        color: 'var(--primary-color)',
        backgroundColor: 'transparent',
        padding: '4px',
        fontSize: '1.1rem', // ggf. anpassen, falls die Icons zu groß/klein sind
        borderRadius: '4px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      };
      break;
    default:
      variantStyles = {};
      break;
  }

  // Spezielle Styles für den deaktivierten Zustand
  const disabledStyles = disabled ? { opacity: 0.5, cursor: 'not-allowed' } : {};

  // Kombiniere alle Stile
  let combinedStyles = {
    ...baseButtonStyle,
    ...variantStyles,
    ...(effect ? effectStyles[effect] : {}),
    boxShadow: (isFocused || isHovered) && !disabled ? '0 0 4px var(--primary-color)' : 'none',
    ...disabledStyles,
    ...style,
  };

    // Farbe für den icon-Variant basierend auf iconType und Hover-Zustand
    if (variant === 'icon') {
      if (iconType === 'delete') {
        combinedStyles.color = isHovered ? 'var(--icon-delete-hover)' : 'var(--icon-delete)';
      } else {
        combinedStyles.color = isHovered ? 'var(--btn-primary-bg-hover)' : 'var(--btn-primary-bg)';
      }
    }

  // Wähle das Element (button oder a)
  const Component = href ? 'a' : 'button';

  return (
    <Component
      {...(href ? { href } : {})}
      {...(!href ? { disabled } : { style: { pointerEvents: disabled ? 'none' : 'auto', ...combinedStyles } })}
      style={combinedStyles}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      {...props}
    >
      {buttonContent}
    </Component>
  );
}

/* ---------------------------------------------------
   useWindowSize-Hook zur Ermittlung der Fenstergröße
--------------------------------------------------- */
function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return windowSize;
}

/* ---------------------------------------------------
   Neue Pagination-Komponente, die komplett auf 
   StyledButton basiert und responsiv reagiert.
--------------------------------------------------- */
export function Pagination({ currentPage, totalPages, onPageChange }) {
  const size = useWindowSize();
  const isMobile = size.width && size.width < 480;
  const [pageInput, setPageInput] = useState("");

  const getPages = (current, total) => {
    const delta = 2;
    const pages = [];
    if (total <= 7) {
      for (let i = 1; i <= total; i++) {
        pages.push(i);
      }
    } else {
      pages.push(1);
      if (current - delta > 2) {
        pages.push('...');
      }
      for (let i = Math.max(2, current - delta); i <= Math.min(total - 1, current + delta); i++) {
        pages.push(i);
      }
      if (current + delta < total - 1) {
        pages.push('...');
      }
      pages.push(total);
    }
    return pages;
  };

  if (isMobile) {
    const handleJump = () => {
      const pageNum = parseInt(pageInput, 10);
      if (!isNaN(pageNum) && pageNum >= 1 && pageNum <= totalPages) {
        onPageChange(pageNum);
      }
      setPageInput("");
    };

    return (
      <div style={{ marginTop: '5px', textAlign: 'center' }}>
        <div style={{ marginBottom: '8px' }}>
          <Trans id="pagination.pageIndicator" values={{ currentPage, totalPages }}>
            Seite {currentPage} von {totalPages}
          </Trans>
        </div>
        <div
          className="pagination-buttons"
          style={{
            display: 'flex',
            gap: '8px',
            justifyContent: 'center',
            alignItems: 'center',
            overflowX: 'auto',
          }}
        >
          <StyledButton
            variant="pagination"
            onClick={() => onPageChange(Math.max(currentPage - 1, 1))}
            disabled={currentPage === 1}
          >
            <Trans id="button.previous">Zurück</Trans>
          </StyledButton>
          <StyledButton
            variant="pagination"
            onClick={() => onPageChange(Math.min(currentPage + 1, totalPages))}
            disabled={currentPage === totalPages}
          >
            <Trans id="button.next">Weiter</Trans>
          </StyledButton>
        </div>
        <div
          style={{
            marginTop: '15px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '4px',
          }}
        >
          <label
            style={{
              fontSize: '1rem',
              color: 'var(--text-color)',
            }}
          >
            {t({ id: 'pagination.inputLabel', message: 'Seitenzahl eingeben:' })}
          </label>
          <div style={{ display: 'flex', gap: '8px', alignItems: 'center', marginBottom: '30px' }}>
            <StyledInput
              type="number"
              min="1"
              max={totalPages}
              value={pageInput}
              onChange={(e) => setPageInput(e.target.value)}
              placeholder={t({ id: 'pagination.inputPlaceholder', message: 'Seite' })}
              style={{
                width: '60px',
                height: '27px',
                minHeight: 'unset',
                padding: '4px 8px',
                fontSize: '0.8rem',
                lineHeight: '22px',
              }}
            />
            <StyledButton variant="pagination" onClick={handleJump}>
              <Trans id="button.go">Gehe zu</Trans>
            </StyledButton>
          </div>
        </div>
      </div>
    );
  } else {
    const pages = getPages(currentPage, totalPages);
    return (
      <div
        className="pagination-buttons"
        style={{
          display: 'flex',
          gap: '8px',
          flexWrap: 'wrap',
          justifyContent: 'center',
          marginTop: '16px',
        }}
      >
        <StyledButton
          variant="pagination"
          onClick={() => onPageChange(1)}
          disabled={currentPage === 1}
        >
          Erste
        </StyledButton>
        <StyledButton
          variant="pagination"
          onClick={() => onPageChange(Math.max(currentPage - 1, 1))}
          disabled={currentPage === 1}
        >
          <Trans id="button.previous">Zurück</Trans>
        </StyledButton>
        {pages.map((page, idx) =>
          page === '...' ? (
            <span key={idx} style={{ padding: '0 8px', alignSelf: 'center' }}>...</span>
          ) : (
            <StyledButton
              key={idx}
              variant="pagination"
              onClick={() => onPageChange(page)}
              style={
                page === currentPage
                  ? {
                    backgroundColor: 'var(--primary-color)',
                    color: 'white',
                    borderColor: 'var(--primary-color)',
                  }
                  : {}
              }
            >
              {page}
            </StyledButton>
          )
        )}
        <StyledButton
          variant="pagination"
          onClick={() => onPageChange(Math.min(currentPage + 1, totalPages))}
          disabled={currentPage === totalPages}
        >
          <Trans id="button.next">Weiter</Trans>
        </StyledButton>
        <StyledButton
          variant="pagination"
          onClick={() => onPageChange(totalPages)}
          disabled={currentPage === totalPages}
        >
          Letzte
        </StyledButton>
      </div>
    );
  }
}
