// src/utils/CoinSelektor.jsx

import React, { useEffect, useState, useMemo } from 'react';
import Select from 'react-select';
import api from '../services/api'; // <--- Axios importiert

/** 
 * Alle bekannten Fiat-Währungen.
 */
const ALL_FIAT_LIST = [
  'usd','eur','aed','ars','aud','bdt','bhd','bmd','brl','cad','chf','clp','cny','czk','dkk',
  'gbp','gel','hkd','huf','idr','ils','inr','jpy','krw','kwd','lkr','mmk','mxn','myr','ngn',
  'nok','nzd','php','pkr','pln','rub','sar','sek','sgd','thb','try','twd','uah','vef','vnd',
  'zar','xdr','xag','xau'
];

/**
 * USD ganz oben, dann EUR, dann restliche Fiats (alphabetisch).
 */
const PREFERRED_ORDER = ['usd', 'eur'];

function CoinSelektor({ selectedCurrency, onChange }) {
  // Lokaler State für die vom Server geladenen Währungen
  const [allCurrencies, setAllCurrencies] = useState([]);
  const [loading, setLoading] = useState(true);

  // 1) /supported-currencies laden (Axios statt fetch)
  useEffect(() => {
    setLoading(true);
    api.get('/supported-currencies')
      .then((res) => {
        setAllCurrencies(res.data || []);
        setLoading(false);
      })
      .catch((err) => {
        console.error('Error loading /supported-currencies:', err);
        setAllCurrencies([]);
        setLoading(false);
      });
  }, []);

  // 2) Gruppierte Optionen (Fiat vs. Crypto) erzeugen
  const groupedOptions = useMemo(() => {
    if (!allCurrencies.length) return [];

    const fiat = [];
    const crypto = [];

    // Aufteilen in Fiat vs. Crypto
    allCurrencies.forEach((cur) => {
      if (ALL_FIAT_LIST.includes(cur.toLowerCase())) {
        fiat.push(cur);
      } else {
        crypto.push(cur);
      }
    });

    // Sortierung: erst "USD, EUR", dann restliche Fiats alphabetisch
    const preferredFiat = [];
    const restFiat = [];
    fiat.forEach((f) => {
      if (PREFERRED_ORDER.includes(f.toLowerCase())) {
        preferredFiat.push(f);
      } else {
        restFiat.push(f);
      }
    });
    restFiat.sort((a, b) => a.localeCompare(b));

    const finalFiat = [];
    PREFERRED_ORDER.forEach((wanted) => {
      const found = preferredFiat.find((f) => f.toLowerCase() === wanted);
      if (found) {
        finalFiat.push(found);
      }
    });
    finalFiat.push(...restFiat);

    // Krypto alphabetisch
    crypto.sort((a, b) => a.localeCompare(b));

    // In React-Select-Format wandeln
    const fiatOptions = finalFiat.map((f) => ({
      label: f.toUpperCase(),
      value: f,
    }));
    const cryptoOptions = crypto.map((c) => ({
      label: c.toUpperCase(),
      value: c,
    }));

    return [
      { label: 'Fiat', options: fiatOptions },
      { label: 'Crypto', options: cryptoOptions },
    ];
  }, [allCurrencies]);

  // 3) Aktuellen Wert (als Objekt) finden
  const valueObj = useMemo(() => {
    for (const group of groupedOptions) {
      const found = group.options.find((opt) => opt.value === selectedCurrency);
      if (found) return found;
    }
    return null;
  }, [selectedCurrency, groupedOptions]);

  // 4) Custom Styles – weniger Padding und kleinere Schrift
  const customStyles = {
    container: (base) => ({
      ...base,
      minWidth: '260px',
      backgroundColor: 'transparent',
    }),
    control: (base, state) => ({
      ...base,
      backgroundColor: 'transparent',
      border: '0.5px solid var(--primary-color)',
      boxShadow: state.isFocused ? '0 0 4px var(--primary-color)' : 'none',
      minHeight: '30px',
      cursor: 'pointer',
      transition: 'box-shadow 0.2s ease',
      '&:hover': {
        boxShadow: '0 0 4px var(--primary-color)',
      },
    }),
    valueContainer: (base) => ({
      ...base,
      backgroundColor: 'transparent',
      padding: '0 4px',
      fontSize: '0.9rem',
      borderRadius: 0,
    }),
    input: (base) => ({
      ...base,
      backgroundColor: 'transparent',
      color: 'var(--text-color)',
      caretColor: 'var(--text-color)',
      margin: 0,
      padding: 0,
      fontSize: '0.9rem',
    }),
    singleValue: (base) => ({
      ...base,
      color: 'var(--text-color)',
      fontSize: '0.9rem',
    }),
    placeholder: (base) => ({
      ...base,
      color: 'var(--text-color)',
      opacity: 0.6,
      fontSize: '0.9rem',
    }),
    menu: (base) => ({
      ...base,
      backgroundColor: 'var(--background-color2)',
      border: '0.5px solid var(--secondary-bg-color)',
      zIndex: 9999,
      whiteSpace: 'nowrap',
      fontSize: '0.9rem',
    }),
    option: (base, state) => ({
      ...base,
      whiteSpace: 'nowrap',
      backgroundColor: state.isSelected
        ? 'var(--btn-primary-bg)'
        : state.isFocused
          ? 'var(--secondary-bg-color)'
          : 'transparent',
      color: state.isSelected ? '#fff' : 'var(--text-color)',
      cursor: 'pointer',
      fontSize: '0.9rem',
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      color: state.isFocused ? 'var(--primary-color)' : 'var(--text-color)',
      '&:hover': {
        color: 'var(--primary-color)',
      },
    }),
    indicatorSeparator: (base) => ({
      ...base,
      backgroundColor: 'transparent',
    }),
  };

  return (
    <Select
      options={groupedOptions}
      value={valueObj}
      onChange={(sel) => onChange(sel.value)}
      isSearchable={true}
      styles={customStyles}
      menuPlacement="auto"
      placeholder="Währung auswählen..."
    />
  );
}

export default CoinSelektor;
