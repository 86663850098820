// src/components/widgets/WidgetPreview.jsx
import React from 'react';
import { Trans } from '@lingui/macro';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { formatPrice } from '../../utils/formatPrice';

/**
 * WidgetPreview => kapselt die "renderPreview"-Logik.
 * Props:
 *  - widgetLoaded (bool)
 *  - previewData (obj oder null)
 *  - isPreviewLoading
 *  - isDelayedSkeleton
 *  - config
 *  - type
 */
const WidgetPreview = ({
  widgetLoaded,
  previewData,
  isPreviewLoading,
  isDelayedSkeleton,
  config,
  type,
}) => {
  if (!widgetLoaded) {
    return <p style={{ textAlign: 'center', opacity: 0.7 }}>Widget wird geladen...</p>;
  }

  if (!previewData && isPreviewLoading) {
    if (!isDelayedSkeleton) {
      return <p style={{ textAlign: 'center', opacity: 0.7 }}>Wird geladen ...</p>;
    } else {
      return (
        <SkeletonTheme baseColor="#ccc" highlightColor="#eee">
          <div style={{ textAlign: 'center' }}>
            <div style={{ marginBottom: '10px' }}>
              <Skeleton circle width={30} height={30} style={{ marginRight: '5px' }} />
              <Skeleton width={80} />
            </div>
            <div style={{ fontSize: '1.2rem', margin: '5px 0' }}>
              <Skeleton width={60} />
            </div>
            <div style={{ margin: '5px 0' }}>
              <Skeleton width={40} />
            </div>
            <small>
              <Skeleton width={100} />
            </small>
          </div>
        </SkeletonTheme>
      );
    }
  }

  if (!previewData) {
    return <p style={{ textAlign: 'center' }}>Keine Preview-Daten</p>;
  }

  const { name: coinName, image, current_price, price_change_percentage_24h } = previewData;
  const priceNum = current_price || 0;
  const pct24 = price_change_percentage_24h || 0;
  const currencyLabel = config.currency.toUpperCase();
  const sign24h = pct24 >= 0 ? '+' : '';

  const wrapperStyles = {
    backgroundColor: config.color,
    color: config.textColor,
    border: `1px solid ${config.borderColor}`,
    borderRadius: `${config.borderRadius}px`,
    textAlign: 'center',
    padding: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  };

  switch (type) {
    case 'price-box':
      return (
        <>
          <div className="widget-preview-box" style={wrapperStyles}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '8px',
                marginBottom: '10px',
              }}
            >
              {image && (
                <img
                  src={image}
                  alt={coinName || config.coin}
                  style={{ width: '20px', height: '20px', borderRadius: '50%' }}
                />
              )}
              <strong>{coinName || config.coin}</strong>
            </div>
            <div style={{ fontSize: '1.2rem' }}>
              {formatPrice(priceNum, config.currency, false)} {currencyLabel}
            </div>
            <div
              style={{
                color: pct24 > 0 ? 'green' : pct24 < 0 ? 'red' : config.textColor,
                fontSize: '0.9rem',
                marginTop: '5px',
              }}
            >
              {`${sign24h}${pct24.toFixed(2)}%`}
            </div>
          </div>
          <small style={{ display: 'block', marginTop: '5px' }}>
            (Price-Box Preview)
          </small>
        </>
      );

    case 'ticker':
      return (
        <div className="widget-preview-box" style={wrapperStyles}>
          <p>
            <Trans id="widget.builder.preview.widgetType">Widget-Typ:</Trans> Ticker
          </p>
          <p>
            Coin: <strong>{config.coin}</strong>
          </p>
          <p>
            Price: {formatPrice(priceNum, config.currency, false)} {currencyLabel}
          </p>
          <p>24h: {`${sign24h}${pct24.toFixed(2)}%`}</p>
          <small>Ticker-Darstellung (z.B. Laufschrift)</small>
        </div>
      );

    case 'chart':
      return (
        <div className="widget-preview-box" style={wrapperStyles}>
          <p>
            <Trans id="widget.builder.preview.widgetType">Widget-Typ:</Trans> Chart
          </p>
          <p>
            Coin: <strong>{config.coin}</strong>
          </p>
          <p>
            Price: {formatPrice(priceNum, config.currency, false)} {currencyLabel}
          </p>
          <p>24h: {`${sign24h}${pct24.toFixed(2)}%`}</p>
          <small>Hier könnte ein Mini-Chart sein ...</small>
        </div>
      );

    default:
      return (
        <div className="widget-preview-box" style={wrapperStyles}>
          <p>(No Preview)</p>
        </div>
      );
  }
};

export default WidgetPreview;
