import React from 'react';
import { Trans } from '@lingui/macro';
import InfoTooltip from './InfoTooltip';

// Lokaler Wrapper, der den Text fett und unterstrichen darstellt
const UnderlinedStrong = ({ children }) => (
  <strong style={{ textDecoration: 'underline' }}>{children}</strong>
);

const TechnicalInfo = ({ blockTime, hashingAlgorithm, genesisDate }) => {
  return (
    <div className="coin-technical-info-card" style={{ position: 'relative' }}>
      <h3>
        <Trans id="label.moreMarketData">Weitere Marktdaten</Trans>
      </h3>
      <div className="tooltip-wrapper">
        <InfoTooltip iconSize={18}>
          <Trans
            id="tooltips.technicalInfoExplanation"
            components={[<br key="br" />, <UnderlinedStrong key="strong" />]}
          >
            {"• <1>Blockzeit</1>: Durchschnittliche Zeit, die ein Block benötigt.<0/>• <1>Hashing-Algorithmus</1>: Der Algorithmus, der das Netzwerk sichert.<0/>• <1>Genesis Datum</1>: Das Startdatum des Projekts."}
          </Trans>
        </InfoTooltip>
      </div>
      <p>
        <strong>
          <Trans id="label.blockTime">Blockzeit:</Trans>
        </strong>{" "}
        {blockTime !== 'N/A'
          ? `${blockTime} Minuten`
          : <Trans id="label.notAvailable">Nicht verfügbar</Trans>}
      </p>
      <p>
        <strong>
          <Trans id="label.hashingAlgorithm">Hashing-Algorithmus:</Trans>
        </strong>{" "}
        {hashingAlgorithm !== 'N/A'
          ? hashingAlgorithm
          : <Trans id="label.notAvailable">Nicht verfügbar</Trans>}
      </p>
      <p>
        <strong>
          <Trans id="label.genesisDate">Genesis Datum:</Trans>
        </strong>{" "}
        {genesisDate !== 'N/A'
          ? genesisDate
          : <Trans id="label.notAvailable">Nicht verfügbar</Trans>}
      </p>
    </div>
  );
};

export default TechnicalInfo;
