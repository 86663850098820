// src/components/CoinDetails/MarketData.jsx
import React, { useState, useEffect } from 'react';
import { Trans } from '@lingui/macro';
import { FaArrowUp, FaArrowDown, FaClock, FaCalendarAlt, FaPercent, FaHashtag } from 'react-icons/fa';
import InfoTooltip from './InfoTooltip';
import { formatChangePercentage } from '../../utils/formatChangePercentage';
import api from '../../services/api'; // <-- Neu: Axios-Client statt fetch

const UnderlinedStrong = ({ children }) => (
  <strong style={{ textDecoration: 'underline' }}>{children}</strong>
);

const MarketData = ({
  coinId,
  vsCurrency,
  marketCap,
  marketCapRank,
  marketCapRank24h,
  marketCapRankChange24h,
  volume24h,
  high24h,
  low24h,
  high24hTimestamp,
  low24hTimestamp,
  ath,
  athDate,
  athChange,
  atl,
  atlDate,
  atlChange,
  userLocale,
}) => {
  const [volumeChange24h, setVolumeChange24h] = useState(null);
  const [loadingData, setLoadingData] = useState(true);

  useEffect(() => {
    async function fetchMarketChart() {
      try {
        const response = await api.get('/coin-market-chart', {
          params: {
            id: coinId,
            vs_currency: vsCurrency,
            days: 1
          }
        });
        const data = response.data;
        if (data && data.total_volumes && data.total_volumes.length >= 2) {
          const volumes = data.total_volumes;
          const firstVolume = volumes[0][1];           // Volumen vor 24h
          const lastVolume = volumes[volumes.length - 1][1]; // Aktuelles Volumen
          const volChange = ((lastVolume - firstVolume) / firstVolume) * 100;
          setVolumeChange24h(volChange);
        }
      } catch (error) {
        console.error('Error fetching market chart data:', error);
      } finally {
        setLoadingData(false);
      }
    }
    if (coinId && vsCurrency) {
      fetchMarketChart();
    }
  }, [coinId, vsCurrency]);

  return (
    <div className="coin-market-extremes" style={{ position: 'relative' }}>
      {/* Tooltip oben rechts */}
      <div className="tooltip-wrapper">
        <InfoTooltip iconSize={18}>
          <Trans
            id="tooltips.marketDataExplanation"
            components={[<br />, <UnderlinedStrong />]}
          >
            {
              "<1>24h Volumen</1>: Das gesamte Handelsvolumen in den letzten 24 Stunden.<0/> <1>24h Hoch/Tief</1>: Der höchste bzw. niedrigste Preis innerhalb der letzten 24 Stunden.<0/> <1>Marktkapitalisierung</1>: Die Gesamtbewertung des Coins, berechnet durch Multiplikation des aktuellen Preises mit der zirkulierenden Menge.<0/> <1>Allzeit-Hoch (ATH)</1>: Der höchste Preis, den der Coin jemals erreicht hat.<0/> <1>Allzeit-Tief (ATL)</1>: Der niedrigste Preis, den der Coin jemals erreicht hat."
            }
          </Trans>
        </InfoTooltip>
      </div>

      {/* Oberer Bereich: 24h Volumen, 24h Hoch, 24h Tief */}
      <div className="market-data-top">
        <div className="market-data-item volume-info">
          <strong>
            <Trans id="label.volume24hFull">24h Volumen:</Trans>
          </strong>
          <p className="market-data-value">{volume24h}</p>
          {volumeChange24h !== null && !loadingData && (
            <div className="volume-change-box">
              <span className="volume-label">24h</span>
              <span className="percent-info">
                <FaPercent className="icon-percent" />
                <span className="percent-text">
                  {formatChangePercentage(volumeChange24h, { hideTrailingPercent: true })}
                </span>
              </span>
            </div>
          )}
        </div>
        <div className="market-data-item high-info">
          <strong>
            <Trans id="label.24hHigh">24h Hoch:</Trans>
          </strong>
          <p className="market-data-value">{high24h}</p>
          {high24hTimestamp && (
            <span className="time-info">
              <FaClock className="icon-time" />
              {high24hTimestamp.toLocaleTimeString(userLocale)}
            </span>
          )}
        </div>
        <div className="market-data-item low-info">
          <strong>
            <Trans id="label.24hLow">24h Tief:</Trans>
          </strong>
          <p className="market-data-value">{low24h}</p>
          {low24hTimestamp && (
            <span className="time-info">
              <FaClock className="icon-time" />
              {low24hTimestamp.toLocaleTimeString(userLocale)}
            </span>
          )}
        </div>
      </div>

      {/* Unterer Bereich: Marktkapitalisierung, MarketCapRank, ATH, ATL */}
      <div className="market-data-bottom">
        {marketCap !== 'N/A' && (
          <div className="market-data-item marketcap-info">
            <strong>
              <Trans id="label.marketCapFull">Marktkapitalisierung:</Trans>
            </strong>
            <p className="market-data-value">{marketCap}</p>
            {marketCapRank !== 'N/A' && (
              <div className="marketcaprank-box">
                <FaHashtag className="icon-marketcaprank" />
                <span className="marketcaprank-value">{marketCapRank}</span>
                {typeof marketCapRankChange24h === 'number' && (
                  <span className="marketcaprank-change">
                    (
                    {Math.abs(marketCapRankChange24h)}
                    {marketCapRankChange24h > 0 ? (
                      <FaArrowDown className="marketcaprank-arrow down" />
                    ) : marketCapRankChange24h < 0 ? (
                      <FaArrowUp className="marketcaprank-arrow up" />
                    ) : null}
                    )
                  </span>
                )}
              </div>
            )}
          </div>
        )}
        <div className="market-data-item ath-item">
          <strong>
            <Trans id="label.ath">Allzeit-Hoch (ATH):</Trans>
          </strong>
          <p className="market-data-value">{ath}</p>
          {athDate && athChange !== null && (
            <div className="ath-info">
              <span className="percent-info">
                <FaPercent className="icon-percent" />
                <span>
                  {formatChangePercentage(athChange, { hideTrailingPercent: true })}
                </span>
              </span>
              <span className="date-info">
                <FaCalendarAlt className="icon-date" />
                {athDate.toLocaleDateString(userLocale)}
              </span>
            </div>
          )}
        </div>
        <div className="market-data-item atl-item">
          <strong>
            <Trans id="label.atl">Allzeit-Tief (ATL):</Trans>
          </strong>
          <p className="market-data-value">{atl}</p>
          {atlDate && atlChange !== null && (
            <div className="atl-info">
              <span className="percent-info">
                <FaPercent className="icon-percent" />
                <span>
                  {formatChangePercentage(atlChange, { hideTrailingPercent: true })}
                </span>
              </span>
              <span className="date-info">
                <FaCalendarAlt className="icon-date" />
                {atlDate.toLocaleDateString(userLocale)}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MarketData;
