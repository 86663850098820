// src/components/favorites/binanceFavorites.js

/**
 * Ein abgespeckter WS-Manager für deine Favoriten, optimiert um unnötige
 * Restarts zu vermeiden.
 */
import { JSON } from 'globalthis/implementation'; // Ggf. anpassen, falls global this anders gehandhabt wird

let ws = null;                      // Die WebSocket-Instanz
let reconnectAttempts = 0;          // Zähler für Wiederverbindungsversuche
let currentSymbols = [];            // Aktuell abonnierte Symbole (z.B. ['btc', 'eth'])
let globalCallback = null;          // Die Callback-Funktion für Preis-Updates
const WS_URL = 'wss://cryptoscan.digital'; // WebSocket URL als Konstante

/**
 * Hilfsfunktion zum Vergleichen von Symbol-Arrays (ignoriert Reihenfolge).
 * @param {string[]} arr1
 * @param {string[]} arr2
 * @returns {boolean} True, wenn die Arrays dieselben Symbole enthalten.
 */
function areSymbolArraysEqual(arr1, arr2) {
    // Grundlegende Prüfungen
    if (!arr1 && !arr2) return true; // Beide null/undefined sind gleich
    if (!arr1 || !arr2) return false; // Einer null/undefined, der andere nicht
    if (arr1.length !== arr2.length) {
        return false; // Unterschiedliche Längen
    }
    // Erstelle Kopien, sortiere sie und vergleiche elementweise
    const sortedArr1 = [...arr1].sort();
    const sortedArr2 = [...arr2].sort();
    return sortedArr1.every((value, index) => value === sortedArr2[index]);
}


/**
 * attemptReconnect:
 * Versucht nach einem exponentiellen Backoff-Delay erneut, die Socket-Verbindung
 * aufzubauen, sofern noch Symbole vorhanden sind.
 */
function attemptReconnect() {
    // Verhindere Reconnect, wenn keine Symbole mehr gewünscht sind oder bereits ein Verbindungsversuch läuft/besteht
    if (!currentSymbols.length || (ws && (ws.readyState === WebSocket.OPEN || ws.readyState === WebSocket.CONNECTING))) {
         if (!currentSymbols.length) {
            // console.log("binanceFavorites: Reconnect verhindert, keine Symbole mehr aktiv.");
         } else {
            // console.log("binanceFavorites: Reconnect verhindert, WS bereits offen oder verbindend.");
         }
         return;
    }

    reconnectAttempts++;
    // Exponentieller Backoff mit Obergrenze (z.B. max 60 Sekunden)
    // 1s, 2s, 4s, 8s, 16s, 32s, 60s, 60s...
    const delay = Math.min(1000 * Math.pow(2, reconnectAttempts -1), 60000);
    console.warn(
        `binanceFavorites: WebSocket nicht verbunden. Reconnect in ${delay / 1000}s (Versuch ${reconnectAttempts})`
    );

    setTimeout(() => {
        // Nur verbinden, wenn immer noch Symbole gewünscht sind und *immer noch keine* Verbindung besteht
        if (currentSymbols.length > 0 && (!ws || ws.readyState === WebSocket.CLOSED)) {
            console.log(`binanceFavorites: Reconnecting now (Attempt ${reconnectAttempts})...`);
            initSocket(); // Ruft initSocket auf, das die Verbindung neu aufbaut
        } else if (ws) {
             console.log(`binanceFavorites: Reconnect abgebrochen, Verbindung besteht inzwischen (State: ${ws.readyState}).`);
        } else {
             console.log(`binanceFavorites: Reconnect abgebrochen, keine Symbole mehr vorhanden.`);
        }
    }, delay);
}

/**
 * initSocket:
 * Erstellt eine *neue* WebSocket-Verbindung, wenn keine aktive besteht.
 * Setzt Event-Handler (onopen, onmessage, onerror, onclose).
 * Das eigentliche Abonnieren geschieht im onopen-Handler.
 */
function initSocket() {
    // Verhindere mehrfache Verbindungsversuche, wenn bereits einer läuft oder offen ist
    if (ws && (ws.readyState === WebSocket.OPEN || ws.readyState === WebSocket.CONNECTING)) {
        // console.log('binanceFavorites: initSocket skipped, connection already open or connecting.');
        return;
    }

    // Alte Verbindung sauber schließen, falls noch Reste vorhanden (sollte nicht nötig sein, aber sicher ist sicher)
    if (ws) {
        console.warn("binanceFavorites: initSocket called while ws existed in unexpected state. Closing old one.", ws.readyState);
        ws.onclose = null; // Verhindere Reconnect-Versuch durch alten Handler
        ws.close();
    }
    ws = null; // Explizit nullen

    console.log('binanceFavorites: Initializing new WebSocket connection...');
    // reconnectAttempts wird in onopen bei Erfolg oder in attemptReconnect bei neuem Versuch zurückgesetzt

    ws = new WebSocket(WS_URL);

    // --- Event Handler ---

    ws.onopen = () => {
        console.log('binanceFavorites: WebSocket connection opened.');
        reconnectAttempts = 0; // Erfolgreich verbunden, Zähler zurücksetzen

        // Abonnieren der aktuell gespeicherten Symbole
        if (currentSymbols.length > 0) {
             console.log('binanceFavorites: Subscribing to symbols onopen:', currentSymbols);
             try {
                ws.send(
                    JSON.stringify({
                        type: 'subscribe',
                        symbols: currentSymbols, // Sendet die aktuelle Liste
                    })
                );
             } catch (e) {
                 console.error("binanceFavorites: Fehler beim Senden der Subscription (onopen):", e);
                 // Ggf. Verbindung schließen und Reconnect versuchen?
                 if (ws) ws.close(); // Schließen löst onclose -> attemptReconnect aus
             }
        } else {
             console.log('binanceFavorites: WebSocket opened, but no symbols currently needed.');
        }
    };

    ws.onmessage = (evt) => {
      try {
          const msg = JSON.parse(evt.data);

          // Typische Preis-Update Nachricht
          if (msg && msg.symbol && msg.data && msg.data.price !== undefined) {
              const lowerSymbol = msg.symbol.toLowerCase();
              // Annahme: Server sendet immer das Handelspaar (z.B. btcusdt)
              const baseSymbol = lowerSymbol.endsWith('usdt')
                  ? lowerSymbol.slice(0, -4)
                  : lowerSymbol; // Fallback, falls es kein USDT-Paar ist

              // Nur Callback aufrufen, wenn das Symbol noch aktuell abonniert ist
              // und der Callback existiert
              if (globalCallback && currentSymbols.includes(baseSymbol) ) {
                  // *** HIER DIE ÄNDERUNG: Fehler als dritter Parameter (null bei Erfolg) ***
                  globalCallback(baseSymbol, {
                      price: msg.data.price, // Preis als String oder Zahl? Ggf. parsen
                      percentageChange: msg.data.percentageChange, // Ggf. parsen
                  }, null); // <<< Fehler ist null
              }
          }
          // Fehler-Nachricht vom Server (z.B. Symbol ungültig)
          else if (msg && msg.symbol && msg.data && msg.data.error) {
              const lowerSymbol = msg.symbol.toLowerCase();
               const baseSymbol = lowerSymbol.endsWith('usdt')
                  ? lowerSymbol.slice(0, -4)
                  : lowerSymbol;
              const errorMessage = msg.data.error; // Die Fehlermeldung vom Server
              console.error(`binanceFavorites: Server error for symbol '${baseSymbol}': ${errorMessage}`);

               // *** HIER DIE ÄNDERUNG: Fehler über Callback melden ***
               if (globalCallback) {
                   // Fehlerfall: data ist null, error ist die Fehlermeldung
                   globalCallback(baseSymbol, null, errorMessage); // <<< Fehlerobjekt/-nachricht übergeben
               }

              // Entferne das fehlerhafte Symbol aus der internen Liste, um Reconnects damit zu vermeiden
              const initialLength = currentSymbols.length;
              currentSymbols = currentSymbols.filter(s => s !== baseSymbol);
              if (currentSymbols.length < initialLength) {
                  console.log(`binanceFavorites: Symbol '${baseSymbol}' removed from internal list due to server error.`);
                  // Optional: Wenn keine Symbole mehr übrig sind, Verbindung schließen?
                  if (currentSymbols.length === 0 && ws && ws.readyState === WebSocket.OPEN) {
                       console.log("binanceFavorites: No symbols left after error, closing connection.");
                       unsubscribeAll(); // Ruft unsubscribeAll auf, um sauber zu schließen
                  }
              }
          }
           // Andere Nachrichten-Typen?
           // else { console.log("binanceFavorites: Received unhandled message type:", msg); }

      } catch (e) {
          console.error("binanceFavorites: Fehler beim Verarbeiten der WS-Nachricht:", e, evt.data);
      }
  };

    ws.onerror = (event) => {
        console.error('binanceFavorites: WebSocket error occurred.', event);
        // Keine explizite Aktion hier, onclose wird den Reconnect auslösen (falls nötig).
    };

    ws.onclose = (event) => {
        const reason = event.reason || 'No specific reason given';
        // Logge nur, wenn es nicht durch explizites unsubscribeAll geschlossen wurde (Code 1000)
        if (event.code !== 1000) {
            console.warn(`binanceFavorites: WebSocket closed unexpectedly (Code: ${event.code}, Reason: ${reason}, Clean: ${event.wasClean}).`);
        } else {
             console.log(`binanceFavorites: WebSocket closed normally (Code: 1000).`);
        }
        ws = null; // Wichtig: ws nullen, damit neue Verbindung aufgebaut werden kann

        // Reconnect nur versuchen, wenn die Schließung nicht explizit durch unsubscribeAll erfolgte (code 1000 ist ok)
        // und wenn wir noch Symbole haben *sollten*.
        // Schließe Codes, die typischerweise keinen Reconnect erfordern (z.B. 1000 Normal, 1005 No Status)
        const shouldAttemptReconnect = event.code !== 1000 && event.code !== 1005;
        if (shouldAttemptReconnect && currentSymbols.length > 0) {
             console.log("binanceFavorites: Attempting reconnect due to unexpected closure.");
             attemptReconnect();
        } else if (currentSymbols.length === 0){
             console.log("binanceFavorites: WebSocket closed and no symbols, no reconnect initiated.");
             reconnectAttempts = 0; // Reset für die Zukunft
             globalCallback = null; // Callback auch zurücksetzen
        } else {
             // Normal geschlossen oder anderer Grund, kein Reconnect
        }
    };
}

/**
 * subscribeToSymbols: Hauptfunktion zum (Neu-)Abonnieren von Symbolen.
 * - Vergleicht neue mit alten Symbolen.
 * - Sendet 'subscribe' nur bei Änderungen oder wenn WS neu gestartet werden muss.
 * - Startet WS nicht mehr bei jedem Aufruf neu.
 * @param {string[]} symbols - Array der zu abonnierenden Basissymbole (z.B. ['btc', 'eth']).
 * @param {function} callback - Funktion, die bei Updates aufgerufen wird: callback(symbol, data).
 */
export function subscribeToSymbols(symbols, callback) {
    // Bereinige die neue Symbolliste (entferne leere/null Werte, konvertiere zu Kleinbuchstaben)
    const newSymbols = symbols
        .filter(s => typeof s === 'string' && s.trim().length > 0)
        .map(s => s.toLowerCase());

    // 1. Callback immer aktualisieren (wichtig, falls sich nur der Callback ändert)
    globalCallback = callback;

    // 2. Prüfen, ob sich die Symbol-Liste tatsächlich geändert hat
    if (areSymbolArraysEqual(newSymbols, currentSymbols)) {
        // console.log("binanceFavorites: subscribeToSymbols - Symbol list unchanged.");

        // 2a. Prüfen, ob die Verbindung trotzdem (neu) gestartet werden muss, falls sie geschlossen ist
        if ((!ws || ws.readyState === WebSocket.CLOSED || ws.readyState === WebSocket.CLOSING) && newSymbols.length > 0) {
             console.log("binanceFavorites: subscribeToSymbols - Symbols unchanged but WS is not open, initializing.");
             initSocket(); // Neu initialisieren
        }
        // Sonst nichts tun, wenn Liste gleich und WS offen/verbindend
        return; // Keine Änderung der Abonnements nötig
    }

    // 3. Symbol-Liste hat sich geändert!
    console.log("binanceFavorites: subscribeToSymbols - Symbol list changed.");
    // console.log("binanceFavorites: Old Symbols:", currentSymbols);
    // console.log("binanceFavorites: New Symbols:", newSymbols);
    currentSymbols = newSymbols; // Neue Liste speichern *bevor* Aktionen ausgelöst werden

    // 4. Wenn die neue Liste leer ist, alles beenden
    if (currentSymbols.length === 0) {
        console.log("binanceFavorites: New symbol list is empty, unsubscribing all.");
        unsubscribeAll(); // Schließt die Verbindung sauber
        return;
    }

    // 5. Interaktion mit WebSocket basierend auf dem aktuellen Zustand
    if (ws && ws.readyState === WebSocket.OPEN) {
        // Verbindung ist offen: Neue Liste senden
        console.log("binanceFavorites: WS is open, sending new subscription:", currentSymbols);
        try {
            // Sende die komplette neue Liste. Der Server sollte die Abos aktualisieren.
            ws.send(JSON.stringify({ type: 'subscribe', symbols: currentSymbols }));
        } catch (e) {
             console.error("binanceFavorites: Fehler beim Senden der Subscription (update):", e);
             // Bei Sendefehler Verbindung neu starten, um Zustand zu synchronisieren
             if(ws) ws.close(); // Schließen erzwingt Reconnect über onclose (wenn Symbole vorhanden)
        }
    } else {
        // Verbindung ist nicht offen oder existiert nicht: Neu initialisieren
        // initSocket wird die (jetzt aktualisierte) currentSymbols-Liste beim Öffnen verwenden
        console.log("binanceFavorites: WS is not open or doesn't exist, initializing connection.");
        // Stelle sicher, dass ein alter Reconnect-Timeout abgebrochen wird, falls vorhanden
        // (Normalerweise nicht nötig, da initSocket alte ws-Instanz schließt)
        initSocket();
    }
}

/**
 * unsubscribeAll:
 * Schließt aktiv die WebSocket-Verbindung und setzt alle Zustandsvariablen zurück.
 * Verhindert automatische Wiederverbindungsversuche.
 * Muss explizit aufgerufen werden, wenn die Favoriten nicht mehr benötigt werden
 * (z.B. beim Unmounten der aufrufenden Komponente).
 */
export function unsubscribeAll() {
    console.log("binanceFavorites: Unsubscribing all and closing WebSocket explicitly.");
    // Wichtig: Zuerst currentSymbols leeren, damit onclose keinen Reconnect versucht!
    currentSymbols = [];
    globalCallback = null;
    reconnectAttempts = 0; // Reset für den Fall, dass später neu abonniert wird

    if (ws) {
        // onclose-Handler temporär entfernen oder prüfen, ob schon null
        // um sicherzustellen, dass unser explizites Schließen keinen Reconnect auslöst
        ws.onclose = () => {
            console.log(`binanceFavorites: WebSocket closed cleanly by unsubscribeAll (Code: ${ws?.closeCode || 'N/A'}).`); // Verwende closeCode wenn verfügbar
             ws = null; // Sicherstellen, dass ws null ist nach dem Schließen
        };
         // Schließe mit normalem Code 1000
        ws.close(1000, "Client unsubscribed all");
        // ws wird im onclose Handler auf null gesetzt.
    } else {
         ws = null; // Sicherstellen, dass ws null ist, falls es schon geschlossen war
    }
}