// src/components/LegalNotice.js
import React from 'react';
import { Trans } from '@lingui/macro';
import '../styles/legalNotice.css';

const LegalNotice = () => {
  return (
    <div className="legalnotice-container">
      {/* Überschrift */}
      <h2 className="legalnotice-title">
        <Trans id="title.legalNotice">Impressum</Trans>
      </h2>

      {/* Verantwortliche Person (ohne Firmenname) */}
      <section className="legalnotice-section">
        <h3>
          <Trans id="heading.responsiblePerson">Verantwortlich für den Inhalt (gemäß § 5 TMG)</Trans>
        </h3>
        <p>
          <Trans id="label.personName">Name:</Trans> Philipp Schirmer <br />
          <Trans id="label.street">Straße:</Trans> Tannenbergstraße 8 <br />
          <Trans id="label.city">PLZ / Ort:</Trans> 57234 Wilnsdorf <br />
          <Trans id="label.telephone">Telefon:</Trans> +49 (0) 174 6892583 <br />
          <Trans id="label.email">E-Mail:</Trans>{' '}
          <a href="mailto:admin@cryptoscan.digital">admin@cryptoscan.digital</a>
        </p>
      </section>

      {/* Haftung für Links */}
      <section className="legalnotice-section">
        <h3>
          <Trans id="heading.linkLiability">Haftung für Links</Trans>
        </h3>
        <p>
          <Trans id="linkLiability.info">
            Diese Website enthält möglicherweise Links zu externen Webseiten Dritter, auf deren
            Inhalte ich keinen Einfluss habe. Deshalb kann ich für diese fremden Inhalte auch keine
            Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige
            Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum
            Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft; rechtswidrige Inhalte
            waren zu diesem Zeitpunkt nicht erkennbar. Eine dauerhafte inhaltliche Kontrolle der
            verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht
            zumutbar. Bei Bekanntwerden von Rechtsverletzungen werde ich derartige Links umgehend
            entfernen.
          </Trans>
        </p>
      </section>

      {/* Urheberrecht */}
      <section className="legalnotice-section">
        <h3>
          <Trans id="heading.copyright">Urheberrecht</Trans>
        </h3>
        <p>
          <Trans id="copyright.info">
            Die durch den Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen
            dem deutschen Urheberrecht. Beiträge Dritter sind als solche gekennzeichnet. Die
            Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
            Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors
            bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten,
            nichtkommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom
            Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet und Inhalte Dritter
            als solche gekennzeichnet. Solltest du trotzdem auf eine Urheberrechtsverletzung
            aufmerksam werden, bitte ich um einen entsprechenden Hinweis. Bei Bekanntwerden von
            Rechtsverletzungen werde ich derartige Inhalte umgehend entfernen.
          </Trans>
        </p>
      </section>

      {/* Keine Abmahnung ohne vorherigen Kontakt */}
      <section className="legalnotice-section">
        <h3>
          <Trans id="heading.noWarning">Keine Abmahnung ohne vorherigen Kontakt</Trans>
        </h3>
        <p>
          <Trans id="noWarning.info">
            Sollte der Inhalt oder die Aufmachung dieser Seiten fremde Rechte Dritter oder
            gesetzliche Bestimmungen verletzen, bitte ich um eine entsprechende Nachricht ohne
            Kostennote. Ich werde zu Recht beanstandete Passagen unverzüglich entfernen, ohne
            dass die Einschaltung eines Rechtsbeistandes erforderlich ist.
          </Trans>
        </p>
      </section>

      {/* Haftungsausschluss (zusätzliche Zusammenfassung) */}
      <section className="legalnotice-section">
        <h3>
          <Trans id="heading.disclaimer">Haftungsausschluss</Trans>
        </h3>
        <p>
          <Trans id="disclaimer.line1">
            Die Inhalte dieser Website wurden mit größtmöglicher Sorgfalt erstellt. Für die
            Richtigkeit, Vollständigkeit und Aktualität kann jedoch keine Gewähr übernommen werden.
            Sollten uns rechtliche Verstöße bekannt werden, entfernen wir die betreffenden Inhalte
            umgehend.
          </Trans>
        </p>
        <p>
          <Trans id="disclaimer.line2">
            Diese Website enthält ggf. Links zu externen Seiten, auf deren Inhalte wir keinen
            Einfluss haben. Deshalb können wir für diese fremden Inhalte keine Gewähr übernehmen.
            Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber
            verantwortlich. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links
            umgehend entfernen.
          </Trans>
        </p>
      </section>

      {/* Rechtswirksamkeit */}
      <section className="legalnotice-section">
        <h3>
          <Trans id="heading.severability">Rechtswirksamkeit</Trans>
        </h3>
        <p>
          <Trans id="severability.info">
            Sollten einzelne Formulierungen dieses Impressums bzw. Haftungsausschlusses der
            geltenden Rechtslage nicht, nicht mehr oder nicht vollständig entsprechen, bleiben die
            übrigen Teile in ihrem Inhalt und ihrer Gültigkeit davon unberührt.
          </Trans>
        </p>
      </section>
    </div>
  );
};

export default LegalNotice;
