// /frontend/src/utils/currencySymbols.js
import React from 'react';
import { 
  FaEuroSign, 
  FaDollarSign, 
  FaPoundSign, 
  FaYenSign 
} from 'react-icons/fa';

// Kryptowährungs-Icons
import btcIcon from 'cryptocurrency-icons/svg/color/btc.svg';
import ethIcon from 'cryptocurrency-icons/svg/color/eth.svg';
import ltcIcon from 'cryptocurrency-icons/svg/color/ltc.svg';
import bchIcon from 'cryptocurrency-icons/svg/color/bch.svg';
import bnbIcon from 'cryptocurrency-icons/svg/color/bnb.svg';
import eosIcon from 'cryptocurrency-icons/svg/color/eos.svg';
import xrpIcon from 'cryptocurrency-icons/svg/color/xrp.svg';
import xlmIcon from 'cryptocurrency-icons/svg/color/xlm.svg';
import linkIcon from 'cryptocurrency-icons/svg/color/link.svg';
import dotIcon from 'cryptocurrency-icons/svg/color/dot.svg';
import yfiIcon from 'cryptocurrency-icons/svg/color/yfi.svg';

// Standard-Style für vorhandene (provided) Icons (z. B. react-icons oder SVGs)
export const defaultProvidedIconStyle = {
  width: 13,
  height: 13,
  padding: 0,
  boxShadow: 'var(--box-shadow)',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: '3px',
};

// Standard-Style für selbst erstellte Icons (CustomCurrencyIcon)
export const defaultCustomIconStyle = {
  minWidth: 12,
  width: 'auto',
  height: 12,
  display: 'inline-flex',
  whiteSpace: 'nowrap',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'transparent',  
  borderRadius: '4px',
  fontSize: 10,
  color: 'var(--text-color)',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  marginRight: '3px',
  marginLeft: '3px',
};

/**
 * CustomCurrencyIcon
 * ------------------
 * Erzeugt ein kleines Badge, das das Währungskürzel (z. B. "INR") darstellt.
 *
 * Props:
 * - code: Währungskürzel (z. B. "inr")
 * - size: Gesamtabmessung des Icons (Standard: 12)
 * - fontSize: Schriftgröße innerhalb des Badges (Standard: 10)
 * - color: Textfarbe
 * - backgroundColor: Hintergrundfarbe des Badges
 * - borderRadius: Eckenrundung (Standard: 4px)
 * - style: Zusätzliche Inline-Stile (optional)
 */
export const CustomCurrencyIcon = ({
  code,
  size = 12,
  fontSize = 10,
  color = 'var(--text-color)',
  borderRadius = '4px',
  style: customStyle = {},
}) => {
  const computedStyle = {
    ...defaultCustomIconStyle,
    minWidth: size,
    height: size,
    fontSize: fontSize,
    borderRadius: borderRadius,
    color: color,
    ...customStyle,
  };

  return <span style={computedStyle}>{code}</span>;
};

/**
 * CURRENCY_SYMBOLS
 * ----------------
 * Mapping der Währungscodes auf ihre jeweiligen Icons.
 *
 * Für Fiat-Währungen:
 *   - USD, EUR, GBP, JPY besitzen spezifische Icons.
 *   - Alle weiteren (z. B. CNY, AUD, CAD etc.) werden NICHT gemappt und erhalten somit den Fallback (CustomCurrencyIcon).
 *
 * Für Kryptowährungen:
 *   - Hier werden die entsprechenden SVG-Icons verwendet.
 */
export const CURRENCY_SYMBOLS = {
  // Fiat-Währungen mit spezifischen Icons:
  usd: FaDollarSign,
  eur: FaEuroSign,
  gbp: FaPoundSign,
  jpy: FaYenSign,

  // Für alle anderen Fiat-Währungen keinen Eintrag – somit greift der Fallback

  // Kryptowährungen mit SVG-Icons:
  btc: btcIcon,
  eth: ethIcon,
  ltc: ltcIcon,
  bch: bchIcon,
  bnb: bnbIcon,
  eos: eosIcon,
  xrp: xrpIcon,
  xlm: xlmIcon,
  link: linkIcon,
  dot: dotIcon,
  yfi: yfiIcon,
};

/**
 * getCurrencyIcon
 * ---------------
 * Diese Funktion gibt für einen gegebenen Währungscode das passende Icon zurück.
 * Falls für den Code kein spezifisches Icon definiert ist, wird ein CustomCurrencyIcon (Badge mit dem Währungskürzel) gerendert.
 *
 * @param {string} code - Der Währungscode (z. B. "usd", "inr", "btc")
 * @param {object} props - Optionale Props, die an das Icon weitergereicht werden (z. B. Größe, Style)
 * @returns {JSX.Element} - Das entsprechende Icon als React-Element.
 */
export function getCurrencyIcon(code, props = {}) {
  const lowerCode = code.toLowerCase();
  const Icon = CURRENCY_SYMBOLS[lowerCode];

  // Merge default provided icon style mit übergebenen Styles
  const mergedStyle = { ...defaultProvidedIconStyle, ...props.style };
  const mergedProps = { ...props, style: mergedStyle };

  if (Icon) {
    // Wenn Icon eine React-Komponente (z. B. aus react-icons) ist:
    if (typeof Icon === 'function') {
      return <Icon {...mergedProps} />;
    }
    // Falls Icon ein SVG-Pfad (String) ist:
    if (typeof Icon === 'string' && Icon.endsWith('.svg')) {
      return <img src={Icon} alt={code} {...mergedProps} />;
    }
    return Icon;
  }
  // Fallback: Kein spezifisches Icon gefunden – CustomCurrencyIcon verwenden.
  return <CustomCurrencyIcon code={code.toUpperCase()} {...props} />;
}
