// src/components/RateLimitOverlay.js
import React, { useEffect, useState } from 'react';
import { t } from '@lingui/macro';

import api from '../services/api'; // <-- Neu: unser zentrales Axios-Objekt

function RateLimitOverlay() {
  const [visible, setVisible] = useState(true);
  const [rateLimitOver, setRateLimitOver] = useState(false);

  useEffect(() => {
    // Sobald rateLimitOver true ist, warten wir 5 Sekunden,
    // bis wir das Overlay ausblenden.
    if (rateLimitOver) {
      const timer = setTimeout(() => {
        setVisible(false);
      }, 5000);

      // Cleanup, falls Komponente vorher ungemountet wird
      return () => clearTimeout(timer);
    }
  }, [rateLimitOver]);

  useEffect(() => {
    const interval = setInterval(async () => {
      try {
        // GET /api/health mit Axios
        const response = await api.get('/health');

        // Wenn /api/health erfolgreich (status 200) => Rate-Limit vorbei
        if (response.status === 200) {
          setRateLimitOver(true);
          clearInterval(interval);
        }
      } catch (error) {
        // Fehler beim Request => wir probieren es im nächsten Intervall erneut
      }
    }, 5000);

    // Cleanup bei Unmount
    return () => clearInterval(interval);
  }, []);

  if (!visible) return null;

  return (
    <div style={styles.overlay}>
      <div style={styles.content}>
        <h1>{t({ id: 'ratelimit.title', message: 'Zu viele Anfragen' })}</h1>
        <p>
          {t({
            id: 'ratelimit.message',
            message:
              'Du hast das Anfrage-Limit überschritten. Bitte warte kurz, wir prüfen regelmäßig die Verfügbarkeit.',
          })}
        </p>
      </div>
    </div>
  );
}

const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.85)',
    color: '#fff',
    zIndex: 9999,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    padding: '20px',
  },
  content: {
    maxWidth: '400px',
    margin: '0 auto',
  },
};

export default RateLimitOverlay;
