// src/utils/StyledInput.jsx
import React, { useState } from 'react';
import { FaSearch } from 'react-icons/fa';

const baseInputStyle = {
  width: '100%',
  minHeight: '30px',
  padding: '4px 8px',
  fontSize: '0.9rem',
  color: 'var(--text-color)',
  backgroundColor: 'transparent',
  border: '0.5px solid var(--primary-color)',
  borderRadius: '4px',
  outline: 'none',
  transition: 'box-shadow 0.2s ease',
};

export function StyledInput({
  type = 'text',
  value,
  onChange,
  placeholder,
  style,          // Standardmäßig für den Container bei Checkboxen mit Label oder für das Input-Element, wenn kein Label vorhanden ist
  inputStyle,     // Zusätzlicher Style, der spezifisch auf das <input> angewendet wird, wenn ein Label existiert
  label,          // Label-Text oder React-Node (nur bei Checkboxen verwendet)
  labelStyle,     // Optionales Style-Override für das Label
  containerStyle, // Optionales Style-Override für den Container (Checkbox + Label)
  ...props
}) {
  const [isFocused, setIsFocused] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  // Sonderbehandlung für Checkboxen (unverändert)
  if (type === 'checkbox') {
    if (label) {
      const containerStyles = {
        display: 'flex',
        alignItems: 'center',
        gap: '4px',          // Reduzierter Abstand zwischen Checkbox und Label
        margin: '0',         // Kein zusätzlicher Außenabstand
        lineHeight: 1,       // Verhindert zusätzliche Zeilenhöhe
        ...containerStyle,
        ...style,            // Hier kommen z. B. marginBottom: '-20px' rein
      };

      const checkboxStyle = {
        width: '12px',
        height: '12px',
        accentColor: 'var(--btn-success-bg)', // React verwendet camelCase
        cursor: 'pointer',
        margin: 0,
        padding: 0,
        border: 'none',
        boxShadow: 'none',
        backgroundColor: 'transparent',
        ...inputStyle,
      };

      return (
        <label style={containerStyles}>
          <input
            type="checkbox"
            onChange={onChange}
            style={checkboxStyle}
            {...props}
          />
          <span
            style={{
              fontSize: '0.8rem',
              color: 'var(--text-color)',
              cursor: 'pointer',
              margin: 0,
              ...labelStyle,
            }}
          >
            {label}
          </span>
        </label>
      );
    }

    const checkboxStyle = {
      width: '12px',
      height: '12px',
      accentColor: 'var(--btn-success-bg)',
      cursor: 'pointer',
      margin: 0,
      padding: 0,
      border: 'none',
      boxShadow: 'none',
      backgroundColor: 'transparent',
      ...style,
    };

    return (
      <input
        type="checkbox"
        onChange={onChange}
        style={checkboxStyle}
        {...props}
      />
    );
  }

  // Sonderbehandlung für Suchfelder
  if (type === 'search') {
    return (
      <div
        style={{
          position: 'relative',
          display: 'inline-block',
          width: '100%',
          ...containerStyle,
        }}
      >
        <FaSearch
          style={{
            position: 'absolute',
            left: '8px',
            top: '50%',
            transform: 'translateY(-50%)',
            pointerEvents: 'none',
            color: 'var(--primary-color)',
          }}
        />
        <input
          type="text"
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          style={{
            ...baseInputStyle,
            paddingLeft: '32px', // Platz für das Icon
            boxShadow: isFocused || isHovered ? '0 0 4px var(--primary-color)' : 'none',
            ...style,
          }}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          {...props}
        />
      </div>
    );
  }

  // Standard-Rendering für andere Input-Typen
  return (
    <input
      type={type}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      style={{
        ...baseInputStyle,
        boxShadow: isFocused || isHovered ? '0 0 4px var(--primary-color)' : 'none',
        ...style,
      }}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      {...props}
    />
  );
}

export function StyledTextarea({
  value,
  onChange,
  placeholder,
  style,
  ...props
}) {
  const [isFocused, setIsFocused] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  return (
    <textarea
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      style={{
        ...baseInputStyle,
        minHeight: '60px', // Textarea etwas höher
        resize: 'vertical',
        boxShadow: isFocused || isHovered ? '0 0 4px var(--primary-color)' : 'none',
        ...style,
      }}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      {...props}
    />
  );
}
