// src/components/CurrencyConverter.jsx
import React, { useState, useEffect, useMemo } from 'react';
import api from '../services/api';
import { StyledInput } from '../utils/StyledInput';
import { StyledButton } from '../utils/StyledButton';
import { Trans, t } from '@lingui/macro';
import { formatPrice } from '../utils/formatPrice';
import { useBinanceData } from '../hooks/useBinanceData';
import CurrencyConverterSearchOverlay from './CurrencyConverterSearchOverlay';

const getCoinPrice = (data, coinId, fiatCurrency) => {
  if (data[coinId] && data[coinId][fiatCurrency] != null) {
    return data[coinId][fiatCurrency];
  }
  const lowerId = coinId.toLowerCase();
  if (data[lowerId] && data[lowerId][fiatCurrency] != null) {
    return data[lowerId][fiatCurrency];
  }
  for (const key of Object.keys(data)) {
    if (data[key] && data[key][fiatCurrency] != null) {
      return data[key][fiatCurrency];
    }
  }
  return null;
};

const AnimatedPrice = React.memo(({ value, currency }) => {
  return (
    <span className="animated-price">
      {typeof value === 'number' ? formatPrice(value, currency, false) : value}
    </span>
  );
});

// PriceCard erhält optional die Prop disableAnimation. Ist diese aktiviert, wird der Wert ohne Animation angezeigt.
const PriceCard = React.memo(({ title, priceValue, disableAnimation }) => (
  <div
    style={{
      backgroundColor: 'transparent',
      border: '1px solid var(--secondary-bg-color)',
      borderRadius: '8px',
      padding: '15px',
      position: 'relative'
    }}
  >
    <h3
      style={{
        margin: '0 0 5px 0',
        fontWeight: 'bold',
        color: 'var(--primary-color)',
        textAlign: 'center'
      }}
    >
      {title}
    </h3>
    <p style={{ margin: 0, fontSize: '1.1rem', textAlign: 'center' }}>
      {disableAnimation ? priceValue : <AnimatedPrice value={priceValue} currency="usd" />}
    </p>
  </div>
));

const formatConversionResult = (amount, fromCoin, result, toCoin) => {
  let decimals;
  if (result < 0.0001) {
    decimals = 8;
  } else if (result < 0.1) {
    decimals = 6;
  } else if (result < 1) {
    decimals = 4;
  } else {
    decimals = 4;
  }
  return `${amount} ${fromCoin.name} = ${Number(result).toFixed(decimals)} ${toCoin.name}`;
};

const CurrencyConverter = () => {
  const defaultFrom = { id: 'bitcoin', symbol: 'btc', name: 'Bitcoin' };
  const defaultTo = { id: 'ethereum', symbol: 'eth', name: 'Ethereum' };

  // Betrag als String speichern, um mobile Eingabeprobleme zu vermeiden
  const [amount, setAmount] = useState("1");
  const [fromCoin, setFromCoin] = useState(defaultFrom);
  const [toCoin, setToCoin] = useState(defaultTo);
  const [basePriceFrom, setBasePriceFrom] = useState(null);
  const [basePriceTo, setBasePriceTo] = useState(null);
  const [loadingRate, setLoadingRate] = useState(false);
  const [error, setError] = useState(null);

  const [showFromSelector, setShowFromSelector] = useState(false);
  const [showToSelector, setShowToSelector] = useState(false);

  const fetchConversionRate = async () => {
    setLoadingRate(true);
    setError(null);
    try {
      const fiatCurrency = localStorage.getItem('vsCurrency') || 'usd';

      const respFrom = await api.get('/prices', {
        params: { ids: fromCoin.id, currency: fiatCurrency }
      });
      const priceFrom = getCoinPrice(respFrom.data, fromCoin.id, fiatCurrency);
      if (priceFrom == null) {
        throw new Error(`Preisdaten für ${fromCoin.name} in ${fiatCurrency} nicht gefunden.`);
      }
      setBasePriceFrom(priceFrom);

      const respTo = await api.get('/prices', {
        params: { ids: toCoin.id, currency: fiatCurrency }
      });
      const priceTo = getCoinPrice(respTo.data, toCoin.id, fiatCurrency);
      if (priceTo == null) {
        throw new Error(`Preisdaten für ${toCoin.name} in ${fiatCurrency} nicht gefunden.`);
      }
      setBasePriceTo(priceTo);
    } catch (err) {
      setError('Fehler beim Abrufen des Wechselkurses');
    } finally {
      setLoadingRate(false);
    }
  };

  useEffect(() => {
    fetchConversionRate();
  }, [fromCoin, toCoin]);

  // Websocket-Daten abrufen
  const fromBinance = useBinanceData(fromCoin.symbol);
  const toBinance = useBinanceData(toCoin.symbol);

  const effectiveFromPrice =
    fromBinance.binancePrice !== null && fromBinance.binancePrice !== undefined
      ? fromBinance.binancePrice
      : basePriceFrom;
  const effectiveToPrice =
    toBinance.binancePrice !== null && toBinance.binancePrice !== undefined
      ? toBinance.binancePrice
      : basePriceTo;

  const effectiveConversionRate = useMemo(() => {
    if (effectiveFromPrice != null && effectiveToPrice != null) {
      return effectiveFromPrice / effectiveToPrice;
    }
    return null;
  }, [effectiveFromPrice, effectiveToPrice]);

  const effectiveResult = useMemo(() => {
    const numericAmount = parseFloat(amount) || 0;
    if (effectiveConversionRate != null) {
      return numericAmount * effectiveConversionRate;
    }
    return null;
  }, [effectiveConversionRate, amount]);

  const fiatCurrency = localStorage.getItem('vsCurrency') || 'usd';

  return (
    <div
      style={{
        padding: '20px',
        maxWidth: '600px',
        margin: '20px auto',
        border: '1px solid var(--secondary-bg-color)',
        borderRadius: '8px',
        backgroundColor: 'var(--background-color2)'
      }}
    >
      <h2>
        <Trans id="converter.title">Währungsumrechner</Trans>
      </h2>

      <div style={{ display: 'flex', gap: '20px', marginBottom: '20px' }}>
        <div style={{ flex: 1 }}>
          <strong>
            <Trans id="converter.from">Von:</Trans>
          </strong>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              border: '1px solid var(--secondary-bg-color)',
              borderRadius: '4px',
              padding: '5px',
              marginTop: '5px'
            }}
            onClick={() => setShowFromSelector(true)}
          >
            <span>
              {fromCoin.name} ({fromCoin.symbol.toUpperCase()})
            </span>
          </div>
          {showFromSelector && (
            <CurrencyConverterSearchOverlay
              onSelect={(coin) => setFromCoin(coin)}
              onClose={() => setShowFromSelector(false)}
            />
          )}
          <div style={{ fontSize: '0.9rem', color: 'var(--text-secondary)', marginTop: '3px' }}>
            <Trans id="converter.basePrice">Preis:</Trans>{' '}
            {effectiveFromPrice != null ? formatPrice(effectiveFromPrice, fiatCurrency, false) : '…'}
          </div>
        </div>
        <div style={{ flex: 1 }}>
          <strong>
            <Trans id="converter.to">Zu:</Trans>
          </strong>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              border: '1px solid var(--secondary-bg-color)',
              borderRadius: '4px',
              padding: '5px',
              marginTop: '5px'
            }}
            onClick={() => setShowToSelector(true)}
          >
            <span>
              {toCoin.name} ({toCoin.symbol.toUpperCase()})
            </span>
          </div>
          {showToSelector && (
            <CurrencyConverterSearchOverlay
              onSelect={(coin) => setToCoin(coin)}
              onClose={() => setShowToSelector(false)}
            />
          )}
          <div style={{ fontSize: '0.9rem', color: 'var(--text-secondary)', marginTop: '3px' }}>
            <Trans id="converter.basePrice">Preis:</Trans>{' '}
            {effectiveToPrice != null ? formatPrice(effectiveToPrice, fiatCurrency, false) : '…'}
          </div>
        </div>
      </div>

      <div style={{ marginBottom: '20px' }}>
        <strong>
          <Trans id="converter.amount">Betrag:</Trans>
        </strong>
        <StyledInput
          type="number"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          min="0"
          style={{ marginTop: '5px' }}
        />
      </div>

      <div style={{ marginBottom: '20px', position: 'relative' }}>
        {loadingRate && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(255,255,255,0.7)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '8px'
            }}
          >
            <p style={{ fontStyle: 'italic' }}>
              <Trans id="converter.loadingRate">Wechselkurs wird geladen…</Trans>
            </p>
          </div>
        )}
        {error && <p style={{ color: 'red', textAlign: 'center' }}>{error}</p>}
        {effectiveResult != null && (
          <PriceCard
            title="Umgerechneter Betrag"
            priceValue={formatConversionResult(amount, fromCoin, effectiveResult, toCoin)}
            disableAnimation={true}
          />
        )}
      </div>
    </div>
  );
};

export default CurrencyConverter;
