// src/components/EditProfile.js

import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import { toast } from 'react-toastify';
import { useNavigate, useLocation } from 'react-router-dom';
import '../styles/EditProfile.css';
import { Trans, t } from '@lingui/macro';
import api from '../services/api'; // <-- Neu: Unser zentrales Axios-Objekt
import SocialGoogleLink from './SocialGoogleLink';

// Import der zentralen Komponenten:
import { StyledInput } from '../utils/StyledInput';
import { StyledButton } from '../utils/StyledButton';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';

function translateMessageKey(messageKey) {
  switch (messageKey) {
    case 'success.profileUpdated':
      return t({ id: 'success.profileUpdated', message: 'Profil aktualisiert!' });
    case 'error.updatingProfile':
      return t({ id: 'error.updatingProfile', message: 'Fehler beim Aktualisieren des Profils.' });
    case 'error.loadingProfileData':
      return t({ id: 'error.loadingProfileData', message: 'Fehler beim Laden der Profildaten.' });
    case 'error.serverUpdatingProfile':
      return t({
        id: 'error.serverUpdatingProfile',
        message: 'Serverfehler beim Aktualisieren des Profils.',
      });
    case 'error.emptyUsernameEmail':
      return t({
        id: 'error.emptyUsernameEmail',
        message: 'Benutzername und E-Mail dürfen nicht leer sein!',
      });
    case 'error.passwordPolicy':
      return t({
        id: 'error.passwordPolicy',
        message:
          'Das Passwort muss mindestens 8 Zeichen lang sein, eine Zahl, einen Groß- und Kleinbuchstaben sowie ein Sonderzeichen enthalten.',
      });
    case 'error.passwordsDontMatch':
      return t({
        id: 'error.passwordsDontMatch',
        message: 'Die Passwörter stimmen nicht überein!',
      });
    case 'error.birthdateFuture':
      return t({
        id: 'error.birthdateFuture',
        message: 'Das Geburtsdatum darf nicht in der Zukunft liegen!',
      });
    case 'error.usernameTaken':
      return t({ id: 'error.usernameTaken', message: 'Benutzername ist bereits vergeben!' });
    case 'error.emailTaken':
      return t({ id: 'error.emailTaken', message: 'E-Mail-Adresse ist bereits vergeben!' });
    case 'success.userDeleted':
      return t({ id: 'success.userDeleted', message: 'Benutzerkonto wurde gelöscht.' });
    case 'error.birthdateUnrealistic':
      return t({
        id: 'error.birthdateUnrealistic',
        message: 'Bitte ein realistisches Geburtsdatum eingeben!',
      });
    default:
      return t({ id: 'error.unknown', message: 'Unbekannter Fehler.' });
  }
}

const EditProfile = () => {
  // Profilzustände
  const [username, setUsername] = useState('');
  const [originalUsername, setOriginalUsername] = useState('');
  const [usernameValid, setUsernameValid] = useState(null);
  const [usernameError, setUsernameError] = useState('');
  const [usernameEdited, setUsernameEdited] = useState(false);

  const [email, setEmail] = useState('');
  const [originalEmail, setOriginalEmail] = useState('');
  const [emailValid, setEmailValid] = useState(null);
  const [emailError, setEmailError] = useState('');
  const [emailEdited, setEmailEdited] = useState(false);

  const [newPassword, setNewPassword] = useState('');
  const [newPasswordValid, setNewPasswordValid] = useState(null);
  const [newPasswordError, setNewPasswordError] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [address, setAddress] = useState('');
  const [zip, setZip] = useState('');
  const [country, setCountry] = useState('');
  const [birthdate, setBirthdate] = useState('');
  const [googleLinked, setGoogleLinked] = useState(false);

  const { isLoggedIn, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  // Modal-States fürs Konto löschen
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState('');

  // Regex-Definitionen
  const usernameRegex = /^[a-zA-Z0-9_\-\[\]]{3,16}$/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const newPasswordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,\-./:;<=>?@\[\\\]^_`{|}~])[A-Za-z\d!"#$%&'()*+,\-./:;<=>?@\[\\\]^_`{|}~]{8,}$/;

  // 1) Query-Parameter (linked=1, googleConflict=1, googleError=1)
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get('linked') === '1') {
      toast.success(
        t({
          id: 'social.googleLinkedSuccess',
          message: 'Dein Konto wurde erfolgreich mit Google verknüpft!',
        })
      );
      params.delete('linked');
    }
    if (params.get('googleConflict') === '1') {
      toast.error(
        t({
          id: 'social.googleConflict',
          message:
            'Deine Google-ID ist bereits mit einem anderen Account verknüpft. Trenne dort die Verbindung, wenn du diesen Account verknüpfen möchtest.',
        })
      );
      params.delete('googleConflict');
    }
    if (params.get('googleError') === '1') {
      toast.error(
        t({
          id: 'social.googleLinkError',
          message: 'Fehler beim Google-Link-Vorgang.',
        })
      );
      params.delete('googleError');
    }
    if (params.toString() !== location.search.slice(1)) {
      navigate({ search: params.toString() }, { replace: true });
    }
  }, [location, navigate]);

  // 2) Profil laden
  useEffect(() => {
    if (!isLoggedIn) return;
    (async () => {
      try {
        // GET /api/profile
        const response = await api.get('/profile');
        const data = response.data;

        setUsername(data.username || '');
        setOriginalUsername(data.username || '');
        setEmail(data.email || '');
        setOriginalEmail(data.email || '');
        setFirstName(data.first_name || '');
        setLastName(data.last_name || '');
        setAddress(data.address || '');
        setZip(data.zip || '');
        setCountry(data.country || '');
        if (data.birthdate) {
          const d = new Date(data.birthdate);
          const yyyy = d.getFullYear();
          const mm = String(d.getMonth() + 1).padStart(2, '0');
          const dd = String(d.getDate()).padStart(2, '0');
          setBirthdate(`${yyyy}-${mm}-${dd}`);
        } else {
          setBirthdate('');
        }
        if (data.google_id) {
          setGoogleLinked(true);
        }
      } catch (err) {
        console.error('Fehler beim Laden des Profils:', err);
        toast.error(translateMessageKey('error.loadingProfileData'));
      }
    })();
  }, [isLoggedIn]);

  // 3) Inline-Validierung Benutzername
  const handleUsernameChange = (e) => {
    const newUsername = e.target.value;
    setUsernameEdited(true);
    setUsername(newUsername);
    if (newUsername.length === 0) {
      setUsernameValid(null);
      setUsernameError('');
    } else if (usernameRegex.test(newUsername)) {
      setUsernameValid(true);
      setUsernameError('');
    } else {
      setUsernameValid(false);
      setUsernameError(
        t({
          id: 'error.usernameFormat',
          message:
            'Der Benutzername darf nur Buchstaben, Zahlen, "_", "-", "[" oder "]" enthalten und muss zwischen 3 und 16 Zeichen lang sein.',
        })
      );
    }
  };

  // 4) Asynchrone Verfügbarkeitsprüfung Benutzername
  useEffect(() => {
    if (!usernameEdited || username.length === 0 || !usernameRegex.test(username)) return;

    const delayDebounceFn = setTimeout(async () => {
      try {
        const { data } = await api.get('/check-username', {
          params: { username: username },
        });
        if (!data.available) {
          setUsernameValid(false);
          setUsernameError(
            t({
              id: 'error.usernameTaken',
              message: 'Benutzername ist bereits vergeben!',
            })
          );
        } else {
          setUsernameValid(true);
          setUsernameError('');
        }
      } catch (err) {
        console.error('Fehler bei der Überprüfung des Benutzernamens:', err);
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [username, usernameEdited, usernameRegex]);

  // 5) Inline-Validierung E-Mail
  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmailEdited(true);
    setEmail(newEmail);
    if (newEmail.length === 0) {
      setEmailValid(null);
      setEmailError('');
    } else if (emailRegex.test(newEmail)) {
      setEmailValid(true);
      setEmailError('');
    } else {
      setEmailValid(false);
      setEmailError(
        t({
          id: 'error.invalidEmail',
          message: 'Bitte eine gültige E-Mail-Adresse eingeben.',
        })
      );
    }
  };

  // 6) Asynchrone Verfügbarkeitsprüfung E-Mail
  useEffect(() => {
    if (!emailEdited || email.length === 0 || !emailRegex.test(email)) return;

    const delayDebounceFn = setTimeout(async () => {
      try {
        const { data } = await api.get('/check-email', {
          params: { email: email },
        });
        if (!data.available) {
          setEmailValid(false);
          setEmailError(
            t({
              id: 'error.emailTaken',
              message: 'E-Mail-Adresse ist bereits vergeben!',
            })
          );
        } else {
          setEmailValid(true);
          setEmailError('');
        }
      } catch (err) {
        console.error('Fehler bei der Überprüfung der E-Mail-Adresse:', err);
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [email, emailEdited, emailRegex]);

  // 7) Inline-Validierung neues Passwort
  const handleNewPasswordChange = (e) => {
    const newVal = e.target.value;
    setNewPassword(newVal);
    if (newVal.length === 0) {
      setNewPasswordValid(null);
      setNewPasswordError('');
    } else if (newPasswordRegex.test(newVal)) {
      setNewPasswordValid(true);
      setNewPasswordError('');
    } else {
      setNewPasswordValid(false);
      setNewPasswordError(
        t({
          id: 'error.passwordPolicy',
          message:
            'Das Passwort muss mindestens 8 Zeichen lang sein, eine Zahl, einen Groß- und Kleinbuchstaben sowie ein Sonderzeichen enthalten.',
        })
      );
    }
  };

  // 8) Validierung der gesamten Form
  const validateForm = () => {
    if (!username || !email) {
      toast.error(translateMessageKey('error.emptyUsernameEmail'));
      return false;
    }
    if (!usernameRegex.test(username)) {
      toast.error(translateMessageKey('error.usernameFormat'));
      return false;
    }
    if (usernameEdited && username !== originalUsername && !usernameValid) {
      toast.error(translateMessageKey('error.usernameTaken'));
      return false;
    }
    if (!emailRegex.test(email)) {
      toast.error(translateMessageKey('error.invalidEmail'));
      return false;
    }
    if (emailEdited && email !== originalEmail && !emailValid) {
      toast.error(translateMessageKey('error.emailTaken'));
      return false;
    }
    if (newPassword.trim() !== '') {
      if (!newPasswordRegex.test(newPassword)) {
        toast.error(translateMessageKey('error.passwordPolicy'));
        return false;
      }
      if (newPassword !== confirmPassword) {
        toast.error(translateMessageKey('error.passwordsDontMatch'));
        return false;
      }
    }
    if (birthdate) {
      const selectedDate = new Date(birthdate);
      const today = new Date();
      selectedDate.setHours(0, 0, 0, 0);
      today.setHours(0, 0, 0, 0);

      if (selectedDate > today) {
        toast.error(translateMessageKey('error.birthdateFuture'));
        return false;
      }
      const earliest = new Date(1920, 0, 1);
      if (selectedDate < earliest) {
        toast.error(translateMessageKey('error.birthdateUnrealistic'));
        return false;
      }
      const oldest = new Date();
      oldest.setFullYear(oldest.getFullYear() - 110);
      if (selectedDate < oldest) {
        toast.error(translateMessageKey('error.birthdateUnrealistic'));
        return false;
      }
    }
    return true;
  };

  // 9) Berechnung, ob Speichern-Button deaktiviert
  const isSaveDisabled =
    (!username || !email) ||
    (usernameEdited && usernameValid === false) ||
    (emailEdited && emailValid === false) ||
    (newPassword.trim() !== '' && newPasswordValid === false);

  // 10) Profil speichern
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    const payload = {
      username,
      email,
      first_name: firstName,
      last_name: lastName,
      address,
      zip,
      country,
      birthdate: birthdate || '',
    };
    if (newPassword.trim() !== '') {
      payload.newPassword = newPassword;
    }

    try {
      // PUT /api/profile
      const response = await api.put('/profile', payload);
      const data = response.data;
      toast.success(translateMessageKey(data.messageKey));
      navigate('/profile');
    } catch (err) {
      console.error('Fehler beim Aktualisieren des Profils:', err);
      const respData = err.response?.data;
      if (respData && respData.messageKey) {
        toast.error(translateMessageKey(respData.messageKey));
      } else {
        toast.error(translateMessageKey('error.serverUpdatingProfile'));
      }
    }
  };

  // 11) Konto löschen
  const handleDeleteUser = async () => {
    try {
      // DELETE /api/delete-user
      const response = await api.delete('/delete-user');
      const data = response.data;
      toast.success(translateMessageKey(data.messageKey));
      logout();
      navigate('/');
    } catch (err) {
      console.error('Fehler beim Löschen des Benutzers:', err);
      const respData = err.response?.data;
      if (respData && respData.messageKey) {
        toast.error(translateMessageKey(respData.messageKey));
      } else {
        toast.error('Fehler beim Löschen des Benutzers');
      }
    } finally {
      setShowDeleteModal(false);
      setDeleteConfirmation('');
    }
  };

  // 12) Abbrechen
  const handleCancel = () => {
    navigate('/profile');
  };

  return (
    <div className="editprofile-container">
      <h2>
        <Trans id="title.editProfile">Profil bearbeiten</Trans>
      </h2>
      <form className="editprofile-form" onSubmit={handleSubmit}>
        {/* Benutzername */}
        <div className="form-group">
          <label htmlFor="username">
            <Trans id="placeholder.username">Benutzername</Trans>
          </label>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <StyledInput
              id="username"
              type="text"
              placeholder={t({ id: 'placeholder.username', message: 'Benutzername' })}
              value={username}
              onChange={handleUsernameChange}
              required
              style={{ flex: 1 }}
            />
            {usernameEdited && username.length > 0 && (
              usernameValid ? (
                <FaCheckCircle style={{ marginLeft: '8px', color: 'green' }} />
              ) : (
                <FaTimesCircle style={{ marginLeft: '8px', color: 'red' }} />
              )
            )}
          </div>
          {usernameEdited && username.length > 0 && usernameValid === false && (
            <div className="error" style={{ textAlign: 'left', fontSize: '0.8rem', marginTop: '2px' }}>
              {usernameError}
            </div>
          )}
        </div>

        {/* E-Mail */}
        <div className="form-group">
          <label htmlFor="email">
            <Trans id="placeholder.email">E-Mail</Trans>
          </label>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <StyledInput
              id="email"
              type="email"
              placeholder={t({ id: 'placeholder.email', message: 'E-Mail' })}
              value={email}
              onChange={handleEmailChange}
              required
              style={{ flex: 1 }}
            />
            {emailEdited && email.length > 0 && (
              emailValid ? (
                <FaCheckCircle style={{ marginLeft: '8px', color: 'green' }} />
              ) : (
                <FaTimesCircle style={{ marginLeft: '8px', color: 'red' }} />
              )
            )}
          </div>
          {emailEdited && email.length > 0 && emailValid === false && (
            <div className="error" style={{ textAlign: 'left', fontSize: '0.8rem', marginTop: '2px' }}>
              {emailError}
            </div>
          )}
        </div>

        {/* Vorname */}
        <div className="form-group">
          <label htmlFor="firstName">
            <Trans id="placeholder.firstName">Vorname</Trans>
          </label>
          <StyledInput
            id="firstName"
            type="text"
            placeholder={t({ id: 'placeholder.firstName', message: 'Vorname' })}
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </div>

        {/* Nachname */}
        <div className="form-group">
          <label htmlFor="lastName">
            <Trans id="placeholder.lastName">Nachname</Trans>
          </label>
          <StyledInput
            id="lastName"
            type="text"
            placeholder={t({ id: 'placeholder.lastName', message: 'Nachname' })}
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>

        {/* Adresse */}
        <div className="form-group">
          <label htmlFor="address">
            <Trans id="placeholder.address">Adresse</Trans>
          </label>
          <StyledInput
            id="address"
            type="text"
            placeholder={t({ id: 'placeholder.address', message: 'Adresse' })}
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
        </div>

        {/* PLZ */}
        <div className="form-group">
          <label htmlFor="zip">
            <Trans id="placeholder.zip">PLZ</Trans>
          </label>
          <StyledInput
            id="zip"
            type="text"
            placeholder={t({ id: 'placeholder.zip', message: 'PLZ' })}
            value={zip}
            onChange={(e) => setZip(e.target.value)}
          />
        </div>

        {/* Land */}
        <div className="form-group">
          <label htmlFor="country">
            <Trans id="placeholder.country">Land</Trans>
          </label>
          <StyledInput
            id="country"
            type="text"
            placeholder={t({ id: 'placeholder.country', message: 'Land' })}
            value={country}
            onChange={(e) => setCountry(e.target.value)}
          />
        </div>

        {/* Geburtsdatum */}
        <div className="form-group">
          <label htmlFor="birthdate">
            <Trans id="placeholder.birthdate">Geburtsdatum</Trans>
          </label>
          <StyledInput
            id="birthdate"
            type="date"
            placeholder={t({ id: 'placeholder.birthdate', message: 'Geburtsdatum' })}
            value={birthdate}
            onChange={(e) => setBirthdate(e.target.value)}
          />
        </div>

        {/* Neues Passwort (optional) */}
        <div className="form-group">
          <label htmlFor="newPassword">
            <Trans id="placeholder.newPasswordOptional">Neues Passwort (optional)</Trans>
          </label>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <StyledInput
              id="newPassword"
              type="password"
              placeholder={t({
                id: 'placeholder.newPasswordOptional',
                message: 'Neues Passwort (optional)',
              })}
              value={newPassword}
              onChange={handleNewPasswordChange}
              style={{ flex: 1 }}
            />
            {newPassword.length > 0 &&
              (newPasswordValid ? (
                <FaCheckCircle style={{ marginLeft: '8px', color: 'green' }} />
              ) : (
                <FaTimesCircle style={{ marginLeft: '8px', color: 'red' }} />
              ))}
          </div>
          {newPassword.length > 0 && newPasswordValid === false && (
            <div
              className="error"
              style={{ textAlign: 'left', fontSize: '0.8rem', marginTop: '2px' }}
            >
              {newPasswordError}
            </div>
          )}
        </div>

        {/* Neues Passwort bestätigen */}
        <div className="form-group">
          <label htmlFor="confirmPassword">
            <Trans id="placeholder.confirmNewPassword">Neues Passwort bestätigen</Trans>
          </label>
          <StyledInput
            id="confirmPassword"
            type="password"
            placeholder={t({
              id: 'placeholder.confirmNewPassword',
              message: 'Neues Passwort bestätigen',
            })}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </div>

        <div className="editprofile-form-buttons">
          <StyledButton
            type="submit"
            variant="confirm"
            translationKey="button.saveProfile"
            defaultMessage="Speichern"
            disabled={isSaveDisabled}
          />
          <StyledButton
            type="button"
            variant="cancel"
            translationKey="modal.cancel"
            defaultMessage="Abbrechen"
            onClick={handleCancel}
          />
        </div>
      </form>

      {/* Social-Google-Link */}
      <SocialGoogleLink googleLinked={googleLinked} setGoogleLinked={setGoogleLinked} />

      <StyledButton
        type="button"
        translationKey="button.deleteAccount"
        defaultMessage="Benutzerkonto löschen"
        variant="danger"
        onClick={() => setShowDeleteModal(true)}
        style={{ display: 'block', margin: '20px auto 0' }}
      />

      {showDeleteModal && (
        <div className="confirm-delete-modal-overlay">
          <div className="confirm-delete-modal-content">
            <h3>
              <Trans id="modal.areYouSure">Bist du sicher?</Trans>
            </h3>
            <p>
              <Trans id="modal.cantUndo">
                Dies kann nicht rückgängig gemacht werden!
                Bitte tippe <strong>"Delete"</strong> oder <strong>"Löschen"</strong>, um zu
                bestätigen.
              </Trans>
            </p>
            <StyledInput
              type="text"
              value={deleteConfirmation}
              onChange={(e) => setDeleteConfirmation(e.target.value)}
            />
            <div className="confirm-delete-modal-buttons">
              <StyledButton
                type="button"
                translationKey="modal.cancel"
                defaultMessage="Abbrechen"
                onClick={() => {
                  setShowDeleteModal(false);
                  setDeleteConfirmation('');
                }}
                style={{ marginRight: '10px' }}
              />
              <StyledButton
                type="button"
                translationKey="modal.confirmDelete"
                defaultMessage="Account löschen"
                variant="danger"
                disabled={
                  !(
                    deleteConfirmation.toLowerCase() === 'delete' ||
                    deleteConfirmation.toLowerCase() === 'löschen'
                  )
                }
                onClick={handleDeleteUser}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditProfile;
