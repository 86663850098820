import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // useNavigate importieren
import '../styles/GLOBALSTYLE.css';         // Globale Styles
import '../styles/CasinoSlotMachine.css';   // Angepasste Styles (siehe unten)

// Modal-Komponente
const Modal = ({ message, onClose, onRedirect }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <p>{message}</p>
        <button onClick={onRedirect} className="redirect-button">
          Jetzt spielen
        </button>
        <button onClick={onClose} className="close-button">
          Später
        </button>
      </div>
    </div>
  );
};

const CasinoSlotMachine = () => {
  const navigate = useNavigate(); // useNavigate initialisieren

  // Symbole mit angepassten Gewichten und Werten
  const symbols = [
    { name: 'cherry', icon: '🍒', value: 5, weight: 25 },
    { name: 'lemon', icon: '🍋', value: 10, weight: 20 },
    { name: 'orange', icon: '🍊', value: 15, weight: 15 },
    { name: 'watermelon', icon: '🍉', value: 20, weight: 10 },
    { name: 'bell', icon: '🔔', value: 30, weight: 8 },
    { name: 'star', icon: '⭐', value: 50, weight: 5 },
    { name: 'seven', icon: '7️⃣', value: 100, weight: 2 },
    { name: 'wild', icon: '🌟', value: 0, weight: 5 },
  ];

  const totalWeight = symbols.reduce((sum, symbol) => sum + symbol.weight, 0);

  const [reels, setReels] = useState([
    ['❓', '❓', '❓'],
    ['❓', '❓', '❓'],
    ['❓', '❓', '❓'],
  ]);
  const [spinning, setSpinning] = useState(false);
  const [balance, setBalance] = useState(1000);
  const [message, setMessage] = useState('');
  const [spins, setSpins] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [tooltipMessage, setTooltipMessage] = useState('');
  const [betAmount, setBetAmount] = useState(10);

  const payLines = [
    [
      [0, 0],
      [0, 1],
      [0, 2],
    ],
    [
      [1, 0],
      [1, 1],
      [1, 2],
    ],
    [
      [2, 0],
      [2, 1],
      [2, 2],
    ],
    [
      [0, 0],
      [1, 1],
      [2, 2],
    ],
    [
      [2, 0],
      [1, 1],
      [0, 2],
    ],
  ];

  const getRandomSymbol = () => {
    let randomNum = Math.floor(Math.random() * totalWeight);
    for (const symbol of symbols) {
      if (randomNum < symbol.weight) {
        return symbol;
      }
      randomNum -= symbol.weight;
    }
    return symbols[0];
  };

  const spinReels = () => {
    if (spinning || balance < betAmount) return;

    setSpinning(true);
    setBalance((prev) => prev - betAmount);

    const newReels = reels.map(() =>
      Array(3)
        .fill(null)
        .map(() => getRandomSymbol())
    );
    setReels(newReels);

    setTimeout(() => {
      setSpinning(false);
      calculateResult(newReels);
      setSpins((prev) => prev + 1);

      if ((spins + 1) % 10 === 0) {
        setShowModal(true);
        setTooltipMessage('Viel Spaß? Probiere jetzt echtes Glücksspiel aus!');
      }
    }, 1000);
  };

  const calculateResult = (newReels) => {
    let totalWin = 0;
    let winningLines = [];

    payLines.forEach((line, index) => {
      const lineSymbols = line.map(([row, col]) => newReels[row][col]);
      if (isWinningLine(lineSymbols)) {
        const symbolValue = getSymbolValue(lineSymbols[0]);
        const lineWin = symbolValue * (betAmount / 10);
        totalWin += lineWin;
        winningLines.push(index + 1);
      }
    });

    if (totalWin > 0) {
      setBalance((prev) => prev + totalWin);
      setMessage(
        `Gewonnen! Linie${winningLines.length > 1 ? 'n' : ''} ${winningLines.join(
          ', '
        )} gewinnt ${totalWin} Punkte!`
      );
    } else {
      setMessage('Leider verloren. Versuche es erneut!');
    }
  };

  const isWinningLine = (symbolsLine) => {
    const firstSymbol = symbolsLine[0];
    return symbolsLine.every(
      (symbol) => symbol.name === firstSymbol.name || symbol.name === 'wild' || firstSymbol.name === 'wild'
    );
  };

  const getSymbolValue = (symbol) => {
    if (symbol.name === 'wild') {
      return 20;
    }
    return symbol.value;
  };

  const redirectToCasino = () => {
    window.location.href = 'https://affiliate-link-example.com';
  };

  return (
    <div className="casino-slot-machine">
      <h1>Casino Slot-Maschine</h1>
      <p className="balance">Kontostand: {balance.toFixed(2)} Punkte</p>
      <div className="slot-machine">
        <div className="reels">
          {reels.map((row, rowIndex) => (
            <div key={rowIndex} className="reel-row">
              {row.map((symbolObj, colIndex) => (
                <div key={colIndex} className={`reel ${spinning ? 'spinning' : ''}`}>
                  {typeof symbolObj === 'string' ? (
                    <span>{symbolObj}</span>
                  ) : (
                    <span>{symbolObj.icon}</span>
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>
        <div className="controls">
          <input
            type="number"
            min="10"
            max={balance}
            step="10"
            value={betAmount}
            onChange={(e) => setBetAmount(Number(e.target.value))}
            disabled={spinning}
          />
          <button onClick={spinReels} disabled={spinning || balance < betAmount}>
            {spinning ? 'Dreht...' : 'Drehen'}
          </button>
        </div>
      </div>
      {message && <p className="message">{message}</p>}
      {balance <= 0 && (
        <div className="redirect-message">
          <p>Du hast keine Punkte mehr! Willst du echtes Glücksspiel ausprobieren?</p>
          <button onClick={redirectToCasino}>Zum Casino wechseln</button>
        </div>
      )}
      {showModal && (
        <Modal
          message={tooltipMessage}
          onClose={() => setShowModal(false)}
          onRedirect={redirectToCasino}
        />
      )}
      <button onClick={() => navigate(-1)} className="back-button">Zurück</button>
    </div>
  );
};

export default CasinoSlotMachine;
