// src/components/Header.js

import React, { useState, useContext, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import '../styles/Header.css';

import PriceTicker from './PriceTicker';
import logoHeader from '../assets/logo_header.png';

// Overlays
import SettingsOverlay from './SettingsOverlay';
import SearchOverlay from './SearchOverlay';
import NotificationsOverlay from './NotificationsOverlay';

import { Trans } from '@lingui/macro';
import {
  FaBell,
  FaCog,
  FaSearch,
  FaBars,
  FaChevronRight,
  FaChevronDown
} from 'react-icons/fa';

import { AuthContext } from '../context/AuthContext';
import { NotificationsContext } from '../context/NotificationsContext';

const Header = () => {
  // Holen des gesamten `user`-Objekts + isLoggedIn, logout
  const { isLoggedIn, logout, user } = useContext(AuthContext);
  // Ermitteln der userRole
  const userRole = user?.role;

  const { unreadCount } = useContext(NotificationsContext);
  const location = useLocation();

  // Mobile-Menü
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const isCryptoActive = [
    '/coinlist',
    '/categories',
    '/gainers-losers',
    '/newly-listed',
    '/global-data',
  ].includes(location.pathname);
  const isToolsActive = ['/crypto-bot'].includes(location.pathname);
  const [cryptoMenuOpen, setCryptoMenuOpen] = useState(isCryptoActive);
  const [toolsMenuOpen, setToolsMenuOpen] = useState(isToolsActive);

  // Overlays: Settings, Search, Notifications
  const [showSettings, setShowSettings] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);

  // Theme beim Laden
  useEffect(() => {
    const storedTheme = localStorage.getItem('theme') || 'dark';
    if (storedTheme === 'dark') {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }
  }, []);

  // Globale Klicks => schließe Overlays
  useEffect(() => {
    const handleGlobalClick = () => {
      if (showSettings) setShowSettings(false);
      if (showSearch) setShowSearch(false);
      if (showNotifications) setShowNotifications(false);
    };
    document.addEventListener('mousedown', handleGlobalClick);
    return () => document.removeEventListener('mousedown', handleGlobalClick);
  }, [showSettings, showSearch, showNotifications]);

  // Toggles
  const toggleMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
    setCryptoMenuOpen(isCryptoActive);
    setToolsMenuOpen(isToolsActive);
  };
  const toggleCryptoMenu = () => setCryptoMenuOpen(!cryptoMenuOpen);
  const toggleToolsMenu = () => setToolsMenuOpen(!toolsMenuOpen);
  const handleOverlayClick = () => {
    setShowMobileMenu(false);
    setCryptoMenuOpen(false);
    setToolsMenuOpen(false);
  };

  // Settings
  const toggleSettingsDropdown = (e) => {
    e.stopPropagation();
    setShowSearch(false);
    setShowNotifications(false);
    setShowSettings(!showSettings);
  };
  const handleCloseSettings = () => {
    setShowSettings(false);
  };

  // Search
  const toggleSearchDropdown = (e) => {
    e.stopPropagation();
    setShowSettings(false);
    setShowNotifications(false);
    setShowSearch(!showSearch);
  };
  const handleCloseSearch = () => {
    setShowSearch(false);
  };

  // Notifications
  const toggleNotificationsDropdown = (e) => {
    e.stopPropagation();
    if (!isLoggedIn) return;
    setShowSettings(false);
    setShowSearch(false);
    setShowNotifications(!showNotifications);
  };
  const handleCloseNotifications = () => {
    setShowNotifications(false);
  };

  // Logout
  const handleLogout = () => {
    logout();
  };

  return (
    <header className="header_main">
      <div className="header_content">
        {/* Logo */}
        <div className="header_logo">
          <NavLink to="/" end>
            <img src={logoHeader} alt="Logo" width="220" height="50" />
          </NavLink>
        </div>

        {/* Desktop-Navigation */}
        <nav className="header_nav header_desktop-only">
          <ul>
            <li className="header_has-submenu">
              <span>
                <Trans id="nav.cryptocurrencies">Cryptocurrencies</Trans>
              </span>
              <ul className="header_submenu">
                <li>
                  <NavLink to="/coinlist">
                    <Trans id="nav.coinlist">Alle Coins</Trans>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/categories">
                    <Trans id="nav.categories">Kategorien</Trans>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/gainers-losers">
                    <Trans id="nav.gainersLosers">Gainers &amp; Losers</Trans>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/newly-listed">
                    <Trans id="nav.newCoins">New Coins</Trans>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/global-data">
                    <Trans id="nav.gobalData">Global Data</Trans>
                  </NavLink>
                </li>
              </ul>
            </li>

            {/* Nur SUPERUSER kann /spot-exchanges und /futures-exchanges sehen: */}
            {userRole === 'superuser' && (
              <li className="header_has-submenu">
                <span>
                  <Trans id="nav.exchanges">Exchanges</Trans>
                </span>
                <ul className="header_submenu">
                  <li>
                    <NavLink to="/spot-exchanges">
                      <Trans id="nav.spotmarket">Spot Market</Trans>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/futures-exchanges">
                      <Trans id="nav.futuresmarket">Futures Market</Trans>
                    </NavLink>
                  </li>
                </ul>
              </li>
            )}

            <li className="header_has-submenu">
              <span>
                <Trans id="nav.tools">Tools</Trans>
              </span>
              <ul className="header_submenu">
                <li>
                  <NavLink to="/dashboard" end>
                    <Trans id="nav.dashboard">Dashboard (beta)</Trans>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/widgets">
                    <Trans id="nav.widgets">Widgets</Trans>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/scamgame">
                    <Trans id="nav.scamgame">Scam-Game</Trans>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/converter">
                    <Trans id="nav.converter">Converter</Trans>
                  </NavLink>
                </li>
              </ul>
            </li>

            {/* ADMIN => userRole=== 'admin' || userRole=== 'superuser' */}
            {(userRole === 'admin' || userRole === 'superuser') && (
              <li>
                <NavLink to="/admin">ADMIN</NavLink>
              </li>
            )}
          </ul>
        </nav>

        {/* Actions (Desktop & Mobile) */}
        <div className="header_actions">
          {/* Desktop-Icons (Notifications, Settings, Search) */}
          <div className="header_icon-group header_desktop-only">
            {isLoggedIn && (
              <div
                className="header_btn header_btn-notification"
                onMouseDown={toggleNotificationsDropdown}
                style={{ position: 'relative' }}
              >
                <FaBell />
                {unreadCount > 0 && (
                  <span className="badge-notification">{unreadCount}</span>
                )}
              </div>
            )}
            {showNotifications && (
              <NotificationsOverlay
                styleType="dropdown"
                onClose={handleCloseNotifications}
              />
            )}

            {/* Settings */}
            <div
              className="header_btn header_btn-settings"
              onMouseDown={toggleSettingsDropdown}
            >
              <FaCog />
            </div>
            {showSettings && (
              <SettingsOverlay
                styleType="dropdown"
                onClose={handleCloseSettings}
              />
            )}

            {/* Suche */}
            <div
              className="header_btn header_btn-search"
              onMouseDown={toggleSearchDropdown}
            >
              <FaSearch />
            </div>
            {showSearch && (
              <SearchOverlay styleType="dropdown" onClose={handleCloseSearch} />
            )}
          </div>

          {/* Desktop Userlinks */}
          <div className="header_user-links header_desktop-only">
            {isLoggedIn ? (
              <>
                <NavLink to="/profile" className="header_user-link">
                  <Trans id="nav.profile">Profil</Trans>
                </NavLink>
                <NavLink to="/favorites" className="header_user-link">
                  <Trans id="nav.favorites">Favoriten</Trans>
                </NavLink>
                <span
                  className="header_user-link"
                  onClick={handleLogout}
                  style={{ cursor: 'pointer' }}
                >
                  <Trans id="nav.logout">Logout</Trans>
                </span>
              </>
            ) : (
              <>
                <NavLink to="/login" className="header_user-link">
                  <Trans id="nav.login">Login</Trans>
                </NavLink>
                <NavLink to="/register" className="header_user-link">
                  <Trans id="nav.register">Register</Trans>
                </NavLink>
              </>
            )}
          </div>

          {/* Desktop Ticker */}
          <div className="header_price-ticker-container header_desktop-only">
            <PriceTicker />
          </div>

          {/* Mobile */}
          <div className="header_mobile-action-bar header_mobile-only">
            <div className="header_mobile-icon-group">
              {isLoggedIn && (
                <div
                  className="header_btn header_btn-notification"
                  onMouseDown={toggleNotificationsDropdown}
                  style={{ position: 'relative' }}
                >
                  <FaBell />
                  {unreadCount > 0 && (
                    <span className="badge-notification">{unreadCount}</span>
                  )}
                </div>
              )}
              {showNotifications && (
                <NotificationsOverlay
                  styleType="dropdown"
                  onClose={handleCloseNotifications}
                />
              )}

              <div
                className="header_btn header_btn-settings"
                onMouseDown={toggleSettingsDropdown}
              >
                <FaCog />
              </div>
              {showSettings && (
                <SettingsOverlay
                  styleType="dropdown"
                  onClose={handleCloseSettings}
                />
              )}

              <div
                className="header_btn header_btn-search"
                onMouseDown={toggleSearchDropdown}
              >
                <FaSearch />
              </div>
              {showSearch && (
                <SearchOverlay styleType="dropdown" onClose={handleCloseSearch} />
              )}

              <div
                className="header_btn header_btn-hamburger"
                onClick={toggleMobileMenu}
              >
                <FaBars />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile-Menü Overlay */}
      {showMobileMenu && (
        <div className="header_mobile-menu-overlay" onClick={handleOverlayClick}>
          <nav
            className="header_mobile-main-nav"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="header_mobile-menu-close" onClick={toggleMobileMenu}>
              X
            </div>
            <ul className="header_mobile-menu-list">
              {/* Cryptocurrencies */}
              <li onClick={toggleCryptoMenu}>
                <span style={{ display: 'inline-flex', alignItems: 'center', gap: '6px' }}>
                  <Trans id="nav.cryptocurrencies">Cryptocurrencies</Trans>
                  {cryptoMenuOpen ? <FaChevronDown /> : <FaChevronRight />}
                </span>
                {cryptoMenuOpen && (
                  <ul className="header_submenu-mobile">
                    <li>
                      <NavLink to="/coinlist" onClick={toggleMobileMenu}>
                        <Trans id="nav.coinlist">Coinlist</Trans>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/categories" onClick={toggleMobileMenu}>
                        <Trans id="nav.categories">Kategorien</Trans>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/gainers-losers" onClick={toggleMobileMenu}>
                        <Trans id="nav.gainersLosers">Gainers &amp; Losers</Trans>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/newly-listed" onClick={toggleMobileMenu}>
                        <Trans id="nav.newCoins">New Coins</Trans>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/global-data" onClick={toggleMobileMenu}>
                        <Trans id="nav.gobalData">Global Data</Trans>
                      </NavLink>
                    </li>
                  </ul>
                )}
              </li>

              {/* Tools */}
              <li onClick={toggleToolsMenu}>
                <span style={{ display: 'inline-flex', alignItems: 'center', gap: '6px' }}>
                  <Trans id="nav.tools">Tools</Trans>
                  {toolsMenuOpen ? <FaChevronDown /> : <FaChevronRight />}
                </span>
                {toolsMenuOpen && (
                  <ul className="header_submenu-mobile">
                    <li>
                      <NavLink to="/dashboard" end onClick={toggleMobileMenu}>
                        <Trans id="nav.dashboard">Dashboard (beta)</Trans>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/widgets" onClick={toggleMobileMenu}>
                        <Trans id="nav.widgets">Widgets</Trans>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/scamgame" onClick={toggleMobileMenu}>
                        <Trans id="nav.scamgame">Scam-Game</Trans>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/converter">
                        <Trans id="nav.converter">Converter</Trans>
                      </NavLink>
                    </li>
                  </ul>
                )}
              </li>

              {/* Mobile-Userlinks */}
              <li className="header_user-links-mobile">
                {isLoggedIn ? (
                  <>
                    <NavLink to="/profile" onClick={toggleMobileMenu}>
                      <Trans id="nav.profile">Profil</Trans>
                    </NavLink>
                    <NavLink to="/favorites" onClick={toggleMobileMenu}>
                      <Trans id="nav.favorites">Favoriten</Trans>
                    </NavLink>
                    <span
                      onClick={() => {
                        handleLogout();
                        toggleMobileMenu();
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      <Trans id="nav.logout">Logout</Trans>
                    </span>
                  </>
                ) : (
                  <>
                    <NavLink to="/login" onClick={toggleMobileMenu}>
                      <Trans id="nav.login">Login</Trans>
                    </NavLink>
                    <NavLink to="/register" onClick={toggleMobileMenu}>
                      <Trans id="nav.register">Register</Trans>
                    </NavLink>
                  </>
                )}
              </li>

              {/* Admin => userRole=== 'admin' || 'superuser' */}
              {(userRole === 'admin' || userRole === 'superuser') && (
                <li>
                  <NavLink to="/admin" onClick={toggleMobileMenu}>
                    VERWALTUNG
                  </NavLink>
                </li>
              )}

              {/* Ticker => ganz unten */}
              <li className="header_mobile-ticker">
                <PriceTicker />
              </li>
            </ul>
          </nav>
        </div>
      )}

      {/* GLOBAL Overlay => dunkelt Hintergrund ab (Settings, Search, Notifications) */}
      {(showSettings || showSearch || showNotifications) && (
        <div
          className="header_global-overlay"
          onClick={() => {
            setShowSettings(false);
            setShowSearch(false);
            setShowNotifications(false);
          }}
        />
      )}
    </header>
  );
};

export default Header;
