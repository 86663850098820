// src/components/dashboard/Dashboard.js
import React, { useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { useNavigate } from 'react-router-dom';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { FaLock, FaLockOpen } from 'react-icons/fa';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import './Dashboard.css';

import FearAndGreedMini from './FearAndGreedMini';
import FearAndGreedIndex from '../FearAndGreedIndex';
import NewlyListedCoinsMini from './NewlyListedCoinsMini';
import Top10DominanceMini from './Top10DominanceMini';
import GainersLosersMini from './GainersLosersMini'; // <-- Unser neues Widget
import { StyledButton } from '../../utils/StyledButton';

const ResponsiveGridLayout = WidthProvider(Responsive);

/**
 * DashboardWidget – dein Widget-Wrapper
 */
const DashboardWidget = ({
    size = 'small',
    onSettings = null,
    onFullscreen = null,
    onGotoMain = null,
    children,
}) => {
    const widgetClass = `dashboard-widget dashboard-widget-${size}`;

    return (
        <div className={widgetClass}>
            {/* Header mit Icons */}
            <div className="dashboard-widget-header">
                <div style={{ flex: 1 }}></div>
                <div className="dashboard-widget-icons">
                    {onSettings && (
                        <StyledButton
                            variant="icon"
                            onClick={onSettings}
                            title={t({ id: 'button.settings', message: 'Einstellungen' })}
                        >
                            ⚙
                        </StyledButton>
                    )}
                    {onFullscreen && (
                        <StyledButton
                            variant="icon"
                            onClick={onFullscreen}
                            title={t({ id: 'button.fullscreen', message: 'In Großansicht' })}
                        >
                            ⛶
                        </StyledButton>
                    )}
                    {onGotoMain && (
                        <StyledButton
                            variant="icon"
                            onClick={onGotoMain}
                            title={t({ id: 'button.gotoMain', message: 'Mehr anzeigen' })}
                        >
                            ⇒
                        </StyledButton>
                    )}
                </div>
            </div>

            {/* Inhalt */}
            <div className="dashboard-widget-content">{children}</div>
        </div>
    );
};

/**
 * FullscreenModal – ein einfacher Modal-Wrapper
 */
const FullscreenModal = ({ onClose, children }) => (
    <div className="dashboard-modal-overlay" onClick={onClose}>
        <div className="dashboard-modal-content" onClick={(e) => e.stopPropagation()}>
            <StyledButton
                variant="icon"
                onClick={onClose}
                title="Close"
                className="dashboard-modal-close-btn"
            >
                ✕
            </StyledButton>
            {children}
        </div>
    </div>
);

const Dashboard = () => {
    const navigate = useNavigate();

    // Fullscreen State
    const [isFullscreenOpen, setIsFullscreenOpen] = useState(false);
    const handleFullscreenFearAndGreed = () => setIsFullscreenOpen(true);
    const closeFullscreenModal = () => setIsFullscreenOpen(false);

    // Neuer State zum Sperren/Entsperren der Widget-Verschiebung
    const [isLocked, setIsLocked] = useState(true);

    // States für den Tooltip
    const [tooltipText, setTooltipText] = useState('');
    const [showTooltip, setShowTooltip] = useState(false);

    // Funktion zum Toggle des Locks inkl. Tooltip-Logik
    const handleLockToggle = () => {
        const newLockState = !isLocked;
        setIsLocked(newLockState);
        setTooltipText(
            newLockState
                ? 'Sortierung gespeichert!'
                : 'Du kannst Widgets jetzt verschieben und dir dein Dashboard nach deinen Wünschen einrichten'
        );
        setShowTooltip(true);
        // Tooltip nach 5 Sekunden wieder ausblenden
        setTimeout(() => setShowTooltip(false), 5000);
    };

    /**
     * Breakpoints und Spalten
     */
    const breakpoints = { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 };
    const cols = { lg: 12, md: 10, sm: 8, xs: 6, xxs: 2 };

    /**
     * Layouts
     * 
     *  - newly => (w: 5, h: 11) in lg
     *  - gainersLosers => Ebenfalls (w: 5, h: 11), aber darunter (y versetzt),
     *    damit nichts überlappt.
     */
    const [layouts, setLayouts] = useState({
        lg: [
            { i: 'fearGreed', x: 0, y: 0, w: 3, h: 9 },
            { i: 'top10', x: 3, y: 0, w: 4, h: 10 },
            { i: 'newly', x: 7, y: 0, w: 5, h: 11 },
            // Neu: GainersLosers -> gleiche Breite/Höhe wie "newly", darunter
            { i: 'gainersLosers', x: 7, y: 11, w: 5, h: 11 },
        ],
        md: [
            { i: 'fearGreed', x: 0, y: 0, w: 3, h: 9 },
            { i: 'top10', x: 3, y: 0, w: 3, h: 10 },
            { i: 'newly', x: 6, y: 0, w: 4, h: 11 },
            { i: 'gainersLosers', x: 6, y: 11, w: 4, h: 10 },
        ],
        sm: [
            { i: 'fearGreed', x: 0, y: 0, w: 4, h: 9 },
            { i: 'top10', x: 4, y: 0, w: 4, h: 10 },
            { i: 'newly', x: 0, y: 1, w: 8, h: 11 },
            { i: 'gainersLosers', x: 0, y: 12, w: 8, h: 10 },
        ],
        xs: [
            { i: 'fearGreed', x: 0, y: 0, w: 3, h: 8 },
            { i: 'top10', x: 3, y: 0, w: 3, h: 9 },
            { i: 'newly', x: 0, y: 1, w: 6, h: 11 },
            { i: 'gainersLosers', x: 0, y: 12, w: 6, h: 11 },
        ],
        xxs: [
            { i: 'fearGreed', x: 0, y: 0, w: 1, h: 8 },
            { i: 'top10', x: 1, y: 0, w: 1, h: 7 },
            { i: 'newly', x: 0, y: 1, w: 2, h: 10 },
            { i: 'gainersLosers', x: 0, y: 11, w: 2, h: 11 },
        ],
    });

    const handleLayoutChange = (layout, allLayouts) => {
        setLayouts(allLayouts);
    };

    return (
        <div id="dashboard-container">
            <div className="dashboard-header">
                <h2>
                    <Trans id="dashboard.title">Dashboard</Trans>
                </h2>
                <p>
                    <Trans id="dashboard.subtitle">
                        Willkommen auf deinem neuen Crypto-Dashboard!
                    </Trans>
                </p>
            </div>

            {/* Neue Control-Section direkt über den Widgets */}
            <div className="dashboard-controls">
                <StyledButton
                    variant="icon"
                    onClick={handleLockToggle}
                    title={isLocked ? 'Widgets verschieben freigeben' : 'Widgets verschieben sperren'}
                >
                    {isLocked ? <FaLock /> : <FaLockOpen />}
                </StyledButton>
                {showTooltip && <div className="lock-tooltip">{tooltipText}</div>}
            </div>

            <ResponsiveGridLayout
                className="dashboard-widgets"
                layouts={layouts}
                breakpoints={breakpoints}
                cols={cols}
                rowHeight={30}
                draggableCancel=".dashboard-widget-icons, button"
                isResizable={false}
                // Draggen wird erlaubt, solange das Dashboard nicht gesperrt ist
                isDraggable={!isLocked}
                onLayoutChange={handleLayoutChange}
            >
                <div key="fearGreed">
                    <DashboardWidget size="small" onFullscreen={handleFullscreenFearAndGreed}>
                        <FearAndGreedMini />
                    </DashboardWidget>
                </div>

                <div key="top10">
                    <DashboardWidget size="medium" onGotoMain={() => navigate('/global-data')}>
                        <Top10DominanceMini />
                    </DashboardWidget>
                </div>

                <div key="newly">
                    <DashboardWidget size="large" onGotoMain={() => navigate('/newly-listed')}>
                        <NewlyListedCoinsMini />
                    </DashboardWidget>
                </div>

                {/* Neues Widget: GainersLosers, gleiche Breite/Höhe wie newly */}
                <div key="gainersLosers">
                    <DashboardWidget size="large" onGotoMain={() => navigate('/gainers-losers')}>
                        <GainersLosersMini />
                    </DashboardWidget>
                </div>
            </ResponsiveGridLayout>

            {isFullscreenOpen && (
                <FullscreenModal onClose={closeFullscreenModal}>
                    <FearAndGreedIndex />
                </FullscreenModal>
            )}
        </div>
    );
};

export default Dashboard;
