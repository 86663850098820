// src/App.js
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import 'bootstrap/dist/css/bootstrap.min.css';
import { toast } from 'react-toastify';
import MatomoProvider from './context/MatomoProvider';
import Header from './components/Header';
import LandingPage from './components/LandingPage';
import Dashboard from './components/dashboard/Dashboard';
import CasinoSlotMachine from './components/CasinoSlotMachine';
import CryptoBotSimulator from './components/CryptoBotSimulator';
import TradingSimulator from './components/TradingSimulator';
import TradesPage from './components/TradesPage';
import CoinChart from './components/CoinChart';
import CoinList from './components/CoinList';
import ExchangeList from './components/ExchangeList';
import FuturesExchangeList from './components/FuturesExchangeList';
import FuturesExchangeDetails from './components/FuturesExchangeDetails';
import CategoryList from './components/CategoryList';
import CoinDetails from './components/CoinDetails/CoinDetails';
import NewlyListedCoins from './components/NewlyListedCoins';
import GainersLosers from './components/GainersLosers';
import Login from './components/Login';
import Register from './components/Register';
import ForgotPassword from './components/ForgotPassword';
import VerifyEmail from './components/VerifyEmail';
import ResetPassword from './components/ResetPassword';
import Profile from './components/Profile';
import EditProfile from './components/EditProfile';
import ProtectedRoute from './components/ProtectedRoute';
import UserFavoritesManager from './components/favorites/UserFavoritesManager';
import { FavoritesProvider } from './context/FavoritesContext';
import AdminUserManagement from './components/admin/AdminUserManagement';
import AdminNotifications from './components/admin/AdminNotifications';
import AdminStats from './components/admin/AdminStats';
import AdminContactMessages from './components/admin/AdminContactMessages';
import AdminConfig from './components/admin/AdminConfig';
import OAuthRedirect from './components/OAuthRedirect';
import { AuthProvider } from './context/AuthContext';
import { ToastContainer } from 'react-toastify';
import { I18nProvider, Trans } from '@lingui/react';
import { t } from '@lingui/macro';
import { i18n } from '@lingui/core';
import { messages as messagesDe } from './locales/de/messages.ts';
import { messages as messagesEn } from './locales/en/messages.ts';
import 'react-toastify/dist/ReactToastify.css';
import { BinanceDataProvider } from './hooks/BinanceDataContext';
import './styles/GLOBALSTYLE.css';
import './styles/GLOBALSTYLE_DARK.css';
import './App.css';
import GlobalData from './components/GlobalData';
import UserInfoModal from './components/UserInfoModal';
import Footer from './components/Footer';
import LegalNotice from './components/LegalNotice';
import { NotificationsProvider } from './context/NotificationsContext';

// SCAMGAME-KOMPONENTEN
import ScamGame from './components/ScamGame/ScamGame';
import ScamGameResults from './components/ScamGame/ScamGameResults';

// WIDGET-KOMPONENTEN
import WidgetDashboard from './components/widgets/WidgetDashboard';
import WidgetBuilder from './components/widgets/WidgetBuilder';

// NEU: ContactForm
import ContactForm from './components/ContactForm';
import Breadcrumbs from './components/Breadcrumbs';

// Overlays
import OfflineOverlay from './components/OfflineOverlay';
import CaptchaOverlay from './components/CaptchaOverlay'; // Captcha
import RateLimitOverlay from './components/RateLimitOverlay'; // Unser NEUES externes Overlay

// Neu: CryptoJourney Wireframe (Interaktives Intro)
import CryptoJourney from './components/CryptoJourney/CryptoJourney';

import CurrencyConverter from './components/CurrencyConverter';



// --- Helper Function für globale Logout-Toast-Übersetzungen ---
// Diese Funktion übersetzt die Keys, die vom api.js Interceptor kommen können.
// Sie wird HIER definiert, da sie in App.js verwendet wird.
function translateMessageKeyGlobal(messageKey, i18nInstance) {
  // Prüfe, ob die i18n Instanz gültig ist
  if (!i18nInstance || !i18nInstance._) {
      console.error("translateMessageKeyGlobal: Ungültige i18n Instanz!");
      // Gib den Key als Fallback zurück
      return messageKey;
  }

  switch (messageKey) {
    case 'error.accountLocked':
      // Stelle sicher, dass t() korrekt aufgerufen wird
      return i18nInstance._(t({ id: 'login.toast.accountLocked', message: 'Dein Account ist aktuell gesperrt!' }));
    case 'error.userBanned':
      return i18nInstance._(t({ id: 'login.toast.userBanned', message: 'Dein Account wurde vom Admin gesperrt!' }));
    case 'error.tokenVersionMismatch':
      return i18nInstance._(t({ id: 'login.toast.tokenVersionMismatch', message: 'Sitzung auf anderem Gerät aktiv oder abgelaufen. Bitte neu einloggen.' }));
    case 'error.invalidOrExpiredRefreshToken':
      return i18nInstance._(t({ id: 'login.toast.invalidRefreshToken', message: 'Deine Sitzung ist abgelaufen. Bitte neu einloggen.' }));
    case 'error.refreshFailed':
      return i18nInstance._(t({ id: 'login.toast.refreshFailed', message: 'Sitzung konnte nicht erneuert werden. Bitte neu einloggen.' }));
    case 'error.refreshError':
      return i18nInstance._(t({ id: 'login.toast.refreshError', message: 'Fehler beim Erneuern der Sitzung. Bitte neu einloggen.' }));
    // Fallback für unbekannte Keys
    default:
      console.warn(`Unbekannter MessageKey in translateMessageKeyGlobal: ${messageKey}`);
      // Besserer Fallback-Text
      return i18nInstance._(t({ id: 'error.unknownLogoutReason', message: `Du wurdest abgemeldet (Grund: ${messageKey}). Bitte neu einloggen.` }));
  }
}


const AppContent = () => {
  return (
    <div className="app-container">
      <Header />
      <Breadcrumbs />
      <div className="app-content">
        <Routes>
          {/* Neue Route für die interaktive Crypto Journey */}
          <Route path="/intro" element={<CryptoJourney />} />
          <Route index element={<CoinList />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/start-test" element={<LandingPage />} />
          <Route path="/coinlist" element={<CoinList />} />
          <Route path="/casino" element={<CasinoSlotMachine />} />
          <Route path="/crypto-bot" element={<CryptoBotSimulator />} />
          <Route path="/trading-simulator" element={<TradingSimulator />} />
          <Route path="/trades" element={<TradesPage />} />
          <Route path="/coin-chart/:coinId" element={<CoinChart />} />
          <Route path="/spot-exchanges" element={<ExchangeList />} />
          <Route path="/futures-exchanges" element={<FuturesExchangeList />} />
          <Route path="/futures-exchange-details" element={<FuturesExchangeDetails />} />
          <Route path="/categories" element={<CategoryList />} />
          <Route path="/coin-details" element={<CoinDetails />} />
          <Route path="/newly-listed" element={<NewlyListedCoins />} />
          <Route path="/gainers-losers" element={<GainersLosers />} />
          <Route path="/global-data" element={<GlobalData />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/oauth-redirect" element={<OAuthRedirect />} />
          <Route path="/legal-notice" element={<LegalNotice />} />
          <Route path="/contact" element={<ContactForm />} />
          <Route path="/scamgame" element={<ScamGame />} />
          <Route path="/scamgame/results" element={<ScamGameResults />} />
          <Route path="/widgets" element={<WidgetDashboard />} />
          <Route path="/widgets/new" element={<WidgetBuilder mode="create" />} />
          <Route path="/widgets/:id/edit" element={<WidgetBuilder mode="edit" />} />
          <Route path="/converter" element={<CurrencyConverter />} />

          <Route
            path="/profile"
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          />
          <Route
            path="/profile/edit"
            element={
              <ProtectedRoute>
                <EditProfile />
              </ProtectedRoute>
            }
          />
          <Route
            path="/favorites"
            element={
              <ProtectedRoute>
                <UserFavoritesManager />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin"
            element={
              <ProtectedRoute adminOnly={true}>
                <AdminUserManagement />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-notifications"
            element={
              <ProtectedRoute adminOnly={true}>
                <AdminNotifications />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-contact-messages"
            element={
              <ProtectedRoute adminOnly={true}>
                <AdminContactMessages />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-stats"
            element={
              <ProtectedRoute adminOnly={true}>
                <AdminStats />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-config"
            element={
              <ProtectedRoute adminOnly={true}>
                <AdminConfig />
              </ProtectedRoute>
            }
          />
        </Routes>
      </div>
      <Footer />
    </div>
  );
};

const App = () => {
  const [showUserInfo, setShowUserInfo] = useState(false);
  const [captchaRequired, setCaptchaRequired] = useState(false);
  const [rateLimitExceeded, setRateLimitExceeded] = useState(false);

  // Captcha (429 + X-Captcha-Required)
  useEffect(() => {
    const handleCaptchaEvent = (e) => {
      setCaptchaRequired(e.detail);
    };
    window.addEventListener('captchaRequired', handleCaptchaEvent);
    return () => {
      window.removeEventListener('captchaRequired', handleCaptchaEvent);
    };
  }, []);

  // Rate Limit (429 ohne X-Captcha-Required)
  useEffect(() => {
    const handleRateLimitEvent = (e) => {
      setRateLimitExceeded(e.detail);
    };
    window.addEventListener('rateLimitExceeded', handleRateLimitEvent);
    return () => {
      window.removeEventListener('rateLimitExceeded', handleRateLimitEvent);
    };
  }, []);

  // Captcha solved
  const handleCaptchaSolved = async (token) => {
    try {
      const response = await fetch('/api/verify-captcha', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token }),
        credentials: 'include',
      });
      if (response.ok) {
        toast.success(t({ id: 'success.captchaVerified', message: 'Captcha erfolgreich verifiziert!' }), {
          position: 'bottom-right',
        });
        setCaptchaRequired(false);
      } else {
        toast.error(t({ id: 'error.captchaVerificationFailed', message: 'Captcha-Verifizierung fehlgeschlagen.' }), {
          position: 'bottom-right',
        });
      }
    } catch (error) {
      console.error('Captcha verification error:', error);
      toast.error(t({ id: 'error.internalServerError', message: 'Interner Serverfehler' }), {
        position: 'bottom-right',
      });
    }
  };

  useEffect(() => {
    // Dark Mode
    const storedMode = localStorage.getItem('darkMode');
    if (storedMode === 'true') {
      document.body.classList.add('dark-mode');
    }

    // Sprache laden
    const storedLanguage = localStorage.getItem('language') || 'de';
    i18n.load({
      de: messagesDe,
      en: messagesEn,
    });
    i18n.activate(storedLanguage);

    // Info-Modal
    const userInfoDismissedAt = localStorage.getItem('userInfoDismissedAt');
    if (!userInfoDismissedAt) {
      setShowUserInfo(true);
    } else {
      const dismissedDate = new Date(parseInt(userInfoDismissedAt, 10));
      const twoWeeksAgo = new Date();
      twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);
      if (dismissedDate < twoWeeksAgo) {
        setShowUserInfo(true);
      }
    }
  }, []);


   // *** NEUER useEffect Hook für globale Logout-Grund-Anzeige ***
   useEffect(() => {
    const reason = sessionStorage.getItem('logoutReason');
    if (reason) {
      // Prüfe, ob i18n bereit ist (hat eine aktive Locale)
      if (i18n.locale) {
          console.log("App.js: Found logoutReason in sessionStorage:", reason);

          // Nachricht holen & übersetzen
          const message = translateMessageKeyGlobal(reason, i18n); // Nutzt die neue Funktion
          let toastType = 'warn'; // Standard

          // Bestimme Typ basierend auf Grund
          if (['error.userBanned', 'error.accountLocked', 'error.refreshFailed', 'error.refreshError'].includes(reason)) {
              toastType = 'error';
          }

          // Toast anzeigen
          console.log(`App.js: Displaying ${toastType} toast for reason: ${reason}, message: "${message}"`);
          if (toastType === 'error') {
              toast.error(message, { position: 'top-right', autoClose: 7000 });
          } else {
              toast.warn(message, { position: 'top-right', autoClose: 7000 });
          }

          // Grund aus sessionStorage entfernen
          sessionStorage.removeItem('logoutReason');
          console.log("App.js: Removed logoutReason from sessionStorage.");

      } else {
          // Fallback, falls i18n noch nicht bereit ist (sollte selten vorkommen)
          console.warn("App.js: logoutReason found, but i18n not ready yet. Displaying fallback toast.");
          // Zeige Toast mit Fallback-Text oder Key
          toast.warn(`Logout Grund: ${reason} (Übersetzung evtl. nicht geladen)`, { position: 'top-right', autoClose: 7000 });
          sessionStorage.removeItem('logoutReason'); // Trotzdem entfernen!
      }
    }
    // Dieser Effekt soll nur einmal pro Seitenaufruf/Reload laufen
  }, []); // Leeres Abhängigkeitsarray!
  // *** ENDE Neuer useEffect Hook ***

  const handleConfirmUserInfo = () => {
    localStorage.setItem('userInfoDismissedAt', Date.now().toString());
    setShowUserInfo(false);
  };

  return (
    <I18nProvider i18n={i18n}>
      <BinanceDataProvider>
        <Router>
        <MatomoProvider />
          <Helmet defaultTitle="CryptoScan - Your Crypto Platform" />
          <AuthProvider>
            <FavoritesProvider>
              <NotificationsProvider>
                {showUserInfo && (
                  <UserInfoModal onConfirm={handleConfirmUserInfo}>
                    <h3>
                      <Trans id="modal.development.title">Hinweis</Trans>
                    </h3>
                    <p>
                      <Trans id="modal.development.message">
                        Diese Seite befindet sich noch in der Entwicklung. Fehler können auftreten.
                      </Trans>
                    </p>
                    <p>
                      <Trans id="modal.development.message.cookies">
                        Wir verwenden Cookies, um die Benutzerfreundlichkeit zu verbessern.
                      </Trans>
                    </p>
                  </UserInfoModal>
                )}

                {/* Zeige RateLimitOverlay (Polling), falls 429 => rateLimitExceeded */}
                {rateLimitExceeded && <RateLimitOverlay />}

                {/* Zeige CaptchaOverlay, falls 429 => X-Captcha-Required */}
                {captchaRequired && <CaptchaOverlay onCaptchaSolved={handleCaptchaSolved} />}

                <OfflineOverlay />
                <AppContent />
                <ToastContainer />
              </NotificationsProvider>
            </FavoritesProvider>
          </AuthProvider>
        </Router>
      </BinanceDataProvider>
    </I18nProvider>
  );
};

export default App;
