// src/components/Register.js

import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import '../styles/Register.css';
import { AuthContext } from '../context/AuthContext';
import { toast } from 'react-toastify';
import { t, Trans } from '@lingui/macro';
import { i18n } from '@lingui/core';
import { FaGoogle, FaUserPlus, FaCheckCircle, FaTimesCircle } from 'react-icons/fa';

// Import unserer zentralen Komponenten
import { StyledInput } from '../utils/StyledInput';
import { StyledButton } from '../utils/StyledButton';

import api from '../services/api'; // Neu: unser Axios-Client

function translateMessageKey(messageKey) {
  switch (messageKey) {
    case 'error.usernamePasswordEmailRequired':
      return t({
        id: 'error.usernamePasswordEmailRequired',
        message: 'Benutzername, Passwort und E-Mail sind erforderlich!',
      });
    case 'error.usernameOrEmailTaken':
      return t({
        id: 'error.usernameOrEmailTaken',
        message: 'Benutzername oder E-Mail bereits registriert!',
      });
    case 'error.creatingUser':
      return t({
        id: 'error.creatingUser',
        message: 'Fehler beim Erstellen des Benutzers',
      });
    case 'error.sendingVerificationEmail':
      return t({
        id: 'error.sendingVerificationEmail',
        message: 'Fehler beim Senden der Bestätigungs-E-Mail',
      });
    case 'error.registrationDisabled':
      return t({
        id: 'error.registrationDisabled',
        message: 'Die Registrierung ist derzeit deaktiviert.',
      });
    case 'success.userRegistered':
      return t({
        id: 'success.userRegistered',
        message: 'Benutzer erfolgreich registriert. Bitte überprüfe deine E-Mail zur Bestätigung.',
      });
    case 'error.internalServerError':
      return t({
        id: 'error.internalServerError',
        message: 'Interner Serverfehler',
      });
    case 'error.captchaNotSolved':
      return t({
        id: 'error.captchaNotSolved',
        message: 'Bitte das Captcha lösen, um fortzufahren.',
      });
    default:
      return t({
        id: 'error.unknown',
        message: 'Unbekannter Fehler.',
      });
  }
}

const Register = () => {
  const { isLoggedIn } = useContext(AuthContext);

  // States
  const [username, setUsername] = useState('');
  const [usernameValid, setUsernameValid] = useState(null);
  const [usernameError, setUsernameError] = useState('');

  const [password, setPassword] = useState('');
  const [passwordValid, setPasswordValid] = useState(null);
  const [passwordError, setPasswordError] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const [email, setEmail] = useState('');
  const [emailValid, setEmailValid] = useState(null);
  const [emailError, setEmailError] = useState('');

  const [inputError, setInputError] = useState('');

  const navigate = useNavigate();
  const storedTheme = localStorage.getItem('theme') || 'dark';
  const isDarkMode = storedTheme === 'dark';
  const storedLang = localStorage.getItem('language') || 'en';

  // reCAPTCHA Site-Key
  const siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

  // reCAPTCHA Script laden
  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?hl=${storedLang}`;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, [storedLang]);

  // Falls bereits eingeloggt => redirect
  useEffect(() => {
    if (isLoggedIn) {
      navigate('/');
    }
  }, [isLoggedIn, navigate]);

  // Regex-Definitionen
  const usernameRegex = /^[a-zA-Z0-9_\-\[\]]{3,16}$/;
  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,\-./:;<=>?@\[\\\]^_`{|}~])[A-Za-z\d!"#$%&'()*+,\-./:;<=>?@\[\\\]^_`{|}~]{8,}$/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Eingabe-Validierung
  const validateInputs = () => {
    if (!usernameRegex.test(username)) {
      const msg = t({
        id: 'error.usernameFormat',
        message:
          'Der Benutzername darf nur Buchstaben, Zahlen, "_", "-", "[" oder "]" enthalten und muss zwischen 3 und 16 Zeichen lang sein.',
      });
      setInputError(msg);
      toast.error(msg);
      return false;
    }
    if (usernameValid === false) {
      const msg = t({
        id: 'error.usernameTaken',
        message: 'Benutzername ist bereits vergeben!',
      });
      setInputError(msg);
      toast.error(msg);
      return false;
    }
    if (!passwordRegex.test(password)) {
      const msg = t({
        id: 'error.passwordPolicy',
        message:
          'Das Passwort muss mindestens 8 Zeichen lang sein, eine Zahl, einen Groß- und Kleinbuchstaben sowie ein Sonderzeichen enthalten.',
      });
      setInputError(msg);
      toast.error(msg);
      return false;
    }
    if (!emailRegex.test(email)) {
      const msg = t({
        id: 'error.invalidEmail',
        message: 'Bitte eine gültige E-Mail-Adresse eingeben.',
      });
      setInputError(msg);
      toast.error(msg);
      return false;
    }
    if (emailValid === false) {
      const msg = t({
        id: 'error.emailTaken',
        message: 'E-Mail-Adresse ist bereits vergeben!',
      });
      setInputError(msg);
      toast.error(msg);
      return false;
    }
    setInputError('');
    return true;
  };

  // Inline-Validierung Username
  const handleUsernameChange = (e) => {
    const newVal = e.target.value;
    setUsername(newVal);

    if (newVal.length === 0) {
      setUsernameValid(null);
      setUsernameError('');
    } else if (usernameRegex.test(newVal)) {
      setUsernameValid(true);
      setUsernameError('');
    } else {
      setUsernameValid(false);
      setUsernameError(
        t({
          id: 'error.usernameFormat',
          message:
            'Der Benutzername darf nur Buchstaben, Zahlen, "_", "-", "[" oder "]" enthalten und muss zwischen 3 und 16 Zeichen lang sein.',
        })
      );
    }
  };

  // Asynchrone Verfügbarkeit Username
  useEffect(() => {
    if (username.length === 0 || !usernameRegex.test(username)) return;

    const delayDebounceFn = setTimeout(() => {
      // => Axios: GET /api/check-username?username=...
      api
        .get('/check-username', {
          params: { username },
        })
        .then(({ data }) => {
          if (!data.available) {
            setUsernameValid(false);
            setUsernameError(
              t({
                id: 'error.usernameTaken',
                message: 'Benutzername ist bereits vergeben!',
              })
            );
          } else {
            setUsernameValid(true);
            setUsernameError('');
          }
        })
        .catch((err) => {
          console.error('Fehler bei der Überprüfung des Benutzernamens:', err);
          // Im Fehlerfall lassen wir den Status unverändert.
        });
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [username]);

  // Inline-Validierung Email
  const handleEmailChange = (e) => {
    const newVal = e.target.value;
    setEmail(newVal);

    if (newVal.length === 0) {
      setEmailValid(null);
      setEmailError('');
    } else if (emailRegex.test(newVal)) {
      setEmailValid(true);
      setEmailError('');
    } else {
      setEmailValid(false);
      setEmailError(
        t({
          id: 'error.invalidEmail',
          message: 'Bitte eine gültige E-Mail-Adresse eingeben.',
        })
      );
    }
  };

  // Asynchrone Verfügbarkeit Email
  useEffect(() => {
    if (email.length === 0 || !emailRegex.test(email)) return;

    const delayDebounceFn = setTimeout(() => {
      // => Axios: GET /api/check-email?email=...
      api
        .get('/check-email', {
          params: { email },
        })
        .then(({ data }) => {
          if (!data.available) {
            setEmailValid(false);
            setEmailError(
              t({
                id: 'error.emailTaken',
                message: 'E-Mail-Adresse ist bereits vergeben!',
              })
            );
          } else {
            setEmailValid(true);
            setEmailError('');
          }
        })
        .catch((err) => {
          console.error('Fehler bei der Überprüfung der E-Mail-Adresse:', err);
        });
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [email]);

  // Inline-Validierung Passwort
  const handlePasswordChange = (e) => {
    const newPass = e.target.value;
    setPassword(newPass);

    if (newPass.length === 0) {
      setPasswordValid(null);
      setPasswordError('');
    } else if (passwordRegex.test(newPass)) {
      setPasswordValid(true);
      setPasswordError('');
    } else {
      setPasswordValid(false);
      setPasswordError(
        t({
          id: 'error.passwordPolicy',
          message:
            'Das Passwort muss mindestens 8 Zeichen lang sein, eine Zahl, einen Groß- und Kleinbuchstaben sowie ein Sonderzeichen enthalten.',
        })
      );
    }
  };

  // Registrierung
  const handleRegister = async (e) => {
    e.preventDefault();
    if (!validateInputs()) {
      return;
    }

    // reCAPTCHA v2 => getResponse()
    const recaptchaToken = window.grecaptcha?.getResponse();
    if (!recaptchaToken) {
      toast.error(translateMessageKey('error.captchaNotSolved'), {
        position: 'bottom-right',
      });
      return;
    }

    try {
      // => Axios: POST /api/register
      const resp = await api.post(
        '/register',
        {
          username,
          password,
          email,
          recaptchaToken,
        },
        {
          headers: {
            'Accept-Language': i18n.locale,
          },
        }
      );

      const data = resp.data;
      toast.success(translateMessageKey(data.messageKey));
      navigate('/login');
    } catch (err) {
      // Fehler abfangen
      if (err.response && err.response.data && err.response.data.messageKey) {
        toast.error(translateMessageKey(err.response.data.messageKey));
      } else {
        toast.error(translateMessageKey('error.internalServerError'));
      }
    } finally {
      window.grecaptcha?.reset();
    }
  };

  return (
    <div className="register-container">
      <h2>
        <Trans id="title.register">Registrieren</Trans>
      </h2>
      <form onSubmit={handleRegister}>
        {/* Benutzername + Icon */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <StyledInput
            type="text"
            placeholder={t({ id: 'placeholder.username', message: 'Benutzername' })}
            value={username}
            onChange={handleUsernameChange}
            required
            style={{ flex: 1 }}
          />
          {username.length > 0 && (
            usernameValid ? (
              <FaCheckCircle style={{ marginLeft: '8px', color: 'green' }} />
            ) : (
              <FaTimesCircle style={{ marginLeft: '8px', color: 'red' }} />
            )
          )}
        </div>
        {username.length > 0 && usernameValid === false && (
          <div
            className="error"
            style={{ textAlign: 'left', fontSize: '0.8rem', marginTop: '-16px' }}
          >
            {usernameError}
          </div>
        )}

        {/* Passwort + Icon */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <StyledInput
            type={showPassword ? 'text' : 'password'}
            placeholder={t({ id: 'placeholder.password', message: 'Passwort' })}
            value={password}
            onChange={handlePasswordChange}
            required
            style={{ flex: 1 }}
          />
          {password.length > 0 && (
            passwordValid ? (
              <FaCheckCircle style={{ marginLeft: '8px', color: 'green' }} />
            ) : (
              <FaTimesCircle style={{ marginLeft: '8px', color: 'red' }} />
            )
          )}
        </div>
        {password.length > 0 && passwordValid === false && (
          <div
            className="error"
            style={{ textAlign: 'left', fontSize: '0.8rem', marginTop: '-16px' }}
          >
            {passwordError}
          </div>
        )}

        {/* Checkbox => Passwort anzeigen */}
        {password.length > 0 && (
          <div className="password-toggle">
            <input
              type="checkbox"
              id="showPassword"
              checked={showPassword}
              onChange={(e) => setShowPassword(e.target.checked)}
            />
            <label htmlFor="showPassword">
              <Trans id="label.showPassword">Passwort anzeigen</Trans>
            </label>
          </div>
        )}

        {/* E-Mail + Icon */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <StyledInput
            type="email"
            placeholder={t({ id: 'placeholder.email', message: 'E-Mail' })}
            value={email}
            onChange={handleEmailChange}
            required
            style={{ flex: 1 }}
          />
          {email.length > 0 && (
            emailValid ? (
              <FaCheckCircle style={{ marginLeft: '8px', color: 'green' }} />
            ) : (
              <FaTimesCircle style={{ marginLeft: '8px', color: 'red' }} />
            )
          )}
        </div>
        {email.length > 0 && emailValid === false && (
          <div
            className="error"
            style={{ textAlign: 'left', fontSize: '0.8rem', marginTop: '-16px' }}
          >
            {emailError}
          </div>
        )}

        {/* reCAPTCHA Widget */}
        <div
          className="g-recaptcha"
          data-sitekey={siteKey}
          data-theme={isDarkMode ? 'dark' : 'light'}
        ></div>

        {/* Registrierungs-Button */}
        <StyledButton type="submit" variant="register">
          <Trans id="button.register">Registrieren</Trans>
        </StyledButton>
      </form>

      <div className="google-login">
        <StyledButton
          variant="google"
          href="/api/auth/google?mode=register"
          style={{
            marginTop: '20px',
          }}
        >
          <Trans id="register.googleRegisterBtn">Mit Google registrieren</Trans>
        </StyledButton>
      </div>

      <div className="links">
        <p>
          <Trans id="message.alreadyRegistered">Bereits registriert?</Trans>{' '}
          <Link to="/login">
            <Trans id="link.loginHere">Hier einloggen</Trans>
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Register;
