// /src/components/WhatIsCrypto.js
import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import '../styles/WhatIsCrypto.css'; // Eigene CSS-Datei

import CryptoGraphic from '../assets/logo_whatiscrypto.png'; // Grafik importieren

const WhatIsCrypto = () => {
  // Definition der Popovers
  const TradingPopover = (
    <Popover id="popover-trading">
      <Popover.Header as="h3">Trading</Popover.Header>
      <Popover.Body>
        Beim Trading kaufst und verkaufst du Kryptowährungen, um von Preisänderungen zu profitieren.
        Es ist wichtig, den Markt genau zu beobachten und fundierte Entscheidungen zu treffen.
      </Popover.Body>
    </Popover>
  );

  const ExchangesPopover = (
    <Popover id="popover-exchanges">
      <Popover.Header as="h3">Exchanges</Popover.Header>
      <Popover.Body>
        Exchanges sind Plattformen, auf denen du Kryptowährungen kaufen, verkaufen und handeln kannst.
        Achte auf Sicherheitsmaßnahmen, Gebühren und die angebotenen Kryptowährungen.
      </Popover.Body>
    </Popover>
  );

  const BuySellPopover = (
    <Popover id="popover-buy-sell">
      <Popover.Header as="h3">Kauf & Verkauf</Popover.Header>
      <Popover.Body>
        <strong>Kauf:</strong> Erwerb von Kryptowährungen zu einem aktuellen Marktpreis.
        <br />
        <strong>Verkauf:</strong> Verkauf deiner Kryptowährungen, um Gewinne zu realisieren oder Verluste zu minimieren.
      </Popover.Body>
    </Popover>
  );

  const MarketPopover = (
    <Popover id="popover-market">
      <Popover.Header as="h3">Marktlage & Preisbewegungen</Popover.Header>
      <Popover.Body>
        Die Marktlage beeinflusst die Preise von Kryptowährungen stark. Faktoren wie Nachfrage, Angebot, Nachrichten und globale Ereignisse können zu Preissteigerungen oder -senkungen führen.
      </Popover.Body>
    </Popover>
  );

  const StrategiesPopover = (
    <Popover id="popover-strategies">
      <Popover.Header as="h3">Grundlegende Handelsstrategien</Popover.Header>
      <Popover.Body>
        <strong>Day Trading:</strong> Täglicher Kauf und Verkauf, um von kurzfristigen Preisbewegungen zu profitieren.
        <br />
        <strong>HODLing:</strong> Langfristiges Halten von Kryptowährungen, um von langfristigen Preissteigerungen zu profitieren.
      </Popover.Body>
    </Popover>
  );

  return (
    <div className="what-is-crypto">
      {/* Bild oben, linksbündig */}
      <img
        src={CryptoGraphic}
        alt="Was ist Krypto?"
        className="crypto-graphic-header"
      />

      <div className="overview-content">
        <div className="overview-text">
          <p>
            Kryptowährungen sind digitale oder virtuelle Währungen, die Kryptografie für
            Sicherheit verwenden. Sie sind dezentralisiert und basieren oft auf der Blockchain-Technologie.
            Entdecke die Welt der digitalen Assets und wie sie die Finanzwelt revolutionieren!
          </p>

          {/* Kategorien mit Popovers */}
          <div className="categories">
            <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={TradingPopover}>
              <div className="category-item">📈 Trading</div>
            </OverlayTrigger>
            <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={ExchangesPopover}>
              <div className="category-item">💱 Exchanges</div>
            </OverlayTrigger>
            <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={BuySellPopover}>
              <div className="category-item">🛒 Kauf & Verkauf</div>
            </OverlayTrigger>
            <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={MarketPopover}>
              <div className="category-item">📊 Marktlage</div>
            </OverlayTrigger>
            <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={StrategiesPopover}>
              <div className="category-item">🎯 Strategien</div>
            </OverlayTrigger>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatIsCrypto;
