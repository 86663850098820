// src/components/widgets/WidgetBuilderForm.jsx
import React from 'react';
import { Trans } from '@lingui/macro';
import CoinSelektor from '../../utils/CoinSelektor';
import CoinSearchInput from './CoinSearchInput';
import SingleSelectNoSearch from '../../utils/SingleSelectNoSearch';



/**
 * WidgetBuilderForm => reines Formular,
 * steuert Name, Typ, Coin, Farben usw.
 *
 * Props:
 *  - name, setName
 *  - nameError
 *  - type, setType
 *  - config, setConfig
 *  - colorLocal, setColorLocal
 *  - textColorLocal, setTextColorLocal
 *  - borderColorLocal, setBorderColorLocal
 *  - borderRadiusLocal, setBorderRadiusLocal
 *  - isSaving
 *  - handleSave(), handleCancel()
 *  - mode (z.B. 'create' oder 'edit')
 *  - isCurrenciesLoaded, supportedCurrencies
 */

const WidgetBuilderForm = ({
  name,
  setName,
  nameError,
  type,
  setType,
  config,
  setConfig,
  colorLocal,
  setColorLocal,
  textColorLocal,
  setTextColorLocal,
  borderColorLocal,
  setBorderColorLocal,
  borderRadiusLocal,
  setBorderRadiusLocal,
  isSaving,
  handleSave,
  handleCancel,
  mode,
  isCurrenciesLoaded,
  supportedCurrencies,
}) => {
  return (
    <div className="widget-builder-fields">
      {/* Name-Feld */}
      <div className="widget-field">
        <label>
          <Trans id="widget.builder.label.name">Name (intern):</Trans>
        </label>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="BTC-Widget.."
        />
        {nameError && (
          <small style={{ fontSize: '0.85rem', color: 'red', lineHeight: 1.2 }}>
            {nameError}
          </small>
        )}
      </div>

      {/* Widget-Typ */}
      <div className="widget-field">
        <label>
          <Trans id="widget.builder.label.type">Typ:</Trans>
        </label>
        <SingleSelectNoSearch
          options={[
            { value: 'price-box', label: 'Price Box' },
            { value: 'ticker', label: 'Ticker (deaktiviert)', isDisabled: true },
            { value: 'chart', label: 'Chart (deaktiviert)', isDisabled: true },
          ]}
          selectedValue={type}
          onChange={(val) => setType(val)}
          placeholder="Bitte wählen..."
        />
      </div>

      {/* Coin-Suche */}
      <div className="widget-field">
        <label>
          <Trans id="widget.builder.label.coin">Coin (z.B. bitcoin):</Trans>
        </label>
        <CoinSearchInput
          selectedCoinId={config.coin}
          onSelectCoin={(coinId) => {
            setConfig((prev) => ({ ...prev, coin: coinId }));
          }}
          isCreate={mode === 'create'}
        />
      </div>

      {/* Währung */}
      <div className="widget-field">
        <label>
          <Trans id="widget.builder.label.currency">Fiat Währung:</Trans>
        </label>
        {!isCurrenciesLoaded ? (
          <div style={{ fontSize: '0.85rem', opacity: 0.8 }}>Loading...</div>
        ) : supportedCurrencies.length === 0 ? (
          <div style={{ fontSize: '0.85rem', opacity: 0.8 }}>
            Keine Währungen gefunden
          </div>
        ) : (
          <CoinSelektor
            supportedCurrencies={supportedCurrencies}
            selectedCurrency={config.currency}
            onChange={(newCur) => {
              setConfig((prev) => ({ ...prev, currency: newCur }));
            }}
          />
        )}
      </div>

      {/* Farben */}
      <div className="widget-field widget-field-inline">
        <div className="color-field">
          <label>
            <Trans id="widget.builder.label.color">Farbe (Hintergrund):</Trans>
          </label>
          <input
            type="color"
            value={colorLocal}
            onChange={(e) => setColorLocal(e.target.value)}
          />
        </div>
        <div className="color-field">
          <label>
            <Trans id="widget.builder.label.textColor">Textfarbe:</Trans>
          </label>
          <input
            type="color"
            value={textColorLocal}
            onChange={(e) => setTextColorLocal(e.target.value)}
          />
        </div>
        <div className="color-field">
          <label>
            <Trans id="widget.builder.label.borderColor">Rahmenfarbe:</Trans>
          </label>
          <input
            type="color"
            value={borderColorLocal}
            onChange={(e) => setBorderColorLocal(e.target.value)}
          />
        </div>
      </div>

      {/* Border-Radius */}
      <div className="widget-field">
        <label>
          <Trans id="widget.builder.label.borderRadius">Ecken (Radius):</Trans>
        </label>
        <SingleSelectNoSearch
          options={[
            { value: "0", label: "Eckig (0px)" },
            { value: "8", label: "Mittel (8px)" },
            { value: "16", label: "Stark (16px)" },
            { value: "20", label: "Rund (20px)" },
          ]}
          selectedValue={borderRadiusLocal}
          onChange={(val) => setBorderRadiusLocal(val)}
          placeholder="Bitte wählen..."
        />
      </div>

      {/* Buttons */}
      <div style={{ display: 'flex', gap: '10px', marginTop: '20px' }}>
        <button onClick={handleSave} disabled={isSaving} className="widget-builder-button">
          {mode === 'create' ? (
            <Trans id="widget.builder.saveCreate">Erstellen</Trans>
          ) : (
            <Trans id="widget.builder.saveEdit">Speichern</Trans>
          )}
        </button>
        <button
          onClick={handleCancel}
          disabled={isSaving}
          className="widget-builder-button cancel"
        >
          <Trans id="widget.builder.cancelButton">Abbrechen</Trans>
        </button>
      </div>
    </div>
  );
};

export default WidgetBuilderForm;
