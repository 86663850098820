// src/components/ScamGame/ScamScenarioCard.js
import React, { useState, useEffect } from 'react';
import { Trans } from '@lingui/macro';
import api from '../../services/api'; // <-- Neu: unser zentrales Axios-Objekt
import '../../styles/ScamGame.css';

// Bilder
import emailDesktop from '../../assets/scamgame/email_1920x150.png';
import emailMobile from '../../assets/scamgame/email_414x100.png';

const ScamScenarioCard = ({ scenario, answered, isCorrect, onAnswered, onNext }) => {
  const [answerResult, setAnswerResult] = useState(null);

  useEffect(() => {
    if (answered) {
      const points = isCorrect ? 10 : 0;
      setAnswerResult({ correct: isCorrect, pointsEarned: points });
    } else {
      setAnswerResult(null);
    }
  }, [answered, isCorrect]);

  async function handleAnswer(userAnswer) {
    try {
      // Anstelle von apiFetch: POST mit Axios
      const { data } = await api.post('/scamgame/career/answer', {
        scenarioId: scenario.id,
        userAnswer,
      });
      // data => { correct, pointsEarned, levelFailed, ... }

      setAnswerResult({
        correct: data.correct,
        pointsEarned: data.pointsEarned,
      });

      if (onAnswered) {
        onAnswered(data.correct, data.levelFailed || false);
      }
    } catch (err) {
      console.error('Error in handleAnswer:', err);
    }
  }

  const scenarioType = scenario.type || 'email';

  function getScenarioTypeTitle(type) {
    switch (type) {
      case 'email':
        return <Trans id="scamgame.type.emailTitle">E-Mail Scam</Trans>;
      case 'website':
        return <Trans id="scamgame.type.websiteTitle">Website Scam</Trans>;
      case 'influencer':
        return <Trans id="scamgame.type.influencerTitle">Influencer Scam</Trans>;
      case 'fakeico':
        return <Trans id="scamgame.type.fakeicoTitle">Fake-ICO-Scam</Trans>;
      case 'nft':
        return <Trans id="scamgame.type.nftTitle">NFT-Scam</Trans>;
      case 'metaverse':
        return <Trans id="scamgame.type.metaverseTitle">Metaverse-Scam</Trans>;
      case 'defirugpull':
        return <Trans id="scamgame.type.defirugpullTitle">DeFi-Rugpull</Trans>;
      case 'exchangehack':
        return <Trans id="scamgame.type.exchangehackTitle">Fake Exchange/Hack</Trans>;
      default:
        return <Trans id="scamgame.type.unknown">Allgemeiner Scam</Trans>;
    }
  }

  function getScenarioTypeIntro(type) {
    switch (type) {
      case 'email':
        return <Trans id="scamgame.type.emailIntro">In dieser Kategorie lernst du, gefälschte oder betrügerische E-Mails zu erkennen.</Trans>;
      case 'website':
        return <Trans id="scamgame.type.websiteIntro">Hier erfährst du, wie man unseriöse Krypto-Webseiten entlarvt.</Trans>;
      case 'influencer':
        return <Trans id="scamgame.type.influencerIntro">Influencer können locken – erfahre hier, wie man Hype von echten Empfehlungen unterscheidet.</Trans>;
      case 'fakeico':
        return <Trans id="scamgame.type.fakeicoIntro">Fake-ICOs versprechen viel, liefern aber wenig – so erkennst du Betrug frühzeitig.</Trans>;
      case 'nft':
        return <Trans id="scamgame.type.nftIntro">Hüte dich vor gefälschten Airdrops oder Wallet-Connect-Anfragen.</Trans>;
      case 'metaverse':
        return <Trans id="scamgame.type.metaverseIntro">Metaverse-Projekte sind im Trend – lerne echte Partnerschaften von reinen Marketingmaschen zu unterscheiden.</Trans>;
      case 'defirugpull':
        return <Trans id="scamgame.type.defirugpullIntro">Erkenne Liquiditätsfallen und übertriebene APYs, bevor du ruggepullt wirst.</Trans>;
      case 'exchangehack':
        return <Trans id="scamgame.type.exchangehackIntro">Viele Betrüger geben sich als Exchange-Support aus, um Keys zu stehlen.</Trans>;
      default:
        return <Trans id="scamgame.type.defaultIntro">Achte auf die typischen roten Flaggen.</Trans>;
    }
  }

  function renderScenarioContent() {
    switch (scenarioType) {
      case 'email':
        return (
          <div className="scamgame-email-view">
            {/* Header-Balken */}
            <div className="email-topbar">
              <picture>
                <source media="(max-width: 414px)" srcSet={emailMobile} />
                <img 
                  src={emailDesktop} 
                  alt="Email Header Balken" 
                  style={{ width: '100%', height: 'auto' }}
                />
              </picture>
            </div>
            <div className="email-header">
              <p>
                <b><Trans id="scamgame.email.from">Von:</Trans></b>{' '}
                suspicious@crypto.xyz
              </p>
              <p>
                <b><Trans id="scamgame.email.subject">Betreff:</Trans></b>{' '}
                {scenario.title}
              </p>
            </div>
            <div className="email-body">
              <p>{scenario.description}</p>
            </div>
          </div>
        );
      case 'website':
        return (
          <div className="scamgame-website-view">
            <div className="website-bar">
              www.{scenario.title?.toLowerCase().replace(/\s/g, '')}.com
            </div>
            <div className="website-content">
              <p>{scenario.description}</p>
            </div>
          </div>
        );
      case 'influencer':
        return (
          <div className="scamgame-influencer-view">
            <div className="influencer-header">@CryptoKing</div>
            <div className="influencer-content">
              <p>{scenario.description}</p>
            </div>
          </div>
        );
      case 'fakeico':
        return (
          <div className="scamgame-fakeico-view">
            <div className="fakeico-header">
              <Trans id="scamgame.fakeico.label">Whitepaper-Auszug</Trans>
            </div>
            <div className="fakeico-content">
              <p>{scenario.description}</p>
            </div>
          </div>
        );
      case 'nft':
        return (
          <div className="scamgame-nft-view">
            <div className="nft-topbar">
              <span className="nft-logo">NFT LaunchPad</span>
              <span className="nft-menu">Explore | My NFTs | Connect Wallet</span>
            </div>
            <div className="nft-body">
              <p>{scenario.description}</p>
            </div>
          </div>
        );
      case 'metaverse':
        return (
          <div className="scamgame-metaverse-view">
            <div className="metaverse-headerbar">
              <span className="metaverse-logo">MegaLand</span>
              <span className="metaverse-menu">Buy Land | Avatars | Roadmap</span>
            </div>
            <div className="metaverse-body">
              <p>{scenario.description}</p>
            </div>
          </div>
        );
      case 'defirugpull':
        return (
          <div className="scamgame-defi-view">
            <div className="defi-top">
              <span className="defi-logo">YummySwap</span>
              <span className="defi-apys">3000% APY!</span>
            </div>
            <div className="defi-body">
              <p>{scenario.description}</p>
            </div>
          </div>
        );
      case 'exchangehack':
        return (
          <div className="scamgame-exchange-view">
            <div className="exchange-headerbar">
              <span className="exchange-logo">BinanceSupport (Fake)</span>
              <span className="exchange-chat">Chat Window</span>
            </div>
            <div className="exchange-body">
              <p>{scenario.description}</p>
            </div>
          </div>
        );
      default:
        return (
          <div className="scamgame-default-view">
            <h4>{scenario.title}</h4>
            <p>{scenario.description}</p>
          </div>
        );
    }
  }

  const alreadyAnswered = answered || false;

  return (
    <div className="scamgame-card data-card">
      <div className="scamgame-type-info">
        <h3>{getScenarioTypeTitle(scenarioType)}</h3>
        <p>{getScenarioTypeIntro(scenarioType)}</p>
      </div>

      {renderScenarioContent()}

      {!alreadyAnswered && !answerResult && (
        <div className="scamgame-answer-buttons">
          <button onClick={() => handleAnswer('scam')}>
            <Trans id="scamgame.button.scam">Scam</Trans>
          </button>
          <button onClick={() => handleAnswer('legit')}>
            <Trans id="scamgame.button.legit">Legit</Trans>
          </button>
        </div>
      )}

      {(alreadyAnswered || answerResult) && (
        <div className="scamgame-feedback">
          {answerResult?.correct ? (
            <p className="correct-answer">
              <Trans id="scamgame.answer.correct">Richtig!</Trans>{' '}
              +{answerResult?.pointsEarned ?? (isCorrect ? 10 : 0)}{' '}
              <Trans id="scamgame.answer.points">Punkte</Trans>
            </p>
          ) : (
            <p className="wrong-answer">
              <Trans id="scamgame.answer.wrong">Falsch!</Trans>{' '}
              0 <Trans id="scamgame.answer.points">Punkte</Trans>
            </p>
          )}

          <p style={{ marginTop: '10px' }}>
            <b>
              <Trans id="scamgame.explanation">Erklärung:</Trans>
            </b>{' '}
            {scenario.explanation}
          </p>

          {onNext && (
            <div style={{ marginTop: '20px', textAlign: 'center' }}>
              <button onClick={onNext}>
                <Trans id="scamgame.button.nextScenario">Weiter</Trans>
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ScamScenarioCard;
