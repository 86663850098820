// src/components/CoinDetails/CompareSearch.jsx
import React, { useState } from 'react';
import { Trans, t } from '@lingui/macro';
import { FaTimes } from 'react-icons/fa';
import { StyledInput } from '../../utils/StyledInput';
import { StyledButton } from '../../utils/StyledButton';
import { formatPrice } from '../../utils/formatPrice';
import { formatChangePercentage } from '../../utils/formatChangePercentage';
import api from '../../services/api'; // <-- Neu: import des zentralen Axios-Clients

/**
 * Minimaler Such-Overlay für den Vergleichs-Coin:
 * - Sucht nur nach Coins (data.coins)
 * - Kein Trending, keine Exchanges, keine Kategorien
 * - Callback onCoinSelected(coinId), um den ausgewählten Coin zu melden
 * - Schließt sich, wenn der Nutzer einen Coin wählt oder auf X klickt
 */
const CompareSearch = ({ onClose, onCoinSelected }) => {
  const [query, setQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [didSearch, setDidSearch] = useState(false);

  const handleSearch = async () => {
    if (!query.trim()) return;
    setIsLoading(true);
    setDidSearch(true);

    try {
      // Statt fetch jetzt axios (unser api-Objekt mit Interceptors) verwenden:
      const resp = await api.get('/search', {
        params: { query: query.trim() }
      });
      const data = resp.data;
      setSearchResults(data.coins || []);
    } catch (err) {
      console.error('CompareSearch error:', err);
      // Hier kannst du optional einen Toast oder ein Error-Handling einbauen
    } finally {
      setIsLoading(false);
    }
  };

  // Suche bei Enter
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  // Auswählen des Coins
  const handleCoinClick = (coin) => {
    if (onCoinSelected) {
      onCoinSelected(coin.id);
    }
    // Optional: Modal schließen
    if (onClose) onClose();
  };

  return (
    <div
      className="compare-search-modal"
      style={{
        position: 'absolute',
        top: 'calc(100% + 5px)',
        right: 0,
        zIndex: 9999,
        width: 'min(90vw, 400px)',
        maxHeight: '70vh',
        overflowY: 'auto',
        backgroundColor: 'var(--background-color2)',
        border: '1px solid var(--secondary-bg-color)',
        borderRadius: '8px',
        padding: '10px',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
        transition: 'opacity 0.2s ease-out, transform 0.2s ease-out',
        opacity: 1,
        transform: 'translateY(0)',
      }}
    >
      {/* Close-Button */}
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <FaTimes style={{ cursor: 'pointer' }} onClick={onClose} />
      </div>

      <h4 style={{ marginTop: 0 }}>
        <Trans id="comparison.searchTitle">Vergleichs-Coin auswählen</Trans>
      </h4>

      <StyledInput
        type="search"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        onKeyDown={handleKeyDown}
        placeholder={t({ id: 'placeholder.search', message: 'Suchbegriff..' })}
      />

      <StyledButton
        variant="primary"
        onClick={handleSearch}
        style={{ marginTop: '8px' }}
        disabled={!query.trim()}
      >
        <Trans id="button.search">Suchen</Trans>
      </StyledButton>

      {isLoading && (
        <div style={{ marginTop: '10px', fontStyle: 'italic' }}>
          <Trans id="message.loading">Lade...</Trans>
        </div>
      )}

      {didSearch && !isLoading && searchResults.length === 0 && (
        <div style={{ marginTop: '10px', fontStyle: 'italic' }}>
          <Trans id="search.noResults">Keine Ergebnisse gefunden</Trans>
        </div>
      )}

      {searchResults.map((coin) => (
        <div
          key={coin.id}
          onClick={() => handleCoinClick(coin)}
          style={{
            border: '1px solid var(--secondary-bg-color)',
            borderRadius: '4px',
            padding: '8px',
            marginTop: '8px',
            cursor: 'pointer',
          }}
        >
          <strong>{coin.name}</strong> ({coin.symbol?.toUpperCase()})<br />
          {coin.data?.price !== undefined && (
            <>
              <span>
                <Trans id="search.label.price">Price:</Trans>{' '}
                {formatPrice(coin.data?.price, 'usd', true)}
              </span>
              <br />
              <span>
                <Trans id="search.label.24hChange">24h Change:</Trans>{' '}
                {formatChangePercentage(coin.data?.price_change_percentage_24h)}
              </span>
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default CompareSearch;
