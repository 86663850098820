// src/components/widgets/WidgetDashboard.js

import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Trans, t } from '@lingui/macro';
import { toast } from 'react-toastify';
import api from '../../services/api'; // <-- Neu: Unser zentrales Axios-Objekt
import { AuthContext } from '../../context/AuthContext';
import '../../styles/WidgetDashboard.css';
import { FaEdit, FaTrash, FaPlus } from 'react-icons/fa';
import { formatDate } from '../../utils/formatDate';

import WidgetEmbedModal from './WidgetEmbedModal';

function getIframeDimensions(widget) {
  if (widget.type === 'price-box') {
    return { width: 240, height: 160 };
  }
  return { width: 300, height: 200 };
}

const WidgetDashboard = () => {
  const navigate = useNavigate();
  const { isLoggedIn } = useContext(AuthContext);

  // (1) Aus dem localStorage holen (oder 'de')
  const userLang = localStorage.getItem('language') || 'de';

  const [widgets, setWidgets] = useState([]);
  const [loading, setLoading] = useState(false);

  // Modal-State
  const [modalOpen, setModalOpen] = useState(false);
  const [modalCode, setModalCode] = useState('');
  const [modalType, setModalType] = useState(''); // "iframe" | "snippet"

  // (A) Widgets laden, falls eingeloggt
  useEffect(() => {
    if (!isLoggedIn) return;
    setLoading(true);

    (async () => {
      try {
        // GET /widgets
        const { data } = await api.get('/widgets');
        setWidgets(data.widgets || []);
      } catch (err) {
        console.error(err);
        toast.error(
          t(
            {
              id: 'widget.dashboard.errorLoadWidgets',
              message: 'Widgets konnten nicht geladen werden: {message}',
            },
            { message: err.message }
          )
        );
      } finally {
        setLoading(false);
      }
    })();
  }, [isLoggedIn]);

  // (B) Widget löschen
  const handleDelete = async (widgetId) => {
    const confirmed = window.confirm(
      t({
        id: 'widget.dashboard.confirmDelete',
        message: 'Widget wirklich löschen?',
      })
    );
    if (!confirmed) return;

    try {
      // DELETE /widgets/:id
      await api.delete(`/widgets/${widgetId}`);

      setWidgets((prev) => prev.filter((w) => w.id !== widgetId));
      toast.success(
        t({
          id: 'widget.dashboard.widgetDeleted',
          message: 'Widget erfolgreich gelöscht!',
        })
      );
    } catch (err) {
      console.error(err);
      toast.error(
        t(
          {
            id: 'widget.dashboard.errorDeleteWidget',
            message: 'Fehler beim Löschen: {message}',
          },
          { message: err.message }
        )
      );
    }
  };

  // (C) Code-Generierung => &lang=${userLang} an URLs anhängen
  const getIframeCode = (widget) => {
    const domain = window.location.origin;
    const { width, height } = getIframeDimensions(widget);
    return `<iframe
  src="${domain}/widget-embed/${widget.id}?token=${widget.embed_token}&lang=${userLang}"
  width="${width}"
  height="${height}"
  style="border:none; overflow:hidden;"
  scrolling="no"
></iframe>`;
  };

  const getSnippetCode = (widget) => {
    const domain = window.location.origin;
    return `<script src="${domain}/widget-snippet/${widget.id}.js?token=${widget.embed_token}&lang=${userLang}"></script>`;
  };

  // (D) Modal öffnen => iframe/snippet
  const handleOpenModal = (widget, codeType) => {
    let codeString = '';
    if (codeType === 'iframe') {
      codeString = getIframeCode(widget);
      setModalType('iframe');
    } else {
      codeString = getSnippetCode(widget);
      setModalType('snippet');
    }
    setModalCode(codeString);
    setModalOpen(true);
  };

  return (
    <div className="widget-dashboard-container">
      <h2 className="widget-dashboard-title">
        <Trans id="widget.dashboard.title">Deine Widgets</Trans>
      </h2>

      <p className="intro-text">
        <Trans id="widget.dashboard.intro">
          Dein Widget-Dashboard ist der erste Schritt zur Gestaltung deiner eigenen interaktiven Datenpräsentation. Bette deine Widgets nahtlos in deine Homepage ein und erstelle auf unserer Plattform individuelle Dashboards, die dir alle relevanten Marktinformationen auf einen Blick liefern.
        </Trans>
      </p>

      {!isLoggedIn && (
        <div className="widget-guest-info">
          <Trans id="widget.dashboard.guestInfo">
            Du bist nicht eingeloggt. Du kannst ein Test-Widget erstellen,
            aber zum Speichern musst du dich registrieren oder einloggen.
          </Trans>
        </div>
      )}

      <div className="widget-dashboard-new-icon">
        <Link
          to="/widgets/new"
          className="widget-icon-btn new-widget"
          title={t({ id: 'widget.dashboard.newIconTitle', message: 'Neues Widget erstellen' })}
        >
          <FaPlus />
        </Link>
      </div>

      {loading && (
        <div>
          <Trans id="widget.dashboard.loading">Widgets werden geladen ...</Trans>
        </div>
      )}

      {!loading && isLoggedIn && widgets.length === 0 && (
        <div style={{ textAlign: 'center', fontStyle: 'italic', marginBottom: '20px' }}>
          <Trans id="widget.dashboard.noWidgets">Keine Widgets angelegt!</Trans>
        </div>
      )}

      <div className="widget-cards-container">
        {widgets.map((widget) => {
          const updatedAtOrCreatedAt = widget.updated_at || widget.created_at;
          const { width, height } = getIframeDimensions(widget);

          return (
            <div key={widget.id} className="widget-card">
              <div className="widget-card-header">
                <h3>{widget.name}</h3>
                <div className="widget-card-icons">
                  <button
                    className="widget-icon-btn"
                    title={t({ id: 'widget.dashboard.editIconTitle', message: 'Bearbeiten' })}
                    onClick={() => navigate(`/widgets/${widget.id}/edit`)}
                  >
                    <FaEdit />
                  </button>
                  <button
                    className="widget-icon-btn delete"
                    title={t({ id: 'widget.dashboard.deleteIconTitle', message: 'Löschen' })}
                    onClick={() => handleDelete(widget.id)}
                  >
                    <FaTrash />
                  </button>
                </div>

                <div className="widget-dates-info">
                  <small>
                    <Trans id="widget.dashboard.createdAt">Erstellt am</Trans>:{' '}
                    {formatDate(widget.created_at)}
                  </small>
                  <small>
                    <Trans id="widget.dashboard.updatedAt">Zuletzt geändert</Trans>:{' '}
                    {formatDate(updatedAtOrCreatedAt)}
                  </small>
                </div>
              </div>

              <div className="widget-preview-frame">
                <iframe
                  title={`widget-preview-${widget.id}`}
                  src={`${window.location.origin}/widget-embed/${widget.id}?token=${widget.embed_token}&lang=${userLang}`}
                  width={width}
                  height={height}
                  style={{ display: 'block' }}
                  scrolling="no"
                />
              </div>

              <div className="widget-card-actions">
                <div className="widget-embed-codes">
                  <button
                    onClick={() => handleOpenModal(widget, 'iframe')}
                    className="widget-embed-copy-button"
                  >
                    <Trans id="widget.button.copyIframe">Copy Iframe</Trans>
                  </button>
                  <button
                    onClick={() => handleOpenModal(widget, 'snippet')}
                    className="widget-embed-copy-button"
                  >
                    <Trans id="widget.button.copySnippet">Copy Snippet</Trans>
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      {modalOpen && (
        <WidgetEmbedModal
          code={modalCode}
          codeType={modalType}
          onClose={() => setModalOpen(false)}
        />
      )}
    </div>
  );
};

export default WidgetDashboard;
