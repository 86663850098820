import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom'; // Importiere useNavigate
import { Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import '../styles/common.css';
import '../styles/TradingSimulator.css';

// Registriere die Chart.js-Komponenten
Chart.register(...registerables);

// Modal-Komponente
const Modal = ({ message, onClose, onRedirect }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <p>{message}</p>
        <button onClick={onRedirect} className="redirect-button">
          Weiter zur echten Börse
        </button>
        <button onClick={onClose} className="close-button">
          Später
        </button>
      </div>
    </div>
  );
};

const TradingSimulator = () => {
  const navigate = useNavigate(); // Initialisiere useNavigate

  const [selectedSymbol, setSelectedSymbol] = useState('BTCUSDT');
  const [availableSymbols] = useState([
    'BTCUSDT',
    'ETHUSDT',
    'BNBUSDT',
    'ADAUSDT',
    'DOGEUSDT',
    'XRPUSDT',
  ]);
  const [chartData, setChartData] = useState([]);
  const [labels, setLabels] = useState([]);
  const [balance, setBalance] = useState(1000);
  const [currentPrice, setCurrentPrice] = useState(null);
  const [position, setPosition] = useState(null);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('info');
  const [priceChange, setPriceChange] = useState('');
  const [tradesCompleted, setTradesCompleted] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [tooltipMessage, setTooltipMessage] = useState('');

  const chartRef = useRef(null);

  // Binance API: Preis für ausgewähltes Symbol abrufen
  const fetchCryptoPrices = async () => {
    try {
      const response = await fetch(
        `https://api.binance.com/api/v3/ticker/price?symbol=${selectedSymbol}`
      );
      const data = await response.json();
      return parseFloat(data.price);
    } catch (error) {
      console.error('Fehler beim Abrufen der Preise:', error);
      return null;
    }
  };

  // Binance API: Historische Daten abrufen
  const fetchHistoricalData = async () => {
    try {
      const response = await fetch(
        `https://api.binance.com/api/v3/klines?symbol=${selectedSymbol}&interval=1m&limit=100`
      );
      const data = await response.json();
      return data.map((item) => ({
        time: new Date(item[0]).toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
        }),
        price: parseFloat(item[4]), // Schlusskurs der Kerze
      }));
    } catch (error) {
      console.error('Fehler beim Abrufen der historischen Daten:', error);
      return [];
    }
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      const historicalData = await fetchHistoricalData();
      if (historicalData.length > 0) {
        setChartData(historicalData.map((item) => item.price));
        setLabels(historicalData.map((item) => item.time));
        setCurrentPrice(historicalData[historicalData.length - 1].price);
      }
    };

    fetchInitialData();
    // Bei Wechsel des Symbols offene Position und Trades zurücksetzen
    setPosition(null);
    setMessage('');
    setMessageType('info');
    setTradesCompleted(0);
  }, [selectedSymbol]);

  useEffect(() => {
    const interval = setInterval(async () => {
      const price = await fetchCryptoPrices();
      if (price) {
        setCurrentPrice(price);
      }
    }, 2000); // Preise alle 2 Sekunden abrufen

    return () => clearInterval(interval);
  }, [selectedSymbol]);

  useEffect(() => {
    if (currentPrice !== null && chartData.length > 0) {
      setChartData((prevData) => {
        const updatedData = [...prevData.slice(1), currentPrice];

        // Dynamische Preisanzeige
        const previousPrice = prevData[prevData.length - 1];
        setPriceChange(
          currentPrice > previousPrice ? 'up' : currentPrice < previousPrice ? 'down' : ''
        );

        setTimeout(() => setPriceChange(''), 1000);

        return updatedData;
      });

      setLabels((prevLabels) => {
        const updatedLabels = [
          ...prevLabels.slice(1),
          new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
        ];
        return updatedLabels;
      });
    }
  }, [currentPrice]);

  // Update des Charts
  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.data.labels = labels;
      chartRef.current.data.datasets[0].data = chartData;
      chartRef.current.update('none'); // 'none' verhindert Animation beim Update
    }
  }, [chartData, labels]);

  // Funktion zur Bestimmung der Dezimalstellen basierend auf dem Symbol
  const getDecimalPlaces = (symbol) => {
    if (symbol === 'BTCUSDT') {
      return 2;
    } else if (symbol === 'ETHUSDT' || symbol === 'BNBUSDT') {
      return 2;
    } else if (symbol === 'ADAUSDT' || symbol === 'DOGEUSDT' || symbol === 'XRPUSDT') {
      return 4;
    } else {
      return 2; // Standardmäßig 2 Dezimalstellen
    }
  };

  const handleBuy = () => {
    if (position) {
      setMessage('Du hast bereits eine offene Position!');
      setMessageType('info');
      return;
    }
    setPosition(currentPrice);
    setMessage(
      `Du hast bei ${currentPrice.toFixed(getDecimalPlaces(selectedSymbol))} gekauft.`
    );
    setMessageType('info');
  };

  const handleSell = () => {
    if (!position) {
      setMessage('Du hast keine offene Position!');
      setMessageType('info');
      return;
    }
    const profit = currentPrice - position;
    setBalance((prev) => prev + profit);

    const profitDisplay = profit.toFixed(2);
    const currentPriceDisplay = currentPrice.toFixed(getDecimalPlaces(selectedSymbol));

    if (profit >= 0) {
      setMessage(
        `Du hast bei ${currentPriceDisplay} verkauft und einen Gewinn von ${profitDisplay} gemacht.`
      );
      setMessageType('success');
    } else {
      setMessage(
        `Du hast bei ${currentPriceDisplay} verkauft und einen Verlust von ${profitDisplay} gemacht.`
      );
      setMessageType('error');
    }
    setPosition(null);
    setTradesCompleted((prev) => prev + 1);
  };

  const redirectToExchange = () => {
    window.location.href = 'https://affiliate-link-example.com';
  };

  const data = {
    labels,
    datasets: [
      {
        label: `Kursverlauf (${selectedSymbol})`,
        data: chartData,
        fill: false,
        borderColor: '#4caf50',
        tension: 0.1,
        pointRadius: 0, // Datenpunkte unsichtbar machen
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 1000,
      easing: 'easeInOutQuad',
    },
    scales: {
      x: {
        title: { display: true, text: 'Zeit' },
      },
      y: {
        title: { display: true, text: 'Preis (USD)' },
      },
    },
  };

  return (
    <div className="trading-simulator">
      <h1>Trading-Simulator</h1>
      {/* Währungspaar-Auswahl */}
      <div className="symbol-selection">
        <p>Währungspaar auswählen:</p>
        <select
          value={selectedSymbol}
          onChange={(e) => setSelectedSymbol(e.target.value)}
        >
          {availableSymbols.map((symbol) => (
            <option key={symbol} value={symbol}>
              {symbol}
            </option>
          ))}
        </select>
      </div>
      {currentPrice === null ? (
        <div className="loading">
          <p>Preise werden geladen...</p>
        </div>
      ) : (
        <>
          <p className="current-price">
            Aktueller Preis ({selectedSymbol}):{' '}
            <span className={`price ${priceChange}`}>
              {currentPrice.toFixed(getDecimalPlaces(selectedSymbol))}
            </span>{' '}
            USD
          </p>
          <div className="chart-container">
            <Line ref={chartRef} data={data} options={options} />
          </div>
        </>
      )}
      <div className="action-container">
        <p className="balance">Kontostand: {balance.toFixed(2)} Punkte</p>
        <div className="actions">
          <button onClick={handleBuy} disabled={!!position}>
            Kaufen
          </button>
          <button onClick={handleSell} disabled={!position}>
            Verkaufen
          </button>
        </div>
      </div>
      {message && <div className={`message ${messageType}`}>{message}</div>}
      {balance <= 0 && (
        <div className="redirect-message">
          <p>Du hast keine Punkte mehr! Willst du echtes Trading ausprobieren?</p>
          <button onClick={redirectToExchange}>Zu einer echten Börse wechseln</button>
        </div>
      )}
      {showModal && (
        <Modal
          message={'2 Trades abgeschlossen! Möchtest du echtes Trading ausprobieren?'}
          onClose={() => setShowModal(false)}
          onRedirect={redirectToExchange}
        />
      )}
      <button onClick={() => navigate(-1)} className="back-button"> {/* navigate(-1) für Zurück */}
        Zurück
      </button>
    </div>
  );
};

export default TradingSimulator;
