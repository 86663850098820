// src/components/ContactForm.js
import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import api from '../services/api'; // <-- Neu: unser zentrales Axios-Objekt
import { StyledInput, StyledTextarea } from '../utils/StyledInput';
import { StyledButton } from '../utils/StyledButton';
import '../styles/ContactForm.css';
import { toast } from 'react-toastify';
import { Trans, t } from '@lingui/macro';
import { i18n } from '@lingui/core';

/** Übersetzung, unverändert */
function translateMessageKey(messageKey) {
  switch (messageKey) {
    case 'error.missingFields':
      return t({ id: 'error.missingFields', message: 'Bitte alle Felder ausfüllen.' });
    case 'error.invalidEmail':
      return t({ id: 'error.invalidEmail', message: 'Bitte eine gültige E-Mail-Adresse eingeben.' });
    case 'error.captchaNotSolved':
      return t({
        id: 'error.captchaNotSolved',
        message: 'Bitte das Captcha lösen, um fortzufahren.',
      });
    case 'error.privacyNotAccepted':
      return t({
        id: 'error.privacyNotAccepted',
        message: 'Bitte Datenschutz zustimmen, um das Formular zu senden.',
      });
    case 'error.dbInsertFailed':
      return t({
        id: 'error.dbInsertFailed',
        message: 'Fehler beim Speichern in der Datenbank.',
      });
    case 'error.internalServerError':
      return t({
        id: 'error.internalServerError',
        message: 'Interner Serverfehler.',
      });
    case 'success.contactFormSubmitted':
      return t({
        id: 'success.contactFormSubmitted',
        message: 'Vielen Dank für deine Nachricht! Wir werden uns bald melden.',
      });
    default:
      return t({ id: 'error.unknown', message: 'Unbekannter Fehler.' });
  }
}

const ContactForm = () => {
  const { isLoggedIn } = useContext(AuthContext);

  // States für die Eingabefelder
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [privacyAccepted, setPrivacyAccepted] = useState(false);

  // NEU: Flags, ob Name/Email aktuell (unverändert) aus dem Profil stammen
  const [isNameFromProfile, setIsNameFromProfile] = useState(false);
  const [isEmailFromProfile, setIsEmailFromProfile] = useState(false);

  // Sprache/Theme aus LocalStorage
  const storedLang = localStorage.getItem('language') || 'de';
  const storedTheme = localStorage.getItem('theme') || 'dark';
  const siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

  // Profil laden bei Login
  useEffect(() => {
    if (isLoggedIn) {
      (async () => {
        try {
          // GET /api/profile
          const response = await api.get('/profile');
          // response.data => Profildaten
          const data = response.data;
          // Vor- & Nachname zusammensetzen
          const profileName = `${data.first_name ?? ''} ${data.last_name ?? ''}`.trim();
          const profileEmail = data.email || '';

          setName(profileName);
          setEmail(profileEmail);

          // Flags auf true, wenn die Felder nicht leer sind
          setIsNameFromProfile(!!profileName);
          setIsEmailFromProfile(!!profileEmail);
        } catch (err) {
          console.error('Fehler beim Laden des Profils:', err);
        }
      })();
    } else {
      // Falls ausgeloggt => Flags resetten
      setIsNameFromProfile(false);
      setIsEmailFromProfile(false);
    }
  }, [isLoggedIn]);

  // reCAPTCHA-Skript dynamisch laden
  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?hl=${storedLang}`;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, [storedLang]);

  // Handler bei Namens- und Email-Änderung
  const handleNameChange = (e) => {
    setName(e.target.value);
    setIsNameFromProfile(false);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setIsEmailFromProfile(false);
  };

  // Submit-Handler
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Schritt 1: Pflichtfelder
    if (!name.trim() || !email.trim() || !subject.trim() || !message.trim()) {
      toast.error(translateMessageKey('error.missingFields'));
      window.grecaptcha?.reset();
      return;
    }

    // Schritt 2: Datenschutz
    if (!privacyAccepted) {
      toast.error(translateMessageKey('error.privacyNotAccepted'));
      window.grecaptcha?.reset();
      return;
    }

    // Schritt 3: E-Mail-Check
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email.trim())) {
      toast.error(translateMessageKey('error.invalidEmail'));
      window.grecaptcha?.reset();
      return;
    }

    // Schritt 4: Captcha
    const recaptchaToken = window.grecaptcha?.getResponse();
    if (!recaptchaToken) {
      toast.error(translateMessageKey('error.captchaNotSolved'));
      window.grecaptcha?.reset();
      return;
    }

    // Alles okay => Request an /api/contact
    try {
      // POST /api/contact
      const response = await api.post('/contact', {
        name,
        email,
        subject,
        message,
        recaptchaToken,
      }, {
        headers: {
          'Accept-Language': i18n.locale,
        },
      });

      const data = response.data;
      if (response.status === 200) {
        toast.success(translateMessageKey(data.messageKey), { position: 'bottom-right' });
        // Felder leeren
        setName('');
        setEmail('');
        setSubject('');
        setMessage('');
        setPrivacyAccepted(false);
        setIsNameFromProfile(false);
        setIsEmailFromProfile(false);
      } else {
        toast.error(translateMessageKey(data.messageKey), { position: 'bottom-right' });
      }
    } catch (err) {
      console.error('Kontaktformular Fehler:', err);
      const respData = err.response?.data;
      if (respData && respData.messageKey) {
        toast.error(translateMessageKey(respData.messageKey), { position: 'bottom-right' });
      } else {
        toast.error(translateMessageKey('error.internalServerError'), { position: 'bottom-right' });
      }
    } finally {
      window.grecaptcha?.reset();
    }
  };

  return (
    <div className="contact-form-container">
      <h2 className="contact-form-title">
        <Trans id="contactForm.title">Kontaktformular</Trans>
      </h2>
      <form className="contact-form" onSubmit={handleSubmit}>
        <label htmlFor="contact-name">
          <Trans id="contactForm.nameLabel">Name</Trans>
        </label>
        <StyledInput
          id="contact-name"
          type="text"
          placeholder={t({ id: 'contactForm.namePlaceholder', message: 'Dein Name' })}
          value={name}
          onChange={handleNameChange}
          required
        />

        <label htmlFor="contact-email">
          <Trans id="contactForm.emailLabel">E-Mail</Trans>
        </label>
        <StyledInput
          id="contact-email"
          type="email"
          placeholder={t({ id: 'contactForm.emailPlaceholder', message: 'Deine E-Mail-Adresse' })}
          value={email}
          onChange={handleEmailChange}
          required
        />

        <label htmlFor="contact-subject">
          <Trans id="contactForm.subjectLabel">Betreff</Trans>
        </label>
        <StyledInput
          id="contact-subject"
          type="text"
          placeholder={t({ id: 'contactForm.subjectPlaceholder', message: 'Betreff' })}
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          required
        />

        <label htmlFor="contact-message">
          <Trans id="contactForm.messageLabel">Nachricht</Trans>
        </label>
        <StyledTextarea
          id="contact-message"
          placeholder={t({ id: 'contactForm.messagePlaceholder', message: 'Deine Nachricht ...' })}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          required
        />

        {/* DSGVO-Zustimmung */}
        <StyledInput
          type="checkbox"
          id="privacyAccepted"
          checked={privacyAccepted}
          onChange={(e) => setPrivacyAccepted(e.target.checked)}
          label={
            <Trans id="contactForm.privacyLabel">
              Ich stimme zu, dass meine Daten zur Kontaktaufnahme verwendet werden.
            </Trans>
          }
        />

        {/* reCAPTCHA Container */}
        <div
          className="g-recaptcha"
          data-sitekey={siteKey}
          data-theme={storedTheme === 'dark' ? 'dark' : 'light'}
        />

        <StyledButton
          type="submit"
          variant="primary"
          translationKey="contactForm.submitButton"
          defaultMessage="Absenden"
          style={{ width: '200px', margin: '0' }}
        />
      </form>
    </div>
  );
};

export default ContactForm;
