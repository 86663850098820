// src/components/GainersLosers.js

import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/GainersLosers.css';
import { Trans } from '@lingui/macro';
import { Popover, OverlayTrigger, Button } from 'react-bootstrap';
import GainersLosersRow from './GainersLosersRow';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import SingleSelectNoSearch from '../utils/SingleSelectNoSearch';
import api from '../services/api'; // <-- Neu: Unser Axios-Objekt

const GainersLosers = () => {
  const navigate = useNavigate();

  const [gainers, setGainers] = useState([]);
  const [losers, setLosers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Filter
  const [duration, setDuration] = useState('24h');
  const [topCoins, setTopCoins] = useState('1000');
  // Währung
  const [vsCurrency, setVsCurrency] = useState('usd');

  // Vorherige Daten (für partial updates)
  const prevGainersRef = useRef([]);
  const prevLosersRef = useRef([]);

  // Retry-Refs
  const retryCountForceRef = useRef(0);
  const retryCountSilentRef = useRef(0);
  const maxRetries = 5;

  // ----------------------------------------------------
  // 1) updateCoinArray => merges old/new coin data
  // ----------------------------------------------------
  const updateCoinArray = (oldArray, newArray) => {
    const oldMap = {};
    oldArray.forEach((c) => {
      oldMap[c.id] = c;
    });

    let changedAny = false;
    const finalArray = newArray.map((newCoin) => {
      const oldCoin = oldMap[newCoin.id];
      if (!oldCoin) {
        changedAny = true;
        return newCoin;
      }
      // Mix
      const updatedCoin = { ...oldCoin };
      let changed = false;
      for (const key of Object.keys(newCoin)) {
        const newVal = newCoin[key];
        if (newVal !== undefined && newVal !== null && oldCoin[key] !== newVal) {
          changed = true;
          updatedCoin[key] = newVal;
        }
      }
      if (changed) changedAny = true;
      return changed ? updatedCoin : oldCoin;
    });

    // Falls length sich ändert
    if (finalArray.length !== oldArray.length) {
      changedAny = true;
    }
    if (!changedAny) {
      return [oldArray, false];
    }
    return [finalArray, true];
  };

  // ----------------------------------------------------
  // 2) Popover-Content
  // ----------------------------------------------------
  const popoverContent = (title) => (
    <Popover id={`popover-${title}`}>
      <Popover.Header as="h3">{title}</Popover.Header>
      <Popover.Body>
        {title === 'Rank' &&
          'Die Position eines Coins basierend auf seiner Marktkapitalisierung.'}
        {title === 'Price' && 'Der aktuelle Preis eines Coins in der Währung.'}
        {title.includes('%') &&
          `Die prozentuale Veränderung über die gewählte Dauer (${title.split(' ')[0]}).`}
        {title === 'Volumen (24h)' && 'Das Handelsvolumen des Coins über 24h.'}
        {title === 'Volumen' &&
          `Das Handelsvolumen über die gewählte Dauer (${title.split(' ')[1]}).`}
      </Popover.Body>
    </Popover>
  );

  // ----------------------------------------------------
  // 3) fetchDataForce => bei Filter/Währung-Wechsel => Hard Reload
  // ----------------------------------------------------
  const fetchDataForce = async () => {
    setLoading(true);
    setError(null);

    // Arrays leeren => um "Loading" anzuzeigen
    setGainers([]);
    setLosers([]);

    try {
      // GET /top-gainers-losers?vs_currency=...&duration=...&top_coins=...
      const { data } = await api.get('/top-gainers-losers', {
        params: {
          vs_currency: vsCurrency,
          duration,
          top_coins: topCoins,
        },
      });

      setGainers(data.top_gainers || []);
      setLosers(data.top_losers || []);

      prevGainersRef.current = data.top_gainers || [];
      prevLosersRef.current = data.top_losers || [];

      retryCountForceRef.current = 0;
      setLoading(false);
    } catch (err) {
      console.error('fetchDataForce error:', err);
      handleFetchError(err, 'force');
      setLoading(false);
    }
  };

  // ----------------------------------------------------
  // 4) fetchDataSilent => Polling => altes beibehalten, partial update
  // ----------------------------------------------------
  const fetchDataSilent = async () => {
    try {
      const { data } = await api.get('/top-gainers-losers', {
        params: {
          vs_currency: vsCurrency,
          duration,
          top_coins: topCoins,
        },
      });

      const [finalGainers, changedGainers] = updateCoinArray(
        prevGainersRef.current,
        data.top_gainers || []
      );
      const [finalLosers, changedLosers] = updateCoinArray(
        prevLosersRef.current,
        data.top_losers || []
      );

      if (changedGainers) {
        setGainers(finalGainers);
        prevGainersRef.current = finalGainers;
      }
      if (changedLosers) {
        setLosers(finalLosers);
        prevLosersRef.current = finalLosers;
      }
      retryCountSilentRef.current = 0;
    } catch (err) {
      console.error('fetchDataSilent error:', err);
      handleFetchError(err, 'silent');
    }
  };

  // ----------------------------------------------------
  // 5) Fehlerhandler + Retry
  // ----------------------------------------------------
  const handleFetchError = (err, mode) => {
    // Mode = 'force' oder 'silent'
    const isSilent = mode === 'silent';
    const retryRef = isSilent ? retryCountSilentRef : retryCountForceRef;

    // Prüfe 429
    if (err.response && err.response.status === 429) {
      console.log('API limit reached => handle special error...');
      handleError(new Error('API limit reached'));
      return;
    }

    // Prüfe Netzwerkfehler
    const isNetworkError =
      !err.response && (err.message.includes('Network') || err.message.includes('fetch'));

    if (isNetworkError) {
      if (retryRef.current < maxRetries) {
        retryRef.current++;
        const retryDelay = Math.pow(2, retryRef.current) * 1000; // 2s, 4s ...
        console.log(
          `fetchData${mode.charAt(0).toUpperCase() + mode.slice(1)}: Retry in ${
            retryDelay / 1000
          }s (Attempt ${retryRef.current} of ${maxRetries})`
        );
        setTimeout(() => {
          isSilent ? fetchDataSilent() : fetchDataForce();
        }, retryDelay);
        return;
      } else {
        // Max retries => Zeige Error
        handleError(err);
      }
    } else {
      // Non-network
      console.log('Non-network error, retry in 5s...');
      setTimeout(() => {
        isSilent ? fetchDataSilent() : fetchDataForce();
      }, 5000);
    }
  };

  const handleError = (err) => {
    let errorMessage;
    if (err.message.includes('fetch') || err.message.includes('Network')) {
      errorMessage = (
        <Trans id="error.networkError">
          Netzwerkfehler: Bitte überprüfe deine Internetverbindung.
        </Trans>
      );
    } else if (err.message.includes('API limit')) {
      errorMessage = (
        <Trans id="error.apiLimit">API-Limit erreicht. Bitte versuche es später erneut.</Trans>
      );
    } else {
      errorMessage = (
        <Trans id="error.unknownError">
          Ein unbekannter Fehler ist aufgetreten. Bitte versuche es erneut.
        </Trans>
      );
    }
    setError(
      <div>
        <div>{errorMessage}</div>
        <button
          onClick={() => {
            retryCountForceRef.current = 0;
            fetchDataForce();
          }}
        >
          <Trans id="button.retry">Erneut versuchen</Trans>
        </button>
      </div>
    );
  };

  // ----------------------------------------------------
  // 6) useEffects
  // ----------------------------------------------------
  // localStorage-Einstellungen
  useEffect(() => {
    const savedCurrency = localStorage.getItem('vsCurrency') || 'usd';
    const savedDuration = localStorage.getItem('duration') || '24h';
    const savedTopCoins = localStorage.getItem('topCoins') || '1000';

    setVsCurrency(savedCurrency);
    setDuration(savedDuration);
    setTopCoins(savedTopCoins);
  }, []);

  // Bei Änderung Filter/Währung => fetchDataForce => Polling
  useEffect(() => {
    localStorage.setItem('vsCurrency', vsCurrency);
    localStorage.setItem('duration', duration);
    localStorage.setItem('topCoins', topCoins);

    fetchDataForce();
    const interval = setInterval(() => {
      fetchDataSilent();
    }, 11000);

    return () => clearInterval(interval);
  }, [vsCurrency, duration, topCoins]);

  // ----------------------------------------------------
  // 7) Klick => navigate
  // ----------------------------------------------------
  const handleCoinClick = (coinId) => {
    navigate(`/coin-details?coin=${coinId}&from=2`);
  };

  // ----------------------------------------------------
  // 8) Handler
  // ----------------------------------------------------
  const handleDurationChange = (val) => {
    setDuration(val);
    localStorage.setItem('duration', val);
  };

  const handleTopCoinsChange = (val) => {
    setTopCoins(val);
    localStorage.setItem('topCoins', val);
  };

  // ----------------------------------------------------
  // Render
  // ----------------------------------------------------
  return (
    <SkeletonTheme baseColor="#ccc" highlightColor="#eee">
      <div className="gainers-losers-container">
        <h2 className="title">
          <Trans id="title.gainersLosers">Gainers & Losers</Trans>
        </h2>

        <p className="intro-text">
          <Trans id="gainersLosers.intro">
            Tauche ein in die Welt der Marktbewegungen – diese kompakte Übersicht der größten
            Gewinner und Verlierer versorgt dich mit den entscheidenden Daten, um deine
            Anlageentscheidungen optimal zu unterstützen.
          </Trans>
        </p>

        <div className="filters">
          <div className="filter-group">
            <label>
              {loading ? <Skeleton width={60} /> : <Trans id="label.duration">Dauer:</Trans>}
            </label>
            {loading ? (
              <Skeleton width={60} height={30} />
            ) : (
              <SingleSelectNoSearch
                options={['1h', '24h', '7d', '14d', '30d', '60d', '1y']}
                selectedValue={duration}
                onChange={handleDurationChange}
              />
            )}
          </div>

          <div className="filter-group">
            <label>
              {loading ? <Skeleton width={70} /> : <Trans id="label.topCoins">Top Coins:</Trans>}
            </label>
            {loading ? (
              <Skeleton width={60} height={30} />
            ) : (
              <SingleSelectNoSearch
                options={['300', '500', '1000', 'all']}
                selectedValue={topCoins}
                onChange={handleTopCoinsChange}
              />
            )}
          </div>
        </div>

        {error && <div className="error-message">{error}</div>}

        {!error && (
          <div className="tables-container">
            {/* Top Gainers */}
            <div className="table-section">
              <h3>{loading ? <Skeleton width={150} /> : <Trans id="title.topGainers">Top Gainers</Trans>}</h3>
              <table className="gainers-losers-table">
                <thead>
                  <tr>
                    {loading
                      ? Array.from({ length: 5 }).map((_, i) => (
                          <th key={i}>
                            <Skeleton width={80} />
                          </th>
                        ))
                      : (
                        <>
                          <th>
                            <Trans id="label.rank">Rank</Trans>
                            <OverlayTrigger
                              trigger="click"
                              placement="right"
                              overlay={popoverContent('Rank')}
                            >
                              <Button variant="link" className="info-button mobile-only">
                                💡
                              </Button>
                            </OverlayTrigger>
                          </th>
                          <th>
                            <Trans id="label.name">Name</Trans>
                          </th>
                          <th>
                            <Trans
                              id="label.priceWithCurrency"
                              values={{ currency: vsCurrency.toUpperCase() }}
                            >
                              Preis ({vsCurrency.toUpperCase()})
                            </Trans>
                            <OverlayTrigger
                              trigger="click"
                              placement="right"
                              overlay={popoverContent('Price')}
                            >
                              <Button variant="link" className="info-button mobile-only">
                                💡
                              </Button>
                            </OverlayTrigger>
                          </th>
                          <th>
                            <Trans
                              id="label.percentChangeWithDuration"
                              values={{ duration }}
                            >
                              {`${duration} % Change`}
                            </Trans>
                            <OverlayTrigger
                              trigger="click"
                              placement="right"
                              overlay={popoverContent(`${duration} %`)}
                            >
                              <Button variant="link" className="info-button mobile-only">
                                💡
                              </Button>
                            </OverlayTrigger>
                          </th>
                          <th>
                            <Trans
                              id="label.volumeWithDuration"
                              values={{ duration }}
                            >
                              Volumen ({duration})
                            </Trans>
                            <OverlayTrigger
                              trigger="click"
                              placement="right"
                              overlay={popoverContent(`Volumen (${duration})`)}
                            >
                              <Button variant="link" className="info-button mobile-only">
                                💡
                              </Button>
                            </OverlayTrigger>
                          </th>
                        </>
                      )}
                  </tr>
                </thead>
                <tbody>
                  {loading
                    ? Array.from({ length: 10 }).map((_, i) => (
                        <GainersLosersRow key={i} loading={true} />
                      ))
                    : gainers.map((coin) => (
                        <GainersLosersRow
                          key={coin.id}
                          coin={coin}
                          vsCurrency={vsCurrency}
                          duration={duration}
                          loading={false}
                          onClick={() => handleCoinClick(coin.id)}
                        />
                      ))}
                </tbody>
              </table>
            </div>

            {/* Top Losers */}
            <div className="table-section">
              <h3>{loading ? <Skeleton width={150} /> : <Trans id="title.topLosers">Top Losers</Trans>}</h3>
              <table className="gainers-losers-table">
                <thead>
                  <tr>
                    {loading
                      ? Array.from({ length: 5 }).map((_, i) => (
                          <th key={i}>
                            <Skeleton width={80} />
                          </th>
                        ))
                      : (
                        <>
                          <th>
                            <Trans id="label.rank">Rank</Trans>
                            <OverlayTrigger
                              trigger="click"
                              placement="right"
                              overlay={popoverContent('Rank')}
                            >
                              <Button variant="link" className="info-button mobile-only">
                                💡
                              </Button>
                            </OverlayTrigger>
                          </th>
                          <th>
                            <Trans id="label.name">Name</Trans>
                          </th>
                          <th>
                            <Trans
                              id="label.priceWithCurrency"
                              values={{ currency: vsCurrency.toUpperCase() }}
                            >
                              Preis ({vsCurrency.toUpperCase()})
                            </Trans>
                            <OverlayTrigger
                              trigger="click"
                              placement="right"
                              overlay={popoverContent('Price')}
                            >
                              <Button variant="link" className="info-button mobile-only">
                                💡
                              </Button>
                            </OverlayTrigger>
                          </th>
                          <th>
                            <Trans
                              id="label.percentChangeWithDuration"
                              values={{ duration }}
                            >
                              {`${duration} % Change`}
                            </Trans>
                            <OverlayTrigger
                              trigger="click"
                              placement="right"
                              overlay={popoverContent(`${duration} %`)}
                            >
                              <Button variant="link" className="info-button mobile-only">
                                💡
                              </Button>
                            </OverlayTrigger>
                          </th>
                          <th>
                            <Trans
                              id="label.volumeWithDuration"
                              values={{ duration }}
                            >
                              Volumen ({duration})
                            </Trans>
                            <OverlayTrigger
                              trigger="click"
                              placement="right"
                              overlay={popoverContent(`Volumen (${duration})`)}
                            >
                              <Button variant="link" className="info-button mobile-only">
                                💡
                              </Button>
                            </OverlayTrigger>
                          </th>
                        </>
                      )}
                  </tr>
                </thead>
                <tbody>
                  {loading
                    ? Array.from({ length: 10 }).map((_, i) => (
                        <GainersLosersRow key={i} loading={true} />
                      ))
                    : losers.map((coin) => (
                        <GainersLosersRow
                          key={coin.id}
                          coin={coin}
                          vsCurrency={vsCurrency}
                          duration={duration}
                          loading={false}
                          onClick={() => handleCoinClick(coin.id)}
                        />
                      ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </SkeletonTheme>
  );
};

export default GainersLosers;
