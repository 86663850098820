// src/components/dashboard/Top10DominanceMini.js
import React from 'react';
import { Trans } from '@lingui/macro';
import { i18n } from '@lingui/core';
import { Pie, Doughnut } from 'react-chartjs-2';
import { useGlobalData } from '../../hooks/useGlobalData';

// Falls du formatDate / formatFutureDate nutzen willst:
import { formatDate, formatFutureDate } from '../../utils/formatDate';

// Farben-Funktion für bekannte Coins
function getCoinColor(coin) {
  const coinColors = {
    BTC: '#F7931A',
    ETH: '#627EEA',
    STETH: '#627EEA',
    XRP: '#346AA9',
    BNB: '#F3BA2F',
    USDT: '#26A17B',
    USDC: '#26A17B',
    ADA: '#0033AD',
    SOL: '#00FFA3',
    DOGE: '#C2A633',
    DOT: '#E6007A',
    MATIC: '#8247E5',
    SHIB: '#FF6F61',
    TRX: '#EB0029',
    LTC: '#A6A9AA',
    AVAX: '#E84142',
    UNI: '#FF007A',
    LINK: '#2A5ADA',
    XLM: '#14B4E2',
    ATOM: '#2E3148',
    ETC: '#669073',
    XMR: '#FF6600',
    BCH: '#8DC351',
    APT: '#0D0D0D',
    ALGO: '#1A4DAB',
    VET: '#15BDFF',
    ICP: '#29ABE2',
  };
  return coinColors[coin.toUpperCase()] || '#CCCCCC';
}

/**
 * Minimaler Pie/Doughnut Chart für die Top 10 Coin-Dominanz.
 */
const Top10DominanceMini = () => {
  // Daten aus unserem Hook
  const { globalData, loading, error } = useGlobalData();

  if (loading) {
    return (
      <div className="top10-dominance-mini-widget">
        <Trans id="top10DominanceMini.loading">Loading Top 10 Dominance...</Trans>
      </div>
    );
  }
  if (error) {
    return (
      <div className="top10-dominance-mini-widget top10-dominance-mini-error">
        <Trans id="top10DominanceMini.error">Error:</Trans> {error}
      </div>
    );
  }
  if (!globalData || !globalData.market_cap_percentage) {
    return (
      <div className="top10-dominance-mini-widget">
        <Trans id="top10DominanceMini.noData">No dominance data found.</Trans>
      </div>
    );
  }

  // --- Top 10 Coins herausfiltern ---
  const marketCapPercentages = globalData.market_cap_percentage;
  let topCoins = Object.entries(marketCapPercentages)
    .sort(([, a], [, b]) => b - a)
    .slice(0, 10);

  // "Other" hinzufügen, wenn Summe < 100%
  const sumTop10 = topCoins.reduce((acc, [, perc]) => acc + perc, 0);
  if (sumTop10 < 100) {
    topCoins.push(['Other', 100 - sumTop10]);
  }

  // --- Mobile vs. Desktop ---
  const isMobile = window.innerWidth < 768;

  // --- Chart-Daten definieren ---
  const labels = topCoins.map(([coin]) =>
    coin === 'Other' ? i18n._('label.other') : coin.toUpperCase()
  );
  const data = {
    labels,
    datasets: [
      {
        data: topCoins.map(([, p]) => p),
        backgroundColor: topCoins.map(([coin]) => getCoinColor(coin)),
      },
    ],
  };

  // --- Optionen für Desktop: DataLabels im Chart ---
  const desktopOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = context.chart.data.labels[context.dataIndex] || '';
            const val = context.parsed;
            return `${label}: ${val.toFixed(2)}%`;
          },
        },
      },
      datalabels: {
        display: true,
        color: '#fff',
        anchor: 'end',
        align: 'start',
        offset: 10,
        formatter: (value, context) => {
          if (value < 5) return '';
          return context.chart.data.labels[context.dataIndex];
        },
      },
    },
  };

  // --- Optionen für Mobile: Nur BTC und ETH zeigen (in kleinem Font) ---
  const mobileOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = context.chart.data.labels[context.dataIndex] || '';
            const val = context.parsed;
            return `${label}: ${val.toFixed(2)}%`;
          },
        },
      },
      datalabels: {
        display: true,
        color: '#fff',
        font: { size: 8 },
        anchor: 'center',
        align: 'center',
        formatter: (value, context) => {
          const label = context.chart.data.labels[context.dataIndex];
          // Zeige nur BTC und ETH an
          if (label === 'BTC' || label === 'ETH') {
            return label;
          }
          return '';
        },
      },
    },
  };

  // --- OPTIONAL: Letztes / Nächstes Update ermitteln ---
  let updatedAtText = null;
  let nextUpdateText = null;
  if (globalData.updated_at) {
    const updatedSec = Number(globalData.updated_at);
    if (!isNaN(updatedSec) && updatedSec > 10000) {
      const isoUpdated = new Date(updatedSec * 1000).toISOString();
      updatedAtText = formatDate(isoUpdated);
    }
  }
  if (globalData.time_until_update) {
    const timeUntilSec = Number(globalData.time_until_update);
    if (!isNaN(timeUntilSec) && timeUntilSec > 0) {
      const futureISO = new Date(Date.now() + timeUntilSec * 1000).toISOString();
      nextUpdateText = formatFutureDate(futureISO);
    }
  }

  // --------------- Render ---------------
  return (
    <div className="top10-dominance-mini-widget">
      <h4>
        <Trans id="global.topCoinDominance">Top 10 Coin Dominance</Trans>
      </h4>

      <div
        className="top10-dominance-chart-container"
        style={{ width: '100%', height: isMobile ? '105px' : '220px' }}
      >
        {isMobile ? (
          <Doughnut data={data} options={mobileOptions} />
        ) : (
          <Pie data={data} options={desktopOptions} />
        )}
      </div>
    </div>
  );
};

export default Top10DominanceMini;
