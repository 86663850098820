// src/components/admin/AdminContactMessages.js
import React, { useState, useEffect } from 'react';
import AdminSubmenu from './AdminSubmenu';
import { Trans, t } from '@lingui/macro';
import { toast } from 'react-toastify';
import api from '../../services/api'; // <-- Unser zentrales Axios-Objekt
import '../../styles/AdminContactMessages.css';

import { formatDate } from '../../utils/formatDate';

// React-Icons
import {
  FaEye,
  FaTrash,
  FaLock,
  FaUnlock,
  FaHourglassHalf
} from 'react-icons/fa';

/** 
 * Übersetzungen/Fehlermapping
 */
function translateMessageKey(key) {
  switch (key) {
    case 'error.dbQueryFailed':
      return t({ id: 'error.dbQueryFailed', message: 'Datenbankfehler.' });
    case 'error.notFound':
      return t({ id: 'error.notFound', message: 'Ticket nicht gefunden.' });
    case 'error.replyMessageRequired':
      return t({ id: 'error.replyMessageRequired', message: 'Bitte eine Antwort eingeben.' });
    case 'error.internalServerError':
      return t({ id: 'error.internalServerError', message: 'Interner Serverfehler.' });
    case 'error.unknown':
      return t({ id: 'error.unknown', message: 'Unbekannter Fehler.' });

    case 'success.replySent':
      return t({ id: 'success.replySent', message: 'Antwort erfolgreich gesendet.' });
    case 'success.ticketStatusUpdated':
      return t({ id: 'success.ticketStatusUpdated', message: 'Ticketstatus aktualisiert.' });
    case 'success.ticketDeleted':
      return t({ id: 'success.ticketDeleted', message: 'Ticket erfolgreich gelöscht.' });

    default:
      return t({ id: 'error.unknown', message: 'Unbekannter Fehler.' });
  }
}

/** 
 * Axios-Error Helper
 */
function handleBackendError(err) {
  if (err.response) {
    const { status, data } = err.response;
    if (status === 403) {
      toast.error(t({ id: 'error.noPermission', message: 'Keine Berechtigung!' }));
    } else {
      // Versuche messageKey zu lesen
      toast.error(translateMessageKey(data?.messageKey || 'error.unknown'));
    }
  } else {
    // Netzwerk- oder Serverfehler
    toast.error(t({ id: 'error.internalServerError', message: 'Serverfehler oder Offline?' }));
  }
}

/**
 * Kleine Helferfunktion: Status -> Icon
 * 'open'        => FaUnlock  (grün)
 * 'in_progress' => FaHourglassHalf (gelb)
 * 'closed'      => FaLock (rot)
 */
function renderStatusIcon(status) {
  switch (status) {
    case 'open':
      return (
        <FaUnlock
          style={{ color: '#28a745' }}
          title={t({ id: 'admin.tickets.open', message: 'Offen' })}
        />
      );
    case 'in_progress':
      return (
        <FaHourglassHalf
          style={{ color: '#ffc107' }}
          title={t({ id: 'admin.tickets.inProgress', message: 'In Bearbeitung' })}
        />
      );
    case 'closed':
      return (
        <FaLock
          style={{ color: '#dc3545' }}
          title={t({ id: 'admin.tickets.closed', message: 'Geschlossen' })}
        />
      );
    default:
      return null;
  }
}

const AdminContactMessages = () => {
  const [tickets, setTickets] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [showDetailModal, setShowDetailModal] = useState(false);
  const [activeTicket, setActiveTicket] = useState(null);
  const [responses, setResponses] = useState([]);

  const [replyText, setReplyText] = useState('');

  const [filterStatus, setFilterStatus] = useState('');
  const [search, setSearch] = useState('');

  // ------------------------------------------------------------
  // 1) IMAP-Fetch + Tickets-Liste laden
  // ------------------------------------------------------------
  useEffect(() => {
    // 1) IMAP abrufen
    api.get('/admin/tickets/fetch-imap')
      .then((resp) => {
        console.log('IMAP fetch result:', resp.data);
      })
      .catch((err) => {
        console.error('Fehler beim IMAP-Fetch:', err);
        // Kein Abbruch, wir laden trotzdem die Tickets
      });

    // 2) Dann Tickets laden
    fetchTickets();
  }, []);

  // ------------------------------------------------------------
  // Filter -> Neu laden
  // ------------------------------------------------------------
  useEffect(() => {
    fetchTickets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterStatus]);

  function fetchTickets() {
    setIsLoading(true);

    let url = '/admin/tickets?';
    if (filterStatus) url += `status=${filterStatus}&`;
    if (search) url += `search=${encodeURIComponent(search)}&`;

    api.get(url)
      .then((resp) => {
        setIsLoading(false);
        const data = resp.data;
        setTickets(data.tickets);
      })
      .catch((err) => {
        setIsLoading(false);
        console.error('Fehler beim Laden der Tickets:', err);
        handleBackendError(err);
      });
  }

  // Suche
  const handleSearchSubmit = (e) => {
    e.preventDefault();
    fetchTickets();
  };

  // Details
  const handleViewDetails = (ticketHash) => {
    api.get(`/admin/tickets/${ticketHash}`)
      .then((resp) => {
        const data = resp.data;
        setActiveTicket(data.ticket);
        setResponses(data.responses || []);
        setShowDetailModal(true);
        setReplyText('');
      })
      .catch((err) => {
        console.error('Fehler beim Laden des Ticket-Details:', err);
        handleBackendError(err);
      });
  };

  // Antworten (POST /admin/tickets/:hash/reply)
  const handleSendReply = () => {
    if (!replyText.trim()) {
      toast.error(t({ id: 'error.replyMessageRequired', message: 'Bitte eine Antwort eingeben.' }));
      return;
    }
    if (!activeTicket) return;

    api.post(`/admin/tickets/${activeTicket.ticket_hash}/reply`, { messageText: replyText })
      .then((resp) => {
        toast.success(
          translateMessageKey(resp.data?.messageKey) || 'Antwort gesendet.'
        );
        setShowDetailModal(false);
        setActiveTicket(null);
        setReplyText('');
        fetchTickets();
      })
      .catch((err) => {
        console.error('Fehler beim Antworten:', err);
        handleBackendError(err);
      });
  };

  // Status ändern (PUT /admin/tickets/:hash/status)
  const handleChangeStatus = (ticketHash, newStatus) => {
    api.put(`/admin/tickets/${ticketHash}/status`, { status: newStatus })
      .then((resp) => {
        toast.success(
          translateMessageKey(resp.data?.messageKey) || 'Status aktualisiert.'
        );
        fetchTickets();
      })
      .catch((err) => {
        console.error('Fehler beim Status-Update:', err);
        handleBackendError(err);
      });
  };

  // Ticket löschen (DELETE /admin/tickets/:hash)
  const handleDelete = (ticketHash) => {
    if (!window.confirm('Wirklich Ticket löschen?')) return;

    api.delete(`/admin/tickets/${ticketHash}`)
      .then((resp) => {
        toast.success(
          translateMessageKey(resp.data?.messageKey) || 'Ticket gelöscht.'
        );
        setTickets((prev) => prev.filter((t) => t.ticket_hash !== ticketHash));
      })
      .catch((err) => {
        console.error('Fehler beim Ticketlöschen:', err);
        handleBackendError(err);
      });
  };

  return (
    <div className="admin-contact-messages-container">
      <AdminSubmenu />

      <h2>
        <Trans id="admin.tickets.header.title">Tickets verwalten</Trans>
      </h2>

      {/* Filter-Feld (kleinere Box) */}
      <div className="tickets-filter-container">
        <form onSubmit={handleSearchSubmit} className="tickets-filter-form">
          <input
            type="text"
            placeholder={t({ id: 'admin.tickets.searchPlaceholder', message: 'Suche...' })}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <select
            value={filterStatus}
            onChange={(e) => setFilterStatus(e.target.value)}
          >
            <option value="">
              {t({ id: 'admin.tickets.allStatuses', message: 'Alle Status' })}
            </option>
            <option value="open">
              {t({ id: 'admin.tickets.open', message: 'Offen' })}
            </option>
            <option value="in_progress">
              {t({ id: 'admin.tickets.inProgress', message: 'In Bearbeitung' })}
            </option>
            <option value="closed">
              {t({ id: 'admin.tickets.closed', message: 'Geschlossen' })}
            </option>
          </select>
          <button type="submit" className="admin-contact-messages-button">
            {t({ id: 'button.search', message: 'Suchen' })}
          </button>
        </form>
      </div>

      {isLoading ? (
        <p><Trans id="admin.tickets.loading">Lade Tickets...</Trans></p>
      ) : (
        <table className="admin-contact-messages-table">
          <thead>
            <tr>
              <th>Hash</th>
              <th>E-Mail</th>
              <th><Trans id="admin.contactMessages.subject">Betreff</Trans></th>
              <th><Trans id="admin.contactMessages.created">Erstellt am</Trans></th>
              <th><Trans id="admin.contactMessages.status">Status</Trans></th>
              <th><Trans id="admin.contactMessages.actions">Aktionen</Trans></th>
            </tr>
          </thead>
          <tbody>
            {tickets.map((tick) => (
              <tr key={tick.id}>
                <td>{tick.ticket_hash}</td>
                <td>{tick.email}</td>
                <td>{tick.subject}</td>

                {/* Datum */}
                <td>{formatDate(tick.created_at)}</td>

                {/* Status-Spalte => Icon */}
                <td>{renderStatusIcon(tick.status)}</td>

                {/* Aktionen */}
                <td style={{ display: 'flex', gap: '6px' }}>
                  {/* "Details" icon */}
                  <button
                    className="admin-contact-messages-button"
                    onClick={() => handleViewDetails(tick.ticket_hash)}
                    title={t({
                      id: 'admin.tickets.details',
                      message: 'Details ansehen'
                    })}
                  >
                    <FaEye />
                  </button>

                  {/* Status-Buttons */}
                  {tick.status !== 'closed' && (
                    <button
                      className="admin-contact-messages-button"
                      onClick={() => handleChangeStatus(tick.ticket_hash, 'closed')}
                      title={t({
                        id: 'admin.tickets.close',
                        message: 'Ticket schließen'
                      })}
                    >
                      <FaLock style={{ color: '#dc3545' }} />
                    </button>
                  )}
                  {tick.status === 'closed' && (
                    <button
                      className="admin-contact-messages-button"
                      onClick={() => handleChangeStatus(tick.ticket_hash, 'open')}
                      title={t({
                        id: 'admin.tickets.reopen',
                        message: 'Wieder öffnen'
                      })}
                    >
                      <FaUnlock style={{ color: '#28a745' }} />
                    </button>
                  )}
                  {tick.status === 'open' && (
                    <button
                      className="admin-contact-messages-button"
                      onClick={() => handleChangeStatus(tick.ticket_hash, 'in_progress')}
                      title={t({
                        id: 'admin.tickets.setInProgress',
                        message: 'In Bearbeitung'
                      })}
                    >
                      <FaHourglassHalf style={{ color: '#ffc107' }} />
                    </button>
                  )}

                  {/* Löschen */}
                  <button
                    className="admin-contact-messages-button"
                    onClick={() => handleDelete(tick.ticket_hash)}
                    title={t({
                      id: 'admin.contactMessages.deleteOne',
                      message: 'Ticket löschen'
                    })}
                  >
                    <FaTrash style={{ color: '#dc3545' }} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {/* Detail-/Reply-Modal */}
      {showDetailModal && activeTicket && (
        <div className="admin-contact-messages-reply-overlay">
          <div className="admin-contact-messages-reply-content">

            {/* HEADER */}
            <div className="admin-contact-messages-reply-header">
              <h3>
                <Trans id="admin.tickets.detailHeader">Ticket-Details</Trans>
              </h3>
            </div>

            {/* BODY */}
            <div className="admin-contact-messages-reply-body">
              <p>
                <strong>Hash:</strong> {activeTicket.ticket_hash}<br />
                <strong><Trans id="admin.contactMessages.name">Name</Trans>:</strong> {activeTicket.requester_name}<br />
                <strong><Trans id="admin.contactMessages.email">E-Mail</Trans>:</strong> {activeTicket.email}<br />
                <strong><Trans id="admin.contactMessages.subject">Betreff</Trans>:</strong> {activeTicket.subject}<br />
                <strong>Status:</strong> {activeTicket.status}<br />
                <strong><Trans id="admin.contactMessages.created">Erstellt am</Trans>:</strong> {formatDate(activeTicket.created_at)}
              </p>

              <hr />
              <h4><Trans id="admin.tickets.originalMessage">Ursprüngliche Nachricht</Trans></h4>
              <blockquote>
                {activeTicket.message_text}
              </blockquote>

              <hr />
              <h4><Trans id="admin.tickets.responses">Verlauf</Trans></h4>
              {responses.length === 0 ? (
                <p>
                  <Trans id="admin.tickets.noResponses">Keine Antworten vorhanden.</Trans>
                </p>
              ) : (
                responses.map((r) => (
                  <div key={r.id} className="admin-ticket-response-item">
                    <p className="admin-ticket-response-meta">
                      <strong>{r.responder_type === 'admin' ? 'Admin' : 'User'}</strong>{' '}
                      ({r.sender_email}) — {formatDate(r.created_at)}
                    </p>
                    <pre>{r.message_text}</pre>
                  </div>
                ))
              )}

              <hr />
              <h4><Trans id="admin.tickets.reply">Antwort verfassen</Trans></h4>
              <textarea
                className="admin-contact-messages-reply-textarea"
                rows={4}
                value={replyText}
                onChange={(e) => setReplyText(e.target.value)}
                placeholder={t({
                  id: 'admin.tickets.replyPlaceholder',
                  message: 'Deine Antwort...'
                })}
              />
            </div>

            {/* FOOTER */}
            <div className="admin-contact-messages-reply-footer admin-contact-messages-reply-actions">
              <button
                className="admin-contact-messages-button"
                onClick={handleSendReply}
              >
                <Trans id="admin.tickets.sendReply">Senden</Trans>
              </button>
              <button
                className="admin-contact-messages-button"
                onClick={() => {
                  setShowDetailModal(false);
                  setActiveTicket(null);
                  setResponses([]);
                  setReplyText('');
                }}
              >
                <Trans id="modal.cancel">Abbrechen</Trans>
              </button>
            </div>

          </div>
        </div>
      )}
    </div>
  );
};

export default AdminContactMessages;
