// src/components/GainersLosersRow.js
import React, { useEffect, useRef, useState } from 'react';
import { formatPrice } from '../utils/formatPrice';
import { formatLargeValue } from '../utils/formatLargeValue';
import { formatChangePercentage } from '../utils/formatChangePercentage';
import { t } from '@lingui/macro';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import FavoriteStar from './CoinDetails/FavoriteStar';

function truncateName(name, maxLength = 17) {
  if (!name) return "";
  return name.length > maxLength ? name.substring(0, maxLength) + "…" : name;
}

const GainersLosersRow = React.memo(({ coin, vsCurrency, duration, loading, onClick }) => {
  const prevCoinRef = useRef(coin);
  const [highlightPrice, setHighlightPrice] = useState(null);
  const [highlightVolume, setHighlightVolume] = useState(null);

  const isMobile = typeof window !== 'undefined' && window.innerWidth <= 768;

  useEffect(() => {
    if (loading) return; // Wenn wir laden, keine Highlight-Checks
    const prevCoin = prevCoinRef.current;

    if (!prevCoin || !coin) {
      prevCoinRef.current = coin;
      return;
    }

    const priceField = coin[vsCurrency];
    const prevPriceField = prevCoin[vsCurrency];
    const volumeFieldName = `${vsCurrency}_24h_vol`;
    const volumeValue = coin[volumeFieldName];
    const prevVolumeValue = prevCoin[volumeFieldName];

    const checkChange = (oldVal, newVal, setHighlight) => {
      if (oldVal !== newVal) {
        const direction = newVal > oldVal ? 'up' : 'down';
        setHighlight(direction);
        setTimeout(() => setHighlight(null), 1000);
      }
    };

    checkChange(prevPriceField, priceField, setHighlightPrice);
    checkChange(prevVolumeValue, volumeValue, setHighlightVolume);

    prevCoinRef.current = coin;
  }, [coin, vsCurrency, loading]);

  if (loading) {
    // Skeleton Ansicht
    return (
      <tr>
        <td><Skeleton width={20} /></td>
        <td>
          <Skeleton width={100} />
        </td>
        <td><Skeleton width={50} /></td>
        <td><Skeleton width={40} /></td>
        <td><Skeleton width={60} /></td>
      </tr>
    );
  }

  if (!coin) {
    return null;
  }

  const priceField = coin[vsCurrency];
  const priceChangeField = `${vsCurrency}_${duration}_change`;
  let priceChangeValue = coin[priceChangeField];

  if (priceChangeValue === undefined || priceChangeValue === null) {
    const fallbackField = `${vsCurrency}_24h_change`;
    priceChangeValue = coin[fallbackField];
  }
  if (priceChangeValue === undefined || priceChangeValue === null) {
    priceChangeValue = 0;
  }

  const priceChangeFormatted = formatChangePercentage(priceChangeValue);
  const volumeFieldName = `${vsCurrency}_24h_vol`;
  const volumeValue = coin[volumeFieldName] ?? 'N/A';
  const displayName = isMobile ? truncateName(coin.name, 17) : coin.name;

  return (
    <tr className="pointer-row" onClick={onClick}>
      <td data-label={t({ id: "label.rankMobile", message: "🏅 Rank" })}>
        {coin.market_cap_rank || 'N/A'}
      </td>
      <td className="coin-info-cell">
        <img src={coin.image} alt={coin.name} className="coin-logo" />
        <span className="coin-name">{displayName}</span>
        <span className="coin-symbol desktop-only">
          ({coin.symbol.toUpperCase()})
        </span>
        {/* Hier wird der FavoriteStar eingefügt */}
        <span onClick={(e) => e.stopPropagation()}>
          <FavoriteStar coinId={coin.id} coinData={coin} />
        </span>
      </td>
      <td data-label={t({ id: "label.priceMobile", message: "💲 Preis" })}>
        <span className={highlightPrice ? `highlight-${highlightPrice}` : ''}>
          {priceField !== undefined && priceField !== null
            ? formatPrice(priceField, vsCurrency)
            : <Skeleton width={50} />}
        </span>
      </td>
      <td data-label={t({ id: "label.percentChangeMobile", message: "🔄 {duration} %", values: { duration } })}>
        {priceChangeFormatted}
      </td>
      <td data-label={t({ id: "label.durationVolumeMobile", message: "💹 {duration} Volumen", values: { duration } })}>
        <span className={highlightVolume ? `highlight-${highlightVolume}` : ''}>
          {typeof volumeValue === 'number'
            ? formatLargeValue(volumeValue, vsCurrency)
            : <Skeleton width={60} />}
        </span>
      </td>
    </tr>
  );
});

export default GainersLosersRow;
