// src/components/admin/AdminConfig.js
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import AdminSubmenu from './AdminSubmenu';
import { Trans, t } from '@lingui/macro';
import api from '../../services/api'; // <-- Hier unsere Axios-Instanz
import '../../styles/AdminConfig.css';

/**
 * Hilfsfunktion zur Fehlerbehandlung
 */
function handleBackendError(err) {
  // Prüfe, ob eine Axios-Response vorliegt
  if (err.response) {
    const { status, data } = err.response;
    // 403 => "Keine Berechtigung"
    if (status === 403) {
      toast.error(t({ id: 'error.noPermission', message: 'Keine Berechtigung!' }));
    } else {
      // Zeige evtl. message aus data.error oder fallback:
      const errorMsg = data?.error || 'Fehler beim AdminConfig-Aufruf.';
      toast.error(errorMsg);
    }
  } else {
    // Kein response => Netzwerkfehler oder Abbruch
    toast.error(t({ id: 'admin.config.loadError', message: 'Netzwerk-/Serverfehler' }));
  }
}

const AdminConfig = () => {
  const [activeTab, setActiveTab] = useState('maintenance');
  const [isLoading, setIsLoading] = useState(true);

  // Wartungsmodus
  const [maintenanceMode, setMaintenanceMode] = useState(0);
  const [maintenanceTitleDe, setMaintenanceTitleDe] = useState('');
  const [maintenanceMessageDe, setMaintenanceMessageDe] = useState('');
  const [maintenanceTitleEn, setMaintenanceTitleEn] = useState('');
  const [maintenanceMessageEn, setMaintenanceMessageEn] = useState('');

  // Registrierung
  const [registrationEnabled, setRegistrationEnabled] = useState(1);

  // --------------------------------------------------
  // 1) Config laden (GET /api/admin/config/maintenance)
  // --------------------------------------------------
  useEffect(() => {
    setIsLoading(true);
    api.get('/admin/config/maintenance')
      .then((resp) => {
        setIsLoading(false);
        const data = resp.data;
        // Wartungsmodus
        setMaintenanceMode(data.maintenance_mode);
        setMaintenanceTitleDe(data.maintenance_title_de || '');
        setMaintenanceMessageDe(data.maintenance_message_de || '');
        setMaintenanceTitleEn(data.maintenance_title_en || '');
        setMaintenanceMessageEn(data.maintenance_message_en || '');
        // Registrierung
        setRegistrationEnabled(data.registration_enabled ?? 1);
      })
      .catch((err) => {
        setIsLoading(false);
        console.error('Fehler beim Laden der Config:', err);
        handleBackendError(err);
      });
  }, []);

  // --------------------------------------------------
  // 2) Speichern (PUT /api/admin/config/maintenance)
  // --------------------------------------------------
  const handleSaveConfig = () => {
    const body = {
      maintenance_mode: maintenanceMode,
      maintenance_title_de: maintenanceTitleDe,
      maintenance_message_de: maintenanceMessageDe,
      maintenance_title_en: maintenanceTitleEn,
      maintenance_message_en: maintenanceMessageEn,
      registration_enabled: registrationEnabled,
    };

    api.put('/admin/config/maintenance', body)
      .then((resp) => {
        if (resp.status === 200) {
          toast.success(t({
            id: 'admin.config.saveSuccess',
            message: 'Einstellungen gespeichert.'
          }));
        } else {
          // Falls der Status nicht 2xx ist (Axios wirft dann normal Error)
          // Aber sicherheitshalber:
          console.warn('Unerwarteter Status:', resp.status);
          toast.error('Fehler beim Speichern der Einstellungen.');
        }
      })
      .catch((err) => {
        console.error('Fehler beim Speichern:', err);
        handleBackendError(err);
      });
  };

  return (
    <div className="admin-config-container">
      <AdminSubmenu />

      <h2>
        <Trans id="admin.config.header.title">System-Einstellungen</Trans>
      </h2>

      {/* Tabs: Wartungsmodus / Registrierung */}
      <div className="admin-config-tabs">
        <button
          className={activeTab === 'maintenance' ? 'active' : ''}
          onClick={() => setActiveTab('maintenance')}
        >
          <Trans id="admin.config.tabs.maintenance">Wartungsmodus</Trans>
        </button>
        <button
          className={activeTab === 'registration' ? 'active' : ''}
          onClick={() => setActiveTab('registration')}
        >
          <Trans id="admin.config.tabs.registration">Registrierung</Trans>
        </button>
      </div>

      {/* TAB-Bereich: Wartungsmodus */}
      {activeTab === 'maintenance' && (
        <div className="tab-content">
          {isLoading ? (
            <p>
              <Trans id="admin.config.loading">Lade aktuelle Einstellungen...</Trans>
            </p>
          ) : (
            <div className="admin-config-form">
              <div className="form-group">
                <label>
                  <Trans id="admin.config.form.maintenanceModeLabel">
                    Wartungsmodus aktiv?
                  </Trans>
                </label>
                <select
                  value={maintenanceMode}
                  onChange={(e) => setMaintenanceMode(Number(e.target.value))}
                >
                  <option value={0}>
                    <Trans id="admin.config.form.maintenanceModeNo">Nein (0)</Trans>
                  </option>
                  <option value={1}>
                    <Trans id="admin.config.form.maintenanceModeYes">Ja (1)</Trans>
                  </option>
                </select>
              </div>

              <hr />

              <h4>
                <Trans id="admin.config.form.deTitle">Deutsche Texte</Trans>
              </h4>
              <div className="form-group">
                <label>
                  <Trans id="admin.config.form.titleDE">Titel (DE)</Trans>
                </label>
                <input
                  type="text"
                  value={maintenanceTitleDe}
                  onChange={(e) => setMaintenanceTitleDe(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>
                  <Trans id="admin.config.form.messageDE">Nachricht (DE)</Trans>
                </label>
                <textarea
                  rows="2"
                  value={maintenanceMessageDe}
                  onChange={(e) => setMaintenanceMessageDe(e.target.value)}
                />
              </div>

              <hr />

              <h4>
                <Trans id="admin.config.form.enTitle">Englische Texte</Trans>
              </h4>
              <div className="form-group">
                <label>
                  <Trans id="admin.config.form.titleEN">Titel (EN)</Trans>
                </label>
                <input
                  type="text"
                  value={maintenanceTitleEn}
                  onChange={(e) => setMaintenanceTitleEn(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>
                  <Trans id="admin.config.form.messageEN">Nachricht (EN)</Trans>
                </label>
                <textarea
                  rows="2"
                  value={maintenanceMessageEn}
                  onChange={(e) => setMaintenanceMessageEn(e.target.value)}
                />
              </div>

              {/* Gemeinsamer Speichern-Button */}
              <div className="form-buttons">
                <button onClick={handleSaveConfig}>
                  <Trans id="admin.config.saveButton">Speichern</Trans>
                </button>
              </div>
            </div>
          )}
        </div>
      )}

      {/* TAB-Bereich: Registrierung */}
      {activeTab === 'registration' && (
        <div className="tab-content">
          {isLoading ? (
            <p>
              <Trans id="admin.config.loading">Lade aktuelle Einstellungen...</Trans>
            </p>
          ) : (
            <div className="admin-config-form">
              <div className="form-group">
                <label>
                  <Trans id="admin.config.registration.enabledLabel">
                    Registrierung aktiviert?
                  </Trans>
                </label>
                <select
                  value={registrationEnabled}
                  onChange={(e) => setRegistrationEnabled(Number(e.target.value))}
                >
                  <option value={1}>
                    <Trans id="admin.config.registration.enabledYes">Ja (1)</Trans>
                  </option>
                  <option value={0}>
                    <Trans id="admin.config.registration.enabledNo">Nein (0)</Trans>
                  </option>
                </select>
              </div>

              {/* Gleicher Speichern-Button => updated die ganze Config */}
              <div className="form-buttons">
                <button onClick={handleSaveConfig}>
                  <Trans id="admin.config.saveButton">Speichern</Trans>
                </button>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AdminConfig;
