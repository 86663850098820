// src/components/favorites/UserFavoritesManager.js

import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Trans, t } from '@lingui/macro';
import { toast } from 'react-toastify';
import { StyledInput } from '../../utils/StyledInput';
import { StyledButton } from '../../utils/StyledButton';
import api from '../../services/api'; // <-- Unser zentrales Axios-Objekt
import { useFavorites } from '../../context/FavoritesContext';
import { formatLargeValue } from '../../utils/formatLargeValue';
import { formatChangePercentage } from '../../utils/formatChangePercentage';
import { formatPrice } from '../../utils/formatPrice';
import { formatDate } from '../../utils/formatDate';

// Icons
import { FaChevronDown, FaChevronUp, FaEdit, FaTrash, FaFolderOpen } from 'react-icons/fa';

// CSS
import '../../styles/UserFavoritesManager.css';

// Binance-WS Manager
import { subscribeToSymbols, unsubscribeAll } from './binanceFavorites';

// Zeilen-Komponente für Coin-Daten
import FavoriteCoinRow, { MiniSparkline } from './FavoriteCoinRow';

/**
 * UserFavoritesManager:
 * - Lädt Ordner & Favoriten
 * - Holt Coin-Daten (Coingecko) inkl. Sparkline
 * - Aktualisiert Live-Daten via Binance Websocket
 * - Zeigt "Favoriten ohne Ordner" als eigene Card mit Header
 *   und pro Ordner eine Card (mit sichtbarem Ordnernamen im Header)
 * - Ermöglicht das Umbenennen und Löschen von Ordnern sowie das Löschen von Favoriten.
 * - Der "Ordner erstellen"-Bereich entfällt.
 */
const UserFavoritesManager = () => {
  // Lokaler State für Favoriten und Coin-Daten
  const [favorites, setFavorites] = useState([]);
  const [favCoinsData, setFavCoinsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [exchangeRatesLoaded, setExchangeRatesLoaded] = useState(false);
  const originalCoinsRef = useRef([]);
  const [vsCurrency, setVsCurrency] = useState('usd');
  const [conversionFactor, setConversionFactor] = useState(1);

  // Zusätzliche State-Variablen zum Verschieben
  const [moveModalOpen, setMoveModalOpen] = useState(false);
  const [favoriteToMove, setFavoriteToMove] = useState(null);
  const [selectedMoveFolder, setSelectedMoveFolder] = useState(null);

  // Für das Löschen eines Favoriten (statt window.confirm)
  const [deleteFavModalOpen, setDeleteFavModalOpen] = useState(false);
  const [favoriteToDelete, setFavoriteToDelete] = useState(null);

  // Zusätzliche State-Variablen für das Rename-Modal
  const [renameFolderId, setRenameFolderId] = useState(null);
  const [renameModalOpen, setRenameModalOpen] = useState(false);
  const [folderToRename, setFolderToRename] = useState(null);
  const [renameFolderName, setRenameFolderName] = useState('');

  // Für das Löschen eines Ordners inkl. Leeren-Funktionalität
  const [deleteFolderModalOpen, setDeleteFolderModalOpen] = useState(false);
  const [folderToDelete, setFolderToDelete] = useState(null);
  // selectedFolderDeleteAction:
  // "deleteFolderWithFavs": Ordner löschen UND Favoriten löschen
  // "deleteFolderOnly": Nur Ordner löschen (Favoriten bleiben, folder_id → NULL)
  // "deleteFavsOnly": Nur Favoriten löschen, Ordner bleibt erhalten
  const [selectedFolderDeleteAction, setSelectedFolderDeleteAction] = useState('');

  // Für die Ordner-Erstellung im Move-Modal:
  const [creatingFolder, setCreatingFolder] = useState(false);
  const [newFolderName, setNewFolderName] = useState('');
  const [simpleDeleteMode, setSimpleDeleteMode] = useState(false);

  // Verwende den FavoritesContext – hier erhalten wir den globalen Ordner-Zustand und die fetchFolders()-Funktion
  const { folders, fetchFolders, removeFavorite } = useFavorites();



  const loadCoinData = useCallback(
    async (coinIds) => {
      if (coinIds.length === 0) return [];
      const joined = coinIds.join(',');

      try {
        // Axios-Request an euren /coins-markets-Endpunkt
        const { data } = await api.get('/coins-markets', {
          params: {
            vs_currency: vsCurrency,
            ids: joined,
            sparkline: true
          }
        });
        // data ist ein Array von Coin-Objekten
        const mapped = data.map((c) => ({
          ...c,
          updatedByBinance: false,
          current_price: c.current_price,
          initialPrice: c.current_price,
          initialPercentageChange: c.price_change_percentage_24h,
          originalPriceUsd: vsCurrency.toLowerCase() === 'usd' ? c.current_price : undefined,
          coingeckoPriceVs: c.current_price,
          market_cap_rank: c.market_cap_rank,
        }));
        return mapped;
      } catch (err) {
        console.error('loadCoinData error:', err);
        toast.error('Fehler beim Laden von Coin-Daten.');
        return [];
      }
    },
    [vsCurrency] // Abhängigkeit, weil du vsCurrency verwendest
  );



 // Hauptfunktion zum Laden der Favoriten und der zugehörigen Marktdaten
 const loadData = useCallback(async () => {
  // console.log("UserFavoritesManager: loadData triggered");
  setLoading(true);
  try {
      // 1. Favoriten vom Backend holen (enthält KEIN Symbol!)
      const { data: favData } = await api.get('/favorites');
      const rawFavorites = Array.isArray(favData) ? favData.map(f => ({ ...f, coin_id: String(f.coin_id) })) : [];

      const coinIds = rawFavorites.map((f) => f.coin_id).filter(Boolean);

      if (coinIds.length > 0) {
          // 2. Marktdaten für diese IDs laden (enthält das Symbol)
          const marketData = await loadCoinData(coinIds);
          const marketDataMap = new Map(marketData.map(item => [item.id, item])); // Map für schnellen Zugriff

          // 3. Favoritenliste ANREICHERN mit dem Symbol aus den Marktdaten
          const enrichedFavorites = rawFavorites.map(fav => {
              const coinMarketData = marketDataMap.get(fav.coin_id);
              return {
                  ...fav,
                  symbol: coinMarketData?.symbol || null // Füge Symbol hinzu!
              };
          }).filter(fav => fav.symbol); // Nur Favoriten behalten, für die wir ein Symbol finden konnten

           // Setze den `favorites` State mit den angereicherten Daten (inkl. Symbol)
           enrichedFavorites.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
           setFavorites(enrichedFavorites); // << WICHTIG: favorites enthält jetzt das Symbol
           // console.log("UserFavoritesManager: Enriched Favorites state set:", enrichedFavorites);

          // 4. `favCoinsData` für die Anzeige erstellen (mergen)
          const mergedDisplayData = marketData
              .filter(md => enrichedFavorites.some(ef => ef.coin_id === md.id))
              .map((coinMarketData) => {
                  const favoriteDetails = enrichedFavorites.find((fav) => fav.coin_id === coinMarketData.id);
                  return {
                      ...coinMarketData,
                      folder_id: favoriteDetails?.folder_id || null,
                      price_at_add: favoriteDetails?.price_at_add != null ? parseFloat(favoriteDetails.price_at_add) : null,
                      marketcap_at_add: favoriteDetails?.marketcap_at_add != null ? parseFloat(favoriteDetails.marketcap_at_add) : null,
                      volume_24h_at_add: favoriteDetails?.volume_24h_at_add != null ? parseFloat(favoriteDetails.volume_24h_at_add) : null,
                      created_at: favoriteDetails?.created_at || null,
                      market_cap_rank_at_add: favoriteDetails?.market_cap_rank_at_add != null
                          ? parseFloat(favoriteDetails.market_cap_rank_at_add)
                          : coinMarketData.market_cap_rank,
                      // initialPrice und originalPriceUsd kommen bereits korrekt aus loadCoinData
                  };
              });
          // Sortiere Anzeigedaten passend zur Favoritenliste
           mergedDisplayData.sort((a, b) => {
               const favA = enrichedFavorites.find(f => f.coin_id === a.id);
               const favB = enrichedFavorites.find(f => f.coin_id === b.id);
               return (favB?.created_at ? new Date(favB.created_at) : 0) - (favA?.created_at ? new Date(favA.created_at) : 0);
           });

          setFavCoinsData(mergedDisplayData);
          originalCoinsRef.current = JSON.parse(JSON.stringify(mergedDisplayData)); // Tiefe Kopie für Originalwerte

      } else {
          // Keine Favoriten
          // console.log("UserFavoritesManager: No favorites found, clearing states.");
          setFavorites([]);
          setFavCoinsData([]);
          originalCoinsRef.current = [];
      }
  } catch (err) {
      console.error('UserFavoritesManager loadData error:', err);
      toast.error(t({ id: 'favorites.loadError', message: 'Fehler beim Laden der Favoriten.' }));
       setFavorites([]);
       setFavCoinsData([]);
       originalCoinsRef.current = [];
  } finally {
      setLoading(false);
  }
}, [loadCoinData]); // loadCoinData als Abhängigkeit


  // --------------------------
  // Folder CRUD über den Context:
  async function handleCreateFolder(e) {
    e.preventDefault();
    if (!newFolderName.trim()) return;
    try {
      // POST /api/favorites/folders
      const { data: newFolder } = await api.post('/favorites/folders', {
        folder_name: newFolderName
      });

      toast.success(t({ id: 'favorit.toast.folderCreated', message: 'Neuer Ordner erstellt' }));
      await fetchFolders(); // Aktualisiere die Ordnerliste im Context
      // Wähle den neu erstellten Ordner automatisch aus:
      setSelectedMoveFolder(newFolder.folder_id);
      setNewFolderName('');
      setCreatingFolder(false);
    } catch (err) {
      console.error('Fehler createFolder:', err);
      const msg = err.response?.data?.message || err.message;
      toast.error(msg);
    }
  }

  async function handleRenameFolderModalConfirm() {
    if (!renameFolderName.trim()) return;
    try {
      // PUT /api/favorites/folders/:folderId
      await api.put(`/favorites/folders/${renameFolderId}`, {
        folder_name: renameFolderName
      });

      toast.success(t({ id: 'favorites.folderRenamed', message: 'Ordner umbenannt' }));
      await fetchFolders();
      setRenameFolderId(null);
      setRenameFolderName('');
      closeRenameModal();
    } catch (err) {
      console.error('renameFolder error:', err);
      const msg = err.response?.data?.message || err.message;
      toast.error(msg);
    }
  }

  async function handleSimpleDeleteFolder() {
    if (!folderToDelete) return;
    try {
      // DELETE /api/favorites/folders/:folderId
      await api.delete(`/favorites/folders/${folderToDelete.folder_id}`);

      toast.success(t({ id: 'favorites.folderDeleted', message: 'Ordner gelöscht' }));
      await fetchFolders();
      closeDeleteFolderModal();
    } catch (err) {
      console.error('handleSimpleDeleteFolder error:', err);
      const msg = err.response?.data?.message || err.message;
      toast.error(msg);
    }
  }

  async function confirmDeleteFolder() {
    if (!folderToDelete) return;
    if (!selectedFolderDeleteAction) {
      toast.error(t({ id: 'favorites.folderDeleteActionRequired', message: 'Bitte wähle eine Aktion aus.' }));
      return;
    }
    try {
      if (selectedFolderDeleteAction === 'deleteFolderWithFavs') {
        // 1) Alle Favoriten im Ordner löschen => PUT /api/favorites/folders/:folderId/empty with {action: 'delete'}
        await api.put(`/favorites/folders/${folderToDelete.folder_id}/empty`, {
          action: 'delete'
        });
        // 2) Ordner löschen
        await api.delete(`/favorites/folders/${folderToDelete.folder_id}`);

        toast.success(t({
          id: 'favorites.folderDeletedWithFavs',
          message: 'Ordner gelöscht – Favoriten wurden ebenfalls entfernt.'
        }));

        // Update Favorites- & Coin-Daten-State
        setFavorites((prev) => {
          const updated = prev.filter(fav => String(fav.folder_id) !== String(folderToDelete.folder_id));
          prev
            .filter(fav => String(fav.folder_id) === String(folderToDelete.folder_id))
            .forEach(fav => removeFavorite(String(fav.coin_id)));
          return updated;
        });
        setFavCoinsData((prev) =>
          prev.filter((coin) => {
            return !favorites.find(
              (fav) =>
                String(fav.coin_id) === String(coin.id) &&
                String(fav.folder_id) === String(folderToDelete.folder_id)
            );
          })
        );

      } else if (selectedFolderDeleteAction === 'deleteFolderOnly') {
        // Entferne Zuordnung der Favoriten (action: 'remove') => PUT /api/favorites/folders/:folderId/empty
        await api.put(`/favorites/folders/${folderToDelete.folder_id}/empty`, {
          action: 'remove'
        });
        // Anschließend Ordner löschen
        await api.delete(`/favorites/folders/${folderToDelete.folder_id}`);

        toast.success(t({
          id: 'favorites.folderDeletedOnly',
          message: 'Ordner gelöscht – Favoriten wurden beibehalten.'
        }));

        // Favoriten folder_id auf null setzen
        setFavorites((prev) =>
          prev.map((fav) =>
            String(fav.folder_id) === String(folderToDelete.folder_id)
              ? { ...fav, folder_id: null }
              : fav
          )
        );

      } else if (selectedFolderDeleteAction === 'deleteFavsOnly') {
        // Lösche ausschließlich die Favoriten => PUT /api/favorites/folders/:folderId/empty with action:'delete'
        await api.put(`/favorites/folders/${folderToDelete.folder_id}/empty`, {
          action: 'delete'
        });

        toast.success(t({
          id: 'favorites.folderEmptiedDelete',
          message: 'Favoriten im Ordner wurden gelöscht.'
        }));

        // Entferne alle Favoriten, die in diesem Ordner waren
        setFavorites((prev) => {
          const updated = prev.filter(fav => String(fav.folder_id) !== String(folderToDelete.folder_id));
          prev
            .filter(fav => String(fav.folder_id) === String(folderToDelete.folder_id))
            .forEach(fav => removeFavorite(String(fav.coin_id)));
          return updated;
        });
        setFavCoinsData((prev) =>
          prev.filter((coin) => {
            return !favorites.find(
              (fav) =>
                String(fav.coin_id) === String(coin.id) &&
                String(fav.folder_id) === String(folderToDelete.folder_id)
            );
          })
        );
      }
      await fetchFolders();
      closeDeleteFolderModal();
    } catch (err) {
      console.error('deleteFolder error:', err);
      const msg = err.response?.data?.message || err.message;
      toast.error(msg);
    }
  }

  function openDeleteFavModal(fav) {
    setFavoriteToDelete(fav);
    setDeleteFavModalOpen(true);
  }

  function closeDeleteFavModal() {
    setDeleteFavModalOpen(false);
    setFavoriteToDelete(null);
  }

  function handleRemoveFavorite(fav) {
    openDeleteFavModal(fav);
  }

  async function confirmDeleteFavorite() {
    if (!favoriteToDelete) return;
    try {
      // DELETE /api/favorites/:coinId
      await api.delete(`/favorites/${favoriteToDelete.coin_id}`);

      toast.success(t({ id: 'favorites.favoriteRemoved', message: 'Gelöscht' }));
      setFavorites((prev) => prev.filter((f) => f.coin_id !== favoriteToDelete.coin_id));
      setFavCoinsData((prev) => prev.filter((c) => c.id !== favoriteToDelete.coin_id));
      removeFavorite(String(favoriteToDelete.coin_id));
      closeDeleteFavModal();
    } catch (err) {
      console.error('deleteFavorite error:', err);
      const msg = err.response?.data?.message || err.message;
      toast.error(msg);
    }
  }

  function openDeleteFolderModal(folder) {
    setFolderToDelete(folder);
    // Prüfe, ob der Ordner leer ist:
    const favoritesInFolder = favorites.filter((f) => f.folder_id === folder.folder_id);
    if (favoritesInFolder.length === 0) {
      setSimpleDeleteMode(true);
    } else {
      setSimpleDeleteMode(false);
      setSelectedFolderDeleteAction('');
    }
    setDeleteFolderModalOpen(true);
  }

  function closeDeleteFolderModal() {
    setDeleteFolderModalOpen(false);
    setFolderToDelete(null);
    setSelectedFolderDeleteAction('');
  }

  function openRenameModal(folder) {
    setFolderToRename(folder);
    setRenameFolderName(folder.folder_name);
    setRenameFolderId(folder.folder_id);
    setRenameModalOpen(true);
  }

  function closeRenameModal() {
    setRenameModalOpen(false);
    setFolderToRename(null);
    setRenameFolderName('');
    setRenameFolderId(null);
  }

  // Öffnet das Move-Modal für einen bestimmten Favoriten
  function openMoveModal(fav) {
    setFavoriteToMove(fav);
    setSelectedMoveFolder(fav.folder_id || null);
    setMoveModalOpen(true);
  }

  function closeMoveModal() {
    setMoveModalOpen(false);
    setFavoriteToMove(null);
    setSelectedMoveFolder(null);
  }

  async function handleMoveFavorite() {
    if (!favoriteToMove) return;
    try {
      // PUT /api/favorites/:coinId/folder
      await api.put(`/favorites/${favoriteToMove.coin_id}/folder`, {
        folder_id: selectedMoveFolder
      });

      toast.success(t({ id: 'favorites.favoriteMoved', message: 'Favorit verschoben' }));
      setFavorites((prev) =>
        prev.map((fav) =>
          fav.coin_id === favoriteToMove.coin_id ? { ...fav, folder_id: selectedMoveFolder } : fav
        )
      );
      closeMoveModal();
    } catch (err) {
      console.error('moveFavorite error:', err);
      const msg = err.response?.data?.message || err.message;
      toast.error(msg);
    }
  }

  // --------------------------
  // Weitere Effekte (Währung, Binance-WS, Datenladen)
  useEffect(() => {
    if (vsCurrency.toLowerCase() === 'usd') {
      setConversionFactor(1);
      setExchangeRatesLoaded(true);
    } else {
      api
        .get('/exchange-rates')
        .then(({ data }) => {
          const rates = data.rates;
          if (rates && rates[vsCurrency.toLowerCase()] && rates['usd']) {
            const factor =
              rates[vsCurrency.toLowerCase()].value / rates['usd'].value;
            setConversionFactor(factor);
          } else {
            setConversionFactor(1);
          }
          setExchangeRatesLoaded(true);
        })
        .catch((err) => {
          console.error(err);
          setConversionFactor(1);
          setExchangeRatesLoaded(true);
        });
    }
  }, [vsCurrency]);

  useEffect(() => {
    const stored = localStorage.getItem('vsCurrency') || 'usd';
    setVsCurrency(stored);
  }, []);

  useEffect(() => {
    if (exchangeRatesLoaded) {
      loadData();
    }
  }, [exchangeRatesLoaded, loadData]);

  // Binance-Websocket-Updates
  const pendingWsUpdatesRef = useRef({});
  useEffect(() => {
    if (favCoinsData.length > 0) {
      const symbols = favCoinsData
        .map((coin) => coin.symbol && coin.symbol.toLowerCase())
        .filter(Boolean);
      subscribeToSymbols(symbols, handleBinanceUpdate);
    }
  }, [favCoinsData]);

  function handleBinanceUpdate(baseSymbol, data) { // Nimm das ganze Objekt/null
    // Prüfe, ob data existiert UND data.price definiert ist
    if (data && data.price !== undefined) {
        // Nur wenn gültig, speichere den Preis
        pendingWsUpdatesRef.current[baseSymbol] = data.price;
    } else {
        // Optional: Logge, dass ein Fehler oder ungültige Daten kamen
        // Dieser Teil verhindert nur den Crash, nicht die Schleife!
        console.warn(`handleBinanceUpdate: Received no valid price data for ${baseSymbol}. Data:`, data);
    }
 }

  useEffect(() => {
    const intervalId = setInterval(() => {
      const updates = { ...pendingWsUpdatesRef.current };
      pendingWsUpdatesRef.current = {};
      if (!Object.keys(updates).length) return;
      setFavCoinsData((prev) =>
        prev.map((coin) => {
          const sym = coin.symbol?.toLowerCase();
          if (!sym || !updates[sym]) return coin;
          const binancePriceUsd = updates[sym];
          let finalPrice =
            vsCurrency.toLowerCase() === 'usd'
              ? binancePriceUsd
              : binancePriceUsd * conversionFactor;
          const pctChange =
            coin.initialPercentageChange +
            ((finalPrice - coin.initialPrice) / coin.initialPrice) * 100;
          return {
            ...coin,
            current_price: finalPrice,
            price_change_percentage_24h: pctChange,
            updatedByBinance: true
          };
        })
      );
    }, 3000);
    return () => clearInterval(intervalId);
  }, [vsCurrency, conversionFactor]);

  useEffect(() => {
    return () => {
      unsubscribeAll();
    };
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const coinIds = favorites.map((f) => f.coin_id).filter(Boolean);
      if (coinIds.length > 0) {
        loadCoinData(coinIds);
      }
    }, 11000);
    return () => clearInterval(intervalId);
  }, [favorites, conversionFactor, loadCoinData]);

  useEffect(() => {
    if (originalCoinsRef.current.length > 0) {
      setFavCoinsData(
        originalCoinsRef.current.map((coin) => {
          if (coin.originalPriceUsd !== undefined) {
            return {
              ...coin,
              current_price: coin.originalPriceUsd * conversionFactor,
              initialPrice: coin.originalPriceUsd * conversionFactor
            };
          } else {
            return coin;
          }
        })
      );
    }
  }, [conversionFactor, vsCurrency]);

  // --------------------------
  // Render
  // Aufteilen: Favoriten ohne Ordner und sortierte Ordner (aus dem Context)
  const favoritesWithoutFolder = favorites.filter((f) => !f.folder_id);
  const sortedFolders = [...folders].sort((a, b) =>
    a.folder_name.localeCompare(b.folder_name, undefined, { sensitivity: 'base' })
  );

  return (
    <div className="favorites-manager-container">
      <h2>
        <Trans id="favoritesManager.title">Favoriten & Ordner verwalten</Trans>
      </h2>

      {/* (1) Favoriten ohne Ordner als eigene Card */}
      <div className="favorites-card" style={{ marginBottom: '30px' }}>
        <div className="favorites-card-header">
          <h3>
            <Trans id="favoritesManager.withoutFolder">Favoriten ohne Ordner</Trans>
          </h3>
        </div>
        <div className="favorites-card-body">
          <FavoritesTable
            emptyKey="favoritesManager.noFavsWithoutFolder"
            favorites={favoritesWithoutFolder}
            vsCurrency={vsCurrency}
            findCoinDataFor={(coin_id) => favCoinsData.find((c) => c.id === coin_id) || { loading: true }}
            onRemoveFavorite={handleRemoveFavorite}
            onMove={openMoveModal}
          />
        </div>
      </div>

      {/* (2) Ordner-Karten */}
      {sortedFolders.map((folder) => {
        const favsInFolder = favorites.filter((f) => f.folder_id === folder.folder_id);
        return (
          <div className="favorites-card" key={folder.folder_id}>
            <div className="favorites-card-header folder-header">
              <h3 className="folder-title-favs">{folder.folder_name}</h3>
              <div className="folder-header-actions">
                <StyledButton
                  variant="icon"
                  className="favorites-icon-btn"
                  onClick={() => openRenameModal(folder)}
                  title={t({ id: 'favoritesManager.rename', message: 'Umbenennen' })}
                >
                  <FaEdit />
                </StyledButton>
                <StyledButton
                  variant="icon"
                  iconType="delete"
                  className="favorites-icon-btn-delete"
                  onClick={() => openDeleteFolderModal(folder)}
                  title={t({ id: 'favoritesManager.delete', message: 'Löschen' })}
                >
                  <FaTrash />
                </StyledButton>
              </div>
            </div>
            <div className="favorites-card-body">
              <FavoritesTable
                emptyKey="favoritesManager.noFavInThisFolder"
                favorites={favsInFolder}
                vsCurrency={vsCurrency}
                findCoinDataFor={(coin_id) => favCoinsData.find((c) => c.id === coin_id) || { loading: true }}
                onRemoveFavorite={handleRemoveFavorite}
                onMove={openMoveModal}
              />
            </div>
          </div>
        );
      })}

      {renameModalOpen && (
        <div className="favorites-modal-backdrop">
          <div className="favorites-modal-content">
            <h3>
              <Trans id="favoritesManager.renameModalTitle">Ordner umbenennen</Trans>
            </h3>
            <div className="rename-modal-body">
              <StyledInput
                value={renameFolderName}
                onChange={(e) => setRenameFolderName(e.target.value)}
                autoFocus
              />
            </div>
            <div className="modal-actions" style={{ marginTop: '20px' }}>
              <StyledButton variant="confirm" onClick={handleRenameFolderModalConfirm}>
                <Trans id="favoritesManager.save">Speichern</Trans>
              </StyledButton>
              <StyledButton variant="cancel" onClick={closeRenameModal}>
                <Trans id="favoritesManager.cancel">Abbrechen</Trans>
              </StyledButton>
            </div>
          </div>
        </div>
      )}

      {deleteFolderModalOpen && (
        <div className="favorites-modal-backdrop">
          <div className="favorites-modal-content">
            {simpleDeleteMode ? (
              <>
                <h3>
                  <Trans id="favoritesManager.deleteFolderModalTitle">Ordner löschen</Trans>
                </h3>
                <p>
                  <Trans id="favoritesManager.simpleDeleteDesc">
                    Möchtest du diesen leeren Ordner wirklich löschen?
                  </Trans>
                </p>
                <div className="modal-actions" style={{ marginTop: '20px' }}>
                  <StyledButton variant="danger" onClick={handleSimpleDeleteFolder}>
                    <Trans id="favoritesManager.confirm">Löschen</Trans>
                  </StyledButton>
                  <StyledButton variant="cancel" onClick={closeDeleteFolderModal}>
                    <Trans id="favoritesManager.cancel">Abbrechen</Trans>
                  </StyledButton>
                </div>
              </>
            ) : (
              <>
                <h3>
                  <Trans id="favoritesManager.deleteFolderModalTitle">Ordner löschen</Trans>
                </h3>
                <p>
                  <Trans id="favoritesManager.deleteFolderModalDesc">
                    Wähle bitte eine Option:
                  </Trans>
                </p>
                <div className="folder-list">
                  <label className="folder-item" style={{ color: 'var(--danger-text)' }}>
                    <input
                      type="radio"
                      name="folderDeleteAction"
                      value="deleteFolderWithFavs"
                      checked={selectedFolderDeleteAction === 'deleteFolderWithFavs'}
                      onChange={() => setSelectedFolderDeleteAction('deleteFolderWithFavs')}
                    />
                    <Trans id="favoritesManager.deleteFolderWithFavs">
                      Ordner und alle Favoriten löschen
                    </Trans>
                  </label>
                  <label className="folder-item" style={{ color: 'var(--danger-text)' }}>
                    <input
                      type="radio"
                      name="folderDeleteAction"
                      value="deleteFavsOnly"
                      checked={selectedFolderDeleteAction === 'deleteFavsOnly'}
                      onChange={() => setSelectedFolderDeleteAction('deleteFavsOnly')}
                    />
                    <Trans id="favoritesManager.deleteFavsOnly">
                      Nur Favoriten aus diesem Ordner löschen
                    </Trans>
                  </label>
                  <label className="folder-item">
                    <input
                      type="radio"
                      name="folderDeleteAction"
                      value="deleteFolderOnly"
                      checked={selectedFolderDeleteAction === 'deleteFolderOnly'}
                      onChange={() => setSelectedFolderDeleteAction('deleteFolderOnly')}
                    />
                    <Trans id="favoritesManager.deleteFolderOnly">
                      Nur Ordner löschen (Favoriten bleiben erhalten)
                    </Trans>
                  </label>
                </div>
                <div className="modal-actions" style={{ marginTop: '20px' }}>
                  <StyledButton
                    variant="danger"
                    onClick={confirmDeleteFolder}
                    disabled={!selectedFolderDeleteAction}
                  >
                    <Trans id="favoritesManager.confirm">Löschen</Trans>
                  </StyledButton>
                  <StyledButton variant="cancel" onClick={closeDeleteFolderModal}>
                    <Trans id="favoritesManager.cancel">Abbrechen</Trans>
                  </StyledButton>
                </div>
              </>
            )}
          </div>
        </div>
      )}

      {deleteFavModalOpen && (
        <div className="favorites-modal-backdrop">
          <div className="favorites-modal-content">
            <h3>
              <Trans id="favoritesManager.deleteFavModalTitle">Favorit löschen</Trans>
            </h3>
            <p>
              <Trans id="favoritesManager.deleteFavModalDesc">
                Möchtest du diesen Favoriten wirklich löschen?
              </Trans>
            </p>
            <div className="modal-actions" style={{ marginTop: '20px' }}>
              <StyledButton variant="danger" onClick={confirmDeleteFavorite}>
                <Trans id="favoritesManager.confirm">Löschen</Trans>
              </StyledButton>
              <StyledButton variant="cancel" onClick={closeDeleteFavModal}>
                <Trans id="favoritesManager.cancel">Abbrechen</Trans>
              </StyledButton>
            </div>
          </div>
        </div>
      )}

      {moveModalOpen && (
        <div className="favorites-modal-backdrop">
          <div className="favorites-modal-content">
            <h3>
              <Trans id="favoritesManager.moveModalTitle">Favorit verschieben</Trans>
            </h3>
            <p>
              <Trans id="favoritesManager.moveModalDesc">
                Wähle einen Ordner aus, erstelle einen neuen oder entferne den Favoriten aus einem Ordner:
              </Trans>
            </p>
            {creatingFolder ? (
              <form onSubmit={handleCreateFolder}>
                <div>
                  <label>
                    <Trans id="favorit.folderNameLabel">Ordner-Name:</Trans>
                  </label>
                  <input
                    type="text"
                    value={newFolderName}
                    maxLength={38}
                    onChange={(e) => setNewFolderName(e.target.value)}
                  />
                  <p className="character-counter">
                    <span>
                      <Trans id="folder.charactersLeft">Zeichen übrig:</Trans> {38 - newFolderName.length}
                    </span>
                  </p>
                </div>
                <div className="folder-create-actions">
                  <StyledButton variant="confirm" type="submit" disabled={!newFolderName.trim()}>
                    <Trans id="favorit.save">Speichern</Trans>
                  </StyledButton>
                  <StyledButton variant="cancel" type="button" onClick={() => setCreatingFolder(false)}>
                    <Trans id="favorit.cancel">Abbrechen</Trans>
                  </StyledButton>
                </div>
              </form>
            ) : (
              <>
                <div className="folder-list">
                  <label className="folder-item">
                    <input
                      type="radio"
                      name="moveFolder"
                      value=""
                      checked={selectedMoveFolder === null}
                      onChange={() => setSelectedMoveFolder(null)}
                    />
                    <Trans id="favoritesManager.noFolder">Kein Ordner</Trans>
                  </label>
                  {folders.map((f) => (
                    <label key={f.folder_id} className="folder-item">
                      <input
                        type="radio"
                        name="moveFolder"
                        value={f.folder_id}
                        checked={selectedMoveFolder === f.folder_id}
                        onChange={() => setSelectedMoveFolder(f.folder_id)}
                      />
                      {f.folder_name}
                    </label>
                  ))}
                </div>
                <StyledButton variant="primary" onClick={() => setCreatingFolder(true)}>
                  <Trans id="favorit.newFolderBtn">Neuen Ordner erstellen</Trans>
                </StyledButton>
                <div className="modal-actions" style={{ marginTop: '20px' }}>
                  <StyledButton variant="confirm" onClick={handleMoveFavorite}>
                    <Trans id="favoritesManager.moveConfirm">Verschieben</Trans>
                  </StyledButton>
                  <StyledButton variant="cancel" onClick={closeMoveModal}>
                    <Trans id="favoritesManager.cancel">Abbrechen</Trans>
                  </StyledButton>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

/**
 * FavoritesTable:
 * Zeigt eine Tabelle (Spalten: Rank, Name, Preis, 24h %, Marktkap., Volumen, 7d Sparkline, Löschen).
 * Falls keine Favoriten vorhanden, wird ein Leer-Hinweis angezeigt.
 */
function FavoritesTable({ emptyKey, favorites, vsCurrency, findCoinDataFor, onRemoveFavorite, onMove }) {
  if (!favorites || favorites.length === 0) {
    return (
      <p className="empty-hint">
        {t({ id: emptyKey || 'empty.favorites', message: 'Keine Favoriten' })}
      </p>
    );
  }
  return (
    <table className="favorites-coin-table">
      <thead>
        <tr>
          <th>Rank</th>
          <th><Trans id="label.name">Name</Trans></th>
          <th><Trans id="label.price">Preis</Trans></th>
          <th><Trans id="label.24hChange">24h %</Trans></th>
          <th><Trans id="label.marketCap">Marktkap.</Trans></th>
          <th><Trans id="label.24hVolume">Volumen</Trans></th>
          <th><Trans id="label.7dSparkline">7d</Trans></th>
          <th><Trans id="label.aktionen">Aktionen</Trans></th>
        </tr>
      </thead>
      <tbody>
        {favorites.map((fav) => {
          const coin = findCoinDataFor(fav.coin_id) || { loading: true };
          return (
            <FavoriteRowTr
              key={fav.id}
              coin={coin}
              vsCurrency={vsCurrency}
              onRemove={() => onRemoveFavorite(fav)}
              onMove={() => onMove(fav)}
            />
          );
        })}
      </tbody>
    </table>
  );
}

// Hook, um zu prüfen, ob das Gerät mobil ist:
function useIsMobile() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return isMobile;
}

function FavoriteRowTr({ coin, vsCurrency, onRemove, onMove }) {
  const isMobile = useIsMobile();
  const [expanded, setExpanded] = useState(false);

  const handleRowClick = () => {
    if (!coin.loading) {
      window.location.href = `/coin-details?coin=${coin.id}&from=4`;
    }
  };

  const handleToggleExpand = (e) => {
    e.stopPropagation();
    setExpanded((prev) => !prev);
  };

  if (coin.loading) {
    return (
      <tr style={{ cursor: 'pointer' }} onClick={handleRowClick}>
        <td colSpan="8">Loading...</td>
      </tr>
    );
  }

  if (isMobile) {
    return (
      <>
        {expanded && coin.created_at && (
          <tr className="mobile-created-at-row">
            <td colSpan="8">
              <span className="mobile-created-at-label">
                {t({
                  id: 'favorites.comparisonDateLabel',
                  message: 'Vergleichsdaten vom:'
                })}{' '}
                {formatDate(coin.created_at)}
              </span>
            </td>
          </tr>
        )}
        <tr onClick={handleRowClick}>
          <td data-label={t({ id: 'label.rankMobile', message: '🏅 Rank' })}>
            <div className="mobile-favs-cell-content">
              <div className="mobile-favs-current-value">{coin.market_cap_rank || '-'}</div>
              {expanded && coin.market_cap_rank_at_add && (
                <div className="mobile-favs-old-value">{coin.market_cap_rank_at_add || '-'}</div>
              )}
            </div>
          </td>
          <td className="coin-info-cell">
            {coin.image && (
              <img src={coin.image} alt={coin.name} className="coin-logo" />
            )}
            <span className="coin-name-favs">{coin.name}</span>
            <span className="coin-symbol">({coin.symbol?.toUpperCase()})</span>
          </td>
          <td data-label={t({ id: 'label.priceMobile', message: 'Preis' })}>
            <div className="mobile-favs-cell-content">
              <div className="mobile-favs-current-value">
                {formatPrice(coin.current_price, vsCurrency)}
              </div>
              {expanded && coin.price_at_add && (
                <div className="mobile-favs-old-value">
                  {formatPrice(coin.price_at_add, vsCurrency)}
                </div>
              )}
            </div>
          </td>
          <td data-label={t({ id: 'label.24hChangeMobile', message: '24h %' })}>
            {formatChangePercentage(coin.price_change_percentage_24h)}
          </td>
          <td data-label={t({ id: 'label.marketCapMobile', message: 'Marktkap.' })}>
            <div className="mobile-favs-cell-content">
              <div className="mobile-favs-current-value">
                {formatLargeValue(coin.market_cap, vsCurrency)}
              </div>
              {expanded && coin.marketcap_at_add && (
                <div className="mobile-favs-old-value">
                  {formatLargeValue(coin.marketcap_at_add, vsCurrency)}
                </div>
              )}
            </div>
          </td>
          <td data-label={t({ id: 'label.24hVolumeMobile', message: 'Volumen' })}>
            <div className="mobile-favs-cell-content">
              <div className="mobile-favs-current-value">
                {formatLargeValue(coin.total_volume, vsCurrency)}
              </div>
              {expanded && coin.volume_24h_at_add && (
                <div className="mobile-favs-old-value">
                  {formatLargeValue(coin.volume_24h_at_add, vsCurrency)}
                </div>
              )}
            </div>
          </td>
          <td data-label={t({ id: 'label.7dSparklineMobile', message: '7d' })}>
            <MiniSparkline data={coin.sparkline_in_7d?.price || []} />
          </td>
          <td
            data-label={t({ id: 'label.aktionen2', message: 'Aktionen:' })}
            className="mobile-delete-cell"
          >
            <StyledButton
              variant="icon"
              onClick={(e) => {
                e.stopPropagation();
                handleToggleExpand(e);
              }}
              title={t({
                id: 'favoritesManager.toggleDetails',
                message: 'Weitere Details anzeigen'
              })}
            >
              {expanded ? <FaChevronUp /> : <FaChevronDown />}
            </StyledButton>
            <StyledButton
              variant="icon"
              onClick={(e) => {
                e.stopPropagation();
                onMove(coin);
              }}
              title={t({ id: 'favoritesManager.move', message: 'Verschieben' })}
            >
              <FaFolderOpen />
            </StyledButton>
            <StyledButton
              variant="icon"
              iconType="delete"
              onClick={(e) => {
                e.stopPropagation();
                onRemove();
              }}
              title={t({ id: 'favoritesManager.delete', message: 'Löschen' })}
            >
              <FaTrash />
            </StyledButton>
          </td>
        </tr>
      </>
    );
  } else {
    return (
      <>
        <tr onClick={handleRowClick}>
          <FavoriteCoinRow coin={coin} vsCurrency={vsCurrency} />
          <td
            className="mobile-delete-cell"
            data-label={t({ id: 'label.aktionen2', message: 'Aktionen:' })}
            onClick={(e) => e.stopPropagation()}
          >
            <StyledButton
              variant="icon"
              onClick={handleToggleExpand}
              title={t({
                id: 'favoritesManager.toggleDetails',
                message: 'Weitere Details anzeigen'
              })}
            >
              {expanded ? <FaChevronUp /> : <FaChevronDown />}
            </StyledButton>
            <StyledButton
              variant="icon"
              onClick={(e) => {
                e.stopPropagation();
                onMove(coin);
              }}
              title={t({ id: 'favoritesManager.move', message: 'Verschieben' })}
            >
              <FaFolderOpen />
            </StyledButton>
            <StyledButton
              variant="icon"
              iconType="delete"
              onClick={(e) => {
                e.stopPropagation();
                onRemove();
              }}
              title={t({ id: 'favoritesManager.delete', message: 'Löschen' })}
            >
              <FaTrash />
            </StyledButton>
          </td>
        </tr>
        {expanded && (
          <tr className="comparison-row">
            <td>{coin.market_cap_rank_at_add || '-'}</td>
            <td>{/* Optionaler Titel */}</td>
            <td>
              <div>
                <span className="comparison-label">Preis:</span>
                <br />
                {coin.price_at_add ? formatPrice(coin.price_at_add, vsCurrency) : 'N/A'}
                {coin.price_at_add && coin.current_price && (
                  <div className="percentage-change">
                    {formatChangePercentage(
                      ((coin.current_price - coin.price_at_add) / coin.price_at_add) * 100
                    )}
                  </div>
                )}
              </div>
            </td>
            <td>{/* Optionaler Inhalt */}</td>
            <td>
              <div>
                <span className="comparison-label">Marktkap:</span>
                <br />
                {coin.marketcap_at_add
                  ? formatLargeValue(coin.marketcap_at_add, vsCurrency)
                  : 'N/A'}
                {coin.marketcap_at_add && coin.market_cap && (
                  <div className="percentage-change">
                    {formatChangePercentage(
                      ((coin.market_cap - coin.marketcap_at_add) / coin.marketcap_at_add) * 100
                    )}
                  </div>
                )}
              </div>
            </td>
            <td>
              <div>
                <span className="comparison-label">Volumen:</span>
                <br />
                {coin.volume_24h_at_add
                  ? formatLargeValue(coin.volume_24h_at_add, vsCurrency)
                  : 'N/A'}
                {coin.volume_24h_at_add && coin.total_volume && (
                  <div className="percentage-change">
                    {formatChangePercentage(
                      ((coin.total_volume - coin.volume_24h_at_add) / coin.volume_24h_at_add) * 100
                    )}
                  </div>
                )}
              </div>
            </td>
            <td>{/* Sparkline */}</td>
            <td>
              <div>
                <span className="comparison-label">Hinzugefügt:</span>
                <br />
                {coin.created_at ? formatDate(coin.created_at) : 'N/A'}
              </div>
            </td>
          </tr>
        )}
      </>
    );
  }
}

export default UserFavoritesManager;
