// src/components/ProtectedRoute.js
import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import LoadingOverlay from './LoadingOverlay'; // Falls du einen Spinner hast

const ProtectedRoute = ({ children, adminOnly = false }) => {
  const { user, isLoggedIn, isAuthLoading, isRefreshing } = useContext(AuthContext);

  // 1) Laden oder während des Refresh → Spinner anzeigen
  if (isAuthLoading || isRefreshing) {
    return <LoadingOverlay />;
  }

  // 2) Nicht eingeloggt → Weiterleitung zur Login-Seite
  if (!isLoggedIn) {
    return <Navigate to="/login" replace />;
  }

  // 3) Falls adminOnly erforderlich ist und der User nicht die entsprechenden Rechte hat, ebenfalls umleiten
  if (adminOnly && (user.role !== 'admin' && user.role !== 'superuser')) {
    return <Navigate to="/login" replace />;
  }

  // 4) Darf durch
  return children;
};

export default ProtectedRoute;
