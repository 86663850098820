// src/hooks/useGlobalData.js
import { useState, useEffect, useRef, useCallback } from 'react';
import { i18n } from '@lingui/core'; // Falls du i18n brauchst
import api from '../services/api';  // Neu: unser zentrales Axios-Objekt

// Eine kleine Konstante für Fear&Greed-Limits
const RANGE_LIMITS = {
  '30d': 30,
  '90d': 90,
  '1y': 365,
  '3y': 1095,
  '5y': 1825,
};

export function useGlobalData() {
  // --------------------------
  // Allgemeine States
  // --------------------------
  const [globalData, setGlobalData] = useState(null);
  const [marketCapChartData, setMarketCapChartData] = useState(null);
  const [globalDefi, setGlobalDefi] = useState(null);
  const [globalStable, setGlobalStable] = useState(null);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Zeiträume / Währungen
  const [vsCurrency, setVsCurrency] = useState('usd');
  const [supportedCurrencies, setSupportedCurrencies] = useState([]);
  const [days, setDays] = useState('30');

  // Lokalisierung
  const userLocale = i18n.locale || 'en';

  // Retry-Refs für globale Daten
  const globalDataRetryRef = useRef(0);
  const marketCapChartRetryRef = useRef(0);
  const maxRetries = 5;

  // --------------------------
  // Fear & Greed: zusätzliche States
  // --------------------------
  const [fearGreedLoading, setFearGreedLoading] = useState(true);
  const [fearGreedError, setFearGreedError] = useState(null);
  const [fearGreedCurrentValue, setFearGreedCurrentValue] = useState(null);
  const [fearGreedChartData, setFearGreedChartData] = useState(null);
  const [fearGreedTimeRange, setFearGreedTimeRange] = useState('30d');

  // --------------------------------------------------
  // 1) Netzwerkfehler behandeln + Retry (stabilisiert)
  // --------------------------------------------------
  const handleNetworkError = useCallback(
    (err, retryRef, retryFn, fnName) => {
      console.error(`${fnName} error:`, err);

      // Wir prüfen weiter auf "Failed to fetch" oder Offline,
      // um unser Retry aufzurufen. Axios-Fehler hat i. d. R. err.message = "Network Error"
      const isNetworkError =
        (err.message && err.message.includes('fetch')) ||
        (err.message && err.message.includes('Network')) ||
        !navigator.onLine;

      if (isNetworkError) {
        if (retryRef.current < maxRetries) {
          retryRef.current++;
          const delay = Math.pow(2, retryRef.current) * 1000;
          console.log(
            `${fnName}: Retry in ${delay / 1000} sec (Attempt ${retryRef.current} of ${maxRetries})`
          );
          setTimeout(() => retryFn(), delay);
        } else {
          console.log(`Max retries reached for ${fnName}, retrying in 5 seconds...`);
          setTimeout(() => retryFn(), 5000);
        }
      } else {
        // Sonstige Fehler => in "error" speichern
        setError(err.message);
      }
    },
    [setError]
  );

  // --------------------------------------------------
  // 2) Fetch-Funktionen: Globale Daten (Axios statt fetch)
  // --------------------------------------------------
  const fetchGlobalData = useCallback(async () => {
    try {
      const { data } = await api.get('/global');
      setGlobalData(data.data);
      globalDataRetryRef.current = 0;
    } catch (err) {
      handleNetworkError(err, globalDataRetryRef, fetchGlobalData, 'fetchGlobalData');
    }
  }, [handleNetworkError]);

  const fetchMarketCapChart = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);

      // GET /market-cap-chart?days=...&vs_currency=...
      const { data } = await api.get('/market-cap-chart', {
        params: { 
          days,
          vs_currency: vsCurrency, // <-- string key, Wert = vsCurrency
        },
      });

      const chartDataRaw = data.market_cap_chart;
      const marketCapPoints = chartDataRaw.market_cap.map(([time, value]) => ({
        x: time,
        y: value,
      }));
      const volumePoints = chartDataRaw.volume.map(([time, value]) => ({
        x: time,
        y: value,
      }));

      setMarketCapChartData({ marketCapPoints, volumePoints });
      setLoading(false);
      marketCapChartRetryRef.current = 0;
    } catch (err) {
      handleNetworkError(err, marketCapChartRetryRef, fetchMarketCapChart, 'fetchMarketCapChart');
      setLoading(false);
    }
  }, [days, vsCurrency, handleNetworkError]);

  const fetchGlobalDefi = useCallback(async () => {
    try {
      const { data } = await api.get('/global-defi');
      setGlobalDefi(data.data);
    } catch (err) {
      console.error('Error fetching global defi:', err);
    }
  }, []);

  const fetchStablecoinData = useCallback(async () => {
    try {
      // GET /coins-markets?vs_currency=usd&category=stablecoins
      const { data } = await api.get('/coins-markets', {
        params: { vs_currency: 'usd', category: 'stablecoins' },
      });
      const aggregated = data.reduce(
        (acc, coin) => ({
          market_cap: acc.market_cap + (coin.market_cap || 0),
          total_volume: acc.total_volume + (coin.total_volume || 0),
        }),
        { market_cap: 0, total_volume: 0 }
      );
      setGlobalStable(aggregated);
    } catch (err) {
      console.error('Error fetching stablecoin data:', err);
    }
  }, []);

  // --------------------------------------------------
  // 3) Fetch-Funktionen: Fear & Greed (Axios)
  // --------------------------------------------------
  const fetchFearGreedCurrent = useCallback(async () => {
    try {
      const { data } = await api.get('/fear-greed');
      if (data?.data?.[0]) {
        setFearGreedCurrentValue(data.data[0]);
      }
    } catch (err) {
      console.error('fetchFearGreedCurrent error:', err);
      setFearGreedError('Fehler beim Laden des aktuellen Fear & Greed Index');
    }
  }, []);

  const fetchFearGreedChart = useCallback(
    async (rangeKey) => {
      try {
        setFearGreedLoading(true);
        const limit = RANGE_LIMITS[rangeKey] || 30;

        // GET /fear-greed/chart?limit=...
        const { data } = await api.get('/fear-greed/chart', { params: { limit } });
        if (data && data.data && Array.isArray(data.data)) {
          const points = data.data.map((entry) => ({
            x: new Date(Number(entry.timestamp) * 1000),
            y: Number(entry.value),
            classification: entry.value_classification,
          }));
          setFearGreedChartData(points);
        }
      } catch (err) {
        console.error('fetchFearGreedChart error:', err);
        setFearGreedError('Fehler beim Laden der Chartdaten');
      } finally {
        setFearGreedLoading(false);
      }
    },
    []
  );

  // --------------------------------------------------
  // 4) useEffects (unter Einbeziehung unserer Callbacks)
  // --------------------------------------------------

  // a) Währung aus localStorage + supportedCurrencies
  useEffect(() => {
    const savedCurrency = localStorage.getItem('vsCurrency') || 'usd';
    setVsCurrency(savedCurrency);

    // GET /supported-currencies
    async function fetchCurrencies() {
      try {
        const { data } = await api.get('/supported-currencies');
        setSupportedCurrencies(data);
      } catch (err) {
        console.error(err);
      }
    }
    fetchCurrencies();
  }, []);

  // b) Global Data => neu, wenn vsCurrency sich ändert
  useEffect(() => {
    fetchGlobalData();
  }, [vsCurrency, fetchGlobalData]);

  // c) Market Cap Chart => neu, wenn days oder vsCurrency sich ändert
  useEffect(() => {
    fetchMarketCapChart();
  }, [days, vsCurrency, fetchMarketCapChart]);

  // d) DeFi + Stable => beim ersten Rendern
  useEffect(() => {
    fetchGlobalDefi();
    fetchStablecoinData();
  }, [fetchGlobalDefi, fetchStablecoinData]);

  // e) Autom. Reload bei Visibility
  useEffect(() => {
    function handleVisibilityChange() {
      if (!document.hidden) {
        fetchGlobalData();
        fetchMarketCapChart();
        fetchGlobalDefi();
        fetchStablecoinData();
        // Fear & Greed
        fetchFearGreedCurrent();
        fetchFearGreedChart(fearGreedTimeRange);
      }
    }
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [
    days,
    vsCurrency,
    fearGreedTimeRange,
    fetchGlobalData,
    fetchMarketCapChart,
    fetchGlobalDefi,
    fetchStablecoinData,
    fetchFearGreedCurrent,
    fetchFearGreedChart,
  ]);

  // f) Fear & Greed: InitialLoad
  useEffect(() => {
    async function initialLoad() {
      setFearGreedLoading(true);
      setFearGreedError(null);
      await fetchFearGreedCurrent();
      await fetchFearGreedChart(fearGreedTimeRange);
      setFearGreedLoading(false);
    }
    initialLoad();
  }, [fearGreedTimeRange, fetchFearGreedChart, fetchFearGreedCurrent]);

  // g) Fear & Greed: Chart neu laden, wenn timeRange sich ändert
  //    (Schon abgedeckt? Ja => c) => wir wollen es explizit => belassen wir es.)
  useEffect(() => {
    fetchFearGreedChart(fearGreedTimeRange);
  }, [fearGreedTimeRange, fetchFearGreedChart]);

  // h) Fear & Greed: Auto-Reload nach time_until_update
  useEffect(() => {
    if (!fearGreedCurrentValue || fearGreedCurrentValue.time_until_update == null) return;

    const delay = Number(fearGreedCurrentValue.time_until_update) * 1000 + 5000;
    const timeout = setTimeout(() => {
      fetchFearGreedCurrent();
      fetchFearGreedChart(fearGreedTimeRange);
    }, Math.max(delay, 5000));

    return () => clearTimeout(timeout);
  }, [fearGreedCurrentValue, fearGreedTimeRange, fetchFearGreedCurrent, fetchFearGreedChart]);

  // --------------------------------------------------
  // 5) Rückgabewerte
  // --------------------------------------------------
  return {
    // Globale Daten
    globalData,
    marketCapChartData,
    globalDefi,
    globalStable,
    loading,
    error,
    vsCurrency,
    setVsCurrency,
    supportedCurrencies,
    days,
    setDays,
    userLocale,

    // Fear & Greed
    fearGreedLoading,
    fearGreedError,
    fearGreedCurrentValue,
    fearGreedChartData,
    fearGreedTimeRange,
    setFearGreedTimeRange,
  };
}
