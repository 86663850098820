// src/components/Login.js

import React, { useState, useContext, useEffect } from 'react'; // Removed useRef
import { useNavigate, Link, useLocation } from 'react-router-dom';
import '../styles/Login.css';
import { AuthContext } from '../context/AuthContext';
import { toast } from 'react-toastify';
import '../styles/ToastOverrides.css';
import { t, Trans } from '@lingui/macro';
import { i18n } from '@lingui/core';

// Import der zentralen Komponenten
import { StyledInput } from '../utils/StyledInput';
import { StyledButton } from '../utils/StyledButton';

import api from '../services/api';

// --- Helper Function für Übersetzungen --- (Assume it's correct like in previous versions)
function translateMessageKey(messageKey) {
    // ... (Übersetzungsfunktion) ...
    switch (messageKey) {
        case 'error.identifierPasswordRequired':
            return t({ id: messageKey, message: 'Benutzername oder E-Mail-Adresse und Passwort dürfen nicht leer sein!' });
        case 'error.userNotFoundOrPasswordInvalid':
            return t({ id: messageKey, message: 'Ungültiger Benutzername oder Passwort.' });
        case 'error.emailNotVerified':
            return t({ id: messageKey, message: 'Bitte bestätige deine E-Mail-Adresse, bevor du dich einloggen kannst.' });
        case 'error.dbQueryFailed':
            return t({ id: messageKey, message: 'Fehler bei der Datenbankabfrage.' });
        case 'success.loginSuccessful':
            return t({ id: messageKey, message: 'Login erfolgreich' });
        case 'error.internalServerError':
            return t({ id: messageKey, message: 'Interner Serverfehler' });
        case 'error.invalidEmail':
            return t({ id: messageKey, message: 'Bitte eine gültige E-Mail-Adresse eingeben.' });
        case 'error.usernameFormat':
            return t({ id: messageKey, message: 'Der Benutzername darf nur Buchstaben, Zahlen, "_", "-", "[" oder "]" enthalten und muss zwischen 3 und 16 Zeichen lang sein.' });
        case 'error.sendingVerificationEmail':
            return t({ id: messageKey, message: 'Fehler beim Senden der Bestätigungs-E-Mail' });
        case 'success.verificationEmailResent':
            return t({ id: messageKey, message: 'Bestätigungs-E-Mail wurde erneut gesendet.' });
        case 'error.LoginTooManyRequests':
            return t({ id: messageKey, message: 'Zu viele Login-Versuche! Bitte warte 15 Minuten und probiere es erneut.' });
        case 'error.accountLocked':
            return t({ id: messageKey, message: 'Dein Account ist aktuell gesperrt!' });
        case 'error.userBanned':
            return t({ id: messageKey, message: 'Dein Account wurde vom Admin gesperrt!' });
        case 'error.captchaNotSolved':
            return t({ id: messageKey, message: 'Bitte das Captcha lösen, um fortzufahren.' });
        case 'error.networkError':
             return t({ id: messageKey, message: 'Netzwerkfehler. Bitte prüfe deine Verbindung.' });
        default:
            console.warn(`Unbekannter MessageKey in translateMessageKey: ${messageKey}`);
            return t({ id: 'error.unknown', message: 'Unbekannter Fehler.' });
    }
}


const Login = () => {
    const { isLoggedIn, login } = useContext(AuthContext);
    const [identifier, setIdentifier] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [rememberMe, setRememberMe] = useState(false);
    const [inputError, setInputError] = useState('');
    const [requiresEmailVerification, setRequiresEmailVerification] = useState(false);
    const [isResendDisabled, setIsResendDisabled] = useState(false);
    const [timer, setTimer] = useState(0);
    // Removed recaptchaWidgetId, recaptchaKey, isRecaptchaScriptLoaded states

    const storedTheme = localStorage.getItem('theme') || 'dark';
    const isDarkMode = storedTheme === 'dark';
    const storedLang = localStorage.getItem('language') || 'de';

    const navigate = useNavigate();
    const location = useLocation();
    const siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
    const recaptchaScriptId = 'recaptcha-api-script'; // Define ID

    // --- useEffect Hooks ---

    // Effekt zum Laden UND ENTFERNEN des Google reCAPTCHA Skripts
    useEffect(() => {
        // Nur ausführen, wenn ein siteKey vorhanden ist
        if (!siteKey) {
            console.log('reCAPTCHA script handling skipped: siteKey is missing.');
            return;
        }

        // Prüfen, ob Skript mit dieser ID bereits existiert
        let existingScript = document.getElementById(recaptchaScriptId);
        if (existingScript) {
            console.log('reCAPTCHA script already exists.');
            // Nichts tun, wenn es schon da ist (verhindert doppeltes Laden bei schnellem Remount)
            // ABER: Wir brauchen die Cleanup-Funktion trotzdem, um es beim Unmount zu entfernen!
        } else {
             console.log('Appending reCAPTCHA script...');
             const script = document.createElement('script');
             script.id = recaptchaScriptId; // ID setzen
             script.src = `https://www.google.com/recaptcha/api.js?hl=${storedLang}`; // Kein render=explicit
             script.async = true;
             script.defer = true;
             script.onerror = () => {
                 console.error('Failed to load reCAPTCHA script!');
             };
             document.body.appendChild(script);
        }

        // *** WICHTIG: Cleanup-Funktion zum Entfernen des Skripts ***
        return () => {
            const scriptToRemove = document.getElementById(recaptchaScriptId);
            if (scriptToRemove) {
                console.log('Removing reCAPTCHA script...');
                try {
                    document.body.removeChild(scriptToRemove);
                    // Optional: Manuell das globale grecaptcha Objekt entfernen? Riskant.
                    // delete window.grecaptcha;
                } catch (e) {
                   console.warn('Error removing reCAPTCHA script:', e);
                }

            } else {
                 console.log('Cleanup: reCAPTCHA script not found for removal.');
            }
             // Reset any lingering reCAPTCHA elements (just in case)
             // This might target divs left over if cleanup failed before
             const recaptchaWidgets = document.querySelectorAll('.g-recaptcha-bubble-arrow, .grecaptcha-badge');
             recaptchaWidgets.forEach(widget => widget.remove());
        };

    // Abhängigkeiten: Nur ausführen, wenn sich die Sprache oder der SiteKey ändert.
    // Wichtig: Nicht von Dingen abhängig machen, die sich ständig ändern.
    }, [storedLang, siteKey]);


    // Andere useEffects (isLoggedIn, location.state, timer)
     useEffect(() => {
        if (isLoggedIn) {
            navigate('/');
        }
    }, [isLoggedIn, navigate]);

    useEffect(() => {
        if (location.state?.successMessage) {
            toast.success(location.state.successMessage, { position: 'center-right' });
            // Clear the state after showing the message
            navigate(location.pathname, { replace: true, state: {} });
        }
    }, [location, navigate]);

     useEffect(() => {
        let interval = null;
        if (isResendDisabled && timer > 0) {
            interval = setInterval(() => {
                setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0));
            }, 1000);
        } else if (isResendDisabled && timer <= 0) {
            setIsResendDisabled(false);
        }
        return () => {
            if (interval) clearInterval(interval);
        };
    }, [isResendDisabled, timer]);


    // --- Input Validierung ---
    const validateInputs = () => {
        // ... Validierungslogik ... (wie vorher)
        const usernameRegex = /^[a-zA-Z0-9_\-\[\]]{3,16}$/;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setRequiresEmailVerification(false);
        setInputError('');

        if (!identifier || !password) {
            const msg = translateMessageKey('error.identifierPasswordRequired');
            setInputError(msg);
            toast.error(msg, { position: 'bottom-right' });
            return false;
        }
        if (identifier.includes('@')) {
            if (!emailRegex.test(identifier)) {
                const msg = translateMessageKey('error.invalidEmail');
                setInputError(msg);
                toast.error(msg, { position: 'bottom-right' });
                return false;
            }
        } else {
            if (!usernameRegex.test(identifier)) {
                const msg = translateMessageKey('error.usernameFormat');
                setInputError(msg);
                toast.error(msg, { position: 'bottom-right' });
                return false;
            }
        }
        return true;
    };


    // --- handleLogin ---
    const handleLogin = async (e) => {
        e.preventDefault();
        if (!validateInputs()) return;

        // *** Zurück zum einfachen Token-Abruf ***
        let recaptchaToken = null;
        if (siteKey && window.grecaptcha) {
             try {
                 recaptchaToken = window.grecaptcha.getResponse();
             } catch (error) {
                  console.warn("Fehler beim Abrufen des reCAPTCHA Tokens (simple):", error);
             }
        }

        // Prüfen, ob Token erforderlich und vorhanden
        if (!recaptchaToken && siteKey) {
            toast.error(translateMessageKey('error.captchaNotSolved'), { position: 'bottom-right' });
            return;
        }

        setRequiresEmailVerification(false);

        try {
            await login({ identifier, password, recaptchaToken, rememberMe });
            toast.success(translateMessageKey('success.loginSuccessful'), { position: 'bottom-right' });

        } catch (err) {
            // ... (Fehlerbehandlung wie vorher) ...
             console.error("Login Component Error Handler:", err);
            let specificMessageKey = 'error.internalServerError';
            let finalMessage = '';

            if (err.response?.data?.messageKey) {
                specificMessageKey = err.response.data.messageKey;
            } else if (err.message?.includes('Network Error')) {
                specificMessageKey = 'error.networkError';
            }

            if (specificMessageKey === 'error.accountLocked') {
                const minutesRemaining = err.response?.data?.minutesRemaining;
                if (minutesRemaining && !isNaN(minutesRemaining)) {
                    finalMessage = t(
                        { id: 'error.accountLockedWithTime', message: `Dein Account ist aktuell gesperrt! Bitte warte {minutesRemaining} Minuten.` },
                        { minutesRemaining }
                    );
                } else {
                    finalMessage = translateMessageKey('error.accountLocked');
                }
            } else {
                finalMessage = translateMessageKey(specificMessageKey);
            }

            if (specificMessageKey === 'error.emailNotVerified') {
                setRequiresEmailVerification(true);
            }

            toast.error(finalMessage, { position: 'bottom-right', autoClose: 7000 });

        } finally {
            // *** Zurück zum einfachen Reset ***
            if (window.grecaptcha && siteKey) { // Check siteKey here too
                try {
                    console.log('Resetting reCAPTCHA widget (simple)...');
                    window.grecaptcha.reset();
                } catch (rcError) {
                    console.warn("Fehler beim Zurücksetzen von reCAPTCHA (simple):", rcError);
                }
            }
        }
    }; // Ende handleLogin


    // --- handleResendVerification ---
    const handleResendVerification = async () => {
        // ... Logik ... (wie vorher)
         try {
            const response = await api.post(
                '/resend-verification-email',
                { identifier },
                { headers: { 'Content-Type': 'application/json', 'Accept-Language': i18n.locale } }
            );
            toast.success(translateMessageKey(response.data.messageKey), { position: 'bottom-right' });
            setIsResendDisabled(true);
            setTimer(60);
        } catch (err) {
            const errorKey = err.response?.data?.messageKey || 'error.internalServerError';
            toast.error(translateMessageKey(errorKey), { position: 'bottom-right' });
        }
    };

    // --- Render JSX ---
    return (
        <div className="login-container">
            <h2><Trans id="title.login">Login</Trans></h2>
            <form onSubmit={handleLogin}>
                {/* Inputs */}
                <StyledInput
                    type="text"
                    placeholder={t({ id: 'placeholder.identifier', message: 'Benutzername oder E-Mail-Adresse'})}
                    value={identifier}
                    onChange={(e) => setIdentifier(e.target.value)}
                    required
                    aria-invalid={!!inputError}
                    aria-describedby={inputError ? "input-error-desc" : undefined}
                />
                 <StyledInput
                    type={showPassword ? 'text' : 'password'}
                    placeholder={t({ id: 'placeholder.password', message: 'Passwort' })}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
                {inputError && <p id="input-error-desc" style={{ color: 'red', fontSize: '0.9em', marginTop: '-5px' }}>{inputError}</p>}
                {/* Checkboxes */}
                 {password.length > 0 && (
                    <StyledInput
                        type="checkbox" id="showPassword" checked={showPassword}
                        onChange={(e) => setShowPassword(e.target.checked)}
                        label={<Trans id="label.showPassword">Passwort anzeigen</Trans>} isCheckbox={true}
                    />
                )}
                <StyledInput
                    type="checkbox" id="rememberMe" checked={rememberMe}
                    onChange={(e) => setRememberMe(e.target.checked)}
                    label={<Trans id="label.rememberMe">Angemeldet bleiben?</Trans>}
                    style={{ marginBottom: '15px' }} isCheckbox={true}
                 />

                {/* *** Zurück zum einfachen reCAPTCHA Div *** */}
                {siteKey && (
                    <div
                        className="g-recaptcha"
                        data-sitekey={siteKey}
                        data-theme={isDarkMode ? 'dark' : 'light'}
                        style={{ margin: '15px auto', width: 'fit-content', transform: 'scale(0.9)', transformOrigin: 'center left' }} // Style ggf. anpassen
                    >
                        {/* Kein "Wird geladen Text" hier */}
                    </div>
                )}

                {/* Buttons */}
                <StyledButton type="submit" variant="login" translationKey="button.login" defaultMessage="Login" />
            </form>
            {/* Google Login */}
            {/* ... (Rest wie vorher) ... */}
            <div className="google-login">
                 <StyledButton type="button" variant="google" onClick={() => { window.location.href = `/api/auth/google?rememberMe=${rememberMe}`; }}
                             style={{ marginTop: '10px' }} translationKey="button.loginWithGoogle" defaultMessage="Mit Google anmelden">
                     <span style={{ verticalAlign: 'middle' }}><Trans id="button.loginWithGoogle">Mit Google anmelden</Trans></span>
                 </StyledButton>
             </div>
             {/* Links */}
             <div className="links">
                 <p><Trans id="message.notRegisteredYet">Noch nicht registriert?</Trans>{' '}<Link to="/register"><Trans id="link.registerHere">Hier anmelden</Trans></Link></p>
                 <p><Link to="/forgot-password"><Trans id="link.forgotPassword">Passwort vergessen?</Trans></Link></p>
             </div>
             {/* Resend Verification */}
             {requiresEmailVerification && (
                 <div className="verification-link">
                     <p style={{color: 'orange', marginBottom: '10px'}}><Trans id="message.emailNotVerified">Deine E-Mail-Adresse ist noch nicht bestätigt.</Trans></p>
                     <StyledButton onClick={handleResendVerification} disabled={isResendDisabled} variant="default">
                         {isResendDisabled
                             ? t({ id: 'button.resendIn', message: `Erneut senden in ${timer}s` }, { timer })
                             : t({ id: 'button.resendVerificationEmail', message: 'Bestätigungs-E-Mail erneut senden' })}
                     </StyledButton>
                 </div>
             )}
        </div>
    );
};

export default Login;