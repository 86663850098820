import { useContext, useEffect } from 'react';
import { BinanceDataContext } from './BinanceDataContext';

export function useBinanceData(symbol) {
  const { data, subscribeToSymbol, unsubscribeFromSymbol } = useContext(BinanceDataContext);
  const lowerSymbol = symbol ? symbol.toLowerCase() + 'usdt' : null;

  useEffect(() => {
    if (symbol) {
      subscribeToSymbol(symbol);
      return () => {
        unsubscribeFromSymbol(symbol);
      };
    }
  }, [symbol, subscribeToSymbol, unsubscribeFromSymbol]);

  // Standard-Objekt für den Fall, dass kein Symbol übergeben wird
  const defaultReturn = {
    binancePrice: null,
    useBinancePrice: false,
    priceDirection: '', // Geändert von priceChangeClass
    highlightKey: null   // Hinzugefügt
  };

  if (!lowerSymbol) {
    // Gib das konsistente Standard-Objekt zurück
    return defaultReturn; // Geändert
  }

  // Gib die Daten aus dem Context zurück ODER das konsistente Standard-Objekt als Fallback
  return data[lowerSymbol] || defaultReturn; // Geändert
}