// src/utils/coinMapping.js
export const coinMapping = {
    bitcoin: 'btcusdt',
    ethereum: 'ethusdt',
    binancecoin: 'bnbusdt',
    cardano: 'adausdt',
    dogecoin: 'dogeusdt',
    ripple: 'xrpusdt',
    // Füge weitere Coins nach Bedarf hinzu
  };
  