// /frontend/src/utils/chartUtils.js

/**
 * Berechnet den Zeitbereich (firstMidnight und lastMidnight) für den Chart.
 */
export const calculateTimeRange = (chartData, days) => {
    const firstDataDate = new Date(chartData.datasets[0].data[0].x);
    const firstMidnight = new Date(
        firstDataDate.getFullYear(),
        firstDataDate.getMonth(),
        firstDataDate.getDate()
    );
    const lastMidnight = new Date(
        firstMidnight.getTime() + (days - 1) * 24 * 60 * 60 * 1000
    );
    return { firstMidnight, lastMidnight };
};

/**
 * Liefert timeOptions und maxTicks auf Basis von selectedDays.
 */
export const getTimeOptionsAndMaxTicks = (selectedDays, chartData) => {
    if (!chartData) {
        return { timeOptions: { unit: 'day' }, maxTicks: undefined };
    }

    /**
     * Hilfsfunktion, um manuell min & max aus den Daten festzulegen.
     */
    const setDayRange = (days) => {
        const allData = chartData.datasets[0].data;
        const firstDate = allData[0].x;
        const lastDate = allData[allData.length - 1].x;
        return {
            unit: 'day',
            min: firstDate,
            max: lastDate,
        };
    };

    let timeOptions = {};
    let maxTicks;

    const selection = String(selectedDays);

    switch (selection) {
        case '1': // 24h
            timeOptions = {};
            break;
        case '7': // 7d
            timeOptions = setDayRange(7);
            maxTicks = 7;
            break;
        case '14': // 14d
            timeOptions = setDayRange(14);
            maxTicks = 14;
            break;
        case '30': // 30d
            timeOptions = setDayRange(30);
            maxTicks = 30;
            break;
        case '60': // 60d
            timeOptions = setDayRange(60);
            maxTicks = 60;
            break;
        case '90': // 90d
            timeOptions = setDayRange(90);
            maxTicks = 90;
            break;
        case '180': // 180d
            timeOptions = setDayRange(180);
            maxTicks = 180;
            break;
        case '200':
            timeOptions = setDayRange(200);
            maxTicks = 200;
            break;
        case '365': // 1y
            timeOptions = setDayRange(365);
            maxTicks = 365;
            break;
        case 'max':
            timeOptions = { unit: 'month' };
            maxTicks = undefined;
            break;
        default:
            // Falls ein numerischer Wert (z.B. "16") übergeben wird, 
            // wird dieser dynamisch in einen Standard-Bucket gemappt:
            const numericDays = parseInt(selection, 10);
            if (!isNaN(numericDays)) {
                // Definiere Standard-Buckets (ohne 1, da das 24h repräsentiert)
                const standardBuckets = [7, 14, 30, 60, 90, 180, 200, 365];
                // Finde den ersten Bucket, der >= numericDays ist
                let bucket = standardBuckets.find(opt => numericDays <= opt);
                if (!bucket) {
                    // Falls numericDays größer als alle Standardwerte ist, nutze numericDays
                    bucket = numericDays;
                }
                timeOptions = setDayRange(bucket);
                maxTicks = bucket;
            } else {
                // Fallback: Zeiteinheit 'day'
                timeOptions = { unit: 'day' };
            }
            break;
    }

    return { timeOptions, maxTicks };
};


/**
 * Tooltip Title Callback
 */
export const tooltipTitleCallback = (tooltipItems, userLocale) => {
    const item = tooltipItems[0];
    const date = new Date(item.parsed.x);
    const dateStr = date.toLocaleDateString(userLocale, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    });
    const timeStr = date.toLocaleTimeString(userLocale, {
        hour: '2-digit',
        minute: '2-digit',
    });

    return userLocale.startsWith('de')
        ? `${dateStr} - ${timeStr} Uhr`
        : `${dateStr} - ${timeStr}`;
};

// ======================================================
// =    Format-Funktionen für verschiedene Zeiträume    =
// ======================================================

const formatShortDateWithYearOnEdgesAndYearChange = (date, index, ticks, userLocale) => {
    if (index === 0) {
        return date.toLocaleDateString(userLocale, {
            day: '2-digit',
            month: '2-digit',
            year: '2-digit'
        });
    }
    const prevDate = new Date(ticks[index - 1].value);
    if (date.getFullYear() !== prevDate.getFullYear()) {
        return date.toLocaleDateString(userLocale, {
            day: '2-digit',
            month: '2-digit',
            year: '2-digit'
        });
    }
    return date.toLocaleDateString(userLocale, {
        day: '2-digit',
        month: '2-digit'
    });
};

// Bei "max": Immer dd.MM.yy
const formatMaxTick = (date, userLocale) => {
    return date.toLocaleDateString(userLocale, {
        day: '2-digit',
        month: '2-digit',
        year: '2-digit'
    });
};

// 7, 90, 180 etc.
const formatDateOrWeekday = (date, index, ticks, userLocale, firstDate) => {
    const daysDiff = Math.round(
        (date.getTime() - firstDate.getTime()) / (24 * 60 * 60 * 1000)
    );
    const isFirst = index === 0;
    const isLast = index === ticks.length - 1;

    if (isFirst || isLast || daysDiff % 7 === 0) {
        let showDate = true;
        if (index > 0) {
            const prevTickValue = ticks[index - 1].value;
            const prevDate = new Date(prevTickValue);
            const prevDaysDiff = Math.round(
                (prevDate.getTime() - firstDate.getTime()) / (24 * 60 * 60 * 1000)
            );
            const prevIsDate = (index - 1 === 0) || (prevDaysDiff % 7 === 0);
            if (prevIsDate) {
                showDate = false;
            }
        }
        if (showDate) {
            return date.toLocaleDateString(userLocale, {
                month: '2-digit',
                day: '2-digit'
            });
        } else {
            return date.toLocaleDateString(userLocale, { weekday: 'short' });
        }
    } else {
        return date.toLocaleDateString(userLocale, { weekday: 'short' });
    }
};

// 1 Tag (24h)
const formatOneDayTick = (date, userLocale) => {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    if (hours === 0 && minutes === 0) {
        return date.toLocaleDateString(userLocale, {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        });
    }
    const timeStr = date.toLocaleTimeString(userLocale, {
        hour: '2-digit',
        minute: '2-digit'
    });
    return userLocale.startsWith('de') ? `${timeStr} Uhr` : timeStr;
};

// 7 Tage (wie 30 Tage)
const formatOneWeekTick = (date, index, ticks, userLocale) => {
    const firstTickValue = ticks[0].value;
    const firstDate = new Date(firstTickValue);
    return formatDateOrWeekday(date, index, ticks, userLocale, firstDate);
};

// 90 Tage (wie 30 Tage)
const formatNinetyDaysTick = (date, index, ticks, userLocale) => {
    const firstTickValue = ticks[0].value;
    const firstDate = new Date(firstTickValue);
    return formatDateOrWeekday(date, index, ticks, userLocale, firstDate);
};

// 180 Tage: (wie 200)
const format180DaysTick = (date, index, userLocale) => {
    if (index % 3 === 0) {
        return date.toLocaleDateString(userLocale, {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit'
        });
    }
    return '';
};

/**
 * Hauptfunktion X-Achse
 */
export const xTickCallback = (value, index, ticks, selectedDays, userLocale) => {
    const date = new Date(value);
    const selection = String(selectedDays);

    switch (selection) {
        case '1': // 24h
            return formatOneDayTick(date, userLocale);
        case '7': // 7d
            return formatOneWeekTick(date, index, ticks, userLocale);
        case '14':
        case '30':
        case '60':
        case '200':
            return formatShortDateWithYearOnEdgesAndYearChange(
                date,
                index,
                ticks,
                userLocale
            );
        case '365': // 1y: Hier wird immer die vollständige Datumsanzeige inklusive Jahr genutzt.
            return date.toLocaleDateString(userLocale, { day: 'numeric', month: 'short', year: '2-digit' });

        case 'max':
            return formatMaxTick(date, userLocale);
        case '90':
            return formatNinetyDaysTick(date, index, ticks, userLocale);
        case '180':
            return format180DaysTick(date, index, userLocale);
        default:
            const numericDays = parseInt(selection, 10);
            if (!isNaN(numericDays)) {
                return formatShortDateWithYearOnEdgesAndYearChange(
                    date,
                    index,
                    ticks,
                    userLocale
                );
            } else {
                const dateStr = date.toLocaleDateString(userLocale, {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit'
                });
                const timeStr = date.toLocaleTimeString(userLocale, {
                    hour: '2-digit',
                    minute: '2-digit'
                });
                return userLocale.startsWith('de')
                    ? `${dateStr} - ${timeStr} Uhr`
                    : `${dateStr} - ${timeStr}`;
            }
    }
};

/**
 * Plugin, um Jahreswechsel als "major" zu markieren, 
 * **aber nur dann**, wenn es tatsächlich einen Datenpunkt an diesem Datum gibt.
 */
export const yearBoundaryPlugin = {
    id: 'yearBoundaryPlugin',
    afterBuildTicks(chart) {
        const xScale = chart.scales.x;
        if (!xScale || !xScale.ticks) return;

        const ticks = xScale.ticks;
        if (ticks.length < 2) return;

        const selectedDays = chart.options?.plugins?.yearBoundaryPlugin?.selectedDays;

        if (selectedDays === '1' || selectedDays === '7') {
            return;
        }

        const mainDataset = chart.data.datasets[0];
        if (!mainDataset || !mainDataset.data) return;

        const dataDatesSet = new Set();
        mainDataset.data.forEach((dp) => {
            const d = new Date(dp.x);
            const key = `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, '0')}-${String(d.getDate()).padStart(2, '0')}`;
            dataDatesSet.add(key);
        });

        for (let i = 1; i < ticks.length; i++) {
            const currDate = new Date(ticks[i].value);
            const prevDate = new Date(ticks[i - 1].value);
            if (currDate.getFullYear() !== prevDate.getFullYear()) {
                const keyCurr = `${currDate.getFullYear()}-${String(currDate.getMonth() + 1).padStart(2, '0')}-${String(currDate.getDate()).padStart(2, '0')}`;
                if (dataDatesSet.has(keyCurr)) {
                    ticks[i].major = true;
                }
            }
        }
    },
};
