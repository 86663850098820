// src/components/admin/AdminStats.js

import React, { useState, useEffect } from 'react';
import { Trans, t } from '@lingui/macro';
import { toast } from 'react-toastify';
import AdminSubmenu from './AdminSubmenu';
import api from '../../services/api'; // <-- Unsere zentrale Axios-Instanz
import '../../styles/AdminStats.css';

// Chart.js & Date-Adapter
import {
  Chart as ChartJS,
  registerables
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import { de as localeDe, enUS as localeEn } from 'date-fns/locale';
import { Line, Bar, Pie } from 'react-chartjs-2';

// Registrieren aller Chart.js-Komponenten
ChartJS.register(...registerables);

/**
 * Fehlerbehandlung bei Backend-Antworten
 * Wir werten err.response aus (Axios-Fehler).
 */
function handleBackendError(err) {
  if (err.response) {
    const { status, data } = err.response;
    if (status === 403) {
      toast.error(t({ id: 'error.noPermission', message: 'Keine Berechtigung!' }));
    } else {
      // ggf. data.messageKey oder data.error ausgeben
      const errorMsg = data?.messageKey || data?.error || 'Fehler beim Laden der Statistiken.';
      toast.error(errorMsg);
    }
  } else {
    // Netzwerkfehler / Timeout / etc.
    toast.error('Fehler beim Laden der Statistiken (Netzwerk/Serverfehler).');
  }
}

/**
 * Farbe für Role im Pie Chart
 */
function getColorForRole(role) {
  switch (role) {
    case 'superuser':
      return '#ff6666';
    case 'admin':
      return '#ffaa00';
    case 'user':
      return '#66cc66';
    default:
      return '#8884d8'; // fallback
  }
}

const AdminStats = () => {
  // -------------------------------------------------------
  // Lokalisierung für Chart.js
  // -------------------------------------------------------
  const storedLang = localStorage.getItem('language') || 'de';
  const chartLocale = storedLang === 'de' ? localeDe : localeEn;

  // Verschiedene Stats-Stores
  const [registrationsLast30, setRegistrationsLast30] = useState([]);
  const [verificationData, setVerificationData] = useState(null);
  const [dauData, setDauData] = useState([]);
  const [mauData, setMauData] = useState([]);
  const [loginLast30, setLoginLast30] = useState([]);
  const [bannedStats, setBannedStats] = useState(null);
  const [rolesData, setRolesData] = useState([]);
  const [countriesData, setCountriesData] = useState([]);
  const [socialLoginLast30, setSocialLoginLast30] = useState([]);
  const [socialLoginReasons, setSocialLoginReasons] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchAllStats();
  }, []);

  /**
   * Lädt alle Statistiken nacheinander
   */
  const fetchAllStats = async () => {
    setIsLoading(true);
    try {
      // 1) Registrations
      let resp = await api.get('/admin/stats/registrations-last30');
      setRegistrationsLast30(resp.data?.data || []);

      // 2) Verification
      resp = await api.get('/admin/stats/verification-status');
      setVerificationData(resp.data);

      // 3) DAU
      resp = await api.get('/admin/stats/dau');
      setDauData(resp.data?.data || []);

      // 4) MAU
      resp = await api.get('/admin/stats/mau');
      setMauData(resp.data?.data || []);

      // 5) Logins (30d)
      resp = await api.get('/admin/stats/login-last30');
      setLoginLast30(resp.data?.data || []);

      // 6) Banned
      resp = await api.get('/admin/stats/banned');
      setBannedStats(resp.data);

      // 7) Roles
      resp = await api.get('/admin/stats/roles');
      setRolesData(resp.data || []);

      // 8) Countries
      resp = await api.get('/admin/stats/countries');
      setCountriesData(resp.data || []);

      // 9) Social-Login (30d)
      resp = await api.get('/admin/stats/social-login-last30');
      setSocialLoginLast30(resp.data?.data || []);

      // 10) Social-Login Fehlgründe (30d)
      resp = await api.get('/admin/stats/social-login-reasons');
      setSocialLoginReasons(resp.data || []);

    } catch (err) {
      console.error('Fehler beim Laden der Admin-Statistiken:', err);
      handleBackendError(err);
    } finally {
      setIsLoading(false);
    }
  };

  // ---------------------------------------------------------------------------------------
  // CHART-DATEN & -OPTIONEN
  // ---------------------------------------------------------------------------------------

  // 1) Registrierungen => Zeitachse
  const registrationsData = {
    datasets: [
      {
        label: t({ id: 'admin.stats.registrationsLabel', message: 'Registrierungen' }),
        data: registrationsLast30.map((item) => ({
          x: item.day.includes('T') ? item.day : `${item.day}T00:00:00`,
          y: item.registrations
        })),
        borderColor: '#8884d8',
        backgroundColor: '#8884d8'
      }
    ]
  };

  const registrationsOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { position: 'top' },
      datalabels: { display: false }
    },
    scales: {
      x: {
        type: 'time',
        adapters: {
          date: {
            locale: chartLocale
          }
        },
        time: {
          unit: 'day',
          displayFormats: {
            day: 'P'
          }
        },
        ticks: {
          autoSkip: false,
          maxRotation: 90,
          minRotation: 45
        }
      },
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1,
          precision: 0
        }
      }
    }
  };

  // 2) DAU => Zeitachse
  const dauChartData = {
    datasets: [
      {
        label: t({ id: 'admin.stats.dauLabel', message: 'Daily Active Users' }),
        data: dauData.map((d) => ({
          x: d.day.includes('T') ? d.day : `${d.day}T00:00:00`,
          y: d.dau
        })),
        borderColor: '#82ca9d',
        backgroundColor: '#82ca9d'
      }
    ]
  };

  const dauOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { position: 'top' },
      datalabels: { display: false }
    },
    scales: {
      x: {
        type: 'time',
        adapters: {
          date: {
            locale: chartLocale
          }
        },
        time: {
          unit: 'day',
          displayFormats: {
            day: 'P'
          }
        },
        ticks: { autoSkip: false }
      },
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1,
          precision: 0
        }
      }
    }
  };

  // 3) MAU => Zeitachse (Monat)
  const mauChartData = {
    datasets: [
      {
        label: t({ id: 'admin.stats.mauLabel', message: 'Monthly Active Users' }),
        data: mauData.map((m) => {
          const mm = String(m.month).padStart(2, '0');
          return { x: `${m.year}-${mm}-01T00:00:00`, y: m.mau };
        }),
        borderColor: '#FF9900',
        backgroundColor: '#FF9900'
      }
    ]
  };

  const mauOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { position: 'top' },
      datalabels: { display: false }
    },
    scales: {
      x: {
        type: 'time',
        adapters: {
          date: {
            locale: chartLocale
          }
        },
        time: {
          unit: 'month',
          displayFormats: {
            month: 'P'
          }
        },
        ticks: {
          autoSkip: false,
          maxRotation: 90,
          minRotation: 45
        }
      },
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1,
          precision: 0
        }
      }
    }
  };

  // 4) Logins => Bar-Chart (Zeitachse)
  const loginBarData = {
    datasets: [
      {
        label: t({ id: 'admin.stats.successfulLogins', message: 'Erfolgreich' }),
        data: loginLast30.map((d) => ({
          x: d.day.includes('T') ? d.day : `${d.day}T00:00:00`,
          y: d.successfulLogins
        })),
        backgroundColor: '#4caf50'
      },
      {
        label: t({ id: 'admin.stats.failedLogins', message: 'Fehlgeschlagen' }),
        data: loginLast30.map((d) => ({
          x: d.day.includes('T') ? d.day : `${d.day}T00:00:00`,
          y: d.failedLogins
        })),
        backgroundColor: '#f44336'
      }
    ]
  };

  const loginBarOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { position: 'top' },
      datalabels: { display: false }
    },
    scales: {
      x: {
        type: 'time',
        adapters: {
          date: {
            locale: chartLocale
          }
        },
        time: {
          unit: 'day',
          displayFormats: { day: 'P' }
        },
        stacked: true,
        ticks: { autoSkip: false }
      },
      y: {
        beginAtZero: true,
        stacked: true,
        ticks: {
          stepSize: 1,
          precision: 0
        }
      }
    }
  };

  // 5) Rollen (Pie Chart)
  const rolesPieData = {
    labels: rolesData.map((r) => r.role),
    datasets: [
      {
        data: rolesData.map((r) => r.count),
        backgroundColor: rolesData.map((r) => getColorForRole(r.role))
      }
    ]
  };
  const pieOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { position: 'top' },
      datalabels: { display: true }
    }
  };

  // 6) Social-Login (30d) => success/fail
  const socialLoginBarData = {
    datasets: [
      {
        label: t({ id: 'admin.stats.social.successfulLogins', message: 'Erfolgreich (Social)' }),
        data: socialLoginLast30.map(d => ({
          x: d.day.includes('T') ? d.day : `${d.day}T00:00:00`,
          y: d.successfulSocialLogins
        })),
        backgroundColor: '#0066cc'
      },
      {
        label: t({ id: 'admin.stats.social.failedLogins', message: 'Fehlgeschlagen (Social)' }),
        data: socialLoginLast30.map(d => ({
          x: d.day.includes('T') ? d.day : `${d.day}T00:00:00`,
          y: d.failedSocialLogins
        })),
        backgroundColor: '#ff3333'
      }
    ]
  };
  const socialLoginBarOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { position: 'top' },
      datalabels: { display: false }
    },
    scales: {
      x: {
        type: 'time',
        adapters: {
          date: {
            locale: chartLocale
          }
        },
        time: {
          unit: 'day',
          displayFormats: { day: 'P' }
        },
        stacked: true
      },
      y: {
        beginAtZero: true,
        stacked: true,
        ticks: {
          stepSize: 1,
          precision: 0
        }
      }
    }
  };

  // 7) Social-Login Fehlgründe (Pie)
  const socialLoginReasonsPieData = {
    labels: socialLoginReasons.map(r => r.reason || 'Unknown'),
    datasets: [
      {
        data: socialLoginReasons.map(r => r.count),
        backgroundColor: [
          '#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6',
          '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D'
        ]
      }
    ]
  };
  const socialLoginReasonsPieOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { position: 'top' },
      datalabels: { display: true }
    }
  };

  return (
    <div className="admin-stats-container">
      <AdminSubmenu />

      <h2 className="stats-main-title">
        <Trans id="admin.stats.title">Admin Statistiken</Trans>
      </h2>

      {isLoading && (
        <p>
          <Trans id="admin.stats.loading">Lade Daten...</Trans>
        </p>
      )}

      {!isLoading && (
        <div className="stats-sections-grid">
          {/* 1) Registrierungen (Line) */}
          <section className="stats-section">
            <h3>
              <Trans id="admin.stats.registrationsLast30">
                Registrierungen (30 Tage)
              </Trans>
            </h3>
            {registrationsLast30.length === 0 ? (
              <p><Trans id="admin.stats.noData">Keine Daten</Trans></p>
            ) : (
              <div className="chart-wrapper">
                <Line data={registrationsData} options={registrationsOptions} />
              </div>
            )}
          </section>

          {/* 2) Verifizierungsstatus */}
          <section className="stats-section">
            <h3><Trans id="admin.stats.verificationStatus">Verifizierungsstatus</Trans></h3>
            {verificationData && (
              <ul>
                <li>
                  <Trans id="admin.stats.verified">Verifiziert</Trans>:{' '}
                  {verificationData.verifiedCount}
                </li>
                <li>
                  <Trans id="admin.stats.notVerified">Nicht verifiziert</Trans>:{' '}
                  {verificationData.notVerifiedCount}
                </li>
                <li>
                  <Trans id="admin.stats.recentlyVerified">
                    In den letzten 30 Tagen registriert & verifiziert
                  </Trans>: {verificationData.recentVerified}
                </li>
              </ul>
            )}
          </section>

          {/* 3) DAU (Line) */}
          <section className="stats-section">
            <h3><Trans id="admin.stats.dau">Daily Active Users (DAU)</Trans></h3>
            {dauData.length === 0 ? (
              <p><Trans id="admin.stats.noData">Keine Daten</Trans></p>
            ) : (
              <div className="chart-wrapper">
                <Line data={dauChartData} options={dauOptions} />
              </div>
            )}
          </section>

          {/* 4) MAU (Line) */}
          <section className="stats-section">
            <h3><Trans id="admin.stats.mau">Monthly Active Users (MAU)</Trans></h3>
            {mauData.length === 0 ? (
              <p><Trans id="admin.stats.noData">Keine Daten</Trans></p>
            ) : (
              <div className="chart-wrapper">
                <Line data={mauChartData} options={mauOptions} />
              </div>
            )}
          </section>

          {/* 5) Logins (Stacked Bar) */}
          <section className="stats-section">
            <h3><Trans id="admin.stats.loginsLast30">Logins (30 Tage)</Trans></h3>
            {loginLast30.length === 0 ? (
              <p><Trans id="admin.stats.noData">Keine Daten</Trans></p>
            ) : (
              <div className="chart-wrapper">
                <Bar data={loginBarData} options={loginBarOptions} />
              </div>
            )}
          </section>

          {/* 6) Banned vs Unbanned */}
          <section className="stats-section">
            <h3><Trans id="admin.stats.bannedunbanned">Banned vs. Unbanned</Trans></h3>
            {bannedStats && (
              <ul>
                <li>
                  <Trans id="admin.stats.banned">Banned</Trans>: {bannedStats.bannedCount}
                </li>
                <li>
                  <Trans id="admin.stats.unbanned">Unbanned</Trans>: {bannedStats.unbannedCount}
                </li>
              </ul>
            )}
          </section>

          {/* 7) Rollen-Übersicht (Pie) */}
          <section className="stats-section">
            <h3><Trans id="admin.stats.roles">Rollen-Übersicht</Trans></h3>
            {rolesData.length === 0 ? (
              <p><Trans id="admin.stats.noData">Keine Daten</Trans></p>
            ) : (
              <div className="chart-wrapper">
                <Pie data={rolesPieData} options={pieOptions} />
              </div>
            )}
          </section>

          {/* 8) Länder-Statistik */}
          <section className="stats-section">
            <h3><Trans id="admin.stats.countries">Länder-Statistik</Trans></h3>
            {countriesData.length === 0 ? (
              <p><Trans id="admin.stats.noData">Keine Daten</Trans></p>
            ) : (
              <ul>
                {countriesData.map((item) => (
                  <li key={item.country}>
                    {item.country || 'Unbekannt'}: {item.count}
                  </li>
                ))}
              </ul>
            )}
          </section>

          {/* 9) Social-Logins (30 Tage) => Bar */}
          <section className="stats-section">
            <h3>{t({ id: 'admin.stats.socialLoginLast30', message: 'Social Logins (30 Tage)' })}</h3>
            {socialLoginLast30.length === 0 ? (
              <p>{t({ id: 'admin.stats.noData', message: 'Keine Daten' })}</p>
            ) : (
              <div className="chart-wrapper">
                <Bar data={socialLoginBarData} options={socialLoginBarOptions} />
              </div>
            )}
          </section>

          {/* 10) Social-Login Fehlgründe => Pie */}
          <section className="stats-section">
            <h3>{t({ id: 'admin.stats.socialReasons30', message: 'Social-Login Fehlgründe (30 Tage)' })}</h3>
            {socialLoginReasons.length === 0 ? (
              <p>{t({ id: 'admin.stats.noData', message: 'Keine Daten' })}</p>
            ) : (
              <div className="chart-wrapper">
                <Pie data={socialLoginReasonsPieData} options={socialLoginReasonsPieOptions} />
              </div>
            )}
          </section>
        </div>
      )}
    </div>
  );
};

export default AdminStats;
