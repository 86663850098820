import React, { useEffect, useRef, useState } from 'react';
import { formatPrice } from '../utils/formatPrice';
import { formatLargeValue } from '../utils/formatLargeValue';
import { formatChangePercentage } from '../utils/formatChangePercentage';
import { t } from '@lingui/macro';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import FavoriteStar from './CoinDetails/FavoriteStar';

/**
 * MiniSparkline:
 * Zeichnet einen 7-Tage-Sparkline-Chart, der am ersten Datenpunkt
 * (data[0]) eine "Baseline" festlegt. Oberhalb: Grün, unterhalb: Rot.
 * In der Mitte eine graue Linie als visuelle 0%-Marke.
 */
const MiniSparkline = ({ data }) => {
  if (!data || data.length === 0) {
    return <div>N/A</div>;
  }

  const width = 115;
  const height = 22;

  // Baseline = erster Datenpunkt => 0%
  const baselineValue = data[0];

  // Max/Min
  const maxPrice = Math.max(...data);
  const minPrice = Math.min(...data);

  // Alle Werte identisch => flat
  const isFlat = maxPrice === minPrice;
  const allAbove = minPrice >= baselineValue;
  const allBelow = maxPrice <= baselineValue;

  // (A) Punkte berechnen
  const toPoint = (price, index, total) => {
    const x = (index / (total - 1)) * width;
    const y = isFlat
      ? height / 2
      : height - ((price - minPrice) / (maxPrice - minPrice)) * height;
    return { x, y, price };
  };
  const points = data.map((p, i) => toPoint(p, i, data.length));

  // (B) Baseline
  const baselineY = isFlat
    ? height / 2
    : height - ((baselineValue - minPrice) / (maxPrice - minPrice)) * height;

  // (C) Intersection-Helfer
  const getIntersection = (p1, p2, baseVal) => {
    const diff = p2.price - p1.price;
    const t = (baseVal - p1.price) / diff;
    return {
      x: p1.x + t * (p2.x - p1.x),
      y: p1.y + t * (p2.y - p1.y),
    };
  };

  // (D) Segmente (rot/grün)
  const segments = [];
  for (let i = 0; i < points.length - 1; i++) {
    const p1 = points[i];
    const p2 = points[i + 1];
    const p1Above = p1.price >= baselineValue;
    const p2Above = p2.price >= baselineValue;

    if (p1Above === p2Above) {
      segments.push({
        points: [p1, p2],
        color: p1Above ? 'var(--price-up-color)' : 'var(--price-down-color)',
      });
    } else {
      const inter = getIntersection(p1, p2, baselineValue);
      segments.push({
        points: [p1, inter],
        color: p1Above ? 'var(--price-up-color)' : 'var(--price-down-color)',
      });
      segments.push({
        points: [inter, p2],
        color: p2Above ? 'var(--price-up-color)' : 'var(--price-down-color)',
      });
    }
  }

  const toPolylineString = (arr) =>
    arr.map((p) => `${p.x},${p.y}`).join(' ');

  // (E) Min/Max Index
  let minIndex = 0;
  data.forEach((val, i) => {
    if (val < data[minIndex]) minIndex = i;
  });
  const minPoint = points[minIndex];
  const minPerc = isFlat
    ? 0
    : ((minPoint.price - baselineValue) / baselineValue) * 100;

  let maxIndex = 0;
  data.forEach((val, i) => {
    if (val > data[maxIndex]) maxIndex = i;
  });
  const maxPoint = points[maxIndex];
  const maxPerc = isFlat
    ? 0
    : ((maxPoint.price - baselineValue) / baselineValue) * 100;

  // Format Prozent
  const formatPerc = (val) => {
    const sign = val >= 0 ? '+' : '';
    return `${sign}${val.toFixed(1)}%`;
  };

  // Label-Farben
  const offset = 3;
  const minColor = minPerc >= 0 ? 'var(--price-up-color)' : 'var(--price-down-color)';
  const maxColor = maxPerc >= 0 ? 'var(--price-up-color)' : 'var(--price-down-color)';

  // Wann min-/max-Label?
  const showMinLabel = !isFlat && !allAbove && !allBelow;
  const showMaxLabel = !isFlat && !allBelow && !allAbove;

  // (F) X-Clamp-Logik
  //   -> Falls x < 5 => textAnchor="start", x=5
  //   -> Falls x > width-5 => textAnchor="end", x=width-5
  //   -> sonst textAnchor="middle"
  function clampLabelX(x) {
    if (x < 7) {
      return { x: 7, anchor: 'start' };
    } else if (x > width - 7) {
      return { x: width - 7, anchor: 'end' };
    } else {
      return { x, anchor: 'middle' };
    }
  }

// (G) Render
return (
  <svg
    width={width}
    height={height}
    style={{ background: 'transparent', overflow: 'visible' }}
  >
    {/* Baseline */}
    <line
      x1={0}
      y1={baselineY}
      x2={width}
      y2={baselineY}
      stroke="#888"
      strokeWidth={1}
      strokeDasharray="2,2"
    />

    {/* Gefüllte Bereiche */}
    {segments.map((seg, idx) => (
      <polygon
        key={`fill-${idx}`}
        points={`${seg.points[0].x},${seg.points[0].y} ${seg.points[1].x},${seg.points[1].y} ${seg.points[1].x},${baselineY} ${seg.points[0].x},${baselineY}`}
        fill={seg.color}
        fillOpacity={0.05} // Leicht transparent
        stroke="none"
      />
    ))}

    {/* Segmente (Linien) */}
    {segments.map((seg, idx) => (
      <polyline
        key={idx}
        fill="none"
        stroke={seg.color}
        strokeWidth={1}
        points={toPolylineString(seg.points)}
      />
    ))}

    {/* ALL ABOVE => nur maxLabel */}
    {allAbove && !isFlat && (() => {
      const { x, anchor } = clampLabelX(maxPoint.x);
      return (
        <text
          x={x}
          y={maxPoint.y - offset}
          fontSize="8"
          textAnchor={anchor}
          fill={maxColor}
        >
          {formatPerc(maxPerc)}
        </text>
      );
    })()}

    {/* ALL BELOW => nur minLabel */}
    {allBelow && !isFlat && (() => {
      const { x, anchor } = clampLabelX(minPoint.x);
      return (
        <text
          x={x}
          y={minPoint.y + offset + 6}
          fontSize="8"
          textAnchor={anchor}
          fill={minColor}
        >
          {formatPerc(minPerc)}
        </text>
      );
    })()}

    {/* Sonst both */}
    {showMinLabel && (() => {
      const { x, anchor } = clampLabelX(minPoint.x);
      return (
        <text
          x={x}
          y={minPoint.y + offset + 6}
          fontSize="8"
          textAnchor={anchor}
          fill={minColor}
        >
          {formatPerc(minPerc)}
        </text>
      );
    })()}

    {showMaxLabel && (() => {
      const { x, anchor } = clampLabelX(maxPoint.x);
      return (
        <text
          x={x}
          y={maxPoint.y - offset}
          fontSize="8"
          textAnchor={anchor}
          fill={maxColor}
        >
          {formatPerc(maxPerc)}
        </text>
      );
    })()}
  </svg>
);
};

const CoinRow = React.memo(({ coin, onClick, vsCurrency, loading }) => {
  const prevCoinRef = useRef(coin);
  const prevCurrencyRef = useRef(vsCurrency);

  // Highlight-States für Preis, MarketCap und Volume
  const [highlightPrice, setHighlightPrice] = useState(null);
  const [highlightMarketCap, setHighlightMarketCap] = useState(null);
  const [highlightVolume, setHighlightVolume] = useState(null);

  useEffect(() => {
    if (loading) return; // Bei Skeleton-Ansicht kein Highlighting
    const prevCoin = prevCoinRef.current;
    const currencyChanged = prevCurrencyRef.current !== vsCurrency;

    if (currencyChanged || prevCoin === coin) {
      prevCoinRef.current = coin;
      prevCurrencyRef.current = vsCurrency;
      return;
    }

    // Helfer zum Prüfen, ob sich ein Feld verändert hat
    const checkChange = (field, setHighlight) => {
      if (prevCoin[field] !== coin[field]) {
        const direction = coin[field] > prevCoin[field] ? 'up' : 'down';
        setHighlight(direction);
        // Effekt nach 1 Sekunde zurücksetzen
        setTimeout(() => setHighlight(null), 1000);
      }
    };

    checkChange('current_price', setHighlightPrice);
    checkChange('market_cap', setHighlightMarketCap);
    checkChange('total_volume', setHighlightVolume);

    prevCoinRef.current = coin;
    prevCurrencyRef.current = vsCurrency;
  }, [coin, vsCurrency, loading]);

  // Loading-Skeleton
  if (loading) {
    return (
      <tr>
        <td><Skeleton width={20} /></td>
        <td className="coin-info-cell">
          <Skeleton width={100} />
        </td>
        <td><Skeleton width={50} /></td>
        <td><Skeleton width={40} /></td>
        <td><Skeleton width={60} /></td>
        <td><Skeleton width={60} /></td>
        <td><Skeleton width={100} height={30} /></td>
      </tr>
    );
  }

  // Formatierung des 24h-Change
  const priceChangeFormatted = formatChangePercentage(coin.price_change_percentage_24h);

  // highlightPrice ist 'up' oder 'down' (oder null) => CSS-Klasse
  const highlightPriceClass = highlightPrice
    ? `highlightText-${highlightPrice}`
    : '';

  return (
    <tr onClick={() => onClick(coin.id)}>
      {/* Rank */}
      <td data-label={t({ id: "label.rankMobile", message: "🏅 Rank" })}>
        {coin.market_cap_rank}
      </td>

      {/* Name & Logo */}
      <td className="coin-info-cell">
        <img src={coin.image} alt={coin.name} className="coin-logo" />
        <span className="coin-name">
          {coin.name}{' '}
        </span>
        <span className="coin-symbol desktop-only">
          ({coin.symbol.toUpperCase()})
        </span>
        <span onClick={(e) => e.stopPropagation()}>
          <FavoriteStar coinId={coin.id} coinData={coin} />
        </span>
      </td>

      {/* Preis (mit Highlight-Klasse) */}
      <td data-label={t({ id: "label.priceMobile", message: "💲 Preis" })}>
        {formatPrice(
          coin.current_price,
          vsCurrency,
          true,
          true,
          highlightPriceClass
        )}
      </td>

      {/* 24h-Prozent-Änderung */}
      <td data-label={t({ id: "label.24hChangeMobile", message: "🔄 24h %" })}>
        {priceChangeFormatted}
      </td>

      {/* MarketCap */}
      <td data-label={t({ id: "label.marketCapMobile", message: "🧱 Marktkapitalisierung" })}>
        {
          formatLargeValue(
            coin.market_cap,
            vsCurrency,
            false,  // forceAbbreviated
            true,   // showIcons
            true,   // highlightNumbersOnly
            highlightMarketCap ? `highlightText-${highlightMarketCap}` : ''
          )
        }
      </td>

      {/* 24h-Volume */}
      <td data-label={t({ id: "label.24hVolumeMobile", message: "💹 24h Volumen" })}>
        {
          formatLargeValue(
            coin.total_volume,
            vsCurrency,
            false,  // forceAbbreviated
            true,   // showIcons
            true,   // highlightNumbersOnly
            highlightVolume ? `highlightText-${highlightVolume}` : ''
          )
        }
      </td>

      {/* 7-Tage-Sparkline: nimmt unser neues MiniSparkline */}
      <td data-label={t({ id: "label.7dSparklineMobile", message: "📈 7d" })}>
        <MiniSparkline data={coin.sparkline_in_7d?.price || []} />
      </td>
    </tr>
  );
});

export default CoinRow;
