import React from 'react';
import { Trans } from '@lingui/macro';
import InfoTooltip from './InfoTooltip';

// Locale anhand der gespeicherten Sprache festlegen:
const storedLang = localStorage.getItem('language') || 'de';
const locale = storedLang === 'de' ? 'de-DE' : 'en-US';

// Neuer Wrapper für <strong> mit Unterstreichung
const UnderlinedStrong = ({ children }) => (
  <strong style={{ textDecoration: 'underline' }}>{children}</strong>
);

const SupplyInfo = ({
  maxSupply,
  totalSupply,
  circulatingSupply,
  supplyDescription,
  percentString,
  percentTitle,
  percentLabel,
}) => {
  return (
    <div className="coin-supply-card" style={{ position: 'relative' }}>
      <h3>
        <Trans id="label.supply">Angebot</Trans>
      </h3>
      {/* Info-Icon in der rechten oberen Ecke */}
      <div className="tooltip-wrapper">
        <InfoTooltip iconSize={18}>
          <Trans id="tooltips.supplyExplanation" components={[<br />, <UnderlinedStrong />]}>
            {"• <1>Maximale Versorgung</1>: Gibt an, wie viele Coins insgesamt existieren können.<0/>• <1>Gesamtversorgung</1>: Zeigt die aktuell im Umlauf befindliche Menge an.<0/>• <1>Umlaufende Coins</1>: Zeigt, wie viele Coins aktuell auf dem Markt zirkulieren.<0/>• <1>Prozentualer Anteil</1>: Der Anteil der umlaufenden Coins an der Gesamtversorgung."}
          </Trans>
        </InfoTooltip>
      </div>

      <div className="supply-info-items">
        <div className="supply-info-item max-supply">
          <strong>
            <Trans id="label.maxSupply">Max. Versorgung:</Trans>
          </strong>
          <p className="supply-value">
            {maxSupply !== null ? maxSupply.toLocaleString(locale) : "N/A"}
          </p>
        </div>
        <div className="supply-info-item total-supply">
          <strong>
            <Trans id="label.totalSupply">Ges. Versorgung:</Trans>
          </strong>
          <p className="supply-value">
            {totalSupply !== null ? totalSupply.toLocaleString(locale) : "N/A"}
          </p>
        </div>
        <div className="supply-info-item circulating-supply">
          <strong>
            <Trans id="label.circulating">Umlaufend:</Trans>
          </strong>
          <p className="supply-value">
            {circulatingSupply !== null ? circulatingSupply.toLocaleString(locale) : "N/A"}
          </p>
        </div>
      </div>

      {percentString !== null && (
        <div className="supply-progress-container" title={percentTitle}>
          <div
            className="supply-progress-bar"
            style={{ width: `${percentString}%` }}
          ></div>
          <span className="supply-percent-label">{percentLabel}</span>
        </div>
      )}
      {supplyDescription && (
        <p className="supply-explanation">{supplyDescription}</p>
      )}
    </div>
  );
};

export default SupplyInfo;
