// /src/components/CategoryRow.js
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { formatLargeValue } from '../utils/formatLargeValue';

const CategoryRow = React.memo(({ cat, rank, loading, isNameSort, onRowClick, vsCurrency, conversionFactor }) => {
  if (loading) {
    const colCount = isNameSort ? 4 : 5;
    return (
      <tr>
        {Array.from({ length: colCount }).map((_, i) => (
          <td key={i}>
            <Skeleton width={80} />
          </td>
        ))}
      </tr>
    );
  }

  // Falls Name-Sort, zeigen wir keinen Rank
  const showRank = (!isNameSort && rank > 0) ? rank : null;
  const categoryName = cat?.name || 'Unknown';

  // Die vom Endpoint gelieferten Werte sind in USD.
  // Wir rechnen sie mit dem conversionFactor in die gewählte Währung um.
  const rawMarketCap = cat?.market_cap || 0;
  const rawVolume24h = cat?.volume_24h || 0;
  const marketCapConverted = rawMarketCap * conversionFactor;
  const volume24hConverted = rawVolume24h * conversionFactor;

  const topCoinImgs = Array.isArray(cat?.top_3_coins) ? cat.top_3_coins : [];
  const topCoinIds = Array.isArray(cat?.top_3_coins_id) ? cat.top_3_coins_id : [];

  const handleRowClick = () => {
    if (onRowClick) onRowClick(cat.id);
  };

  return (
    <tr onClick={handleRowClick}>
      {showRank !== null && (
        <td data-label="Rank">
          {showRank}
        </td>
      )}
      <td data-label="Name" className="coin-info-cell">
        <span className="coin-name">{categoryName}</span>
      </td>
      <td data-label="Marktkapitalisierung">
        {formatLargeValue(marketCapConverted, vsCurrency, false, true, false, '')}
      </td>
      <td data-label="24h Volumen">
        {formatLargeValue(volume24hConverted, vsCurrency, false, true, false, '')}
      </td>
      <td data-label="Top Coins" onClick={(e) => e.stopPropagation()}>
        {topCoinImgs.length === 0 ? (
          <span style={{ opacity: 0.6, fontSize: '0.9rem' }}>
            Keine Coins
          </span>
        ) : (
          topCoinImgs.map((imgUrl, i) => {
            const coinId = topCoinIds[i] || 'unknown';
            if (!imgUrl) return null;
            return (
              <a
                key={`${coinId}-${i}`}
                href={`/coin-details?coin=${coinId}&from=1`}
                style={{ marginRight: 8 }}
                onClick={(ev) => ev.stopPropagation()}
              >
                <img
                  src={imgUrl}
                  alt={coinId}
                  title={coinId.toUpperCase()}
                  className="coin-logo"
                  onError={(ev) => {
                    ev.currentTarget.remove();
                  }}
                />
              </a>
            );
          })
        )}
      </td>
    </tr>
  );
});

export default CategoryRow;
