// /src/components/LandingPage.js

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Importiere useNavigate
import '../styles/LandingPage.css';

import WhatIsCrypto from './WhatIsCrypto';
import BuyingSimulator from './BuyingSimulator';
import CoinsSection from './CoinsSection';

const LandingPage = () => {
  const [totalCoins, setTotalCoins] = useState(0); // Gesamtanzahl der Münzen
  const navigate = useNavigate(); // Initialisiere useNavigate für Navigation

  const addCoins = (amount) => {
    setTotalCoins((prev) => prev + amount);
  };

  const shareMessage = 'Schau dir diese tolle Plattform an!';
  const shareUrl = window.location.href;

  return (
    <div className="landing-page">

      {/* Einführung und Leitfaden */}
      <WhatIsCrypto />

      {/* Grundlagen des Kryptohandels */}
      <div className="fundamentals-section">
        <h2>Grundlagen des Kryptokaufs und Tradings</h2>
        <p>
          Um erfolgreich mit Kryptowährungen zu handeln oder zu investieren, ist es wichtig, die Grundlagen zu verstehen.
          Nutze unseren interaktiven Leitfaden, um die wesentlichen Konzepte zu erlernen.
        </p>
        <BuyingSimulator addCoins={addCoins} />
      </div>

      {/* Coins-Abschnitt */}
      <CoinsSection />
      
    </div>
  );
};

export default LandingPage;
