import React, { useEffect, useRef } from 'react';

const TradingViewAdvancedChart = ({ symbol = "BINANCE:BTCUSDT", interval = "D", theme = "light" }) => {
  const widgetRef = useRef(null);

  useEffect(() => {
    if (widgetRef.current) {
      // Container leeren vor Neubefüllung
      widgetRef.current.innerHTML = '';
    }

    const containerId = "tradingview_container"; // Fester Container-Name ohne Sonderzeichen

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = 'https://s3.tradingview.com/tv.js';

    const scriptContent = `
      new TradingView.widget({
        "width": "100%",
        "height": "100%",
        "symbol": "${symbol}",
        "interval": "${interval}",
        "theme": "${theme}",
        "style": "1",
        "locale": "en",
        "toolbar_bg": "#f1f3f6",
        "enable_publishing": false,
        "withdateranges": true,
        "hide_side_toolbar": false,
        "allow_symbol_change": true,
        "container_id": "${containerId}"
      });
    `;

    script.onload = () => {
      const scriptExec = document.createElement('script');
      scriptExec.type = 'text/javascript';
      scriptExec.innerHTML = scriptContent;
      widgetRef.current.appendChild(scriptExec);
    };

    widgetRef.current.innerHTML = `<div class="tradingview-widget-container"><div id="${containerId}"></div></div>`;
    widgetRef.current.appendChild(script);

    return () => {
      if (widgetRef.current) {
        widgetRef.current.innerHTML = '';
      }
    };
  }, [symbol, interval, theme]);

  return (
    <div style={{ width: '100%', height: '500px', position: 'relative' }} ref={widgetRef}></div>
  );
};

export default TradingViewAdvancedChart;
