// src/components/widgets/WidgetBuilder.js
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Trans, t } from '@lingui/macro';
import { toast } from 'react-toastify';
import api from '../../services/api'; // <-- Neu: unser Axios-Client
import '../../styles/WidgetBuilder.css';
import WidgetBuilderForm from './WidgetBuilderForm';  // <-- NEU
import WidgetPreview from './WidgetPreview';          // <-- NEU
import 'react-loading-skeleton/dist/skeleton.css';

// Debounce-Hook
function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => clearTimeout(timer);
  }, [value, delay]);
  return debouncedValue;
}

const DEFAULT_CONFIG = {
  coin: 'bitcoin',
  currency: 'usd',
  color: '#2C2C2C',
  textColor: '#FFFAFA',
  borderColor: '#cccccc',
  borderRadius: 8,
};

const WidgetBuilder = ({ mode }) => {
  const navigate = useNavigate();
  const { id } = useParams();

  // General States
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [type, setType] = useState('price-box');
  const [config, setConfig] = useState(DEFAULT_CONFIG);
  const [isSaving, setIsSaving] = useState(false);

  // Loaded-Flag
  const [widgetLoaded, setWidgetLoaded] = useState(false);

  // Preview
  const [previewData, setPreviewData] = useState(null);
  const [isPreviewLoading, setIsPreviewLoading] = useState(true);
  const [isDelayedSkeleton, setIsDelayedSkeleton] = useState(false);

  // Color Debounce
  const [colorLocal, setColorLocal] = useState('#2C2C2C');
  const [textColorLocal, setTextColorLocal] = useState('#FFFAFA');
  const [borderColorLocal, setBorderColorLocal] = useState('#cccccc');
  const [borderRadiusLocal, setBorderRadiusLocal] = useState('8');

  const debouncedColor = useDebounce(colorLocal, 300);
  const debouncedTextColor = useDebounce(textColorLocal, 300);
  const debouncedBorderColor = useDebounce(borderColorLocal, 300);

  // Currencies
  const [supportedCurrencies, setSupportedCurrencies] = useState([]);
  const [isCurrenciesLoaded, setIsCurrenciesLoaded] = useState(false);

  // Delay-Skeleton
  useEffect(() => {
    const timer = setTimeout(() => setIsDelayedSkeleton(true), 300);
    return () => clearTimeout(timer);
  }, []);

  // (1) load currencies
  useEffect(() => {
    const loadSupportedCurrencies = async () => {
      try {
        const { data } = await api.get('/supported-currencies');
        setSupportedCurrencies(data);
      } catch (err) {
        console.error(err);
        toast.error(
          t({
            id: 'widget.builder.errorCurrenciesLoad',
            message: 'Währungen konnten nicht geladen werden. Bitte später erneut versuchen.',
          })
        );
      } finally {
        setIsCurrenciesLoaded(true);
      }
    };
    loadSupportedCurrencies();
  }, []);

  // (2) Edit => Widget laden
  useEffect(() => {
    if (mode === 'edit' && id) {
      (async () => {
        try {
          const { data } = await api.get(`/widgets/${id}`); // GET /api/widgets/:id
          setName(data.name);
          setType(data.type);

          const parsed =
            typeof data.config === 'string' ? JSON.parse(data.config) : data.config;

          setConfig({
            coin: parsed.coin || 'bitcoin',
            currency: parsed.currency || 'usd',
            color: parsed.color || '#2C2C2C',
            textColor: parsed.textColor || '#FFFAFA',
            borderColor: parsed.borderColor || '#cccccc',
            borderRadius: parsed.borderRadius ?? 0,
          });

          setColorLocal(parsed.color || '#2C2C2C');
          setTextColorLocal(parsed.textColor || '#FFFAFA');
          setBorderColorLocal(parsed.borderColor || '#cccccc');
          setBorderRadiusLocal(String(parsed.borderRadius ?? 0));
          setWidgetLoaded(true);
        } catch (err) {
          console.error(err);
          toast.error(
            t(
              {
                id: 'widget.builder.errorFetchWidget',
                message: 'Fehler beim Laden des Widgets: {message}',
              },
              { message: err.message }
            )
          );
        }
      })();
    } else if (mode === 'create') {
      // Neues Widget => Defaults
      setColorLocal(DEFAULT_CONFIG.color);
      setTextColorLocal(DEFAULT_CONFIG.textColor);
      setBorderColorLocal(DEFAULT_CONFIG.borderColor);
      setBorderRadiusLocal(String(DEFAULT_CONFIG.borderRadius));
      setWidgetLoaded(true);
    }
  }, [mode, id]);

  // (3) Debounce -> config
  useEffect(() => {
    setConfig((prev) => ({
      ...prev,
      color: debouncedColor,
      textColor: debouncedTextColor,
      borderColor: debouncedBorderColor,
    }));
  }, [debouncedColor, debouncedTextColor, debouncedBorderColor]);

  useEffect(() => {
    setConfig((prev) => ({
      ...prev,
      borderRadius: parseInt(borderRadiusLocal, 10) || 0,
    }));
  }, [borderRadiusLocal]);

  // (4) SPEICHERN
  const handleSave = async () => {
    setNameError('');
    setIsSaving(true);

    // Validierung
    if (!name.trim()) {
      const key = 'widget.builder.errorNameEmpty';
      setNameError(
        <Trans id={key}>Bitte gib einen Namen für dein Widget ein!</Trans>
      );
      toast.error(t({ id: key, message: 'Bitte gib einen Namen für dein Widget ein!' }));
      setIsSaving(false);
      return;
    }
    if (name.trim().length > 20) {
      const key = 'widget.builder.errorNameMaxLength';
      setNameError(
        <Trans id={key}>Name darf nicht länger als 20 Zeichen sein!</Trans>
      );
      toast.error(t({ id: key, message: 'Name darf nicht länger als 20 Zeichen sein!' }));
      setIsSaving(false);
      return;
    }

    const body = {
      name,
      type,
      config: {
        coin: config.coin,
        currency: config.currency,
        color: config.color,
        textColor: config.textColor,
        borderColor: config.borderColor,
        borderRadius: config.borderRadius,
      },
    };

    try {
      let url = '/widgets'; // => /api/widgets
      let method = 'POST';
      if (mode === 'edit' && id) {
        url = `/widgets/${id}`;
        method = 'PUT';
      }

      // Per Axios:
      if (method === 'POST') {
        await api.post(url, body);
      } else {
        await api.put(url, body);
      }

      toast.success(
        t({
          id: 'widget.builder.successWidgetSaved',
          message: 'Widget erfolgreich gespeichert!',
        })
      );
      navigate('/widgets');
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status === 403) {
        toast.error(
          t({
            id: 'widget.builder.errorFreeLimit',
            message: 'Free-Tarif-Limit erreicht oder Pro-Feature. Bitte upgrade dein Abo!',
          })
        );
      } else if (err.response && err.response.status === 401) {
        toast.error(
          t({
            id: 'widget.builder.errorMustLogin',
            message: 'Du musst eingeloggt sein, um ein Widget zu speichern.',
          })
        );
      } else {
        toast.error(
          t(
            {
              id: 'widget.builder.errorSaveFailed',
              message: 'Speichern fehlgeschlagen: {message}',
            },
            { message: err.message }
          )
        );
      }
    } finally {
      setIsSaving(false);
    }
  };

  const handleCancel = () => {
    navigate('/widgets');
  };

  // (5) PREVIEW-Logik
  useEffect(() => {
    if (!widgetLoaded) return;

    setPreviewData(null);
    setIsPreviewLoading(true);

    let intervalId;
    const loadPreviewData = async () => {
      const coinId = (config.coin || '').toLowerCase().trim();
      if (!coinId) {
        setIsPreviewLoading(false);
        return;
      }
      try {
        // /coin-data?id=...
        const { data } = await api.get('/coin-data', {
          params: { id: coinId }
        });
        const coinName = data.name || coinId;
        const coinImage = data.image?.large || '';
        const priceNum = data.market_data?.current_price?.[config.currency] || 0;
        const pct24 =
          data.market_data?.price_change_percentage_24h_in_currency?.[config.currency] || 0;

        setPreviewData({
          name: coinName,
          image: coinImage,
          current_price: priceNum,
          price_change_percentage_24h: pct24,
        });
      } catch (err) {
        console.error(err);
        toast.error(
          t({
            id: 'widget.builder.errorPreviewLoad',
            message: 'Konnte die Vorschau nicht laden. Bitte später erneut versuchen.',
          })
        );
        setPreviewData(null);
      } finally {
        setIsPreviewLoading(false);
      }
    };

    loadPreviewData();
    intervalId = setInterval(loadPreviewData, 15000);
    return () => clearInterval(intervalId);
  }, [widgetLoaded, config.coin, config.currency, type]);

  return (
    <div className="widget-builder-container">
      <h2 className="widget-builder-title">
        {mode === 'create' ? (
          <Trans id="widget.builder.createTitle">Widget erstellen</Trans>
        ) : (
          <Trans id="widget.builder.editTitle">Widget bearbeiten</Trans>
        )}
      </h2>

      <p className="intro-text">
        <Trans id="widget.builder.intro">
          Erstelle dein individuelles Widget – mit unserem Widget Builder hast du die volle Kontrolle:
          Wähle den gewünschten Coin, passe Farben, Text, Rahmen und Ecken an und beobachte in der
          Live-Vorschau, wie dein Widget Form annimmt. So wird dein Widget zu einem echten Unikat,
          das sich nahtlos in dein Dashboard einfügt.
        </Trans>
      </p>

      <div className="widget-builder-form">
        {/* (A) FORM-Abschnitt */}
        <WidgetBuilderForm
          name={name}
          setName={setName}
          nameError={nameError}
          type={type}
          setType={setType}
          config={config}
          setConfig={setConfig}
          colorLocal={colorLocal}
          setColorLocal={setColorLocal}
          textColorLocal={textColorLocal}
          setTextColorLocal={setTextColorLocal}
          borderColorLocal={borderColorLocal}
          setBorderColorLocal={setBorderColorLocal}
          borderRadiusLocal={borderRadiusLocal}
          setBorderRadiusLocal={setBorderRadiusLocal}
          isSaving={isSaving}
          handleSave={handleSave}
          handleCancel={handleCancel}
          mode={mode}
          isCurrenciesLoaded={isCurrenciesLoaded}
          supportedCurrencies={supportedCurrencies}
        />

        {/* (B) PREVIEW-Abschnitt */}
        <div className="widget-builder-preview">
          <h3>
            <Trans id="widget.builder.previewTitle">Vorschau</Trans>
          </h3>

          <WidgetPreview
            widgetLoaded={widgetLoaded}
            previewData={previewData}
            isPreviewLoading={isPreviewLoading}
            isDelayedSkeleton={isDelayedSkeleton}
            config={config}
            type={type}
          />

          <small style={{ marginTop: '10px', display: 'block' }}>
            <Trans id="widget.builder.preview.info">
              Diese Vorschau wechselt je nach Widget-Typ.
              Der Name wird nicht im Widget angezeigt.
            </Trans>
          </small>
        </div>
      </div>
    </div>
  );
};

export default WidgetBuilder;
