// src/components/GlobalData.js

import React from 'react';
import { Trans } from '@lingui/macro';
import { i18n } from '@lingui/core';
import { Chart as ChartJS } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import annotationPlugin from 'chartjs-plugin-annotation';
import { Line, Pie, Doughnut } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import FearAndGreedIndex from './FearAndGreedIndex';
import { useGlobalData } from '../hooks/useGlobalData';

import { formatLargeValue } from '../utils/formatLargeValue';
import { formatChangePercentage } from '../utils/formatChangePercentage';
import {
  getTimeOptionsAndMaxTicks,
  xTickCallback,
  tooltipTitleCallback,
} from '../utils/chartUtils';
import SingleSelectNoSearch from '../utils/SingleSelectNoSearch';

import '../styles/GlobalData.css';

/* ---------------------------------------------
  1) ChartJS Plugin-Code (PercentChange)
--------------------------------------------- */
const customPercentChangePlugin = {
  id: 'customPercentChangePlugin',
  afterDraw(chart, args, pluginOptions) {
    const { ctx, chartArea } = chart;
    const text = pluginOptions?.changeText || '';
    if (!text) return;

    const textColor = pluginOptions?.textColor || '#000';
    const boxColor = pluginOptions?.backgroundColor || 'rgba(0,0,0,0.1)';
    const padding = 6;
    const x = chartArea.right - 10;
    const y = chartArea.top - 6;

    ctx.save();
    ctx.font = 'bold 14px sans-serif';
    ctx.textBaseline = 'middle';
    ctx.fillStyle = textColor;

    const textWidth = ctx.measureText(text).width;
    const boxWidth = textWidth + padding * 2;
    const boxHeight = 24;
    const boxLeft = x - boxWidth;
    const boxTop = y - boxHeight / 2;

    // Hintergrund-Box
    ctx.fillStyle = boxColor;
    ctx.fillRect(boxLeft, boxTop, boxWidth, boxHeight);

    // Text
    ctx.fillStyle = textColor;
    const textX = boxLeft + padding;
    const textY = y;
    ctx.fillText(text, textX, textY);
    ctx.restore();
  },
};

// ChartJS Plugins registrieren
ChartJS.register(ChartDataLabels, annotationPlugin, customPercentChangePlugin);

/* ---------------------------------------------
  2) Hilfsfunktion für Volumen-Styling
--------------------------------------------- */
const getVolumeAppearance = (days) => {
  let backgroundColor = 'rgba(255,99,132,0.1)';
  let borderWidth = 0.5;
  const numericDays = days === 'max' ? 3650 : parseInt(days, 10);

  if (numericDays <= 7) {
    backgroundColor = 'rgba(255,99,132,0.1)';
    borderWidth = 0.5;
  } else if (numericDays <= 30) {
    backgroundColor = 'rgba(255,99,132,0.15)';
    borderWidth = 0.7;
  } else if (numericDays <= 90) {
    backgroundColor = 'rgba(255,99,132,0.2)';
    borderWidth = 0.9;
  } else {
    backgroundColor = 'rgba(255,99,132,0.3)';
    borderWidth = 1;
  }
  return { backgroundColor, borderWidth };
};

/* ---------------------------------------------
  3) Mini-Komponente: GlobalMarketOverviewCard
--------------------------------------------- */
const GlobalMarketOverviewCard = ({ globalData, userLocale }) => {
  if (!globalData) return null;

  return (
    <div className="data-card">
      <h3>
        <Trans id="global.marketOverview">Global Market Overview</Trans>
      </h3>
      <p>
        <strong>
          <Trans id="label.totalMarketCap">Total Market Cap:</Trans>
        </strong>{' '}
        {globalData.total_market_cap?.usd
          ? formatLargeValue(parseFloat(globalData.total_market_cap.usd), 'usd', true, false)
          : 'N/A'}
      </p>
      <p>
        <strong>
          <Trans id="label.totalVolume">24h Volume:</Trans>
        </strong>{' '}
        {globalData.total_volume?.usd
          ? formatLargeValue(parseFloat(globalData.total_volume.usd), 'usd', true, false)
          : 'N/A'}
      </p>
      <p>
        <strong>
          <Trans id="label.btcDominance">Bitcoin Dominance:</Trans>
        </strong>{' '}
        {globalData.market_cap_percentage?.btc
          ? parseFloat(globalData.market_cap_percentage.btc).toFixed(2) + '%'
          : 'N/A'}
      </p>
      <p>
        <strong>
          <Trans id="label.marketCapChange24h">Market Cap Change 24h:</Trans>
        </strong>{' '}
        {formatChangePercentage(globalData.market_cap_change_percentage_24h_usd, {
          useBackground: false,
        })}
      </p>
      <p>
        <strong>
          <Trans id="summary.activeCryptos">Aktive Coins:</Trans>
        </strong>{' '}
        {globalData.active_cryptocurrencies?.toLocaleString(userLocale) || 'N/A'}
      </p>
      <p>
        <strong>
          <Trans id="summary.markets">Märkte:</Trans>
        </strong>{' '}
        {globalData.markets?.toLocaleString(userLocale) || 'N/A'}
      </p>
    </div>
  );
};

/* ---------------------------------------------
  4) Komponente: DominancePieChart
--------------------------------------------- */
export const DominancePieChart = ({ globalData, userLocale }) => {
  if (!globalData || !globalData.market_cap_percentage) return null;

  const isMobile = window.innerWidth < 768;
  const marketCapPercentages = globalData.market_cap_percentage;

  // Top 10 Coins bestimmen
  let topCoins = Object.entries(marketCapPercentages)
    .sort(([, a], [, b]) => b - a)
    .slice(0, 10);

  // Prüfen, ob Summe < 100 -> "Other" hinzufügen
  const sumTop10 = topCoins.reduce((acc, [, perc]) => acc + perc, 0);
  if (sumTop10 < 100) {
    topCoins.push(['Other', 100 - sumTop10]);
  }

  // Farben definieren
  const coinColors = {
    BTC: '#F7931A',
    ETH: '#627EEA',
    STETH: '#627EEA',
    XRP: '#346AA9',
    BNB: '#F3BA2F',
    USDT: '#26A17B',
    USDC: '#26A17B',
    ADA: '#0033AD',
    SOL: '#00FFA3',
    DOGE: '#C2A633',
    DOT: '#E6007A',
    MATIC: '#8247E5',
    SHIB: '#FF6F61',
    TRX: '#EB0029',
    LTC: '#A6A9AA',
    AVAX: '#E84142',
    UNI: '#FF007A',
    LINK: '#2A5ADA',
    XLM: '#14B4E2',
    ATOM: '#2E3148',
    ETC: '#669073',
    XMR: '#FF6600',
    BCH: '#8DC351',
    APT: '#0D0D0D',
    ALGO: '#1A4DAB',
    VET: '#15BDFF',
    ICP: '#29ABE2',
    Other: '#AAAAAA',
  };

  // Labels lokalisieren
  const localizedLabels = topCoins.map(([coin]) =>
    coin === 'Other' ? i18n._('label.other') : coin.toUpperCase()
  );

  // Chart-Daten
  const pieData = {
    labels: localizedLabels,
    datasets: [
      {
        data: topCoins.map(([, percentage]) => percentage),
        backgroundColor: topCoins.map(
          ([coin]) => coinColors[coin.toUpperCase()] || '#CCCCCC'
        ),
      },
    ],
  };

  // Chart-Optionen
  const pieOptions = {
    responsive: true,
    maintainAspectRatio: false,
    layout: { padding: { top: 20, bottom: 50, left: 20, right: 20 } },
    interaction: { mode: 'point', intersect: true },
    plugins: {
      annotation: false,
      legend: {
        display: !isMobile,
        position: 'bottom',
        labels: { usePointStyle: true, font: { size: 12 }, padding: 30 },
        onClick: null,
      },
      tooltip: {
        callbacks: {
          title: (context) => context[0].label,
          label: (context) => {
            const value = context.parsed;
            const formattedValue = value.toLocaleString(i18n.locale || 'en', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
            return `${formattedValue}%`;
          },
        },
        displayColors: false,
      },
      datalabels: {
        display: !isMobile,
        color: (context) => {
          const dataset = context.chart.data.datasets[0];
          return dataset.backgroundColor[context.dataIndex];
        },
        font: { size: 12 },
        formatter: (value, context) => {
          if (value < 2) return '';
          const label = context.chart.data.labels[context.dataIndex];
          const formattedValue = value.toLocaleString(i18n.locale || 'en', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
          return `${label}\n${formattedValue}%`;
        },
        anchor: 'end',
        align: 'end',
        offset: 1,
        clamp: true,
        clip: false,
      },
    },
  };

  return (
    <div className="data-card" style={{ marginTop: '20px', textAlign: 'center' }}>
      <h3>
        <Trans id="global.topCoinDominance">Top 10 Coin Dominance</Trans>
      </h3>
      <div
        id="dominance-pie-chart"
        style={{
          width: '100%',
          maxWidth: '600px',
          margin: '0 auto',
          position: 'relative',
        }}
      >
        {isMobile ? (
          <Doughnut data={pieData} options={{ ...pieOptions, maintainAspectRatio: true }} />
        ) : (
          <Pie data={pieData} options={{ ...pieOptions, maintainAspectRatio: true }} />
        )}
      </div>
      {isMobile && (
        <div className="dominance-mobile-legend">
          {Object.entries(marketCapPercentages)
            .sort(([, a], [, b]) => b - a)
            .slice(0, 10)
            .map(([coinKey]) => {
              const color = coinColors[coinKey.toUpperCase()] || '#CCCCCC';
              const label =
                coinKey === 'Other' ? i18n._('label.other') : coinKey.toUpperCase();
              return (
                <div className="mobile-legend-item" key={coinKey}>
                  <span
                    className="mobile-legend-color"
                    style={{ backgroundColor: color }}
                  />
                  <span className="mobile-legend-label">{label}</span>
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
};

/* ---------------------------------------------
  5) Komponente: MarketCapLineChart
--------------------------------------------- */
export const MarketCapLineChart = ({
  marketCapChartData,
  userLocale,
  days,
  vsCurrency = 'usd',
  handleDaysChange,
}) => {
  if (!marketCapChartData) return null;

  const isMobile = window.innerWidth < 768;
  const { marketCapPoints, volumePoints } = marketCapChartData;
  const { backgroundColor, borderWidth } = getVolumeAppearance(days);

  // Prozent-Veränderung ermitteln
  let percentageChange = 0;
  if (marketCapPoints.length > 1) {
    const firstValue = marketCapPoints[0].y;
    const lastValue = marketCapPoints[marketCapPoints.length - 1].y;
    percentageChange = ((lastValue - firstValue) / firstValue) * 100;
  }
  const percentageChangeElement = formatChangePercentage(percentageChange, {
    useBackground: true,
  });
  const className = percentageChangeElement.props.className || '';

  // Farbe je nach Trend
  let fontColor = '#000';
  let backgroundFill = 'rgba(0, 0, 0, 0.1)';

  if (className.includes('GlobalData_BackgroundPercentage-up')) {
    fontColor = '#1faa0c';
    backgroundFill = 'rgba(26, 170, 12, 0.2)';
  } else if (className.includes('GlobalData_BackgroundPercentage-down')) {
    fontColor = '#ff0000';
    backgroundFill = 'rgba(255, 0, 0, 0.2)';
  } else if (className.includes('GlobalData_BackgroundPercentage-neutral')) {
    fontColor = '#999999';
    backgroundFill = 'rgba(153, 153, 153, 0.2)';
  }
  const formattedChangeText = percentageChangeElement.props.children;

  // ChartJS Daten
  const data = {
    datasets: [
      {
        label: 'Market Cap',
        data: marketCapPoints,
        borderColor: 'rgba(75,192,192,1)',
        backgroundColor: 'rgba(75,192,192,0.2)',
        fill: true,
        tension: 0.1,
        pointRadius: 0,
        type: 'line',
        yAxisID: 'y',
      },
      {
        label: '24h Volume',
        data: volumePoints,
        type: 'bar',
        borderColor: 'rgba(255,99,132,1)',
        backgroundColor: backgroundColor,
        borderWidth: borderWidth,
        yAxisID: 'yVolume',
        order: 1,
      },
    ],
  };

  // Zeit-/Ticks-Berechnung
  const mockChartData = { datasets: [{ data: marketCapPoints }] };
  const { timeOptions, maxTicks } = getTimeOptionsAndMaxTicks(days, mockChartData);

  // Optionen
  const options = {
    responsive: true,
    interaction: { mode: 'index', intersect: false },
    layout: { padding: { top: 20, right: 10 } },
    plugins: {
      datalabels: { display: false },
      annotation: false,
      customPercentChangePlugin: {
        changeText: formattedChangeText,
        textColor: fontColor,
        backgroundColor: backgroundFill,
      },
      legend: { display: false },
      tooltip: {
        callbacks: {
          title: (items) => tooltipTitleCallback(items, userLocale),
          label: (context) => {
            const value = context.parsed.y;
            const formattedValue = value.toLocaleString(i18n.locale || 'en', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
            return `${context.dataset.label}: ${formattedValue}`;
          },
        },
      },
    },
    scales: {
      x: {
        type: 'time',
        time: timeOptions,
        ticks: {
          includeBounds: true,
          autoSkip: false,
          maxTicksLimit: maxTicks,
          callback: (value, index, ticks) =>
            xTickCallback(value, index, ticks, days, userLocale),
        },
        stacked: false,
      },
      y: {
        type: 'linear',
        display: true,
        position: 'left',
        title: { display: true, text: 'Market Cap (USD)' },
        ticks: {
          callback: (value) => formatLargeValue(value, vsCurrency, true, false),
        },
      },
      yVolume: {
        type: 'linear',
        display: !isMobile,
        position: 'right',
        title: { display: !isMobile, text: 'Volume (USD)' },
        grid: { drawOnChartArea: false },
        ticks: {
          callback: (value) => formatLargeValue(value, vsCurrency, true, false),
        },
      },
    },
  };

  return (
    <div className="data-card">
      <div className="chart-header">
        <h3>
          <Trans id="global.marketCapChart">Global Market Cap Chart</Trans>
        </h3>
        <div className="select-container">
          <SingleSelectNoSearch
            options={[
              { value: '1', label: '24h' },
              { value: '7', label: '7d' },
              { value: '14', label: '14d' },
              { value: '30', label: '30d' },
              { value: '90', label: '90d' },
              { value: '180', label: '180d' },
              { value: '365', label: '1y' },
              { value: 'max', label: 'max' },
            ]}
            selectedValue={days}
            onChange={handleDaysChange}
            placeholder="Zeitraum auswählen"
          />
        </div>
      </div>
      <Line data={data} options={options} />
    </div>
  );
};

/* ---------------------------------------------
  6) Hauptkomponente: GlobalData
--------------------------------------------- */
const GlobalData = () => {
  // 1) Alle Daten & States via Custom-Hook
  const {
    globalData,
    marketCapChartData,
    globalDefi,
    globalStable,
    loading,
    error,
    vsCurrency,
    days,
    setDays,
    userLocale,
  } = useGlobalData();

  // 2) Handler für den Zeitraum
  const handleDaysChange = (newVal) => {
    setDays(newVal);
  };

  // 3) Render
  return (
    <div id="global-data-container">
      <h2 id="global-data-title">
        <Trans id="title.globalData">Globale Kryptomarktdaten</Trans>
      </h2>

      <p className="intro-text">
        <Trans id="description.intro">
          Erlebe den Puls des globalen Kryptomarkts – dieses Dashboard bündelt alle wesentlichen
          Daten: die Gesamt-Marktkapitalisierung, die Dominanz der Top 10 sowie weitere zentrale
          Kennzahlen. So behältst du jederzeit den Überblick und erkennst Trends auf einen Blick.
        </Trans>
      </p>

      {/* Loading-Zustand */}
      {loading && (
        <div id="global-data-loading-container">
          <SkeletonTheme baseColor="#ccc" highlightColor="#eee">
            <h2>
              <Skeleton width={200} />
            </h2>
            <div className="data-card">
              <h3>
                <Skeleton width={250} />
              </h3>
              <div
                style={{
                  width: '100%',
                  height: '300px',
                  margin: '0 auto',
                  position: 'relative',
                  background: '#ccc',
                  borderRadius: '10px',
                }}
              >
                <Skeleton height="100%" />
              </div>
            </div>
          </SkeletonTheme>
        </div>
      )}

      {/* Error-Zustand */}
      {error && <div id="global-data-error">{error}</div>}

      {/* Anzeige der Daten (wenn nicht loading & kein error) */}
      {!loading && !error && (
        <>
          {/* Cards mit Market Overview, DeFi, Stablecoins */}
          <div className="global-cards-container">
            <div className="card-column">
              <GlobalMarketOverviewCard globalData={globalData} userLocale={userLocale} />
            </div>

            {/* DeFi Overview */}
            {globalDefi && (
              <div className="card-column">
                <div className="data-card">
                  <h3>
                    <Trans id="global.defiOverview">DeFi Overview</Trans>
                  </h3>
                  <p>
                    <strong>
                      <Trans id="label.defiTVL">Total Value Locked:</Trans>
                    </strong>{' '}
                    {globalDefi.defi_market_cap
                      ? formatLargeValue(parseFloat(globalDefi.defi_market_cap), 'usd', true, false)
                      : 'N/A'}
                  </p>
                  <p>
                    <strong>
                      <Trans id="label.defiVolume">24h Trading Volume:</Trans>
                    </strong>{' '}
                    {globalDefi.trading_volume_24h
                      ? formatLargeValue(parseFloat(globalDefi.trading_volume_24h), 'usd', true, false)
                      : 'N/A'}
                  </p>
                  <p>
                    <strong>
                      <Trans id="label.defiDominance">DeFi Dominance:</Trans>
                    </strong>{' '}
                    {globalDefi.defi_dominance
                      ? `${parseFloat(globalDefi.defi_dominance).toFixed(2)}%`
                      : 'N/A'}
                  </p>
                  <p>
                    <strong>
                      <Trans id="label.topDefiCoin">Top DeFi Coin:</Trans>
                    </strong>{' '}
                    {globalDefi.top_coin_name} (
                    {globalDefi.top_coin_defi_dominance
                      ? `${parseFloat(globalDefi.top_coin_defi_dominance).toFixed(2)}%`
                      : 'N/A'}
                    )
                  </p>
                </div>
              </div>
            )}

            {/* Stablecoin Market */}
            {globalStable && (
              <div className="card-column">
                <div className="data-card">
                  <h3>
                    <Trans id="global.stablecoinMarket">Stablecoin Market</Trans>
                  </h3>
                  <p>
                    <strong>
                      <Trans id="label.stablecoinMarketCap">Stablecoin Market Cap:</Trans>
                    </strong>{' '}
                    {globalStable.market_cap
                      ? formatLargeValue(parseFloat(globalStable.market_cap), 'usd', true, false)
                      : 'N/A'}
                  </p>
                  <p>
                    <strong>
                      <Trans id="label.stablecoinVolume">24h Stablecoin Volume:</Trans>
                    </strong>{' '}
                    {globalStable.total_volume
                      ? formatLargeValue(parseFloat(globalStable.total_volume), 'usd', true, false)
                      : 'N/A'}
                  </p>
                </div>
              </div>
            )}
          </div>

          {/* Globale Marktcap-Charts */}
          <MarketCapLineChart
            marketCapChartData={marketCapChartData}
            userLocale={userLocale}
            days={days}
            vsCurrency={vsCurrency}
            handleDaysChange={handleDaysChange}
          />

          {/* Fear & Greed Index */}
          <FearAndGreedIndex />

          {/* PieChart: Top10 Dominance */}
          <DominancePieChart globalData={globalData} userLocale={userLocale} />
        </>
      )}
    </div>
  );
};

export default GlobalData;
