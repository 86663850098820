// utils/formatChangePercentage.js
import React from 'react';

export function formatChangePercentage(value, options = {}) {
  const { useBackground = false, hideTrailingPercent = false } = options;

  if (value === null || value === undefined || isNaN(value)) {
    return 'N/A';
  }

  // Locale basierend auf der Sprache festlegen:
  const storedLang = localStorage.getItem('language') || 'de';
  const locale = storedLang === 'de' ? 'de-DE' : 'en-US';

  // Runden auf zwei Nachkommastellen
  const roundedValue = Math.round(value * 100) / 100;

  const sign = roundedValue > 0 ? '+' : roundedValue < 0 ? '-' : '';

  const formatter = new Intl.NumberFormat(locale, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const formattedNumber = formatter.format(Math.abs(roundedValue));
  const formatted = `${sign}${formattedNumber}${hideTrailingPercent ? '' : '%'}`;

  // Standardausgabe
  if (!useBackground) {
    if (roundedValue > 0) {
      return <span className="price-up">{formatted}</span>;
    } else if (roundedValue < 0) {
      return <span className="price-down">{formatted}</span>;
    } else {
      return <span className="price-neutral">{formatted}</span>;
    }
  }

  // Ausgabe mit Hintergrund-Rechteck
  const className =
    roundedValue > 0
      ? 'GlobalData_BackgroundPercentage-rect GlobalData_BackgroundPercentage-up'
      : roundedValue < 0
      ? 'GlobalData_BackgroundPercentage-rect GlobalData_BackgroundPercentage-down'
      : 'GlobalData_BackgroundPercentage-rect GlobalData_BackgroundPercentage-neutral';

  return <span className={className}>{formatted}</span>;
}
