// src/context/FavoritesContext.js

import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback
} from 'react';
import { AuthContext } from './AuthContext';
import api from '../services/api'; // Unser Axios-Client

const FavoritesContext = createContext();

export const FavoritesProvider = ({ children }) => {
  const [favorites, setFavorites] = useState(new Set());
  const [folders, setFolders] = useState([]);
  const [loading, setLoading] = useState(true);
  const { isLoggedIn } = useContext(AuthContext);

  /**
   * fetchFavorites: Lädt die Favoriten-IDs vom Server.
   * - via useCallback, damit wir eine stabile Referenz haben
   *   und die ESLint-Warnung vermeiden.
   */
  const fetchFavorites = useCallback(() => {
    if (!isLoggedIn) {
      setFavorites(new Set());
      setLoading(false);
      return;
    }
    setLoading(true);

    // GET /api/favorites/ids
    api
      .get('/favorites/ids')
      .then((res) => {
        setFavorites(new Set(res.data));
        setLoading(false);
      })
      .catch((err) => {
        console.error('Fehler beim Laden der Favoriten-IDs:', err);
        setLoading(false);
      });
  }, [isLoggedIn]);

  /**
   * fetchFolders: Lädt die Favoriten-Ordner vom Server.
   */
  const fetchFolders = useCallback(() => {
    if (!isLoggedIn) return;

    // GET /api/favorites/folders
    api
      .get('/favorites/folders')
      .then((res) => {
        if (Array.isArray(res.data)) {
          setFolders(res.data);
        }
      })
      .catch((err) => {
        console.error('Fehler beim Laden der Ordner:', err);
      });
  }, [isLoggedIn]);

  /**
   * useEffect (1): Wird beim (re)Mount oder wenn sich isLoggedIn ändert,
   * ruft fetchFavorites und fetchFolders auf.
   */
  useEffect(() => {
    fetchFavorites();
    fetchFolders();
  }, [isLoggedIn, fetchFavorites, fetchFolders]);

  /**
   * useEffect (2): Sichtbarkeitswechsel und Fokus => Reload
   */
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible' && isLoggedIn) {
        fetchFavorites();
        fetchFolders();
      }
    };

    const handleFocus = () => {
      if (isLoggedIn) {
        fetchFavorites();
        fetchFolders();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    window.addEventListener('focus', handleFocus);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('focus', handleFocus);
    };
  }, [isLoggedIn, fetchFavorites, fetchFolders]);

  // Favoriten einfach lokal hinzufügen (ohne extra Fetch)
  const addFavorite = (coinId) => {
    setFavorites((prev) => new Set([...prev, coinId]));
  };

  // Favoriten lokal entfernen
  const removeFavorite = (coinId) => {
    setFavorites((prev) => {
      const newSet = new Set(prev);
      newSet.delete(coinId);
      return newSet;
    });
  };

  return (
    <FavoritesContext.Provider
      value={{
        favorites,
        addFavorite,
        removeFavorite,
        loading,
        folders,
        fetchFolders
      }}
    >
      {children}
    </FavoritesContext.Provider>
  );
};

export const useFavorites = () => useContext(FavoritesContext);
