// src/components/CoinDetails/ActiveMarkets.jsx

import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { Trans } from '@lingui/macro';
import { formatLargeValue } from '../../utils/formatLargeValue';
import { FaInfoCircle, FaTimesCircle, FaExclamationTriangle } from 'react-icons/fa';
import InfoTooltip from './InfoTooltip'; // InfoTooltip aus dem gleichen Ordner
import '../../styles/CoinDetails.css'; // CSS
import SingleSelectSearch from '../../utils/SingleSelectSearch';
import { Pagination } from '../../utils/StyledButton';
import api from '../../services/api'; // <-- NEU: axios-Instanz statt fetch

function shortenString(str, maxLength = 14) {
  if (!str) return 'N/A';
  if (str.length <= maxLength) return str;
  const start = str.slice(0, 4);
  const end = str.slice(-4);
  return `${start}../..${end}`;
}

function getTrustScoreColor(score) {
  switch (score) {
    case 'green':
      return 'green';
    case 'yellow':
      return 'yellow';
    default:
      return 'gray';
  }
}

const ActiveMarkets = ({ coinId, vsCurrency, userLocale }) => {
  const [markets, setMarkets] = useState([]);
  const [availablePairs, setAvailablePairs] = useState([]);
  const [availableExchanges, setAvailableExchanges] = useState([]);
  const [selectedPair, setSelectedPair] = useState('');
  const [selectedExchange, setSelectedExchange] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const marketsPerPage = 10;

  // Tooltip-Zustand (nur bei Mobile relevant)
  const [showMobileTooltip, setShowMobileTooltip] = useState(null);
  const tooltipRefs = useRef({});

  /**
   * Lade-Funktion via useCallback, um die ESLint-Warnung
   * "exhaustive-deps" zu vermeiden.
   */
  const loadMarkets = useCallback(async () => {
    if (!coinId) return;
    setLoading(true);
    setError(null);

    try {
      // Query-Params vorbereiten
      const params = new URLSearchParams();
      params.set('id', coinId);
      params.set('page', currentPage);
      params.set('marketsPerPage', marketsPerPage);
      if (selectedPair.trim() !== '') {
        params.set('pair', selectedPair);
      }
      if (selectedExchange.trim() !== '') {
        params.set('exchange', selectedExchange);
      }

      // Axios-Request
      const { data } = await api.get(`/coin-tickers?${params.toString()}`);
      // "data" sollte etwas wie { tickers, availablePairs, ... } enthalten

      setMarkets(data.tickers || []);
      setAvailablePairs(data.availablePairs || []);
      setAvailableExchanges(data.availableExchanges || []);
      setCurrentPage(data.currentPage);
      setTotalPages(data.totalPages);

      setLoading(false);
    } catch (err) {
      console.error('Fehler beim Laden der Märkte:', err);
      setError(
        <Trans id="error.marketsLoad">
          Fehler beim Laden der Märkte. Bitte versuche es erneut.
        </Trans>
      );
      setLoading(false);
    }
  }, [
    coinId,
    currentPage,
    marketsPerPage,
    selectedPair,
    selectedExchange
    // vsCurrency oder userLocale werden hier anscheinend nicht für
    // /coin-tickers-Endpunkt genutzt, 
    // falls doch => in die Dependencies aufnehmen
  ]);

  /**
   * useEffect: ruft `loadMarkets()` auf, sobald sich Dependencies ändern.
   */
  useEffect(() => {
    loadMarkets();
  }, [loadMarkets]);

  /**
   * Tooltip-Logik fürs Schließen bei Klick außerhalb
   */
  useEffect(() => {
    function handleClickOutside(event) {
      if (showMobileTooltip === null) return;
      const currentTooltip = tooltipRefs.current[showMobileTooltip];
      if (currentTooltip && !currentTooltip.contains(event.target)) {
        setShowMobileTooltip(null);
      }
    }

    if (showMobileTooltip !== null) {
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('touchstart', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [showMobileTooltip]);

  /**
   * Filter zurücksetzen
   */
  const handleResetFilters = () => {
    setSelectedPair('');
    setSelectedExchange('');
    setCurrentPage(1);
  };

  // Abhängige Disables
  const exchangeDisabled = selectedPair.trim() !== '';
  const pairDisabled = selectedExchange.trim() !== '';

  // Memo-Options für Exchanges
  const exchangeOptions = useMemo(() => {
    const opts = availableExchanges.map((ex) => ({
      value: ex,
      label: ex.toUpperCase()
    }));
    opts.unshift({ value: '', label: 'Alle Exchanges' });
    return opts;
  }, [availableExchanges]);

  // Memo-Options für Paare
  const pairOptions = useMemo(() => {
    const opts = availablePairs.map((pair) => ({
      value: pair,
      label: shortenString(pair, 14)
    }));
    opts.unshift({ value: '', label: 'Alle Paare' });
    return opts;
  }, [availablePairs]);

  return (
    <div className="aktiveMarkets_container">
      {/* Header mit Tooltip */}
      <div className="aktiveMarkets-header" style={{ position: 'relative' }}>
        <h3>
          <Trans id="label.activeMarkets">Aktive Märkte</Trans>
        </h3>
        <div className="tooltip-wrapper" style={{ position: 'absolute', top: 0, right: 0 }}>
          <InfoTooltip iconSize={18}>
            <Trans id="tooltips.activeMarketsExplanation">
              Hier werden die Märkte angezeigt, an denen der Coin gehandelt wird. 
              Filtere nach Exchange oder Handelspaar, um gezielt zu suchen.
            </Trans>
          </InfoTooltip>
        </div>
      </div>

      {loading ? (
        <div className="loading-spinner-container">
          <div className="spinner"></div>
          <Trans id="message.loadingMarkets">Lade Märkte...</Trans>
        </div>
      ) : error ? (
        <div className="error-message">{error}</div>
      ) : markets.length === 0 ? (
        <div>
          <Trans id="message.noMarketsAvailable">Keine Märkte verfügbar.</Trans>
        </div>
      ) : (
        <>
          <div className="aktiveMarkets-filter-bar">
            <div style={{ marginRight: '10px' }}>
              <label>
                <Trans id="label.selectExchange">Exchange auswählen:</Trans>
              </label>
              <SingleSelectSearch
                options={exchangeOptions}
                selectedValue={selectedExchange}
                onChange={(val) => {
                  setSelectedExchange(val);
                  setCurrentPage(1);
                }}
                placeholder="Alle Exchanges"
                isDisabled={exchangeDisabled}
              />
            </div>

            <div style={{ marginRight: '10px' }}>
              <label>
                <Trans id="label.selectPair">Handelspaar auswählen:</Trans>
              </label>
              <SingleSelectSearch
                options={pairOptions}
                selectedValue={selectedPair}
                onChange={(val) => {
                  setSelectedPair(val);
                  setCurrentPage(1);
                }}
                placeholder="Alle Paare"
                isDisabled={pairDisabled}
              />
            </div>

            {(selectedPair || selectedExchange) && (
              <button
                onClick={handleResetFilters}
                className="aktiveMarkets-reset-button"
                title="Filter zurücksetzen"
              >
                <FaTimesCircle size={20} className="aktiveMarkets-reset-icon" />
              </button>
            )}
          </div>

          <div className="aktiveMarkets-info-text">
            {selectedPair && !selectedExchange && (
              <Trans id="message.pairFilterActive">
                Es werden nur noch Exchanges und Märkte angezeigt, bei denen das ausgewählte Paar verfügbar ist.
              </Trans>
            )}
            {selectedExchange && !selectedPair && (
              <Trans id="message.exchangeFilterActive">
                Es werden nur noch Paare und Märkte angezeigt, die auf der ausgewählten Exchange gehandelt werden.
              </Trans>
            )}
            {selectedPair && selectedExchange && (
              <Trans id="message.bothFiltersActive">
                Es werden nur noch Märkte angezeigt, die sowohl das ausgewählte Paar 
                als auch die ausgewählte Exchange erfüllen.
              </Trans>
            )}
          </div>

          <table className="aktiveMarkets_table">
            <thead>
              <tr>
                <th>
                  <Trans id="label.marketName">Markt</Trans>
                </th>
                <th>
                  <Trans id="label.tradingPair">Handelspaar</Trans>
                </th>
                <th>
                  <Trans id="label.volume24h">24h Volumen</Trans>
                </th>
                <th>
                  <Trans id="label.tradeUrl">Handel</Trans>
                </th>
                <th>
                  <Trans id="label.trustScore">Trust Score</Trans>
                </th>
              </tr>
            </thead>
            <tbody>
              {markets.map((ticker, index) => {
                const volume = formatLargeValue(
                  ticker.converted_volume.usd,
                  vsCurrency,
                  true,
                  false
                );
                const trustScore = ticker.trust_score || 'N/A';
                const originalPair = `${ticker.base}/${ticker.target}`;
                const shortenedPair = shortenString(originalPair);
                const isShortened = shortenedPair !== originalPair;
                const marketName = ticker.market.name;
                const marketLogo = ticker.market.logo;

                let trustElement;
                if (trustScore === 'green' || trustScore === 'yellow') {
                  const trustColor = getTrustScoreColor(trustScore);
                  trustElement = (
                    <span
                      className={`aktiveMarkets-trust-indicator aktiveMarkets-trust-${trustColor}`}
                      title={trustScore}
                    ></span>
                  );
                } else {
                  const iconColor = trustScore === 'red' ? 'red' : 'gray';
                  trustElement = (
                    <FaExclamationTriangle
                      className={`aktiveMarkets-trust-warn aktiveMarkets-trust-${iconColor}`}
                      title={trustScore}
                    />
                  );
                }

                return (
                  <tr key={index}>
                    <td data-label="Markt">
                      {marketLogo && (
                        <img
                          src={marketLogo}
                          alt={marketName}
                          className="aktiveMarkets-market-logo"
                        />
                      )}
                      {marketName}
                    </td>
                    <td
                      data-label="Handelspaar"
                      className="aktiveMarkets_pairCell"
                      title={originalPair}
                    >
                      {shortenedPair}
                      {isShortened && (
                        <FaInfoCircle
                          className="aktiveMarkets_infoIcon"
                          onClick={() =>
                            setShowMobileTooltip(showMobileTooltip === index ? null : index)
                          }
                        />
                      )}
                      {showMobileTooltip === index && (
                        <div
                          className="aktiveMarkets_mobileTooltip"
                          ref={(el) => (tooltipRefs.current[index] = el)}
                        >
                          {originalPair}
                        </div>
                      )}
                    </td>
                    <td data-label="24h Volumen">{volume}</td>
                    <td data-label="Handel">
                      {ticker.trade_url ? (
                        <a
                          href={ticker.trade_url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Trans id="label.trade">Trade</Trans>
                        </a>
                      ) : (
                        'N/A'
                      )}
                    </td>
                    <td data-label="Trust Score" className="aktiveMarkets-trust-cell">
                      {trustElement}{' '}
                      <span className="aktiveMarkets-trust-label">
                        {trustScore.charAt(0).toUpperCase() + trustScore.slice(1)}
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          {totalPages > 1 && (
            <div className="aktiveMarkets_paginationControls">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={setCurrentPage}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ActiveMarkets;
