// src/services/api.js
// FINALE VERSION (v31) - Überarbeitet für Klarheit, Konsistenz und Robustheit

import axios from 'axios';
import { toast } from 'react-toastify';
import { t } from '@lingui/macro'; // Importiere t für Übersetzungen

// --- Konstanten ---
const ACCESS_TOKEN_COOKIE = 'accessToken';
const REFRESH_TOKEN_COOKIE = 'refreshToken';
const GUEST_ID_COOKIE = 'guestId';
const LOGOUT_REASON_KEY = 'logoutReason';

// Backend Message Keys für kritische Fehler, die sofortigen Logout erfordern
const CRITICAL_ERROR_KEYS = [
  'error.tokenVersionMismatch',
  'error.userBanned',
  'error.accountLocked',
  'error.invalidOrExpiredRefreshToken', // Wichtig, wenn /refresh selbst 401/403 liefert
];

// Backend Message Keys, die auf Gast-Session-Probleme hindeuten (wenn kein Refresh-Token vorhanden ist)
const GUEST_SESSION_ISSUE_KEYS = [
  'error.invalidGuestSession',
  'error.noAuthOrGuestToken',
  'error.noAuthToken',
];

// API Endpunkte (um Tippfehler zu vermeiden)
const API_REFRESH_URL = '/api/refresh';
const API_INIT_GUEST_URL = '/api/init-guest';
const API_LOGOUT_URL = '/api/logout'; // Falls es einen expliziten Logout-Endpunkt gibt (optional hier)

// --- Globale Zustandsvariablen ---
let isRefreshingToken = false; // Flag, um parallele Refresh-Versuche zu verhindern
let hasForcedLogoutOccurred = false; // Verhindert mehrfache Logout-Ausführungen pro Seitenaufruf

// Event Listener, um den Logout-Flag bei jedem Neuladen der Seite zurückzusetzen
window.addEventListener('load', () => {
  console.log("[API Interceptor] Resetting hasForcedLogoutOccurred flag on page load.");
  hasForcedLogoutOccurred = false;
});

// --- Helper Functions ---

/**
 * Liest einen Cookie-Wert anhand seines Namens.
 * @param {string} name Name des Cookies.
 * @returns {string | null} Wert des Cookies oder null, falls nicht gefunden oder document nicht verfügbar.
 */
function getCookie(name) {
  if (typeof document === 'undefined' || !document.cookie) {
    return null;
  }
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
  return null;
}

/**
 * Löscht die Authentifizierungs- und Gast-Cookies.
 * Wird bei kritischen Fehlern oder fehlgeschlagenem Refresh aufgerufen.
 */
function clearAuthCookies() {
  console.log("[API Interceptor] Clearing auth cookies (accessToken, refreshToken, guestId).");
  const expiry = 'expires=Thu, 01 Jan 1970 00:00:00 UTC;';
  const path = 'path=/;';
  // Wichtig: secure; SameSite=Strict; hinzufügen, wenn über HTTPS verwendet!
  document.cookie = `${ACCESS_TOKEN_COOKIE}=; ${expiry} ${path}`;
  document.cookie = `${REFRESH_TOKEN_COOKIE}=; ${expiry} ${path}`;
  document.cookie = `${GUEST_ID_COOKIE}=; ${expiry} ${path}`;
}

/**
 * Zentralisierte Logout-Logik.
 * Speichert optional einen Grund, löscht Cookies, löst den Logout-Zustand im AuthContext aus
 * UND erzwingt einen Seiten-Reload, um Inkonsistenzen zu vermeiden.
 * @param {string} [messageKey] - Optionaler Key der Nachricht für den Grund des Logouts.
 */
function performLogout(messageKey) {
  // Verhindere Endlosschleife, falls wir schon auf /login sind
  if (window.location.pathname === '/login') {
    console.warn("[API Interceptor] performLogout called on /login page. Preventing potential loop.");
    // Sicherstellen, dass Cookies trotzdem gelöscht sind
    clearAuthCookies();
    // Versuchen, den State zu ändern, falls möglich (ohne Navigation)
    if (window.logout) {
      try {
        window.logout({ navigate: false });
      } catch (e) { /* Ignoriere Fehler hier */ }
    }
    return;
  }

  // Sicherstellen, dass dies nur einmal pro Seitenlebenszyklus vor dem Reload ausgeführt wird
  if (hasForcedLogoutOccurred) {
      console.log("[API Interceptor] Forced logout/reload sequence already initiated. Skipping duplicate call.");
      return;
  }
  hasForcedLogoutOccurred = true; // Setze Flag sofort

  console.warn(`[API Interceptor] Initiating forced logout sequence. Reason: '${messageKey || 'N/A'}'`);

  // Schritt 1: Grund speichern (für Anzeige nach Reload)
  if (messageKey) {
    try {
      sessionStorage.setItem(LOGOUT_REASON_KEY, messageKey);
      console.log(`[API Interceptor] Stored logoutReason in sessionStorage: ${messageKey}`);
    } catch (storageError) {
      console.error("[API Interceptor] Failed to set logoutReason in sessionStorage:", storageError);
    }
  }

  // Schritt 2: Cookies löschen
  clearAuthCookies();

  // Schritt 3: AuthContext State ändern (versuchen)
  // WICHTIG: window.logout wird erwartet, vom AuthContext bereitzustellen.
  // Es sollte idealerweise den lokalen Authentifizierungsstatus zurücksetzen.
  if (window.logout) {
    try {
      // navigate: false, da wir sowieso einen Reload erzwingen
      window.logout({ navigate: false });
      console.log("[API Interceptor] AuthContext state change triggered for logout (window.logout).");
    } catch (stateErr) {
      console.error("[API Interceptor] Error calling window.logout in performLogout:", stateErr);
    }
  } else {
    // Dies ist kritisch, wenn der AuthContext nicht erreichbar ist. Der Reload behebt es oft.
    console.error('[API Interceptor] window.logout was not found! Cannot update AuthContext state directly.');
  }

  // Schritt 4: Reload erzwingen (als letzter Ausweg zur Bereinigung)
  console.log("[API Interceptor] Forcing page reload now...");
  window.location.reload();
}


/**
 * Initialisiert eine Gast-Session durch Abrufen einer guestId vom Server.
 * Verwendet eine direkte axios-Instanz, um den Interceptor zu umgehen.
 * @returns {Promise<boolean>} True bei Erfolg, false bei Misserfolg.
 */
async function initGuestSession() {
  console.log("[API Interceptor] Attempting to initialize guest session via GET", API_INIT_GUEST_URL);
  try {
    // Direkte axios-Nutzung, um Interceptoren (insbesondere diesen hier) zu vermeiden
    const response = await axios.get(API_INIT_GUEST_URL, { withCredentials: true });
    console.log("[API Interceptor] initGuestSession response:", response.status, response.data);
    if (response.data?.guestId) {
      console.log("[API Interceptor] Guest session initialized successfully. GuestID:", response.data.guestId);
      // Das Setzen des Cookies übernimmt der Server per Set-Cookie Header
      return true;
    } else {
      console.warn("[API Interceptor] initGuestSession response missing guestId.", response.data);
      return false;
    }
  } catch (err) {
    console.error('[API Interceptor] Error during initGuestSession API call:', err.response?.status, err.response?.data || err.message);
    return false;
  }
}

/**
 * Erneuert das Access-Token mithilfe des Refresh-Tokens via POST /api/refresh.
 * Verwendet eine direkte axios-Instanz, um den Interceptor zu umgehen.
 * @returns {Promise<boolean>} True bei Erfolg (neues Access Token Cookie wurde vom Server gesetzt), false bei Misserfolg.
 */
async function refreshToken() {
  console.log("[API Interceptor] Attempting to refresh token via POST", API_REFRESH_URL);
  try {
      // Direkte axios-Nutzung, um Interceptoren zu vermeiden
      const response = await axios.post(API_REFRESH_URL, {}, { withCredentials: true });
      console.log("[API Interceptor] refreshToken response:", response.status, response.data);

      // Erfolg wird primär durch Status 200 signalisiert.
      // Das Backend sollte bei Erfolg das AccessToken-Cookie neu setzen.
      // Optional kann ein messageKey zur Bestätigung geprüft werden.
      if (response.status === 200 /* && response.data?.messageKey === 'success.tokenRefreshed' */) {
          console.log("[API Interceptor] Token refresh successful (Status 200). Expecting new accessToken cookie from server.");
          return true;
      } else {
          // Unerwarteter Erfolgscode oder Payload. Sicher ist sicher -> Fehler.
          console.error('[API Interceptor] Unexpected successful refresh response:', response.status, response.data);
          return false;
      }
  } catch (err) {
      // Fehler vom API call (z.B. 401, 403, 500) wird hier gefangen
      console.error('[API Interceptor] Error during refreshToken API call:', err.response?.status, err.response?.data?.messageKey || err.message);
      // Wenn der Refresh fehlschlägt (z.B. 401), wird dies hier als 'false' zurückgegeben.
      // Der Aufrufer (refreshTokenWithRetry) behandelt den Logout dann.
      return false;
  }
}

/**
 * Versucht, das Token mit Wiederholungen zu erneuern.
 * @param {number} [maxRetries=3] Maximale Anzahl an Versuchen.
 * @returns {Promise<boolean>} True bei Erfolg, false nach allen Versuchen.
 */
async function refreshTokenWithRetry(maxRetries = 3) {
  for (let attempt = 1; attempt <= maxRetries; attempt++) {
    console.log(`[API Interceptor] Refresh attempt ${attempt} of ${maxRetries}`);
    const refreshed = await refreshToken(); // Ruft die obige Funktion auf
    if (refreshed) {
      console.log(`[API Interceptor] Refresh attempt ${attempt} successful.`);
      return true; // Erfolg!
    }
    // Wenn refreshToken() false zurückgibt (API Fehler oder unerwartete Antwort)
    if (attempt < maxRetries) {
      const delay = 500 * attempt; // Einfaches exponentielles Backoff
      console.log(`[API Interceptor] Refresh attempt ${attempt} failed. Waiting ${delay}ms before retry.`);
      await new Promise(resolve => setTimeout(resolve, delay));
    }
  }
  console.warn(`[API Interceptor] Token refresh failed definitively after ${maxRetries} attempts.`);
  return false; // Nach allen Versuchen fehlgeschlagen
}

// --- Globale Axios-Instanz mit Basis-URL und Credentials ---
const api = axios.create({
  baseURL: '/api', // Standard-Basis-URL für alle Anfragen mit dieser Instanz
  withCredentials: true, // Wichtig für Cookies (Session, CSRF, Tokens)
  headers: {
    'X-Requested-With': 'XMLHttpRequest', // Standard-Header für viele Frameworks zur Identifizierung von AJAX-Requests
  },
  // Timeout hinzufügen?
  // timeout: 10000, // z.B. 10 Sekunden
});

// --- Axios Request Interceptor ---
// Hauptsächlich für Logging oder Hinzufügen von Headern (z.B. CSRF-Token, falls nicht per Cookie)
api.interceptors.request.use(
  (config) => {
    // Optional: Füge hier dynamische Header hinzu (z.B. CSRF Token aus Meta-Tag)
    // const csrfToken = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content');
    // if (csrfToken) {
    //   config.headers['X-CSRF-TOKEN'] = csrfToken;
    // }
    console.debug(`[API Interceptor] >> Request: ${config.method.toUpperCase()} ${config.url}`, config.data || ''); // Weniger ausführlich
    return config;
  },
  (error) => {
    console.error("[API Interceptor] !! Request Error:", error);
    return Promise.reject(error);
  }
);

// --- Axios Response Interceptor ---
// Behandelt primär Fehler, insbesondere Authentifizierung (401/403) und Rate Limits (429)
api.interceptors.response.use(
  (response) => {
    // Erfolgreiche Antworten einfach durchlassen (dieser Teil bleibt gleich)
    console.debug(`[API Interceptor] << Response: ${response.status} from ${response.config.method.toUpperCase()} ${response.config.url}`);
    return response;
  },

  // ERSETZE DEINE BISHERIGE FEHLERFUNKTION KOMPLETT MIT DIESER HIER:
  async (error) => {
    const { config: originalRequest, response } = error;

    // 2. Status und Daten extrahieren (bleibt gleich)
    const { status, data } = response;
    const messageKey = data?.messageKey;
    console.warn(`[API Interceptor] !! Response Error: Status ${status} for ${originalRequest?.method?.toUpperCase()} ${originalRequest?.url}. MessageKey: ${messageKey || 'N/A'}`);

    // 3. Fehler von Refresh/Logout ignorieren (bleibt gleich)
    if (originalRequest?.url === API_REFRESH_URL || originalRequest?.url === API_LOGOUT_URL) {
        console.log(`[API Interceptor] Ignoring error from ${originalRequest.url} itself.`);
        return Promise.reject(error);
    }

    // 4. Rate Limit / Captcha (429) behandeln (bleibt gleich)
    if (status === 429) {
      const captchaRequiredHeader = response.headers?.['x-captcha-required'];
      if (captchaRequiredHeader === 'true') {
        console.warn("[API Interceptor] Captcha required (429). Dispatching 'captchaRequired' event.");
        window.dispatchEvent(new CustomEvent('captchaRequired', { detail: true }));
      } else {
        console.warn("[API Interceptor] Rate limit exceeded (429). Dispatching 'rateLimitExceeded' event.");
        window.dispatchEvent(new CustomEvent('rateLimitExceeded', { detail: true }));
        toast.warn(t({ id: 'api.error.rateLimit', message: 'Zu viele Anfragen. Bitte warten Sie einen Moment.' }), { position: 'bottom-right' });
      }
      return Promise.reject(error);
    }

    // 5. Authentifizierungsfehler (401) oder Berechtigungsfehler (403) behandeln (HIER DIE ÄNDERUNGEN)
    if (status === 401) { // NUR 401 hier behandeln
        console.log(`[API Interceptor] Handling 401. Analyzing reason... MessageKey: ${messageKey}`);

        // 5a. Prüfen auf KRITISCHEN Fehler (z.B. falsche Token-Version, User gesperrt)
        if (CRITICAL_ERROR_KEYS.includes(messageKey)) {
            console.error(`[API Interceptor] Critical auth error (${messageKey}) from 401. Forcing logout!`);
            performLogout(messageKey); // Löst Logout und Reload aus
            return Promise.reject(error); // WICHTIG: Beendet die Funktion hier
        }

        // 5b. Wenn nicht kritisch -> IMMER Refresh versuchen (wegen HttpOnly Cookie)
        console.log(`[API Interceptor] Received non-critical 401. Attempting token refresh unconditionally...`);

        if (isRefreshingToken) {
            // Warten, wenn bereits ein Refresh läuft
            console.log("[API Interceptor] Refresh already in progress. Waiting...");
            // WICHTIG: Die Promise des Warte-Vorgangs zurückgeben
            return new Promise((resolve, reject) => {
                setTimeout(async () => {
                    console.log("[API Interceptor] Retrying original request after waiting for refresh:", originalRequest.url);
                    try {
                        originalRequest._waitedForRefresh = true;
                        const response = await api.request(originalRequest);
                        resolve(response); // Erfolgreichen Retry auflösen
                    } catch (retryError) {
                        console.error("[API Interceptor] Error retrying request after waiting:", retryError);
                        reject(retryError); // Fehler beim Retry weitergeben
                    }
                }, 1500);
            });
        } else {
            // Neuen Refresh-Prozess starten
            console.log("[API Interceptor] Starting token refresh process...");
            isRefreshingToken = true;
            window.dispatchEvent(new CustomEvent('tokenRefreshStarted'));

            // WICHTIG: Das Ergebnis des Refresh-Versuchs (als Promise) zurückgeben
            // Wir nutzen eine async IIFE, um das Promise korrekt zurückzugeben.
            return (async () => {
                try {
                    const refreshed = await refreshTokenWithRetry(3); // Ruft POST /api/refresh auf

                    if (refreshed) { // Refresh war erfolgreich (Backend gab 200 OK)
                        console.log("[API Interceptor] Refresh successful. Retrying original request:", originalRequest.url);
                        originalRequest._isRetryRequest = true;
                        if (window.fetchUserProfileAgain) {
                            console.log("[API Interceptor] Triggering AuthContext.fetchUserProfileAgain...");
                            setTimeout(() => window.fetchUserProfileAgain(), 100);
                        }
                        // Ursprünglichen Request wiederholen UND dessen Promise zurückgeben
                        return api.request(originalRequest);
                    } else { // Refresh ist fehlgeschlagen (Backend gab z.B. 401/403 für /api/refresh)
                        console.error("[API Interceptor] Token refresh failed definitively (refreshToken likely invalid). Forcing logout.");
                        performLogout('error.refreshFailed'); // Logout auslösen
                        // Den ursprünglichen 401-Fehler zurückweisen
                        return Promise.reject(error);
                    }
                } catch (refreshProcessError) { // Unerwarteter Fehler während des Refresh-Ablaufs
                    console.error("[API Interceptor] Unexpected error during refresh process execution:", refreshProcessError);
                    performLogout('error.refreshError'); // Sicherheitshalber Logout
                    // Den Fehler vom Refresh-Prozess zurückweisen
                    return Promise.reject(refreshProcessError);
                } finally {
                    isRefreshingToken = false;
                    window.dispatchEvent(new CustomEvent('tokenRefreshEnded'));
                }
            })(); // Die async IIFE sofort ausführen und ihr Promise zurückgeben
        }
        // Wegen der 'return'-Statements oben sollte die Funktion hier nicht mehr ankommen für 401.

    } else if (status === 403) { // Separater Block nur für 403 (Permission Denied)
        console.log(`[API Interceptor] Handling 403. Analyzing reason... MessageKey: ${messageKey}`);

        // Optional: Prüfen, ob kritische Fehler auch mit 403 kommen können
        if (CRITICAL_ERROR_KEYS.includes(messageKey)) {
             console.error(`[API Interceptor] Critical auth error (${messageKey}) with status 403. Forcing logout!`);
             performLogout(messageKey);
             return Promise.reject(error);
        }

        // Standard 403 Behandlung
        console.warn(`[API Interceptor] Status 403 (Permission Denied). Rejecting promise.`);
        toast.error(t({ id: 'api.error.permissionDenied', message: 'Keine Berechtigung für diese Aktion.' }), { position: 'bottom-right' });
        return Promise.reject(error); // Fehler einfach weitergeben

    } // Ende der 401/403 Behandlung

    // 6. Andere Fehler (500, 404 etc.) durchlassen (bleibt gleich)
    console.log(`[API Interceptor] Passing non-auth/rate-limit error (Status ${status}) through.`);
    if (status >= 500) {
        toast.error(t({ id: 'api.error.serverError', message: 'Ein interner Serverfehler ist aufgetreten.' }), { position: 'bottom-right' });
    }
    return Promise.reject(error); // Fehler an Aufrufer weitergeben

  } // Ende der Fehlerbehandlungsfunktion
); // Ende api.interceptors.response.use

// --- Exports ---
// Exportiere die konfigurierte Axios-Instanz als Standard
export default api;

// Exportiere spezifische Funktionen, falls sie extern benötigt werden (z.B. im AuthContext für einen manuellen Refresh)
export { initGuestSession, refreshToken, refreshTokenWithRetry, performLogout };