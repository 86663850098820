// src/components/admin/AdminSubmenu.js

import React from 'react';
import { NavLink } from 'react-router-dom';
import { Trans } from '@lingui/macro';

const AdminSubmenu = () => {
  return (
    <div className="admin-submenu">
      <NavLink
        to="/admin"
        end
        className={({ isActive }) =>
          isActive ? 'admin-submenu-link active' : 'admin-submenu-link'
        }
      >
        <Trans id="submenu.admin.userManagement">Benutzerverwaltung</Trans>
      </NavLink>
      <NavLink
        to="/admin-notifications"
        className={({ isActive }) =>
          isActive ? 'admin-submenu-link active' : 'admin-submenu-link'
        }
      >
        <Trans id="submenu.admin.notifications">Benachrichtigungen</Trans>
      </NavLink>
      <NavLink
        to="/admin-stats"
        className={({ isActive }) =>
          isActive ? 'admin-submenu-link active' : 'admin-submenu-link'
        }
      >
        <Trans id="submenu.admin.stats">Statistiken</Trans>
      </NavLink>
      <NavLink
        to="/admin-config"
        className={({ isActive }) =>
          isActive ? 'admin-submenu-link active' : 'admin-submenu-link'
        }
      >
        <Trans id="submenu.admin.config">Konfiguration</Trans>
      </NavLink>
      <NavLink
        to="/admin-contact-messages"
        className={({ isActive }) =>
          isActive ? 'admin-submenu-link active' : 'admin-submenu-link'
        }
      >
        <Trans id="submenu.admin.contactMessages">Kontaktanfragen</Trans>
      </NavLink>
    </div>
  );
};

export default AdminSubmenu;
