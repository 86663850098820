// /frontend/src/utils/SingleSelectNoSearch.jsx
import React, { useMemo } from 'react';
import Select from 'react-select';

function SingleSelectNoSearch({
  options,
  selectedValue,
  onChange,
  placeholder = 'Bitte wählen...',
  minWidth: propMinWidth, // Neuer optionaler Prop
}) {
  // 1) Optionen normalisieren: Sind es Strings oder { value, label }?
  const normalizedOptions = useMemo(() => {
    if (!options.length) return [];
    if (typeof options[0] === 'object') {
      return options;
    }
    return options.map((str) => ({
      value: str,
      label: String(str),
    }));
  }, [options]);

  // 2) Aktuell ausgewählten Wert finden
  const currentValueObj = useMemo(() => {
    return normalizedOptions.find((opt) => opt.value === selectedValue) || null;
  }, [normalizedOptions, selectedValue]);

  // 3) Mindestbreite ermitteln:
  // Falls propMinWidth angegeben, verwenden wir diesen.
  // Ansonsten versuchen wir, anhand der längsten String-Option zu messen.
  const computedMinWidth = useMemo(() => {
    if (propMinWidth) {
      return propMinWidth;
    }
    let longestText = '';
    normalizedOptions.forEach((opt) => {
      if (typeof opt.label === 'string' && opt.label.length > longestText.length) {
        longestText = opt.label;
      }
    });
    if (longestText === '') return 150; // Fallback
    if (typeof document !== 'undefined') {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      context.font = '14px sans-serif';
      const textWidth = context.measureText(longestText).width;
      return textWidth + 40; // Puffer
    }
    return 150;
  }, [normalizedOptions, propMinWidth]);

  // 4) Custom Styles – weniger Padding, kleinere Schrift
  // Da diese Version kein Suchfeld benötigt, setzen wir isSearchable auf false.
  const customStyles = {
    container: (base) => ({
      ...base,
      minWidth: computedMinWidth,
      backgroundColor: 'transparent',
    }),
    control: (base, state) => ({
      ...base,
      minWidth: computedMinWidth,
      backgroundColor: 'transparent',
      border: '0.5px solid var(--primary-color)',
      boxShadow: state.isFocused ? '0 0 4px var(--primary-color)' : 'none',
      minHeight: '30px',
      cursor: 'pointer',
      transition: 'box-shadow 0.2s ease',
      '&:hover': {
        boxShadow: '0 0 4px var(--primary-color)',
      },
    }),
    valueContainer: (base) => ({
      ...base,
      backgroundColor: 'transparent',
      padding: '0 4px', // weniger Padding
      fontSize: '0.9rem',
    }),
    input: (base) => ({
      ...base,
      backgroundColor: 'transparent',
      color: 'var(--text-color)',
      caretColor: 'var(--text-color)',
      margin: 0,
      padding: 0,
      fontSize: '0.9rem',
    }),
    singleValue: (base) => ({
      ...base,
      color: 'var(--text-color)',
      fontSize: '0.9rem',
    }),
    placeholder: (base) => ({
      ...base,
      color: 'var(--text-color)',
      opacity: 0.6,
      fontSize: '0.9rem',
    }),
    menu: (base) => ({
      ...base,
      backgroundColor: 'var(--background-color2)',
      border: '0.5px solid var(--secondary-bg-color)',
      zIndex: 9999,
      whiteSpace: 'nowrap',
      fontSize: '0.9rem',
    }),
    option: (base, state) => ({
      ...base,
      whiteSpace: 'nowrap',
      backgroundColor: state.isSelected
        ? 'var(--btn-primary-bg)'
        : state.isFocused
        ? 'var(--secondary-bg-color)'
        : 'transparent',
      color: state.isSelected ? '#fff' : 'var(--text-color)',
      cursor: 'pointer',
      fontSize: '0.9rem',
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      color: state.isFocused ? 'var(--primary-color)' : 'var(--text-color)',
      '&:hover': {
        color: 'var(--primary-color)',
      },
    }),
    indicatorSeparator: (base) => ({
      ...base,
      backgroundColor: 'transparent',
    }),
  };

  return (
    <Select
      options={normalizedOptions}
      value={currentValueObj}
      onChange={(sel) => onChange(sel.value)}
      isSearchable={false}
      styles={customStyles}
      menuPlacement="auto"
      placeholder={placeholder}
    />
  );
}

export default SingleSelectNoSearch;
