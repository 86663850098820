// src/components/LoadingOverlay.js
import React from 'react';
import { Trans } from '@lingui/macro';

const LoadingOverlay = () => {
  return (
    <div style={styles.overlay}>
      <div style={styles.content}>
        <h1>
          <Trans id="loadingAuth.title">Lade...</Trans>
        </h1>
        <p>
          <Trans id="loadingAuth.message">
            Bitte warten, wir überprüfen deinen Login-Status.
          </Trans>
        </p>
      </div>
    </div>
  );
};

const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.85)',
    color: '#fff',
    zIndex: 9999,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    padding: '20px',
  },
  content: {
    maxWidth: '500px',
    margin: '0 auto',
  },
};

export default LoadingOverlay;
