// src/components/SettingsOverlay.jsx

import React, { useEffect, useState } from 'react';
import { Trans } from '@lingui/macro';
import { FaTimes } from 'react-icons/fa';

// Importiere unsere SingleSelectNoSearch-Komponente
import SingleSelectNoSearch from '../utils/SingleSelectNoSearch';
// Beibehalten, falls du den CoinSelektor für Währung nutzt:
import CoinSelektor from '../utils/CoinSelektor';

// Neuen StyledButton importieren:
import { StyledButton } from '../utils/StyledButton';

const SettingsOverlay = ({ styleType, onClose }) => {
  const [language, setLanguage] = useState('de');
  const [theme, setTheme] = useState('light');
  const [vsCurrency, setVsCurrency] = useState('usd');

  // Beim ersten Rendern die gespeicherten Werte laden
  useEffect(() => {
    const storedLang = localStorage.getItem('language') || 'de';
    const storedTheme = localStorage.getItem('theme') || 'dark';
    const storedCurrency = localStorage.getItem('vsCurrency') || 'usd';

    setLanguage(storedLang);
    setTheme(storedTheme);
    setVsCurrency(storedCurrency);
  }, []);

  // Theme sofort umschalten bei Änderung
  useEffect(() => {
    if (theme === 'dark') {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }
  }, [theme]);

  // Callbacks
  const handleLanguageChange = (lang) => {
    setLanguage(lang);
  };

  const handleThemeChange = (t) => {
    setTheme(t);
  };

  // Speichern: in localStorage, Cookie setzen und Reload
  const handleSave = () => {
    localStorage.setItem('language', language);
    localStorage.setItem('theme', theme);
    localStorage.setItem('vsCurrency', vsCurrency);

    document.cookie = `lang=${language}; path=/; max-age=31536000; SameSite=Lax`;
    window.location.reload();
  };

  if (styleType === 'dropdown') {
    return (
      <div
        className="settings_dropdown-panel"
        onMouseDown={(e) => e.stopPropagation()}
        style={{
          position: 'absolute',
          right: '0px',
          top: '60px',
          zIndex: 9999,
          width: '300px',
          border: '1px solid var(--secondary-bg-color)',
          borderRadius: '8px',
          backgroundColor: 'var(--background-color2)',
          padding: '10px',
        }}
      >
        {/* Schließen-Icon */}
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <FaTimes
            style={{ cursor: 'pointer', marginBottom: '5px' }}
            onClick={onClose}
          />
        </div>

        <h4 style={{ marginTop: 0, marginBottom: '10px' }}>
          <Trans id="settings.title">Einstellungen</Trans>
        </h4>

        {/* Sprache */}
        <div className="settings_dropdown-item">
          <label>
            <Trans id="settings.languageLabel">Sprache:</Trans>
          </label>
          <SingleSelectNoSearch
            options={[
              { value: 'de', label: 'Deutsch (DE)' },
              { value: 'en', label: 'Englisch (EN)' },
            ]}
            selectedValue={language}
            onChange={handleLanguageChange}
            placeholder="Sprache auswählen"
          />
        </div>

        {/* Theme */}
        <div className="settings_dropdown-item" style={{ marginTop: '10px' }}>
          <label>
            <Trans id="settings.themeLabel">Theme:</Trans>
          </label>
          <SingleSelectNoSearch
            options={[
              { value: 'light', label: 'White (disabled)', isDisabled: true },
              { value: 'dark', label: 'Dark' },
            ]}
            selectedValue={theme}
            onChange={handleThemeChange}
            placeholder="Theme auswählen"
          />
        </div>

        {/* Währung */}
        <div className="settings_dropdown-item" style={{ marginTop: '10px' }}>
          <label>
            <Trans id="settings.currencyLabel">Währung:</Trans>
          </label>
          <CoinSelektor
            selectedCurrency={vsCurrency}
            onChange={(val) => setVsCurrency(val)}
          />
        </div>

        {/* Hier wird der neue StyledButton verwendet */}
        <StyledButton
          type="submit"
          onClick={handleSave}
          translationKey="button.save"
          defaultMessage="Speichern"
          style={{ marginTop: '15px' }}
        />
      </div>
    );
  }

  return null;
};

export default SettingsOverlay;
