// src/components/CategoryList.js
import React, { useState, useEffect, useRef } from 'react';
import { Trans } from '@lingui/macro';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import '../styles/CoinList.css';
import CategoryRow from './CategoryRow';
import SingleSelectNoSearch from '../utils/SingleSelectNoSearch';
import { Pagination } from '../utils/StyledButton';

import api from '../services/api'; // <-- Neu: Zentrales Axios-Objekt

const sortOptions = [
  {
    value: 'market_cap_desc',
    label: <Trans id="sort.market_cap_desc">Marktkapitalisierung ↓</Trans>,
  },
  {
    value: 'market_cap_asc',
    label: <Trans id="sort.market_cap_asc">Marktkapitalisierung ↑</Trans>,
  },
  {
    value: 'name_desc',
    label: <Trans id="sort.name_desc">Name Z-A</Trans>,
  },
  {
    value: 'name_asc',
    label: <Trans id="sort.name_asc">Name A-Z</Trans>,
  },
  {
    value: 'market_cap_change_24h_desc',
    label: <Trans id="sort.market_cap_change_24h_desc">24h % ↓</Trans>,
  },
  {
    value: 'market_cap_change_24h_asc',
    label: <Trans id="sort.market_cap_change_24h_asc">24h % ↑</Trans>,
  },
];

const catsPerPageOptions = [
  { value: '25', label: '25' },
  { value: '50', label: '50' },
  { value: '100', label: '100' },
  { value: '250', label: '250' },
];

const CategoryList = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Sortierung (Backend)
  const [sortOrder, setSortOrder] = useState('market_cap_desc');

  // Frontend-Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [catsPerPage, setCatsPerPage] = useState(50);

  // Dynamische Währung – Standard: 'usd'
  const [vsCurrency, setVsCurrency] = useState(localStorage.getItem('vsCurrency') || 'usd');

  // Umrechnungsfaktor (USD -> vsCurrency)
  const [conversionFactor, setConversionFactor] = useState(1);
  useEffect(() => {
    if (vsCurrency.toLowerCase() === 'usd') {
      setConversionFactor(1);
    } else {
      (async () => {
        try {
          const { data } = await api.get('/exchange-rates');
          const rates = data.rates;
          if (rates && rates[vsCurrency.toLowerCase()] && rates['usd']) {
            const factor =
              rates[vsCurrency.toLowerCase()].value / rates['usd'].value;
            setConversionFactor(factor);
          } else {
            setConversionFactor(1);
          }
        } catch (err) {
          console.error(err);
          setConversionFactor(1);
        }
      })();
    }
  }, [vsCurrency]);

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const isFirstLoad = useRef(true);

  // Beim ersten Rendern: Parameter aus der URL übernehmen, wenn vorhanden
  useEffect(() => {
    const sortFromUrl = searchParams.get('sort');
    const pageFromUrl = parseInt(searchParams.get('page'), 10);
    const perPageFromUrl = parseInt(searchParams.get('perPage'), 10);

    if (sortFromUrl) {
      setSortOrder(sortFromUrl);
    }
    if (pageFromUrl) {
      setCurrentPage(pageFromUrl);
    }
    if (perPageFromUrl) {
      setCatsPerPage(perPageFromUrl);
    }
  }, []); // nur einmal beim Mount

  // URL aktualisieren, wenn sich die Parameter ändern (nicht beim ersten Rendern)
  useEffect(() => {
    if (isFirstLoad.current) {
      isFirstLoad.current = false;
      return;
    }
    setSearchParams({
      sort: sortOrder,
      page: currentPage,
      perPage: catsPerPage,
    });
  }, [sortOrder, currentPage, catsPerPage, setSearchParams]);

  // Fetch-Funktion für Kategorien
  const fetchCategories = async (orderParam) => {
    setLoading(true);
    setError(null);
    try {
      // GET /coins-categories-with-market-data?order=...
      const { data } = await api.get('/coins-categories-with-market-data', {
        params: { order: orderParam },
      });
      setCategories(data);
    } catch (err) {
      console.error('fetchCategories error:', err);
      setError(err.message || 'Unknown Error');
    } finally {
      setLoading(false);
    }
  };

  // Initialer Load + bei Änderung der Sortierung
  useEffect(() => {
    fetchCategories(sortOrder);
  }, [sortOrder]);

  // Neu: Bei Sichtbarkeitswechsel (z.B. Tab-Wechsel) neu laden
  useEffect(() => {
    function handleVisibilityChange() {
      if (!document.hidden) {
        fetchCategories(sortOrder);
      }
    }
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [sortOrder]);

  // Bei Sortierwechsel => Seite zurücksetzen
  const handleSortChange = (newOrder) => {
    setSortOrder(newOrder);
    setCurrentPage(1);
  };

  const indexOfLastCat = currentPage * catsPerPage;
  const indexOfFirstCat = indexOfLastCat - catsPerPage;
  const visibleCategories = categories.slice(indexOfFirstCat, indexOfLastCat);

  const totalCategories = categories.length;
  const totalPages = Math.ceil(totalCategories / catsPerPage);

  // parseInt, da SingleSelectNoSearch als String zurückgibt
  const handleCatsPerPageChange = (newVal) => {
    const numericVal = parseInt(newVal, 10);
    setCatsPerPage(numericVal);
    setCurrentPage(1);
  };

  // Klick auf eine Zeile => navigate
  const handleRowClick = (catId) => {
    if (catId) {
      navigate(`/coinlist?category=${catId}`);
    }
  };

  const isNameSort = sortOrder.includes('name_');

  return (
    <SkeletonTheme baseColor="#ccc" highlightColor="#eee">
      <div className="coin-list-container">
        <h2 className="coin-list-title">
          <Trans id="title.categories">Kategorien</Trans>
        </h2>

        <p className="intro-text">
          <Trans id="categories.intro">
            Unsere Kategorien bieten dir einen umfassenden Einblick in den Kryptomarkt. Tauche gezielt in verschiedene Segmente ein – von großen Marktkapitalisierungen bis hin zu speziellen Nischen. Wähle eine Kategorie, um alle Coins in diesem Segment zu entdecken und detaillierte Marktdaten auf einen Blick zu erhalten.
          </Trans>
        </p>

        <div className="coin-list-controls">
          {/* Sortier-Dropdown */}
          <div className="coins-per-page">
            <label>
              {loading ? <Skeleton width={100} /> : <Trans id="label.sortBy">Sortieren nach:</Trans>}
            </label>
            {loading ? (
              <Skeleton width={120} height={30} />
            ) : (
              <SingleSelectNoSearch
                options={sortOptions}
                selectedValue={sortOrder}
                onChange={handleSortChange}
                placeholder=""
                minWidth={215}
              />
            )}
          </div>

          {/* Kategorien pro Seite */}
          <div className="coins-per-page">
            <label>
              {loading ? <Skeleton width={130} /> : <Trans id="label.entriesPerPage">Einträge pro Seite:</Trans>}
            </label>
            {loading ? (
              <Skeleton width={120} height={30} />
            ) : (
              <SingleSelectNoSearch
                options={catsPerPageOptions}
                selectedValue={String(catsPerPage)}
                onChange={handleCatsPerPageChange}
                placeholder="Pro Seite"
              />
            )}
          </div>
        </div>

        {error && <div className="error-message">{error}</div>}

        {!error && (
          <>
            <table className="coin-list-table">
              <thead>
                <tr>
                  {loading
                    ? Array.from({ length: isNameSort ? 4 : 5 }).map((_, i) => (
                        <th key={i}>
                          <Skeleton width="100%" />
                        </th>
                      ))
                    : (
                      <>
                        {!isNameSort && <th><Trans id="label.rank">Rank</Trans></th>}
                        <th><Trans id="label.name">Name</Trans></th>
                        <th><Trans id="label.marketCap">Marktkapitalisierung</Trans></th>
                        <th><Trans id="label.24hVolume">24h Volumen</Trans></th>
                        <th><Trans id="label.topCoins2">Top Coins</Trans></th>
                      </>
                    )}
                </tr>
              </thead>
              <tbody>
                {loading
                  ? Array.from({ length: catsPerPage }).map((_, i) => (
                      <CategoryRow key={`skeleton-${i}`} loading={true} isNameSort={isNameSort} />
                    ))
                  : visibleCategories.map((cat, index) => (
                      <CategoryRow
                        key={cat.id || index}
                        cat={cat}
                        loading={false}
                        rank={isNameSort ? 0 : indexOfFirstCat + index + 1}
                        isNameSort={isNameSort}
                        onRowClick={handleRowClick}
                        vsCurrency={vsCurrency}
                        conversionFactor={conversionFactor}
                      />
                    ))}
              </tbody>
            </table>

            {(!loading && categories.length > 0) && (
              <div className="pagination-controls bottom-pagination-controls">
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={setCurrentPage}
                />
              </div>
            )}
          </>
        )}
      </div>
    </SkeletonTheme>
  );
};

export default CategoryList;
