// src/components/dashboard/FearAndGreedMini.js
import React from 'react';
import { Trans } from '@lingui/macro';
import { useGlobalData } from '../../hooks/useGlobalData';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { formatDate, formatFutureDate } from '../../utils/formatDate';



const isMobile = window.innerWidth < 768;
// oder du nimmst useMediaQuery / React Hook

const circleSize = isMobile ? 100 : 150;
// am Handy 100px, Desktop 150px

function getGaugeColor(value) {
  if (value <= 24) return '#d9534f'; // Extreme Fear
  if (value <= 49) return '#f0ad4e'; // Fear
  if (value <= 74) return '#ffeb3b'; // Neutral
  if (value <= 89) return '#8bc34a'; // Greed
  return '#5cb85c';                  // Extreme Greed
}

// NEU: Hilfsfunktion für Klassifizierung
function translateClassification(classification) {
  switch (classification) {
    case 'Extreme Fear':
      return <Trans id="fearGreedIndex.legendExtremeFear">Extreme Fear</Trans>;
    case 'Fear':
      return <Trans id="fearGreedIndex.legendFear">Fear</Trans>;
    case 'Neutral':
      return <Trans id="fearGreedIndex.legendNeutral">Neutral</Trans>;
    case 'Greed':
      return <Trans id="fearGreedIndex.legendGreed">Greed</Trans>;
    case 'Extreme Greed':
      return <Trans id="fearGreedIndex.legendExtremeGreed">Extreme Greed</Trans>;
    default:
      // Fallback
      return classification || '';
  }
}

const FearAndGreedMini = () => {
  const {
    fearGreedLoading,
    fearGreedError,
    fearGreedCurrentValue,
  } = useGlobalData();

  if (fearGreedLoading) {
    return (
      <div className="fear-greed-mini-widget">
        <Trans id="fearGreedMini.loading">Loading Fear &amp; Greed...</Trans>
      </div>
    );
  }
  if (fearGreedError) {
    return (
      <div className="fear-greed-mini-widget fear-greed-mini-error">
        {fearGreedError}
      </div>
    );
  }
  if (!fearGreedCurrentValue) {
    return null;
  }

  // Wert + Klassifizierung
  const numericValue = Number(fearGreedCurrentValue.value) || 0;
  const classification = fearGreedCurrentValue.value_classification;

  // Letztes Update / Nächstes Update
  let lastUpdateText = null;
  let nextUpdateText = null;

  if (fearGreedCurrentValue.timestamp) {
    const timestampSec = Number(fearGreedCurrentValue.timestamp);
    if (!isNaN(timestampSec) && timestampSec > 0) {
      const isoDate = new Date(timestampSec * 1000).toISOString();
      lastUpdateText = formatDate(isoDate);
    }
  }

  if (fearGreedCurrentValue.time_until_update) {
    const timeUntilSec = Number(fearGreedCurrentValue.time_until_update);
    if (!isNaN(timeUntilSec) && timeUntilSec > 0) {
      const futureIso = new Date(Date.now() + timeUntilSec * 1000).toISOString();
      nextUpdateText = formatFutureDate(futureIso);
    }
  }

  return (
    <div
      className="fear-greed-mini-widget"
      style={{ textAlign: 'center' }}
    >
      <h4>
        <Trans id="fearGreedIndex.title">Fear &amp; Greed</Trans>
      </h4>

      {/* Größerer Kreis, z. B. 120px */}
      <div style={{ width: circleSize, margin: '1rem auto' }}>
        <CircularProgressbar
          value={numericValue}
          maxValue={100}
          text={`${numericValue}`}
          styles={buildStyles({
            pathColor: getGaugeColor(numericValue),
            textColor: '#fff',
            trailColor: '#333',
          })}
        />
      </div>

      <p>
        {/* Hier benutzen wir jetzt unsere Hilfsfunktion */}
        {translateClassification(classification)}
      </p>

    </div>
  );
};

export default FearAndGreedMini;
