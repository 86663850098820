// src/components/UserInfoModal.js
import React from 'react';
import { Trans } from '@lingui/macro';
import '../styles/UserInfoModal.css';
import { StyledButton } from '../utils/StyledButton'; // Import der StyledButton-Komponente

const UserInfoModal = ({ onConfirm, children }) => {
  return (
    <div className="user-info-modal-overlay">
      <div className="user-info-modal-content">
        {children}
        <StyledButton 
          onClick={onConfirm}
          variant="default" // oder eine andere Variante, die dir passend erscheint
        >
          <Trans id="modal.development.button">Verstanden</Trans>
        </StyledButton>
      </div>
    </div>
  );
};

export default UserInfoModal;
