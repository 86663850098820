// src/contexts/AuthContext.js - KORRIGIERT

// Entferne useMemo und initGuestSession aus dem Import
import React, { createContext, useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
// Importiere nur refreshToken (initGuestSession wird hier nicht gebraucht)
import api, { refreshToken } from '../services/api';
import { debounce } from 'lodash';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [isAuthLoading, setIsAuthLoading] = useState(true);
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [authCheckDone, setAuthCheckDone] = useState(false);
    const [isAuthReady, setIsAuthReady] = useState(false); // Wird jetzt bereitgestellt

    const navigate = useNavigate();

    // Hilfsfunktion, um fetchUserProfile global verfügbar zu machen (für Interceptor)
    const triggerFetchUserProfile = useCallback(() => {
        // Diese Funktion ruft die unten definierte, gedrosselte Funktion auf
        // Wir verwenden eine separate Funktion, um Zirkelbezüge in den deps zu vermeiden
        // und um sicherzustellen, dass wir immer die *aktuellste* debounced Funktion aufrufen.
        // Da fetchUserProfile selbst useCallback verwendet (siehe unten),
        // ist diese Funktion hier stabil.
         fetchUserProfileInternal();
    }, []); // Keine Abhängigkeiten hier, da es nur die innere Funktion aufruft

    useEffect(() => {
        // Mache die trigger-Funktion global verfügbar
        window.fetchUserProfileAgain = triggerFetchUserProfile;
        return () => {
            delete window.fetchUserProfileAgain;
        }
    }, [triggerFetchUserProfile]); // Hängt von der stabilen trigger-Funktion ab


    // Die eigentliche, gedrosselte fetchUserProfile Logik
    // Verwende useCallback hier, um sicherzustellen, dass die debounced Funktion
    // nicht bei jedem Render neu erstellt wird, es sei denn, Abhängigkeiten ändern sich.
    const fetchUserProfileInternal = useCallback(
        debounce(async () => {
            console.log("AuthContext: fetchUserProfileInternal() (debounced) called.");
            // Prüfung auf isRefreshing hier drin lassen, falls die Funktion direkt aufgerufen wird
            if (isRefreshing) {
                console.log("fetchUserProfileInternal skipped due to ongoing refresh.");
                return;
            }
            // Setze isAuthLoading nur, wenn nicht schon ein Refresh läuft
            // oder wenn wir den Auth-Status noch gar nicht kennen
            if (!isRefreshing || !authCheckDone) {
                setIsAuthLoading(true);
            }
            console.log("AuthContext: Fetching user profile (debounced)...");

            try {
                const response = await api.get('/profile');
                console.log("AuthContext: Profile response received", response.data);

                if (response.data.isGuest) {
                    console.log("AuthContext: User is Guest.");
                    setUser(null);
                } else {
                    console.log("AuthContext: User is Logged In.", response.data.username);
                    setUser(response.data);
                }
            } catch (error) {
                 // Der Interceptor sollte 401er fangen und refreshen.
                 // Wenn hier immer noch ein Fehler ankommt (z.B. 500 oder nach fehlgeschlagenem Refresh),
                 // sollten wir den User als ausgeloggt betrachten.
                console.error("fetchUserProfileInternal => /profile ERROR:", error?.response?.status, error?.response?.data?.message || error.message);
                 // Sicherstellen, dass der User auf null gesetzt wird bei Fehler nach dem initialen Check
                 if(authCheckDone) { // Nur null setzen wenn der erste Check schon mal lief
                     setUser(null);
                 }
            } finally {
                setIsAuthLoading(false);
                setAuthCheckDone(true);
                setIsAuthReady(true); // Provider ist bereit
                console.log("AuthContext: fetchUserProfileInternal finished. isAuthReady set to true.");
            }
        }, 300), // Leicht erhöhter Debounce-Wert kann manchmal helfen
        // Abhängigkeiten für useCallback:
        // isRefreshing: um den Check durchzuführen
        // authCheckDone: um zu entscheiden, ob Fehler zum setUser(null) führt
        // setIsAuthLoading, setUser, setIsAuthReady: State setters sind stabil
        [isRefreshing, authCheckDone, setIsAuthLoading, setUser, setIsAuthReady] // Korrekte Abhängigkeiten für ESLint
    );


    // -----------------------------------------------------------
    // 2) Beim App-Start /profile abrufen
    // -----------------------------------------------------------
    useEffect(() => {
        // Nur beim ersten Mount ODER wenn user explizit null ist UND noch geladen wird
        if (isAuthLoading && user === null) {
            console.log("AuthContext: Initial fetchUserProfile triggered.");
            fetchUserProfileInternal(); // Rufe die interne, gedrosselte Funktion auf
        }
        // Wenn user auf null gesetzt wird (z.B. nach Logout oder Fehler),
        // muss nicht unbedingt erneut gefetched werden, der Zustand ist ja dann klar.
    }, [isAuthLoading, user, fetchUserProfileInternal]); // Korrekte Abhängigkeiten (Warnung 5 behoben)

    // -----------------------------------------------------------
    // 3) login - bleibt gleich
    // -----------------------------------------------------------
    const login = async (credentials) => {
        setIsAuthLoading(true);
        try {
            const resp = await api.post('/login', credentials);
            if (resp.status === 200) {
                console.log("AuthContext: Login successful, fetching profile...");
                await fetchUserProfileInternal(); // Rufe die interne Funktion auf
                return true;
            } else {
                console.error('AuthContext: Login API call returned non-200 status:', resp.statusText);
                setIsAuthLoading(false);
                return false;
            }
        } catch (err) {
            console.error('AuthContext: Fehler beim Login-Aufruf:', err);
            setIsAuthLoading(false);
            throw err;
        }
    };

    // -----------------------------------------------------------
    // 4) logout - bleibt gleich
    // -----------------------------------------------------------
    const logout = useCallback(async (options = { navigate: true }) => {
        console.log(`AuthContext: Logout called. Navigate: ${options.navigate}`);
        try {
            await api.post('/logout');
            console.log("AuthContext: Backend logout notified.");
        } catch (error) {
            console.warn("AuthContext: Backend Logout-Fehler (ignoriert für Client-Logout):", error);
        }
        setUser(null);
        setIsAuthLoading(false);
        setIsAuthReady(true); // Nach Logout ist der Auth-Status auch "bereit" (nämlich: nicht eingeloggt)
        setAuthCheckDone(true); // Status ist bekannt
        window.isLoggedIn = false;

        if (options.navigate) {
            console.log("AuthContext: Navigating to /login after logout.");
            navigate('/login');
        } else {
            console.log("AuthContext: Logout state changed, no navigation triggered by logout function.");
        }
    }, [navigate]);

    // -----------------------------------------------------------
    // 5) Globale Logout-Funktionen bereitstellen - bleibt gleich
    // -----------------------------------------------------------
     useEffect(() => {
         window.logout = logout;
         window.localLogout = (options = { navigate: false }) => { /* ... bleibt gleich ... */ };
         return () => { /* ... bleibt gleich ... */ };
     }, [logout, navigate]);


    // -----------------------------------------------------------
    // 6) handleOAuthLogin - ruft jetzt interne Funktion auf
    // -----------------------------------------------------------
    const handleOAuthLogin = useCallback(async () => {
        console.log("AuthContext: Handling OAuth Login, fetching profile...");
        await fetchUserProfileInternal(); // Rufe die interne Funktion auf
        return true;
    }, [fetchUserProfileInternal]); // Abhängigkeit von der internen Funktion

    // -----------------------------------------------------------
    // 7) isLoggedIn - bleibt gleich
    // -----------------------------------------------------------
    const isLoggedIn = !!user;

    // Setze window.isLoggedIn - bleibt gleich
    useEffect(() => {
        if (!isAuthLoading && authCheckDone) {
            const newIsLoggedIn = !!user;
            if (window.isLoggedIn !== newIsLoggedIn) {
                console.log(`AuthContext: Auth status changed. User is ${newIsLoggedIn ? 'LOGGED IN' : 'NOT LOGGED IN'}. Setting window.isLoggedIn.`);
                window.isLoggedIn = newIsLoggedIn;
            }
        }
    }, [isAuthLoading, authCheckDone, user]);


    // -----------------------------------------------------------
    // 8) Token-Refresh Monitor (vom Interceptor) - verwendet jetzt triggerFetchUserProfile
    // -----------------------------------------------------------
    useEffect(() => {
        const handleRefreshStarted = () => {
            console.log("AuthContext: Event 'tokenRefreshStarted' received.");
            setIsRefreshing(true);
        };
        const handleRefreshEnded = () => {
            console.log("AuthContext: Event 'tokenRefreshEnded' received.");
            setIsRefreshing(false);
            // Profil neu laden nach Refresh-Versuch (egal ob erfolgreich oder nicht)
            // Verwende die globale trigger Funktion, die wir oben definiert haben
            console.log("AuthContext: Triggering profile fetch again after refresh attempt ended.");
            triggerFetchUserProfile(); // Ruft die stabile Trigger-Funktion auf
        };

        window.addEventListener('tokenRefreshStarted', handleRefreshStarted);
        window.addEventListener('tokenRefreshEnded', handleRefreshEnded);

        return () => {
            window.removeEventListener('tokenRefreshStarted', handleRefreshStarted);
            window.removeEventListener('tokenRefreshEnded', handleRefreshEnded);
        };
    }, [triggerFetchUserProfile]); // Abhängigkeit von der stabilen Trigger-Funktion

    // -----------------------------------------------------------
    // 9) Proaktiver Refresh - bleibt gleich
    // -----------------------------------------------------------
    useEffect(() => {
        let refreshInterval;
        if (isLoggedIn) {
            console.log("AuthContext: User is logged in, starting proactive refresh interval (6 min).");
            refreshInterval = setInterval(() => {
                console.log("AuthContext: Proactive refresh triggered.");
                refreshToken().then((refreshed) => {
                    if (refreshed) {
                        console.log("AuthContext: Proactive refresh successful.");
                    } else {
                        console.warn("AuthContext: Proactive refresh failed. Interceptor will handle on next API call.");
                    }
                }).catch(err => {
                    console.error("AuthContext: Error during proactive refresh:", err);
                });
            }, 360000); // 6 Minuten
        } else {
            console.log("AuthContext: User not logged in, clearing proactive refresh interval.");
            if (refreshInterval) clearInterval(refreshInterval);
        }
        return () => {
            if (refreshInterval) {
                console.log("AuthContext: Clearing proactive refresh interval on cleanup.");
                clearInterval(refreshInterval);
            }
        };
    }, [isLoggedIn]);

    // Stelle sicher, dass der Provider den korrekten Value übergibt
    // Füge isAuthReady hinzu (Warnung 3 behoben)
    return (
        <AuthContext.Provider value={{
            user,
            isLoggedIn,
            isAuthLoading,
            isRefreshing,
            isAuthReady, // <-- HINZUGEFÜGT
            login,
            logout,
            handleOAuthLogin,
        }}>
            {children}
        </AuthContext.Provider>
    );
};