import React, { useState, useRef, useEffect } from 'react';
import { FaQuestionCircle } from 'react-icons/fa';
import { Trans } from '@lingui/macro';
import './InfoTooltip.css';

const InfoTooltip = ({ children, iconSize = 20, style = {} }) => {
  const [open, setOpen] = useState(false);
  const tooltipRef = useRef(null);

  const toggleTooltip = () => setOpen(prev => !prev);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        setOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div
      className="info-tooltip-container"
      style={{ position: 'relative', display: 'inline-block', ...style }}
      ref={tooltipRef}
    >
      <FaQuestionCircle
        className="tooltip-icon"
        onClick={toggleTooltip}
        style={{ fontSize: iconSize }}
      />
      {open && (
        <div className="info-tooltip-content">
          <div className="tooltip-inner-content">
            {children}
          </div>
        </div>
      )}
    </div>
  );
};

export default InfoTooltip;
