import React, { useState, useEffect } from 'react'; // useState, useEffect importieren
// import { Trans } from '@lingui/macro';
import { FaPercent, FaChevronDown } from 'react-icons/fa';
import { formatChangePercentage } from '../../utils/formatChangePercentage';

const PriceDisplay = ({
  formattedPrice,
  priceChangePercentage,
  // Erwarte jetzt priceDirection und highlightKey statt priceChangeClass
  priceDirection, // 'up', 'down' oder ''
  highlightKey,   // Zeitstempel oder Zähler, der sich bei up/down ändert
  selectedChangeTimeframe,
  setSelectedChangeTimeframe,
  availableTimeframes,
}) => {
  const [timeframeDropdownOpen, setTimeframeDropdownOpen] = useState(false);
  // NEU: Lokaler State für die Animationsklasse
  const [animateClass, setAnimateClass] = useState('');

  const toggleDropdown = () => setTimeframeDropdownOpen(prev => !prev);

  // NEU: Effekt, der auf Änderungen vom highlightKey reagiert
  useEffect(() => {
    let timeoutId = null;

    if (highlightKey) { // Nur wenn ein neuer Key da ist (also eine Änderung stattfand)
      if (priceDirection === 'up') {
        setAnimateClass('coin-price-highlight-up');
      } else if (priceDirection === 'down') {
        setAnimateClass('coin-price-highlight-down');
      }

      // Setze Timeout, um die Animationsklasse nach 1 Sekunde zu entfernen
      // (Dauer sollte zur CSS-Animationsdauer passen)
      timeoutId = setTimeout(() => {
        setAnimateClass('');
      }, 1000); // 1000ms = 1 Sekunde
    }

    // Cleanup-Funktion für den Timeout, falls der Key sich erneut ändert,
    // bevor der Timeout abgelaufen ist.
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
    // Effekt soll nur laufen, wenn sich der highlightKey ändert
  }, [highlightKey, priceDirection]);

  return (
    <div className="coin-price-display">
      <span
        // Wende die lokale state-Variable 'animateClass' an
        className={`coin-price-value ${animateClass}`}
      >
        {formattedPrice}
      </span>
      {priceChangePercentage !== null && (
        <div className="coin-price-change-container">
          {/* Restlicher Code bleibt gleich... */}
           <div className="price-change-box">
             <span className="timeframe-label" onClick={toggleDropdown}>
               {selectedChangeTimeframe}
               <FaChevronDown className={`dropdown-arrow ${timeframeDropdownOpen ? 'open' : ''}`} />
             </span>
             <FaPercent className="price-change-icon" />
             <span className="price-change-value">
               {formatChangePercentage(priceChangePercentage, { hideTrailingPercent: true })}
             </span>
           </div>
           {timeframeDropdownOpen && (
             <div className="timeframe-dropdown">
               {availableTimeframes.map((tf) => (
                 <div
                   key={tf}
                   className={`timeframe-option ${tf === selectedChangeTimeframe ? 'active' : ''}`}
                   onClick={() => {
                     setSelectedChangeTimeframe(tf);
                     setTimeframeDropdownOpen(false);
                   }}
                 >
                   {tf}
                 </div>
               ))}
             </div>
           )}
        </div>
      )}
    </div>
  );
};

export default PriceDisplay;