// src/components/CoinDetails/FavoriteStar.js

import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaStar, FaRegStar } from 'react-icons/fa';
import { AuthContext } from '../../context/AuthContext';
import { useFavorites } from '../../context/FavoritesContext';
import { toast } from 'react-toastify';
import { StyledButton } from '../../utils/StyledButton';
import { Trans, t } from '@lingui/macro';
import api from '../../services/api'; // <-- Neu: Axios-Client
import './FavoriteStar.css';

const FavoriteStar = ({ coinId, coinData }) => {
  const navigate = useNavigate();
  const { isLoggedIn } = useContext(AuthContext);
  const { favorites, addFavorite, removeFavorite, folders, fetchFolders } = useFavorites();

  const [selectedFolderId, setSelectedFolderId] = useState(null);
  const [creatingFolder, setCreatingFolder] = useState(false);
  const [newFolderName, setNewFolderName] = useState('');
  const [showFolderModal, setShowFolderModal] = useState(false);
  const [showGuestModal, setShowGuestModal] = useState(false);
  const [hover, setHover] = useState(false);

  const normalizedCoinId = String(coinId);
  const isFavorite = favorites.has(normalizedCoinId);

  /**
   * Favorit hinzufügen (POST /favorites)
   */
  async function handleAddFavorite(folderId = null) {
    try {
      await api.post('/favorites', {
        coin_id: coinId,
        folder_id: folderId,
        price_at_add: coinData.current_price,
        marketcap_at_add: coinData.market_cap,
        volume_24h_at_add: coinData.total_volume,
        market_cap_rank_at_add: coinData.market_cap_rank,
      });

      toast.success(
        t({ id: 'favorit.toast.added', message: 'Favorit hinzugefügt' })
      );
      addFavorite(normalizedCoinId);
    } catch (err) {
      console.error('Fehler handleAddFavorite:', err);
      // Falls das Backend eine Fehlermeldung wie 'maximal 20 Favoriten' zurückgibt
      const msg = err.response?.data?.message || err.message;
      if (msg.includes('maximal 20 Favoriten')) {
        toast.error(
          t({
            id: 'favorit.limitReached',
            message:
              'In der Free-Version können maximal 20 Favoriten angelegt werden. Bitte upgraden, um mehr Favoriten zu nutzen.',
          })
        );
      } else {
        toast.error(msg);
      }
    }
  }

  /**
   * Favorit entfernen (DELETE /favorites/:coinId)
   */
  async function handleRemoveFavorite() {
    try {
      await api.delete(`/favorites/${coinId}`);
      toast.success(
        t({ id: 'favorit.toast.removed', message: 'Favorit entfernt' })
      );
      removeFavorite(normalizedCoinId);
    } catch (err) {
      console.error('Fehler removeFavorite:', err);
      const msg = err.response?.data?.message || err.message;
      toast.error(msg);
    }
  }

  /**
   * Stern-Click: Hinzufügen oder Entfernen
   */
  function handleStarClick() {
    if (isFavorite) {
      handleRemoveFavorite();
    } else {
      if (!isLoggedIn) {
        setShowGuestModal(true);
      } else {
        setShowFolderModal(true);
      }
    }
  }

  /**
   * Neuen Ordner erstellen (POST /favorites/folders)
   */
  async function handleCreateFolder(e) {
    e.preventDefault();
    if (!newFolderName.trim()) return;

    try {
      const { data: newFolder } = await api.post('/favorites/folders', {
        folder_name: newFolderName,
      });
      toast.success(
        t({ id: 'favorit.toast.folderCreated', message: 'Neuer Ordner erstellt' })
      );
      await fetchFolders();
      setSelectedFolderId(newFolder.folder_id);
      setNewFolderName('');
      setCreatingFolder(false);
    } catch (err) {
      console.error('Fehler createFolder:', err);
      const msg = err.response?.data?.message || err.message;
      toast.error(msg);
    }
  }

  /**
   * Favorit in Ordner hinzufügen
   */
  function handleAddToFolder() {
    handleAddFavorite(selectedFolderId || null);
    setShowFolderModal(false);
  }

  function goToLogin() {
    setShowGuestModal(false);
    navigate('/login');
  }

  return (
    <>
      <span
        className="favorite-star"
        onClick={handleStarClick}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {isFavorite ? (
          <FaStar className="favorite-star-icon-full" />
        ) : (
          <FaRegStar className="favorite-star-icon" />
        )}
      </span>

      {showGuestModal && (
        <div className="favorites-modal-backdrop">
          <div className="favorites-modal-content">
            <h3>
              <Trans id="favorit.pleaseLoginTitle">Bitte anmelden</Trans>
            </h3>
            <p>
              <Trans id="favorit.pleaseLoginDesc">
                Du musst eingeloggt sein, um die Favoriten-Funktion nutzen zu können.
              </Trans>
            </p>
            <div className="modal-actions" style={{ marginTop: '20px' }}>
              <StyledButton variant="primary" onClick={goToLogin}>
                <Trans id="favorit.button.goToLogin">Zum Login</Trans>
              </StyledButton>
              <StyledButton variant="cancel" onClick={() => setShowGuestModal(false)}>
                <Trans id="favorit.button.cancel">Abbrechen</Trans>
              </StyledButton>
            </div>
          </div>
        </div>
      )}

      {showFolderModal && (
        <div className="favorites-modal-backdrop">
          <div className="favorites-modal-content">
            <div className="favorites-modal-footer">
              <a href="/favorites" className="favorites-link">
                <Trans id="favorit.manageFavorites">Alle Favoriten verwalten</Trans>
              </a>
            </div>
            <h3>
              <Trans id="favorit.folderModalTitle">Favorit hinzufügen</Trans>
            </h3>
            <p>
              <Trans id="favorit.folderModalDesc">
                Wähle einen bestehenden Ordner aus oder erstelle optional einen neuen:
              </Trans>
            </p>
            {!creatingFolder && (
              <div className="folder-list">
                {folders.length === 0 && (
                  <p>
                    <Trans id="favorit.emptyFoldersMsg">
                      Keine Ordner vorhanden. Du kannst unten einen erstellen.
                    </Trans>
                  </p>
                )}
                {folders.map((f) => (
                  <label key={f.folder_id} className="folder-item">
                    <input
                      type="radio"
                      name="folder"
                      value={f.folder_id}
                      checked={selectedFolderId === f.folder_id}
                      onChange={() => setSelectedFolderId(f.folder_id)}
                    />
                    {f.folder_name}
                  </label>
                ))}
              </div>
            )}
            {!creatingFolder && (
              <StyledButton variant="primary" onClick={() => setCreatingFolder(true)}>
                <Trans id="favorit.newFolderBtn">Neuen Ordner erstellen</Trans>
              </StyledButton>
            )}
            {creatingFolder && (
              <form onSubmit={handleCreateFolder}>
                <div>
                  <label>
                    <Trans id="favorit.folderNameLabel">Ordner-Name:</Trans>
                  </label>
                  <input
                    type="text"
                    value={newFolderName}
                    maxLength={38}
                    onChange={(e) => setNewFolderName(e.target.value)}
                  />
                  <p className="character-counter">
                    <span>
                      <Trans id="folder.charactersLeft">Zeichen übrig:</Trans>{' '}
                      {38 - newFolderName.length}
                    </span>
                  </p>
                </div>
                <div className="folder-create-actions">
                  <StyledButton variant="confirm" type="submit" disabled={!newFolderName.trim()}>
                    <Trans id="favorit.save">Speichern</Trans>
                  </StyledButton>
                  <StyledButton variant="cancel" type="button" onClick={() => setCreatingFolder(false)}>
                    <Trans id="favorit.cancel">Abbrechen</Trans>
                  </StyledButton>
                </div>
              </form>
            )}
            {!creatingFolder && (
              <div className="modal-actions">
                <StyledButton variant="confirm" onClick={handleAddToFolder}>
                  <Trans id="favorit.add">Hinzufügen</Trans>
                </StyledButton>
                <StyledButton variant="cancel" onClick={() => setShowFolderModal(false)}>
                  <Trans id="favorit.cancel">Abbrechen</Trans>
                </StyledButton>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default FavoriteStar;
