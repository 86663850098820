// src/components/PriceTicker.js
import React, { useState, useEffect, useRef, useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import {
  subscribeToSingleSymbol,
  unsubscribeFromSingleSymbol
} from '../hooks/BinanceDataCoinList';
import { formatPrice } from '../utils/formatPrice';
import CoinSelektor from '../utils/CoinSelektor'; // 

import api from '../services/api'; // NEU: Axios-Instanz

// Coin-Logos
import btcLogo from 'cryptocurrency-icons/svg/color/btc.svg';
import ethLogo from 'cryptocurrency-icons/svg/color/eth.svg';
import xrpLogo from 'cryptocurrency-icons/svg/color/xrp.svg';
import solLogo from 'cryptocurrency-icons/svg/color/sol.svg';
import bnbLogo from 'cryptocurrency-icons/svg/color/bnb.svg';

const COIN_LOGOS = { btc: btcLogo, eth: ethLogo, xrp: xrpLogo, sol: solLogo, bnb: bnbLogo };
const COIN_LABELS = { btc: 'Bitcoin', eth: 'Ethereum', xrp: 'XRP', sol: 'Solana', bnb: 'BNB' };
const AVAILABLE_COINS = ['btc', 'eth', 'xrp', 'sol', 'bnb'];
const COIN_API_IDS = {
  btc: 'bitcoin',
  eth: 'ethereum',
  xrp: 'ripple',
  sol: 'solana',
  bnb: 'binancecoin'
};

export default function PriceTicker() {
  // Alle Hooks werden immer aufgerufen:
  const { isAuthLoading } = useContext(AuthContext);
  const [selectedCoin, setSelectedCoin] = useState(() => localStorage.getItem('headerCoin') || 'btc');
  const [vsCurrency, setVsCurrency] = useState(() => localStorage.getItem('vsCurrency') || 'usd');
  const [fallbackMap, setFallbackMap] = useState({});
  const [currentPrice, setCurrentPrice] = useState(null); // null => "Loading.."
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [priceChangeClass, setPriceChangeClass] = useState('');

  const pendingPriceRef = useRef(null); // speichert den rohen Binance-USD-Preis
  const prevPriceRef = useRef(null);    // merkt den zuletzt gesetzten finalPrice
  const dropdownRef = useRef(null);

  // useEffects, die immer aufgerufen werden
  useEffect(() => {
    setCurrentPrice(null);
    fetchFallbackPrices().then(() => {
      let binancePriceUsd = pendingPriceRef.current;
      if (!binancePriceUsd && prevPriceRef.current) {
        binancePriceUsd = null;
      }
      const immediatePrice = calcInitialPrice(binancePriceUsd);
      if (immediatePrice != null) {
        setCurrentPrice(immediatePrice);
        prevPriceRef.current = immediatePrice;
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCoin, vsCurrency]);

  async function fetchFallbackPrices() {
    try {
      const apiIdsJoined = Object.values(COIN_API_IDS).join(',');
      const multi = vsCurrency.toLowerCase() === 'usd'
        ? 'usd'
        : `usd,${vsCurrency.toLowerCase()}`;

      // ALT: fetch(`/prices?ids=${apiIdsJoined}&currency=${multi}`, ...)
      const resp = await api.get(`/prices?ids=${apiIdsJoined}&currency=${multi}`);
      const data = resp.data;
      setFallbackMap(data);
    } catch (error) {
      console.error('Error in fetchFallbackPrices:', error);
    }
  }

  function calcInitialPrice(binancePriceUsd) {
    if (!binancePriceUsd) {
      return computeFallbackOnly(selectedCoin, vsCurrency, fallbackMap);
    }
    const conv = convertBinancePriceToVs(
      binancePriceUsd, selectedCoin, vsCurrency, fallbackMap
    );
    if (conv !== null) return conv;
    return computeFallbackOnly(selectedCoin, vsCurrency, fallbackMap);
  }

  useEffect(() => {
    pendingPriceRef.current = null;
    const handlePriceUpdate = (symbol, { price }) => {
      if (symbol.toLowerCase() === selectedCoin) {
        pendingPriceRef.current = price;
      }
    };
    subscribeToSingleSymbol(selectedCoin, handlePriceUpdate);
    return () => {
      unsubscribeFromSingleSymbol(selectedCoin, handlePriceUpdate);
      pendingPriceRef.current = null;
    };
  }, [selectedCoin]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (pendingPriceRef.current != null) {
        const binancePriceUsd = pendingPriceRef.current;
        const finalPrice = convertBinancePriceToVs(
          binancePriceUsd, selectedCoin, vsCurrency, fallbackMap
        );
        if (finalPrice !== null) {
          if (prevPriceRef.current !== null) {
            if (finalPrice > prevPriceRef.current) {
              setPriceChangeClass('price-up');
            } else if (finalPrice < prevPriceRef.current) {
              setPriceChangeClass('price-down');
            }
            setTimeout(() => setPriceChangeClass(''), 500);
          }
          setCurrentPrice(finalPrice);
          prevPriceRef.current = finalPrice;
        }
        pendingPriceRef.current = null;
      }
    }, 2000);
    return () => clearInterval(interval);
  }, [selectedCoin, vsCurrency, fallbackMap]);

  useEffect(() => {
    localStorage.setItem('headerCoin', selectedCoin);
  }, [selectedCoin]);
  useEffect(() => {
    localStorage.setItem('vsCurrency', vsCurrency);
  }, [vsCurrency]);

  let finalDisplayText;
  if (currentPrice === null) {
    finalDisplayText = 'Loading..';
  } else {
    const numericPart = formatPrice(currentPrice, vsCurrency, false);
    finalDisplayText = `${numericPart} ${vsCurrency.toUpperCase()}`;
  }

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setIsDropdownOpen(false);
      }
    };
    if (isDropdownOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isDropdownOpen]);

  return (
    <>
      {isAuthLoading ? (
        <div>Loading prices…</div>
      ) : (
        <div className="compact-price-ticker" ref={dropdownRef} style={{ position: 'relative' }}>
          <div
            className={`coin-display ${priceChangeClass}`}
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          >
            <img
              src={COIN_LOGOS[selectedCoin]}
              alt={selectedCoin.toUpperCase()}
              className="coin-display-icon"
            />
            <span className="coin-price-text">{finalDisplayText}</span>
            <span className="dropdown-arrow">{isDropdownOpen ? '▲' : '▼'}</span>
          </div>
          {isDropdownOpen && (
            <div className="coin-dropdown" style={{ minWidth: '180px', maxWidth: '180px', overflow: 'hidden' }}>
              {AVAILABLE_COINS.map((coin) => (
                <div
                  key={coin}
                  className="dropdown-coin"
                  onClick={() => {
                    setSelectedCoin(coin);
                    setIsDropdownOpen(false);
                  }}
                >
                  <img
                    src={COIN_LOGOS[coin]}
                    alt={coin.toUpperCase()}
                    className="dropdown-coin-icon"
                  />
                  <span>{COIN_LABELS[coin]}</span>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </>
  );
}

/** Fallback-Berechnung */
function computeFallbackOnly(coinKey, vsCurrency, fallbackMap) {
  const coinApiId = COIN_API_IDS[coinKey];
  if (!coinApiId || !fallbackMap[coinApiId]) return null;
  const val = fallbackMap[coinApiId][vsCurrency.toLowerCase()];
  if (!val || typeof val !== 'number') return null;
  return val;
}

/** Umrechnung des Binance-USD-Preises in vsCurrency */
function convertBinancePriceToVs(binancePriceUsd, coinKey, vsCurrency, fallbackMap) {
  if (typeof binancePriceUsd !== 'number' || !binancePriceUsd) return null;
  if (vsCurrency.toLowerCase() === 'usd') return binancePriceUsd;
  const coinApiId = COIN_API_IDS[coinKey];
  if (!coinApiId) return binancePriceUsd;
  if (vsCurrency.toLowerCase() === coinKey.toLowerCase()) return 1.0;
  const coinObj = fallbackMap[coinApiId];
  if (!coinObj) return binancePriceUsd;
  const coingeckoUsd = coinObj['usd'] || 0;
  const coingeckoVs = coinObj[vsCurrency.toLowerCase()] || 0;
  if (coingeckoUsd <= 0 || coingeckoVs <= 0) return binancePriceUsd;
  const factor = coingeckoVs / coingeckoUsd;
  return binancePriceUsd * factor;
}
