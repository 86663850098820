// src/components/CoinChart.jsx
import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { Trans } from '@lingui/macro';
import { Line, Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import 'chartjs-adapter-date-fns';
import {
  getTimeOptionsAndMaxTicks,
  tooltipTitleCallback,
  xTickCallback,
  yearBoundaryPlugin,
} from './chartUtils';
import { formatPriceAsString } from './formatPrice';
import { formatLargeValue } from './formatLargeValue';
import api from '../services/api'; // <=== Unser zentraler Axios-Client

Chart.register(...registerables, annotationPlugin, yearBoundaryPlugin);

// Einfaches Crosshair-Plugin
const crosshairPlugin = {
  id: 'crosshairLine',
  afterDraw(chart) {
    if (!chart.tooltip || !chart.tooltip._active || !chart.tooltip._active.length) {
      return;
    }
    const ctx = chart.ctx;
    const activePoint = chart.tooltip._active[0];
    const x = activePoint.element.x;
    const topY = chart.chartArea.top;
    const bottomY = chart.chartArea.bottom;
    ctx.save();
    ctx.beginPath();
    ctx.moveTo(x, topY);
    ctx.lineTo(x, bottomY);
    ctx.lineWidth = 1;
    ctx.strokeStyle = 'rgba(0,0,0,0.5)';
    ctx.stroke();
    ctx.restore();
  },
};
Chart.register(crosshairPlugin);

const CoinChart = forwardRef(function CoinChart(
  {
    coinId,
    vsCurrency,
    selectedDays,
    userLocale,
    currentPrice,
    comparisonCoinId,
    comparisonCoinName,
    mainCoinName,
    isFullscreen,
  },
  ref
) {
  const [chartData, setChartData] = useState(null);
  const [chartLoading, setChartLoading] = useState(false);
  const [chartError, setChartError] = useState(null);
  const [comparisonData, setComparisonData] = useState(null);

  // Annotationen werden erst nach Layout-Festlegung gesetzt
  const [annotationsState, setAnnotationsState] = useState({});

  const chartRef = useRef(null);
  const volumeChartRef = useRef(null);

  // Flash-Effekt fürs "aktuelle Preis"-Label
  const [priceFlashBorder, setPriceFlashBorder] = useState({ borderColor: '#fff', borderWidth: 0.1 });
  const prevPriceRef = useRef(null);

  useImperativeHandle(ref, () => ({
    downloadChartAsImage: () => {
      if (chartRef.current) {
        return chartRef.current.toBase64Image('image/png', 1);
      }
      return null;
    },
  }));

  // =========================================================
  // 1) Haupt-Coin-Daten laden (Axios statt fetch)
  // =========================================================
  useEffect(() => {
    if (!coinId) return;
    setChartLoading(true);
    setChartError(null);

    const fetchChartData = async () => {
      try {
        const { data } = await api.get('/coin-market-chart', {
          params: {
            id: coinId,
            vs_currency: vsCurrency,
            days: selectedDays,
          },
        });

        // Sortieren
        data.prices.sort((a, b) => a[0] - b[0]);
        data.market_caps.sort((a, b) => a[0] - b[0]);
        data.total_volumes.sort((a, b) => a[0] - b[0]);

        // Mapping
        const chartPoints = data.prices.map((entry, index) => ({
          x: entry[0],
          y: entry[1],
          marketCap: data.market_caps[index] ? data.market_caps[index][1] : null,
          volume: data.total_volumes[index] ? data.total_volumes[index][1] : null,
          change24h:
            index > 0 && data.prices[index - 1]
              ? ((entry[1] - data.prices[index - 1][1]) /
                  data.prices[index - 1][1]) *
                100
              : 0,
        }));

        const pricesOnly = chartPoints.map((p) => p.y);
        const high = Math.max(...pricesOnly);
        const low = Math.min(...pricesOnly);

        let fallbackPrice = 0;
        if (chartPoints.length > 0) {
          fallbackPrice = chartPoints[chartPoints.length - 1].y;
        }

        // Ein Datensatz (Hauptpreis)
        const datasets = [
          {
            label: 'Price',
            data: chartPoints,
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 1,
            tension: 0,
            pointRadius: 0,
            pointHoverRadius: 5,
            pointHitRadius: 10,
            yAxisID: 'y',
            spanGaps: true,
            fill: {
              target: { value: fallbackPrice },
              above: 'rgba(200, 0, 0, 0.11)',
              below: 'rgba(0, 200, 0, 0.11)',
            },
          },
        ];

        setChartData({
          datasets,
          high,
          low,
          marketCaps: data.market_caps.map((entry) => ({
            x: entry[0],
            y: entry[1],
          })),
          volumes: data.total_volumes.map((entry) => ({
            x: entry[0],
            y: entry[1],
          })),
        });
        setChartLoading(false);
      } catch (err) {
        console.error(err);
        setChartError(
          <Trans id="error.chartLoad">
            Fehler beim Laden der Chartdaten. Bitte versuche es erneut.
          </Trans>
        );
        setChartLoading(false);
      }
    };

    fetchChartData();
  }, [coinId, vsCurrency, selectedDays]);

  // =========================================================
  // 2) Vergleichs-Coin-Daten laden (Axios statt fetch)
  // =========================================================
  useEffect(() => {
    if (!comparisonCoinId) {
      setComparisonData(null);
      return;
    }
    const fetchComparisonData = async () => {
      try {
        const { data } = await api.get('/coin-market-chart', {
          params: {
            id: comparisonCoinId,
            vs_currency: vsCurrency,
            days: selectedDays,
          },
        });

        data.prices.sort((a, b) => a[0] - b[0]);
        data.total_volumes.sort((a, b) => a[0] - b[0]);

        const pricePoints = data.prices.map((entry) => ({ x: entry[0], y: entry[1] }));
        const volumePoints = data.total_volumes.map((entry) => ({ x: entry[0], y: entry[1] }));

        setComparisonData({
          main: pricePoints,
          volumes: volumePoints,
        });
      } catch (err) {
        console.error('Fehler Comparison:', err);
        setComparisonData(null);
      }
    };
    fetchComparisonData();
  }, [comparisonCoinId, vsCurrency, selectedDays]);

  // =========================================================
  // 3) Live-Updates + Preis-Flash
  // =========================================================
  useEffect(() => {
    if (currentPrice != null) {
      if (prevPriceRef.current != null && prevPriceRef.current > 0) {
        if (currentPrice > prevPriceRef.current) {
          setPriceFlashBorder({ borderColor: 'rgba(0,200,0,0.8)', borderWidth: 0.3 });
          setTimeout(() => {
            setPriceFlashBorder({ borderColor: '#fff', borderWidth: 0 });
          }, 800);
        } else if (currentPrice < prevPriceRef.current) {
          setPriceFlashBorder({ borderColor: 'rgba(200,0,0,0.8)', borderWidth: 0.3 });
          setTimeout(() => {
            setPriceFlashBorder({ borderColor: '#fff', borderWidth: 0 });
          }, 800);
        }
      }
      prevPriceRef.current = currentPrice;
    }

    const chartInstance = chartRef.current;
    if (!chartInstance || currentPrice == null || currentPrice <= 0) return;

    const mainDataset = chartInstance.data.datasets[0];
    if (!mainDataset) return;

    const now = Date.now();
    const dataPoints = mainDataset.data;
    if (dataPoints.length > 0) {
      const lastPoint = dataPoints[dataPoints.length - 1];
      if (now - lastPoint.x < 30000) {
        lastPoint.x = now;
        lastPoint.y = currentPrice;
      } else {
        dataPoints.push({ x: now, y: currentPrice });
      }
    } else {
      dataPoints.push({ x: now, y: currentPrice });
    }

    // fill-Definition
    mainDataset.fill = {
      target: { value: currentPrice },
      above: 'rgba(200, 0, 0, 0.11)',
      below: 'rgba(0, 200, 0, 0.11)',
    };
    mainDataset.pointRadius = 0;
    mainDataset.pointHoverRadius = 0;

    chartInstance.update('active');
  }, [currentPrice, selectedDays]);

  // =========================================================
  // 4) Vergleichsdaten integrieren
  // =========================================================
  useEffect(() => {
    if (!chartData) return;
    const merged = structuredClone(chartData);
    merged.datasets = merged.datasets.filter((ds) => !ds.isComparison);

    if (comparisonCoinId && comparisonData?.main) {
      merged.datasets.push({
        label: comparisonCoinName || 'Vergleich',
        data: comparisonData.main,
        borderColor: 'rgba(255, 99, 132, 0.7)',
        borderWidth: 1,
        backgroundColor: 'rgba(255, 99, 132, 0.1)',
        fill: false,
        tension: 0,
        pointRadius: 0,
        pointHoverRadius: 0,
        pointHitRadius: 10,
        yAxisID: 'yRight',
        isComparison: true,
      });
    }

    const oldDatasetsJSON = JSON.stringify(chartData.datasets);
    const newDatasetsJSON = JSON.stringify(merged.datasets);
    if (oldDatasetsJSON !== newDatasetsJSON) {
      setChartData(merged);
    }
  }, [comparisonData, comparisonCoinId, chartData, comparisonCoinName]);

  // =========================================================
  // 5) Chart neu zeichnen
  // =========================================================
  useEffect(() => {
    if (chartRef.current) chartRef.current.update();
    if (volumeChartRef.current) volumeChartRef.current.update();
  }, [chartData]);

  // =========================================================
  // 6) Zeit-/Tick-Optionen
  // =========================================================
  const { timeOptions, maxTicks } = useMemo(() => {
    if (!chartData) return { timeOptions: { unit: 'day' }, maxTicks: undefined };
    return getTimeOptionsAndMaxTicks(selectedDays, chartData);
  }, [selectedDays, chartData]);

  // =========================================================
  // 7) Annotation: High-Low etwas früher ausblenden
  // =========================================================
  useEffect(() => {
    if (!chartData || !chartRef.current || !chartRef.current.chartArea) return;
    const chartInstance = chartRef.current;
    const yScale = chartInstance.scales.y;
    const { top: chartTop, bottom: chartBottom } = chartInstance.chartArea;

    let lastPriceComputed = currentPrice;
    const mainData = chartData.datasets[0]?.data || [];
    if (lastPriceComputed == null && mainData.length > 0) {
      lastPriceComputed = mainData[mainData.length - 1].y;
    }

    const { high, low } = chartData;

    // Threshold: z.B. 1.5% bei '1', sonst 19%
    let threshold;
    switch (String(selectedDays)) {
      case '1':
        threshold = 0.015;
        break;
      default:
        threshold = 0.03;
        break;
    }

    // relDiffHigh / relDiffLow
    let showHighLine = false;
    let showLowLine = false;
    if (lastPriceComputed < high) {
      const relDiffHigh = (high - lastPriceComputed) / high;
      showHighLine = relDiffHigh >= threshold;
    }
    if (lastPriceComputed > low) {
      const relDiffLow = (lastPriceComputed - low) / low;
      showLowLine = relDiffLow >= threshold;
    }

    // Offset-Logik
    let priceLabelYAdjust = 0;
    let highLabelYAdjust = -5;
    let lowLabelYAdjust = 5;

    // Falls wir am High => verschieben. Für simplicity belassen wir's
    // --------------------------------------------------
    const chartPoints = mainData;
    const highIndex = chartPoints.findIndex((p) => p.y === high);
    const lowIndex = chartPoints.findIndex((p) => p.y === low);
    const highX = highIndex !== -1 ? chartPoints[highIndex].x : null;
    const lowX = lowIndex !== -1 ? chartPoints[lowIndex].x : null;

    const newAnnotations = {};

    // A) current Price
    newAnnotations.currentPriceLine = {
      type: 'line',
      yMin: lastPriceComputed,
      yMax: lastPriceComputed,
      borderColor: 'rgba(75, 192, 192, 0.8)',
      borderWidth: 0.5,
      z: 1001,
      label: {
        display: true,
        content: `${formatPriceAsString(lastPriceComputed, vsCurrency)}`,
        position: 'end',
        xAdjust: -15,
        yAdjust: priceLabelYAdjust,
        backgroundColor: 'rgba(75,192,192,0.3)',
        borderColor: priceFlashBorder.borderColor,
        borderWidth: priceFlashBorder.borderWidth,
        font: { size: 11, weight: 'bold' },
        padding: 3,
      },
    };

    // B) High
    if (highX !== null && showHighLine) {
      newAnnotations.highLine = {
        type: 'line',
        xMin: highX - 5 * 60 * 1000,
        xMax: highX + 5 * 60 * 1000,
        yMin: high,
        yMax: high,
        borderColor: 'rgba(0, 200, 0, 0.8)',
        borderWidth: 1,
        z: -1,
        label: {
          display: true,
          content: `High: ${formatPriceAsString(high, vsCurrency)}`,
          position: 'start',
          yAdjust: highLabelYAdjust,
          backgroundColor: 'rgba(0,200,0,0.1)',
          font: { size: 10, weight: 'bold' },
          padding: 2,
        },
      };
    }

    // C) Low
    if (lowX !== null && showLowLine) {
      newAnnotations.lowLine = {
        type: 'line',
        xMin: lowX - 5 * 60 * 1000,
        xMax: lowX + 5 * 60 * 1000,
        yMin: low,
        yMax: low,
        borderColor: 'rgba(200, 0, 0, 0.8)',
        borderWidth: 1,
        z: -1,
        label: {
          display: true,
          content: `Low: ${formatPriceAsString(low, vsCurrency)}`,
          position: 'end',
          yAdjust: lowLabelYAdjust,
          backgroundColor: 'rgba(200,0,0,0.1)',
          font: { size: 10, weight: 'bold' },
          padding: 2,
        },
      };
    }

    setAnnotationsState(newAnnotations);
    chartInstance.update();
  }, [chartData, currentPrice, selectedDays, vsCurrency, priceFlashBorder]);

  // =========================================================
  // 8) Chart-Optionen
  // =========================================================
  let decimationSamples = 0;
  let decimationEnabled = false;
  const daysAsNum = Number(selectedDays);
  if (selectedDays === 'max' || daysAsNum >= 90) {
    decimationEnabled = true;
    decimationSamples = 300;
  } else if (daysAsNum >= 30) {
    decimationEnabled = true;
    decimationSamples = 500;
  }

  const commonLayout = { padding: { left: 0 } };

  const priceChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    animation: { duration: 0, easing: 'linear' },
    layout: commonLayout,
    plugins: {
      decimation: {
        enabled: decimationEnabled,
        algorithm: 'min-max',
        samples: decimationSamples,
      },
      yearBoundaryPlugin: {
        selectedDays: String(selectedDays),
      },
      datalabels: { display: false },
      legend: { display: false },
      tooltip: {
        enabled: true,
        displayColors: false,
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        borderColor: '#ffffff',
        borderWidth: 1,
        animation: false,
        intersect: true,
        mode: 'nearest',
        callbacks: {
          title: (items) => {
            if (!items.length) return '';
            const dataset = items[0].dataset;
            return dataset.isComparison ? comparisonCoinName : mainCoinName;
          },
          beforeBody: (items) => tooltipTitleCallback(items, userLocale),
          label: (context) => {
            const dataPoint =
              context.raw || context.dataset.data[context.dataIndex];
            const price = formatPriceAsString(dataPoint.y, vsCurrency);
            const marketCap =
              dataPoint.marketCap != null
                ? formatLargeValue(dataPoint.marketCap, vsCurrency, true, false)
                : 'N/A';
            const volume =
              dataPoint.volume != null
                ? formatLargeValue(dataPoint.volume, vsCurrency, true, false)
                : 'N/A';
            const change24h =
              dataPoint.change24h != null
                ? `${dataPoint.change24h.toFixed(2)}%`
                : 'N/A';

            return [
              `Preis: ${price} ${vsCurrency.toUpperCase()}`,
              `Marktkap.: ${marketCap} ${vsCurrency.toUpperCase()}`,
              `Volumen: ${volume} ${vsCurrency.toUpperCase()}`,
              `24h Änderung: ${change24h}`,
            ];
          },
        },
        titleFont: { weight: 'bold' },
        bodyFont: { weight: 'normal' },
        footerFont: { weight: 'normal' },
      },
      annotation: {
        annotations: annotationsState,
      },
    },
    interaction: {
      mode: 'index',
      intersect: false,
    },
    scales: {
      x: {
        type: 'time',
        bounds: 'data',
        offset: false,
        time: timeOptions,
        display: false,
        ticks: {
          major: { enabled: true },
          callback: (value, index, ticks) =>
            xTickCallback(value, index, ticks, selectedDays, userLocale),
          autoSkip: true,
          ...(maxTicks && { maxTicksLimit: maxTicks }),
        },
      },
      y: {
        type: 'linear',
        display: true,
        beginAtZero: false,
        position: 'left',
        title: {
          display: true,
          text: mainCoinName
            ? `${mainCoinName} (${vsCurrency.toUpperCase()})`
            : vsCurrency.toUpperCase(),
          color: 'rgba(75, 192, 192, 1)',
          font: { size: 16, weight: 'bold' },
        },
        ticks: {
          padding: 10,
          callback: (value) =>
            formatPriceAsString(parseFloat(value), vsCurrency, true),
        },
        afterFit(scale) {
          scale.width = 92;
        },
      },
      yRight: {
        type: 'linear',
        display: !!comparisonCoinId,
        position: 'right',
        grid: { drawOnChartArea: false },
        title: {
          display: true,
          text: comparisonCoinName
            ? `${comparisonCoinName} (${vsCurrency.toUpperCase()})`
            : 'Vergleich',
          color: 'rgba(255, 99, 132, 1)',
          font: { size: 16, weight: 'bold' },
        },
        ticks: {
          padding: 0,
          align: 'end',
          callback: (value) => formatPriceAsString(parseFloat(value), vsCurrency, true),
        },
        afterFit(scale) {
          if (comparisonCoinId) {
            scale.width = 92;
          }
        },
      },
    },
  };

  // =========================================================
  // Volumen-Chart
  // =========================================================
  const volumeChartData = {
    datasets: [
      {
        label: userLocale && userLocale.startsWith('de') ? 'Volumen' : 'Volume',
        xAxisID: 'x',
        data: chartData?.volumes || [],
        backgroundColor: 'rgba(75, 192, 192, 0.1)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
        yAxisID: 'y',
        barPercentage: 0.5,
        barThickness: 0.5,
      },
    ],
  };

  if (comparisonData && comparisonData.volumes) {
    volumeChartData.datasets.push({
      label:
        comparisonCoinName
          ? userLocale && userLocale.startsWith('de')
            ? `${comparisonCoinName} Volumen`
            : `${comparisonCoinName} Volume`
          : userLocale && userLocale.startsWith('de')
            ? 'Volumen Vergleich'
            : 'Volume Comparison',
      xAxisID: 'x',
      data: comparisonData.volumes,
      backgroundColor: 'rgba(255, 99, 132, 0.4)',
      borderColor: 'rgba(255, 99, 132, 1)',
      borderWidth: 1,
      yAxisID: 'yRight',
      barPercentage: 0.5,
      barThickness: 0.8,
      isComparison: true,
    });
  }

  const mainVolumeMax = chartData?.volumes?.length
    ? Math.max(...chartData.volumes.map((v) => v.y))
    : undefined;
  const compVolumeMax = comparisonData?.volumes?.length
    ? Math.max(...comparisonData.volumes.map((v) => v.y))
    : undefined;

  const volumeChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    animation: { duration: 0 },
    layout: commonLayout,
    plugins: {
      legend: { display: false },
      datalabels: { display: false },
      tooltip: {
        enabled: true,
        displayColors: false,
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        borderColor: '#ffffff',
        borderWidth: 1,
        titleFont: { weight: 'bold', color: '#ffffff' },
        bodyFont: { weight: 'normal', color: '#ffffff' },
        footerFont: { weight: 'normal', color: '#ffffff' },
        callbacks: {
          title: (items) => tooltipTitleCallback(items, userLocale),
          label: (context) => {
            const volumeValue = context.raw.y;
            const coinName = context.dataset.isComparison ? comparisonCoinName : mainCoinName;
            const volumeLabel = userLocale && userLocale.startsWith('de') ? 'Volumen' : 'Volume';
            return `${coinName} ${volumeLabel}: ${formatLargeValue(volumeValue, vsCurrency, true, false)}`;
          },
        },
      },
    },
    interaction: { mode: 'index', intersect: false },
    scales: {
      x: {
        type: 'time',
        bounds: 'data',
        offset: false,
        time: timeOptions,
        display: true,
        categoryPercentage: 0.8,
        ticks: {
          major: { enabled: true },
          callback: (value, index, ticks) =>
            xTickCallback(value, index, ticks, selectedDays, userLocale),
          autoSkip: true,
          ...(maxTicks && { maxTicksLimit: maxTicks }),
        },
      },
      y: {
        type: 'linear',
        display: true,
        beginAtZero: true,
        max: mainVolumeMax,
        grid: { drawBorder: false },
        title: {
          display: true,
          text: userLocale && userLocale.startsWith('de') ? 'Volumen' : 'Volume',
          font: { size: 16, weight: 'bold' },
        },
        ticks: {
          callback: (value) =>
            formatLargeValue(parseFloat(value), vsCurrency, true, false),
        },
        afterFit(scale) {
          scale.width = 92;
        },
      },
      yRight: {
        type: 'linear',
        display: comparisonData && comparisonData.volumes ? true : false,
        beginAtZero: true,
        max: compVolumeMax,
        position: 'right',
        grid: { drawOnChartArea: false },
        title: {
          display: true,
          text: userLocale && userLocale.startsWith('de') ? 'Volumen' : 'Volume',
          font: { size: 16, weight: 'bold' },
        },
        ticks: {
          padding: 0,
          align: 'end',
          callback: (value) => formatPriceAsString(parseFloat(value), vsCurrency, true),
        },
        afterFit(scale) {
          if (comparisonData && comparisonData.volumes) {
            scale.width = 92;
          }
        },
      },
    },
  };

  return (
    <div className="chartcardinner" style={isFullscreen ? { height: '100vh' } : {}}>
      <div
        className="chart-container"
        style={
          isFullscreen
            ? { display: 'flex', flexDirection: 'column', height: '100%', position: 'relative' }
            : { height: '550px', position: 'relative' }
        }
      >
        {/* Error-Overlay */}
        {chartError && (
          <div className="chart-error-overlay">
            {chartError}
          </div>
        )}

        {/* Loading-Overlay */}
        {chartLoading && (
          <div className="chart-loading-overlay">
            <div className="chart-spinner" />
            <p><Trans id="message.loadingChartData">Lade Chart-Daten...</Trans></p>
          </div>
        )}

        {/* Preischart */}
        <div
          className="price-chart-container"
          style={
            isFullscreen
              ? { flex: '0 0 55%', overflow: 'hidden' }
              : { height: '70%', position: 'relative' }
          }
        >
          {chartLoading
            ? null
            : !chartData
              ? (
                <div style={{ textAlign: 'center', padding: '2rem' }}>
                  <Trans id="message.noData">Keine Daten vorhanden.</Trans>
                </div>
              )
              : (
                <Line ref={chartRef} data={chartData} options={priceChartOptions} />
              )
          }
        </div>

        {/* Volumenchart */}
        <div
          className="volume-chart-container"
          style={
            isFullscreen
              ? { flex: '0 0 20%', overflow: 'hidden', marginTop: '15px' }
              : { height: '25%', position: 'relative', marginTop: '15px' }
          }
        >
          {chartData ? (
            <Bar ref={volumeChartRef} data={volumeChartData} options={volumeChartOptions} />
          ) : null}
        </div>
      </div>
    </div>
  );
});

export default CoinChart;
