// src/components/ResetPassword.js
import React, { useState } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import '../styles/ResetPassword.css';
import { toast } from 'react-toastify';
import { t } from '@lingui/macro';
import { i18n } from '@lingui/core';

// Import der ausgelagerten Komponenten
import { StyledInput } from '../utils/StyledInput';
import { StyledButton } from '../utils/StyledButton';

import api from '../services/api'; // NEU: unser Axios-Client

const ResetPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');
  const id = queryParams.get('id');

  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const validateInputs = () => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    if (!passwordRegex.test(newPassword)) {
      const errorMessage = t({
        id: 'error.passwordPolicy',
        message:
          'Das Passwort muss mindestens 8 Zeichen lang sein, eine Zahl, einen Groß- und Kleinbuchstaben sowie ein Sonderzeichen enthalten.',
      });
      toast.error(errorMessage);
      return false;
    }

    if (newPassword !== confirmPassword) {
      const errorMessage = t({
        id: 'error.passwordsDontMatch',
        message: 'Die Passwörter stimmen nicht überein!',
      });
      toast.error(errorMessage);
      return false;
    }

    return true;
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (!validateInputs()) {
      return;
    }

    setIsLoading(true);
    try {
      // Axios-Aufruf => POST /reset-password + queryParams => { params: { token, id } }
      const resp = await api.post(
        '/reset-password',
        { newPassword },
        {
          params: { token, id },
          headers: {
            'Accept-Language': i18n.locale,
          },
        }
      );

      const data = resp.data;
      // Status >=200 <300 => success
      toast.success(
        data.message ||
          t({ id: 'success.passwordReset', message: 'Passwort erfolgreich zurückgesetzt.' })
      );
      navigate('/login');
    } catch (err) {
      // Axios-Error => err.response
      if (err.response && err.response.data) {
        const data = err.response.data;
        if (data.messageKey === 'error.LoginTooManyRequests') {
          toast.error(
            t({
              id: 'error.LoginTooManyRequests',
              message: 'Zu viele Login-Versuche! Bitte warte 15 Minuten und probiere es erneut.',
            })
          );
        } else if (data.messageKey === 'error.invalidOrExpiredToken') {
          toast.error(
            t({
              id: 'error.invalidOrExpiredToken',
              message: 'Der Link zum Zurücksetzen des Passworts ist ungültig oder abgelaufen.',
            })
          );
        } else {
          toast.error(data.message || 'Fehler beim Zurücksetzen des Passworts.');
        }
      } else {
        toast.error(
          t({
            id: 'error.internalServerError',
            message: 'Interner Serverfehler',
          })
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="reset-password-container">
      <h2>{t({ id: 'title.resetPassword', message: 'Passwort zurücksetzen' })}</h2>
      <form onSubmit={handleResetPassword}>
        <StyledInput
          type="password"
          placeholder={t({ id: 'placeholder.newPassword', message: 'Neues Passwort' })}
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          required
        />
        <StyledInput
          type="password"
          placeholder={t({
            id: 'placeholder.confirmNewPassword',
            message: 'Neues Passwort bestätigen',
          })}
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
        />
        <StyledButton type="submit" variant="primary" disabled={isLoading}>
          {isLoading
            ? t({ id: 'button.resetting', message: 'Zurücksetzen...' })
            : t({ id: 'button.resetPassword', message: 'Passwort zurücksetzen' })}
        </StyledButton>
      </form>
      <div className="links">
        <p>
          <Link to="/login">
            {t({ id: 'link.backToLogin', message: 'Zurück zum Login' })}
          </Link>
        </p>
      </div>
    </div>
  );
};

export default ResetPassword;
