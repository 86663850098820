// src/components/widgets/CoinSearchInput.jsx

import React, { useState, useEffect, useRef } from 'react';
import { Trans } from '@lingui/macro';
import api from '../../services/api'; // <-- Neu: Unser zentrales Axios-Objekt
import { StyledInput } from '../../utils/StyledInput';

/** Debounce-Hook */
function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => clearTimeout(handler);
  }, [value, delay]);
  return debouncedValue;
}

const CoinSearchInput = ({
  selectedCoinId,   // z.B. "dent" oder ""
  onSelectCoin,     // Callback (coinId) => {}
  isCreate = false, // default false
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [didUserInteract, setDidUserInteract] = useState(false);
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  const MIN_CHARS = 2;
  const debouncedTerm = useDebounce(searchTerm, 300);
  const containerRef = useRef(null);

  // (A) Reagiere auf selectedCoinId
  useEffect(() => {
    if (isCreate) {
      // Wenn wir im "Create"-Modus sind, leeren wir das Feld.
      setSearchTerm('');
      return;
    }
    if (!selectedCoinId) {
      return;
    }
    if (didUserInteract) {
      // User hat schon manuell interagiert => Nicht überschreiben
      return;
    }

    // Lade den Coin-Namen (damit wir z.B. "Bitcoin" im Input haben)
    const fetchCoinName = async (coinId) => {
      try {
        setIsLoading(true);
        const { data } = await api.get('/coin-data', {
          params: { id: coinId }
        });
        if (data && data.name) {
          setSearchTerm(data.name);
        }
      } catch (err) {
        console.error('Fehler beim Laden coin-data:', err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCoinName(selectedCoinId);
  }, [selectedCoinId, isCreate, didUserInteract]);

  // (B) Auto-Suggest per /search?query=...
  useEffect(() => {
    const loadSearch = async () => {
      if (debouncedTerm.trim().length < MIN_CHARS) {
        setResults([]);
        return;
      }
      setIsLoading(true);
      try {
        const { data } = await api.get('/search', {
          params: { query: debouncedTerm.trim() }
        });
        if (data && data.coins) {
          setResults(data.coins);
        } else {
          setResults([]);
        }
      } catch (err) {
        console.error('Fehler bei CoinSearchInput:', err);
        setResults([]);
      } finally {
        setIsLoading(false);
      }
    };

    loadSearch();
  }, [debouncedTerm]);

  // (C) KlickOutside => schließe das Dropdown
  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (containerRef.current && !containerRef.current.contains(e.target)) {
        setShowDropdown(false);
      }
    };
    document.addEventListener('mousedown', handleOutsideClick);
    return () => document.removeEventListener('mousedown', handleOutsideClick);
  }, []);

  // HANDLER
  const handleInputChange = (e) => {
    setDidUserInteract(true);
    setSearchTerm(e.target.value);
    setShowDropdown(true);
  };

  const handleSelectCoin = (coin) => {
    setDidUserInteract(true);
    setSearchTerm(coin.name);
    setShowDropdown(false);
    onSelectCoin(coin.id);
  };

  return (
    <div style={{ position: 'relative' }} ref={containerRef}>
      <StyledInput
        type="search"
        value={searchTerm}
        onChange={handleInputChange}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
        placeholder="Bitcoin, Ethereum, ..."
        containerStyle={{ marginBottom: '10px' }}
      />

      {showDropdown && (
        <div
          style={{
            position: 'absolute',
            top: '40px',
            left: 0,
            width: '100%',
            maxHeight: '200px',
            overflowY: 'auto',
            border: '0.5px solid var(--primary-color)',
            borderRadius: '4px',
            backgroundColor: 'var(--background-color2)',
            zIndex: 999,
          }}
        >
          {isLoading && (
            <div style={{ padding: '8px', fontStyle: 'italic' }}>
              <Trans id="search.loading">Lade...</Trans>
            </div>
          )}

          {!isLoading && debouncedTerm.trim().length < MIN_CHARS && (
            <div style={{ padding: '8px', fontStyle: 'italic' }}>
              <Trans id="search.min2chars">Bitte mindestens 2 Zeichen eingeben</Trans>
            </div>
          )}

          {!isLoading &&
            debouncedTerm.trim().length >= MIN_CHARS &&
            results.map((coin) => (
              <div
                key={coin.id}
                onClick={() => handleSelectCoin(coin)}
                style={{
                  padding: '8px',
                  cursor: 'pointer',
                  borderBottom: '0.5px solid var(--primary-color)',
                }}
              >
                {coin.thumb && (
                  <img
                    src={coin.thumb}
                    alt={coin.name}
                    style={{ width: '20px', height: '20px', marginRight: '8px' }}
                  />
                )}
                <strong>{coin.name}</strong>{' '}
                <small>({coin.symbol?.toUpperCase()})</small>
              </div>
            ))}

          {!isLoading &&
            debouncedTerm.trim().length >= MIN_CHARS &&
            results.length === 0 && (
              <div style={{ padding: '8px', fontStyle: 'italic' }}>
                <Trans id="search.noResults">Keine Ergebnisse</Trans>
              </div>
            )}
        </div>
      )}
    </div>
  );
};

export default CoinSearchInput;
