// src/components/SearchOverlay.jsx
import React, { useState, useEffect } from 'react';
import { t, Trans } from '@lingui/macro';
import { Link, useNavigate } from 'react-router-dom';
import { FaTimes } from 'react-icons/fa';
import slugify from 'slugify';

// Utils
import { formatPrice } from '../utils/formatPrice';
import { formatLargeValue } from '../utils/formatLargeValue';
import { formatChangePercentage } from '../utils/formatChangePercentage';

import { StyledInput } from '../utils/StyledInput';
import { StyledButton } from '../utils/StyledButton';

import { useBinanceData } from '../hooks/useBinanceData';
import api from '../services/api'; // ★ Unsere neue Axios-Instanz

const SearchOverlay = ({ styleType, onClose }) => {
  const [query, setQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // Trending = Array of { item: {...} }
  const [trending, setTrending] = useState([]);
  const [searchData, setSearchData] = useState(null);
  const [didSearch, setDidSearch] = useState(false);

  // priceMap = { [coinId]: { usd, usd_24h_change, usd_market_cap, usd_24h_vol, ...} }
  const [priceMap, setPriceMap] = useState({});

  const isMobile = typeof window !== 'undefined' && window.innerWidth <= 768;

  const navigate = useNavigate();

  // ----------------------------------
  // Kategorie-Klick => Navigation
  // ----------------------------------
  const handleBadgeClick = (category) => {
    if (category && typeof category === 'object' && category.id) {
      navigate(`/coinlist?category=${category.id}`);
      return;
    }
    const cleanName = category.name.replace(/\s*\(.*?\)/, '');
    const slug = slugify(cleanName, { lower: true });
    navigate(`/coinlist?category=${slug}`);
  };

  // ----------------------------------
  // 1) Laden der Trending-Daten
  // ----------------------------------
  useEffect(() => {
    fetchTrending();
  }, []);

  const fetchTrending = async () => {
    try {
      setIsLoading(true);
      // ALT: fetch('/search/trending') => jetzt:
      const resp = await api.get('/search/trending');
      const data = resp.data;

      // Preis-Change normalisieren => Falls item.data.price_change_percentage_24h ein Objekt ist, extrahiere .usd
      const newArr = (data.coins || []).map((obj) => {
        const tItem = obj.item;
        if (tItem?.data?.price_change_percentage_24h) {
          const raw = tItem.data.price_change_percentage_24h;
          if (typeof raw === 'object' && raw.usd !== undefined) {
            tItem.data.price_change_percentage_24h = raw.usd;
          }
        }
        return obj;
      });

      setTrending(newArr);
    } catch (err) {
      console.error('Fehler beim Laden von Trending:', err);
    } finally {
      setIsLoading(false);
    }
  };

  // ----------------------------------
  // 2) Wenn Trending-Daten da => priceMap laden
  // ----------------------------------
  useEffect(() => {
    if (!trending || !trending.length) return;
    const coinIds = trending.map((t) => t.item?.id).filter(Boolean).join(',');
    if (coinIds) {
      fetchPriceMap(coinIds);
    }
  }, [trending]);

  // ----------------------------------
  // 3) Suchergebnisse => priceMap laden
  // ----------------------------------
  useEffect(() => {
    if (!searchData || !searchData.coins?.length) return;
    const coinIds = searchData.coins.map((c) => c.id).join(',');
    if (coinIds) {
      fetchPriceMap(coinIds);
    }
  }, [searchData]);

  const fetchPriceMap = async (coinIds) => {
    try {
      // ALT: fetch(`/prices?ids=${coinIds}&currency=usd`) => jetzt:
      const resp = await api.get(`/prices?ids=${coinIds}&currency=usd`);
      setPriceMap((prev) => ({ ...prev, ...resp.data }));
    } catch (err) {
      console.error('Fehler beim Laden der Preis-Daten:', err);
    }
  };

  // ----------------------------------
  // Suche
  // ----------------------------------
  const handleQueryChange = (e) => {
    setQuery(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      performSearch();
    }
  };

  const performSearch = async () => {
    if (query.trim().length < 3) return;
    setDidSearch(true);
    setIsLoading(true);

    try {
      // ALT: fetch(`/search?query=${encodeURIComponent(query)}`) => jetzt:
      const resp = await api.get(`/search?query=${encodeURIComponent(query)}`);
      const data = resp.data;

      // Preis-Change normalisieren
      if (data && data.coins) {
        data.coins.forEach((c) => {
          if (c.data?.price_change_percentage_24h) {
            const raw = c.data.price_change_percentage_24h;
            if (typeof raw === 'object' && raw.usd !== undefined) {
              c.data.price_change_percentage_24h = raw.usd;
            }
          }
        });
      }
      setSearchData(data);
    } catch (err) {
      console.error('Fehler bei der Suche:', err);
    } finally {
      setIsLoading(false);
    }
  };

  // ----------------------------------
  // Bei Klick auf Link => Overlay schließen
  // ----------------------------------
  const handleLinkClick = () => {
    if (onClose) onClose();
  };

  // ----------------------------------
  // Abbrechen, wenn styleType != 'dropdown'
  // ----------------------------------
  if (styleType !== 'dropdown') return null;

  // Check für "keine Ergebnisse"
  const noResults =
    didSearch &&
    searchData &&
    !searchData.coins?.length &&
    !searchData.exchanges?.length &&
    !searchData.categories?.length;

  return (
    <div
      className="settings_dropdown-panel"
      onMouseDown={(e) => e.stopPropagation()}
      style={{
        width: 'min(90vw, 500px)',
        maxHeight: '75vh',
        overflowY: 'auto',
        position: 'absolute',
        right: 0,
        top: '60px',
        zIndex: 9999,
        border: '1px solid var(--secondary-bg-color)',
        borderRadius: '8px',
        backgroundColor: 'var(--background-color2)',
        padding: '10px',
      }}
    >
      {/* Schließen-Button */}
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <FaTimes
          style={{ cursor: 'pointer', marginBottom: '5px' }}
          onClick={onClose}
        />
      </div>

      <h4 style={{ marginTop: 0, marginBottom: '10px' }}>
        <Trans id="search.title">Suche</Trans>
      </h4>

      <div style={{ marginBottom: '10px' }}>
        <StyledInput
          type="search"
          value={query}
          onChange={handleQueryChange}
          onKeyDown={handleKeyDown}
          placeholder={t({
            id: 'placeholder.search',
            message: 'Suchbegriff...',
          })}
          containerStyle={{ marginBottom: '-10px' }}
        />
      </div>

      {query.trim().length < 3 && (
        <div
          style={{
            marginBottom: '10px',
            fontStyle: 'italic',
            fontSize: '0.9rem',
            color: 'var(--text-color)',
          }}
        >
          <Trans id="search.minChars">Bitte mind. 3 Buchstaben eingeben</Trans>
        </div>
      )}

      <StyledButton
        onClick={performSearch}
        variant="primary"
        style={{ marginBottom: '20px', minWidth: '200px' }}
        disabled={query.trim().length < 3}
      >
        <Trans id="button.search">Suchen</Trans>
      </StyledButton>

      {isLoading && (
        <div style={{ marginBottom: '10px', fontStyle: 'italic' }}>
          <Trans id="search.loading">Lade...</Trans>
        </div>
      )}

      {/* TRENDING-COINS */}
      {!isLoading && !didSearch && (
        <TrendingCoinsList
          coins={trending}
          priceMap={priceMap}
          onLinkClick={handleLinkClick}
          isMobile={isMobile}
        />
      )}

      {/* SUCHERGEBNISSE */}
      {!isLoading && didSearch && searchData && (
        <>
          <SearchResults
            data={searchData}
            priceMap={priceMap}
            onLinkClick={handleLinkClick}
            isMobile={isMobile}
            handleBadgeClick={handleBadgeClick}
          />
          {noResults && (
            <div style={{ fontStyle: 'italic' }}>
              <Trans id="search.noResults">Keine Ergebnisse gefunden</Trans>
            </div>
          )}
        </>
      )}
    </div>
  );
};

/* ------------------------------------------------------------------
 * TRENDING-COINS-LIST
 * ------------------------------------------------------------------ */
const TrendingCoinsList = ({ coins, priceMap, onLinkClick, isMobile }) => {
  if (!coins?.length) {
    return (
      <div style={{ marginBottom: '10px', fontStyle: 'italic' }}>
        <Trans id="search.trending.noData">Keine Trending-Daten</Trans>
      </div>
    );
  }

  return (
    <div>
      <div style={{ fontWeight: 'bold', marginBottom: '6px' }}>
        <Trans id="search.trending">Trending Coins:</Trans>
      </div>
      {coins.map((trendObj, idx) => {
        const { item } = trendObj;
        if (!item) return null;
        return (
          <TrendingCoinCard
            key={item.id || idx}
            item={item}
            priceMap={priceMap}
            onLinkClick={onLinkClick}
            isMobile={isMobile}
          />
        );
      })}
    </div>
  );
};

/* ------------------------------------------------------------------
 * TRENDING-COIN-CARD (kleine Variante)
 * ------------------------------------------------------------------ */
const TrendingCoinCard = ({ item, priceMap, onLinkClick, isMobile }) => {
  const truncated = truncatedTitle(item.name, isMobile);

  // Fallback-Daten von Coingecko (priceMap)
  const fallbackData = priceMap[item.id?.toLowerCase()] || {};
  const usdPrice = fallbackData.usd;
  const usdChange = fallbackData.usd_24h_change;
  const usdMC = fallbackData.usd_market_cap;
  const usdVol = fallbackData.usd_24h_vol;

  // Hole Binance-Live-Daten per Hook
  const {
    binancePrice,
    binanceChange,
    useBinancePrice
  } = useBinanceData(item.symbol);

  // Welchen Endpreis anzeigen?
  const finalPrice =
    useBinancePrice && binancePrice != null ? binancePrice : usdPrice;

  // *** NEU: percentageChange aus Binance **wenn** vorhanden, sonst Fallback
  const finalChange =
    useBinancePrice && binanceChange != null
      ? binanceChange
      : item.data?.price_change_percentage_24h ?? usdChange;

  return (
    <HoverableCard style={cardStyle}>
      <div style={{ display: 'flex', gap: '8px' }}>
        {item.thumb && (
          <img
            src={item.thumb}
            alt={item.name}
            style={{ width: '32px', height: '32px', objectFit: 'cover' }}
          />
        )}
        <div>
          <Link
            to={`/coin-details?coin=${item.id}`}
            style={{
              textDecoration: 'none',
              color: 'var(--text-color)',
            }}
            onClick={onLinkClick}
          >
            <strong style={{ fontSize: '1rem' }}>
              {truncated}
              {item.market_cap_rank && (
                <span
                  style={{
                    marginLeft: '8px',
                    fontSize: '0.9rem',
                    color: '#aaa',
                  }}
                >
                  #{item.market_cap_rank}
                </span>
              )}
            </strong>
            <br />
            <span style={{ fontSize: '0.9rem' }}>
              ({item.symbol?.toUpperCase()})
            </span>
          </Link>

          {/* Extra Infos */}
          <div style={{ marginTop: '6px' }}>
            {finalPrice !== undefined ? (
              <>
                <div>
                  <Trans id="search.label.price">Price:</Trans>{' '}
                  {formatPrice(finalPrice, 'usd', true)}
                </div>
                <div style={{ display: 'flex', gap: '6px' }}>
                  <span>
                    <Trans id="search.label.24hChange">24h Change:</Trans>
                  </span>
                  {formatChangePercentage(finalChange)}
                </div>
                <div>
                  <Trans id="search.label.marketCap">Market Cap:</Trans>{' '}
                  {formatLargeValue(usdMC, 'usd')}
                </div>
                <div>
                  <Trans id="search.label.volume">Volume:</Trans>{' '}
                  {formatLargeValue(usdVol, 'usd')}
                </div>
              </>
            ) : (
              <div style={{ fontSize: '0.85rem', fontStyle: 'italic' }}>
                (
                <Trans id="search.label.noPriceData">Keine Preisdaten</Trans>
                )
              </div>
            )}
          </div>
        </div>
      </div>
    </HoverableCard>
  );
};

/* ------------------------------------------------------------------
 * SUCHERGEBNISSE
 * ------------------------------------------------------------------ */
const SearchResults = ({
  data,
  priceMap,
  onLinkClick,
  isMobile,
  handleBadgeClick,
}) => {
  const { coins, exchanges, categories } = data;

  return (
    <div>
      {/* COINS */}
      {coins?.length > 0 && (
        <div style={{ marginBottom: '12px' }}>
          <SectionTitle label="Coins" />
          {coins.map((coin, idx) => (
            <CoinCard
              key={`${coin.id}-${idx}`}
              coin={coin}
              priceMap={priceMap}
              onLinkClick={onLinkClick}
              isMobile={isMobile}
            />
          ))}
        </div>
      )}

      {/* EXCHANGES */}
      {exchanges?.length > 0 && (
        <div style={{ marginBottom: '12px' }}>
          <SectionTitle label="Exchanges" />
          {exchanges.map((exg, idx) => (
            <ExchangeCard
              key={`${exg.id}-${idx}`}
              exchange={exg}
              onLinkClick={onLinkClick}
              isMobile={isMobile}
            />
          ))}
        </div>
      )}

      {/* KATEGORIEN */}
      {categories?.length > 0 && (
        <div style={{ marginBottom: '12px' }}>
          <SectionTitle label="Kategorien" />
          {categories.map((category, idx) => (
            <CategoryCard
              key={`${category.id}-${idx}`}
              category={category}
              onBadgeClick={handleBadgeClick}
              onClose={onLinkClick}
              isMobile={isMobile}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const SectionTitle = ({ label }) => (
  <div
    style={{
      fontWeight: 'bold',
      marginBottom: '6px',
      borderBottom: '1px solid var(--secondary-bg-color)',
    }}
  >
    {label}
  </div>
);

/* ------------------------------------------------------------------
 *   COIN-CARD (für Suchergebnisse)
 * ------------------------------------------------------------------ */
const CoinCard = ({ coin, priceMap, onLinkClick, isMobile }) => {
  const truncated = truncatedTitle(coin.name, isMobile);

  const fallbackData = priceMap[coin.id?.toLowerCase()] || {};
  const usdPrice = fallbackData.usd;
  const usdChange = fallbackData.usd_24h_change;
  const usdMC = fallbackData.usd_market_cap;
  const usdVol = fallbackData.usd_24h_vol;

  const { binancePrice, binanceChange, useBinancePrice } = useBinanceData(coin.symbol);
  const finalPrice =
    useBinancePrice && binancePrice != null ? binancePrice : usdPrice;
  const finalChange =
    useBinancePrice && binanceChange != null
      ? binanceChange
      : coin.data?.price_change_percentage_24h ?? usdChange;

  return (
    <HoverableCard style={cardStyle}>
      <div style={{ display: 'flex', gap: '8px' }}>
        <img
          src={coin.large || coin.thumb}
          alt={coin.name}
          style={{ width: '48px', height: '48px', objectFit: 'cover' }}
        />
        <div>
          <Link
            to={`/coin-details?coin=${coin.id}`}
            style={{
              textDecoration: 'none',
              color: 'var(--text-color)',
            }}
            onClick={onLinkClick}
          >
            <strong style={{ fontSize: '1rem' }}>
              {truncated}
              {coin.market_cap_rank && (
                <span
                  style={{
                    marginLeft: '8px',
                    fontSize: '0.9rem',
                    color: '#aaa',
                  }}
                >
                  #{coin.market_cap_rank}
                </span>
              )}
            </strong>
            <br />
            <span style={{ fontSize: '0.9rem' }}>
              ({coin.symbol?.toUpperCase()})
            </span>
          </Link>

          {/* Extra Infos */}
          <div style={{ marginTop: '6px' }}>
            {finalPrice !== undefined ? (
              <>
                <div>
                  <Trans id="search.label.price">Price:</Trans>{' '}
                  {formatPrice(finalPrice, 'usd', true)}
                </div>
                <div style={{ display: 'flex', gap: '6px' }}>
                  <span>
                    <Trans id="search.label.24hChange">24h Change:</Trans>
                  </span>
                  {formatChangePercentage(finalChange)}
                </div>
                <div>
                  <Trans id="search.label.marketCap">Market Cap:</Trans>{' '}
                  {formatLargeValue(usdMC, 'usd')}
                </div>
                <div>
                  <Trans id="search.label.volume">Volume:</Trans>{' '}
                  {formatLargeValue(usdVol, 'usd')}
                </div>
              </>
            ) : (
              <div style={{ fontSize: '0.85rem', fontStyle: 'italic' }}>
                (
                <Trans id="search.label.noPriceData">Keine Preisdaten</Trans>
                )
              </div>
            )}
          </div>
        </div>
      </div>
    </HoverableCard>
  );
};

/* ------------------------------------------------------------------
 *   EXCHANGE-CARD
 * ------------------------------------------------------------------ */
const ExchangeCard = ({ exchange, onLinkClick, isMobile }) => {
  const truncated = truncatedTitle(exchange.name, isMobile);
  return (
    <HoverableCard style={cardStyle}>
      <div style={{ display: 'flex', gap: '8px' }}>
        <img
          src={exchange.large || exchange.thumb}
          alt={exchange.name}
          style={{ width: '48px', height: '48px', objectFit: 'cover' }}
        />
        <div>
          <Link
            to={`/exchange-details?exchange=${exchange.id}`}
            style={{
              textDecoration: 'none',
              color: 'var(--text-color)',
            }}
            onClick={onLinkClick}
          >
            <strong style={{ fontSize: '1rem' }}>{truncated}</strong>
          </Link>
          <br />
          {exchange.market_type && (
            <div style={{ fontSize: '0.85rem' }}>
              Market Type: {exchange.market_type}
            </div>
          )}
        </div>
      </div>
    </HoverableCard>
  );
};

/* ------------------------------------------------------------------
 *   CATEGORY-CARD
 * ------------------------------------------------------------------ */
const CategoryCard = ({ category, onBadgeClick, onClose, isMobile }) => {
  const truncated = truncatedTitle(category.name, isMobile);
  return (
    <HoverableCard
      style={cardStyle}
      onClick={() => {
        onBadgeClick(category);
        onClose();
      }}
    >
      <strong style={{ fontSize: '1rem' }}>{truncated}</strong>
    </HoverableCard>
  );
};

/* ------------------------------------------------------------------
 *   HOVERABLE-CARD
 * ------------------------------------------------------------------ */
const HoverableCard = ({ children, style, ...props }) => {
  const [hover, setHover] = useState(false);
  return (
    <div
      {...props}
      style={{
        ...style,
        transition: 'background-color 0.2s ease',
        backgroundColor: hover
          ? 'rgba(0,0,0,0.03)'
          : style.backgroundColor || 'transparent',
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {children}
    </div>
  );
};

/* ------------------------------------------------------------------
 *   STYLES & HELPER
 * ------------------------------------------------------------------ */
const cardStyle = {
  border: '1px solid var(--secondary-bg-color)',
  borderRadius: '4px',
  padding: '8px',
  marginBottom: '8px',
  cursor: 'pointer',
};

function truncatedTitle(title = '', isMobile) {
  const maxLen = isMobile ? 18 : 32;
  if (!title || title.length <= maxLen) return title;
  return title.substring(0, maxLen - 1) + '…';
}

export default SearchOverlay;
