// src/components/dashboard/GainersLosersMini.js

import React, { useEffect, useState } from 'react';
import { Trans } from '@lingui/macro';
import { formatPrice } from '../../utils/formatPrice';
import { formatChangePercentage } from '../../utils/formatChangePercentage';
import { StyledButton } from '../../utils/StyledButton';
import api from '../../services/api'; // <-- Neu: Axios-Client importieren

const GainersLosersMini = () => {
  const [gainers, setGainers] = useState([]);
  const [losers, setLosers] = useState([]);
  const [activeTab, setActiveTab] = useState('gainers');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const vsCurrency = 'usd';
  const duration = '24h';
  const topCoins = '1000';

  const fetchData = async () => {
    try {
      setLoading(true);
      setError(null);

      // Statt fetch() nun api.get()
      const { data } = await api.get('/top-gainers-losers', {
        params: {
          vs_currency: vsCurrency,
          duration,
          top_coins: topCoins
        }
      });

      const top5Gainers = (data.top_gainers || []).slice(0, 5);
      const top5Losers = (data.top_losers || []).slice(0, 5);

      setGainers(top5Gainers);
      setLosers(top5Losers);
    } catch (err) {
      console.error('GainersLosersMini error:', err);
      // Wenn die API eine Fehlermeldung zurückgibt, kannst du sie so ermitteln:
      const msg = err.response?.data?.message || err.message;
      setError(msg);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    const interval = setInterval(() => {
      fetchData();
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  if (loading) {
    return (
      <div className="gainers-losers-mini-widget">
        <Trans id="gainersLosersMini.loading">
          Loading top winners &amp; losers...
        </Trans>
      </div>
    );
  }

  if (error) {
    return (
      <div className="gainers-losers-mini-widget gainers-losers-mini-error">
        <Trans id="gainersLosersMini.error">Error:</Trans> {error}
      </div>
    );
  }

  const currentList = activeTab === 'gainers' ? gainers : losers;

  const handleRowClick = (coinId) => {
    window.location.href = `https://cryptoscan.digital/coin-details?coin=${coinId}&from=5`;
  };

  const renderCoinRow = (coin) => {
    const priceField = coin[vsCurrency];
    const changeField = `${vsCurrency}_${duration}_change`;
    const priceChangeValue =
      coin[changeField] ?? coin[`${vsCurrency}_24h_change`] ?? 0;

    return (
      <tr
        key={coin.id}
        onClick={() => handleRowClick(coin.id)}
        className="gainers-losers-mini-clickable-row"
      >
        <td>{coin.market_cap_rank ?? '—'}</td>
        <td>
          <img
            src={coin.image}
            alt={coin.name}
            style={{
              width: '16px',
              height: '16px',
              marginRight: '6px',
              verticalAlign: 'middle'
            }}
          />
          {coin.name}
        </td>
        {/* Preis-Zelle + mobile 24h-Change */}
        <td>
          {formatPrice(priceField, vsCurrency)}
          <div className="mini-mobile-24h-change">
            {formatChangePercentage(priceChangeValue)}
          </div>
        </td>
        {/* Desktop-Spalte */}
        <td className="mini-desktop-only">
          {formatChangePercentage(priceChangeValue)}
        </td>
      </tr>
    );
  };

  return (
    <div className="gainers-losers-mini-widget">
      <h4 style={{ textAlign: 'center' }}>
        <Trans id="title.gainersLosers">Gewinner & Verlierer</Trans>
      </h4>
      <div className="gainers-losers-mini-tabs">
        <StyledButton
          variant="tab"
          style={
            activeTab === 'gainers'
              ? {
                  backgroundColor: 'var(--primary-color)',
                  color: '#fff'
                }
              : {}
          }
          onClick={() => setActiveTab('gainers')}
        >
          <Trans id="title.topGainers">Top Gainers</Trans>
        </StyledButton>

        <StyledButton
          variant="tab"
          style={
            activeTab === 'losers'
              ? {
                  backgroundColor: 'var(--primary-color)',
                  color: '#fff'
                }
              : {}
          }
          onClick={() => setActiveTab('losers')}
        >
          <Trans id="title.topLosers">Top Losers</Trans>
        </StyledButton>
      </div>

      <table className="gainers-losers-mini-table">
        <thead>
          <tr>
            <th>
              <Trans id="label.rank">Rank</Trans>
            </th>
            <th>
              <Trans id="label.name">Name</Trans>
            </th>
            <th>
              <Trans id="label.price">Price</Trans>
            </th>
            <th className="mini-desktop-only">
              <Trans id="label.24hChange">24h %</Trans>
            </th>
          </tr>
        </thead>
        <tbody>{currentList.map(renderCoinRow)}</tbody>
      </table>
    </div>
  );
};

export default GainersLosersMini;
