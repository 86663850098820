// src/components/ExchangeList.js

import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import '../styles/ExchangeList.css';
import ExchangeRow from './ExchangeRow';
import SingleSelectNoSearch from '../utils/SingleSelectNoSearch';
import { Pagination } from '../utils/StyledButton';
import { Trans } from '@lingui/macro';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import api from '../services/api'; // <-- Neu: unser Axios-Objekt

const ExchangeList = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // URL-Parameter direkt beim Initialisieren
  const searchParams = new URLSearchParams(location.search);
  const initialPage = parseInt(searchParams.get('page') || '1', 10);
  const initialPerPage = parseInt(searchParams.get('per_page') || '50', 10);

  // States
  const [exchanges, setExchanges] = useState([]);
  const [loadingTotal, setLoadingTotal] = useState(true);
  const [loadingExchanges, setLoadingExchanges] = useState(true);
  const [error, setError] = useState(null);

  const [totalExchanges, setTotalExchanges] = useState(0);
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [exchangesPerPage, setExchangesPerPage] = useState(initialPerPage);

  // Kombinierter Lade-Zustand
  const loading = loadingTotal || loadingExchanges;

  // Retry-Refs und maxRetries (für Gesamtzahl und Exchanges-Daten)
  const retryCountTotalRef = useRef(0);
  const retryCountExchangesRef = useRef(0);
  const maxRetries = 5;

  // -------------------------------------------
  // URL-Updater
  // -------------------------------------------
  useEffect(() => {
    const params = new URLSearchParams();
    if (currentPage !== 1) {
      params.set('page', currentPage.toString());
    }
    if (exchangesPerPage !== 50) {
      params.set('per_page', exchangesPerPage.toString());
    }
    const paramString = params.toString();
    navigate(paramString ? `?${paramString}` : '', { replace: true });
  }, [currentPage, exchangesPerPage, navigate]);

  const totalPages = totalExchanges ? Math.ceil(totalExchanges / exchangesPerPage) : 1;

  // -------------------------------------------
  // Data Fetching: Gesamtzahl der Exchanges (mit Retry)
  // -------------------------------------------
  const fetchTotalExchangesData = async () => {
    setLoadingTotal(true);
    try {
      // GET /exchanges-data?per_page=...&page=...
      const { data } = await api.get('/exchanges-data', {
        params: {
          per_page: exchangesPerPage,
          page: currentPage,
        },
      });
      // data.totalExchanges => Gesamtzahl
      setTotalExchanges(data.totalExchanges);
      setLoadingTotal(false);
      retryCountTotalRef.current = 0;
    } catch (err) {
      console.error('Error fetching totalExchanges:', err);

      // Prüfen, ob es sich um ein Netzwerkproblem handeln könnte
      // (Bsp.: err.code === 'ERR_NETWORK', oder err.message 'Network Error')
      const isNetworkError =
        !err.response && (err.message.includes('Network') || err.message.includes('fetch'));

      if (isNetworkError) {
        if (retryCountTotalRef.current < maxRetries) {
          retryCountTotalRef.current++;
          const retryDelay = Math.pow(2, retryCountTotalRef.current) * 1000;
          console.log(
            `fetchTotalExchangesData: Retry in ${retryDelay / 1000} sec (Attempt ${retryCountTotalRef.current} of ${maxRetries})`
          );
          setTimeout(fetchTotalExchangesData, retryDelay);
          return;
        } else {
          console.log('Max retries reached for fetchTotalExchangesData, retrying in 5 seconds...');
          setTimeout(fetchTotalExchangesData, 5000);
          return;
        }
      } else {
        console.log('Non-network error in fetchTotalExchangesData, retrying in 5 seconds...');
        setTimeout(fetchTotalExchangesData, 5000);
      }
      setLoadingTotal(false);
    }
  };

  // -------------------------------------------
  // Data Fetching: Exchanges-Daten (mit Retry)
  // -------------------------------------------
  const fetchExchangesData = async (showLoading = false) => {
    if (showLoading) {
      setLoadingExchanges(true);
      setError(null);
    }
    try {
      const { data } = await api.get('/exchanges-data', {
        params: {
          per_page: exchangesPerPage,
          page: currentPage,
        },
      });
      // data.data => Array
      setExchanges(data.data);
      setError(null);
      if (showLoading) {
        setLoadingExchanges(false);
      }
      retryCountExchangesRef.current = 0;
    } catch (err) {
      console.error('fetchExchangesData error:', err);

      const isNetworkError =
        !err.response && (err.message.includes('Network') || err.message.includes('fetch'));

      if (isNetworkError) {
        if (retryCountExchangesRef.current < maxRetries) {
          retryCountExchangesRef.current++;
          const retryDelay = Math.pow(2, retryCountExchangesRef.current) * 1000;
          console.log(
            `fetchExchangesData: Retry in ${retryDelay / 1000} sec (Attempt ${retryCountExchangesRef.current} of ${maxRetries})`
          );
          setTimeout(() => {
            fetchExchangesData(showLoading);
          }, retryDelay);
          return;
        } else {
          console.log('Max retries reached for fetchExchangesData, retrying in 5 seconds...');
          setTimeout(() => {
            fetchExchangesData(showLoading);
          }, 5000);
          return;
        }
      } else {
        console.log('Non-network error in fetchExchangesData, retrying in 5 seconds...');
        setTimeout(() => {
          fetchExchangesData(showLoading);
        }, 5000);
      }

      if (showLoading) {
        setLoadingExchanges(false);
      }
    }
  };

  // -------------------------------------------
  // Initial Data Load and Polling
  // -------------------------------------------
  useEffect(() => {
    fetchTotalExchangesData();
    fetchExchangesData(true);

    // Refresh alle 11 Sekunden
    const intervalId = setInterval(() => {
      fetchTotalExchangesData();
      fetchExchangesData(false);
    }, 11000);

    return () => clearInterval(intervalId);
  }, [currentPage, exchangesPerPage]);

  // -------------------------------------------
  // Automatischer Reload bei Sichtbarkeitswechsel
  // -------------------------------------------
  useEffect(() => {
    function handleVisibilityChange() {
      if (!document.hidden) {
        fetchTotalExchangesData();
        fetchExchangesData(true);
      }
    }
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  // -------------------------------------------
  // Klick auf eine Zeile => Exchange-Website im neuen Tab
  // -------------------------------------------
  const handleExchangeClick = (exchange) => {
    window.open(exchange.url, '_blank');
  };

  // -------------------------------------------
  // Rendering
  // -------------------------------------------
  return (
    <SkeletonTheme baseColor="#ccc" highlightColor="#eee">
      <div className="coin-list-container">
        <h2 className="coin-list-title">
          <Trans id="title.exchanges">Exchange Liste</Trans>
        </h2>

        <p className="intro-text">
          <Trans id="exchangeList.intro">
            Unsere Exchange-Liste bietet dir einen Überblick über führende Kryptowährungsbörsen mit
            wichtigen Kennzahlen.
          </Trans>
        </p>

        <div className="coin-list-controls">
          <div className="coins-per-page">
            <label>
              {loading ? (
                <Skeleton width={100} />
              ) : (
                <Trans id="label.entriesPerPage">Exchanges pro Seite:</Trans>
              )}
            </label>
            {loading ? (
              <Skeleton width={100} height={30} />
            ) : (
              <SingleSelectNoSearch
                options={['25', '50', '100', '250']}
                selectedValue={String(exchangesPerPage)}
                onChange={(newVal) => {
                  setExchangesPerPage(parseInt(newVal, 10));
                  setCurrentPage(1);
                }}
                placeholder="Exchanges pro Seite"
              />
            )}
          </div>
        </div>

        {error && <div className="error-message">{error}</div>}

        {!error && (
          <>
            <table className="coin-list-table">
              <thead>
                <tr>
                  {loading
                    ? Array.from({ length: 7 }).map((_, i) => (
                        <th key={i}>
                          <Skeleton width="100%" />
                        </th>
                      ))
                    : (
                      <>
                        <th>
                          <Trans id="label.rank">Rank</Trans>
                        </th>
                        <th>
                          <Trans id="label.name">Name</Trans>
                        </th>
                        <th>
                          <Trans id="label.year">Jahr</Trans>
                        </th>
                        <th>
                          <Trans id="label.country">Land</Trans>
                        </th>
                        <th>
                          <Trans id="label.trustScore">Trust Score</Trans>
                        </th>
                        <th>
                          <Trans id="label.volume">24h Volumen (BTC)</Trans>
                        </th>
                        <th>
                          <Trans id="label.volumeNormalized">24h Volumen Normalized (BTC)</Trans>
                        </th>
                      </>
                    )}
                </tr>
              </thead>
              <tbody>
                {loading
                  ? Array.from({ length: exchangesPerPage }).map((_, i) => (
                      <ExchangeRow key={i} loading={true} />
                    ))
                  : exchanges.map((exchange) => (
                      <ExchangeRow
                        key={exchange.id}
                        exchange={exchange}
                        onClick={handleExchangeClick}
                        loading={false}
                      />
                    ))}
              </tbody>
            </table>
            {!loading && totalExchanges > 0 && (
              <div className="pagination-controls bottom-pagination-controls">
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={setCurrentPage}
                />
              </div>
            )}
          </>
        )}
      </div>
    </SkeletonTheme>
  );
};

export default ExchangeList;
