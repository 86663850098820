import React from 'react';
import { Trans} from '@lingui/macro';

const rewriteDescriptionLinks = (html) => {
  if (!html) return html;
  html = html.replace(
    /https:\/\/www\.coingecko\.com\/en\/coins\/([a-z0-9-]+)/g,
    (match, coinId) => `/coin-details?coin=${coinId}`
  );
  html = html.replace(/https:\/\/www\.coingecko\.com\/[^\"]+/g, '#');
  return html;
};

const Description = ({ description }) => {
  const modifiedDescription = rewriteDescriptionLinks(description);

  return (
    <div className="coin-description-card">
      <h3>
        <Trans id="label.description">Beschreibung</Trans>
      </h3>
      {typeof modifiedDescription === 'string' ? (
        <div dangerouslySetInnerHTML={{ __html: modifiedDescription }} />
      ) : (
        <p>{modifiedDescription}</p>
      )}
    </div>
  );
};

export default Description;
