// src/components/CoinList.js

import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import '../styles/CoinList.css';
import CoinRow from './CoinRow';
import SingleSelectNoSearch from '../utils/SingleSelectNoSearch';
import SingleSelectSearch from '../utils/SingleSelectSearch';
import { Pagination } from '../utils/StyledButton';
import { Trans } from '@lingui/macro';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

// Websocket-Updater
import { updateSymbols } from '../hooks/BinanceDataCoinList';
import api from '../services/api'; // <-- Neu: Unser zentrales Axios-Objekt

/**
 * Hilfsfunktionen
 */

// Kategorien abrufen
async function fetchCategories() {
  const { data } = await api.get('/coins-categories');
  return data;
}

// Gesamtzahl Coins ermitteln
async function fetchTotalCoins(category) {
  if (!category) {
    // /coins/all-with-count => { total }
    const { data } = await api.get('/coins/all-with-count');
    return data.total;
  } else {
    // /coins/category-full?category=XYZ => { total }
    const { data } = await api.get('/coins/category-full', {
      params: { category },
    });
    return data.total;
  }
}

const CoinList = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Lese URL-Parameter beim Initialisieren
  const searchParams = new URLSearchParams(location.search);
  const initialPage = parseInt(searchParams.get('page') || '1', 10);
  const initialPerPage = parseInt(searchParams.get('per_page') || '50', 10);
  const initialCategory = searchParams.get('category') || '';

  // ----------------------------------
  // State-Variablen
  // ----------------------------------
  const [coins, setCoins] = useState([]);
  const [loadingTotal, setLoadingTotal] = useState(true);
  const [loadingCoins, setLoadingCoins] = useState(true);
  const [error, setError] = useState(null);

  const [totalCoins, setTotalCoins] = useState(0);
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [coinsPerPage, setCoinsPerPage] = useState(initialPerPage);

  // Kategorien
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(initialCategory);

  // Währung (aus localStorage)
  const [vsCurrency, setVsCurrency] = useState('usd');

  // Websocket / Binance
  const prevDataRef = useRef([]);
  const pendingWsUpdatesRef = useRef({});

  const loading = loadingTotal || loadingCoins;

  // -------------------------------------------
  // Bei Änderung der URL (location.search) => Parameter updaten
  // -------------------------------------------
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const pageParam = parseInt(params.get('page') || '1', 10);
    const perPageParam = parseInt(params.get('per_page') || '50', 10);
    const catParam = params.get('category') || '';

    if (pageParam !== currentPage) setCurrentPage(pageParam);
    if (perPageParam !== coinsPerPage) setCoinsPerPage(perPageParam);
    if (catParam !== selectedCategory) setSelectedCategory(catParam);
  }, [location.search]);

  // -------------------------------------------
  // (A) Kategorien laden => nur 1x
  // -------------------------------------------
  useEffect(() => {
    (async () => {
      try {
        const cats = await fetchCategories();
        setCategories(cats);
      } catch (err) {
        console.error('Error loading categories', err);
      }
    })();
  }, []);

  // -------------------------------------------
  // (B) Währung aus localStorage
  // -------------------------------------------
  useEffect(() => {
    const savedCurrency = localStorage.getItem('vsCurrency') || 'usd';
    setVsCurrency(savedCurrency);
  }, []);

  // -------------------------------------------
  // (C) totalCoins laden
  // -------------------------------------------
  useEffect(() => {
    setLoadingTotal(true);
    fetchTotalCoins(selectedCategory)
      .then((total) => {
        setTotalCoins(total);
        setLoadingTotal(false);
      })
      .catch((err) => {
        console.error('Error fetching totalCoins:', err);
        setError(err.message);
        setLoadingTotal(false);
      });
  }, [selectedCategory]);

  // -------------------------------------------
  // (D) Coins laden (+Sparkline +Category)
  // -------------------------------------------
  const updateCoinsState = (newData) => {
    const oldData = prevDataRef.current || [];
    const oldMap = {};
    oldData.forEach((c) => {
      oldMap[c.id] = c;
    });

    const finalCoins = newData.map((newCoin) => {
      const oldCoin = oldMap[newCoin.id];
      const isBaseCurrency = vsCurrency.toLowerCase() === newCoin.symbol?.toLowerCase();

      // Falls wir keinen oldCoin oder die vsCurrency sich geändert hat
      if (!oldCoin || vsCurrency !== oldCoin.lastVsCurrency) {
        return {
          ...newCoin,
          updatedByBinance: false,
          coingeckoPriceUsd: newCoin.current_price_usd, // USD-Wert
          coingeckoPriceVs: newCoin.current_price,       // vsCurrency-Wert
          current_price: isBaseCurrency ? 1 : newCoin.current_price,
          initialPrice: isBaseCurrency ? 1 : newCoin.current_price,
          initialPercentageChange: isBaseCurrency ? 0 : newCoin.price_change_percentage_24h,
          lastVsCurrency: vsCurrency,
        };
      }
      // Wir übernehmen alte WebSocket-Daten, falls updatedByBinance=true
      return {
        ...oldCoin,
        market_cap_rank: newCoin.market_cap_rank,
        market_cap: newCoin.market_cap,
        total_volume: newCoin.total_volume,
        coingeckoPriceUsd: newCoin.current_price_usd,
        coingeckoPriceVs: newCoin.current_price,
        current_price: oldCoin.updatedByBinance
          ? oldCoin.current_price
          : newCoin.current_price,
        price_change_percentage_24h: oldCoin.updatedByBinance
          ? oldCoin.price_change_percentage_24h
          : newCoin.price_change_percentage_24h,
      };
    });

    setCoins(finalCoins);
    prevDataRef.current = finalCoins;
  };

  const fetchCoinsData = async (showLoading = false) => {
    if (showLoading) {
      setLoadingCoins(true);
    }

    try {
      // Build Query
      const params = {
        vs_currency: vsCurrency,
        per_page: coinsPerPage.toString(),
        page: currentPage.toString(),
        sparkline: 'true',
      };
      if (selectedCategory) {
        params.category = selectedCategory;
      }

      // Request: GET /coins-markets
      const { data } = await api.get('/coins-markets', { params });
      // data => Array mit Coin-Objekten (Coingecko-ähnlich)

      // => fetch /prices?currency=usd&ids=...
      const coinIds = data.map((coin) => coin.id).join(',');
      let priceData = {};
      try {
        const respPrice = await api.get('/prices', {
          params: { currency: 'usd', ids: coinIds },
        });
        priceData = respPrice.data;
      } catch (priceError) {
        console.error('Error fetching coin prices:', priceError);
        // fallback = leeres Objekt
      }

      const merged = data.map((coin) => {
        // priceData[coin.id]?.usd
        const pd = priceData[coin.id];
        return {
          ...coin,
          current_price_usd: pd ? pd.usd : null,
        };
      });

      // State aktualisieren
      updateCoinsState(merged);

      // Fehlerreset
      setError(null);

      // Websocket-Symbole aktualisieren
      const symbolsArray = merged.map((c) => c.symbol?.toLowerCase()).filter(Boolean);
      updateSymbols(symbolsArray, (symbol, { price, percentageChange }) => {
        pendingWsUpdatesRef.current[symbol.toLowerCase()] = { price, percentageChange };
      });

      if (showLoading) {
        setLoadingCoins(false);
      }
    } catch (err) {
      console.error('fetchCoinsData error:', err);
      setError(err.message);
      if (showLoading) {
        setLoadingCoins(false);
      }
    }
  };

  // -------------------------------------------
  // (E) useEffect => Coins laden + Polling
  // -------------------------------------------
  useEffect(() => {
    fetchCoinsData(true);

    // Refresh alle 11s
    const intervalId = setInterval(() => {
      fetchCoinsData(false);
    }, 11000);

    return () => clearInterval(intervalId);
  }, [currentPage, coinsPerPage, vsCurrency, selectedCategory]);

  // -------------------------------------------
  // (F) Sichtbarkeitswechsel => neu laden
  // -------------------------------------------
  useEffect(() => {
    function handleVisibilityChange() {
      if (!document.hidden) {
        fetchCoinsData(true);
      }
    }
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [currentPage, coinsPerPage, vsCurrency, selectedCategory]);

  // -------------------------------------------
  // (G) Websocket-Update (alle 3s)
  // -------------------------------------------
  useEffect(() => {
    const intervalId = setInterval(() => {
      const updates = { ...pendingWsUpdatesRef.current };
      pendingWsUpdatesRef.current = {};

      const symbols = Object.keys(updates);
      if (!symbols.length) return;

      setCoins((prevCoins) => {
        const newCoins = prevCoins.map((coin) => {
          const sym = coin.symbol?.toLowerCase();
          if (!sym || !symbols.includes(sym)) return coin;

          // Binance schickt uns Price in USD
          const binancePriceUsd = updates[sym].price;
          let finalPrice;
          const isBaseCurrency = vsCurrency.toLowerCase() === sym;

          if (vsCurrency.toLowerCase() === 'usd') {
            finalPrice = binancePriceUsd;
          } else if (isBaseCurrency) {
            // Falls die vsCurrency identisch mit dem Symbol ist
            finalPrice = 1; 
          } else if (coin.coingeckoPriceUsd && coin.coingeckoPriceVs) {
            // wir rechnen den Binance-USD-Preis um auf vsCurrency
            // => factor = coingeckoPriceVs / coingeckoPriceUsd
            const factor = coin.coingeckoPriceVs / coin.coingeckoPriceUsd;
            finalPrice = binancePriceUsd * factor;
          } else {
            finalPrice = binancePriceUsd;
          }

          const percentageChange = isBaseCurrency
            ? 0
            : coin.initialPercentageChange +
              ((finalPrice - coin.initialPrice) / coin.initialPrice) * 100;

          return {
            ...coin,
            current_price: finalPrice,
            price_change_percentage_24h: percentageChange,
            updatedByBinance: true,
          };
        });
        prevDataRef.current = newCoins;
        return newCoins;
      });
    }, 3000);

    return () => clearInterval(intervalId);
  }, [vsCurrency]);

  // -------------------------------------------
  // (H) URL-Aufbau => ?page=..&per_page=..&category=..
  // -------------------------------------------
  useEffect(() => {
    const params = new URLSearchParams();
    if (currentPage !== 1) {
      params.set('page', currentPage.toString());
    }
    if (coinsPerPage !== 50) {
      params.set('per_page', coinsPerPage.toString());
    }
    if (selectedCategory) {
      params.set('category', selectedCategory);
    }
    const paramString = params.toString();
    navigate(paramString ? `?${paramString}` : '', { replace: true });
  }, [currentPage, coinsPerPage, selectedCategory, navigate]);

  // Gesamtzahl Seiten
  const totalPages = totalCoins ? Math.ceil(totalCoins / coinsPerPage) : 1;

  // -------------------------------------------
  // Klick-Handler
  // -------------------------------------------
  const handleCoinClick = (coinId) => {
    navigate(`/coin-details?coin=${coinId}`);
  };

  const handleCategorySelect = (catValue) => {
    setSelectedCategory(catValue);
    setCurrentPage(1);
  };

  // -------------------------------------------
  // Rendering
  // -------------------------------------------
  return (
    <SkeletonTheme baseColor="#ccc" highlightColor="#eee">
      <div className="coin-list-container">
        <h2 className="coin-list-title">
          {selectedCategory ? `Coinliste - ${selectedCategory}` : <Trans id="title.cryptocurrencies">Coinliste</Trans>}
        </h2>

        <p className="intro-text">
          <Trans id="coinList.intro">
            Unsere Coin-Liste präsentiert dir eine präzise Übersicht aktueller Marktdaten –
            von Preisen über Handelsvolumen bis hin zur gesamten Marktkapitalisierung.
            Mit nur einem Klick auf einen Coin erhältst du umfassende Detailinformationen,
            sodass du den Markt in seiner ganzen Tiefe erfassen und fundierte Entscheidungen
            treffen kannst.
          </Trans>
        </p>

        <div className="coin-list-controls">
          {/* Kategorie-Selector */}
          <div className="coins-per-page">
            <label>
              {loading ? (
                <Skeleton width={100} />
              ) : (
                <Trans id="label.category">Kategorie:</Trans>
              )}
            </label>
            {loading ? (
              <Skeleton width={130} height={30} />
            ) : (
              <div style={{ minWidth: '270px' }}>
                <SingleSelectSearch
                  options={categories}
                  selectedValue={selectedCategory}
                  onChange={handleCategorySelect}
                  placeholder="Kategorie auswählen"
                />
              </div>
            )}
          </div>

          {/* Coins per Page */}
          <div className="coins-per-page">
            <label>
              {loading ? (
                <Skeleton width={100} />
              ) : (
                <Trans id="label.entriesPerPage">Coins pro Seite:</Trans>
              )}
            </label>
            {loading ? (
              <Skeleton width={100} height={30} />
            ) : (
              <SingleSelectNoSearch
                options={['25', '50', '100', '250']}
                selectedValue={String(coinsPerPage)}
                onChange={(newVal) => {
                  setCoinsPerPage(parseInt(newVal, 10));
                  setCurrentPage(1);
                }}
                placeholder="Coins pro Seite"
              />
            )}
          </div>
        </div>

        {error && <div className="error-message">{error}</div>}

        {!error && !loading && coins.length === 0 ? (
          <div className="no-coins-message">
            <Trans id="coinList.noCoins">Keine Coins gefunden.</Trans>
          </div>
        ) : (
          <>
            <table className="coin-list-table">
              <thead>
                <tr>
                  {loading
                    ? Array.from({ length: 7 }).map((_, i) => (
                        <th key={i}>
                          <Skeleton width="100%" />
                        </th>
                      ))
                    : (
                      <>
                        <th><Trans id="label.rank">Rank</Trans></th>
                        <th><Trans id="label.name">Name</Trans></th>
                        <th>
                          <Trans
                            id="label.priceWithCurrency"
                            values={{ currency: vsCurrency.toUpperCase() }}
                          >
                            Preis ({vsCurrency.toUpperCase()})
                          </Trans>
                        </th>
                        <th><Trans id="label.24hChange">24h %</Trans></th>
                        <th><Trans id="label.marketCap">Marktkap.</Trans></th>
                        <th><Trans id="label.24hVolume">24h Volumen</Trans></th>
                        <th><Trans id="label.7dSparkline">Sparkline (7 Tage)</Trans></th>
                      </>
                    )}
                </tr>
              </thead>
              <tbody>
                {loading
                  ? Array.from({ length: coinsPerPage }).map((_, i) => (
                      <CoinRow key={i} loading={true} />
                    ))
                  : coins.map((coin) => (
                      <CoinRow
                        key={coin.id}
                        coin={coin}
                        onClick={handleCoinClick}
                        vsCurrency={vsCurrency}
                        loading={false}
                      />
                    ))}
              </tbody>
            </table>

            {/* Pagination */}
            {!loading && totalCoins > 0 && (
              <div className="pagination-controls bottom-pagination-controls">
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={setCurrentPage}
                />
              </div>
            )}
          </>
        )}
      </div>
    </SkeletonTheme>
  );
};

export default CoinList;
