// src/components/FearAndGreedIndex.js

import React from 'react';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import { t, Trans } from '@lingui/macro';
import { format } from 'date-fns';
import { enUS, de } from 'date-fns/locale';

import SingleSelectNoSearch from '../utils/SingleSelectNoSearch';
import { formatDate, formatFutureDate } from '../utils/formatDate';
import { useGlobalData } from '../hooks/useGlobalData';
import '../styles/FearAndGreedIndex.css';

/* ------------------------------------
   Locale und Übersetzungs-Kram
------------------------------------ */
const storedLang = localStorage.getItem('language') || 'en';
const localeMapping = {
  en: enUS,
  de: de,
};

/* ------------------------------------
   Custom Legend Plugin
------------------------------------ */
const customLegendPlugin = {
  id: 'customLegend',
  beforeDraw: (chart, args, options) => {
    const {
      ctx,
      chartArea: { left, top, width },
    } = chart;
    const isMobile = chart.width < 768;
    const itemsPerRow = isMobile ? 2 : options.itemsPerRow || 5;

    // Legenden-Items – Übersetzungen via t()
    const legendItems = [
      { label: t({ id: 'fearGreedIndex.legendExtremeFear', message: 'Extreme Fear' }), color: '#d9534f' },
      { label: t({ id: 'fearGreedIndex.legendFear', message: 'Fear' }), color: '#f0ad4e' },
      { label: t({ id: 'fearGreedIndex.legendNeutral', message: 'Neutral' }), color: '#ffeb3b' },
      { label: t({ id: 'fearGreedIndex.legendGreed', message: 'Greed' }), color: '#8bc34a' },
      { label: t({ id: 'fearGreedIndex.legendExtremeGreed', message: 'Extreme Greed' }), color: '#5cb85c' },
    ];

    const boxSize = options.boxSize || 10;
    const textSpacing = options.textSpacing || 4;
    const spacing = options.spacing || 8;
    const verticalSpacing = options.verticalSpacing || 8;
    const offsetTop = options.offsetTop || 0;

    ctx.save();
    ctx.font = options.font || '12px sans-serif';
    ctx.textAlign = 'left';
    ctx.textBaseline = 'middle';
    const textColor = options.textColor || 'rgba(255, 255, 255, 0.7)';
    ctx.fillStyle = textColor;
    const globalAlpha = options.globalAlpha !== undefined ? options.globalAlpha : 0.8;
    ctx.globalAlpha = globalAlpha;

    // Zeilen bilden
    let rows = [];
    for (let i = 0; i < legendItems.length; i += itemsPerRow) {
      rows.push(legendItems.slice(i, i + itemsPerRow));
    }

    rows.forEach((row, rowIndex) => {
      // Breite ermitteln
      let rowTotalWidth = 0;
      row.forEach((item, i) => {
        const textWidth = ctx.measureText(item.label).width;
        rowTotalWidth += boxSize + textSpacing + textWidth;
        if (i < row.length - 1) {
          rowTotalWidth += spacing;
        }
      });

      const centerX = left + width / 2;
      let startX = centerX - rowTotalWidth / 2;
      let posY = top + offsetTop + rowIndex * (boxSize + verticalSpacing);

      row.forEach((item, i) => {
        ctx.fillStyle = item.color;
        ctx.fillRect(startX, posY - boxSize / 2, boxSize, boxSize);
        startX += boxSize + textSpacing;
        ctx.fillStyle = textColor;
        ctx.fillText(item.label, startX, posY);
        const textWidth = ctx.measureText(item.label).width;
        startX += textWidth;
        if (i < row.length - 1) {
          startX += spacing;
        }
      });
    });
    ctx.restore();
  },
};

/* ------------------------------------
   Farb-Logik für den Gauge
------------------------------------ */
function getGaugeColor(value) {
  if (value <= 24) return '#d9534f'; // Extreme Fear
  if (value <= 49) return '#f0ad4e'; // Fear
  if (value <= 74) return '#ffeb3b'; // Neutral
  if (value <= 89) return '#8bc34a'; // Greed
  return '#5cb85c'; // Extreme Greed
}

/* ------------------------------------
   Klassifizierung übersetzen
------------------------------------ */
function translateClassification(value) {
  switch (value) {
    case 'Extreme Fear':
      return t({ id: 'fearGreedIndex.legendExtremeFear', message: 'Extreme Fear' });
    case 'Fear':
      return t({ id: 'fearGreedIndex.legendFear', message: 'Fear' });
    case 'Neutral':
      return t({ id: 'fearGreedIndex.legendNeutral', message: 'Neutral' });
    case 'Greed':
      return t({ id: 'fearGreedIndex.legendGreed', message: 'Greed' });
    case 'Extreme Greed':
      return t({ id: 'fearGreedIndex.legendExtremeGreed', message: 'Extreme Greed' });
    default:
      return value;
  }
}

/* ------------------------------------
   Hauptkomponente: FearAndGreedIndex
------------------------------------ */
const FearAndGreedIndex = () => {
  const {
    fearGreedLoading,
    fearGreedError,
    fearGreedCurrentValue,
    fearGreedChartData,
    fearGreedTimeRange,
    setFearGreedTimeRange,
  } = useGlobalData();

  // Lokaler State, um anzuzeigen, ob Chart-Daten aktuell geladen werden
  const [localChartLoading, setLocalChartLoading] = React.useState(false);

  // Sobald das Timeframe wechselt, setzen wir den Lade-Status
  React.useEffect(() => {
    setLocalChartLoading(true);
  }, [fearGreedTimeRange]);

  // Wenn neue Chart-Daten da sind, setzen wir den Lade-Status zurück
  React.useEffect(() => {
    if (fearGreedChartData) {
      setLocalChartLoading(false);
    }
  }, [fearGreedChartData]);

  // Globales Laden: Falls CURRENT-Daten noch nicht da sind, zeigen wir eine globale Ladeanzeige
  if (fearGreedLoading && !fearGreedCurrentValue) {
    return (
      <div className="fear-greed-loading">
        <Trans id="fearGreedIndex.loading">Lade Fear &amp; Greed Index...</Trans>
      </div>
    );
  }
  if (fearGreedError) {
    return <div className="fear-greed-error">{fearGreedError}</div>;
  }
  if (!fearGreedCurrentValue) {
    return null;
  }

  // Chart-Daten vorbereiten
  const chartData = fearGreedChartData;
  let lineData = null;
  let lineOptions = {};
  if (chartData) {
    lineData = {
      datasets: [
        {
          label: 'F&G Index',
          data: chartData,
          borderColor: 'rgba(255, 99, 132, 1)',
          borderWidth: 1.2,
          segment: {
            borderColor: (ctx) => {
              const currentVal = ctx.p0.parsed.y;
              return getGaugeColor(currentVal);
            },
          },
          backgroundColor: 'rgba(255, 99, 132, 0.1)',
          tension: 0.2,
          pointRadius: 0,
        },
      ],
    };

    const timeUnit = ['30d', '90d'].includes(fearGreedTimeRange) ? 'day' : 'month';
    const dayDisplayFormat =
      ['30d', '90d'].includes(fearGreedTimeRange)
        ? storedLang === 'de'
          ? 'dd.MM'
          : 'MM/dd'
        : storedLang === 'de'
          ? 'dd.MM.yyyy'
          : 'P';
    const tooltipDateFormat = storedLang === 'de' ? 'dd.MM.yyyy' : 'MM/dd/yyyy';

    lineOptions = {
      responsive: true,
      maintainAspectRatio: false,
      interaction: { mode: 'index', intersect: false },
      layout: { padding: { top: 10, bottom: 10 } },
      scales: {
        x: {
          type: 'time',
          time: {
            unit: timeUnit,
            tooltipFormat: tooltipDateFormat,
            displayFormats: {
              day: dayDisplayFormat,
              month: storedLang === 'de' ? 'MMM yyyy' : 'LLL yyyy',
            },
            locale: localeMapping[storedLang],
          },
          min: undefined,
          max: undefined,
        },
        y: { min: 0, max: 100 },
      },
      plugins: {
        legend: { display: false },
        tooltip: {
          displayColors: false,
          callbacks: {
            title: (tooltipItems) => {
              const date = tooltipItems[0].parsed.x;
              return format(new Date(date), tooltipDateFormat, {
                locale: localeMapping[storedLang],
              });
            },
            footer: (tooltipItems) => {
              if (tooltipItems.length) {
                const classification = tooltipItems[0].raw.classification;
                return translateClassification(classification);
              }
              return '';
            },
          },
        },
        datalabels: { display: false },
      },
    };
  }

  const numericValue = Number(fearGreedCurrentValue.value) || 0;
  const gaugeColor = getGaugeColor(numericValue);
  const classification = fearGreedCurrentValue.value_classification;
  const updatedAt = formatDate(
    new Date(Number(fearGreedCurrentValue.timestamp) * 1000).toISOString()
  );
  const timeUntilUpdateSec = Number(fearGreedCurrentValue.time_until_update || 0);
  const futureUpdate = new Date(Date.now() + timeUntilUpdateSec * 1000).toISOString();

  return (
    <div className="fear-greed-card">
      <div className="chart-header2">
        <h3>
          <Trans id="fearGreedIndex.title">Fear &amp; Greed Index</Trans>
        </h3>
      </div>
      <div className="fear-greed-body">
        {/* LINKER BEREICH: Gauge + Infos */}
        <div className="fear-greed-left">
          <div className="fear-greed-gauge">
            <CircularProgressbar
              value={numericValue}
              maxValue={100}
              text={`${numericValue}`}
              styles={buildStyles({
                pathColor: gaugeColor,
                textColor: '#fff',
                trailColor: '#333',
              })}
            />
          </div>
          <div className="fear-greed-info">
            <div className="fear-greed-classification">
              {translateClassification(classification)}
            </div>
            <div className="fear-greed-updated">
              <small>
                <Trans id="fearGreedIndex.lastUpdate">Last Update:</Trans> {updatedAt}
              </small>
            </div>
            <div className="fear-greed-nextupdate">
              <small>
                <Trans id="fearGreedIndex.nextUpdate">Next Update:</Trans>{' '}
                {formatFutureDate(futureUpdate)}
              </small>
            </div>
          </div>
        </div>

        {/* RECHTER BEREICH: Timeframe-Selector + Chart */}
        <div className="fear-greed-right">
          <div className="timeframe-select-wrapper">
            <SingleSelectNoSearch
              options={['30d', '90d', '1y', '3y', '5y']}
              selectedValue={fearGreedTimeRange}
              onChange={(val) => setFearGreedTimeRange(val)}
              placeholder={t({ id: 'fearGreedIndex.selectTimeframe', message: 'Zeitraum auswählen' })}
              minWidth={65}
            />
          </div>
          <div className="fear-greed-chart">
            {localChartLoading ? (
              <div
                className="fear-greed-chart-loading"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%',
                }}
              >
                <Trans id="message.loadingData">Lade Daten...</Trans>
              </div>
            ) : (
              <Line data={lineData} options={lineOptions} plugins={[customLegendPlugin]} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FearAndGreedIndex;
