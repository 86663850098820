// CryptoBotSimulator.js
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import '../styles/CryptoBotSimulator.css';
import { SMA } from 'technicalindicators';

// WebSocket-Hook
import { useBinanceData } from '../hooks/useBinanceData';

// Importiere alle Strategien
import {
  buyDipStrategy,
  smaCrossoverStrategy,
  fixedProfitStrategy,
  stopLossStrategy,
  combinedStopLossAndProfitStrategy,
  partialTakeProfitStrategy,
  trailingStopStrategy,
  computePositionSize,
} from './CryptoBotStrategyManager';

Chart.register(...registerables, annotationPlugin);

const Modal = ({ message, onClose, onRedirect }) => {
  return (
    <div className="cbot-modal-overlay">
      <div className="cbot-modal-content">
        <p>{message}</p>
        <button onClick={onRedirect} className="cbot-redirect-button">
          Weiter zur echten Börse
        </button>
        <button onClick={onClose} className="cbot-close-button">
          Später
        </button>
      </div>
    </div>
  );
};

const CryptoBotSimulator = () => {
  const navigate = useNavigate();

  // ====== Allgemeine States ======
  const [selectedSymbol, setSelectedSymbol] = useState('BTCUSDT');
  const [availableSymbols] = useState([
    'BTCUSDT',
    'ETHUSDT',
    'BNBUSDT',
    'ADAUSDT',
    'DOGEUSDT',
    'XRPUSDT',
  ]);
  const [balance, setBalance] = useState(1000);
  const [botTrades, setBotTrades] = useState([]);
  const [activeTrade, setActiveTrade] = useState(null);

  // ====== Chart-Daten ======
  const [chartData, setChartData] = useState([]);
  const [labels, setLabels] = useState([]);
  const [smaData, setSmaData] = useState([]);
  const [smaPeriod, setSmaPeriod] = useState(14);
  const chartRef = useRef(null);
  const [annotations, setAnnotations] = useState([]);

  // ====== Strategien Kauf ======
  const [buyStrategy, setBuyStrategy] = useState('buyDip');
  const [buyDipPercentage, setBuyDipPercentage] = useState(3);

  // ====== Strategien Verkauf (alte und neue) ======
  const [sellStrategy, setSellStrategy] = useState('fixedProfit');
  // fixProfit
  const [sellProfitPercentage, setSellProfitPercentage] = useState(10);
  // stopLoss
  const [stopLossFixedPercentage, setStopLossFixedPercentage] = useState(5);
  // combined
  const [combinedStopLossPercentage, setCombinedStopLossPercentage] = useState(5);
  const [combinedTakeProfitPercentage, setCombinedTakeProfitPercentage] = useState(10);

  // ====== Teilverkauf (Partial Take Profit) ======
  const [partialTakeProfitActive, setPartialTakeProfitActive] = useState(false);
  const [partialTpLevels] = useState([5, 10]);
  const [partialTpFractions] = useState([0.5, 1]);

  // ====== Trailing Stop ======
  const [trailingStopActive, setTrailingStopActive] = useState(false);
  const [trailingStopPercentage, setTrailingStopPercentage] = useState(5);

  // ====== Positionssizing & Risikomanagement ======
  const [useRiskBasedPositionSizing, setUseRiskBasedPositionSizing] = useState(false);
  const [riskPercentage, setRiskPercentage] = useState(2);
  const [initialStopLossPercent, setInitialStopLossPercent] = useState(5);
  const [investmentAmount, setInvestmentAmount] = useState(100);

  // ====== Bot-Steuerung ======
  const [isRunning, setIsRunning] = useState(false);
  const [isStopping, setIsStopping] = useState(false);

  // ====== UI Hilfsstates ======
  const [showModal, setShowModal] = useState(false);
  const [tooltipMessage, setTooltipMessage] = useState('');
  const [priceChangeColor, setPriceChangeColor] = useState(null);

  // ====== Live-Preis vom WebSocket ======
  const rawSymbol = selectedSymbol.replace('USDT', '');
  const binanceEntry = useBinanceData(rawSymbol);
  const currentPrice = binanceEntry.binancePrice;

  // ====== Historische Daten laden ======
  const fetchHistoricalData = async () => {
    try {
      const response = await fetch(
        `https://api.binance.com/api/v3/klines?symbol=${selectedSymbol}&interval=1m&limit=100`
      );
      const data = await response.json();
      return data.map((item) => ({
        time: new Date(item[0]).toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
        }),
        price: parseFloat(item[4]),
      }));
    } catch (error) {
      console.error('Fehler beim Abrufen der historischen Daten:', error);
      return [];
    }
  };

  useEffect(() => {
    const initData = async () => {
      const historical = await fetchHistoricalData();
      if (historical.length > 0) {
        setChartData(historical.map((d) => d.price));
        setLabels(historical.map((d) => d.time));
        const smaVals = SMA.calculate({
          period: smaPeriod,
          values: historical.map((d) => d.price),
        });
        setSmaData(smaVals);
      } else {
        setChartData([]);
        setLabels([]);
        setSmaData([]);
      }
      setActiveTrade(null);
      setAnnotations([]);
    };
    initData();
  }, [selectedSymbol, smaPeriod]);

  // ====== Live-Preis-Updates ======
  useEffect(() => {
    if (currentPrice == null) return;

    // Chart
    setChartData((prev) => {
      if (prev.length === 0) return [currentPrice];
      const updated = [...prev.slice(1), currentPrice];

      const prevPrice = prev[prev.length - 1];
      if (currentPrice > prevPrice) {
        setPriceChangeColor('var(--price-up-color)');
      } else if (currentPrice < prevPrice) {
        setPriceChangeColor('var(--price-down-color)');
      } else {
        setPriceChangeColor(null);
      }
      return updated;
    });

    // Labels
    setLabels((prev) => {
      const updated = [
        ...prev.slice(1),
        new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
      ];
      return updated;
    });

    // SMA
    setSmaData((prevSma) => {
      const newData = [...chartData, currentPrice];
      if (newData.length < smaPeriod) return prevSma;
      const smaVals = SMA.calculate({ period: smaPeriod, values: newData });
      return smaVals;
    });

    // Bot-Logik
    if (isRunning) {
      handleBuyDecision(currentPrice);
      handleSellDecision(currentPrice);
    }
  }, [currentPrice, isRunning]);

  // Farbe zurücksetzen
  useEffect(() => {
    let t;
    if (priceChangeColor) {
      t = setTimeout(() => setPriceChangeColor(null), 1000);
    }
    return () => t && clearTimeout(t);
  }, [priceChangeColor]);

  // Modal nach 3 Verkäufen
  useEffect(() => {
    const sellTrades = botTrades.filter((t) => t.type === 'sell');
    if (sellTrades.length > 0 && sellTrades.length % 3 === 0 && !isStopping && !activeTrade) {
      setShowModal(true);
      setTooltipMessage('3 Trades abgeschlossen! Möchtest du echtes Trading ausprobieren?');
    }
  }, [botTrades, isStopping, activeTrade]);

  // ====== Annotations ======
  const addAnnotation = (time, type, price) => {
    setAnnotations((prev) => [
      ...prev,
      {
        type: 'line',
        mode: 'vertical',
        scaleID: 'x',
        value: time,
        borderColor: type === 'buy' ? 'var(--price-up-color)' : 'var(--price-down-color)',
        borderWidth: 2,
        label: {
          enabled: true,
          content: `${type === 'buy' ? 'Kauf' : 'Verkauf'} @ ${price.toFixed(2)}`,
          position: 'end',
          backgroundColor: 'rgba(0,0,0,0.7)',
          color: '#fff',
        },
      },
    ]);
  };

  // ====== Kaufstrategie ======
  const handleBuyDecision = (price) => {
    if (activeTrade) return;

    switch (buyStrategy) {
      case 'buyDip':
        buyDipStrategy({
          chartData,
          price,
          buyDipPercentage,
          executeBuy,
        });
        break;
      case 'smaCrossover':
        smaCrossoverStrategy({
          chartData,
          smaData,
          price,
          smaPeriod,
          executeBuy,
        });
        break;
      default:
        break;
    }
  };

  // ====== Verkaufsstrategie (Haupt + optional) ======
  const handleSellDecision = (price) => {
    if (!activeTrade || activeTrade.type !== 'buy') return;

    // 1) Haupt-Verkaufsstrategie
    switch (sellStrategy) {
      case 'fixedProfit':
        fixedProfitStrategy({
          price,
          activeTrade,
          sellProfitPercentage,
          executeSellAll,
        });
        break;
      case 'stopLoss':
        stopLossStrategy({
          price,
          activeTrade,
          stopLossPercentage: stopLossFixedPercentage,
          executeSellAll,
        });
        break;
      case 'combinedStopLossTakeProfit':
        combinedStopLossAndProfitStrategy({
          price,
          activeTrade,
          stopLossPercentage: combinedStopLossPercentage,
          takeProfitPercentage: combinedTakeProfitPercentage,
          executeSellAll,
        });
        break;
      default:
        break;
    }

    // Prüfen, ob Hauptstrategie den Trade bereits geschlossen hat
    if (!activeTrade || activeTrade.type !== 'buy') {
      return; // Position weg => kein Teilverkauf / Trailing Stop nötig
    }

    // 2) Teilverkäufe (optional)
    if (partialTakeProfitActive) {
      partialTakeProfitStrategy({
        price,
        activeTrade,
        partialTpLevels,
        partialTpFractions,
        executeSellFraction,
      });
    }

    // Prüfen, ob durch Teilverkauf komplett raus
    if (!activeTrade || activeTrade.type !== 'buy') return;

    // 3) Trailing Stop (optional)
    if (trailingStopActive) {
      trailingStopStrategy({
        price,
        activeTrade,
        trailingStopPercentage,
        executeSellAll,
        onUpdateMaxPrice: (newMax) => {
          // maxPriceSinceEntry aktualisieren
          setActiveTrade((prev) => (prev ? { ...prev, maxPriceSinceEntry: newMax } : prev));
        },
      });
    }
  };

  // ====== Kauf-Funktion ======
  const executeBuy = (price) => {
    if (balance <= 0) return;

    // Positionsgröße
    let finalInvestment = investmentAmount;
    const estimatedSL = price * (1 - initialStopLossPercent / 100);
    if (useRiskBasedPositionSizing) {
      const res = computePositionSize({
        balance,
        riskPercentage,
        buyPrice: price,
        stopLossPrice: estimatedSL,
      });
      finalInvestment = res.positionSizeInUSD;
    }

    // Falls größer als Kontostand => limitieren
    if (finalInvestment > balance) {
      finalInvestment = balance;
    }
    if (finalInvestment <= 0) return;

    const quantity = finalInvestment / price;
    const ts = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

    const newTrade = {
      type: 'buy',
      price,
      amount: finalInvestment,
      quantity,
      time: ts,
      partialSalesExecuted: [],
      maxPriceSinceEntry: price,
    };

    setActiveTrade(newTrade);
    setBotTrades((prev) => [
      ...prev,
      {
        ...newTrade,
        source: 'Bot',
      },
    ]);
    setBalance((prev) => prev - finalInvestment);
    addAnnotation(ts, 'buy', price);
  };

  // ====== Verkauf (alles) ======
  const executeSellAll = (price, source = 'Bot') => {
    if (!activeTrade) return;

    const { amount, price: buyPrice, quantity } = activeTrade;
    const profit = quantity * (price - buyPrice);
    const ts = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

    setActiveTrade(null);
    setBalance((prev) => prev + amount + profit);

    setBotTrades((prev) => [
      ...prev,
      {
        type: 'sell',
        price,
        profit,
        source,
        time: ts,
      },
    ]);

    addAnnotation(ts, 'sell', price);

    if (isStopping) {
      setIsStopping(false);
      setIsRunning(false);
    }
  };

  // ====== Teilverkauf ======
  const executeSellFraction = (price, fraction, source = 'Bot (Teilverkauf)') => {
    if (!activeTrade) return;
    const { amount, price: buyPrice, quantity } = activeTrade;
    if (fraction <= 0 || fraction > 1) return;

    const partUSD = amount * fraction;
    const partCoins = quantity * fraction;
    const partProfit = partCoins * (price - buyPrice);

    const newAmount = amount - partUSD;
    const newQuantity = quantity - partCoins;

    const ts = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    setBalance((prev) => prev + partUSD + partProfit);

    // Komplett raus?
    const isClosed = newQuantity < 0.0000001 || newAmount < 0.0001;
    if (isClosed) {
      setActiveTrade(null);
    } else {
      // Update partial position
      setActiveTrade((prev) => {
        if (!prev) return null;
        return {
          ...prev,
          amount: newAmount,
          quantity: newQuantity,
        };
      });
    }

    setBotTrades((prev) => [
      ...prev,
      {
        type: 'sell',
        price,
        profit: partProfit,
        source,
        time: ts,
      },
    ]);

    addAnnotation(ts, 'sell', price);

    if (isStopping && isClosed) {
      setIsStopping(false);
      setIsRunning(false);
    }
  };

  // ====== Manuelles Sofortverkaufen (alles) ======
  const immediateSell = () => {
    if (!activeTrade) return;
    executeSellAll(currentPrice, 'Manuell');
  };

  const immediateSellButtonLabel = () => {
    if (!activeTrade) return 'Sofort verkaufen';
    const { price: buyPrice, quantity } = activeTrade;
    const profit = quantity * (currentPrice - buyPrice);
    return `Sofort verkaufen (${profit >= 0 ? '+' : ''}${profit.toFixed(2)} USD)`;
  };

  // Bot starten/stoppen
  const startBot = () => {
    setIsRunning(true);
    setShowModal(false);
  };
  const stopBot = () => {
    if (activeTrade) {
      setIsStopping(true);
    } else {
      setIsRunning(false);
    }
  };

  // ChartJS aktualisieren
  useEffect(() => {
    if (!chartRef.current) return;
    chartRef.current.data.labels = labels;
    chartRef.current.data.datasets[0].data = chartData;
    chartRef.current.data.datasets[1].data = Array(chartData.length - smaData.length)
      .fill(null)
      .concat(smaData);

    chartRef.current.options.plugins.annotation.annotations = annotations;
    chartRef.current.update('none');
  }, [chartData, smaData, labels, annotations]);

  // Hilfsfunktionen
  const getDecimalPlaces = (symbol) => {
    if (['BTCUSDT', 'ETHUSDT', 'BNBUSDT'].includes(symbol)) {
      return 2;
    } else if (['ADAUSDT', 'DOGEUSDT', 'XRPUSDT'].includes(symbol)) {
      return 4;
    }
    return 2;
  };

  // Chart-Setup
  const rootStyle = getComputedStyle(document.documentElement);
  const upColor = rootStyle.getPropertyValue('--price-up-color').trim() || '#4caf50';
  const primaryColor = rootStyle.getPropertyValue('--primary-color').trim() || '#0056b3';

  const data = {
    labels,
    datasets: [
      {
        label: `Kursverlauf (${selectedSymbol})`,
        data: chartData,
        fill: false,
        borderColor: upColor,
        tension: 0.1,
        pointRadius: 0,
      },
      {
        label: `SMA (${smaPeriod})`,
        data: Array(chartData.length - smaData.length).fill(null).concat(smaData),
        fill: false,
        borderColor: primaryColor,
        tension: 0.1,
        pointRadius: 0,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: { duration: 1000, easing: 'easeInOutQuad' },
    scales: {
      x: { title: { display: true, text: 'Zeit' } },
      y: { title: { display: true, text: 'Preis (USD)' } },
    },
    plugins: {
      datalabels: { display: false },
      annotation: {
        annotations,
      },
    },
  };

  const priceClass =
    priceChangeColor === 'var(--price-up-color)'
      ? 'price-up'
      : priceChangeColor === 'var(--price-down-color)'
      ? 'price-down'
      : '';

  return (
    <div className="cbot-simulator-layout">

      {/* -- Linke Spalte: Einstellungen -- */}
      <div className="cbot-left-panel">

        {/* Kaufstrategie */}
        <div className="cbot-strategy-selector-row">
          <div className="cbot-strategy-column">
            <p>Kaufstrategie:</p>
            <select
              value={buyStrategy}
              onChange={(e) => setBuyStrategy(e.target.value)}
              disabled={isRunning}
            >
              <option value="buyDip">Kaufen bei Rückgang (-X%)</option>
              <option value="smaCrossover">SMA Crossover</option>
            </select>
            {buyStrategy === 'buyDip' && (
              <div className="cbot-strategy-parameter">
                <p>Rückgang in %:</p>
                <input
                  type="number"
                  value={buyDipPercentage}
                  onChange={(e) => setBuyDipPercentage(Number(e.target.value))}
                  disabled={isRunning}
                  min={1}
                  max={100}
                />
              </div>
            )}
            {buyStrategy === 'smaCrossover' && (
              <div className="cbot-strategy-parameter">
                <p>SMA-Zeitraum:</p>
                <input
                  type="number"
                  value={smaPeriod}
                  onChange={(e) => setSmaPeriod(Number(e.target.value))}
                  disabled={isRunning}
                  min={2}
                  max={chartData.length || 100}
                />
              </div>
            )}
          </div>
        </div>

        {/* Verkaufsstrategie (alte + neue) */}
        <div className="cbot-strategy-selector-row">
          <div className="cbot-strategy-column">
            <p>Verkaufsstrategie (Haupt):</p>
            <select
              value={sellStrategy}
              onChange={(e) => setSellStrategy(e.target.value)}
              disabled={isRunning}
            >
              <option value="fixedProfit">Gewinn sichern (+X%)</option>
              <option value="stopLoss">Stop Loss (-X%)</option>
              <option value="combinedStopLossTakeProfit">Kombiniert (TP + SL)</option>
            </select>

            {/* fixedProfit */}
            {sellStrategy === 'fixedProfit' && (
              <div className="cbot-strategy-parameter">
                <p>Gewinn in %:</p>
                <input
                  type="number"
                  value={sellProfitPercentage}
                  onChange={(e) => setSellProfitPercentage(Number(e.target.value))}
                  disabled={isRunning}
                  min={1}
                  max={100}
                />
              </div>
            )}

            {/* Stop Loss */}
            {sellStrategy === 'stopLoss' && (
              <div className="cbot-strategy-parameter">
                <p>Stop Loss in %:</p>
                <input
                  type="number"
                  value={stopLossFixedPercentage}
                  onChange={(e) => setStopLossFixedPercentage(Number(e.target.value))}
                  disabled={isRunning}
                  min={1}
                  max={100}
                />
              </div>
            )}

            {/* Kombiniert */}
            {sellStrategy === 'combinedStopLossTakeProfit' && (
              <div className="cbot-strategy-parameter">
                <p>Stop Loss in %:</p>
                <input
                  type="number"
                  value={combinedStopLossPercentage}
                  onChange={(e) => setCombinedStopLossPercentage(Number(e.target.value))}
                  disabled={isRunning}
                  min={1}
                  max={100}
                />
                <p>Take Profit in %:</p>
                <input
                  type="number"
                  value={combinedTakeProfitPercentage}
                  onChange={(e) => setCombinedTakeProfitPercentage(Number(e.target.value))}
                  disabled={isRunning}
                  min={1}
                  max={100}
                />
              </div>
            )}
          </div>

          {/* Teilverkauf + Trailing Stop */}
          <div className="cbot-strategy-column">
            <p>Weitere Optionen:</p>

            <label>
              <input
                type="checkbox"
                checked={partialTakeProfitActive}
                onChange={(e) => setPartialTakeProfitActive(e.target.checked)}
                disabled={isRunning}
              />
              Teilverkäufe (Partial TP)
            </label>

            <label style={{ marginLeft: '20px' }}>
              <input
                type="checkbox"
                checked={trailingStopActive}
                onChange={(e) => setTrailingStopActive(e.target.checked)}
                disabled={isRunning}
              />
              Trailing Stop
            </label>

            {trailingStopActive && (
              <div className="cbot-strategy-parameter">
                <p>Trailing Stop in %:</p>
                <input
                  type="number"
                  value={trailingStopPercentage}
                  onChange={(e) => setTrailingStopPercentage(Number(e.target.value))}
                  disabled={isRunning}
                  min={1}
                  max={90}
                />
              </div>
            )}
          </div>
        </div>

        {/* Positionssizing / Risikomanagement */}
        <div className="cbot-strategy-selector-row">
          <div className="cbot-strategy-column">
            <p>Positionssizing:</p>
            <label>
              <input
                type="checkbox"
                checked={useRiskBasedPositionSizing}
                onChange={(e) => setUseRiskBasedPositionSizing(e.target.checked)}
                disabled={isRunning}
              />
              Risiko-basiert
            </label>
            {useRiskBasedPositionSizing && (
              <div className="cbot-strategy-parameter">
                <p>Max. Risiko in % (vom Kontostand):</p>
                <input
                  type="number"
                  value={riskPercentage}
                  onChange={(e) => setRiskPercentage(Number(e.target.value))}
                  disabled={isRunning}
                  min={1}
                  max={100}
                />
                <p>Initialer Stop-Loss in % (unter Kaufkurs):</p>
                <input
                  type="number"
                  value={initialStopLossPercent}
                  onChange={(e) => setInitialStopLossPercent(Number(e.target.value))}
                  disabled={isRunning}
                  min={1}
                  max={50}
                />
              </div>
            )}
          </div>
          {!useRiskBasedPositionSizing && (
            <div className="cbot-strategy-column">
              <p>Fester Einsatzbetrag (USD):</p>
              <input
                type="number"
                value={investmentAmount}
                onChange={(e) => setInvestmentAmount(Number(e.target.value))}
                disabled={isRunning}
                min={10}
                max={balance}
              />
            </div>
          )}
        </div>

        {/* Kontostand */}
        <div className="cbot-balance-investment-container">
          <div className="cbot-balance-section">
            <p>Kontostand:</p>
            <p className="cbot-balance-display">{balance.toFixed(2)} USD</p>
          </div>
        </div>

        {/* Bot-Steuerung */}
        <div className="cbot-controls">
          <button onClick={startBot} disabled={isRunning}>
            Start
          </button>
          <button onClick={stopBot} disabled={!isRunning}>
            Stop
          </button>
        </div>
      </div>

      {/* -- Mittlere Spalte: Chart / Preis -- */}
      <div className="cbot-center-panel">
        <h1>Crypto Bot Simulator</h1>

        {/* Symbol-Auswahl */}
        <div className="cbot-symbol-selection">
          <p>Währungspaar auswählen:</p>
          <select
            value={selectedSymbol}
            onChange={(e) => setSelectedSymbol(e.target.value)}
            disabled={isRunning}
          >
            {availableSymbols.map((s) => (
              <option key={s} value={s}>
                {s}
              </option>
            ))}
          </select>
        </div>

        {currentPrice == null ? (
          <div className="cbot-loading">
            <p>Preise werden geladen...</p>
          </div>
        ) : (
          <>
            <p className="cbot-current-price">
              Aktueller Preis ({selectedSymbol}):{' '}
              <span className={`price-value ${priceClass}`}>
                {currentPrice.toFixed(getDecimalPlaces(selectedSymbol))}
              </span>{' '}
              USD
            </p>
            <div className="cbot-chart-container">
              <Line ref={chartRef} data={data} options={options} />
            </div>
          </>
        )}

        {/* Falls Modal oder Tooltip */}
        {isStopping && activeTrade && (
          <div className="cbot-tooltip">
            <p>Der Bot wird stoppen, sobald der aktuelle Trade abgeschlossen ist.</p>
            <button
              className="cbot-immediate-sell-button"
              style={{
                backgroundColor:
                  currentPrice && currentPrice >= activeTrade.price
                    ? 'var(--price-up-color)'
                    : 'var(--price-down-color)',
                color: 'var(--btn-success-text, #ffffff)',
              }}
              onClick={immediateSell}
            >
              {immediateSellButtonLabel()}
            </button>
          </div>
        )}

        {showModal && !isStopping && (
          <Modal
            message={tooltipMessage}
            onClose={() => setShowModal(false)}
            onRedirect={() => (window.location.href = 'https://deine-werbeseite.com')}
          />
        )}
      </div>

      {/* -- Rechte Spalte: Trades -- */}
      <div className="cbot-right-panel">
        <div className="cbot-trades">
          <h3>Bot-Trades:</h3>
          <ul>
            {botTrades.map((t, idx) => (
              <li key={idx}>
                [{t.source}] {t.type === 'buy' ? 'Kauf' : 'Verkauf'} bei {t.price.toFixed(2)} USD um{' '}
                {t.time}
                {t.profit !== undefined && ` (Profit: ${t.profit.toFixed(2)} USD)`}
              </li>
            ))}
          </ul>
        </div>
      </div>

    </div>
  );
};

export default CryptoBotSimulator;
