// BinanceDataCoinList.js

let ws = null;
let currentCallback = null;
let currentSymbols = []; // Hält alle Symbole (ohne 'usdt') für die Seitenabos
let reconnectAttempts = 0;

/**
 * Globale Verwaltung aller EINZEL-Abos.
 * Map-Struktur: { symbol (z.B. 'btc') => { callbacks: Set(), unsubscribeFn } }
 */
const globalSingleSubscriptions = new Map();

/**
 * 1) Interner Reconnect-Versuch
 */
function attemptReconnect() {
  if (!currentSymbols.length && globalSingleSubscriptions.size === 0) return;
  reconnectAttempts++;
  const delay = Math.min(5000 * reconnectAttempts * reconnectAttempts, 60000);
  console.warn(`BinanceDataCoinList: versuche Reconnect in ${delay / 1000}s (Versuch ${reconnectAttempts}).`);

  setTimeout(() => {
    // Nur neu aufbauen, wenn es überhaupt noch Symbole gibt
    if (currentSymbols.length > 0 || globalSingleSubscriptions.size > 0) {
      initSocket();
      // Bei onopen abonnieren wir sowohl currentSymbols als auch alle Single-Abos
    }
  }, delay);
}

/**
 * 2) Socket initialisieren (falls nötig)
 */
function initSocket() {
  // Wenn bereits offen oder in CONNECTING, nichts tun
  if (ws && (ws.readyState === WebSocket.OPEN || ws.readyState === WebSocket.CONNECTING)) {
    return;
  }

  // Vorherigen Socket ggf. schließen
  if (ws) {
    ws.onclose = null;
    ws.close();
  }

  reconnectAttempts = 0;
  const wsUrl = `wss://cryptoscan.digital`;
  ws = new WebSocket(wsUrl);

  ws.onopen = () => {
    console.log('BinanceDataCoinList: WebSocket geöffnet.');
    reconnectAttempts = 0;

    // Jetzt alle aktuellen Symbole der Seitenabos abonnieren
    if (currentSymbols.length > 0) {
      ws.send(JSON.stringify({ type: 'subscribe', symbols: currentSymbols }));
    }

    // Zusätzlich alle EINZEL-Abos erneut anmelden
    // globalSingleSubscriptions hält Symbole ohne "usdt"-Suffix
    const singleSymbols = Array.from(globalSingleSubscriptions.keys());
    if (singleSymbols.length > 0) {
      // subscribe mit {symbols: [...] } oder einzeln
      // Wir können es einzeln machen, oder in bulk:
      // => Für Kompatibilität: machen wir EINZELN, 
      // weil wir ja in subscribeToSingleSymbol unten ggf. symbol +usdt dranhängen
      singleSymbols.forEach((sym) => {
        // Rufe intern subscribeSingleSymbolOnWs auf, das type='subscribe' sendet
        subscribeSingleSymbolOnWs(sym);
      });
    }
  };

  ws.onmessage = (event) => {
    const msg = JSON.parse(event.data);

    // 1) Seitenabos
    if (msg.symbol && msg.data && msg.data.price !== undefined) {
      const lowerSymbol = msg.symbol.toLowerCase(); // z.B. 'btcusdt'
      const baseSymbol = lowerSymbol.endsWith('usdt') ? lowerSymbol.slice(0, -4) : lowerSymbol;
      if (currentCallback) {
        currentCallback(baseSymbol, {
          price: msg.data.price,
          percentageChange: msg.data.percentageChange,
        });
      }

      // 2) Einzelabos => falls Symbol in globalSingleSubscriptions
      if (globalSingleSubscriptions.has(baseSymbol)) {
        const subscription = globalSingleSubscriptions.get(baseSymbol);
        subscription.callbacks.forEach((cb) => cb(baseSymbol, {
          price: msg.data.price,
          percentageChange: msg.data.percentageChange,
        }));
      }
    }
    else if (msg.symbol && msg.data && msg.data.error) {
      // Fehler bei Symbol
      const lowerSymbol = msg.symbol.toLowerCase();
      const baseSymbol = lowerSymbol.endsWith('usdt') ? lowerSymbol.slice(0, -4) : lowerSymbol;

      // ggf. Seitenabo bereinigen
      if (currentSymbols.includes(baseSymbol)) {
        currentSymbols = currentSymbols.filter(s => s !== baseSymbol);
      }

      // ggf. Einzelabo bereinigen
      if (globalSingleSubscriptions.has(baseSymbol)) {
        const subscription = globalSingleSubscriptions.get(baseSymbol);
        subscription.unsubscribeFn?.();
        globalSingleSubscriptions.delete(baseSymbol);
        console.warn(`Einzelabo entfernt wg. Error: ${baseSymbol}`);
      }
    }
  };

  ws.onerror = (err) => {
    console.error('BinanceDataCoinList: WebSocket Error', err);
  };

  ws.onclose = (event) => {
    console.warn(`BinanceDataCoinList: WebSocket geschlossen (Code: ${event.code}).`);
    ws = null;

    // Reconnect, wenn noch Symbole existieren
    if (currentSymbols.length > 0 || globalSingleSubscriptions.size > 0) {
      attemptReconnect();
    }
  };
}

/**
 * Hilfsfunktion: Abonniere EIN Symbol (z. B. 'btc') über den WS (type='subscribe')
 */
function subscribeSingleSymbolOnWs(symbol) {
  if (!ws || ws.readyState !== WebSocket.OPEN) return;
  ws.send(JSON.stringify({ type: 'subscribe', symbol }));
}

/**
 * Hilfsfunktion: Deabonniere EIN Symbol (z. B. 'btc') über den WS (type='unsubscribe')
 */
function unsubscribeSingleSymbolOnWs(symbol) {
  if (!ws || ws.readyState !== WebSocket.OPEN) return;
  ws.send(JSON.stringify({ type: 'unsubscribe', symbol }));
}

/**
 * 3) Update-Funktion (Seitenabos): Diff ermitteln,
 * vermeidet doppelte Abos, wenn ein Symbol schon als EINZEL-Abo existiert
 */
export function updateSymbols(newSymbolList, callback) {
  currentCallback = callback;

  // Socket ggf. initialisieren
  initSocket();

  // Filter: Symbole, die bereits in globalSingleSubscriptions sind, werden NICHT erneut abonniert
  const filteredNewSymbols = newSymbolList.filter(sym => !globalSingleSubscriptions.has(sym));

  // Diff berechnen
  const symbolsToAdd = filteredNewSymbols.filter(sym => !currentSymbols.includes(sym));
  const symbolsToRemove = currentSymbols.filter(sym => !newSymbolList.includes(sym));

  // Neue Symbole abonnieren
  if (symbolsToAdd.length > 0 && ws && ws.readyState === WebSocket.OPEN) {
    ws.send(JSON.stringify({ type: 'subscribe', symbols: symbolsToAdd }));
  }

  // Alte Symbole abbestellen
  // (nur wenn sie nicht in globalSingleSubscriptions auftauchen)
  const finalSymbolsToRemove = symbolsToRemove.filter(sym => !globalSingleSubscriptions.has(sym));
  if (finalSymbolsToRemove.length > 0 && ws && ws.readyState === WebSocket.OPEN) {
    ws.send(JSON.stringify({ type: 'unsubscribe', symbols: finalSymbolsToRemove }));
  }

  // Lokale Liste anpassen
  // WICHTIG: Die "neue" Liste ist filteredNewSymbols
  // damit wir in currentSymbols nur das speichern, was die Seite "verwalten" soll
  currentSymbols = [
    ...newSymbolList.filter(sym => !globalSingleSubscriptions.has(sym)),
  ];
}

/**
 * Schließt ALLE Subscriptions (Seitenabos und Einzelabos) und den Socket
 * (falls du es manuell brauchst).
 */
export function unsubscribeAll() {
  if (ws) {
    ws.onclose = null; 
    ws.close();
  }
  ws = null;
  currentSymbols = [];
  currentCallback = null;

  // Außerdem alle Einzelabos beenden
  for (const [symbol, subscription] of globalSingleSubscriptions.entries()) {
    subscription.unsubscribeFn?.();
  }
  globalSingleSubscriptions.clear();
}

/**
 * 4) EINZEL-Abonnements, um doppelte Subscribes zu vermeiden
 */

/**
 * Abonniert EIN Symbol "symbol" (z. B. 'btc'), sofern es nicht schon existiert.
 * callback: Funktion(symbol, { price, percentageChange }) => void
 */
export function subscribeToSingleSymbol(symbol, callback) {
  const lowerSymbol = symbol.toLowerCase();

  // Prüfen, ob Symbol schon in globalSingleSubscriptions existiert
  if (globalSingleSubscriptions.has(lowerSymbol)) {
    // Dann fügen wir nur den Callback hinzu
    const sub = globalSingleSubscriptions.get(lowerSymbol);
    sub.callbacks.add(callback);
    console.log(`EinzelAbo: Weiterer Callback für ${lowerSymbol}, now total: ${sub.callbacks.size}`);
    return;
  }

  // Andernfalls neues Abo
  const callbacks = new Set();
  callbacks.add(callback);

  // Wir legen eine Unsubscribe-Funktion an,
  // die wir ausrufen, wenn KEINE Callbacks mehr vorhanden sind.
  const unsubscribeFn = () => {
    // Schicke unsubscribe an den Server
    unsubscribeSingleSymbolOnWs(lowerSymbol);
  };

  globalSingleSubscriptions.set(lowerSymbol, {
    callbacks,
    unsubscribeFn,
  });

  // Socket sicherstellen
  initSocket();

  // Symbol jetzt am WebSocket anmelden (falls offen)
  subscribeSingleSymbolOnWs(lowerSymbol);
  console.log(`EinzelAbo: Neu für ${lowerSymbol}, 1 Callback`);
}

/**
 * Entfernt den Callback für EIN Symbol.
 * Falls keine Callbacks mehr übrig, wird unsub-Funktion aufgerufen.
 */
export function unsubscribeFromSingleSymbol(symbol, callback) {
  const lowerSymbol = symbol.toLowerCase();

  if (!globalSingleSubscriptions.has(lowerSymbol)) {
    console.warn(`EinzelAbo: Symbol nicht vorhanden => ${lowerSymbol}`);
    return;
  }

  const sub = globalSingleSubscriptions.get(lowerSymbol);
  sub.callbacks.delete(callback);

  if (sub.callbacks.size === 0) {
    // Keiner hört mehr => unsub
    sub.unsubscribeFn?.();
    globalSingleSubscriptions.delete(lowerSymbol);
    console.log(`EinzelAbo: Komplett abgemeldet => ${lowerSymbol}`);
  } else {
    console.log(`EinzelAbo: Callback entfernt => ${lowerSymbol}, verbleibend: ${sub.callbacks.size}`);
  }
}
