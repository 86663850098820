// src/components/CoinChart.js
import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import '../styles/common.css';
import '../styles/CoinChart.css';
import { coinMapping } from '../utils/coinMapping'; // Importiere das Mapping
import { getGranularity } from '../utils/granularity'; // Importiere die Granularitätsfunktion
import api from '../services/api'; // <-- NEU: Unser zentrales Axios-Objekt

Chart.register(...registerables);

const CoinChart = () => {
  const { coinId } = useParams();
  const navigate = useNavigate();

  const [chartData, setChartData] = useState([]);
  const [labels, setLabels] = useState([]);
  const [currentPrice, setCurrentPrice] = useState(null);
  const [priceChange, setPriceChange] = useState('');
  const [loading, setLoading] = useState(true);
  const [timeframe, setTimeframe] = useState('1');
  const chartRef = useRef(null);

  const prevPriceRef = useRef(null);
  const latestPriceRef = useRef(null);

  const binanceSymbol = coinMapping[coinId?.toLowerCase()] || '';
  const granularity = getGranularity(timeframe);

  // (A) Historische Daten laden
  useEffect(() => {
    if (!binanceSymbol) {
      console.error(`Kein Binance-Symbol für coinId: ${coinId}`);
      setLoading(false);
      return;
    }

    const fetchHistoricalData = async () => {
      setLoading(true);
      try {
        // Vorher: fetch(`/coin-market-chart?id=${coinId}&vs_currency=usd&days=${timeframe}`)
        const { data } = await api.get('/coin-market-chart', {
          params: {
            id: coinId,
            vs_currency: 'usd',
            days: timeframe
          }
        });

        const formattedData = data.prices.map(([timestamp, price]) => ({
          time: new Date(timestamp),
          price
        }));

        const maxDataPoints =
          granularity === 'daily' ? 3650
          : granularity === 'hourly' ? 90 * 24
          : 288;

        const slicedData = formattedData.slice(-maxDataPoints);
        const times = slicedData.map((item) => item.time);
        const prices = slicedData.map((item) => item.price);

        setChartData(prices);
        setLabels(times);
        setCurrentPrice(prices[prices.length - 1]);
      } catch (error) {
        console.error('Fehler beim Abrufen der historischen Daten:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchHistoricalData();
  }, [coinId, timeframe, binanceSymbol, granularity]);

  // (B) WebSocket-Logik (Binance)
  useEffect(() => {
    if (!binanceSymbol) return;

    const socket = new WebSocket(`wss://stream.binance.com:9443/ws/${binanceSymbol}@trade`);

    socket.onmessage = (event) => {
      const { p: price } = JSON.parse(event.data);
      latestPriceRef.current = parseFloat(price);
    };

    const enableRealTimeUpdates = parseInt(timeframe, 10) <= 90 || timeframe === '1';
    let interval;
    if (enableRealTimeUpdates) {
      interval = setInterval(() => {
        const latestPrice = latestPriceRef.current;
        if (latestPrice !== null && prevPriceRef.current !== latestPrice) {
          const isPriceUp = prevPriceRef.current && latestPrice > prevPriceRef.current;

          setPriceChange(isPriceUp ? 'price-up' : 'price-down');
          setTimeout(() => setPriceChange(''), 1000);

          prevPriceRef.current = latestPrice;
          setCurrentPrice(latestPrice);

          // Aktuelle Daten in chartData einfügen, älteste entfernen (max 288 Einträge)
          setChartData((prev) => [...prev.slice(-288 + 1), latestPrice]);
          setLabels((prev) => [...prev.slice(-288 + 1), new Date()]);
        }
      }, 3000);
    }

    return () => {
      socket.close();
      if (interval) clearInterval(interval);
    };
  }, [binanceSymbol, timeframe]);

  // (C) Aktualisiere das Chart-Objekt bei Änderungen
  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.data.labels = labels.map((label) => {
        if (label instanceof Date) {
          if (granularity === '5-minute') {
            return label.toLocaleTimeString();
          } else if (granularity === 'hourly') {
            return label.toLocaleString([], {
              month: 'short',
              day: 'numeric',
              hour: '2-digit',
            });
          } else {
            return label.toLocaleDateString([], { month: 'short', day: 'numeric' });
          }
        }
        return '';
      });
      chartRef.current.data.datasets[0].data = chartData;
      chartRef.current.update('none');
    }
  }, [labels, chartData, granularity]);

  // (D) Chart.js-Konfiguration
  const data = {
    labels,
    datasets: [
      {
        label: `Kursverlauf (${binanceSymbol.toUpperCase()})`,
        data: chartData,
        borderColor: '#4caf50',
        tension: 0.1,
        pointRadius: 0,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: { duration: 1000, easing: 'easeInOutQuad' },
    scales: {
      x: {
        title: { text: 'Zeit', display: true },
        ticks: { maxTicksLimit: 10 },
      },
      y: {
        title: { text: 'Preis (USD)', display: true },
        beginAtZero: false,
      },
    },
    plugins: {
      legend: { display: false },
    },
  };

  return (
    <div className="coin-chart">
      <h1>Chart für {coinId?.toUpperCase()}</h1>

      <div className="timeframe-selection">
        <label htmlFor="timeframe-select">Timeframe:</label>
        <select
          id="timeframe-select"
          value={timeframe}
          onChange={(e) => setTimeframe(e.target.value)}
        >
          {['1', '7', '30', '90', '180', '365', 'max'].map((tf) => (
            <option value={tf} key={tf}>{tf} Tage</option>
          ))}
        </select>
      </div>

      {loading ? (
        <div className="loading">Preise werden geladen...</div>
      ) : (
        <>
          <div className="current-price">
            Aktueller Preis:{' '}
            <span className={`price ${priceChange}`}>
              ${currentPrice?.toFixed(2) || 'N/A'} USD
            </span>
          </div>
          <div className="chart-container">
            <Line ref={chartRef} data={data} options={options} />
          </div>
        </>
      )}
      <button className="back-button" onClick={() => navigate(-1)}>Zurück</button>
    </div>
  );
};

export default CoinChart;
