import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { StyledButton } from '../utils/StyledButton';

const slugify = (text) => {
  return text
    .toString()
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/-+/g, '-');
};

const CategoryBadges = ({ categories = [] }) => {
  const navigate = useNavigate();
  const containerRef = useRef(null);
  
  const handleBadgeClick = (category) => {
    // Wenn category ein Objekt ist und eine ID besitzt, nutze diese direkt.
    if (typeof category === 'object' && category.id) {
      navigate(`/coinlist?category=${category.id}`);
      return;
    }
  
    // Andernfalls verarbeite es wie bisher.
    const name = typeof category === 'string' ? category : category.name;
    if (!name) {
      console.warn("Kein gültiger Name vorhanden.");
      return;
    }
    const cleanName = name.replace(/\s*\(.*?\)/, '');
    
    // Falls du noch einzelne Überschreibungen benötigst:
    const categorySlugMapping = {
      // Füge hier weitere Überschreibungen hinzu, falls nötig. 
      // Zum Beispiel:
      //       "Meme": "meme-tokenss",
    };
  
    const slug = categorySlugMapping[cleanName] || slugify(cleanName);
    navigate(`/coinlist?category=${slug}`);
  };
  

  // Funktion für Drag-to-Scroll
  const handleMouseDown = (e) => {
    if (!containerRef.current) return;
    containerRef.current.style.cursor = 'grabbing';
    let startX = e.pageX;
    let scrollLeft = containerRef.current.scrollLeft;
    
    const handleMouseMove = (moveEvent) => {
      containerRef.current.scrollLeft = scrollLeft - (moveEvent.pageX - startX);
    };

    const handleMouseUp = () => {
      containerRef.current.style.cursor = 'grab';
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  return (
    <div className="category-badges-wrapper">
      <div
        ref={containerRef}
        className="category-badges-container"
        onMouseDown={handleMouseDown}
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'nowrap',
          overflowX: 'auto',
          gap: '8px',
          alignItems: 'center',
          cursor: 'grab', // Zeigt den "Hand"-Cursor an
        }}
      >
        {categories.map((cat, index) => {
          const name = typeof cat === 'string' ? cat : cat.name;
          return name ? (
            <StyledButton
              key={index}
              variant="category"
              onClick={() => handleBadgeClick(cat)}
            >
              {name}
            </StyledButton>
          ) : null;
        })}
      </div>
    </div>
  );
};

export default React.memo(CategoryBadges, (prevProps, nextProps) => {
  return JSON.stringify(prevProps.categories) === JSON.stringify(nextProps.categories);
});
