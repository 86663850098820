// src/components/Profile.js
import React, { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import { Trans, t } from '@lingui/macro';
import { Link, Navigate } from 'react-router-dom';
import { FaGoogle } from 'react-icons/fa';
import '../styles/Profile.css';
import LoadingOverlay from './LoadingOverlay'; // Falls du einen Spinner hast

const Profile = () => {
  const { user, isAuthLoading, isLoggedIn } = useContext(AuthContext);

  // Falls noch geladen wird, Spinner anzeigen
  if (isAuthLoading) {
    return <LoadingOverlay />;
  }

  // Falls nicht eingeloggt, umleiten
  if (!isLoggedIn) {
    return <Navigate to="/login" replace />;
  }

  // Nun haben wir einen geladenen User, also:
  const birthdateFormatted = user.birthdate
    ? new Date(user.birthdate).toLocaleDateString()
    : '-';
  const updatedAtFormatted = user.updated_at
    ? new Date(user.updated_at).toLocaleString()
    : '-';

  return (
    <div className="profile-container">
      <div className="profile-header">
        <h2>
          <Trans id="title.yourProfile">Dein Profil</Trans>
        </h2>
      </div>
      <div className="profile-info">
        <p className="label">
          <Trans id="label.username">Benutzername:</Trans>
        </p>
        <p>
          {user.username}
          {user.google_id && (
            <FaGoogle
              style={{
                marginLeft: '8px',
                fontSize: '0.9rem',
                color: '#4285F4',
              }}
              title={t({
                id: 'profile.googleLinked',
                message: 'Mit Google verknüpft',
              })}
            />
          )}
        </p>
        <p className="label">
          <Trans id="label.email">E-Mail:</Trans>
        </p>
        <p>{user.email}</p>
        <p className="label">
          <Trans id="label.firstName">Vorname:</Trans>
        </p>
        <p>{user.first_name || '-'}</p>
        <p className="label">
          <Trans id="label.lastName">Nachname:</Trans>
        </p>
        <p>{user.last_name || '-'}</p>
        <p className="label">
          <Trans id="label.address">Adresse:</Trans>
        </p>
        <p>
          {user.address || '-'}, {user.zip || '-'}, {user.country || '-'}
        </p>
        <p className="label">
          <Trans id="label.birthdate">Geburtsdatum:</Trans>
        </p>
        <p>{birthdateFormatted}</p>
        <p className="label">
          <Trans id="label.updatedAt">Geändert am:</Trans>
        </p>
        <p>{updatedAtFormatted}</p>
      </div>
      <div className="profile-links">
        <Link to="/profile/edit">
          <Trans id="link.editProfile">Profil bearbeiten</Trans>
        </Link>
      </div>
    </div>
  );
};

export default Profile;
