// src/components/CurrencyConverterSearchOverlay.jsx
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import api from '../services/api';
import { StyledInput } from '../utils/StyledInput';
import { StyledButton } from '../utils/StyledButton';
import { Trans, t } from '@lingui/macro';
import { FaTimes } from 'react-icons/fa';

const OverlayBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
`;

const ModalContainer = styled.div`
  background: var(--background-color2);
  border: 1px solid var(--secondary-bg-color);
  border-radius: 8px;
  padding: 20px;
  max-width: 90vw;
  max-height: 80vh;
  overflow-y: auto;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.3s ease-out;

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0.95);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const CloseIcon = styled(FaTimes)`
  cursor: pointer;
  font-size: 1.2rem;
  transition: opacity 0.2s ease, transform 0.2s ease;
  &:hover {
    opacity: 0.7;
    transform: scale(1.1);
  }
`;

const CoinItem = styled.div`
  padding: 10px;
  border-bottom: 1px solid var(--secondary-bg-color);
  cursor: pointer;
  transition: background 0.2s;

  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
`;

const CurrencyConverterSearchOverlay = ({ onSelect, onClose }) => {
  const [query, setQuery] = useState('');
  const [coins, setCoins] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);

  const handleSearch = async () => {
    if (query.trim().length < 3) return;
    setLoading(true);
    try {
      const resp = await api.get(`/search?query=${encodeURIComponent(query)}`);
      const data = resp.data;
      setCoins(data.coins || []);
    } catch (err) {
      // Interne Fehlerbehandlung
    } finally {
      setLoading(false);
      setHasSearched(true);
    }
  };

  // Schließen via Escape-Taste
  useEffect(() => {
    const handleEsc = (e) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };
    document.addEventListener('keydown', handleEsc);
    return () => document.removeEventListener('keydown', handleEsc);
  }, [onClose]);

  return (
    <OverlayBackground onClick={onClose}>
      <ModalContainer onClick={(e) => e.stopPropagation()}>
        <Header>
          <h3>
            <Trans id="converter.searchTitle">Coin suchen</Trans>
          </h3>
          <CloseIcon onClick={onClose} />
        </Header>
        <StyledInput
          type="text"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder={t({ id: 'placeholder.search', message: 'Suchbegriff...' })}
          onKeyDown={(e) => {
            if (e.key === 'Enter') handleSearch();
          }}
          autoFocus
          style={{ marginBottom: '10px' }}
        />
        <StyledButton onClick={handleSearch} disabled={query.trim().length < 3}>
          <Trans id="button.search">Suchen</Trans>
        </StyledButton>
        {loading && <p><Trans id="converter.loading">Lade...</Trans></p>}
        {!loading && hasSearched && coins.length === 0 && query.trim().length >= 3 && (
          <p style={{ fontStyle: 'italic' }}>
            <Trans id="converter.noResults">Keine Ergebnisse gefunden</Trans>
          </p>
        )}
        {!loading &&
          coins.map((coin) => (
            <CoinItem
              key={coin.id}
              onClick={() => {
                onSelect(coin);
                onClose();
              }}
            >
              {coin.name} ({coin.symbol.toUpperCase()})
            </CoinItem>
          ))}
      </ModalContainer>
    </OverlayBackground>
  );
};

export default CurrencyConverterSearchOverlay;
