// src/context/MatomoProvider.js
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

/**
 * Hier deine Matomo-URLs und Site-ID anpassen
 */
const MATOMO_URL = 'https://analytics.cryptoscan.digital/matomo.php';
const MATOMO_JS = 'https://analytics.cryptoscan.digital/matomo.js';
const MATOMO_SITE_ID = '1';

function MatomoProvider() {
  const location = useLocation();

  /**
   * 1) Skript laden & Grundkonfiguration
   */
  useEffect(() => {
    // Prüfen, ob Matomo schon läuft
    if (!window._paq) {
      // _paq-Array für Matomo-Befehle
      window._paq = [];

      // Basis-Konfiguration
      window._paq.push(['setTrackerUrl', MATOMO_URL]);
      window._paq.push(['setSiteId', MATOMO_SITE_ID]);
      window._paq.push(['enableLinkTracking']);

      // Matomo-Skript dynamisch anhängen
      const script = document.createElement('script');
      script.async = true;
      script.src = MATOMO_JS;
      document.body.appendChild(script);

      // Sobald Matomo geladen ist, können wir die visitorId auslesen
      window._paq.push([
        function() {
          const visitorId = this.getVisitorId();

          // 2) Axios-Header setzen
          axios.defaults.headers.common['x-matomo-visitor-id'] = visitorId || '';
        }
      ]);
    }
  }, []);

  /**
   * 2) PageView tracken bei Routenwechsel (SPA)
   */
  useEffect(() => {
    if (window._paq) {
      // z.B. Current URL
      window._paq.push(['setCustomUrl', location.pathname + location.search]);
      // Dokument-Titel
      window._paq.push(['setDocumentTitle', document.title]);
      // Tatsächlicher PageView
      window._paq.push(['trackPageView']);
    }
  }, [location]);

  // Rendert nichts, wir brauchen nur die Effekte
  return null;
}

export default MatomoProvider;
