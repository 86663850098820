// src/components/FuturesExchangeList.js

import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import '../styles/FuturesExchangeList.css';
import FuturesExchangeRow from './FuturesExchangeRow';
import SingleSelectNoSearch from '../utils/SingleSelectNoSearch';
import { Pagination } from '../utils/StyledButton';
import { Trans } from '@lingui/macro';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';

import api from '../services/api'; // <-- Neu: unser zentraler Axios-Objekt

const sortOptions = [
  { value: 'market_asc', label: <Trans id="sort.market_asc">Market A-Z</Trans> },
  { value: 'market_desc', label: <Trans id="sort.market_desc">Market Z-A</Trans> },
  { value: 'openInterest_desc', label: <Trans id="sort.openInterest_desc">Open Interest High-Low</Trans> },
  { value: 'openInterest_asc', label: <Trans id="sort.openInterest_asc">Open Interest Low-High</Trans> },
  { value: 'volume_desc', label: <Trans id="sort.volume_desc">Volume High-Low</Trans> },
  { value: 'volume_asc', label: <Trans id="sort.volume_asc">Volume Low-High</Trans> },
  { value: 'pairs_desc', label: <Trans id="sort.pairs_desc">Pairs High-Low</Trans> },
  { value: 'pairs_asc', label: <Trans id="sort.pairs_asc">Pairs Low-High</Trans> },
];

const FuturesExchangeList = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // URL-Parameter
  const searchParams = new URLSearchParams(location.search);
  const initialPage = parseInt(searchParams.get('page') || '1', 10);
  const initialPerPage = parseInt(searchParams.get('per_page') || '50', 10);

  const [futuresExchanges, setFuturesExchanges] = useState([]);
  const [loadingTotal, setLoadingTotal] = useState(true);
  const [loadingFutures, setLoadingFutures] = useState(true);
  const [error, setError] = useState(null);
  const [totalUniqueExchanges, setTotalUniqueExchanges] = useState(0);
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [futuresPerPage, setFuturesPerPage] = useState(initialPerPage);

  // Standard-Sortierung: Open Interest (desc)
  const [sortOrder, setSortOrder] = useState('openInterest_desc');

  // Currency
  const [vsCurrency, setVsCurrency] = useState(localStorage.getItem('vsCurrency') || 'usd');
  const [conversionFactor, setConversionFactor] = useState(1);

  const loading = loadingTotal || loadingFutures;

  const retryCountTotalRef = useRef(0);
  const retryCountFuturesRef = useRef(0);
  const maxRetries = 5;

  // ----------------------------
  // Währungsumrechnung
  // ----------------------------
  useEffect(() => {
    if (vsCurrency.toLowerCase() === 'usd') {
      setConversionFactor(1);
    } else {
      api
        .get('/exchange-rates')
        .then(({ data }) => {
          const rates = data.rates;
          if (rates && rates[vsCurrency.toLowerCase()] && rates['usd']) {
            setConversionFactor(rates[vsCurrency.toLowerCase()].value / rates['usd'].value);
          } else {
            setConversionFactor(1);
          }
        })
        .catch((err) => {
          console.error(err);
          setConversionFactor(1);
        });
    }
  }, [vsCurrency]);

  // ----------------------------
  // URL-Parameter synchronisieren
  // ----------------------------
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const pageParam = parseInt(params.get('page') || '1', 10);
    const perPageParam = parseInt(params.get('per_page') || '50', 10);
    if (pageParam !== currentPage) setCurrentPage(pageParam);
    if (perPageParam !== futuresPerPage) setFuturesPerPage(perPageParam);
  }, [location.search]);

  // ----------------------------
  // Gesamtzahl Exchanges laden (Retry)
  // ----------------------------
  const fetchTotalExchangesData = async () => {
    setLoadingTotal(true);
    try {
      // GET /derivatives-data?per_page=...&page=...
      const { data } = await api.get('/derivatives-data', {
        params: { per_page: futuresPerPage, page: currentPage },
      });
      setTotalUniqueExchanges(data.totalUniqueExchanges);
      setLoadingTotal(false);
      retryCountTotalRef.current = 0;
    } catch (err) {
      console.error('Error fetching totalUniqueExchanges:', err);
      const isNetworkError =
        !err.response && (err.message.includes('Network') || err.message.includes('fetch'));

      if (isNetworkError) {
        if (retryCountTotalRef.current < maxRetries) {
          retryCountTotalRef.current++;
          const delay = Math.pow(2, retryCountTotalRef.current) * 1000;
          console.log(
            `fetchTotalExchangesData: Retry in ${delay / 1000} sec (Attempt ${retryCountTotalRef.current} of ${maxRetries})`
          );
          setTimeout(fetchTotalExchangesData, delay);
          return;
        } else {
          console.log('Max retries reached for totalExchanges, retrying in 5 sec...');
          setTimeout(fetchTotalExchangesData, 5000);
          return;
        }
      } else {
        console.log('Non-network error in totalExchanges, retrying in 5 sec...');
        setTimeout(fetchTotalExchangesData, 5000);
      }
      setLoadingTotal(false);
    }
  };

  // ----------------------------
  // Futures-Daten laden (Retry)
  // ----------------------------
  const fetchFuturesData = async (showLoading = false) => {
    if (showLoading) setLoadingFutures(true);
    try {
      // GET /derivatives-data?per_page=...&page=...
      const { data } = await api.get('/derivatives-data', {
        params: { per_page: futuresPerPage, page: currentPage },
      });
      setFuturesExchanges(data.data);
      setError(null);
      if (showLoading) setLoadingFutures(false);
      retryCountFuturesRef.current = 0;
    } catch (err) {
      console.error('fetchFuturesData error:', err);
      const isNetworkError =
        !err.response && (err.message.includes('Network') || err.message.includes('fetch'));

      if (isNetworkError) {
        if (retryCountFuturesRef.current < maxRetries) {
          retryCountFuturesRef.current++;
          const retryDelay = Math.pow(2, retryCountFuturesRef.current) * 1000;
          console.log(
            `fetchFuturesData: Retry in ${retryDelay / 1000} sec (Attempt ${retryCountFuturesRef.current} of ${maxRetries})`
          );
          setTimeout(() => fetchFuturesData(showLoading), retryDelay);
          return;
        } else {
          console.log('Max retries reached for futuresData, retrying in 5 sec...');
          setTimeout(() => fetchFuturesData(showLoading), 5000);
          return;
        }
      } else {
        console.log('Non-network error in futuresData, retrying in 5 sec...');
        setTimeout(() => fetchFuturesData(showLoading), 5000);
      }

      if (showLoading) setLoadingFutures(false);
    }
  };

  // ----------------------------
  // Initial Load + Polling
  // ----------------------------
  useEffect(() => {
    fetchTotalExchangesData();
    fetchFuturesData(true);
    const intervalId = setInterval(() => {
      fetchTotalExchangesData();
      fetchFuturesData(false);
    }, 11000);
    return () => clearInterval(intervalId);
  }, [currentPage, futuresPerPage]);

  // ----------------------------
  // Sichtbarkeitswechsel => neu laden
  // ----------------------------
  useEffect(() => {
    function handleVisibilityChange() {
      if (!document.hidden) {
        fetchTotalExchangesData();
        fetchFuturesData(true);
      }
    }
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  // ----------------------------
  // URL-Updater
  // ----------------------------
  useEffect(() => {
    const params = new URLSearchParams();
    if (currentPage !== 1) params.set('page', currentPage.toString());
    if (futuresPerPage !== 50) params.set('per_page', futuresPerPage.toString());
    const paramString = params.toString();
    navigate(paramString ? `?${paramString}` : '', { replace: true });
  }, [currentPage, futuresPerPage, navigate]);

  const totalPagesCalc = totalUniqueExchanges
    ? Math.ceil(totalUniqueExchanges / futuresPerPage)
    : 1;

  // Sortier-Icons
  const renderSortIcon = (columnPrefix) => {
    if (!sortOrder.startsWith(columnPrefix)) return null;
    return sortOrder.endsWith('_asc') ? (
      <FaChevronUp className="sort-icon" />
    ) : (
      <FaChevronDown className="sort-icon" />
    );
  };

  // Sortierfunktion
  const sortExchanges = (exchanges, order) => {
    return [...exchanges].sort((a, b) => {
      const openInterestA = a.pairs.reduce((sum, pair) => sum + (Number(pair.open_interest) || 0), 0);
      const openInterestB = b.pairs.reduce((sum, pair) => sum + (Number(pair.open_interest) || 0), 0);
      const volumeA = a.pairs.reduce((sum, pair) => sum + (Number(pair.volume_24h) || 0), 0);
      const volumeB = b.pairs.reduce((sum, pair) => sum + (Number(pair.volume_24h) || 0), 0);
      const pairsCountA = a.pairsCount || a.pairs.length;
      const pairsCountB = b.pairsCount || b.pairs.length;
      const marketA = a.market.toLowerCase();
      const marketB = b.market.toLowerCase();

      switch (order) {
        case 'market_asc':
          return marketA.localeCompare(marketB);
        case 'market_desc':
          return marketB.localeCompare(marketA);
        case 'openInterest_desc':
          return openInterestB - openInterestA;
        case 'openInterest_asc':
          return openInterestA - openInterestB;
        case 'volume_desc':
          return volumeB - volumeA;
        case 'volume_asc':
          return volumeA - volumeB;
        case 'pairs_desc':
          return pairsCountB - pairsCountA;
        case 'pairs_asc':
          return pairsCountA - pairsCountB;
        default:
          return 0;
      }
    });
  };

  const sortedFuturesExchanges = sortExchanges(futuresExchanges, sortOrder);

  const handleSortChange = (newSort) => {
    setSortOrder(newSort);
  };

  const handleFuturesExchangeClick = (exchange) => {
    navigate(`/futures-exchange-details?market=${encodeURIComponent(exchange.market)}`, {
      state: { pairs: exchange.pairs, market: exchange.market },
    });
  };

  return (
    <SkeletonTheme baseColor="#ccc" highlightColor="#eee">
      <div className="futures-exchange-list-container">
        <h2 className="futures-exchange-list-title">
          <Trans id="title.futuresExchanges">Futures / Derivatives Liste</Trans>
        </h2>
        <p className="intro-text">
          <Trans id="futuresExchangeList.intro">
            Unsere Futures- bzw. Derivatives-Liste bietet dir einen Überblick über bedeutende
            Futures-Plattformen und ihre Kennzahlen.
          </Trans>
        </p>

        <div className="futures-exchange-list-controls">
          <div className="futures-per-page">
            <label>
              {loading ? <Skeleton width={140} /> : <Trans id="label.sortBy">Sortieren nach:</Trans>}
            </label>
            {loading ? (
              <Skeleton width={140} height={30} />
            ) : (
              <SingleSelectNoSearch
                options={sortOptions}
                selectedValue={sortOrder}
                onChange={handleSortChange}
                placeholder="Sortieren"
                minWidth={150}
              />
            )}
          </div>
          <div className="futures-per-page">
            <label>
              {loading ? <Skeleton width={100} /> : <Trans id="label.entriesPerPage">Einträge:</Trans>}
            </label>
            {loading ? (
              <Skeleton width={100} height={30} />
            ) : (
              <SingleSelectNoSearch
                options={['25', '50', '100']}
                selectedValue={String(futuresPerPage)}
                onChange={(newVal) => {
                  setFuturesPerPage(parseInt(newVal, 10));
                  setCurrentPage(1);
                }}
                placeholder="Futures pro Seite"
              />
            )}
          </div>
        </div>

        {error && <div className="error-message">{error}</div>}

        {!error && (
          <>
            <table className="futures-exchange-list-table">
              <thead>
                <tr>
                  {loading
                    ? Array.from({ length: 5 }).map((_, i) => (
                        <th key={i}>
                          <Skeleton width="100%" />
                        </th>
                      ))
                    : (
                      <>
                        <th>
                          <Trans id="label.rank">Rank</Trans>
                        </th>
                        <th>
                          <Trans id="label.market">Market</Trans> {renderSortIcon('market')}
                        </th>
                        <th>
                          <Trans id="label.pairs">Pairs</Trans> {renderSortIcon('pairs')}
                        </th>
                        <th>
                          <Trans id="label.openInterest">
                            Open Interest ({vsCurrency.toUpperCase()})
                          </Trans>{' '}
                          {renderSortIcon('openInterest')}
                        </th>
                        <th>
                          <Trans id="label.volume">
                            24h Volumen ({vsCurrency.toUpperCase()})
                          </Trans>{' '}
                          {renderSortIcon('volume')}
                        </th>
                      </>
                    )}
                </tr>
              </thead>
              <tbody>
                {loading
                  ? Array.from({ length: futuresPerPage }).map((_, i) => (
                      <FuturesExchangeRow key={i} loading={true} />
                    ))
                  : sortedFuturesExchanges.map((exchange, index) => (
                      <FuturesExchangeRow
                        key={exchange.market}
                        exchange={exchange}
                        onClick={handleFuturesExchangeClick}
                        rank={(currentPage - 1) * futuresPerPage + index + 1}
                        loading={false}
                        vsCurrency={vsCurrency}
                        conversionFactor={conversionFactor}
                      />
                    ))}
              </tbody>
            </table>

            {!loading && totalUniqueExchanges > 0 && (
              <div className="pagination-controls bottom-pagination-controls">
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPagesCalc}
                  onPageChange={setCurrentPage}
                />
              </div>
            )}
          </>
        )}
      </div>
    </SkeletonTheme>
  );
};

export default FuturesExchangeList;
