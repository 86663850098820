// src/components/ScamGame/ScamGame.js

import React, { useState, useEffect } from 'react';
import { Trans } from '@lingui/macro';
import { Link } from 'react-router-dom';
import api from '../../services/api'; // <-- Neu: zentrales Axios-Objekt
import ScamScenarioCard from './ScamScenarioCard';
import '../../styles/ScamGame.css';

const ScamGame = () => {
  const [activePage, setActivePage] = useState('home');
  const [careerStatus, setCareerStatus] = useState(null);

  // Szenarien: { id, title, description, user_answer, is_correct, type, ... }
  const [scenarios, setScenarios] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Lokaler Fortschritt
  const [progressList, setProgressList] = useState([]);
  const [coinBalance, setCoinBalance] = useState(0);

  // Overlay, wenn Level nicht bestanden
  const [showFailOverlay, setShowFailOverlay] = useState(false);

  // ===========================
  // (1) Karriere + Score laden
  // ===========================
  useEffect(() => {
    fetchCareerStatus();
    fetchUserScore();
  }, []);

  async function fetchCareerStatus() {
    setLoading(true);
    setError(null);
    try {
      // Anstelle von apiFetch('/api/scamgame/career/status'):
      const { data } = await api.get('/scamgame/career/status');

      // Wenn das Backend 401 sendet, wirft Axios im Normalfall
      // einen Error, den wir im catch abfangen:
      setCareerStatus(data);
    } catch (err) {
      if (err.response && err.response.status === 401) {
        // => NICHT eingeloggt
        setError('notLoggedIn');
      } else {
        console.error(err);
        setError('Fehler beim Laden des Karriere-Status');
      }
    } finally {
      setLoading(false);
    }
  }

  async function fetchUserScore() {
    try {
      // Anstelle von apiFetch('/api/score'):
      const { data } = await api.get('/score');
      // Hier war vorher "if (!response.ok) throw..." => Axios wirft automatisch.
      setCoinBalance(data.score);
    } catch (err) {
      console.error('Fehler beim Laden des Scores:', err);
    }
  }

  // ===========================
  // (2) Level starten/fortsetzen
  // ===========================
  async function handleStartLevel() {
    if (!careerStatus) return;
    setLoading(true);
    setError(null);

    setScenarios([]);
    setProgressList([]);
    setCurrentIndex(0);
    setShowFailOverlay(false);

    try {
      // Anstelle von apiFetch('/api/scamgame/career/scenarios'):
      const { data } = await api.get('/scamgame/career/scenarios');

      setScenarios(data);

      const newProgress = data.map((s) => ({
        scenarioId: s.id,
        answered: s.user_answer !== null,
        isCorrect: !!s.is_correct,
      }));
      setProgressList(newProgress);

      const firstUnansweredIndex = newProgress.findIndex((p) => p.answered === false);
      if (firstUnansweredIndex >= 0) {
        setCurrentIndex(firstUnansweredIndex);
      } else {
        setCurrentIndex(0);
      }

      setActivePage('game');
    } catch (err) {
      console.error(err);
      setError('Fehler beim Laden der Level-Szenarien');
    } finally {
      setLoading(false);
    }
  }

  // ===========================
  // (3) onAnswered => progressList updaten
  // ===========================
  function handleAnswerUpdate(scenarioId, isCorrect, levelFailed) {
    if (levelFailed) {
      setShowFailOverlay(true);
    }
    setProgressList((prev) =>
      prev.map((item) => {
        if (item.scenarioId === scenarioId) {
          return { ...item, answered: true, isCorrect };
        }
        return item;
      })
    );
  }

  // ===========================
  // (4) "Weiter" => nächstes Szenario
  // ===========================
  function handleNextScenario() {
    if (currentIndex < scenarios.length - 1) {
      setCurrentIndex(currentIndex + 1);
    } else {
      fetchCareerStatus();
      setActivePage('summary');
    }
  }

  // ===========================
  // Overlay, wenn Level fail
  // ===========================
  function renderFailOverlay() {
    if (!showFailOverlay) return null;

    return (
      <div className="level-failed-overlay">
        <div className="level-failed-content data-card">
          <h3>
            <Trans id="scamgame.career.failedTitle">Level nicht bestanden</Trans>
          </h3>
          <p>
            <Trans id="scamgame.career.failedMessage">
              Du hast leider nicht genügend richtige Antworten erreicht.
              Die bisherigen Antworten in diesem Level wurden zurückgesetzt.
              Versuche es erneut!
            </Trans>
          </p>
          <div className="scamgame-buttons" style={{ marginTop: '20px' }}>
            <button onClick={handleStartLevel}>
              <Trans id="scamgame.career.retryLevel">Level neu starten</Trans>
            </button>
            <button onClick={() => setShowFailOverlay(false)}>
              <Trans id="scamgame.button.closeOverlay">Schließen</Trans>
            </button>
          </div>
        </div>
      </div>
    );
  }

  // ===========================
  // (5) Render
  // ===========================

  // (A) Falls wir noch laden ...
  if (loading) {
    return (
      <div id="scamgame-home-container">
        <p>
          <Trans id="scamgame.loadingCareer">Lade Karriere-Daten...</Trans>
        </p>
      </div>
    );
  }

  // (B) Falls wir explizit "notLoggedIn" haben => "Bitte einloggen!"
  if (error === 'notLoggedIn') {
    return (
      <div id="scamgame-home-container">
        <h2 style={{ color: 'red', marginBottom: '20px' }}>
          <Trans id="scamgame.career.needLoginTitle">Du bist nicht eingeloggt!</Trans>
        </h2>
        <p>
          <Trans id="scamgame.career.needLoginMessage">
            Um das ScamGame zu spielen oder deinen Karriere-Fortschritt zu sehen, musst du eingeloggt sein.
          </Trans>
        </p>
        <div className="scamgame-buttons" style={{ marginTop: '20px' }}>
          <Link to="/login" className="scamgame-link-button">
            <Trans id="scamgame.career.goLogin">Zur Login-Seite</Trans>
          </Link>
        </div>
      </div>
    );
  }

  // (C) Falls sonstige Fehler
  if (error && error !== 'notLoggedIn') {
    return (
      <div id="scamgame-home-container">
        <p style={{ color: 'red' }}>{error}</p>
      </div>
    );
  }

  // (D) Summary-Ansicht
  if (activePage === 'summary') {
    const correctCnt = progressList.filter((p) => p.isCorrect).length;
    const allCnt = progressList.length;

    return (
      <div id="scamgame-home-container">
        {renderFailOverlay()}
        <h2>
          <Trans id="scamgame.career.levelDone">Level abgeschlossen!</Trans>
        </h2>
        <p>
          <Trans id="scamgame.career.levelDoneCount" values={{ correctCnt, allCnt }}>
            Du hast {correctCnt} von {allCnt} richtig beantwortet.
          </Trans>
        </p>
        <button onClick={() => setActivePage('home')}>
          <Trans id="scamgame.button.backHome">Zurück</Trans>
        </button>
      </div>
    );
  }

  // (E) Spiel ("game")
  if (activePage === 'game') {
    const scenario = scenarios[currentIndex] || null;
    const cTotal = scenarios.length;
    const cIndex = currentIndex + 1;
    const cCorrect = progressList.filter((p) => p.isCorrect).length;
    const currentProgress = progressList.find((p) => p.scenarioId === scenario?.id);

    return (
      <div id="scamgame-game-container">
        {renderFailOverlay()}

        <div className="scamgame-balance">
          <Trans id="scamgame.balance.dynamic">
            Dein Kontostand: {coinBalance} Coins
          </Trans>
        </div>

        <h2 className="scamgame-title">
          <Trans id="scamgame.title">Krypto-Scam-Erkennung</Trans>
        </h2>

        {/* Fortschritt */}
        <div style={{ marginBottom: '10px', textAlign: 'center' }}>
          <Trans id="scamgame.career.progress" values={{ cIndex, cTotal, cCorrect }}>
            Fortschritt: {cIndex}/{cTotal} | Richtig beantwortet: {cCorrect}
          </Trans>
        </div>

        {/* Miniliste */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            gap: '8px',
            flexWrap: 'wrap',
            marginBottom: '20px',
          }}
        >
          {progressList.map((p, idx) => {
            const scenNum = idx + 1;
            let style = {
              padding: '6px 8px',
              borderRadius: '4px',
              transition: 'all 0.3s ease',
            };

            if (!p.answered) {
              style.backgroundColor = 'var(--background-color2)';
            } else if (p.isCorrect) {
              style.backgroundColor = 'rgba(0,255,0,0.3)';
            } else {
              style.backgroundColor = 'rgba(255,0,0,0.3)';
            }

            if (idx === currentIndex) {
              style.border = '3px solid var(--primary-color)';
              style.boxShadow = '0 0 10px var(--primary-color)';
              style.transform = 'scale(1.08)';
              style.fontWeight = 'bold';
            }

            return (
              <div key={p.scenarioId} style={style}>
                <Trans id="scamgame.career.scenarioNumber" values={{ scenNum }}>
                  Szenario {scenNum}
                </Trans>
              </div>
            );
          })}
        </div>

        {scenario && currentProgress && (
          <ScamScenarioCard
            key={scenario.id}
            scenario={scenario}
            answered={currentProgress.answered}
            isCorrect={currentProgress.isCorrect}
            onAnswered={(correct, levelFailed) =>
              handleAnswerUpdate(scenario.id, correct, levelFailed)
            }
            onNext={handleNextScenario}
          />
        )}

        {/* Zurück-Button */}
        <div className="scamgame-buttons" style={{ marginTop: '20px' }}>
          <button onClick={() => setActivePage('home')}>
            <Trans id="scamgame.button.backHome">Zurück</Trans>
          </button>
          <Link to="/scamgame/results" className="scamgame-link-button">
            <Trans id="scamgame.showResults">Meine Ergebnisse</Trans>
          </Link>
        </div>
      </div>
    );
  }

  // (F) Karriere-Home (Default)
  return (
    <div id="scamgame-home-container">
      <div className="scamgame-balance">
        <Trans id="scamgame.balance.dynamic">
          Dein Kontostand: {coinBalance} Coins
        </Trans>
      </div>

      {careerStatus && (
        <>
          <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>
            <Trans id="scamgame.career.titleIntro">
              Willkommen zum Krypto-Scam-Karriere-Modus
            </Trans>
          </h2>

          <div className="scamgame-intro data-card" style={{ marginBottom: '20px' }}>
            <p>
              <Trans id="scamgame.career.longIntro">
                In diesem Spiel erlebst du Schritt für Schritt die gängigsten Betrugsmaschen
                (von einfachen Phishing-Mails bis hin zu hochprofessionellen Krypto-Rugpulls).
                Sammle Erfahrung und lerne, rote Flaggen zu erkennen!
              </Trans>
            </p>
            <p>
              <Trans id="scamgame.career.longIntro2">
                Jeder Level enthält mehrere Szenarien – manche sind klarer Betrug, andere
                könnten tatsächlich legitim sein. Achte genau auf Details, um richtige Entscheidungen
                zu treffen. Dein Fortschritt wird gespeichert, sodass du jederzeit fortsetzen kannst!
              </Trans>
            </p>
          </div>

          <h3 style={{ marginBottom: '10px' }}>
            <Trans id="scamgame.career.currentLevelLabel">
              Dein aktuelles Level:
            </Trans>{' '}
            {careerStatus.currentLevel}
          </h3>

          {careerStatus.levelInfo && (
            <div className="data-card" style={{ marginBottom: '20px' }}>
              <h4 style={{ marginBottom: '10px' }}>
                {careerStatus.levelInfo.title}
              </h4>
              <p>{careerStatus.levelInfo.story}</p>
            </div>
          )}

          <div className="scamgame-buttons" style={{ marginTop: '20px' }}>
            <button onClick={handleStartLevel}>
              {careerStatus.scenariosPlayed && careerStatus.scenariosPlayed > 0 ? (
                <Trans id="scamgame.career.buttonContinue">Fortsetzen</Trans>
              ) : (
                <Trans id="scamgame.career.startLevel">Level starten</Trans>
              )}
            </button>

            {/* "Meine Ergebnisse" - Button als Link mit Style */}
            <Link to="/scamgame/results" className="scamgame-link-button">
              <Trans id="scamgame.showResults">Meine Ergebnisse</Trans>
            </Link>
          </div>
        </>
      )}

      {/* Falls wir WEDER loading NOCH error NOCH careerStatus haben => leere Anzeige */}
      {!careerStatus && !error && (
        <p>
          <Trans id="scamgame.loadingCareer">Lade Karriere-Daten...</Trans>
        </p>
      )}
    </div>
  );
};

export default ScamGame;
