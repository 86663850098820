import React from 'react';
import FavoriteStar from './FavoriteStar';

const Header = ({ coinData }) => {
  return (
    <div className="coin-header">
      <div className="logo-and-title">
        {coinData.image?.large && (
          <img
            src={coinData.image.large}
            alt={coinData.name}
            className="coin-logo-large"
          />
        )}
        <h2 className="coin-title">
          {coinData.name} ({coinData.symbol?.toUpperCase()})
        </h2>
        <span className="favstar-coindetails"><FavoriteStar coinId={coinData.id} coinData={coinData} /></span>
      </div>
    </div>
  );
};

export default Header;
