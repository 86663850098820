// src/context/NotificationsContext.js
import React, { createContext, useState, useEffect, useContext } from 'react';
import { AuthContext } from './AuthContext';
import api from '../services/api';

export const NotificationsContext = createContext();

export function NotificationsProvider({ children }) {
  const { isLoggedIn } = useContext(AuthContext);

  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);

  useEffect(() => {
    if (!isLoggedIn) {
      // Reset, wenn User ausgeloggt
      setNotifications([]);
      setUnreadCount(0);
      return;
    }

    // 1) Einmal initial laden
    fetchNotifications();

    // 2) Polling alle 22s (z. B.)
    const intervalId = setInterval(() => {
      fetchNotifications();
    }, 22000);

    return () => clearInterval(intervalId);
  }, [isLoggedIn]);

  const fetchNotifications = async () => {
    try {
      // Neuer Axios-Aufruf
      const resp = await api.get('/notifications');
      // resp.data => Array
      setNotifications(resp.data || []);
      const newUnreadCount = (resp.data || []).filter((n) => !n.is_read).length;
      setUnreadCount(newUnreadCount);
    } catch (err) {
      console.error('Fehler beim Laden von Notifications:', err);
    }
  };

  const markAsRead = async (notificationId) => {
    try {
      await api.put(`/notifications/${notificationId}/read`);
      // Lokal updaten
      setNotifications((prev) =>
        prev.map((n) =>
          n.id === notificationId ? { ...n, is_read: 1 } : n
        )
      );
      setUnreadCount((prev) => Math.max(prev - 1, 0));
    } catch (err) {
      console.error('markAsRead error:', err);
    }
  };

  const markAllAsRead = async () => {
    try {
      await api.put(`/notifications/mark-all-read`);
      setNotifications((prev) => prev.map((n) => ({ ...n, is_read: 1 })));
      setUnreadCount(0);
    } catch (err) {
      console.error('markAllAsRead error:', err);
    }
  };

  return (
    <NotificationsContext.Provider
      value={{
        notifications,
        unreadCount,
        fetchNotifications,
        markAsRead,
        markAllAsRead,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
}
