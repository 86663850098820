// src/components/dashboard/NewlyListedCoinsMini.js
import React, { useEffect, useState } from 'react';
import { Trans } from '@lingui/macro';
import { formatPrice } from '../../utils/formatPrice';
import { formatChangePercentage } from '../../utils/formatChangePercentage';
import { formatDate } from '../../utils/formatDate';
import api from '../../services/api'; // <-- Neu: Axios-Client

const NewlyListedCoinsMini = () => {
  const [coins, setCoins] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Currency
  const vsCurrency = 'usd';

  // Daten laden
  const fetchData = async () => {
    try {
      setError(null);
      // Statt fetch() jetzt api.get() verwenden und Query-Parameter übergeben
      const { data } = await api.get('/newly-listed-coins', {
        params: {
          page: 1,
          per_page: 5,
          currency: vsCurrency,
        },
      });
      setCoins(data.coins || []);
    } catch (err) {
      console.error('NewlyListedCoinsMini error:', err);
      const msg = err.response?.data?.message || err.message;
      setError(msg);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    const interval = setInterval(() => {
      fetchData();
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  if (loading) {
    return (
      <div className="newly-listed-coins-mini-widget">
        <Trans id="newlyListedCoinsMini.loading">
          Loading newly listed coins...
        </Trans>
      </div>
    );
  }

  if (error) {
    return (
      <div className="newly-listed-coins-mini-widget newly-listed-coins-mini-error">
        <Trans id="newlyListedCoinsMini.error">Error:</Trans> {error}
      </div>
    );
  }

  if (!coins || coins.length === 0) {
    return (
      <div className="newly-listed-coins-mini-widget">
        <Trans id="newlyListedCoinsMini.noData">No newly listed coins found.</Trans>
      </div>
    );
  }

  const handleRowClick = (coinId) => {
    window.location.href = `https://cryptoscan.digital/coin-details?coin=${coinId}&from=5`;
  };

  return (
    <div className="newly-listed-coins-mini-widget">
      <h4 style={{ textAlign: 'center' }}>
        <Trans id="title.newlyListedCoins">Newly Listed Coins</Trans>
      </h4>

      <table className="newly-listed-coins-mini-table">
        <thead>
          <tr>
            <th>
              <Trans id="label.name">Name</Trans>
            </th>
            <th>
              <Trans id="label.price">Price</Trans>
            </th>
            {/* 24h-Spalte bekommt eine Desktop-only-Klasse */}
            <th className="mini-desktop-only">
              <Trans id="label.24hChange">24h %</Trans>
            </th>
            <th>
              <Trans id="label.activatedAt">Aktiviert am</Trans>
            </th>
          </tr>
        </thead>
        <tbody>
          {coins.map((coin) => {
            const price = formatPrice(coin.price, vsCurrency);
            const change24h = formatChangePercentage(coin.price_change_percentage_24h);
            const activated = formatDate(coin.activated_at);

            return (
              <tr
                key={coin.id}
                onClick={() => handleRowClick(coin.id)}
                className="clickable-row"
              >
                <td>{coin.name}</td>

                {/* Preis-Zelle: Enthält bei Mobile zusätzlich 24h-Änderung (darunter) */}
                <td>
                  {price}
                  <div className="mini-mobile-24h-change">{change24h}</div>
                </td>

                {/* 24h-Spalte (Desktop) */}
                <td className="mini-desktop-only">{change24h}</td>

                <td>{activated}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default NewlyListedCoinsMini;
