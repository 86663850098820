// utils/formatDate.js
import { i18n } from '@lingui/core'

/**
 * Gibt einen relativen Zeit-String für vergangene Zeitpunkte zurück, z.B.:
 * - "Gerade eben" / "Just now"
 * - "Vor 3 Minuten" / "3 minutes ago"
 * - "Vor 2 Stunden" / "2 hours ago"
 * - "Vor 10 Tagen" / "10 days ago"
 * - "Vor 2 Monaten und 5 Tagen" / "2 months and 5 days ago"
 * - "Vor 1 Jahr und 3 Monaten" / "1 year and 3 months ago"
 * 
 * Wenn dateString leer/ungültig => return ''
 */
export function formatDate(dateString) {
  if (!dateString) {
    return ''; // Kein Wert => Keine Anzeige
  }

  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    return ''; // Ungültiges Datum => nichts anzeigen
  }

  const now = new Date();
  const diffMs = now - date;
  if (diffMs < 0) {
    // Datum liegt in der Zukunft
    return '';
  }

  const diffMinutes = Math.floor(diffMs / 60000);
  const isGerman = i18n.locale === 'de';

  if (diffMinutes < 1) {
    return isGerman ? 'Gerade eben' : 'Just now';
  }

  if (diffMinutes < 60) {
    return isGerman
      ? `vor ${diffMinutes} ${diffMinutes === 1 ? 'Minute' : 'Minuten'}`
      : `${diffMinutes} ${diffMinutes === 1 ? 'minute' : 'minutes'} ago`;
  }

  const hours = diffMs / 3600000;
  if (hours < 24) {
    const roundedHours = Math.round(hours);
    return isGerman
      ? `vor ${roundedHours} ${roundedHours === 1 ? 'Stunde' : 'Stunden'}`
      : `${roundedHours} ${roundedHours === 1 ? 'hour' : 'hours'} ago`;
  }

  const diffDays = Math.floor(diffMs / 86400000);
  if (diffDays < 30) {
    return isGerman
      ? `vor ${diffDays} ${diffDays === 1 ? 'Tag' : 'Tagen'}`
      : `${diffDays} ${diffDays === 1 ? 'day' : 'days'} ago`;
  }

  let monthDiff =
    (now.getFullYear() - date.getFullYear()) * 12 +
    (now.getMonth() - date.getMonth());
  if (now.getDate() < date.getDate()) {
    monthDiff--;
  }

  if (diffDays < 365) {
    const interimDate = new Date(date);
    interimDate.setMonth(interimDate.getMonth() + monthDiff);
    const leftoverDays = Math.floor((now - interimDate) / 86400000);

    if (leftoverDays > 0) {
      return isGerman
        ? `vor ${monthDiff} ${monthDiff === 1 ? 'Monat' : 'Monaten'} und ${leftoverDays} ${leftoverDays === 1 ? 'Tag' : 'Tagen'}`
        : `${monthDiff} ${monthDiff === 1 ? 'month' : 'months'} and ${leftoverDays} ${leftoverDays === 1 ? 'day' : 'days'} ago`;
    } else {
      return isGerman
        ? `vor ${monthDiff} ${monthDiff === 1 ? 'Monat' : 'Monaten'}`
        : `${monthDiff} ${monthDiff === 1 ? 'month' : 'months'} ago`;
    }
  }

  const yearDiff = Math.floor(monthDiff / 12);
  const remainingMonths = monthDiff % 12;

  if (remainingMonths > 0) {
    return isGerman
      ? `vor ${yearDiff} ${yearDiff === 1 ? 'Jahr' : 'Jahren'} und ${remainingMonths} ${remainingMonths === 1 ? 'Monat' : 'Monaten'}`
      : `${yearDiff} ${yearDiff === 1 ? 'year' : 'years'} and ${remainingMonths} ${remainingMonths === 1 ? 'month' : 'months'} ago`;
  } else {
    return isGerman
      ? `vor ${yearDiff} ${yearDiff === 1 ? 'Jahr' : 'Jahren'}`
      : `${yearDiff} ${yearDiff === 1 ? 'year' : 'years'} ago`;
  }
}

/**
 * Gibt einen relativen Zeit-String für zukünftige Zeitpunkte zurück, z.B.:
 * - "in 5 Minuten"
 * - "in 1 Stunde"
 * - "in 2 Stunden"
 * - "in 10 Tagen"
 * - "in 2 Monaten und 5 Tagen"
 * - "in 1 Jahr und 3 Monaten"
 * 
 * Wenn dateString leer/ungültig => return ''
 */
export function formatFutureDate(dateString) {
  if (!dateString) {
    return ''; // Kein Wert => Keine Anzeige
  }
  
  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    return ''; // Ungültiges Datum => nichts anzeigen
  }
  
  const now = new Date();
  const diffMs = date - now;
  if (diffMs < 0) {
    return ''; // Datum liegt in der Vergangenheit
  }
  
  const diffMinutes = Math.floor(diffMs / 60000);
  const isGerman = i18n.locale === 'de';
  
  if (diffMinutes < 1) {
    return isGerman ? 'in wenigen Augenblicken' : 'in a few moments';
  }
  
  if (diffMinutes < 60) {
    return isGerman
      ? `in ${diffMinutes} ${diffMinutes === 1 ? 'Minute' : 'Minuten'}`
      : `in ${diffMinutes} ${diffMinutes === 1 ? 'minute' : 'minutes'}`;
  }
  
  const hours = diffMs / 3600000;
  if (hours < 24) {
    const roundedHours = Math.round(hours);
    return isGerman
      ? `in ${roundedHours} ${roundedHours === 1 ? 'Stunde' : 'Stunden'}`
      : `in ${roundedHours} ${roundedHours === 1 ? 'hour' : 'hours'}`;
  }
  
  const diffDays = Math.floor(diffMs / 86400000);
  if (diffDays < 30) {
    return isGerman
      ? `in ${diffDays} ${diffDays === 1 ? 'Tag' : 'Tagen'}`
      : `in ${diffDays} ${diffDays === 1 ? 'day' : 'days'}`;
  }
  
  let monthDiff =
    (date.getFullYear() - now.getFullYear()) * 12 +
    (date.getMonth() - now.getMonth());
  if (date.getDate() < now.getDate()) {
    monthDiff--;
  }
  
  if (diffDays < 365) {
    const interimDate = new Date(now);
    interimDate.setMonth(interimDate.getMonth() + monthDiff);
    const leftoverDays = Math.floor((date - interimDate) / 86400000);
    
    if (leftoverDays > 0) {
      return isGerman
        ? `in ${monthDiff} ${monthDiff === 1 ? 'Monat' : 'Monaten'} und ${leftoverDays} ${leftoverDays === 1 ? 'Tag' : 'Tagen'}`
        : `in ${monthDiff} ${monthDiff === 1 ? 'month' : 'months'} and ${leftoverDays} ${leftoverDays === 1 ? 'day' : 'days'}`;
    } else {
      return isGerman
        ? `in ${monthDiff} ${monthDiff === 1 ? 'Monat' : 'Monaten'}`
        : `in ${monthDiff} ${monthDiff === 1 ? 'month' : 'months'}`;
    }
  }
  
  const yearDiff = Math.floor(monthDiff / 12);
  const remainingMonths = monthDiff % 12;
  
  if (remainingMonths > 0) {
    return isGerman
      ? `in ${yearDiff} ${yearDiff === 1 ? 'Jahr' : 'Jahren'} und ${remainingMonths} ${remainingMonths === 1 ? 'Monat' : 'Monaten'}`
      : `in ${yearDiff} ${yearDiff === 1 ? 'year' : 'years'} and ${remainingMonths} ${remainingMonths === 1 ? 'month' : 'months'}`;
  } else {
    return isGerman
      ? `in ${yearDiff} ${yearDiff === 1 ? 'Jahr' : 'Jahren'}`
      : `in ${yearDiff} ${yearDiff === 1 ? 'year' : 'years'}`;
  }
}
