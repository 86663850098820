// src/components/AdminUserManagement.js

import React, { useEffect, useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import AdminSubmenu from './AdminSubmenu';
import { formatDate } from '../../utils/formatDate';
import '../../styles/AdminUserManagement.css';

import {
  FaEdit,
  FaTrash,
  FaBell,
  FaCheck,
  FaTimes,
  FaStickyNote,
  FaCrown,
  FaSignOutAlt,
  FaBan,
  FaUserCircle,
  FaUsers,
  FaGoogle
} from 'react-icons/fa';

import api from '../../services/api'; // Unser Axios-Objekt

// ---------------------------------------
// Mobile-Check per User-Agent
// ---------------------------------------
function isMobileDevice() {
  return /Mobi|Android|iPhone|iPad|iPod|Opera Mini|IEMobile/i.test(navigator.userAgent);
}

// ---------------------------------------
// MySQL => Date
// ---------------------------------------
function parseMySQLDateTime(mysqlString) {
  if (!mysqlString) return null;
  return new Date(mysqlString.replace(' ', 'T'));
}

// ---------------------------------------
// Online < 5 min?
// ---------------------------------------
function isUserOnline(user) {
  if (!user.last_activity) return false;
  const dateObj = parseMySQLDateTime(user.last_activity);
  if (!dateObj || isNaN(dateObj.getTime())) return false;

  const diffMs = Date.now() - dateObj.getTime();
  const diffMin = diffMs / 60000;
  return diffMin < 5;
}

// ---------------------------------------
// relative Zeit
// ---------------------------------------
function getRelativeTime(user) {
  if (!user.last_activity) return '';
  return formatDate(user.last_activity);
}

// ---------------------------------------
function translateMessageKey(messageKey, tFunc) {
  switch (messageKey) {
    case 'error.dbQueryFailed':
      return tFunc({ id: 'error.dbQueryFailed', message: 'Datenbankfehler.' });
    case 'error.userNotFound':
      return tFunc({ id: 'error.userNotFound', message: 'Benutzer nicht gefunden.' });
    case 'error.loadingUsers':
      return tFunc({ id: 'error.loadingUsers', message: 'Fehler beim Laden der Benutzerliste.' });
    case 'success.roleUpdated':
      return tFunc({ id: 'success.roleUpdated', message: 'Rolle erfolgreich aktualisiert!' });
    case 'success.userDeleted':
      return tFunc({ id: 'success.userDeleted', message: 'Benutzer wurde gelöscht.' });
    case 'success.userBanned':
      return tFunc({ id: 'success.userBanned', message: 'Benutzer wurde gesperrt (banned).' });
    case 'success.userUnbanned':
      return tFunc({ id: 'success.userUnbanned', message: 'Benutzer wurde wieder entsperrt.' });
    default:
      return messageKey || tFunc({ id: 'error.unknown', message: 'Unbekannter Fehler.' });
  }
}

/**
 * Hilfsfunktion für Axios-Fehlerbehandlung
 */
function handleBackendError(err, tFunc) {
  if (err.response) {
    const { status, data } = err.response;
    if (status === 403) {
      toast.error(tFunc({ id: 'error.noPermission', message: 'Keine Berechtigung!' }));
    } else {
      toast.error(translateMessageKey(data?.messageKey, tFunc));
    }
  } else {
    toast.error(tFunc({ id: 'error.internalServerError', message: 'Interner Serverfehler oder Netzwerkfehler.' }));
  }
}

// ---------------------------------------
function renderGoogleIcon(user) { // tFunc wird nicht mehr übergeben
  // Prüfe nur noch, ob der User eine Google ID hat
  if (!user.google_id) return null;

  // Verwende direkt das t-Makro für die Übersetzung
  const titleText = t({
     id: 'admin.userManagement.googleUser',
     message: 'Google Benutzer'
   });

  return (
    <FaGoogle
      style={{ marginLeft: '5px', color: '#4285F4', fontSize: '0.8rem' }}
      title={titleText} // Verwende den übersetzten Text
    />
  );
}

const AdminUserManagement = () => {
  const { i18n } = useLingui();

  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(false);

  // GÄSTE
  const [guestCount, setGuestCount] = useState(0);

  // Rolle / Ban / Verified Modal
  const [showRoleModal, setShowRoleModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [newRole, setNewRole] = useState('');
  const [originalBanState, setOriginalBanState] = useState(0);
  const [modalIsBanned, setModalIsBanned] = useState(0);
  const [originalVerifiedState, setOriginalVerifiedState] = useState(0);
  const [modalIsVerified, setModalIsVerified] = useState(0);

  // Delete Modal
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState(null);

  // Notify Modal
  const [showNotifyModal, setShowNotifyModal] = useState(false);
  const [notifyUserId, setNotifyUserId] = useState(null);
  const [notifyTitle, setNotifyTitle] = useState('');
  const [notifyMessage, setNotifyMessage] = useState('');

  // Notes Modal
  const [showNotesModal, setShowNotesModal] = useState(false);
  const [notesUserId, setNotesUserId] = useState(null);
  const [notesText, setNotesText] = useState('');

  // Check ob mobil
  useEffect(() => {
    setIsMobile(isMobileDevice());
  }, []);

  /**
   * Benutzer-Liste laden (page=1)
   * => useCallback, damit useEffect uns nicht meckert
   */
  const fetchUsers = useCallback(async (page = 1) => {
    setIsLoading(true);
    try {
      const resp = await api.get(`/admin/users?page=${page}&limit=15`);
      const data = resp.data;

      const normalized = data.users.map((u) => ({
        ...u,
        is_banned: parseInt(u.is_banned, 10),
      }));

      setUsers(normalized);
      setCurrentPage(data.currentPage);
      setTotalPages(data.totalPages);
    } catch (err) {
      console.error('Fehler beim Abrufen der Benutzer:', err);
      handleBackendError(err, i18n._);
    } finally {
      setIsLoading(false);
    }
  }, [i18n]);

  /**
   * refreshUsers: stiller Reload
   */
  const refreshUsers = useCallback(
    async (page = currentPage) => {
      try {
        const resp = await api.get(`/admin/users?page=${page}&limit=15`);
        const data = resp.data;
        const normalized = data.users.map((u) => ({
          ...u,
          is_banned: parseInt(u.is_banned, 10),
        }));
        setUsers(normalized);
        setCurrentPage(data.currentPage);
        setTotalPages(data.totalPages);
      } catch (err) {
        console.error('Fehler beim stillen Refresh:', err);
        // optional kein Toast
      }
    },
    [i18n, currentPage]
  );

  /**
   * Gast-Session-Zähler
   */
  const fetchGuestCount = useCallback(async () => {
    try {
      const resp = await api.get('/admin/guests/active');
      setGuestCount(resp.data.totalGuests || 0);
    } catch (err) {
      console.error('Fehler fetchGuestCount:', err);
    }
  }, []);

  // OnMount => fetch (users(1) + guests)
  useEffect(() => {
    fetchUsers(1);
    fetchGuestCount();
  }, [fetchUsers, fetchGuestCount]);

  // Intervall => alle 30s => refresh & guests
  useEffect(() => {
    const interval = setInterval(() => {
      refreshUsers(currentPage);
      fetchGuestCount();
    }, 30000);
    return () => clearInterval(interval);
  }, [currentPage, refreshUsers, fetchGuestCount]);

  /**
   * handleLogoutAllUsers
   */
  const handleLogoutAllUsers = async () => {
    if (!window.confirm('Wirklich ALLE Nutzer ausloggen?')) return;
    try {
      await api.post('/admin/users/logout-all');
      toast.success('Alle Nutzer wurden ausgeloggt.');
    } catch (err) {
      console.error('Fehler beim logout-all:', err);
      handleBackendError(err, i18n._);
    }
  };

  /**
   * handleLogoutSingleUser
   */
  const handleLogoutSingleUser = async (userId) => {
    if (!window.confirm(`Benutzer #${userId} wirklich ausloggen?`)) return;
    try {
      await api.post(`/admin/users/${userId}/logout`);
      toast.success(`Benutzer #${userId} wurde ausgeloggt.`);
    } catch (err) {
      console.error('Fehler beim Ausloggen eines Nutzers:', err);
      handleBackendError(err, i18n._);
    }
  };

  // Paging
  const handlePrevPage = () => {
    if (currentPage > 1) {
      fetchUsers(currentPage - 1);
    }
  };
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      fetchUsers(currentPage + 1);
    }
  };

  // openRoleModal
  const openRoleModal = (userId, currentRole, isBanned, isVerified) => {
    setSelectedUserId(userId);
    setNewRole(currentRole);
    setOriginalBanState(isBanned);
    setModalIsBanned(isBanned);
    setOriginalVerifiedState(isVerified);
    setModalIsVerified(isVerified);
    setShowRoleModal(true);
  };

  /**
   * Rolle/Ban/Verify updaten
   */
  const handleUpdateRoleAndBan = async () => {
    if (!newRole.trim()) return;

    try {
      // 1) Rolle updaten
      await api.put(`/admin/users/${selectedUserId}/role`, { newRole });

      // 2) Ban
      if (modalIsBanned !== originalBanState) {
        if (modalIsBanned === 1) {
          // ban
          await api.put(`/admin/users/${selectedUserId}/ban`);
        } else {
          // unban
          await api.put(`/admin/users/${selectedUserId}/unban`);
        }
      }
      // 3) Verified
      if (modalIsVerified !== originalVerifiedState) {
        if (modalIsVerified === 1) {
          // verify
          await api.put(`/admin/users/${selectedUserId}/verify`);
        } else {
          // unverify
          await api.put(`/admin/users/${selectedUserId}/unverify`);
        }
      }

      // Refresh
      await refreshUsers(currentPage);

      // Schließen
      setShowRoleModal(false);
      setSelectedUserId(null);
      setNewRole('');
      setModalIsBanned(0);
      setModalIsVerified(0);

      toast.success(t({
        id: 'admin.userManagement.settingsSaved',
        message: 'Einstellungen gespeichert.'
      }));
    } catch (err) {
      console.error('Fehler beim Update von Rolle/Ban/Verified:', err);
      handleBackendError(err, i18n._);
    }
  };

  // openDeleteModal
  const openDeleteModal = (userId) => {
    setDeleteUserId(userId);
    setShowDeleteModal(true);
  };

  const handleDeleteUser = async () => {
    try {
      await api.delete(`/admin/users/${deleteUserId}`);
      toast.success(t({ id: 'success.userDeleted', message: 'Benutzer wurde gelöscht.' }));
      refreshUsers(currentPage);
    } catch (err) {
      console.error('Fehler beim Löschen:', err);
      handleBackendError(err, i18n._);
    } finally {
      setShowDeleteModal(false);
      setDeleteUserId(null);
    }
  };

  // openNotifyModal
  const openNotifyModal = (userId) => {
    setNotifyUserId(userId);
    setNotifyTitle('');
    setNotifyMessage('');
    setShowNotifyModal(true);
  };

  const handleSendNotification = async () => {
    if (!notifyTitle.trim() || !notifyMessage.trim()) {
      toast.error(t({ id: 'error.missingFields', message: 'Titel und Nachricht erforderlich!' }));
      return;
    }
    try {
      await api.post(`/admin/users/${notifyUserId}/notify`, {
        title: notifyTitle,
        message: notifyMessage
      });
      toast.success(t({ id: 'success.notificationSent', message: 'Benachrichtigung gesendet!' }));
    } catch (err) {
      console.error('Fehler beim Senden einer Benachrichtigung:', err);
      handleBackendError(err, i18n._);
    } finally {
      setShowNotifyModal(false);
      setNotifyUserId(null);
      setNotifyTitle('');
      setNotifyMessage('');
    }
  };

  // openNotesModal
  const openNotesModal = (userId, existingNotes) => {
    setNotesUserId(userId);
    setNotesText(existingNotes || '');
    setShowNotesModal(true);
  };

  const handleSaveNotes = async () => {
    try {
      await api.put(`/admin/users/${notesUserId}/notes`, { notes: notesText });
      toast.success(t({ id: 'success.notesSaved', message: 'Notizen erfolgreich gespeichert.' }));
      refreshUsers(currentPage);
    } catch (err) {
      console.error('Fehler beim Speichern der Notizen:', err);
      handleBackendError(err, i18n._);
    } finally {
      setShowNotesModal(false);
      setNotesUserId(null);
      setNotesText('');
    }
  };

  // ABO-Icon => "pro"
  const renderSubscriptionIcon = (user) => {
    if (user.subscription_level === 'pro') {
      return (
        <FaCrown
          style={{ marginLeft: '6px', color: '#f4b400' }}
          title="Pro"
        />
      );
    }
    return null;
  };

  // Online-Count
  const onlineCount = users.filter(isUserOnline).length;

  // MOBILE => Minimale Info
  if (isMobile) {
    return (
      <div className="adminusermanagement-container" style={{ textAlign: 'center' }}>
        <h2>
          <Trans id="admin.userManagement.title">Benutzerverwaltung</Trans>
        </h2>
        <div style={{ margin: '20px 0', fontSize: '1.1rem' }}>
          <FaUsers style={{ color: 'green', marginRight: '6px' }} />
          {onlineCount}{' '}
          {i18n.locale === 'de' ? 'Benutzer online' : 'users online'}

          {/* Gast-Zähler */}
          <span style={{ margin: '0 8px' }}>|</span>
          <FaUsers style={{ color: 'blue', marginRight: '6px' }} />
          {guestCount}{' '}
          {i18n.locale === 'de' ? 'Gäste' : 'guests'}
        </div>
        <div style={{ marginTop: '20px', fontSize: '1.1rem', color: '#cc3333' }}>
          {i18n.locale === 'de'
            ? 'Diese Ansicht ist nicht für mobile Geräte optimiert. Bitte wechsle auf einen Desktop.'
            : 'This view is not optimized for mobile devices. Please switch to a desktop.'
          }
        </div>
      </div>
    );
  }

  return (
    <div className="adminusermanagement-container">
      <AdminSubmenu />

      <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>
        <Trans id="admin.userManagement.title">Benutzerverwaltung</Trans>
      </h2>

      {/* Oberer Bereich: X online, Y Gäste, "Alle ausloggen" */}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '20px'
        }}
      >
        {/* Links */}
        <div style={{ fontSize: '1.1rem' }}>
          <FaUsers style={{ color: 'green', marginRight: '6px' }} />
          {onlineCount}{' '}
          {i18n.locale === 'de' ? 'Benutzer online' : 'users online'}

          <span style={{ margin: '0 8px' }}>|</span>

          <FaUsers style={{ color: 'blue', marginRight: '6px' }} />
          {guestCount}{' '}
          {i18n.locale === 'de' ? 'Gäste' : 'guests'}
        </div>

        {/* Rechts */}
        <button
          className="adminusermanagement-pagination-button"
          onClick={handleLogoutAllUsers}
        >
          <Trans id="admin.userManagement.logoutAll">Alle ausloggen</Trans>
        </button>
      </div>

      {isLoading ? (
        <p><Trans id="admin.userManagement.loading">Lade Benutzer...</Trans></p>
      ) : (
        <>
          <table className="adminusermanagement-table">
            <thead>
              <tr>
                <th><Trans id="admin.userManagement.username">Benutzer</Trans></th>
                <th><Trans id="admin.userManagement.role">Rolle</Trans></th>
                <th><Trans id="admin.userManagement.gamesGlobalScore">Coins</Trans></th>
                <th><Trans id="admin.userManagement.createdAt">Erstellt</Trans></th>
                <th><Trans id="admin.userManagement.actions">Aktionen</Trans></th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => {
                const rowClass = user.is_banned === 1 ? 'banned-row' : '';
                const online = isUserOnline(user);
                const relativeTime = getRelativeTime(user);
                const badgeColor = online ? 'green' : 'red';

                let statusIcon = null;
                if (user.is_banned === 1) {
                  // banned
                  statusIcon = (
                    <FaBan
                      style={{ marginLeft: '6px', color: 'red' }}
                      title={t({
                        id: 'admin.userManagement.bannedUser',
                        message: 'Dieser Benutzer ist gesperrt.'
                      })}
                    />
                  );
                } else if (user.is_verified) {
                  // verified
                  statusIcon = (
                    <FaCheck
                      style={{ marginLeft: '6px', color: 'green' }}
                      title={t({
                        id: 'admin.userManagement.verifiedUser',
                        message: 'Benutzer ist verifiziert.'
                      })}
                    />
                  );
                } else {
                  // not verified
                  statusIcon = (
                    <FaTimes
                      style={{ marginLeft: '6px', color: 'red' }}
                      title={t({
                        id: 'admin.userManagement.notVerifiedUser',
                        message: 'Benutzer ist nicht verifiziert.'
                      })}
                    />
                  );
                }

                return (
                  <tr key={user.id} className={rowClass}>
                    <td>
                      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '4px' }}>
                        <FaUserCircle
                          style={{
                            fontSize: '20px',
                            marginRight: '6px',
                            color: '#888'
                          }}
                        />
                        <span>
                          {user.username} (#{user.id})
                        </span>

                        {statusIcon}
                        {renderGoogleIcon(user, i18n._)}
                        {renderSubscriptionIcon(user)}

                        {/* Online/Offline-Badge */}
                        <span style={{ display: 'inline-flex', alignItems: 'center', marginLeft: '6px' }}>
                          <span
                            style={{
                              width: '8px',
                              height: '8px',
                              borderRadius: '50%',
                              backgroundColor: badgeColor,
                              marginRight: '4px'
                            }}
                            title={online
                              ? t({ id: 'user.online', message: 'Online' })
                              : t({ id: 'user.offline', message: 'Offline' })
                            }
                          />
                          <small style={{ fontSize: '0.75rem', color: '#555' }}>
                            {online
                              ? (i18n.locale === 'de' ? 'Online' : 'Online')
                              : relativeTime
                            }
                          </small>
                        </span>
                      </div>
                      <div className="adminusermanagement-user-email">
                        {user.email}
                      </div>
                    </td>

                    <td>{user.role}</td>
                    <td>{String(user.games_global_score || '-')}</td>
                    <td>{formatDate(user.created_at)}</td>
                    <td>
                      {/* Rolle & Ban */}
                      <button
                        className="btn-action"
                        title={t({ id: 'tooltip.editRole', message: 'Rolle ändern / Ban' })}
                        onClick={() => openRoleModal(user.id, user.role, user.is_banned, user.is_verified)}
                      >
                        <FaEdit />
                      </button>

                      {/* Notification */}
                      <button
                        className="btn-action"
                        title={t({ id: 'tooltip.sendNotification', message: 'Benachrichtigen' })}
                        onClick={() => openNotifyModal(user.id)}
                      >
                        <FaBell style={{ color: '#17a2b8' }} />
                      </button>

                      {/* Notes */}
                      <button
                        className="btn-action"
                        title={t({ id: 'tooltip.editNotes', message: 'Notizen bearbeiten' })}
                        onClick={() => openNotesModal(user.id, user.notes)}
                      >
                        <FaStickyNote style={{ color: 'orange' }} />
                      </button>

                      {/* Logout Single */}
                      <button
                        className="btn-action"
                        title={t({ id: 'tooltip.logoutUser', message: 'Ausloggen' })}
                        onClick={() => handleLogoutSingleUser(user.id)}
                      >
                        <FaSignOutAlt style={{ color: '#ff9900' }} />
                      </button>

                      {/* Delete User */}
                      <button
                        className="btn-action"
                        title={t({ id: 'tooltip.deleteUser', message: 'Löschen' })}
                        onClick={() => openDeleteModal(user.id)}
                      >
                        <FaTrash style={{ color: '#dc3545' }} />
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          {totalPages > 1 && (
            <div className="adminusermanagement-pagination">
              <button
                className="adminusermanagement-pagination-button"
                disabled={currentPage <= 1}
                onClick={handlePrevPage}
              >
                <Trans id="pagination.prev">Zurück</Trans>
              </button>
              <span className="adminusermanagement-pagination-status">
                <Trans id="pagination.page">Seite</Trans> {currentPage}{' '}
                <Trans id="pagination.of">von</Trans> {totalPages}
              </span>
              <button
                className="adminusermanagement-pagination-button"
                disabled={currentPage >= totalPages}
                onClick={handleNextPage}
              >
                <Trans id="pagination.next">Weiter</Trans>
              </button>
            </div>
          )}
        </>
      )}

      {/* ROLE & BAN Modal */}
      {showRoleModal && (
        <div className="adminusermanagement-modal-overlay">
          <div className="adminusermanagement-modal-content">
            <h3>
              <Trans id="admin.userManagement.changeRoleBan">
                Rolle ändern / Sperrstatus
              </Trans>
            </h3>

            <label style={{ display: 'block', marginBottom: '6px' }}>
              <Trans id="admin.userManagement.changeRole">Rolle</Trans>:
            </label>
            <select
              value={newRole}
              onChange={(e) => setNewRole(e.target.value)}
            >
              <option value="user">User</option>
              <option value="admin">Admin</option>
              <option value="superuser">Superuser</option>
            </select>

            {/* BAN CHECKBOX */}
            <div style={{ marginTop: '12px' }}>
              <label>
                <input
                  type="checkbox"
                  checked={modalIsBanned === 1}
                  onChange={(e) => setModalIsBanned(e.target.checked ? 1 : 0)}
                />
                {' '}
                <Trans id="admin.userManagement.banUserCheckbox">
                  Nutzer ist gesperrt (Ban)?
                </Trans>
              </label>
            </div>

            {/* VERIFIED CHECKBOX */}
            <div style={{ marginTop: '12px' }}>
              <label>
                <input
                  type="checkbox"
                  checked={modalIsVerified === 1}
                  onChange={(e) => setModalIsVerified(e.target.checked ? 1 : 0)}
                />
                {' '}
                <Trans id="admin.userManagement.verifyUserCheckbox">
                  E-Mail als verifiziert markieren?
                </Trans>
              </label>
            </div>

            <div className="modal-buttons" style={{ marginTop: '20px' }}>
              <button onClick={handleUpdateRoleAndBan}>
                <Trans id="button.save">Speichern</Trans>
              </button>
              <button
                onClick={() => {
                  setShowRoleModal(false);
                  setSelectedUserId(null);
                  setNewRole('');
                  setModalIsBanned(0);
                  setModalIsVerified(0);
                }}
              >
                <Trans id="modal.cancel">Abbrechen</Trans>
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Delete Modal */}
      {showDeleteModal && (
        <div className="adminusermanagement-modal-overlay">
          <div className="adminusermanagement-modal-content">
            <h3>
              <Trans id="admin.userManagement.deleteTitle">Benutzer löschen</Trans>
            </h3>
            <p>
              <Trans id="admin.userManagement.deleteConfirm">
                Bist du sicher, dass du diesen Benutzer löschen möchtest?
              </Trans>
            </p>
            <div className="modal-buttons">
              <button onClick={handleDeleteUser}>
                <Trans id="modal.confirmDelete">Löschen</Trans>
              </button>
              <button
                onClick={() => {
                  setShowDeleteModal(false);
                  setDeleteUserId(null);
                }}
              >
                <Trans id="modal.cancel">Abbrechen</Trans>
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Notify Modal */}
      {showNotifyModal && (
        <div className="adminusermanagement-modal-overlay">
          <div className="adminusermanagement-modal-content">
            <h3>
              <Trans id="admin.userManagement.notifyUser">Benutzer benachrichtigen</Trans>
            </h3>
            <div className="form-group">
              <label>
                <Trans id="placeholder.notificationTitle">Titel</Trans>
              </label>
              <input
                type="text"
                value={notifyTitle}
                onChange={(e) => setNotifyTitle(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>
                <Trans id="placeholder.notificationMessage">Nachricht</Trans>
              </label>
              <textarea
                rows={4}
                value={notifyMessage}
                onChange={(e) => setNotifyMessage(e.target.value)}
              />
            </div>
            <div className="modal-buttons">
              <button onClick={handleSendNotification}>
                <Trans id="button.send">Senden</Trans>
              </button>
              <button
                onClick={() => {
                  setShowNotifyModal(false);
                  setNotifyUserId(null);
                  setNotifyTitle('');
                  setNotifyMessage('');
                }}
              >
                <Trans id="modal.cancel">Abbrechen</Trans>
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Notes Modal */}
      {showNotesModal && (
        <div className="adminusermanagement-modal-overlay">
          <div className="adminusermanagement-modal-content">
            <h3>
              <Trans id="admin.userManagement.editNotes">Notizen bearbeiten</Trans>
            </h3>
            <div className="form-group">
              <label>
                <Trans id="admin.userManagement.notesLabel">Notizen</Trans>
              </label>
              <textarea
                rows={6}
                value={notesText}
                onChange={(e) => setNotesText(e.target.value)}
              />
            </div>
            <div className="modal-buttons" style={{ marginTop: '20px' }}>
              <button onClick={handleSaveNotes}>
                <Trans id="button.save">Speichern</Trans>
              </button>
              <button
                onClick={() => {
                  setShowNotesModal(false);
                  setNotesUserId(null);
                  setNotesText('');
                }}
              >
                <Trans id="modal.cancel">Abbrechen</Trans>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminUserManagement;
