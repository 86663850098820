// src/components/ScamGame/ScamGameResults.js
import React, { useState, useEffect } from 'react';
import { Trans } from '@lingui/macro';
import { Link } from 'react-router-dom';
import api from '../../services/api'; // <-- Zentrales Axios-Objekt
import '../../styles/ScamGame.css';

const ScamGameResults = () => {
  // State für alle Ergebnisse
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(true);

  // State für aufgeklappte Einträge
  const [expandedItems, setExpandedItems] = useState([]);

  // Karriere-Status, um z. B. das aktuelle Level anzuzeigen
  const [careerStatus, setCareerStatus] = useState(null);
  const [loadingCareer, setLoadingCareer] = useState(false);

  // 1) Lifecycle: Ergebnisse + Karriere laden
  useEffect(() => {
    fetchResults();
    fetchCareerStatus();
  }, []);

  // 2) API: Alle bisherigen Antworten (Scamgame-Results)
  const fetchResults = async () => {
    setLoading(true);
    try {
      const { data } = await api.get('/scamgame/results');
      setResults(data);
    } catch (err) {
      console.error('Fehler beim Laden der Ergebnisse:', err);
    } finally {
      setLoading(false);
    }
  };

  // 3) API: Karriere-Status (aktuelles Level etc.)
  const fetchCareerStatus = async () => {
    setLoadingCareer(true);
    try {
      const { data } = await api.get('/scamgame/career/status');
      setCareerStatus(data);
    } catch (err) {
      console.error('Fehler beim Laden des Karriere-Status:', err);
    } finally {
      setLoadingCareer(false);
    }
  };

  // 4) Klick zum Aus- oder Einklappen eines Eintrags
  const handleToggleExpand = (resultId) => {
    setExpandedItems((prev) => {
      if (prev.includes(resultId)) {
        // bereits expanded => schließe es
        return prev.filter((id) => id !== resultId);
      } else {
        // noch nicht expanded => füge hinzu
        return [...prev, resultId];
      }
    });
  };

  // 5) Render

  // A) Loading-Zustand für die Ergebnisse
  if (loading) {
    return (
      <p>
        <Trans id="scamgame.loadingResults">Lade Ergebnisse...</Trans>
      </p>
    );
  }

  return (
    <div id="scamgame-results-container">
      <div className="scamgame-results data-card">
        <h3>
          <Trans id="scamgame.results.title">Deine bisherigen Antworten</Trans>
        </h3>

        {/* Aktuelles Level anzeigen (falls gewünscht) */}
        {!loadingCareer && careerStatus && careerStatus.currentLevel && (
          <p style={{ marginBottom: '15px' }}>
            <Trans id="scamgame.career.currentLevelLabel">Dein aktuelles Level:</Trans>{' '}
            <b>{careerStatus.currentLevel}</b>
          </p>
        )}

        {/* Falls noch keine Antworten */}
        {results.length === 0 && (
          <p>
            <Trans id="scamgame.results.noAnswers">Du hast noch keine Antworten gegeben.</Trans>
          </p>
        )}

        {/* Wenn wir Antworten haben */}
        {results.length > 0 && (
          <ul className="scamgame-results-list">
            {results.map((res) => {
              const isCorrect = !!res.is_correct;
              const expanded = expandedItems.includes(res.id);

              return (
                <li
                  key={res.id}
                  onClick={() => handleToggleExpand(res.id)}
                  style={{
                    cursor: 'pointer',
                    marginBottom: '10px',
                    padding: '8px',
                    borderRadius: '6px',
                    border: '1px solid #666'
                  }}
                >
                  {/* Haupt-Infos (Titel, Richtig/Falsch, Punkte) */}
                  {isCorrect ? (
                    <span className="scamgame-result-icon correct">✔️</span>
                  ) : (
                    <span className="scamgame-result-icon wrong">❌</span>
                  )}{' '}
                  <b>{res.title}</b> – {res.user_answer.toUpperCase()} –{' '}
                  {isCorrect ? (
                    <Trans id="scamgame.results.correct">Richtig</Trans>
                  ) : (
                    <Trans id="scamgame.results.wrong">Falsch</Trans>
                  )}{' '}
                  (
                  {res.points}{' '}
                  <Trans id="scamgame.results.points">Punkte</Trans>
                  )

                  {/* Wenn expanded => mehr Details */}
                  {expanded && (
                    <div
                      style={{
                        marginTop: '6px',
                        fontSize: '0.95rem',
                        background: 'var(--secondary-bg-color)',
                        padding: '8px',
                        borderRadius: '4px'
                      }}
                    >
                      {/* answered_at-Datum (falls vorhanden) */}
                      {res.answered_at && (
                        <p style={{ marginBottom: '5px' }}>
                          <b>
                            <Trans id="scamgame.results.dateLabel">Datum:</Trans>
                          </b>{' '}
                          {new Date(res.answered_at).toLocaleString()}
                        </p>
                      )}

                      {/* Typ */}
                      <p style={{ marginBottom: '5px' }}>
                        <b>
                          <Trans id="scamgame.results.typeLabel">Typ:</Trans>
                        </b>{' '}
                        {res.type || '—'}
                      </p>

                      {/* Erklärung */}
                      <p>
                        <b>
                          <Trans id="scamgame.explanation">Erklärung:</Trans>
                        </b>{' '}
                        {res.explanation || '—'}
                      </p>
                    </div>
                  )}
                </li>
              );
            })}
          </ul>
        )}

        {/* Zurück-Button => Link zur Haupt-ScamGame-Route */}
        <div className="scamgame-buttons" style={{ marginTop: '20px' }}>
          <Link to="/scamgame" className="scamgame-link-button">
            <Trans id="scamgame.results.backHome">Zurück zur Auswahl</Trans>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ScamGameResults;
