// src/components/FuturesExchangeRow.js

import React from 'react';
import { t } from '@lingui/macro';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { formatLargeValue } from '../utils/formatLargeValue';

const FuturesExchangeRow = React.memo(({ exchange, onClick, rank, loading, vsCurrency, conversionFactor }) => {
  if (loading) {
    return (
      <tr>
        <td><Skeleton width={20} /></td>
        <td className="futures-info-cell"><Skeleton width={100} /></td>
        <td><Skeleton width={80} /></td>
        <td><Skeleton width={80} /></td>
        <td><Skeleton width={80} /></td>
      </tr>
    );
  }

  // Aggregiere die Werte aus allen Pairs der Exchange
  const totalOpenInterest = exchange.pairs.reduce(
    (sum, pair) => sum + (Number(pair.open_interest) || 0),
    0
  );
  const totalVolume = exchange.pairs.reduce(
    (sum, pair) => sum + (Number(pair.volume_24h) || 0),
    0
  );

  // Wende den Umrechnungsfaktor an (Rohwerte in USD)
  const convertedOpenInterest = totalOpenInterest * conversionFactor;
  const convertedVolume = totalVolume * conversionFactor;

  return (
    <tr onClick={() => onClick(exchange)}>
      <td data-label={t({ id: "label.rankMobile", message: "🏅 Rank" })}>{rank}</td>
      <td className="futures-info-cell" data-label={t({ id: "label.marketMobile", message: "🏦 Market" })}>
        <span className="futures-market">{exchange.market}</span>
      </td>
      <td data-label={t({ id: "label.pairsMobile", message: "Pairs" })}>
        {exchange.pairsCount} Pairs
      </td>
      <td data-label={t({ id: "label.openInterestMobile", message: "📊 Open Interest" })}>
        {formatLargeValue(convertedOpenInterest, vsCurrency, false, true, true, '')}
      </td>
      <td data-label={t({ id: "label.volumeMobile", message: "💹 24h Volumen" })}>
        {formatLargeValue(convertedVolume, vsCurrency, false, true, true, '')}
      </td>
    </tr>
  );
});

export default FuturesExchangeRow;
