import React, { useMemo, useState } from 'react';
import Select, { components } from 'react-select';
import { t } from '@lingui/macro';

/**
 * Custom MenuList, die Touch-Events isoliert, sodass Scrollen im Dropdown
 * nicht an die übergeordnete Seite weitergereicht wird.
 */
const CustomMenuList = (props) => {
  const handleTouchMove = (e) => {
    e.stopPropagation();
  };

  return (
    <div onTouchMove={handleTouchMove}>
      <components.MenuList {...props} />
    </div>
  );
};

/**
 * Custom ClearIndicator, der statt eines "×" den Text "RESET" anzeigt.
 * Der Text wechselt beim Hover sanft in die Farbe var(--primary-color).
 * Er wird nur angezeigt, wenn ein Wert ausgewählt wurde und dessen Label nicht dem Placeholder entspricht.
 */
const CustomClearIndicator = (props) => {
  const [hover, setHover] = useState(false);
  const selected = props.selectProps.value;
  if (!selected || selected.label === props.selectProps.placeholder) return null;

  return (
    <components.ClearIndicator {...props}>
      <div
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        style={{
          padding: '0 8px',
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          transition: 'opacity 0.2s ease, color 0.2s ease',
        }}
      >
        <span
          style={{
            fontSize: '16px',
            fontWeight: 'normal', // dünn, nicht bold
            color: hover ? 'var(--primary-color)' : 'var(--text-color)',
            opacity: hover ? 1 : 0.5, // im Normalzustand halbtransparent
          }}
        >
          RESET
        </span>
      </div>
    </components.ClearIndicator>
  );
};

function SingleSelectSearch({
  options,
  selectedValue,
  onChange,
  placeholder = 'Bitte wählen...',
  ...props
}) {
  // Optionen normalisieren:
  const normalizedOptions = useMemo(() => {
    if (!options.length) return [];
    if (typeof options[0] === 'object') {
      if (options[0].hasOwnProperty('category_id')) {
        return [
          {
            value: '',
            label: placeholder,
          },
          ...options.map((cat) => ({
            value: cat.category_id,
            label: cat.name || cat.category_id,
          })),
        ];
      }
      if (options[0].hasOwnProperty('value') && options[0].hasOwnProperty('label')) {
        return options;
      }
      return options.map((opt) => ({
        value: opt,
        label: String(opt),
      }));
    } else {
      return options.map((opt) => ({ value: opt, label: String(opt) }));
    }
  }, [options, placeholder]);

  // Den aktuell ausgewählten Wert als Objekt finden.
  const currentValueObj = useMemo(() => {
    return normalizedOptions.find((opt) => opt.value === selectedValue) || null;
  }, [normalizedOptions, selectedValue]);

  // Custom Styles – hier kannst du weiter anpassen:
  const customStyles = {
    container: (base) => ({
      ...base,
      minWidth: '260px',
      backgroundColor: 'transparent',
    }),
    control: (base, state) => ({
      ...base,
      backgroundColor: 'transparent',
      border: '0.5px solid var(--primary-color)',
      boxShadow: state.isFocused ? '0 0 4px var(--primary-color)' : 'none',
      minHeight: '30px',
      cursor: 'pointer',
      transition: 'box-shadow 0.2s ease',
      '&:hover': {
        boxShadow: '0 0 4px var(--primary-color)',
      },
    }),
    valueContainer: (base) => ({
      ...base,
      backgroundColor: 'transparent',
      padding: '0 4px',
      fontSize: '0.9rem',
    }),
    input: (base) => ({
      ...base,
      backgroundColor: 'transparent',
      color: 'var(--text-color)',
      caretColor: 'var(--text-color)',
      margin: 0,
      padding: 0,
      fontSize: '0.9rem',
    }),
    singleValue: (base) => ({
      ...base,
      color: 'var(--text-color)',
      fontSize: '0.9rem',
    }),
    placeholder: (base) => ({
      ...base,
      color: 'var(--text-color)',
      opacity: 0.6,
      fontSize: '0.9rem',
    }),
    menu: (base) => ({
      ...base,
      backgroundColor: 'var(--background-color2)',
      border: '0.5px solid var(--secondary-bg-color)',
      zIndex: 9999,
      whiteSpace: 'nowrap',
      fontSize: '0.9rem',
    }),
    menuList: (base) => ({
      ...base,
      maxHeight: '200px',
      overflowY: 'auto',
      WebkitOverflowScrolling: 'touch',
    }),
    option: (base, state) => ({
      ...base,
      whiteSpace: 'nowrap',
      backgroundColor: state.isSelected
        ? 'var(--btn-primary-bg)'
        : state.isFocused
          ? 'var(--secondary-bg-color)'
          : 'transparent',
      color: state.isSelected ? '#fff' : 'var(--text-color)',
      cursor: 'pointer',
      fontSize: '0.9rem',
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      color: state.isFocused ? 'var(--primary-color)' : 'var(--text-color)',
      '&:hover': {
        color: 'var(--primary-color)',
      },
    }),
    indicatorSeparator: (base) => ({
      ...base,
      backgroundColor: 'transparent',
    }),
  };

  return (
    <Select
      options={normalizedOptions}
      value={currentValueObj}
      onChange={(selected) => onChange(selected ? selected.value : '')}
      isSearchable={true}
      isClearable={true}
      placeholder={placeholder}
      styles={customStyles}
      components={{
        MenuList: CustomMenuList,
        ClearIndicator: CustomClearIndicator,
      }}
      {...props}
    />
  );
}

export default SingleSelectSearch;
