// src/components/NotificationsOverlay.jsx

import React, { useContext, useState } from 'react';
import { NotificationsContext } from '../context/NotificationsContext';
import { useNavigate } from 'react-router-dom';
import { FaTimes, FaExternalLinkAlt } from 'react-icons/fa';
import { Trans } from '@lingui/macro';
import { formatDate } from '../utils/formatDate';
import { StyledButton } from '../utils/StyledButton'; // Import des neuen Buttons

const NotificationsOverlay = ({ styleType, onClose }) => {
  const { notifications, markAsRead, markAllAsRead } = useContext(NotificationsContext);
  const navigate = useNavigate();
  const [hoveredId, setHoveredId] = useState(null);

  if (styleType !== 'dropdown') return null;

  const handleMarkAllRead = () => {
    markAllAsRead();
  };

  const handleNotificationClick = (n) => {
    if (!n.link) {
      if (!n.is_read) {
        markAsRead(n.id);
      }
      return;
    }
    if (!n.is_read) {
      markAsRead(n.id);
    }
    if (n.link.startsWith('/')) {
      navigate(n.link);
    } else {
      window.open(n.link, '_blank');
    }
    if (onClose) onClose();
  };

  const handleMouseEnter = (id) => setHoveredId(id);
  const handleMouseLeave = () => setHoveredId(null);

  return (
    <div
      className="notifications_dropdown-panel"
      onMouseDown={(e) => e.stopPropagation()}
      style={{
        maxHeight: '400px',
        overflowY: 'auto',
        position: 'absolute',
        right: '0px',
        top: '60px',
        zIndex: 9999,
        border: '1px solid var(--secondary-bg-color)',
        borderRadius: '8px',
        backgroundColor: 'var(--background-color2)',
        padding: '10px',
      }}
    >
      {/* Kopfzeile */}
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '6px' }}>
        <strong>
          <Trans id="notifications.title">Benachrichtigungen</Trans>
        </strong>
        <FaTimes style={{ cursor: 'pointer' }} onClick={onClose} />
      </div>

      {/* Button "Alle gelesen" mit dem neuen StyledButton */}
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '6px' }}>
        <StyledButton
          onClick={handleMarkAllRead}
          translationKey="notifications.markAllAsRead"
          defaultMessage="Alle gelesen"
          style={{ fontSize: '0.85rem', padding: '5px 8px', marginBottom: '5px' }}
        />
      </div>

      {notifications.length === 0 ? (
        <div style={{ fontStyle: 'italic' }}>
          <Trans id="notifications.none">Keine Benachrichtigungen</Trans>
        </div>
      ) : (
        notifications.map((n) => {
          const unreadBg = n.is_read ? 'transparent' : 'rgba(255, 239, 138, 0.2)';
          const hoverBg = n.link ? 'rgba(128, 180, 255, 0.2)' : unreadBg;
          const isHovered = hoveredId === n.id;

          return (
            <div
              key={n.id}
              onClick={() => handleNotificationClick(n)}
              onMouseEnter={() => handleMouseEnter(n.id)}
              onMouseLeave={handleMouseLeave}
              style={{
                padding: '8px',
                marginBottom: '4px',
                borderRadius: '4px',
                // Hier wird der Rahmen hinzugefügt:
                border: '1px solid var(--secondary-bg-color)',
                cursor: n.link ? 'pointer' : 'default',
                backgroundColor: isHovered ? hoverBg : unreadBg,
                transition: 'background-color 0.15s ease',
              }}
            >
              {/* Titel + Link-Icon */}
              <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                <div style={{ fontWeight: 'bold' }}>{n.title}</div>
                {n.link && (
                  <FaExternalLinkAlt
                    style={{
                      fontSize: '0.85rem',
                      opacity: 0.8,
                      flexShrink: 0,
                    }}
                  />
                )}
              </div>

              {/* Nachricht */}
              <div style={{ fontSize: '0.9rem', marginTop: '2px' }}>{n.message}</div>

              {/* Datum */}
              <div style={{ fontSize: '0.7rem', color: '#999', marginTop: '4px' }}>
                {formatDate(n.created_at, false)}
              </div>
            </div>
          );
        })
      )}
    </div>
  );
};

export default NotificationsOverlay;
