// src/components/SocialGoogleLink.js
import React from 'react';
import { toast } from 'react-toastify';
import { t, Trans } from '@lingui/macro';
import { FaGoogle } from 'react-icons/fa';
import { StyledButton } from '../utils/StyledButton';
import api from '../services/api'; // Unser zentraler Axios-Client

/**
 * @param {boolean} googleLinked - aktueller Status (true/false)
 * @param {function} setGoogleLinked - Updater-Funktion aus EditProfile
 */
function SocialGoogleLink({ googleLinked, setGoogleLinked }) {
  // 1) Unlink Google (bleibt unverändert)
  const handleUnlinkGoogle = async () => {
    if (
      !window.confirm(
        t({
          id: 'social.unlinkConfirm',
          message: 'Google-Verknüpfung wirklich aufheben?',
        })
      )
    )
      return;

    try {
      const { data } = await api.put('/profile/unlink-google');
      if (data.messageKey === 'success.googleUnlinked') {
        toast.success(
          t({ id: 'social.googleUnlinked', message: 'Google-Verknüpfung aufgehoben.' })
        );
        setGoogleLinked(false);
      } else {
        toast.error(t({ id: 'social.unlinkError', message: 'Fehler beim Entkoppeln.' }));
      }
    } catch (err) {
      console.error(err);
      toast.error(t({ id: 'social.unlinkError', message: 'Fehler beim Entkoppeln.' }));
    }
  };

  // 2) Link Google – jetzt mit Abruf des Link-Tokens
  const handleLinkGoogle = async () => {
    try {
      // Rufe den Link-Token vom neuen Backend-Endpoint ab
      const tokenResponse = await api.get('/auth/google/link-token', { withCredentials: true });
      if (!tokenResponse.data || !tokenResponse.data.linkToken) {
        throw new Error('Kein Link-Token erhalten');
      }
      const linkToken = tokenResponse.data.linkToken;
      // Baue den state-Parameter als JSON-String, der den Modus 'link', das Token und rememberMe enthält
      const stateData = encodeURIComponent(JSON.stringify({ mode: 'link', token: linkToken, rememberMe: true }));
      // Weiterleitung zum Google-Auth-Endpoint im Link-Modus
      window.location.href = `/api/auth/google?mode=link&state=${stateData}`;
    } catch (error) {
      console.error('Fehler beim Generieren des Link-Tokens:', error);
      toast.error(t({ id: 'social.linkError', message: 'Fehler beim Starten des Google-Link-Vorgangs.' }));
    }
  };

  return (
    <div className="social-google-link">
      {googleLinked ? (
        <>
          <p>
            <Trans id="social.alreadyLinked">Dein Konto ist aktuell mit Google verknüpft.</Trans>
          </p>
          <StyledButton
            onClick={handleUnlinkGoogle}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '0 auto',
            }}
          >
            <FaGoogle style={{ marginRight: '8px' }} />
            <Trans id="social.unlinkGoogleButton">Google-Verknüpfung aufheben</Trans>
          </StyledButton>
        </>
      ) : (
        <>
          <p>
            <Trans id="social.notLinked">Keine Google-Verknüpfung vorhanden.</Trans>
          </p>
          <StyledButton
            onClick={handleLinkGoogle}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '0 auto',
            }}
          >
            <FaGoogle style={{ marginRight: '8px' }} />
            <Trans id="social.linkGoogleButton">Mit Google verknüpfen</Trans>
          </StyledButton>
        </>
      )}
    </div>
  );
}

export default SocialGoogleLink;
