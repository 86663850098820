import React, { useEffect, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';

import { formatPrice } from '../../utils/formatPrice';
import { formatLargeValue } from '../../utils/formatLargeValue';
import { formatChangePercentage } from '../../utils/formatChangePercentage';
import { t } from '@lingui/macro';
import 'react-loading-skeleton/dist/skeleton.css';

/**
 * MiniSparkline:
 * Zeichnet einen 7-Tage-Sparkline-Chart, analog zu CoinRow.js.
 */
function MiniSparkline({ data }) {
  if (!data || data.length === 0) return <div>N/A</div>;
  const width = 115;
  const height = 22;
  const baselineValue = data[0];
  const maxPrice = Math.max(...data);
  const minPrice = Math.min(...data);
  const isFlat = maxPrice === minPrice;
  const allAbove = minPrice >= baselineValue;
  const allBelow = maxPrice <= baselineValue;

  const toPoint = (price, index, total) => {
    const x = (index / (total - 1)) * width;
    const y = isFlat ? height / 2 : height - ((price - minPrice) / (maxPrice - minPrice)) * height;
    return { x, y, price };
  };
  const points = data.map((p, i) => toPoint(p, i, data.length));
  const baselineY = isFlat ? height / 2 : height - ((baselineValue - minPrice) / (maxPrice - minPrice)) * height;

  function getIntersection(p1, p2, baseVal) {
    const diff = p2.price - p1.price;
    const tVal = (baseVal - p1.price) / diff;
    return { x: p1.x + tVal * (p2.x - p1.x), y: p1.y + tVal * (p2.y - p1.y) };
  }

  const segments = [];
  for (let i = 0; i < points.length - 1; i++) {
    const p1 = points[i], p2 = points[i + 1];
    const p1Above = p1.price >= baselineValue;
    const p2Above = p2.price >= baselineValue;
    if (p1Above === p2Above) {
      segments.push({ points: [p1, p2], color: p1Above ? 'var(--price-up-color)' : 'var(--price-down-color)' });
    } else {
      const inter = getIntersection(p1, p2, baselineValue);
      segments.push({ points: [p1, inter], color: p1Above ? 'var(--price-up-color)' : 'var(--price-down-color)' });
      segments.push({ points: [inter, p2], color: p2Above ? 'var(--price-up-color)' : 'var(--price-down-color)' });
    }
  }

  const toPolylineString = (arr) => arr.map((p) => `${p.x},${p.y}`).join(' ');

  let minIndex = 0;
  data.forEach((val, i) => { if (val < data[minIndex]) minIndex = i; });
  let maxIndex = 0;
  data.forEach((val, i) => { if (val > data[maxIndex]) maxIndex = i; });
  const minPoint = points[minIndex];
  const maxPoint = points[maxIndex];
  const minPerc = isFlat ? 0 : ((minPoint.price - baselineValue) / baselineValue) * 100;
  const maxPerc = isFlat ? 0 : ((maxPoint.price - baselineValue) / baselineValue) * 100;
  const offset = 3;
  const minColor = minPerc >= 0 ? 'var(--price-up-color)' : 'var(--price-down-color)';
  const maxColor = maxPerc >= 0 ? 'var(--price-up-color)' : 'var(--price-down-color)';
  const showMinLabel = !isFlat && !(minPrice >= baselineValue) && !(maxPrice <= baselineValue);
  const showMaxLabel = !isFlat && !(maxPrice <= baselineValue) && !(minPrice >= baselineValue);

  function clampLabelX(x) {
    if (x < 7) return { x: 7, anchor: 'start' };
    if (x > width - 7) return { x: width - 7, anchor: 'end' };
    return { x, anchor: 'middle' };
  }
  function formatPerc(val) {
    const sign = val >= 0 ? '+' : '';
    return `${sign}${val.toFixed(1)}%`;
  }

  return (
    <svg width={width} height={height} style={{ background: 'transparent', overflow: 'visible' }}>
      {/* Baseline */}
      <line x1={0} y1={baselineY} x2={width} y2={baselineY} stroke="#888" strokeWidth={1} strokeDasharray="2,2" />

      {/* Gefüllte Bereiche zwischen Linie und Baseline */}
      {segments.map((seg, idx) => (
        <polygon
          key={`fill-${idx}`}
          points={`
            ${seg.points[0].x},${seg.points[0].y} 
            ${seg.points[1].x},${seg.points[1].y} 
            ${seg.points[1].x},${baselineY} 
            ${seg.points[0].x},${baselineY}
          `}
          fill={seg.color}
          fillOpacity={0.05}  // Hier kannst du den Wert anpassen (z.B. 0.1 für mehr Transparenz)
          stroke="none"
        />
      ))}

      {/* Linien (Sparkline) */}
      {segments.map((seg, idx) => (
        <polyline key={idx} fill="none" stroke={seg.color} strokeWidth={1} points={toPolylineString(seg.points)} />
      ))}

      {/* Label-Anzeige */}
      {minPrice >= baselineValue && !isFlat && (() => {
        const { x, anchor } = clampLabelX(maxPoint.x);
        return (
          <text x={x} y={maxPoint.y - offset} fontSize="8" textAnchor={anchor} fill={maxColor}>
            {formatPerc(maxPerc)}
          </text>
        );
      })()}
      {maxPrice <= baselineValue && !isFlat && (() => {
        const { x, anchor } = clampLabelX(minPoint.x);
        return (
          <text x={x} y={minPoint.y + offset + 6} fontSize="8" textAnchor={anchor} fill={minColor}>
            {formatPerc(minPerc)}
          </text>
        );
      })()}
      {showMinLabel && (() => {
        const { x, anchor } = clampLabelX(minPoint.x);
        return (
          <text x={x} y={minPoint.y + offset + 6} fontSize="8" textAnchor={anchor} fill={minColor}>
            {formatPerc(minPerc)}
          </text>
        );
      })()}
      {showMaxLabel && (() => {
        const { x, anchor } = clampLabelX(maxPoint.x);
        return (
          <text x={x} y={maxPoint.y - offset} fontSize="8" textAnchor={anchor} fill={maxColor}>
            {formatPerc(maxPerc)}
          </text>
        );
      })()}
    </svg>
  );
}


/**
 * FavoriteCoinRow:
 * Zeigt 7 Spalten:
 * 1) Rank
 * 2) Name (Logo + Coinname); hierbei wird KEIN data-label gesetzt, damit im mobilen Layout kein Label vor dem Namen erscheint.
 * 3) Preis
 * 4) 24h%
 * 5) Marktkapitalisierung
 * 6) Volumen
 * 7) Sparkline
 */
const FavoriteCoinRow = React.memo(({ coin, vsCurrency = 'usd', loading }) => {
  const prevCoinRef = useRef(null);
  const prevCurrencyRef = useRef(vsCurrency);

  const [highlightPrice, setHighlightPrice] = useState(null);
  const [highlightMcap, setHighlightMcap] = useState(null);
  const [highlightVol, setHighlightVol] = useState(null);

  useEffect(() => {
    if (loading) return;
    if (!prevCoinRef.current) {
      prevCoinRef.current = coin;
      return;
    }
    const prev = prevCoinRef.current;
    const currencyChanged = prevCurrencyRef.current !== vsCurrency;
    if (currencyChanged) {
      prevCoinRef.current = coin;
      prevCurrencyRef.current = vsCurrency;
      return;
    }
    function check(field, setFn) {
      if (prev[field] !== coin[field]) {
        const dir = coin[field] > prev[field] ? 'up' : 'down';
        setFn(dir);
        setTimeout(() => setFn(null), 1000);
      }
    }
    check('current_price', setHighlightPrice);
    check('market_cap', setHighlightMcap);
    check('total_volume', setHighlightVol);
    prevCoinRef.current = coin;
    prevCurrencyRef.current = vsCurrency;
  }, [coin, vsCurrency, loading]);

  if (loading || !coin.id) {
    return (
      <>
        <td data-label={t({ id: 'label.rankMobile', message: '🏅 Rank' })}><Skeleton width={30} /></td>
        <td>
          <Skeleton width={100} />
        </td>
        <td data-label={t({ id: 'label.priceMobile', message: 'Preis' })}><Skeleton width={50} /></td>
        <td data-label={t({ id: 'label.24hChangeMobile', message: '24h %' })}><Skeleton width={40} /></td>
        <td data-label={t({ id: 'label.marketCapMobile', message: 'Marktkap.' })}><Skeleton width={60} /></td>
        <td data-label={t({ id: 'label.24hVolumeMobile', message: 'Volumen' })}><Skeleton width={60} /></td>
        <td data-label={t({ id: 'label.7dSparklineMobile', message: '7d' })}>
          <Skeleton width={60} height={22} />
        </td>
      </>
    );
  }

  const highlightPriceClass = highlightPrice ? `highlightText-${highlightPrice}` : '';
  const highlightMcapClass = highlightMcap ? `highlightText-${highlightMcap}` : '';
  const highlightVolClass = highlightVol ? `highlightText-${highlightVol}` : '';

  const priceChangeStr = formatChangePercentage(coin.price_change_percentage_24h);

  return (
    <>
      {/* Spalte 1: Rank */}
      <td data-label={t({ id: 'label.rankMobile', message: '🏅 Rank' })}>
        {coin.market_cap_rank || '-'}
      </td>
      {/* Spalte 2: Name (Logo, Name, Symbol) */}
      <td className="coin-info-cell">
        {coin.image && (
          <img src={coin.image} alt={coin.name} className="coin-logo" />
        )}
        <span className="coin-name-favs">{coin.name}</span>
        <span className="coin-symbol">({coin.symbol?.toUpperCase()})</span>
      </td>
      {/* Spalte 3: Preis */}
      <td data-label={t({ id: 'label.priceMobile', message: 'Preis' })} className={highlightPriceClass}>
        {formatPrice(coin.current_price, vsCurrency)}
      </td>
      {/* Spalte 4: 24h % */}
      <td data-label={t({ id: 'label.24hChangeMobile', message: '24h %' })}>
        {priceChangeStr}
      </td>
      {/* Spalte 5: Marktkapitalisierung */}
      <td data-label={t({ id: 'label.marketCapMobile', message: 'Marktkap.' })} className={highlightMcapClass}>
        {formatLargeValue(coin.market_cap, vsCurrency)}
      </td>
      {/* Spalte 6: Volumen */}
      <td data-label={t({ id: 'label.24hVolumeMobile', message: 'Volumen' })} className={highlightVolClass}>
        {formatLargeValue(coin.total_volume, vsCurrency)}
      </td>
      {/* Spalte 7: Sparkline */}
      <td data-label={t({ id: 'label.7dSparklineMobile', message: '7d' })}>
        <MiniSparkline data={coin.sparkline_in_7d?.price || []} />
      </td>
    </>
  );
});

export { MiniSparkline };
export default FavoriteCoinRow;
