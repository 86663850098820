// src/components/ExchangeRow.js

import React from 'react';
import { t } from '@lingui/macro';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const ExchangeRow = React.memo(({ exchange, onClick, loading }) => {
  // Loading-Skeleton
  if (loading) {
    return (
      <tr>
        <td><Skeleton width={20} /></td>
        <td className="exchange-info-cell">
          <Skeleton width={100} />
        </td>
        <td><Skeleton width={50} /></td>
        <td><Skeleton width={50} /></td>
        <td><Skeleton width={30} /></td>
        <td><Skeleton width={80} /></td>
        <td><Skeleton width={80} /></td>
      </tr>
    );
  }

  // Für Rank: Falls exchange.trust_score_rank vorhanden, nutzen wir diesen, sonst "N/A"
  const rank = exchange.trust_score_rank || 'N/A';

  return (
    <tr onClick={() => onClick(exchange)}>
      <td data-label={t({ id: "label.rankMobile", message: "🏅 Rank" })}>
        {rank}
      </td>
      <td className="exchange-info-cell">
        <img src={exchange.image} alt={exchange.name} className="exchange-logo" />
        <span className="exchange-name">{exchange.name}</span>
      </td>
      <td data-label={t({ id: "label.yearMobile", message: "📅 Jahr" })}>
        {exchange.year_established || 'N/A'}
      </td>
      <td data-label={t({ id: "label.countryMobile", message: "🌍 Land" })}>
        {exchange.country || 'N/A'}
      </td>
      <td data-label={t({ id: "label.trustScoreMobile", message: "⭐ Trust Score" })}>
        {exchange.trust_score || 'N/A'}
      </td>
      <td data-label={t({ id: "label.volumeMobile", message: "💹 24h Volumen (BTC)" })}>
        {exchange.trade_volume_24h_btc ? Number(exchange.trade_volume_24h_btc).toLocaleString() : 'N/A'}
      </td>
      <td data-label={t({ id: "label.volumeNormalizedMobile", message: "💹 24h Volumen Normalized (BTC)" })}>
        {exchange.trade_volume_24h_btc_normalized ? Number(exchange.trade_volume_24h_btc_normalized).toLocaleString() : 'N/A'}
      </td>
    </tr>
  );
});

export default ExchangeRow;
