import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; 
import '../styles/TradesPage.css';

const TradesPage = () => {
  const navigate = useNavigate();

  const [trades, setTrades] = useState([]);
  const [btcPrice, setBtcPrice] = useState(null);
  const [btcPriceChange, setBtcPriceChange] = useState('');
  const [prevBtcPrice, setPrevBtcPrice] = useState(null);

  useEffect(() => {
    const btcSocket = new WebSocket('wss://stream.binance.com:9443/ws/btcusdt@trade');

    btcSocket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      const price = parseFloat(data.p);
      const quantity = parseFloat(data.q);
      const isMaker = data.m;
      const tradeTime = new Date(data.T);

      // Preisänderung hervorheben
      if (prevBtcPrice !== null) {
        if (price > prevBtcPrice) {
          setBtcPriceChange('price-up');
        } else if (price < prevBtcPrice) {
          setBtcPriceChange('price-down');
        }
        setTimeout(() => setBtcPriceChange(''), 1000);
      }
      setPrevBtcPrice(price);
      setBtcPrice(price);

      // Neue Trade-Daten hinzufügen
      setTrades((prevTrades) => [
        {
          price,
          quantity,
          isMaker: isMaker ? 'Verkauf' : 'Kauf',
          time: tradeTime.toLocaleTimeString('de-DE', {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
          }),
        },
        ...prevTrades.slice(0, 19),
      ]);
    };

    return () => {
      btcSocket.close();
    };
  }, [prevBtcPrice]);

  return (
    <div className="trades-page">
      {/* Back-Button entfernt */}
      <h1>Letzte Trades</h1>
      <div className={`current-price ${btcPriceChange}`}>
        {btcPrice !== null ? (
          <div>
            Aktueller BTC-Preis: 
            <span>
              ${btcPrice.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
            </span>
          </div>
        ) : (
          <div>Lädt...</div>
        )}
      </div>
      <div className="trades-table-wrapper">
        <table className="trades-table">
          <thead>
            <tr>
              <th>Preis ($)</th>
              <th>Menge (BTC)</th>
              <th>Kauf/Verkauf</th>
              <th>Zeit</th>
            </tr>
          </thead>
          <tbody>
            {trades.map((trade, index) => (
              <tr key={index} className={trade.isMaker === 'Kauf' ? 'buy-row' : 'sell-row'}>
                <td>{trade.price.toFixed(2)}</td>
                <td>{trade.quantity.toFixed(6)}</td>
                <td>{trade.isMaker}</td>
                <td>{trade.time}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TradesPage;
