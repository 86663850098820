// CryptoBotStrategyManager.js

// ====== Kaufstrategien ======
export const buyDipStrategy = ({ chartData, price, buyDipPercentage, executeBuy }) => {
    if (!chartData || chartData.length === 0) return;
  
    const maxPrice = Math.max(...chartData);
    if (price <= maxPrice * (1 - buyDipPercentage / 100)) {
      executeBuy(price);
    }
  };
  
  export const smaCrossoverStrategy = ({
    chartData,
    smaData,
    price,
    smaPeriod,
    executeBuy,
  }) => {
    if (smaData.length >= 2 && chartData.length >= 2) {
      const smaValue = smaData[smaData.length - 1];
      const prevSmaValue = smaData[smaData.length - 2];
      const prevPrice = chartData[chartData.length - 2];
  
      if (prevPrice < prevSmaValue && price > smaValue) {
        executeBuy(price);
      }
    }
  };
  
  // ====== Alte Verkaufsstrategien (Haupt) ======
  export const fixedProfitStrategy = ({
    price,
    activeTrade,
    sellProfitPercentage,
    executeSellAll,
  }) => {
    if (!activeTrade) return;
    const { price: buyPrice } = activeTrade;
  
    // +X% Gewinn => verkaufen
    if (price >= buyPrice * (1 + sellProfitPercentage / 100)) {
      executeSellAll(price, 'Bot (fixedProfit)');
    }
  };
  
  export const stopLossStrategy = ({
    price,
    activeTrade,
    stopLossPercentage,
    executeSellAll,
  }) => {
    if (!activeTrade) return;
    const { price: buyPrice } = activeTrade;
  
    // -X% => verkaufen
    if (price <= buyPrice * (1 - stopLossPercentage / 100)) {
      executeSellAll(price, 'Bot (stopLoss)');
    }
  };
  
  export const combinedStopLossAndProfitStrategy = ({
    price,
    activeTrade,
    stopLossPercentage,
    takeProfitPercentage,
    executeSellAll,
  }) => {
    if (!activeTrade) return;
    const { price: buyPrice } = activeTrade;
  
    // Take Profit
    if (price >= buyPrice * (1 + takeProfitPercentage / 100)) {
      executeSellAll(price, 'Bot (combinedTakeProfit)');
      return;
    }
  
    // Stop Loss
    if (price <= buyPrice * (1 - stopLossPercentage / 100)) {
      executeSellAll(price, 'Bot (combinedStopLoss)');
    }
  };
  
  // ====== Teilverkäufe (Partial Take Profit) ======
  export const partialTakeProfitStrategy = ({
    price,
    activeTrade,
    partialTpLevels,
    partialTpFractions,
    executeSellFraction,
  }) => {
    if (!activeTrade) return;
    const { price: buyPrice, partialSalesExecuted = [] } = activeTrade;
  
    // Gehe alle Level durch
    for (let i = 0; i < partialTpLevels.length; i++) {
      const level = partialTpLevels[i];
      const fraction = partialTpFractions[i];
      // Schon ausgeführt?
      if (partialSalesExecuted.includes(i)) continue;
  
      if (price >= buyPrice * (1 + level / 100)) {
        // Teilverkauf
        executeSellFraction(price, fraction);
  
        // Markiere diesen Level als ausgeführt
        partialSalesExecuted.push(i);
      }
    }
  };
  
  // ====== Trailing Stop ======
  export const trailingStopStrategy = ({
    price,
    activeTrade,
    trailingStopPercentage,
    executeSellAll,
    onUpdateMaxPrice,
  }) => {
    if (!activeTrade) return;
    let { maxPriceSinceEntry } = activeTrade;
  
    // Neues Hoch?
    if (price > maxPriceSinceEntry) {
      maxPriceSinceEntry = price;
      if (onUpdateMaxPrice) onUpdateMaxPrice(price);
    }
  
    // Trailing Stop Level
    const stopLevel = maxPriceSinceEntry * (1 - trailingStopPercentage / 100);
    if (price <= stopLevel) {
      executeSellAll(price, 'Bot (trailingStop)');
    }
  };
  
  // ====== Positionssizing nach Risikomanagement ======
  export const computePositionSize = ({
    balance,
    riskPercentage,
    buyPrice,
    stopLossPrice,
  }) => {
    const capitalToRisk = (balance * riskPercentage) / 100;
    const riskPerCoin = buyPrice - stopLossPrice;
    if (riskPerCoin <= 0) {
      return { positionSizeInUSD: 0 };
    }
  
    const maxCoins = capitalToRisk / riskPerCoin;
    const positionSizeInUSD = maxCoins * buyPrice;
    return { positionSizeInUSD };
  };
  