import React from 'react';
import { Trans } from '@lingui/macro';
import { FaExternalLinkAlt } from 'react-icons/fa';
import InfoTooltip from './InfoTooltip';

const UnderlinedStrong = ({ children }) => (
  <strong style={{ textDecoration: 'underline' }}>{children}</strong>
);

const Links = ({ homepage, blockchainSite, subreddit, forum }) => {
  return (
    <div className="coin-links" style={{ position: 'relative' }}>
      <h3>
        <Trans id="label.links">Links</Trans>
      </h3>
      {/* Tooltip in die rechte obere Ecke positionieren – analog zu MarketData */}
      <div className="tooltip-wrapper">
        <InfoTooltip iconSize={18}>
          <Trans
            id="tooltips.linksExplanation"
            components={[<UnderlinedStrong key="strong" />]}
          >
            {"<1>Wichtige Links</1>: In diesem Bereich findest du nützliche Verweise, die dir weiterführende Informationen zur Kryptowährung liefern – von der offiziellen Homepage über den Blockchain Explorer bis hin zu Community-Plattformen wie Reddit und Foren."}
          </Trans>
        </InfoTooltip>
      </div>

      <div>
        <p>
          <strong>
            <Trans id="label.homepage">Homepage:</Trans>
          </strong>{' '}
          {homepage !== '#' ? (
            <a href={homepage} target="_blank" rel="noopener noreferrer">
              <span className="short-link">{homepage}</span>
              <FaExternalLinkAlt className="link-icon" />
            </a>
          ) : (
            <Trans id="label.notAvailable">Nicht verfügbar</Trans>
          )}
        </p>
        <p>
          <strong>
            <Trans id="label.blockchainSite">Blockchain Explorer:</Trans>
          </strong>{' '}
          {blockchainSite !== '#' ? (
            <a href={blockchainSite} target="_blank" rel="noopener noreferrer">
              <span className="short-link">{blockchainSite}</span>
              <FaExternalLinkAlt className="link-icon" />
            </a>
          ) : (
            <Trans id="label.notAvailable">Nicht verfügbar</Trans>
          )}
        </p>
        <p>
          <strong>
            <Trans id="label.reddit">Reddit:</Trans>
          </strong>{' '}
          {subreddit !== '#' ? (
            <a href={subreddit} target="_blank" rel="noopener noreferrer">
              <span className="short-link">{subreddit}</span>
              <FaExternalLinkAlt className="link-icon" />
            </a>
          ) : (
            <Trans id="label.notAvailable">Nicht verfügbar</Trans>
          )}
        </p>
        <p>
          <strong>
            <Trans id="label.forum">Forum:</Trans>
          </strong>{' '}
          {forum !== '#' ? (
            <a href={forum} target="_blank" rel="noopener noreferrer">
              <span className="short-link">{forum}</span>
              <FaExternalLinkAlt className="link-icon" />
            </a>
          ) : (
            <Trans id="label.notAvailable">Nicht verfügbar</Trans>
          )}
        </p>
      </div>
    </div>
  );
};

export default Links;
