// src/components/ForgotPassword.js

import React, { useState, useEffect } from 'react';
import '../styles/ForgotPassword.css';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { StyledButton } from '../utils/StyledButton';
import { StyledInput } from '../utils/StyledInput';

import api from '../services/api'; // <-- Neu: unser zentraler Axios-Client

function translateMessageKey(messageKey) {
  switch (messageKey) {
    case 'error.internalServerError':
      return t({ id: 'error.internalServerError', message: 'Interner Serverfehler' });
    case 'error.invalidEmail':
      return t({ id: 'error.invalidEmail', message: 'Bitte eine gültige E-Mail-Adresse eingeben.' });
    case 'error.emailRequired':
      return t({ id: 'error.emailRequired', message: 'Bitte geben Sie eine E-Mail-Adresse ein.' });
    case 'error.dbQueryFailed':
      return t({ id: 'error.dbQueryFailed', message: 'Fehler bei der Datenbankabfrage.' });
    case 'error.sendingResetEmail':
      return t({ id: 'error.sendingResetEmail', message: 'Fehler beim Senden der E-Mail.' });
    case 'info.resetLinkSent':
      return t({
        id: 'info.resetLinkSent',
        message: 'Ein Link zum Zurücksetzen des Passworts wurde gesendet.',
      });
    case 'info.emailIfExistsResetLinkSent':
      return t({
        id: 'info.emailIfExistsResetLinkSent',
        message: 'Wenn die E-Mail-Adresse existiert, wurde ein Link zum Zurücksetzen des Passworts gesendet.',
      });
    default:
      return t({ id: 'error.unknown', message: 'Unbekannter Fehler.' });
  }
}

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [timer, setTimer] = useState(0); // Gleicher Timer wie in Login.js

  useEffect(() => {
    const checkTimer = () => {
      const lastSent = localStorage.getItem('forgotPasswordLastSent');
      if (lastSent) {
        const elapsed = Math.floor((Date.now() - parseInt(lastSent, 10)) / 1000);
        if (elapsed < 60) {
          setTimer(60 - elapsed);
        } else {
          localStorage.removeItem('forgotPasswordLastSent');
        }
      }
    };

    checkTimer();

    let interval = null;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer <= 1) {
            clearInterval(interval);
            localStorage.removeItem('forgotPasswordLastSent');
            return 0;
          }
          return prevTimer - 1;
        });
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [timer]);

  const handleForgotPassword = async (e) => {
    e.preventDefault();

    if (!email) {
      toast.error(translateMessageKey('error.emailRequired'));
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      toast.error(translateMessageKey('error.invalidEmail'));
      return;
    }

    if (timer > 0) {
      toast.error(
        t(
          {
            id: 'message.waitBeforeRetry',
            message: 'Bitte warte {cooldown} Sekunden, bevor du es erneut versuchst.',
          },
          { cooldown: timer }
        )
      );
      return;
    }

    setIsLoading(true);
    try {
      // POST /api/forgot-password
      const response = await api.post(
        '/forgot-password',
        { email },
        {
          headers: {
            'Content-Type': 'application/json',
            'Accept-Language': i18n.locale,
          },
        }
      );

      // Axios wirft Fehler bei Status ≥ 400
      const data = response.data;
      toast.success(translateMessageKey(data.messageKey));
      setEmail('');
      setTimer(60);
      localStorage.setItem('forgotPasswordLastSent', Date.now().toString());
    } catch (err) {
      // Falls 4xx/5xx: err.response vorhanden
      if (err.response) {
        const data = err.response.data;
        if (data && data.messageKey) {
          toast.error(translateMessageKey(data.messageKey));
        } else {
          toast.error(translateMessageKey('error.internalServerError'));
        }
      } else {
        // Netzwerkfehler oder anderes
        toast.error(translateMessageKey('error.internalServerError'));
      }
    } finally {
      setIsLoading(false);
    }
  };

  // Button-Text abhängig von isLoading und Timer
  const buttonText = isLoading
    ? t({ id: 'button.sending', message: 'Senden...' })
    : timer > 0
    ? t(
        { id: 'button.resendIn', message: `Erneut senden in ${timer}s` },
        { timer }
      )
    : t({ id: 'button.resetPassword', message: 'Passwort zurücksetzen' });

  return (
    <div className="forgot-password-container">
      <h2>{t({ id: 'title.forgotPassword', message: 'Passwort vergessen' })}</h2>
      <form onSubmit={handleForgotPassword}>
        <StyledInput
          type="email"
          placeholder={t({ id: 'placeholder.email', message: 'E-Mail' })}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <StyledButton type="submit" variant="primary" disabled={isLoading || timer > 0}>
          {buttonText}
        </StyledButton>
      </form>
      <div className="links">
        <p>
          <Link to="/login">
            {t({ id: 'link.backToLogin', message: 'Zurück zum Login' })}
          </Link>
        </p>
      </div>
    </div>
  );
};

export default ForgotPassword;
