// src/hooks/useBinanceTrades.js
import { useState, useEffect, useRef } from 'react';

export default function useBinanceTrades(symbol) {
  const [trades, setTrades] = useState([]);
  const [error, setError] = useState(null);
  const [symbolInvalid, setSymbolInvalid] = useState(false);
  // Dieses Ref erlaubt uns, synchron zu prüfen, ob das Symbol ungültig ist.
  const symbolInvalidRef = useRef(false);

  const wsRef = useRef(null);
  const reconnectTimeoutRef = useRef(null);
  const reconnectAttemptRef = useRef(0);
  const tradeQueueRef = useRef([]);      // Queue für Trades
  const throttleTimeoutRef = useRef(null); // Timer für Throttling
  const messageReceivedRef = useRef(false); // Flag, ob Trade-Daten empfangen wurden
  const noMessageTimerRef = useRef(null); // Timer, um fehlende Nachrichten zu erkennen

  // Diese Funktion verarbeitet die gesammelten Trades in der Queue.
  const processQueue = () => {
    if (tradeQueueRef.current.length > 0) {
      // Nur valide Trades übernehmen (kein undefined oder null)
      const validTrades = tradeQueueRef.current.filter(trade => trade);
      setTrades(prev => [...validTrades, ...prev].slice(0, 20));
      tradeQueueRef.current = []; // Queue leeren
    }
    throttleTimeoutRef.current = null;
  };

  useEffect(() => {
    // Bei Symbolwechsel: Alte Trades und Fehler zurücksetzen,
    // und das ungültig-Flag auch im State zurücksetzen.
    setTrades([]);
    setError(null);
    setSymbolInvalid(false);
    symbolInvalidRef.current = false;
    messageReceivedRef.current = false;

    // Falls kein Symbol vorliegt oder das Symbol bereits als ungültig markiert ist,
    // wird hier nicht versucht, eine Verbindung aufzubauen.
    if (!symbol || symbolInvalidRef.current) return;

    let cancelled = false;

    const clearNoMessageTimer = () => {
      if (noMessageTimerRef.current) {
        clearTimeout(noMessageTimerRef.current);
        noMessageTimerRef.current = null;
      }
    };

    // Vorherige WS-Verbindung und Timer sauber schließen
    if (wsRef.current) {
      wsRef.current.close();
      wsRef.current = null;
    }
    if (reconnectTimeoutRef.current) {
      clearTimeout(reconnectTimeoutRef.current);
    }
    if (throttleTimeoutRef.current) {
      clearTimeout(throttleTimeoutRef.current);
    }

    const connectWS = () => {
      // Sollte das Symbol inzwischen als ungültig erkannt worden sein, keine Verbindung mehr aufbauen
      if (symbolInvalidRef.current) return;

      const ws = new WebSocket('wss://cryptoscan.digital');
      wsRef.current = ws;
      messageReceivedRef.current = false;
      clearNoMessageTimer();

      ws.onopen = () => {
        console.log(`Trades WS connected for ${symbol}`);
        setError(null);
        reconnectAttemptRef.current = 0;
        ws.send(JSON.stringify({ type: 'subscribeTrades', symbol }));

        // Timer starten: Wenn innerhalb von 5000ms keine Nachricht empfangen wird, WS schließen.
        noMessageTimerRef.current = setTimeout(() => {
          if (!messageReceivedRef.current) {
            console.warn(`Keine Trades empfangen für ${symbol} nach 60s – erneuter Verbindungsaufbau.`);
            ws.close();
          }
        }, 60000);
      };

      ws.onmessage = (event) => {
        try {
          const msg = JSON.parse(event.data);
          if (msg.error) {
            setError(msg.error);
            // Ungültiges Symbol sofort kennzeichnen
            if (msg.error.includes('does not exist')) {
              setSymbolInvalid(true);
              symbolInvalidRef.current = true;
              ws.close();
            }
            return;
          }
          if (msg.data && msg.data.error) {
            setError(msg.data.error);
            if (msg.data.error.includes('does not exist')) {
              setSymbolInvalid(true);
              symbolInvalidRef.current = true;
              ws.close();
            }
            return;
          }
          // Akzeptiere sowohl symbol als auch symbolusdt
          if (
            msg.type === 'tradeUpdate' &&
            msg.symbol &&
            (
              msg.symbol.toLowerCase() === symbol.toLowerCase() ||
              msg.symbol.toLowerCase() === symbol.toLowerCase() + 'usdt'
            )
          ) {
            messageReceivedRef.current = true;
            clearNoMessageTimer();
            if (msg.data) {
              tradeQueueRef.current.push(msg.data);
            }
            if (!throttleTimeoutRef.current) {
              throttleTimeoutRef.current = setTimeout(processQueue, 500);
            }
          }
        } catch (err) {
          console.error('Error parsing trades message:', err);
        }
      };

      ws.onerror = (err) => {
        console.error(`Trades WS error for ${symbol}:`, err);
        setError('WebSocket error');
      };

      ws.onclose = () => {
        console.log(`Trades WS disconnected for ${symbol}`);
        clearNoMessageTimer();
        // Nur reconnecten, wenn nicht abgebrochen und das Symbol nicht als ungültig markiert wurde.
        if (!cancelled && !symbolInvalidRef.current) {
          let attempt = reconnectAttemptRef.current;
          let delay = attempt < 4 ? (attempt + 1) * 2000 : 12000;
          reconnectAttemptRef.current++;
          reconnectTimeoutRef.current = setTimeout(() => {
            console.log(`Reconnecting Trades WS for ${symbol} (Attempt ${reconnectAttemptRef.current})...`);
            connectWS();
          }, delay);
        }
      };
    };

    connectWS();

    // Bei Sichtbarkeitswechsel: Alte Trades löschen und WS neu starten.
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        console.log('Seite ist wieder sichtbar – alte Trades werden gelöscht.');
        setTrades([]);
        if (wsRef.current) {
          wsRef.current.close();
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      cancelled = true;
      clearNoMessageTimer();
      if (reconnectTimeoutRef.current) {
        clearTimeout(reconnectTimeoutRef.current);
      }
      if (throttleTimeoutRef.current) {
        clearTimeout(throttleTimeoutRef.current);
      }
      if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
        wsRef.current.send(JSON.stringify({ type: 'unsubscribe', symbol }));
      }
      if (wsRef.current) {
        wsRef.current.close();
      }
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      console.log(`Trades WS disconnected for ${symbol}`);
    };
  }, [symbol]);

  return { trades, error };
}
