// src/components/CoinsSection.js

import React, { useState, useEffect } from 'react';
import { OverlayTrigger, Popover, Card, Row, Col, Modal, Button } from 'react-bootstrap';
import { FaBitcoin, FaEthereum } from 'react-icons/fa';
import { SiDogecoin, SiBinance, SiLitecoin, SiCardano, SiPolkadot } from 'react-icons/si';
import BarChart from './BarChart';
import api from '../services/api'; // <-- Neu: Unser zentraler Axios-Client
import '../styles/CoinsSection.css';

const CoinsSection = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [barData, setBarData] = useState(null);

  // Popovers für die Kategorien
  const getPopover = (category) => {
    switch (category) {
      case 'Top-Coins':
        return (
          <Popover id="popover-topcoins">
            <Popover.Header as="h3">Top-Coins</Popover.Header>
            <Popover.Body>
              Top-Coins sind die führenden Kryptowährungen basierend auf Marktkapitalisierung.
              Sie sind etabliert, haben eine große Nutzerbasis und eine hohe Liquidität.
              Beispiele sind Bitcoin, Ethereum und Binance Coin.
            </Popover.Body>
          </Popover>
        );
      case 'Alt-Coins':
        return (
          <Popover id="popover-altcoins">
            <Popover.Header as="h3">Alt-Coins</Popover.Header>
            <Popover.Body>
              Alt-Coins sind alternative Kryptowährungen, die nach Bitcoin entwickelt wurden.
              Sie bieten oft spezifische Anwendungsfälle und technologische Verbesserungen.
              Beispiele sind Litecoin, Cardano und Polkadot.
            </Popover.Body>
          </Popover>
        );
      case 'Meme-Coins':
        return (
          <Popover id="popover-memecoins">
            <Popover.Header as="h3">Meme-Coins</Popover.Header>
            <Popover.Body>
              Meme-Coins sind Kryptowährungen, die oft ohne ernsthaften Zweck oder Fundament
              erstellt werden, inspiriert von populären Internet-Memes.
              Beispiele sind Dogecoin und Shiba Inu.
            </Popover.Body>
          </Popover>
        );
      default:
        return null;
    }
  };

  // Modal-Inhalte basierend auf der ausgewählten Kategorie
  const getModalContent = () => {
    switch (selectedCategory) {
      case 'Top-Coins':
        return (
          <>
            <Modal.Header closeButton>
              <Modal.Title>Top-Coins</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                Top-Coins sind die führenden Kryptowährungen basierend auf Marktkapitalisierung.
                Sie sind etabliert, haben eine große Nutzerbasis und eine hohe Liquidität. 
                Beispiele sind Bitcoin, Ethereum und Binance Coin.
              </p>
              <ul>
                <li>
                  <FaBitcoin size={25} style={{ color: '#f7931a' }} />
                  <strong> Bitcoin (BTC):</strong> Die erste und bekannteste Kryptowährung.
                </li>
                <li>
                  <FaEthereum size={25} style={{ color: '#3c3c3d' }} />
                  <strong> Ethereum (ETH):</strong> Plattform für dezentrale Anwendungen.
                </li>
                <li>
                  <SiBinance size={25} style={{ color: '#F3BA2F' }} />
                  <strong> Binance Coin (BNB):</strong> Token der Binance-Börse.
                </li>
              </ul>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowModal(false)}>
                Schließen
              </Button>
            </Modal.Footer>
          </>
        );
      case 'Alt-Coins':
        return (
          <>
            <Modal.Header closeButton>
              <Modal.Title>Alt-Coins</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                Alt-Coins sind alternative Kryptowährungen, die nach Bitcoin entwickelt wurden.
                Sie bieten oft spezifische Anwendungsfälle und technologische Verbesserungen.
                Beispiele sind Litecoin, Cardano und Polkadot.
              </p>
              <ul>
                <li>
                  <SiLitecoin size={25} style={{ color: '#b4b4b4' }} />
                  <strong> Litecoin (LTC):</strong> Schnellere Transaktionszeiten als Bitcoin.
                </li>
                <li>
                  <SiCardano size={25} style={{ color: '#0033ad' }} />
                  <strong> Cardano (ADA):</strong> Fokus auf Sicherheit und Skalierbarkeit.
                </li>
                <li>
                  <SiPolkadot size={25} style={{ color: '#e6007a' }} />
                  <strong> Polkadot (DOT):</strong> Vernetzung verschiedener Blockchains.
                </li>
              </ul>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowModal(false)}>
                Schließen
              </Button>
            </Modal.Footer>
          </>
        );
      case 'Meme-Coins':
        return (
          <>
            <Modal.Header closeButton>
              <Modal.Title>Meme-Coins</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                Meme-Coins sind Kryptowährungen, die oft ohne ernsthaften Zweck oder Fundament
                erstellt werden, inspiriert von populären Internet-Memes.
                Beispiele sind Dogecoin und Shiba Inu.
              </p>
              <ul>
                <li>
                  <strong>Dogecoin (DOGE):</strong> Ursprünglich als Scherz gestartet,
                  hat sich eine große Community aufgebaut.
                </li>
                <li>
                  <strong>Shiba Inu (SHIB):</strong> Weiterentwicklung des Dogecoin-Konzepts
                  mit zusätzlichen Features.
                </li>
              </ul>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowModal(false)}>
                Schließen
              </Button>
            </Modal.Footer>
          </>
        );
      default:
        return null;
    }
  };

  // (A) Daten von der Backend-/Proxy-API holen (statt direkte CoinGecko-API)
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Vorher: axios.get('/global')
        // Jetzt: api.get('/global')
        const { data } = await api.get('/global');
        const globalData = data.data;

        const topCoinsMarketCap = 
          globalData.market_cap_percentage.btc +
          globalData.market_cap_percentage.eth +
          globalData.market_cap_percentage.bnb; // Top-Coins in %
        const memeCoinsMarketCap = globalData.market_cap_percentage.doge; // Meme-Coins in %
        const altCoinsMarketCap = 100 - topCoinsMarketCap - memeCoinsMarketCap; // Rest -> Alt-Coins

        setBarData({
          labels: ['Top-Coins', 'Alt-Coins', 'Meme-Coins'],
          datasets: [
            {
              label: 'Marktanteil (%)',
              data: [
                parseFloat(topCoinsMarketCap.toFixed(2)),
                parseFloat(altCoinsMarketCap.toFixed(2)),
                parseFloat(memeCoinsMarketCap.toFixed(2)),
              ],
              backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
            },
          ],
        });
      } catch (error) {
        console.error('Fehler beim Laden der Daten:', error);
        // Fallback-Daten, falls die API-Anfrage fehlschlägt
        setBarData({
          labels: ['Top-Coins', 'Alt-Coins', 'Meme-Coins'],
          datasets: [
            {
              label: 'Marktanteil (%)',
              data: [50, 35, 15],
              backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
            },
          ],
        });
      }
    };

    fetchData();
  }, []);

  // (B) Karte-Klick => Modal öffnen
  const handleCardClick = (category) => {
    setSelectedCategory(category);
    setShowModal(true);
  };

  // (C) Chart-Optionen
  const barOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
      title: {
        display: true,
        text: 'Verhältnis der Coins',
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (context) {
            return `${context.label}: ${context.parsed.y}%`;
          },
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        max: 100,
        ticks: {
          stepSize: 20,
        },
        title: {
          display: true,
          text: 'Prozentsatz (%)',
        },
      },
      x: {
        title: {
          display: true,
          text: 'Coin-Kategorien',
        },
      },
    },
  };

  return (
    <div className="coins-section">
      <h2>Verstehen Sie die verschiedenen Coins</h2>
      <p>
        Kryptowährungen gibt es in vielen Formen und Kategorien. Hier sind die wichtigsten Arten 
        von Coins, die Sie kennen sollten:
      </p>

      <Row className="justify-content-center">
        {/* Top-Coins Karte */}
        <Col md={4} sm={6} className="mb-4 d-flex justify-content-center">
          <OverlayTrigger
            trigger={['hover', 'focus']}
            placement="top"
            overlay={getPopover('Top-Coins')}
          >
            <Card className="coin-card" onClick={() => handleCardClick('Top-Coins')}>
              <Card.Body className="d-flex flex-column align-items-center">
                <FaBitcoin size={50} color="#f7931a" />
                <Card.Title className="mt-3 card-title">Top-Coins</Card.Title>
                <Card.Text>
                  Führende Kryptowährungen basierend auf Marktkapitalisierung.
                </Card.Text>
              </Card.Body>
            </Card>
          </OverlayTrigger>
        </Col>

        {/* Alt-Coins Karte */}
        <Col md={4} sm={6} className="mb-4 d-flex justify-content-center">
          <OverlayTrigger
            trigger={['hover', 'focus']}
            placement="top"
            overlay={getPopover('Alt-Coins')}
          >
            <Card className="coin-card" onClick={() => handleCardClick('Alt-Coins')}>
              <Card.Body className="d-flex flex-column align-items-center">
                <FaEthereum size={50} color="#3c3c3d" />
                <Card.Title className="mt-3 card-title">Alt-Coins</Card.Title>
                <Card.Text>
                  Alternative Kryptowährungen mit spezifischen Anwendungsfällen.
                </Card.Text>
              </Card.Body>
            </Card>
          </OverlayTrigger>
        </Col>

        {/* Meme-Coins Karte */}
        <Col md={4} sm={6} className="mb-4 d-flex justify-content-center">
          <OverlayTrigger
            trigger={['hover', 'focus']}
            placement="top"
            overlay={getPopover('Meme-Coins')}
          >
            <Card className="coin-card" onClick={() => handleCardClick('Meme-Coins')}>
              <Card.Body className="d-flex flex-column align-items-center">
                <SiDogecoin size={50} color="#c2a633" />
                <Card.Title className="mt-3 card-title">Meme-Coins</Card.Title>
                <Card.Text>
                  Humorvolle Coins inspiriert von Internet-Memes.
                </Card.Text>
              </Card.Body>
            </Card>
          </OverlayTrigger>
        </Col>
      </Row>

      {/* Diagramm: Verhältnis der Coins */}
      <div className="coin-ratio-chart">
        <h3>Verhältnis der Coins</h3>
        {barData ? (
          <BarChart data={barData} options={barOptions} />
        ) : (
          <p>Daten werden geladen...</p>
        )}
      </div>

      {/* Erklärung der Wertigkeit */}
      <div className="coin-value-explanation">
        <h3>Wertigkeit der Coins</h3>
        <p>
          Die Wertigkeit von Coins hängt von verschiedenen Faktoren ab, einschließlich ihrer Technologie,
          Marktnachfrage, Akzeptanz und Anwendungsfälle. Top-Coins haben oft die höchste Stabilität
          und Liquidität, während Alt-Coins und Meme-Coins mehr Volatilität und spekulative
          Chancen bieten.
        </p>
      </div>

      {/* Modal für detaillierte Informationen */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        {getModalContent()}
      </Modal>
    </div>
  );
};

export default CoinsSection;
