// src/components/OfflineOverlay.js
import React, { useState, useEffect } from 'react';
import { Trans } from '@lingui/macro';

const OfflineOverlay = () => {
  const [isOffline, setIsOffline] = useState(!navigator.onLine);

  useEffect(() => {
    const handleOnline = () => setIsOffline(false);
    const handleOffline = () => setIsOffline(true);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  if (!isOffline) {
    return null;
  }

  return (
    <div style={styles.overlay}>
      <div style={styles.content}>
        <h1>
          <Trans id="offline.title">Keine Internetverbindung</Trans>
        </h1>
        <p>
          <Trans id="offline.message">Bitte überprüfe deine Internetverbindung.</Trans>
        </p>
      </div>
    </div>
  );
};

const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.85)',
    color: '#fff',
    zIndex: 9999,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    padding: '20px',
  },
  content: {
    maxWidth: '500px',
  },
};

export default OfflineOverlay;
