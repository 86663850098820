// src/components/widgets/WidgetEmbedModal.jsx
import React from 'react';
import { Trans, t } from '@lingui/macro';
import { toast } from 'react-toastify';

export default function WidgetEmbedModal({ code, codeType, onClose }) {
  const handleCopy = () => {
    navigator.clipboard.writeText(code).then(
      () => {
        toast.success(
          t({
            id: 'widget.dashboard.copiedEmbedCode',
            message: 'Embed-Code erfolgreich kopiert!',
          })
        );
      },
      (err) => {
        console.error(err);
        toast.error(
          t({
            id: 'widget.dashboard.copyFailed',
            message: 'Kopieren fehlgeschlagen!',
          })
        );
      }
    );
  };

  const renderInstructions = () => {
    if (codeType === 'iframe') {
      return (
        <>
          <p>
            <Trans id="widget.dashboard.modal.iframeInstructionIntro">
              Unten siehst du den Iframe-Code für dein Widget. Kopiere diesen Code und
              füge ihn in dein HTML-Dokument ein.
            </Trans>
          </p>
          <ul>
            <li>
              <Trans id="widget.dashboard.modal.iframeBulletWidthHeight">
                Passe <strong>width</strong> und <strong>height</strong> an dein Layout an.
              </Trans>
            </li>
            <li>
              <Trans id="widget.dashboard.modal.iframeBulletStyle">
                Du kannst über <strong>style</strong> das Aussehen verändern (keine Ränder, etc.).
              </Trans>
            </li>
            <li>
              <Trans id="widget.dashboard.modal.iframeBulletLangParam">
                Falls du das Widget auf Englisch anzeigen möchtest, ändere
                <code>&amp;lang=de</code> auf <code>&amp;lang=en</code> im <strong>src</strong>-Attribut.
              </Trans>
            </li>
          </ul>
        </>
      );
    } else {
      return (
        <>
          <p>
            <Trans id="widget.dashboard.modal.snippetInstructionIntro">
              Unten siehst du ein Script-Tag, das dynamisch dein Widget lädt.
            </Trans>
          </p>
          <ul>
            <li>
              <Trans id="widget.dashboard.modal.snippetBulletOne">
                Du kannst <code>&amp;lang=de</code> zu <code>&amp;lang=en</code> ändern,
                falls du das Widget auf Englisch möchtest.
              </Trans>
            </li>
            <li>
              <Trans id="widget.dashboard.modal.snippetBulletTwo">
                Füge das Script an passender Stelle in deinem HTML ein, z. B. im <strong>body</strong>.
              </Trans>
            </li>
          </ul>
        </>
      );
    }
  };

  return (
    <div style={overlayStyle}>
      <div style={modalStyle}>
        <h2 style={{ marginTop: 0 }}>
          {codeType === 'iframe' ? (
            <Trans id="widget.dashboard.modal.iframeTitle">Iframe-Einbindung</Trans>
          ) : (
            <Trans id="widget.dashboard.modal.snippetTitle">Snippet-Einbindung</Trans>
          )}
        </h2>

        {renderInstructions()}

        <textarea
          readOnly
          value={code}
          style={{
            width: '100%',
            height: '120px',
            margin: '10px 0',
            fontFamily: 'monospace',
            fontSize: '0.95rem',
          }}
        />

        <div style={{ display: 'flex', gap: '10px', justifyContent: 'flex-end' }}>
          <button onClick={handleCopy}>
            <Trans id="widget.dashboard.modal.button.copy">Code kopieren</Trans>
          </button>
          <button onClick={onClose}>
            <Trans id="widget.dashboard.modal.button.close">Schließen</Trans>
          </button>
        </div>
      </div>
    </div>
  );
}

const overlayStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0,0,0,0.5)',
  zIndex: 9999,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const modalStyle = {
  backgroundColor: 'var(--background-color2)',
  color: 'var(--text-color)',
  padding: '20px',
  borderRadius: '8px',
  width: '90%',
  maxWidth: '500px',
  boxShadow: '0 0 10px rgba(0,0,0,0.3)',
};
