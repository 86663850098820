// src/components/CoinDetails/ChartContainer.js

import React, { useState, useEffect } from 'react';
import { Trans } from '@lingui/macro';
import {
  FaExpand,
  FaChartLine,
  FaChartBar,
  FaDownload,
  FaPlusCircle,
  FaTimesCircle,
} from 'react-icons/fa';
import TradingViewAdvancedChart from '../../utils/TradingViewAdvancedChart';
import CoinChart from '../../utils/CoinChart';
import { StyledButton } from '../../utils/StyledButton';
import SingleSelectNoSearch from '../../utils/SingleSelectNoSearch';
import CompareSearch from './CompareSearch';
import { useWindowSize } from '../../utils/useWindowSize';
import InfoTooltip from './InfoTooltip';
import api from '../../services/api'; // <-- Unsere neue Axios-Instanz

const ChartContainer = ({
  coinId,
  vsCurrency,
  selectedChartTimeframe,
  setSelectedChartTimeframe,
  // availableChartTimeframes wird nicht mehr verwendet
  selectedDays,
  chartContainerRef,
  chartRef,
  showTradingView,
  toggleChart,
  handleDownloadPng,
  userLocale,
  effectivePrice,
  chartTheme,
  symbol,
  mainCoinName,
}) => {
  const { width } = useWindowSize();
  const isMobile = width < 480;

  // Setze standardmäßig "24h" (days = "1"), falls kein Wert vorhanden ist.
  const [localSelectedDays, setLocalSelectedDays] = useState(selectedDays || '1');

  // Fullscreen State
  const [isFullscreen, setIsFullscreen] = useState(false);
  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      if (chartContainerRef.current) {
        chartContainerRef.current.requestFullscreen()
          .then(() => setIsFullscreen(true))
          .catch(err => console.error(`Request Fullscreen failed: ${err}`));
      }
    } else {
      document.exitFullscreen()
        .then(() => setIsFullscreen(false))
        .catch(err => console.error(`Exit Fullscreen failed: ${err}`));
    }
  };

  useEffect(() => {
    const handleFullscreenChange = () => {
      if (!document.fullscreenElement) {
        setIsFullscreen(false);
      }
    };
    document.addEventListener('fullscreenchange', handleFullscreenChange);
    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);

  const tradingViewSymbol = symbol ? `BINANCE:${symbol.toUpperCase()}USDT` : null;
  const showChartLoading = !tradingViewSymbol;

  // Vergleichsmodus State
  const [showCompareSearch, setShowCompareSearch] = useState(false);
  const [comparisonCoinId, setComparisonCoinId] = useState(null);
  const [comparisonCoinName, setComparisonCoinName] = useState(null);

  const handleCoinSelected = async (selectedId) => {
    setComparisonCoinId(selectedId);
    setShowCompareSearch(false);
    try {
      // Anstelle von fetch => api.get
      const response = await api.get(`/coin-data?id=${selectedId}`);
      setComparisonCoinName(response.data.name);
    } catch (error) {
      console.error('Fehler beim Laden des Vergleichs-Coin-Namens:', error);
      setComparisonCoinName('Unbekannt');
    }
  };

  const handleCompareButtonClick = () => {
    if (comparisonCoinId) {
      // Vergleich beenden
      setComparisonCoinId(null);
      setComparisonCoinName(null);
    } else {
      // Vergleich starten
      setShowCompareSearch(true);
    }
  };

  const iconButtonStyle = isMobile
    ? { padding: '9px 9px', fontSize: '0.85rem' }
    : {};

  // --- Dynamische Timeframe-Berechnung ---
  const [earliestTimestamp, setEarliestTimestamp] = useState(null);
  const [computedTimeframes, setComputedTimeframes] = useState([]);
  const [ageHint, setAgeHint] = useState('');

  /**
   * EarliestTimestamp laden
   */
  useEffect(() => {
    if (!coinId || !vsCurrency) return;

    const fetchEarliestTimestamp = async () => {
      try {
        // fetch -> api.get
        const res = await api.get(`/coin-market-chart?id=${coinId}&vs_currency=${vsCurrency}&days=1`);
        if (res.data.earliestTimestamp) {
          setEarliestTimestamp(res.data.earliestTimestamp);
        }
      } catch (error) {
        console.error('Fehler beim Laden des earliestTimestamp:', error);
      }
    };
    fetchEarliestTimestamp();
  }, [coinId, vsCurrency]);

  useEffect(() => {
    const standardOptions = [
      { value: '1', label: '24h' },
      { value: '7', label: '7d' },
      { value: '14', label: '14d' },
      { value: '30', label: '30d' },
      { value: '60', label: '60d' },
      { value: '200', label: '200d' },
      { value: '365', label: '1y' },
    ];
    if (!earliestTimestamp) {
      setComputedTimeframes([]);
      return;
    }
    const coinAgeInDays = Math.floor((Date.now() - earliestTimestamp) / (1000 * 60 * 60 * 24));
    if (coinAgeInDays < 1) {
      setComputedTimeframes([{ value: '1', label: '24h' }]);
      setAgeHint(
        <Trans id="chart.timeframe.24h.hint">
          Hinweis: Neuer Coin
        </Trans>
      );
      return;
    }
    if (coinAgeInDays >= 365) {
      const available = [...standardOptions];
      available.push({ value: 'max', label: 'max' });
      setComputedTimeframes(available);
      setAgeHint('');
    } else {
      const available = standardOptions.filter(opt => parseInt(opt.value, 10) <= coinAgeInDays);
      if (!available.some(opt => parseInt(opt.value, 10) === coinAgeInDays)) {
        available.push({ value: String(coinAgeInDays), label: `${coinAgeInDays}d` });
      }
      available.sort((a, b) => parseInt(a.value, 10) - parseInt(b.value, 10));
      setComputedTimeframes(available);
      setAgeHint('');
    }
  }, [earliestTimestamp]);

  // --- Standardwert setzen ---
  useEffect(() => {
    if (computedTimeframes.length > 0 && (!localSelectedDays || localSelectedDays === '')) {
      // Da 24h immer vorhanden ist, setzen wir einfach "1"
      const option24h = computedTimeframes.find(opt => opt.value === '1');
      if (option24h) {
        setLocalSelectedDays('1');
        setSelectedChartTimeframe('1');
      } else {
        // Fallback: das kleinste verfügbare Timeframe
        const smallest = computedTimeframes[0];
        setLocalSelectedDays(smallest.value);
        setSelectedChartTimeframe(smallest.value);
      }
    }
  }, [computedTimeframes, localSelectedDays, setSelectedChartTimeframe]);

  // --- Wenn wir TradingView anzeigen möchten ---
  if (showTradingView) {
    return (
      <div ref={chartContainerRef} className="candlestick-chart-card">
        <div style={{ position: 'relative', marginBottom: '10px' }}>
          <div className="coin-chart-title-container">
            <h3 className="coin-chart-title">
              <Trans id="label.tradingviewchart">Tradingview Chart</Trans>
            </h3>
          </div>
          <div className="tooltip-wrapper">
            <InfoTooltip iconSize={18}>
              <Trans id="tooltips.chartExplanation">
                Dieser Chart zeigt den historischen Preisverlauf sowie das Handelsvolumen. 
                Du kannst verschiedene Zeiträume auswählen, Coins miteinander vergleichen und 
                den Chart in den TradingView-Modus umschalten. Zudem kannst du Screenshots 
                speichern und den Chart im Vollbildmodus betrachten.
              </Trans>
            </InfoTooltip>
          </div>
        </div>
        <div
          className="coin-chart-header"
          style={{ display: 'flex', alignItems: 'center', gap: isMobile ? '5px' : '10px' }}
        >
          <div style={{ marginLeft: 'auto', display: 'flex', gap: isMobile ? '5px' : '10px' }}>
            <StyledButton
              variant="chart"
              onClick={toggleFullscreen}
              style={!isMobile ? { padding: '6px 12px' } : iconButtonStyle}
              className={!isMobile ? 'desktop-icon-button' : ''}
            >
              <FaExpand size={isMobile ? 15 : 16} />
            </StyledButton>
            <StyledButton
              variant="chart"
              onClick={toggleChart}
              style={!isMobile ? { padding: '6px 12px' } : iconButtonStyle}
              className={!isMobile ? 'desktop-icon-button' : ''}
            >
              <FaChartBar size={isMobile ? 15 : 16} />
            </StyledButton>
          </div>
        </div>
        {showChartLoading ? (
          <div style={{ padding: '20px', textAlign: 'center' }}>
            <Trans id="message.loadingChart">Lade Chart...</Trans>
          </div>
        ) : (
          <TradingViewAdvancedChart symbol={tradingViewSymbol} theme={chartTheme} />
        )}
      </div>
    );
  }

  // --- Falls wir NICHT TradingView, sondern unseren normalen Chart zeigen ---
  if (computedTimeframes.length === 0) {
    // Warte, bis wir Timeframes haben (earliestTimestamp => computedTimeframes)
    return (
      <div className="loading-spinner-container">
        <div className="spinner"></div>
        <Trans id="message.loadingTimeframes">Lade Zeitrahmen...</Trans>
      </div>
    );
  }

  return (
    <div ref={chartContainerRef} className="coin-chart-card">
      <div style={{ position: 'relative', marginBottom: '10px' }}>
        <div className="coin-chart-title-container">
          <h3 className="coin-chart-title">
            <Trans id="label.chart">Chart Übersicht</Trans>
          </h3>
        </div>
        <div className="tooltip-wrapper">
          <InfoTooltip iconSize={18}>
            <Trans id="tooltips.chartExplanation">
              Dieser Chart zeigt den historischen Preisverlauf sowie das Handelsvolumen.
              Du kannst verschiedene Zeiträume auswählen, Coins miteinander vergleichen
              und den Chart in den TradingView-Modus umschalten. Zudem kannst du Screenshots
              speichern und den Chart im Vollbildmodus betrachten.
            </Trans>
          </InfoTooltip>
        </div>
      </div>

      <div
        className="coin-chart-header"
        style={{ display: 'flex', alignItems: 'center', gap: isMobile ? '5px' : '10px' }}
      >
        <SingleSelectNoSearch
          options={computedTimeframes}
          selectedValue={localSelectedDays}
          onChange={(value) => {
            setSelectedChartTimeframe(value);
            setLocalSelectedDays(value);
            localStorage.setItem('selectedChartTimeframe', value);
          }}
          placeholder="Zeitraum"
          minWidth={80}
        />
        {ageHint && (
          <div style={{ marginLeft: '10px', fontSize: '0.9rem', color: '#888' }}>
            {ageHint}
          </div>
        )}

        <div style={{ marginLeft: 'auto', display: 'flex', gap: isMobile ? '5px' : '10px' }}>
          <StyledButton
            variant="chart"
            onClick={toggleFullscreen}
            style={!isMobile ? { padding: '5px 12px' } : iconButtonStyle}
            className={!isMobile ? 'desktop-icon-button' : ''}
          >
            <FaExpand size={isMobile ? 15 : 16} />
          </StyledButton>

          <StyledButton
            variant="chart"
            onClick={toggleChart}
            style={!isMobile ? { padding: '5px 12px' } : iconButtonStyle}
            className={!isMobile ? 'desktop-icon-button' : ''}
          >
            <FaChartLine size={isMobile ? 15 : 16} />
          </StyledButton>

          <StyledButton
            variant="chart"
            onClick={handleDownloadPng}
            style={!isMobile ? { padding: '5px 12px' } : iconButtonStyle}
            className={!isMobile ? 'desktop-icon-button' : ''}
          >
            <FaDownload
              size={isMobile ? 14 : 16}
              style={{ marginRight: isMobile ? '0px' : '5px' }}
            /> {isMobile ? '' : ' PNG'}
          </StyledButton>

          {/* Vergleich */}
          <div style={{ position: 'relative', display: 'inline-block' }}>
            <StyledButton
              variant="primary"
              onClick={handleCompareButtonClick}
              style={isMobile ? { padding: '9px 9px', fontSize: '0.85rem' } : {}}
              className={!isMobile ? 'desktop-compare-button' : ''}
              title={comparisonCoinId ? 'Vergleich beenden' : 'Vergleich starten'}
            >
              {comparisonCoinId ? (
                <FaTimesCircle
                  size={isMobile ? 15 : 16}
                  style={{ marginRight: isMobile ? '2px' : '5px' }}
                />
              ) : (
                <FaPlusCircle
                  size={isMobile ? 15 : 16}
                  style={{ marginRight: isMobile ? '2px' : '5px' }}
                />
              )}
              {isMobile ? '' : comparisonCoinId ? 'Vergleich beenden' : 'Vergleich starten'}
            </StyledButton>
            {showCompareSearch && !comparisonCoinId && (
              <CompareSearch
                onClose={() => setShowCompareSearch(false)}
                onCoinSelected={handleCoinSelected}
              />
            )}
          </div>
        </div>
      </div>

      <CoinChart
        ref={chartRef}
        coinId={coinId}
        comparisonCoinId={comparisonCoinId}
        comparisonCoinName={comparisonCoinName}
        mainCoinName={mainCoinName}
        vsCurrency={vsCurrency}
        selectedDays={localSelectedDays}
        formatPrice={(price, currency) => price}
        userLocale={userLocale}
        currentPrice={effectivePrice}
        isFullscreen={isFullscreen}
      />
    </div>
  );
};

export default ChartContainer;
