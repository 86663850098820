// src/components/CoinDetails/CoinDetails.js

import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Trans, t } from '@lingui/macro';
import { i18n } from '@lingui/core';
import { formatPrice } from '../../utils/formatPrice';
import { removeTrailingZeros } from '../../utils/formatPrice';
import { formatLargeValue } from '../../utils/formatLargeValue';
import { useBinanceData } from '../../hooks/useBinanceData';

import Header from './Header';
import PriceDisplay from './PriceDisplay';
import CapVolumeInfo from './CapVolumeInfo';
import MarketData from './MarketData';
import SupplyInfo from './SupplyInfo';
import ChartContainer from './ChartContainer';
import Description from './Description';
import TechnicalInfo from './TechnicalInfo';
import Links from './Links';
import ActiveMarkets from './ActiveMarkets';
import TradesDisplay from './TradesDisplay';

import '../../styles/CoinDetails.css';
import { Helmet } from 'react-helmet';

// Neu: Unser zentrales Axios-Objekt
import api from '../../services/api';

/**
 * Hilfsfunktion für Title-Formatting
 */
function formatTitlePrice(price, currency = 'usd') {
  if (price === null || price === undefined || isNaN(price)) {
    return 'N/A';
  }
  const storedLang = localStorage.getItem('language') || 'de';
  const locale = storedLang === 'de' ? 'de-DE' : 'en-US';

  let formattedNumber;

  if (price >= 100) {
    formattedNumber = price.toLocaleString(locale, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    formattedNumber = removeTrailingZeros(formattedNumber);
  } else if (price >= 10) {
    formattedNumber = price.toLocaleString(locale, {
      minimumFractionDigits: 4,
      maximumFractionDigits: 4,
    });
    formattedNumber = removeTrailingZeros(formattedNumber);
  } else if (price >= 1) {
    formattedNumber = price.toLocaleString(locale, {
      minimumFractionDigits: 4,
      maximumFractionDigits: 4,
    });
    formattedNumber = removeTrailingZeros(formattedNumber);
  } else {
    // Für Preise < 1
    if (price >= 0.001) {
      const increment = 0.000005;
      const rounded = Math.round(price / increment) * increment;
      formattedNumber = rounded.toFixed(6);
      formattedNumber = removeTrailingZeros(formattedNumber);
    } else if (price >= 0.000001) {
      formattedNumber = price.toFixed(12);
      formattedNumber = removeTrailingZeros(formattedNumber);
    } else {
      formattedNumber = price.toFixed(16);
      formattedNumber = removeTrailingZeros(formattedNumber);
    }
  }

  return `${formattedNumber} ${currency.toUpperCase()}`;
}

const CoinDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const coinId = new URLSearchParams(location.search).get('coin');
  const userLocale = i18n.locale || 'en';

  // States
  const [coinData, setCoinData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null); // (im UI nicht mehr stark genutzt)
  const [vsCurrency, setVsCurrency] = useState(localStorage.getItem('vsCurrency') || 'usd');

  // Vergleichsmodus
  const [showComparison, setShowComparison] = useState(false);
  const [showCompareSearch, setShowCompareSearch] = useState(false);
  const [comparisonCoinId, setComparisonCoinId] = useState(null);
  const [comparisonCoinName, setComparisonCoinName] = useState(null);
  const [mainCoinName, setMainCoinName] = useState(null);

  // Retry-Logik
  const retryCountRef = useRef(0);
  const maxRetries = 5;

  const handleCompareClick = () => {
    if (showComparison) {
      setShowComparison(false);
      setComparisonCoinId(null);
    } else {
      setShowCompareSearch(true);
    }
  };
  const handleCoinSelected = async (selectedId) => {
    setShowCompareSearch(false);
    setShowComparison(true);
    setComparisonCoinId(selectedId);

    try {
      const url = `/coin-data?id=${selectedId}&localization=false&tickers=false&market_data=false&community_data=false&developer_data=false&sparkline=false`;
      const resp = await api.get(url);
      setComparisonCoinName(resp.data.name);
    } catch (error) {
      console.error('Fehler beim Laden des Vergleichs-Coin-Namens:', error);
      setComparisonCoinName('Vergleich');
    }
  };

  // Mappings
  const timeframeFields = {
    '24h': 'price_change_percentage_24h_in_currency',
    '7d': 'price_change_percentage_7d_in_currency',
    '14d': 'price_change_percentage_14d_in_currency',
    '30d': 'price_change_percentage_30d_in_currency',
    '60d': 'price_change_percentage_60d_in_currency',
    '200d': 'price_change_percentage_200d_in_currency',
    '1y': 'price_change_percentage_1y_in_currency',
  };
  const chartTimeframesMapping = {
    '24h': 1,
    '7d': 7,
    '14d': 14,
    '30d': 30,
    '60d': 60,
    '200d': 200,
    '1y': 365,
    'max': 'max',
  };

  const [selectedChangeTimeframe, setSelectedChangeTimeframe] = useState('24h');
  const [selectedChartTimeframe, setSelectedChartTimeframe] = useState(() => {
    const stored = localStorage.getItem('selectedChartTimeframe');
    return stored ? stored : '24h';
  });

  // Chart
  const chartContainerRef = useRef(null);
  const chartRef = useRef(null);
  const [showTradingView, setShowTradingView] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);

  // Binance
  const [coingeckoBasePrice, setCoingeckoBasePrice] = useState(null);
  const [initialGeckoPercentage, setInitialGeckoPercentage] = useState(null);

  const { binancePrice, useBinancePrice, priceDirection, highlightKey } = useBinanceData(
    coinData ? coinData.symbol : ''
  );

  // Kategorien
  const [fullCategories, setFullCategories] = useState([]);

  // -----------------------------------
  // Daten laden via Axios
  // -----------------------------------
  const fetchCoinData = async (showLoading = false) => {
    if (showLoading) {
      setLoading(true);
      setError(null);
    }
    const url = `/coin-data?id=${coinId}&localization=true&tickers=false&market_data=true&community_data=true&developer_data=true&sparkline=false`;

    try {
      const res = await api.get(url);
      const data = res.data;

      setCoinData(data);
      setMainCoinName(data.name);

      // Coingecko Price
      const currentPrice = data.market_data?.current_price?.[vsCurrency];
      if (currentPrice !== undefined) {
        setCoingeckoBasePrice(currentPrice);
      }

      retryCountRef.current = 0;
      setError(null);
      if (showLoading) setLoading(false);
    } catch (err) {
      console.error('fetchCoinData Fehler:', err);

      // 429 Rate-Limit?
      if (err.response && err.response.status === 429) {
        console.warn('API limit reached (429).');
        // Ggf. Retry abwarten ...
      } else if (err.code === 'ERR_NETWORK') {
        // => Netzwerkfehler
        if (retryCountRef.current < maxRetries) {
          retryCountRef.current++;
          const retryDelay = Math.pow(2, retryCountRef.current) * 1000;
          console.log(
            `Erneuter Versuch in ${retryDelay / 1000} Sekunden... (Versuch ${retryCountRef.current} von ${maxRetries})`
          );
          setTimeout(() => fetchCoinData(false), retryDelay);
          return;
        }
      }
      // Sonst "Hard"-Abbruch
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!coinId) return;
    fetchCoinData(true);
    const intervalId = setInterval(() => {
      fetchCoinData(false);
    }, 6000);
    return () => clearInterval(intervalId);
  }, [coinId, vsCurrency]);

  // Offline => neu laden
  useEffect(() => {
    const handleOnline = () => {
      fetchCoinData(false);
    };
    window.addEventListener('online', handleOnline);
    return () => {
      window.removeEventListener('online', handleOnline);
    };
  }, []);

  // Initialer Gecko-Prozentsatz
  useEffect(() => {
    if (!coinData) return;
    const priceChangeField = timeframeFields[selectedChangeTimeframe];
    if (
      coinData.market_data?.[priceChangeField]?.[vsCurrency] !== undefined &&
      initialGeckoPercentage === null
    ) {
      setInitialGeckoPercentage(coinData.market_data[priceChangeField][vsCurrency]);
    }
  }, [coinData, selectedChangeTimeframe, vsCurrency, initialGeckoPercentage]);

  // Verfügbare Timeframes
  const availableTimeframes = useMemo(() => {
    if (!coinData || !coinData.market_data) return [];
    return Object.keys(timeframeFields).filter((tf) => {
      const field = timeframeFields[tf];
      return coinData.market_data[field]?.[vsCurrency] !== undefined;
    });
  }, [coinData, vsCurrency]);

  // In Chart => plus "max" wenn 1y existiert
  const availableChartTimeframes = useMemo(() => {
    const chartTFs = [...availableTimeframes];
    if (chartTFs.includes('1y')) {
      chartTFs.push('max');
    }
    return chartTFs;
  }, [availableTimeframes]);

  useEffect(() => {
    if (coinData && availableTimeframes.length > 0 && !availableTimeframes.includes(selectedChangeTimeframe)) {
      setSelectedChangeTimeframe(availableTimeframes[0]);
    }
  }, [coinData, availableTimeframes, selectedChangeTimeframe]);

  useEffect(() => {
    if (coinData && availableChartTimeframes.length > 0 && !availableChartTimeframes.includes(selectedChartTimeframe)) {
      setSelectedChartTimeframe(availableChartTimeframes[0]);
    }
  }, [coinData, availableChartTimeframes, selectedChartTimeframe]);

  const selectedDays = chartTimeframesMapping[selectedChartTimeframe];

  // -----------------------------------
  // Effektiver Preis
  // -----------------------------------
  const symbol = coinData ? coinData.symbol : '';
  const symbolLower = symbol.toLowerCase();
  const safeVsCurrency = vsCurrency || 'usd';
  const vsLower = safeVsCurrency.toLowerCase();

  let effectivePrice;
  let effectivePriceDirection = priceDirection; // Basiert auf neuem Wert


  if (symbolLower === vsLower) {
    effectivePrice = 1;
    effectivePriceDirection = ''; // Richtung zurücksetzen
  } else {
    if (useBinancePrice) {
      if (binancePrice !== null && coingeckoBasePrice !== null) {
        // Wir brauchen den lockedFactor
        const coingeckoPrices = coinData?.market_data?.current_price || {};
        const coingeckoPriceVs = coingeckoPrices[vsCurrency];
        const coingeckoPriceUsd = coingeckoPrices['usd'];
        const lockedFactor =
          coingeckoPriceVs && coingeckoPriceUsd ? coingeckoPriceVs / coingeckoPriceUsd : null;
        if (lockedFactor) {
          effectivePrice = binancePrice * lockedFactor;
        } else {
          effectivePrice = 'N/A';
        }
      } else {
        effectivePrice = 'N/A';
      }
    } else {
      const coingeckoPrices = coinData?.market_data?.current_price || {};
      effectivePrice =
        coingeckoPrices[vsCurrency] !== undefined && coingeckoPrices[vsCurrency] !== null
          ? coingeckoPrices[vsCurrency]
          : 'N/A';
    }
  }

  // -----------------------------------
  // Preisänderung
  // -----------------------------------
  const priceChangeField = timeframeFields[selectedChangeTimeframe];
  const geckoPercentageForCurrentTimeframe =
    coinData?.market_data?.[priceChangeField]?.[vsCurrency] ?? null;

  const priceChangePercentage = useMemo(() => {
    if (symbolLower === vsLower) {
      return 0;
    }
    if (
      useBinancePrice &&
      binancePrice !== null &&
      coingeckoBasePrice !== null &&
      geckoPercentageForCurrentTimeframe !== null
    ) {
      const coingeckoPrices = coinData?.market_data?.current_price || {};
      const coingeckoPriceVs = coingeckoPrices[vsCurrency];
      const coingeckoPriceUsd = coingeckoPrices['usd'];
      const lockedFactor =
        coingeckoPriceVs && coingeckoPriceUsd ? coingeckoPriceVs / coingeckoPriceUsd : null;
      if (lockedFactor) {
        const binancePriceInVsCurrency = binancePrice * lockedFactor;
        const binanceChange =
          ((binancePriceInVsCurrency - coingeckoBasePrice) / coingeckoBasePrice) * 100;
        return geckoPercentageForCurrentTimeframe + binanceChange;
      }
    }
    return geckoPercentageForCurrentTimeframe;
  }, [
    useBinancePrice,
    binancePrice,
    coingeckoBasePrice,
    geckoPercentageForCurrentTimeframe,
    vsCurrency,
    symbolLower,
    vsLower,
    coinData,
  ]);


  // -----------------------------------
  // Kategorien
  // -----------------------------------
  useEffect(() => {
    async function fetchFullCategories() {
      try {
        const resp = await api.get('/coins-categories-with-market-data?order=market_cap_desc');
        setFullCategories(resp.data || []);
      } catch (err) {
        console.error('Fehler beim Laden der Kategorienliste:', err);
      }
    }
    fetchFullCategories();
  }, []);

  const mappedCategories =
    coinData && coinData.categories
      ? coinData.categories.map((catName) => {
          const match = fullCategories.find((catObj) => catObj.name === catName);
          return match || catName;
        })
      : [];

  // -----------------------------------
  // MarketData
  // -----------------------------------
  const marketData = {
    high24h: coinData?.market_data?.high_24h
      ? formatPrice(coinData.market_data.high_24h[vsCurrency], vsCurrency)
      : 'N/A',
    low24h: coinData?.market_data?.low_24h
      ? formatPrice(coinData.market_data.low_24h[vsCurrency], vsCurrency)
      : 'N/A',
    high24hTimestamp: coinData?.market_data?.high_24h_timestamp
      ? new Date(coinData.market_data.high_24h_timestamp)
      : null,
    low24hTimestamp: coinData?.market_data?.low_24h_timestamp
      ? new Date(coinData.market_data.low_24h_timestamp)
      : null,
    ath: coinData?.market_data?.ath
      ? formatPrice(coinData.market_data.ath[vsCurrency], vsCurrency)
      : 'N/A',
    athDate: coinData?.market_data?.ath_date
      ? new Date(coinData.market_data.ath_date[vsCurrency])
      : null,
    athChange: coinData?.market_data?.ath_change_percentage
      ? coinData.market_data.ath_change_percentage[vsCurrency]
      : null,
    atl: coinData?.market_data?.atl
      ? formatPrice(coinData.market_data.atl[vsCurrency], vsCurrency)
      : 'N/A',
    atlDate: coinData?.market_data?.atl_date
      ? new Date(coinData.market_data.atl_date[vsCurrency])
      : null,
    atlChange: coinData?.market_data?.atl_change_percentage
      ? coinData.market_data.atl_change_percentage[vsCurrency]
      : null,
    marketCap: coinData?.market_data?.market_cap
      ? (() => {
          const rawMarketCap = coinData.market_data.market_cap[vsCurrency];
          if (rawMarketCap !== null && rawMarketCap > 0) {
            const formattedMc = formatLargeValue(rawMarketCap, vsCurrency, true);
            return formattedMc === '0,00' ? 'N/A' : formattedMc;
          }
          return 'N/A';
        })()
      : 'N/A',
    volume24h: coinData?.market_data?.total_volume
      ? (() => {
          const rawVolume = coinData.market_data.total_volume[vsCurrency];
          return rawVolume !== null && rawVolume !== undefined
            ? formatLargeValue(rawVolume, vsCurrency, true)
            : 'N/A';
        })()
      : 'N/A',
    marketCapRank: coinData?.market_cap_rank || 'N/A',
    marketCapRank24h: coinData?.market_cap_rank_24h || 'N/A',
    marketCapRankChange24h: coinData?.market_cap_rank_change_24h || null,
  };

  // Supply
  const supplyData = {
    maxSupply: coinData?.market_data?.max_supply ?? null,
    totalSupply: coinData?.market_data?.total_supply ?? null,
    circulatingSupply: coinData?.market_data?.circulating_supply ?? null,
  };

  let supplyDescription = null;
  let percentCirculating = null;
  if (supplyData.circulatingSupply === null) {
    supplyDescription = (
      <Trans id="description.noCirculatingData">
        Keine Daten über die umlaufende Versorgung verfügbar.
      </Trans>
    );
  } else if (supplyData.circulatingSupply === 0) {
    supplyDescription = (
      <Trans id="description.zeroCirculating">
        Hinweis: Die umlaufende Versorgung ist derzeit 0. Der Coin könnte noch sehr neu sein oder es
        liegen fehlerhafte Daten vor.
      </Trans>
    );
  } else {
    if (supplyData.maxSupply !== null && supplyData.maxSupply > 0) {
      percentCirculating = (supplyData.circulatingSupply / supplyData.maxSupply) * 100;
      supplyDescription = (
        <Trans id="description.maxSupply">
          Anteil der umlaufenden Coins an der maximalen Versorgung.
        </Trans>
      );
    } else if (supplyData.totalSupply !== null && supplyData.totalSupply > 0) {
      percentCirculating = (supplyData.circulatingSupply / supplyData.totalSupply) * 100;
      supplyDescription = (
        <Trans id="description.totalSupplyFallback">
          Da keine maximale Versorgung bekannt ist, wird hier der Anteil der umlaufenden Coins an
          der derzeitigen Gesamtversorgung dargestellt.
        </Trans>
      );
    } else {
      supplyDescription = (
        <Trans id="description.noReferenceData">
          Keine maximale oder gesamte Versorgung bekannt. Ein prozentualer Vergleich ist nicht
          möglich.
        </Trans>
      );
    }
  }

  const percentString = percentCirculating !== null ? percentCirculating.toFixed(2) : null;
  const percentTitle =
    percentString !== null
      ? i18n._({
          id: 'title.percentCirculation',
          message: '{percent}% im Umlauf',
          values: { percent: percentString },
        })
      : i18n._({
          id: 'title.noData',
          message: 'Keine ausreichenden Daten verfügbar',
        });
  const percentLabel =
    percentString !== null
      ? i18n._({
          id: 'label.percentValue',
          message: '{percent}%',
          values: { percent: percentString },
        })
      : '';

  // Technische Infos
  let genesisDate = coinData?.genesis_date || 'N/A';
  if (genesisDate !== 'N/A') {
    const dateObj = new Date(genesisDate);
    if (!isNaN(dateObj.getTime())) {
      genesisDate = dateObj.toLocaleDateString(userLocale, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
    }
  }
  const blockTime = coinData?.block_time_in_minutes || 'N/A';
  const hashingAlgorithm = coinData?.hashing_algorithm || 'N/A';

  const homepage =
    coinData?.links?.homepage && coinData.links.homepage.length > 0 && coinData.links.homepage[0]
      ? coinData.links.homepage[0]
      : '#';
  const blockchainSite =
    coinData?.links?.blockchain_site &&
    coinData.links.blockchain_site.length > 0 &&
    coinData.links.blockchain_site[0]
      ? coinData.links.blockchain_site[0]
      : '#';
  const subreddit = coinData?.links?.subreddit_url || '#';
  const forum =
    coinData?.links?.official_forum_url &&
    coinData.links.official_forum_url.length > 0 &&
    coinData.links.official_forum_url[0]
      ? coinData.links.official_forum_url[0]
      : '#';

  // Chart
  const toggleChart = () => setShowTradingView((prev) => !prev);
  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      if (chartContainerRef.current) {
        chartContainerRef.current
          .requestFullscreen()
          .then(() => setIsFullscreen(true))
          .catch((err) => console.error(`Request Fullscreen failed: ${err}`));
      }
    } else {
      document
        .exitFullscreen()
        .then(() => setIsFullscreen(false))
        .catch((err) => console.error(`Exit Fullscreen failed: ${err}`));
    }
  };

  const handleDownloadPng = () => {
    if (chartRef.current && chartRef.current.downloadChartAsImage) {
      const dataUrl = chartRef.current.downloadChartAsImage();
      if (dataUrl) {
        const link = document.createElement('a');
        link.href = dataUrl;
        link.download = 'chart.png';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  useEffect(() => {
    const handleFullScreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };
    document.addEventListener('fullscreenchange', handleFullScreenChange);
    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
    };
  }, []);

  // -----------------------------------
  // Frühes Exit
  // -----------------------------------
  if (!coinId) {
    return null;
  }
  if (!coinData) {
    return (
      <div className="coin-details-container">
        <div className="loading-spinner-container">
          <div className="spinner"></div>
          <Trans id="message.loadingDataCoinDetails">Lade Coin-Daten...</Trans>
        </div>
      </div>
    );
  }

  // Falls "loading" = false, coinData = null => "keine Daten"
  // (In den meisten Fällen obsolet, da coinData != null)
  if (!loading && !coinData) {
    console.error('Keine Coin-Daten verfügbar, obwohl die Anfrage abgeschlossen wurde.');
    return (
      <div className="coin-details-container">
        <div className="no-data-message">
          <Trans id="message.noDataAvailable">Keine Daten verfügbar.</Trans>
        </div>
      </div>
    );
  }

  // Dynamic Title
  const priceForTitle =
    typeof effectivePrice === 'number' ? formatTitlePrice(effectivePrice, vsCurrency) : 'N/A';
  const dynamicTitle =
    coinData && priceForTitle !== 'N/A'
      ? `${coinData.name} (${coinData.symbol?.toUpperCase()}) - ${priceForTitle} | CryptoScan`
      : coinData
      ? `${coinData.name} | CryptoScan`
      : 'Coin Details - CryptoScan';

  // Props
  const priceDisplayData = {
    formattedPrice: effectivePrice === 'N/A' ? 'N/A' : formatPrice(effectivePrice, vsCurrency),
    priceChangePercentage,
    priceDirection: priceDirection, 
    highlightKey: highlightKey,    
    marketCapRank: marketData.marketCapRank,
    selectedChangeTimeframe,
    setSelectedChangeTimeframe,
    availableTimeframes,
  };

  const capVolumeData = {
    categories: mappedCategories,
  };

  const marketDataProps = {
    high24h: marketData.high24h,
    low24h: marketData.low24h,
    high24hTimestamp: marketData.high24hTimestamp,
    low24hTimestamp: marketData.low24hTimestamp,
    ath: marketData.ath,
    athDate: marketData.athDate,
    athChange: marketData.athChange,
    atl: marketData.atl,
    atlDate: marketData.atlDate,
    atlChange: marketData.atlChange,
    marketCap: marketData.marketCap,
    volume24h: marketData.volume24h,
    marketCapRank: marketData.marketCapRank,
    marketCapRank24h: marketData.marketCapRank24h,
    marketCapRankChange24h: marketData.marketCapRankChange24h,
    userLocale,
    coinId,
    vsCurrency,
  };

  const supplyInfoData = {
    ...supplyData,
    supplyDescription,
    percentString,
    percentTitle,
    percentLabel,
  };

  const chartContainerData = {
    coinId,
    vsCurrency,
    selectedChartTimeframe,
    setSelectedChartTimeframe,
    availableChartTimeframes,
    selectedDays,
    chartContainerRef,
    chartRef,
    showTradingView,
    toggleChart,
    toggleFullscreen,
    handleDownloadPng,
    userLocale,
    effectivePrice,
    chartTheme: document.body.classList.contains('dark-mode') ? 'dark' : 'light',
    symbol: coinData.symbol,
    comparisonCoinId,
    comparisonCoinName,
    mainCoinName,
    onCompareClick: handleCompareClick,
    showComparison,
  };

  const descriptionData = {
    description:
      coinData.description?.[userLocale] || coinData.description?.en || t`Keine Beschreibung verfügbar.`,
  };

  const technicalInfoData = {
    blockTime,
    hashingAlgorithm,
    genesisDate,
  };

  const linksData = {
    homepage,
    blockchainSite,
    subreddit,
    forum,
  };

  return (
    <div className={`coin-details-container ${isFullscreen ? 'fullscreen' : ''}`}>
      <Helmet>
        <title>{dynamicTitle}</title>
        <meta
          name="description"
          content={
            coinData
              ? `Aktuelle Informationen zu ${coinData.name} (${coinData.symbol?.toUpperCase()}). Preis, Marktdaten und mehr.`
              : 'Coin Details werden geladen.'
          }
        />
      </Helmet>

      <Header coinData={coinData} onCompareClick={handleCompareClick} showComparison={showComparison} />

      <PriceDisplay {...priceDisplayData} />
      <CapVolumeInfo {...capVolumeData} />

      <div className="coin-info-grid">
        <MarketData {...marketDataProps} />
        <SupplyInfo {...supplyInfoData} />
      </div>

      <ChartContainer {...chartContainerData} />

      <TradesDisplay symbol={coinData ? coinData.symbol : ''} />
      <Description {...descriptionData} />
      <ActiveMarkets coinId={coinId} vsCurrency={vsCurrency} userLocale={userLocale} />

      <div className="coin-info-grid">
        <TechnicalInfo {...technicalInfoData} />
        <Links {...linksData} />
      </div>
    </div>
  );
};

export default CoinDetails;
