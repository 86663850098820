// src/components/Footer.js

import React from 'react';
import '../styles/Footer.css'; // unser angepasstes CSS
import { t } from '@lingui/macro';
import {
  FaFacebookF,
  FaInstagram,
  FaReddit,
  FaTelegramPlane,
  FaInfoCircle,
  FaShieldAlt,
  FaEnvelope
} from 'react-icons/fa';

import { FaXTwitter } from 'react-icons/fa6';

const Footer = () => {
  return (
    <footer className="footer_main">
      <div className="footer_content">
        {/* Social Icons (links) */}
        <div className="footer_left-group">
          <a
            id="footer.social.facebook"
            href="https://www.facebook.com/cryptoscanD/"
            className="footer-social-link"
            target="blank"
            title={t({ id: 'footer.social.facebookTitle', message: 'Facebook' })}
          >
            <FaFacebookF />
          </a>
          <a
            id="footer.social.instagram"
            href="https://www.instagram.com/cryptoscandigital/"
            className="footer-social-link"
            target="blank"
            title={t({ id: 'footer.social.instagramTitle', message: 'Instagram' })}
          >
            <FaInstagram />
          </a>
          <a
            id="footer.social.twitter"
            href="https://x.com/CryptoscanD/"
            className="footer-social-link"
            target="blank"
            title={t({ id: 'footer.social.twitterTitle', message: 'Twitter' })}
          >
            <FaXTwitter />
          </a>
          <a
            id="footer.social.reddit"
            href="javascript:void(0)"
            className="footer-social-link disabled"
            title={t({ id: 'footer.social.redditTitle', message: 'Reddit' })}
          >
            <FaReddit />
          </a>
          <a
            id="footer.social.telegram"
            href="javascript:void(0)"
            className="footer-social-link disabled"
            title={t({ id: 'footer.social.telegramTitle', message: 'Telegram' })}
          >
            <FaTelegramPlane />
          </a>
        </div>

        {/* Copyright (Mitte) */}
        <div className="footer_copyright">
          © 2025 cryptoscan.digital / All rights reserved.
        </div>

        {/* Link-Gruppe (rechts) */}
        <div className="footer_right-group">
          <a
            id="footer.link.impressum"
            href="/legal-notice"
            className="footer-link"
            title={t({ id: 'footer.link.impressumTitle', message: 'Impressum' })}
          >
            <FaInfoCircle className="footer-link-icon" />
            <span>{t({ id: 'footer.link.impressumLabel', message: 'Impressum' })}</span>
          </a>

          <a
            id="footer.link.datenschutz"
            href="/privacy-policy"
            className="footer-link disabled"
            title={t({ id: 'footer.link.datenschutzTitle', message: 'Datenschutz' })}
          >
            <FaShieldAlt className="footer-link-icon" />
            <span>{t({ id: 'footer.link.datenschutzLabel', message: 'Datenschutz' })}</span>
          </a>

          <a
            id="footer.link.kontakt"
            href="/contact"
            className="footer-link"
            title={t({ id: 'footer.link.kontaktTitle', message: 'Kontakt' })}
          >
            <FaEnvelope className="footer-link-icon" />
            <span>{t({ id: 'footer.link.kontaktLabel', message: 'Kontakt' })}</span>
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
