import React from 'react';
import CategoryBadges from '../CategoryBadges';

const CapVolumeInfo = ({ categories }) => {
  return (
    <div className="cap-volume-info">
      {categories && categories.length > 0 && (
        <div style={{ marginBottom: '12px' }}>
          <CategoryBadges categories={categories} />
        </div>
      )}
    </div>
  );
};

export default CapVolumeInfo;