// src/components/Breadcrumbs.js
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Trans } from '@lingui/macro';
import '../styles/Breadcrumbs.css';

// Mapping für Detailseiten (zweiter Breadcrumb)
const secondCrumbMap = {
  // Kryptobereich – Detailseite:
  '/coin-details': (search) => {
    const coinParam = new URLSearchParams(search).get('coin');
    return coinParam
      ? coinParam.charAt(0).toUpperCase() + coinParam.slice(1)
      : <Trans id="nav.coinDetails">Coin Details</Trans>;
  },
  // Tools-Bereich – Detailseiten:
  '/crypto-bot': <Trans id="nav.cryptoBot">Trading Simulator</Trans>,
  '/scamgame': <Trans id="nav.scamgame">Scam-Game</Trans>,
  // Account-Bereich – Detailseite:
  '/profile/edit': <Trans id="link.editProfile">Profil bearbeiten</Trans>
};

const Breadcrumbs = () => {
  const location = useLocation();
  const { pathname, search } = location;

  // Whitelist: Nur diese Routen sollen Breadcrumbs rendern.
  const breadcrumbWhitelist = [
    '/scamgame/results',
    '/widgets/new',
    '/coin-details',
    '/profile/edit'
  ];

  const shouldRenderBreadcrumbs = breadcrumbWhitelist.some(item =>
    pathname.startsWith(item)
  );

  if (!shouldRenderBreadcrumbs) {
    return null;
  }

  // Spezialfall: "/scamgame/results"
  if (pathname === '/scamgame/results') {
    return (
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/scamgame">
              <Trans id="nav.scamgame">Scam-Game</Trans>
            </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            <Trans id="scamgame.showResults">Ergebnisse</Trans>
          </li>
        </ol>
      </nav>
    );
  }

  // Spezialfall: "/widgets/new"
  if (pathname === '/widgets/new') {
    return (
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/widgets">
              <Trans id="nav.widgets">Widgets</Trans>
            </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            <Trans id="widget.builder.createTitle">Widget erstellen</Trans>
          </li>
        </ol>
      </nav>
    );
  }

  // Für Kryptowährungs-Detailseiten: (alle Routen, die mit "/coin-details" beginnen)
  if (pathname.startsWith('/coin-details')) {
    // Den Query-Parameter "from" auslesen
    const urlParams = new URLSearchParams(search);
    const fromParam = urlParams.get('from');

    // Mapping des "from" Parameters:
    // "1" -> Kategorien (wie in CategorieList.js gesendet)
    // "2" -> GainersLosers
    // "3" -> NewlyListedCoins
    const referrerMapping = {
      "1": {
        url: '/categories',
        label: <Trans id="nav.categories">Kategorien</Trans>
      },
      "2": {
        url: '/gainers-losers',
        label: <Trans id="nav.gainersLosers">Gainers & Losers</Trans>
      },
      "3": {
        url: '/newly-listed',
        label: <Trans id="nav.newCoins">Neue Listings</Trans>
      },
      "4": {
        url: '/favorites',
        label: <Trans id="nav.favorites">Favoriten</Trans>
      }
    };

    // Fallback: Wenn kein passender "from" Parameter vorhanden ist, verwende CoinList
    const baseCrumb = referrerMapping[fromParam] || {
      url: '/coinlist',
      label: <Trans id="nav.cryptocurrencies">Kryptowährungen</Trans>
    };

    const value = secondCrumbMap['/coin-details'];
    const secondCrumb = {
      url: '/coin-details',
      label: typeof value === 'function' ? value(search) : value
    };

    // Falls beide Breadcrumbs identisch sind, rendern wir nichts.
    if (baseCrumb.label === secondCrumb.label) {
      return null;
    }

    return (
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to={baseCrumb.url}>{baseCrumb.label}</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {secondCrumb.label}
          </li>
        </ol>
      </nav>
    );
  }

  // Für Account‑Detailseiten: (alle Routen, die mit "/profile/edit" beginnen)
  if (pathname.startsWith('/profile/edit')) {
    const baseCrumb = {
      url: '/profile',
      label: <Trans id="nav.profile">Profil</Trans>
    };

    const value = secondCrumbMap['/profile/edit'];
    const secondCrumb = {
      url: '/profile/edit',
      label: typeof value === 'function' ? value(search) : value
    };

    if (baseCrumb.label === secondCrumb.label) {
      return null;
    }

    return (
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to={baseCrumb.url}>{baseCrumb.label}</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {secondCrumb.label}
          </li>
        </ol>
      </nav>
    );
  }

  return null;
};

export default Breadcrumbs;
